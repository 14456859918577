import React, {Component} from 'react';
import {Button, Col, Form, FormControl, FormGroup, Row} from "react-bootstrap";
import AddFormMargin from "../../../../sharedComponents/formComponents/AddFormMargin";
import {IconCheck, IconSave} from "../../../../sharedComponents/icons/FontAwesome";
import {regexp_password} from "../../../../utilities/regexps/regexps";
import {TextAlert} from "../../../../sharedComponents/typographyComponents/TextAlert/TextAlert";
import {
    displayNotificationError,
    displayNotificationInfo
} from "../../../../utilities/notifications/NotificationsManager";
import {Redirect} from 'react-router-dom';
import LoginService from "../../../../services/LoginService";
import axios from 'axios'

class UstawieniaKontaContainer extends Component {

    state = {
        stareHaslo: '',
        noweHaslo: '',
        noweHasloPowtorz: '',
        pokazCzyHaslaTakieSame: false,
        pokazWskazowkeDoHasla: false,
        kolorWskazowkiDoHasla: 'info',

        pokazEkranPoZmianieHasla: false,
        czyPrzekierowac: false,

        zgody: [],
        trwaZmiana: false
    };

    componentDidMount() {
        this.pobierzZgody();
    }

    pobierzZgody = () => {
        LoginService.getZgody().then((response) => {
            this.setState({zgody: response.data});
        }).catch((e) => {
            console.log('error');
        })
    }

    onClickZmien = () => {
        let isOk = true;

        if (!Boolean(this.state.stareHaslo) || !Boolean(this.state.noweHaslo) || !Boolean(this.state.noweHasloPowtorz)) {
            isOk = false;
            displayNotificationError('Należy uzupełnić wszystkie pola', '')
        }
        if (!regexp_password.test(this.state.noweHaslo)) {
            isOk = false;
            displayNotificationError('Nowe hasło nie spełnia wymagań', 'Hasło musi posiadać co najmniej 8 znaków i powinno zawierać: małą literę, wielką literę, cyfrę, znak specjalny. Dozwolone znaki specjalne: ! @ # $ % ^ &');
        }
        if (this.state.noweHaslo !== this.state.noweHasloPowtorz) {
            isOk = false;
            displayNotificationError('Podane nowe hasła nie są identyczne', '')
        }

        if (isOk) {

            LoginService.zmienHaslo(
                {
                    "noweHaslo": this.state.noweHaslo,
                    "stareHaslo": this.state.stareHaslo
                }
            )
                .then((response) => {

                    let isResponseOk = false;

                    if (response && response.status < 300) {
                        if (response.data.indexOf('nieprawidłowe') > 0) {
                        } else
                            isResponseOk = true
                    }

                    if (isResponseOk) {


                        this.setState({pokazEkranPoZmianieHasla: true});
                        LoginService.logoutUser()
                            .then((response) => {
                                setTimeout(() => {
                                    delete axios.defaults.headers.common["Authorization"];
                                    LoginService.removeUserFromStorage();
                                    this.setState(({czyPrzekierowac: true}));
                                }, 1500)

                            })
                    } else {
                        displayNotificationError('Wystąpił błąd podczas próby zmiany hasła', response.data)
                    }

                })
                .catch((error) => {
                    displayNotificationError('Wystąpił błąd podczas próby zmiany hasła', '')
                });
        }

    };

    czyZgoda = (nazwa: any): boolean => {
        let obj: any = this.state.zgody.find((p: any) => p.nazwaZgody === nazwa);

        if(obj){
            return obj.zgoda
        } else {
            return false
        }
    }

    zmienZgode = (nazwa: any) => {
        this.setState({trwaZmiana: true});
        let {zgody} = this.state;
        let z: any = zgody.find((p: any) => p.nazwaZgody === nazwa);

        if(z){
            z.zgoda = !z.zgoda;

            LoginService.setZgody(z).then((response) => {
                displayNotificationInfo('Zmieniono zgodę', '');
                this.setState({zgody, trwaZmiana: false});
            }).catch((e) => {
                displayNotificationError('Wystąpił problem podczas próby zmiany zgody', '');
                z.zgoda = !z.zgoda;
                this.setState({zgody, trwaZmiana: false});
            })
        }
    }

    render() {

        let {pokazEkranPoZmianieHasla, czyPrzekierowac, pokazCzyHaslaTakieSame, pokazWskazowkeDoHasla, kolorWskazowkiDoHasla, zgody, trwaZmiana} = this.state;

        return (
            <div id={'UstawieniaUzytkownikaContainer'}>
                {
                    pokazEkranPoZmianieHasla ?
                        <div style={{textAlign:'center',marginTop:'25px'}}>
                            <h4>
                                <span className={'text-success'} style={{marginRight:'5px'}}>
                                    <IconCheck/>
                                </span>
                                <span>
                                    Pomyślnie zmieniono hasło
                                </span>
                            </h4>
                            <h6 className={'heading-label'}>
                                Za chwilę nastąpi przeniesienie do ekranu logowania...
                            </h6>
                            {
                                czyPrzekierowac && <Redirect to={'/'}/>
                            }
                        </div>
                        :
                        <div>
                            <h6 className={'heading-label'}>
                                Zmiana hasła
                            </h6>
                            <Form>
                                <Row>
                                    <Col md={4} lg={3} xl={2}>
                                        <Form.Label>Stare hasło</Form.Label>
                                        <FormControl size={'sm'} type={'password'} onChange={(e: any) => this.setState({stareHaslo: e.target.value})}/>
                                    </Col>
                                    <Col md={4} lg={3} xl={2}>
                                        <Form.Label>Nowe hasło</Form.Label>
                                        <FormControl size={'sm'} type={'password'} onChange={(e: any) => this.setState({noweHaslo: e.target.value})}
                                                     onFocus={() => this.setState({pokazWskazowkeDoHasla: true, kolorWskazowkiDoHasla: 'info'})}
                                                     onBlur={(e: any) => {
                                                         if (!regexp_password.test(e.target.value)) {
                                                             this.setState({kolorWskazowkiDoHasla: 'danger'});
                                                         } else {
                                                             this.setState({kolorWskazowkiDoHasla: 'success'});
                                                         }
                                                     }}
                                        />
                                    </Col>
                                    <Col md={4} lg={3} xl={2}>
                                        <Form.Label>Powtórz nowe hasło</Form.Label>
                                        <FormControl size={'sm'} type={'password'} onChange={(e: any) => this.setState({noweHasloPowtorz: e.target.value})}
                                                     onFocus={() => this.setState({pokazCzyHaslaTakieSame: true})}
                                        />
                                    </Col>
                                    <Col md={4} lg={3} xl={2}>
                                        <AddFormMargin hideForMobileDevices767={true}/>
                                        <Button variant={'primary'} size={'sm'} onClick={this.onClickZmien}><IconSave/> Zmień</Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div style={{marginTop: '15px'}}>
                                            {
                                                (pokazWskazowkeDoHasla) &&
                                                <TextAlert
                                                    type={kolorWskazowkiDoHasla}>
                                                    Hasło musi posiadać co najmniej 8 znaków i powinno zawierać: małą literę, wielką literę, cyfrę, znak specjalny. Dozwolone znaki
                                                    specjalne: ! @ # $ % ^ &
                                                </TextAlert>
                                            }
                                            {
                                                (pokazCzyHaslaTakieSame) &&
                                                <>
                                                    {
                                                        this.state.noweHaslo === this.state.noweHasloPowtorz && this.state.noweHaslo !== '' ?
                                                            <TextAlert type={'success'}>Hasła są takie same</TextAlert>
                                                            :
                                                            <TextAlert type={'danger'}>Hasła nie są takie same</TextAlert>
                                                    }
                                                </>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                }

                <div>
                    <h6 className={'heading-label'}>
                        Zgody
                    </h6>
                    <Row>
                        <Col>
                            <Form.Check inline={false} type="checkbox"
                                        label="Zgoda na powiadomienia email"
                                        checked={this.czyZgoda('ZGODA_EMAIL')}
                                        onClick={() => this.zmienZgode('ZGODA_EMAIL')}
                                        disabled={trwaZmiana}
                                        id={'zgodaEmail'}
                            />
                            <Form.Check inline={false} type="checkbox"
                                        label="Zgoda na powiadomienia mobilne"
                                        checked={this.czyZgoda('ZGODA_MOBILE')}
                                        onClick={() => this.zmienZgode('ZGODA_MOBILE')}
                                        disabled={trwaZmiana}
                                        id={'zgodaMobile'}
                            />
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default UstawieniaKontaContainer;
