import React from 'react';
import NavbarComponent from "./headerComponents/NavbarComponent";
import HeaderUpperComponent from "./headerComponents/HeaderUpperComponent";
import PowiadomieniaService from "../../services/PowiadomieniaService";

interface Props {
    isAdminPanel: boolean,
    stanKoszykaObject: any,
    handleChangeSiec: (siecObj: any) => void;
    setStyleContrast: (style: string) => void;
}

interface State {
    isToggle: boolean,
    contrast: boolean
}

class HeaderComponent extends React.Component<Props, State> {

    state = {
        isToggle: false,
        nowePowiadomienia: false,
        contrast: false
    };

    componentDidMount() {
        this.pobierzNieodczytane();
        this.setState({contrast: ((localStorage.getItem('FAMIX_CZYKONTRAST')) !== null)? ((localStorage.getItem('FAMIX_CZYKONTRAST') === 'contrast')) : false})
    }

    pobierzNieodczytane = () => {
        PowiadomieniaService.getNieodczytaneKomunikatyCount().then((response) => {
            // @ts-ignore
            this.setState({nowePowiadomienia: (response.data > 0)? response.data : false});
        }).catch((e) => {
            console.log('error');
        })
    }

    onClickToggleNavigation = () => {
        this.setState(prevState => ({    // prevState?
            isToggle: !prevState.isToggle
        }));
    };

    onClickContrast = () => {
        let {contrast} = this.state;
        console.log('contrast')

        if(contrast){
            this.props.setStyleContrast('');
            this.setState({contrast: false});
        } else {
            this.props.setStyleContrast('contrast');
            this.setState({contrast: true});
        }
    }

    render() {

        let {isToggle, nowePowiadomienia} = this.state;
        let {isAdminPanel, stanKoszykaObject, handleChangeSiec} = this.props;

        return (
            <header>
                <HeaderUpperComponent handleToggleNavigation={this.onClickToggleNavigation}
                                      isAdminPanel={isAdminPanel}
                                      stanKoszykaObject={stanKoszykaObject}
                                      handleChangeSiec={handleChangeSiec}
                                      nowePowiadomienia={nowePowiadomienia}
                                      setStyleContrast={this.onClickContrast}
                />
                <NavbarComponent isToggle={isToggle} isAdminPanel={isAdminPanel}
                                 handleChangeSiec={handleChangeSiec}
                                 nowePowiadomienia={nowePowiadomienia}
                />
            </header>
        );
    }
}

export default HeaderComponent;