import React from 'react';
import TopCard from "../../../sharedComponents/TopCard";
import LoginService from "../../../services/LoginService";
import {famix_getFirmaOrKontrahentFromLS} from "../../../utilities/famix_others/famix_getAnotherData";
import KoszykService from "../../../services/KoszykService";

const InformacjeUzytkownikComponent = () => {

    return (
        <div id={'InformacjeUzytkownikComponent'}>
            <TopCard heading={'Użytkownik'} hasPadding={true}>
                <h6 className={'heading-label'}>
                    Zalogowany użytkownik
                </h6>
                <h6>
                    {LoginService.getCurrentUser() && (LoginService.getCurrentUser().username? LoginService.getCurrentUser().username : 'Brak loginu')}
                </h6>
                {!KoszykService.isEmpty(LoginService.getCurrentUser().pracownik) &&
                    <>
                        <h6 className={'heading-label'}>
                            Pracownik
                        </h6>
                        <h6>
                            {LoginService.getCurrentUser() && (LoginService.getCurrentUser().pracownik? LoginService.getCurrentUser().pracownik : 'Brak nazwy pracownika')}
                        </h6>
                    </>
                }
                <h6 className={'heading-label'}>
                    Firma / Kontrahent
                </h6>
                <h6>
                    {famix_getFirmaOrKontrahentFromLS()}
                </h6>
                <h6 className={'heading-label'}>
                    Magazyn
                </h6>
                <h6>
                    {LoginService.getCurrentUser() && (LoginService.getCurrentUser().nazwaMagazynu ? LoginService.getCurrentUser().nazwaMagazynu : ' - brak magazynu')}
                </h6>
            </TopCard>
        </div>
    );
};

export default InformacjeUzytkownikComponent;
