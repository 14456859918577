import React, {Component} from 'react';
import LayoutComponent from "../../../sharedComponents/layout/LayoutComponent";
import {API_Admin_Logi} from "../../../_static_data/response";
import AdminLogiTable from "./components/AdminLogiTable";
import TopTableGridSwitcher from "../../../sharedComponents/TopTableGridSwitcher";
import AdminLogiGrid from "./components/AdminLogiGrid";
import {isMobileDevice} from "../../../utilities/rwd/rwdUtilities";

class AdminLogiModule extends Component {

    state = {
        logi: [],
        currentView: true, // table - true, grid - false
    };

    componentDidMount() {
        this.getLogi()
        this.setState({currentView:isMobileDevice()?'grid':'table'});
    }

    getLogi = () => {
        this.setState({logi: API_Admin_Logi()});
    };


    render() {

        let {logi, currentView} = this.state;

        return (
            <LayoutComponent breadcrumbs={[{url: "/admin", name: 'PA'}, {url: "/admin/logi", name: 'Logi'}]} isAdminPanel={true}>
                <div id={'ADMIN_MODULE'}>
                <div id={'ADMIN_LOGI'}>
                    <TopTableGridSwitcher currentView={currentView} quantity={logi.length} handleChangeView={(currentView) => this.setState({currentView})}/>
                    {
                        currentView ?
                            <AdminLogiTable logi={logi}/>
                            :
                            <AdminLogiGrid logi={logi}/>
                    }
                </div>
                </div>
            </LayoutComponent>
        );
    }
}

export default AdminLogiModule;