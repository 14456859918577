import React, {useEffect} from 'react';
import logo from "../resources/images/famixlogo.png";

const Unavailable = (props: any) => {

    useEffect(() => {
        const interval = setInterval(() => {
            window.location.reload();
        }, 180000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className={'loginBody'}>
            <div className={'przerwaTechniczna'} style={{textAlign: 'center', margin: '10vh auto 0'}}>
                <img src={logo} alt={'logo'} style={{maxWidth: '300px'}}/>
                <div className={'loginContainer'} style={{textAlign: 'center', color: 'white'}}>
                    {/*<h2><i className={'fa fa-power-off'} style={{marginRight: 5}}/> System jest wyłączony</h2>*/}
                    <h2 style={{marginTop: '15px'}}><i className={'fa fa-cog fa-spin'}/> Przerwa techniczna</h2>

                    {(props.awaria) ?
                        <h6>Platforma będzie wkrótce znów dostępna. <br/>Przepraszamy za utrudnienia.</h6>
                        :
                        <h6>Dostęp do systemu nie jest możliwy w
                            godzinach <br/> {JSON.parse(props.appData).start_time} - {JSON.parse(props.appData).end_time}
                            <br/>Przepraszamy za utrudnienia.
                        </h6>
                    }
                </div>
            </div>
        </div>
    );
}

export default Unavailable;
