import React from 'react';
import {Link} from "react-router-dom";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import tgrid from "../../../../utilities/table/tgrid";
import TopCard from "../../../../sharedComponents/TopCard";
import {TableActionButtons_EditDelete} from "../../../../sharedComponents/tableComponents/tableButtons";
import {TableImage_WithPreview} from "../../../../sharedComponents/tableComponents/tableImages";
import {
    TableFormatters_formatBoolean,
    TableFormatters_formatMoney,
    TableFormatters_formatPromocja
} from "../../../../sharedComponents/tableComponents/tableFormatters";
import {prettyColumns_AdminProdukty} from "../../../../utilities/exports/prettyColumnToExport";

interface Props {
    data: object[],
    handleClickEdit?: any
    hideActionButtons?: boolean,
    topCardHeading: string
}

const AdminPromocjeProduktyTable: React.FC<Props> = ({data, handleClickEdit, hideActionButtons = false, topCardHeading}) => {

    const formatZdjecieRow = (cell: string, row: any) => {
        return (<TableImage_WithPreview item={row} desc={'Produkt ' + row.nazwa}/>)
    };

    const formatMoneyRow = (cell: number, row: object) => {
        return <TableFormatters_formatMoney value={cell}/>
    };


    const formatPromocjaRow = (cell: any) => {
        if (cell !== null)
            return (<TableFormatters_formatPromocja value={cell}/>);
        return ''
    };

    const formatOpakowanieRow = (cell: any, row: any) => {
        return `${cell} ${row.jednostkaMiara}`
    };

    const formatDostepnoscRow = (cell: boolean) => {
        return <TableFormatters_formatBoolean value={cell} titleTrue={'Produkt jest dostępny'}
                                              titleFalse={'Produkt jest niedostępny'}/>
    };

    const formatNazwaRow = (cell: string, row: any) => {
        return <Link to={row.url} target={"_blank"}>{cell}</Link>
    };

    const formatAkcje = (cell: any, row: any) => {
        return <TableActionButtons_EditDelete handleClickEdit={() => handleClickEdit(row)}
                                              handleClickDelete={() => console.log('delete', row)}/>
    };

    return (
        <div id={'AdminProduktyTable'}>
            <TopCard heading={topCardHeading} hasPadding={false} enableExport={true} dataToExport={data} columnsNames={prettyColumns_AdminProdukty}
            >
                <BootstrapTable data={data} hover={true}>
                    <TableHeaderColumn dataField={'id'}
                                       thStyle={tgrid.t1h}
                                       tdStyle={tgrid.t1h}
                                       dataSort={true}
                                       isKey={true}>ID</TableHeaderColumn>
                    <TableHeaderColumn dataField={'zdjecie'}
                                       thStyle={tgrid.t2}
                                       tdStyle={tgrid.t2}
                                       dataSort={true}
                                       dataFormat={formatZdjecieRow}
                    >Zdjęcie</TableHeaderColumn>
                    <TableHeaderColumn dataField={'indeksTop'}
                                       thStyle={tgrid.t2h}
                                       tdStyle={tgrid.t2h}
                                       dataSort={true}
                    >Indeks</TableHeaderColumn>
                    <TableHeaderColumn dataField={'producent'}
                                       thStyle={tgrid.t2h}
                                       tdStyle={tgrid.t2h}
                                       dataSort={true}
                    >Producent</TableHeaderColumn>
                    <TableHeaderColumn dataField={'nazwa'}
                                       thStyle={tgrid.t6}
                                       tdStyle={tgrid.t6}
                                       dataSort={true}
                                       dataFormat={formatNazwaRow}
                    >Nazwa</TableHeaderColumn>
                    <TableHeaderColumn dataField={'iloscOpakowanie'}
                                       thStyle={tgrid.t1h}
                                       tdStyle={tgrid.t1h}
                                       dataFormat={formatOpakowanieRow}
                                       dataSort={true}
                    >Ile</TableHeaderColumn>
                    <TableHeaderColumn dataField={'opakowanieMiara'}
                                       thStyle={tgrid.t1}
                                       tdStyle={tgrid.t1}
                                       dataSort={true}
                    >Opak.</TableHeaderColumn>
                    <TableHeaderColumn dataField={'cenaKatalogowa'}
                                       thStyle={tgrid.t2}
                                       tdStyle={tgrid.t2}
                                       dataSort={true}
                                       dataFormat={formatMoneyRow}
                    >Cena kat.</TableHeaderColumn>
                    <TableHeaderColumn dataField={'cena'}
                                       thStyle={tgrid.t2}
                                       tdStyle={tgrid.t2}
                                       dataSort={true}
                                       dataFormat={formatMoneyRow}
                    >Cena</TableHeaderColumn>
                    <TableHeaderColumn dataField={'promocja'}
                                       thStyle={tgrid.t1}
                                       tdStyle={tgrid.t1}
                                       dataFormat={formatPromocjaRow}
                                       dataSort={true}
                    >Prom.</TableHeaderColumn>
                    <TableHeaderColumn dataField={'czyDostepny'}
                                       thStyle={tgrid.t1}
                                       tdStyle={tgrid.t1}
                                       dataSort={true}
                                       dataFormat={formatDostepnoscRow}
                    >Dost.</TableHeaderColumn>
                    <TableHeaderColumn dataField={'id'}
                                       thStyle={tgrid.t2}
                                       tdStyle={tgrid.t2}
                                       dataFormat={formatAkcje}
                                       hidden={hideActionButtons}
                    >Akcje</TableHeaderColumn>
                </BootstrapTable>
            </TopCard>
        </div>
    );
};

export default AdminPromocjeProduktyTable;