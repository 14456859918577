import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCog,
    faUser,
    faPowerOff,
    faBars,
    faHome,
    faEnvelope,
    faPhone,
    faCalendarAlt,
    faSearch,
    faFilter,
    faPrint,
    faList,
    faTimes,
    faExclamationTriangle,
    faPaperPlane,
    faTable,
    faTh,
    faCertificate,
    faCheck,
    faShoppingCart,
    faCartPlus,
    faExternalLinkAlt,
    faUserPlus,
    faSignInAlt,
    faEdit,
    faTrash,
    faPlus,
    faParking,
    faSave,
    faUserLock,
    faLockOpen,
    faLock,
    faToggleOn,
    faToggleOff,
    faFileCsv,
    faFilePdf,
    faInfoCircle,
    faHighlighter,
    faImages,
    faFileUpload,
    faFileImport,
    faEye,
    faEyeSlash,
    faFileAlt,
    faBriefcase,
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faFolderMinus,
    faMinusSquare,
    faCheckCircle,
    faExclamationCircle,
    faCopyright,
    faCircle,
    faCircleNotch,
    faSpinner,
    faSyncAlt,
    faCopy,
    faFileExport,
    faBell,
    faKey,
    faCamera,
    faCaretSquareLeft,
    faEraser, faPlusSquare,
    faTimesCircle,
    faDownload,
    faServer,
    faFileExcel
} from "@fortawesome/free-solid-svg-icons";

export const IconHome = () => <FontAwesomeIcon icon={faHome}/>
export const IconUser = () => <FontAwesomeIcon icon={faUser}/>
export const IconBriefcase = () => <FontAwesomeIcon icon={faBriefcase}/>
export const IconSettings = () => <FontAwesomeIcon icon={faCog}/>
export const IconCart = () => <FontAwesomeIcon icon={faShoppingCart}/>
export const IconCartPlus = () => <FontAwesomeIcon icon={faCartPlus}/>
export const IconLogout = () => <FontAwesomeIcon icon={faPowerOff}/>
export const IconBars = () => <FontAwesomeIcon icon={faBars}/>
export const IconEnvelope = () => <FontAwesomeIcon icon={faEnvelope}/>
export const IconKey= () => <FontAwesomeIcon icon={faKey}/>
export const IconPhone = () => <FontAwesomeIcon icon={faPhone}/>
export const IconCalendar = () => <FontAwesomeIcon icon={faCalendarAlt}/>
export const IconSearch = () => <FontAwesomeIcon icon={faSearch}/>
export const IconFilter = () => <FontAwesomeIcon icon={faFilter}/>
export const IconList = () => <FontAwesomeIcon icon={faList}/>
export const IconPrint = () => <FontAwesomeIcon icon={faPrint}/>
export const IconNotification = () => <FontAwesomeIcon icon={faBell}/>
export const IconTimes = () => <FontAwesomeIcon icon={faTimes}/>
export const IconCheck = () => <FontAwesomeIcon icon={faCheck}/>
export const IconCheckCircle = () => <FontAwesomeIcon icon={faCheckCircle}/>
export const IconExclamation = () => <FontAwesomeIcon icon={faExclamationCircle}/>
export const IconSend = () => <FontAwesomeIcon icon={faPaperPlane}/>
export const IconCamera = () => <FontAwesomeIcon icon={faCamera}/>
export const IconClear = () => <FontAwesomeIcon icon={faEraser}/>
export const IconTable = () => <FontAwesomeIcon icon={faTable}/>
export const IconGrid = () => <FontAwesomeIcon icon={faTh}/>
export const IconMultistar = () => <FontAwesomeIcon icon={faCertificate}/>
export const IconExternalLink = () => <FontAwesomeIcon icon={faExternalLinkAlt}/>
export const IconUserPlus = () => <FontAwesomeIcon icon={faUserPlus}/>
export const IconUserSignIn = () => <FontAwesomeIcon icon={faSignInAlt}/>
export const IconEdit = () => <FontAwesomeIcon icon={faEdit}/>
export const IconTrash = () => <FontAwesomeIcon icon={faTrash}/>
export const IconAdd = () => <FontAwesomeIcon icon={faPlus}/>
export const IconPromo = () => <FontAwesomeIcon icon={faParking}/>
export const IconSave = () => <FontAwesomeIcon icon={faSave}/>
export const IconLockUser = () => <FontAwesomeIcon icon={faUserLock}/>
export const IconLockOpen = () => <FontAwesomeIcon icon={faLockOpen}/>
export const IconLock = () => <FontAwesomeIcon icon={faLock}/>
export const IconFileCSV = () => <FontAwesomeIcon icon={faFileCsv}/>
export const IconInfo = () => <FontAwesomeIcon icon={faInfoCircle}/>
export const IconToggleOn = () => <FontAwesomeIcon icon={faToggleOn}/>
export const IconToggleOff = () => <FontAwesomeIcon icon={faToggleOff}/>
export const IconReklamacja = () => <FontAwesomeIcon icon={faHighlighter}/>
export const IconImages = () => <FontAwesomeIcon icon={faImages}/>
export const IconUploadFile = () => <FontAwesomeIcon icon={faFileUpload}/>
export const IconImportFile = () => <FontAwesomeIcon icon={faFileImport}/>
export const IconExportFile = () => <FontAwesomeIcon icon={faFileExport}/>
export const IconEye = () => <FontAwesomeIcon icon={faEye}/>
export const IconEyeSlash = () => <FontAwesomeIcon icon={faEyeSlash}/>
export const IconFileTxt = () => <FontAwesomeIcon icon={faFileAlt}/>
export const IconArrowDoubleLeft = () => <FontAwesomeIcon icon={faAngleDoubleLeft}/>
export const IconArrowDoubleRight = () => <FontAwesomeIcon icon={faAngleDoubleRight}/>
export const IconMinusSquare = () => <FontAwesomeIcon icon={faMinusSquare}/>
export const IconBack = () => <FontAwesomeIcon icon={faCaretSquareLeft}/>
export const IconCircle = () => <FontAwesomeIcon icon={faCircle}/>
export const IconCircleEmpty = () => <FontAwesomeIcon icon={faCircleNotch}/>
export const IconRefresh = () => <FontAwesomeIcon icon={faSyncAlt}/>
export const IconCopy = () => <FontAwesomeIcon icon={faCopy}/>
export const IconLoading = () => <FontAwesomeIcon icon={faSpinner} spin={true}/>
export const IconPlus = () => <FontAwesomeIcon icon={faPlusSquare} spin={false}/>
export const IconMinus = () => <FontAwesomeIcon icon={faMinusSquare} spin={false}/>
export const IconWarning = () => <FontAwesomeIcon icon={faExclamationTriangle} spin={false}/>
export const IconTimesCircle = () => <FontAwesomeIcon icon={faTimesCircle}/>
export const IconExportPdf = () => <FontAwesomeIcon icon={faFilePdf}/>
export const IconDownload = () => <FontAwesomeIcon icon={faDownload}/>
export const IconServer = () => <FontAwesomeIcon icon={faServer}/>
export const IconExcel = () => <FontAwesomeIcon icon={faFileExcel}/>
