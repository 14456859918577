import React from 'react';
import {IconLoading} from "../icons/FontAwesome";

interface Props {
    isBig?: boolean,
    isCentered?: boolean,
    loadingText?: string
}

const TopLoaderComponent: React.FC<Props> = ({loadingText = "Trwa ładowanie...", isBig = true, isCentered = true}) => {

    let classes = 'famix-loader';
    if (isBig)
        classes += ' big';
    if (isCentered)
        classes += ' centered';

    return (
        <div className={classes}>
            <div>
                <IconLoading/>
            </div>
            <div>
                {loadingText}
            </div>
        </div>
    );
};

export default TopLoaderComponent;