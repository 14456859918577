import React from 'react'
import LoginService from "../../services/LoginService";

export const famix_getFirmaOrKontrahentFromLS = () =>{
    let name:any = '-';

    let currentUser = LoginService.getCurrentUser();
    if(currentUser){
        if(Boolean(currentUser.firma) && Boolean(currentUser.firma2)){
            name = <span style={{lineHeight: 1.5}}>{currentUser.kontrahent}<div>{currentUser.firma} {currentUser.firma2}</div><div>{currentUser.adres1}</div><div>{currentUser.adres2}</div><div>NIP: {currentUser.nrIdent}</div></span>
        }
        else if(Boolean(currentUser.firma)){
            name = currentUser.firma
        }
        else if(Boolean(currentUser.kontrahent)){
            name = currentUser.kontrahent
        }
    }
    return name;
};

