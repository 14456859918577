import React from 'react';
import {TextAlert} from "../typographyComponents/TextAlert/TextAlert";
import TopLoaderComponent from "./TopLoaderComponent";

interface Props {
    children: any,
    isLoading: boolean,
    isError: boolean,
    errorMessage?: any,
    isBig?: boolean,
    isCentered?: boolean,
    loadingText?: string
}

const TopLoading: React.FC<Props> = ({children, isLoading, isError, errorMessage = 'Wystąpił błąd z pobraniem danych', loadingText = "Trwa ładowanie...", isBig = true, isCentered = true}) => {
    return (
        <div>
            {
                isLoading ?
                    <TopLoaderComponent loadingText={loadingText} isBig={isBig} isCentered={isCentered}/>
                    :
                    isError ?
                        <TextAlert type={'danger'}>{errorMessage}</TextAlert>
                        :
                        children
            }
        </div>
    );
};

export default TopLoading;