import React, {Component} from 'react';
import {Button, Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import okladkaDefault from '../../../resources/images/prombrakzdj4.png';
// import okladkaDefault from '../../../resources/images/prombrakzdj.png';
// import okladkaDefault from '../../../resources/images/promocjadefault.png';
//@ts-ignore
import {LazyLoadImage} from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import PromocjeService from "../../../services/PromocjeService";
import {displayNotificationError} from "../../../utilities/notifications/NotificationsManager";
import TopCard from "../../../sharedComponents/TopCard";
import PromocjaModuleBody from "../../promocja/PromocjaModuleBody";
import LayoutComponent from "../../../sharedComponents/layout/LayoutComponent";
import logo from "../../../resources/images/famixlogos.png";
import TopLoading from "../../../sharedComponents/loader/TopLoading";
import PopupWrapper from "../../../sharedComponents/popups/PopupWrapper";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import IconPromocja from "../../oferta/components/IconPromocja";
import TopTableGridSwitcher from "../../../sharedComponents/TopTableGridSwitcher";
import {changeSortowaniePromocje, changeSortowanieReklamacje, isEmpty} from "../../../utilities/arrays/arraysUtilities";
import KoszykService from "../../../services/KoszykService";
// @ts-ignore
import overlayFactory from 'react-bootstrap-table2-overlay';
import PromocjaGazetkaContainer from "../../promocja/gazetkaComponents/PromocjaGazetkaContainer";
import PromocjaHeader from "../../promocja/PromocjaHeader";

interface MatchParams {
    path: string;
}

export interface RouteComponentProps<MatchParams> {
    match: any;
}

interface Props extends RouteComponentProps<MatchParams> {
}

interface Props {
    // promocje: any
}

class PromocjeGrid extends Component<Props> {

    state={
        prom: '',
        promocje: [],
        isRequestLoading: true,
        isRequestError: false,
        showPromocjaPopup: false,
        nazwaPromocji: '',
        idPromocji: null,
        shouldKoszykUpdate: false,
        currentView: true,
        sort: [{kierunek: "asc", kolumna: "cast(NAZWA%20as%20varchar(60)%20character%20set%20win1250)%20collate%20PXW_PLK%20 "}],
        loading: false,
        selectedGazetka: null,
        stronyGazetki: {},
        czyEdycja: false,
        loadingStrony: false,
        sezon: null
        // pageableObject: {
        //     currentPage: 0,
        //     currentSize: 0,
        //     totalElements: 0
        // },
    }

    componentDidMount() {
        if (Boolean(this.props.match))
            if (Boolean(this.props.match.params))
                if (Boolean(this.props.match.params.id))
                {
                    this.setState({prom: this.props.match.params.id}, () => this.onClickProm(this.state.prom))
                }
    }

    generateSortString = (): string => {
        let sortowanie = this.state.sort;
        let sortString = '';

        for (let i=0; sortowanie.length > i; i++)
        {
            if(!KoszykService.isEmpty(sortowanie[i].kolumna))
            // @ts-ignore
            sortString = sortString + '&sort=' + sortowanie[i].kolumna + ',' + sortowanie[i].kierunek;
        }

        return sortString;

    }

    onClickProm = (prom: string) => {
        this.setState({
            // isRequestLoading: true,
            isRequestError: false
        });

        PromocjeService.getPromocjeNew(prom, this.generateSortString()).then((response) => {
            this.setState({promocje: response.data, isRequestLoading: false, isRequestError: false, loading: false});

            // jesli jedna promocja to przejdz do niej
            if(response.data.length === 1){
                if(prom==='PRSEZ'){
                    this.setState({showPromocjaPopup: true, idPromocji: null, sezon: response.data[0].grupa, nazwaPromocji: response.data[0].nazwa});
                } else {
                    if(response.data[0].rodzaj === 'GAZET')
                    {
                        this.setState({selectedGazetka: response.data[0]}, () => this.pobierzStrGazetki());
                    } else {
                        this.setState({showPromocjaPopup: true, idPromocji: response.data[0].idUm});
                    }
                }
            }

        }).catch((e) => {
            displayNotificationError('Błąd pobrania promocji', '');
            this.setState({isRequestLoading: false, isRequestError: true, loading: false});
        })
    }

    pobierzStrGazetki = () => {
        this.setState({loadingStrony: true});

        let dane = {
            // @ts-ignore
            idGaz: this.state.selectedGazetka.idGaz,
            // @ts-ignore
            ileStron: this.state.selectedGazetka.ileStron
        }

        PromocjeService.getStronyGazetki(dane).then((response) => {
            this.setState({stronyGazetki: response.data, loadingStrony: false});
        }).catch((e) => {
            this.setState({loadingStrony: false});
            displayNotificationError('Błąd', '');
        })
    }

    akcjaFormat = (cell: any, row: any) => {
        if(this.state.prom === 'PRSEZ'){
            return <div className={'promButton'}>
                <Button onClick={() => this.setState({showPromocjaPopup: true, sezon: cell, nazwaPromocji: row.nazwa})} size={'sm'}>PRZEJDŹ</Button>
            </div>
        } else {
            if(row.rodzaj === 'GAZET'){
                return <div className={'promButton'}>
                    <Button onClick={() => this.setState({selectedGazetka: row}, () => this.pobierzStrGazetki())} size={'sm'}>PRZEJDŹ</Button>
                </div>
            } else {
                return <div className={'promButton'}>
                    <Button onClick={() => this.setState({showPromocjaPopup: true, idPromocji: cell})} size={'sm'}>PRZEJDŹ</Button>
                </div>
            }
        }
    }

    renderTyp = (): string => {
        let {prom} = this.state;

        switch (prom) {
            case 'AG':
                return 'Aktualna gazetka';
            case 'CPM':
                return 'Ceny promocyjne na miesiąc';
            case 'MPT':
                return 'Promocje MPT';
            case 'NOW':
                return 'Nowości';
            case 'PDK':
                return 'Promocje dedykowane dla klienta';
            case 'PLSKL':
                return 'Plakat sklepowy';
            case 'PRSEZ':
                return 'Produkty sezonowe';
            case 'PT':
                return 'Promocje tygodniowe';
            case 'WYP':
                return 'Wyprzedaże';
        }

        return 'Promocje'
    }

    // onChangeSizeInTable = (size: number) => {
    //     this.setState({
    //         loading: true,
    //         size
    //     }, () => this.pobierzReklamacje())
    // };
    //
    // onChangePageInTable = (page: number, size: number) => {
    //     this.setState({
    //         page: page - 1,
    //         loading: true,
    //         size: size
    //     }, () => this.pobierzReklamacje())
    // };

    typFormat = (cell: any, row: any) => {
        if(row.rodzaj === 'GAZET'){
            return <div className={'typ'}><h6>Gazetka</h6></div>
        } else {
            return <div className={'typ'}><IconPromocja typ={row.ozn}/><h6> {PromocjeService.getLabel(cell)}</h6></div>
        }
    }

    onChangeSortowanie = (sort: any) => {

        this.setState({sort: sort, page: 0, loading: true}, () => this.onClickProm(this.state.prom));

    };

    onSortChange = (sortName: any, sortOrder: any) => {

        // @ts-ignore
        this.onChangeSortowanie(changeSortowaniePromocje(this.state.sort, sortName));

    }

    ikonaSort = (typ: string) => {
        let {sort} = this.state;
        let index = -1;

        for (let i = 0; sort.length > i; i++)
        {
            if (sort[i].kolumna === typ)
            {
                index = i;
                break;
            }
        }

        if (index !== -1)
        {
            if (sort[index].kierunek === "asc")
            {
                return <i className="fa fa-sort-asc" />
            } else
                return <i className="fa fa-sort-desc" />
        } else
            return <i className="fa fa-sort" style={{color: '#aeaeae'}}/>

    }

    columns = [{
        dataField: 'idUm',
        text: 'Id',
        align: 'center',
        headerFormatter: () => {return <>Id &nbsp;{this.ikonaSort('id_um')}</>},
        headerStyle: {cursor: 'pointer'},
        onSort: this.onSortChange,
        sort: true
        // hidden: true
    }, {
        dataField: 'rodzaj',
        text: 'Typ promocji',
        formatter: this.typFormat,
        headerFormatter: () => {return <>Typ promocji &nbsp;{this.ikonaSort('rodzaj')}</>},
        headerStyle: {cursor: 'pointer'},
        onSort: this.onSortChange,
        sort: true
    }, {
        dataField: 'nazwa',
        text: 'Nazwa',
        headerFormatter: () => {return <>Nazwa &nbsp;{this.ikonaSort('cast(NAZWA%20as%20varchar(60)%20character%20set%20win1250)%20collate%20PXW_PLK%20 ')}</>},
        headerStyle: {cursor: 'pointer'},
        onSort: this.onSortChange,
        sort: true
    }, {
        dataField: 'opisWww',
        text: 'Opis',
        headerFormatter: () => {return <>Opis &nbsp;{this.ikonaSort('cast(OPIS%20as%20varchar(100)%20character%20set%20win1250)%20collate%20PXW_PLK%20 ')}</>},
        headerStyle: {cursor: 'pointer'},
        onSort: this.onSortChange,
        sort: true
    }, {
        dataField: 'dataOd',
        text: 'Data od',
        align: 'center',
        style: {width: '80px'},
        headerFormatter: () => {return <>Data od &nbsp;{this.ikonaSort('data_od')}</>},
        headerStyle: {cursor: 'pointer'},
        onSort: this.onSortChange,
        sort: true
    }, {
        dataField: 'dataDo',
        text: 'Data do',
        align: 'center',
        style: {width: '80px'},
        headerFormatter: () => {return <>Data do &nbsp;{this.ikonaSort('data_do')}</>},
        headerStyle: {cursor: 'pointer'},
        onSort: this.onSortChange,
        sort: true
    }, {
        dataField: 'idUm',
        text: 'Akcja',
        align: 'center',
        style: {width: '80px'},
        formatter: this.akcjaFormat
    }];

    columnsSezony = [{
        dataField: 'grupa',
        text: 'Grupa'
    }, {
        dataField: 'nazwa',
        text: 'Nazwa'
    }, {
        dataField: 'dataOd',
        text: 'Data od',
        align: 'center',
        style: {width: '80px'}
    }, {
        dataField: 'dataDo',
        text: 'Data do',
        align: 'center',
        style: {width: '80px'},
    }, {
        dataField: 'grupa',
        text: 'Akcja',
        align: 'center',
        style: {width: '80px'},
        formatter: this.akcjaFormat
    }];

    render() {

        let {promocje, isRequestError, czyEdycja, isRequestLoading, sezon, showPromocjaPopup, nazwaPromocji, idPromocji, shouldKoszykUpdate, prom, currentView, selectedGazetka, stronyGazetki, loadingStrony} = this.state;
        const breadcrumbs = [{name: 'Promocje', url: '/promocje/prom'}]

        return (
            <LayoutComponent breadcrumbs={breadcrumbs}
                             shouldKoszykUpdate={shouldKoszykUpdate}
                             handleShouldKoszykUpdateChange={() => this.setState({shouldKoszykUpdate: false})}
                             handleChangeSiec={() => this.onClickProm(this.state.prom)}
                             ustawCzyEdycja={(czyEdycja) => this.setState({czyEdycja: czyEdycja})}
            >

                <Row style={{marginTop: '15px'}}>
                    <Col xs={1} sm={2} md={2} lg={1} xl={1} style={{padding: '0'}} className={'align-items-center'}>
                        <img src={logo} alt={'prom'} style={{width: '11vmin'}}/>
                    </Col>
                    <Col xs={9} sm={9} md={9} lg={10} xl={10} className={'my-auto'}>
                        <Row>
                            <Col className={'my-auto'}>
                                <h1 style={{fontSize: '5vmin', color: '#383666'}}>{this.renderTyp()}</h1>
                            </Col>
                            <Col className={'my-auto'}>
                                {(selectedGazetka) &&
                                <PromocjaHeader promocja={selectedGazetka}/>
                                }
                            </Col>
                        </Row>


                    </Col>
                    <Col xs={1} sm={1} md={1} lg={1} xl={1} style={{textAlign: 'right'}}>
                        <h1 style={{fontSize: '9vmin', color: '#F9BA00'}}>%</h1>
                    </Col>
                </Row>

                <Link to={'/promocje'}>
                    <Button variant={'primary'}><i className="fa fa-arrow-left" /> Wróć do promocji</Button>
                </Link>

                <hr/>

                <div id={'PROMOCJE_MODULE'}>
                <div id={'PromocjeGrid'}>
                    <TopLoading isLoading={isRequestLoading || loadingStrony} isError={isRequestError}>
                    {
                        promocje.length > 0 ?
                            // prom === 'CPM'?
                            (KoszykService.isEmpty(selectedGazetka))?
                            <>
                                {(prom !== 'PRSEZ')?
                                    <div>
                                        <div style={{display: 'inline-block', width: '100%'}}>
                                            <TopTableGridSwitcher currentView={currentView}
                                                                  handleChangeView={() => this.setState({currentView: !currentView})}/>
                                        </div>
                                        {currentView?
                                            <BootstrapTable remote keyField='id' data={ promocje } columns={ this.columns } hover noDataIndication={'Brak pasujących wyników'}
                                                            headerClasses={'headerbs'}
                                                            loading={this.state.loading}
                                                            overlay={ overlayFactory({ spinner: true, styles: { overlay: (base: any) => ({...base, background: 'rgba(96,93,141,0.3)'}) } }) }
                                            />
                                            :
                                            <Row>
                                                {
                                                    promocje.map((promocja: any) => (
                                                        <Col sm={6} md={4} lg={3} xl={3}>
                                                            {/*<Link to={'/promocje/' + promocja.idUm} className={'promocja-container'}>*/}
                                                            <div className={'promocja-container'} onClick={() => {
                                                                if(promocja.rodzaj === 'GAZET')
                                                                {
                                                                    this.setState({selectedGazetka: promocja}, () => this.pobierzStrGazetki());
                                                                } else {
                                                                    this.setState({
                                                                        showPromocjaPopup: true,
                                                                        idPromocji: promocja.idUm
                                                                    });
                                                                }
                                                            }}>
                                                                <div className={'promocja-image'}>
                                                                    <LazyLoadImage src={
                                                                        (promocja.gazImg) ? promocja.gazImg : okladkaDefault
                                                                    } alt={'Okładka promocji'} effect={'blur'}/>
                                                                </div>
                                                                <div className={'promocja-description'}>
                                                                    <h3>
                                                                        {promocja.nazwa.substring(0,50)} {promocja.nazwa.length>50 &&'...'}
                                                                    </h3>
                                                                    <h6>
                                                                        {promocja.dataOd} - {promocja.dataDo}
                                                                    </h6>
                                                                </div>
                                                                {/*</Link>*/}
                                                            </div>
                                                        </Col>
                                                    ))
                                                }
                                            </Row>
                                        }
                                    </div>
                                    :
                                    <BootstrapTable remote keyField='id' data={ promocje } columns={ this.columnsSezony } hover noDataIndication={'Brak pasujących wyników'}
                                                    headerClasses={'headerbs'}
                                                    loading={this.state.loading}
                                                    overlay={ overlayFactory({ spinner: true, styles: { overlay: (base: any) => ({...base, background: 'rgba(96,93,141,0.3)'}) } }) }
                                    />
                                }


                            </>
                            :
                            <>
                                <PromocjaGazetkaContainer strony={stronyGazetki} gazetka={selectedGazetka} czyEdycja={this.state.czyEdycja}
                                                          shouldKoszykUpdate={() => this.setState({shouldKoszykUpdate: true})}/>
                            </>
                            :
                            <div>Brak obecnie dostępnych promocji</div>
                    }
                    </TopLoading>
                </div>
                </div>

                {
                    showPromocjaPopup &&
                    <PopupWrapper header={`Promocja: `+nazwaPromocji} onClose={() => this.setState({showPromocjaPopup: false, nazwaPromocji: ''})} classname={'POPUP_WRAPPER_OLD'}>
                        <PromocjaModuleBody czyEdycja={czyEdycja} czyZOferty={false} czyZLinku={false} sezon={sezon} idUm={idPromocji} odswierzKoszyk={() => this.setState({shouldKoszykUpdate: true})} ustawNazwePromocji={(nazwa) => this.setState({nazwaPromocji: nazwa})}/>
                    </PopupWrapper>
                }

            </LayoutComponent>
        );
    }
}

export default PromocjeGrid;