import React from 'react';
import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {IconExternalLink} from "../../../sharedComponents/icons/FontAwesome";
import {isMobileDevice} from "../../../utilities/rwd/rwdUtilities";
import {formatMoney} from "../../../utilities/formatters/formatMoney";
import FakturyPodsumowanie from "./FakturyPodsumowanie";
import {TextAlert} from "../../../sharedComponents/typographyComponents/TextAlert/TextAlert";

interface Props {
    pozycje: any
}

const FakturyPozycjePopup: React.FC<Props> = ({pozycje}) => {
    return (
        <div id={'FakturyPozycjePopup'}>
            {
                pozycje.length > 0 &&
                <div className={'pozycja-row'}>
                    <Row>
                        <Col md={1} lg={1} xl={1}>
                            Pozycja:
                        </Col>
                        <Col md={5} lg={5} xl={5}>
                            Informacje o produkcie:
                        </Col>
                        <Col md={2} lg={2} xl={2}>
                            Cena jednostki:
                        </Col>
                        <Col md={2} lg={2} xl={2}>
                            Ilość:
                        </Col>
                        <Col md={2} lg={2} xl={2}>
                            Wartość:
                        </Col>
                    </Row>
                </div>
            }
            {
                pozycje.map((pozycja: any) => (
                    <div className={'pozycja-row'}>
                        <Row>
                            <Col md={1} lg={1} xl={1}>
                                # {pozycja.poz}
                            </Col>
                            <Col md={5} lg={5} xl={5}>
                                <div>
                                    <Link to={'/oferta/'+pozycja.indeks} target={'_blank'}>
                                        {pozycja.indeks} <IconExternalLink/>
                                    </Link>
                                </div>
                                <h6>
                                    {pozycja.producent}, {pozycja.nazwa}
                                </h6>
                            </Col>
                            <Col md={2} lg={2} xl={2}>
                                <div className={'bigger-lineheight'}>
                                    {
                                        isMobileDevice() &&
                                        <label>Cena:</label>
                                    }
                                    <span><span className={'text-bold'}>{formatMoney(pozycja.cena)}</span>/{pozycja.jm}</span>
                                </div>
                            </Col>
                            <Col md={2} lg={2} xl={2}>
                                <div className={'bigger-lineheight'}>
                                    {
                                        isMobileDevice() &&
                                        <label>Ilość:</label>
                                    }
                                    <span>{pozycja.ilosc} {pozycja.jm}</span>
                                </div>
                            </Col>
                            <Col md={2} lg={2} xl={2}>
                                <div className={'bigger-lineheight money-format'}>
                                    <label>
                                        {
                                            isMobileDevice() && 'Wartość '
                                        }
                                        netto:
                                    </label>
                                    <span>
                                           {formatMoney(pozycja.wartosc)}
                                    </span>
                                </div>
                            </Col>
                        </Row>
                    </div>
                ))
            }
            {
                pozycje.length === 0 &&
                    <TextAlert type={'info'}>Brak pozycji na fakturze</TextAlert>
            }
        </div>
    );
};

export default FakturyPozycjePopup;