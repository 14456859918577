import React from 'react';
import {formatMoney} from "../../../../utilities/formatters/formatMoney";
import FakturyService from "../../../../services/FakturyService";
import BootstrapTable from "react-bootstrap-table-next";
import {TextAlert} from "../../../../sharedComponents/typographyComponents/TextAlert/TextAlert";
import FakturyTableExpandablePozycjeTable from "./FakturyTableExpandablePozycjeTable";
import TopLoading from "../../../../sharedComponents/loader/TopLoading";
import paginationFactory from "react-bootstrap-table2-paginator";
// @ts-ignore
import overlayFactory from 'react-bootstrap-table2-overlay';
import {_omit} from "../../../../utilities/lodash/lodashUtilities";
import {
    changeSortowanie,
    changeSortowaniePozycjeFaktury,
    ikonaSort
} from '../../../../utilities/arrays/arraysUtilities';

interface Props {
    row: object[]
}

interface State {
    currentFaktura: any,
    currentPozycje: object[],
    isLoadingPozycje: boolean,
    isErrorPozycje: boolean,
    expanded: object[],
    page: number,
    size: number,
    pageableObject: any,
    sort: any,
    loading: boolean
}

class FakturyExpand extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
        currentFaktura: this.props.row,
        currentPozycje: [],
        isLoadingPozycje: false,
        isErrorPozycje: false,
        expanded: [],
        loading: false,
        page: 0,
        size: 10,
        sort: [{kierunek: "asc", kolumna: "poz"}],
        pageableObject: {
            currentPage: 0,
            currentSize: 0,
            totalElements: 0
        }
    }};

    componentDidMount() {
        // this.pobierzPozycje(this.props.row);
        this.pobierzPozycje2();
    }

    pobierzPozycje = (row: any) => {
        this.setState({
            currentFaktura: row,
            isLoadingPozycje: true,
            isErrorPozycje: false,
        });

        let data = {
            data: row.dataWys,
            numer: row.numer,
            symbol: row.symbol
        }

        FakturyService.getPozycjeFakturyNew(data).then((response) => {
            this.setState({
                                currentPozycje: response.data,
                                isLoadingPozycje: false
                            });
        }).catch((e) => {
            this.setState({isLoadingPozycje: false, isErrorPozycje: true});
        })

        // FakturyService.getPozycjeFaktury({ "data": row.dataWys, "podstawa": row.podstawa })
        //     .then((response: any) => {
        //         if (response && response.status < 300) {
        //             this.setState({
        //                 currentPozycje: response.data,
        //                 isLoadingPozycje: false
        //             })
        //         } else {
        //             this.setState({isLoadingPozycje: false, isErrorPozycje: true})
        //         }
        //     })
        //     .catch((error: any) => {
        //         this.setState({isLoadingPozycje: false, isErrorPozycje: true})
        //     })

    };

    generateSortString = (): string => {
        let sortowanie = this.state.sort;
        let sortString = '';

        for (let i=0; sortowanie.length > i; i++)
        {
            // @ts-ignore
            sortString = sortString + '&sort=' + sortowanie[i].kolumna + ',' + sortowanie[i].kierunek;
        }

        return sortString;

    }

    pobierzPozycje2 = (page = this.state.page, size = this.state.size) => {
        // this.setState({
        //     currentFaktura: row,
        //     isLoadingPozycje: true,
        //     isErrorPozycje: false,
        // });

        let data = {
            data: this.state.currentFaktura.dataWys,
            numer: this.state.currentFaktura.numer,
            symbol: this.state.currentFaktura.symbol
        }

        FakturyService.getPozycjeFakturyNew2(page, size, data, this.generateSortString()).then((response) => {
            this.setState({
                currentPozycje: response.data.content,
                pageableObject: Object.assign({}, _omit(response.data, "content"), {currentPage: page}, {currentSize: size}),
                loading: false,
                isLoadingPozycje: false
            });
        }).catch((e) => {
            this.setState({isLoadingPozycje: false, isErrorPozycje: true, loading: false});
        })

        // FakturyService.getPozycjeFaktury({ "data": row.dataWys, "podstawa": row.podstawa })
        //     .then((response: any) => {
        //         if (response && response.status < 300) {
        //             this.setState({
        //                 currentPozycje: response.data,
        //                 isLoadingPozycje: false
        //             })
        //         } else {
        //             this.setState({isLoadingPozycje: false, isErrorPozycje: true})
        //         }
        //     })
        //     .catch((error: any) => {
        //         this.setState({isLoadingPozycje: false, isErrorPozycje: true})
        //     })

    };

    formatMoneyRow = (cell: number) => {
        return <div className={"text-right"}>{formatMoney(cell)}</div>
    };

    handleChangePage = (page: number, size: number) => {
        this.setState({
            page: page - 1,
            loading: true,
            size: size
        }, () => this.pobierzPozycje2())
    };

    onSortChange = (sortName: any, sortOrder: any) => {

        // @ts-ignore
        this.onChangeSortowanie(changeSortowaniePozycjeFaktury(this.state.sort, sortName));

    }

    onChangeSortowanie = (sort: any) => {

        this.setState({sort: sort, page: 0, loading: true}, () => this.pobierzPozycje2());

    };

    columns = [{
        dataField: 'poz',
        text: 'Poz.',
        headerFormatter: () => {return <>Poz. &nbsp;{ikonaSort('poz', this.state.sort)}</>},
        headerStyle: {cursor: 'pointer'},
        onSort: this.onSortChange,
        sort: true
    }, {
        dataField: 'indeks',
        text: 'Indeks',
        align: 'center',
        headerFormatter: () => {return <>Indeks &nbsp;{ikonaSort('g.indeks', this.state.sort)}</>},
        headerStyle: {cursor: 'pointer'},
        onSort: this.onSortChange,
        sort: true
    }, {
        dataField: 'producent',
        text: 'Producent',
        align: 'center',
        headerFormatter: () => {return <>Producent &nbsp;{ikonaSort('producent', this.state.sort)}</>},
        headerStyle: {cursor: 'pointer'},
        onSort: this.onSortChange,
        sort: true
    }, {
        dataField: 'nazwa',
        text: 'Nazwa',
        headerFormatter: () => {return <>Nazwa &nbsp;{ikonaSort('g.nazwa', this.state.sort)}</>},
        headerStyle: {cursor: 'pointer'},
        onSort: this.onSortChange,
        sort: true
    }, {
        dataField: 'cena',
        text: 'Cena',
        formatter: this.formatMoneyRow,
        headerFormatter: () => {return <>Cena &nbsp;{ikonaSort('g.cena', this.state.sort)}</>},
        headerStyle: {cursor: 'pointer'},
        onSort: this.onSortChange,
        sort: true
    }, {
        dataField: 'ilosc',
        text: 'Ilość',
        align: 'center',
        headerFormatter: () => {return <>Ilość &nbsp;{ikonaSort('ilosc', this.state.sort)}</>},
        headerStyle: {cursor: 'pointer'},
        onSort: this.onSortChange,
        sort: true
    }, {
        dataField: 'jm',
        text: 'JM',
        headerFormatter: () => {return <>JM &nbsp;{ikonaSort('g.jm', this.state.sort)}</>},
        headerStyle: {cursor: 'pointer'},
        onSort: this.onSortChange,
        sort: true
    }, {
        dataField: 'wartosc',
        text: 'Wartość',
        formatter: this.formatMoneyRow,
        headerFormatter: () => {return <>Wartość &nbsp;{ikonaSort('12', this.state.sort)}</>},
        headerStyle: {cursor: 'pointer'},
        onSort: this.onSortChange,
        sort: true
    }];

    render() {
        let {currentPozycje, loading, pageableObject} = this.state;

        return (
            <div id={'fakturyExpand'}>
                <TopLoading isLoading={this.state.isLoadingPozycje} isError={this.state.isErrorPozycje}>
                    <>
                        {
                            this.state.isErrorPozycje && <TextAlert type={'warning'} marginTop={true} marginBottom={true}>Wystąpił błąd podczas próby pobrania pozycji</TextAlert>
                        }
                        {
                            this.state.currentPozycje.length === 0 ?
                                <TextAlert type={'info'} marginTop={true} marginBottom={true}>Brak pozycji na tej fakturze</TextAlert>
                                :
                                <BootstrapTable remote keyField='poz' data={ currentPozycje } columns={ this.columns } hover noDataIndication={'Brak pasujących wyników'}
                                                loading={loading}
                                                overlay={ overlayFactory({ spinner: true, styles: { overlay: (base: any) => ({...base, background: 'rgba(96,93,141,0.3)'}) } }) }
                                                pagination={(paginationFactory({
                                                    page: (pageableObject.currentPage) + 1,
                                                    sizePerPage: pageableObject.currentSize,
                                                    totalSize: pageableObject.totalElements,
                                                    sizePerPageList: [10, 12, 20, 50, 100],
                                                    hideSizePerPage: true,
                                                    // onSizePerPageChange: this.handleChangeSizePerPage,
                                                    onPageChange: this.handleChangePage
                                                }))}/>
                        }
                    </>
                </TopLoading>

            </div>
        );
    }
}

export default FakturyExpand;
