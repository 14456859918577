import React, {Component} from 'react';
import {Row, Col, Button} from "react-bootstrap";

//@ts-ignore
import ImageMapper from 'react-image-mapper';
import {IconArrowDoubleLeft, IconArrowDoubleRight, IconTimes} from "../../../../sharedComponents/icons/FontAwesome";

interface Props {
    gazetkaAreasMap: any,
    currentStrona: number,
    handleChangeCurrentPage: (type: string) => void,
}

const IMG_SCALE = 1.25;

class PromocjaPreview extends Component<Props> {

    state = {
        currentArea: null,
        clickedArea: null
    };

    componentDidMount() {
    }

    onEnterArea = (area: any) => {
        this.setState({currentArea: area})
    };

    onLeaveArea = () => {
        this.setState({currentArea: null})
    };

    onClickArea = (area: any) => {
        this.setState({clickedArea: area})
    };


    render() {

        let {gazetkaAreasMap, currentStrona} = this.props;

        return (
            <div>
                {
                    gazetkaAreasMap.length>0?

                        <div>
                            <div>
                                <Button variant={'light'} disabled={currentStrona === 0}
                                        onClick={() => this.props.handleChangeCurrentPage("-")}>
                                    <IconArrowDoubleLeft/> poprzedna strona
                                </Button>
                                <Button variant={'light'} disabled={currentStrona === gazetkaAreasMap.length - 1}
                                        onClick={() => this.props.handleChangeCurrentPage("+")}>
                                    następna strona <IconArrowDoubleRight/>
                                </Button>
                            </div>
                            {
                                ((Boolean(gazetkaAreasMap[currentStrona]) && gazetkaAreasMap[currentStrona].areas.length > 0)) ?
                                    <Row>
                                        <Col>
                                            <ImageMapper src={gazetkaAreasMap[currentStrona].plik} map={
                                                {
                                                    name: gazetkaAreasMap[currentStrona].name,
                                                    areas: Array.from(gazetkaAreasMap[currentStrona].areas, (area: any) =>
                                                        (
                                                            {
                                                                ...area,
                                                                coords: Array.from(area.coords, (coord: number) => coord * IMG_SCALE)
                                                            }
                                                        )
                                                    ),
                                                }
                                            }
                                                         width={gazetkaAreasMap[currentStrona].imgDimensions.width * IMG_SCALE}
                                                         height={gazetkaAreasMap[currentStrona].imgDimensions.height * IMG_SCALE}
                                                         onMouseEnter={this.onEnterArea}
                                                         onMouseLeave={this.onLeaveArea}
                                                         onClick={this.onClickArea}
                                            />
                                            {
                                                Boolean(this.state.clickedArea) &&
                                                <div style={{
                                                    zIndex: 99999999,
                                                    width: '100px',
                                                    height: '50px',
                                                    position: "absolute",
                                                    background: 'red',
                                                    //@ts-ignore
                                                    marginTop: ((gazetkaAreasMap[currentStrona].imgDimensions.height * IMG_SCALE * -1) + this.state.clickedArea.center[1]),
                                                    //@ts-ignore
                                                    marginLeft: (this.state.clickedArea.center[0])
                                                }}>
                                                    <span>buy form</span>
                                                    <span onClick={() => this.setState({clickedArea: null})}><IconTimes/></span>
                                                </div>
                                            }
                                        </Col>
                                        <Col>
                                            <h5>Oznaczone produkty</h5>
                                            <ul>
                                                {
                                                    gazetkaAreasMap[currentStrona].areas.map((area: any) => {
                                                            if (Boolean(area.product)) {
                                                                return (
                                                                    <li style={{
                                                                        background:
                                                                        // @ts-ignore
                                                                            (Boolean(this.state.currentArea) && this.state.currentArea.name === area.name) ?
                                                                                'rgba(45,136,188,0.2)'
                                                                                :
                                                                                "transparent"
                                                                    }}>
                                                                        {area.product.producent}, {area.product.nazwa}
                                                                    </li>
                                                                )
                                                            }
                                                        }
                                                    )}
                                            </ul>
                                        </Col>
                                    </Row>
                                    :
                                    <text className={'text-danger'}>
                                        Nie zaznaczono jeszcze nic na stronie {currentStrona}
                                    </text>
                            }
                        </div>
                        :
                        <text className={'text-danger'}>
                            Nie zdefiniowano plików gazetki
                        </text>
                }
            </div>
        );
    }
}

export default PromocjaPreview;