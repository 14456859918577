import React, {Component} from 'react';
import {formatMoney} from "../../../utilities/formatters/formatMoney";
import RozrachunkiService from "../../../services/RozrachunkiService";
import TopLoading from "../../../sharedComponents/loader/TopLoading";
import {Col, Row} from "react-bootstrap";

interface Props {
    naleznosci: any,
    isLoadingNaleznosci: boolean,
    isErrorNaleznosci: boolean,
}

class RachunkiNaleznosciBox extends React.Component<Props> {

    state = {
        // naleznosci: null,
        // isLoadingNaleznosci: false,
        // isErrorNaleznosci: false,
    };

    componentDidMount() {
        // this.pobierzNaleznosci()
    }


    // pobierzNaleznosci = () => {
    //     this.setState({
    //         isLoadingNaleznosci: true,
    //         isErrorNaleznosci: false,
    //     });
    //     RozrachunkiService.getNaleznosci()
    //         .then((response: any) => {
    //             if (response && response.status < 300) {
    //                 this.setState({
    //                     isLoadingNaleznosci: false,
    //                     naleznosci: response.data,
    //                 });
    //             } else {
    //                 this.setState({
    //                     isLoadingNaleznosci: false,
    //                     isErrorNaleznosci: true,
    //                 });
    //             }
    //         })
    //         .catch((error: any) => {
    //             this.setState({
    //                 isLoadingNaleznosci: false,
    //                 isErrorNaleznosci: true,
    //             });
    //         })
    // };

    render() {

        let {isLoadingNaleznosci, isErrorNaleznosci, naleznosci} = this.props;

        return (
            <TopLoading isLoading={isLoadingNaleznosci} isError={isErrorNaleznosci} isCentered={false} isBig={false} loadingText={'Trwa ładowanie należności'}>
                {
                    naleznosci &&
                    <div id={'RachunkiNaleznosciBox'}>
                        <Row>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                <div className={'card'}>
                                    <label>
                                        Do zapłaty ogółem:
                                    </label>
                                    <span>
                                    {
                                        //@ts-ignore
                                        formatMoney(naleznosci.doZaplatyOgolem)
                                    }
                                </span>
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                <div className={'card'}>
                                    <label>
                                        Do zapłaty po terminie:
                                    </label>
                                    {
                                        //@ts-ignore
                                        <span className={naleznosci.doZaplatyPoTerminie === 0 ? 'terminok' : 'poterminie'}>
                                            {
                                                //@ts-ignore
                                                formatMoney(naleznosci.doZaplatyPoTerminie)
                                            }
                                        </span>
                                    }
                                </div>
                            </Col>
                        </Row>


                    </div>
                }
            </TopLoading>
        );
    }
}

export default RachunkiNaleznosciBox;