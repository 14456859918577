import React, {Component} from 'react';
import {Table} from "react-bootstrap";
import {
    TableFormatters_formatBoolean,
    TableFormatters_formatMoney
} from "../../../sharedComponents/tableComponents/tableFormatters";
import {Link} from "react-router-dom";
import OverflowTextWrapper from "../../../sharedComponents/typographyComponents/overflowText/OverflowTextWrapper";
import tgrid from "../../../utilities/table/tgrid";
import Shared_ProductAddToBasketForm from "../../../sharedComponents/_reusable/ProductAddToBasket/Shared_ProductAddToBasketForm";
import {IconCart, IconCheck, IconTimes} from "../../../sharedComponents/icons/FontAwesome";
import {isMobileDevice} from "../../../utilities/rwd/rwdUtilities";
import {formatMoney} from "../../../utilities/formatters/formatMoney";
import BootstrapTable from "react-bootstrap-table-next";
import KoszykService from "../../../services/KoszykService";
import {displayNotificationError, displayNotificationInfo} from "../../../utilities/notifications/NotificationsManager";
import TopLoading from "../../../sharedComponents/loader/TopLoading";
// @ts-ignore
import overlayFactory from 'react-bootstrap-table2-overlay';

interface Props {
    produktyStrona: any,
    czyEdycja: boolean,
    handleChangeInBasket: (previousAmount: any, amount: any, item: any, opakowanie: string) => void,
    odswiezIloscLokalnie?: (amount: any, item: any, typOpak?: string) => void,
    loadingProdukty: boolean,
    loadingProduktyError: boolean,
    loading: boolean
}

class PromocjaGazetkaOferta extends Component<Props> {



    render() {

        let {produktyStrona, loadingProdukty, loadingProduktyError, loading} = this.props;

        const columns = [{
            dataField: 'indeks',
            text: 'Indeks',
            align: 'center',
            // hidden: true
        },
        //     {
        //     dataField: 'producent',
        //     text: 'Producent',
        //     align: 'center',
        // },
            {
            dataField: 'nazwaPokaz',
            text: 'Nazwa',
        }, {
            dataField: 'cena',
            text: 'Cena sprz.',
            align: 'center',
            formatter: (cell: any) => (
                formatMoney(cell)
            )
        }, {
            dataField: 'zakupJm',
            text: 'Koszyk',
            // headerFormatter: () => {return( <><IconCart/>&nbsp; Koszyk&nbsp;{this.ikonaSort('koszyk_ilosc')}</>)},
            style: {width: '145px'},
            // formatExtraData: changeProps || this.props.changeOferta,
            formatter: (cell: any, row: any, index: any, extraData: any) => (
                <Shared_ProductAddToBasketForm item={row} fieldForAmount={'koszykIlosc'} alwaysDostepne={true} inline={true} odswiezIloscLokalnie={this.props.odswiezIloscLokalnie} showButton={false} handleChangeInBasket={this.props.handleChangeInBasket}/>
            )
        }];

        return (<div className={'promocjaGazetkaOferta'}>
            <TopLoading isLoading={loadingProdukty} isError={loadingProduktyError}>
                <BootstrapTable remote keyField='indeks' data={ produktyStrona } columns={ columns } hover noDataIndication={'Brak pasujących wyników'}
                                headerClasses={'headerbsGaz'}
                                loading={loading}
                                overlay={ overlayFactory({ spinner: true, styles: { overlay: (base: any) => ({...base, background: 'rgba(96,93,141,0.3)'}) } }) }
                />
            </TopLoading>
            </div>
        );
    }
}

export default PromocjaGazetkaOferta;
