import React from 'react';
import TopCard from "../../../sharedComponents/TopCard";
import avatar from '../../../resources/images/useravatar.png';
import {IconEnvelope, IconPhone} from "../../../sharedComponents/icons/FontAwesome";
import LoginService from "../../../services/LoginService";

const InformacjeKontaktComponent = () => {
    return (
        <div id={'InformacjeOpiekunComponent'}>
            <TopCard heading={'Kontakt'} hasPadding={true}>
                <div className={'box-inner'}>
                    <div className={'text-box-info'}>
                        <h6>{(LoginService.getCurrentUser() && (LoginService.getCurrentUser().nazwaMagazynu ? 'Magazyn ' + LoginService.getCurrentUser().nazwaMagazynu : 'Brak wskazanego magazynu'))}</h6>
                        <h6 className={'heading-label'}>Kontakt</h6>
                        {(LoginService.getCurrentUser() && (LoginService.getCurrentUser().nazwaMagazynu ? <div>
                            {LoginService.getCurrentUser().nazwaMagazynu === 'Rzeszów' ?
                            <>
                                <h6>
                                    <IconPhone/><span>Dział fakturowania: tel. (17) 852-61-05 wew. 31</span>
                                </h6>
                                <h6>
                                    <IconPhone/><span>tel. (17) 860-06-14 wew. 62</span>
                                </h6>
                                <h6>
                                    <IconPhone/><span>tel. kom. 502 061-449</span>
                                </h6>
                                <h6>
                                    <IconEnvelope/><span>sprzedaz@famix.com.pl</span>
                                </h6>
                            </> : null}
                            {LoginService.getCurrentUser().nazwaMagazynu === 'Kraków' ?
                                <>
                                    <h6>
                                        <IconPhone/><span>Dział fakturowania: tel. (12) 680-76-30</span>
                                    </h6>
                                    <h6>
                                        <IconPhone/><span>tel. kom. 530-892-123</span>
                                    </h6>
                                    <h6>
                                        <IconPhone/><span>tel. kom. 530-879-110</span>
                                    </h6>
                                    <h6>
                                        <IconEnvelope/><span>sprzedazkrakow@famix.com.pl</span>
                                    </h6>
                                </> : null}

                        </div> : null))}
                    </div>
                </div>
            </TopCard>
        </div>
    );
};

export default InformacjeKontaktComponent;
