export const prettyColumns_Oferta = [


    {value: 'indeks', label: 'Indeks'},
    {value: 'grupa', label: 'Grupa'},
    {value: 'imagePath', label: 'Zdjecie_url'},
    {value: 'producent', label: 'Producent'},
    {value: 'nazwa', label: 'Nazwa_produktu'},
    {value: 'nrKat', label: 'Kod_paskowy_EAN'},
    {value: 'opis', label: 'Opis'},
    // {value: 'opak2', label: 'Opakowanie'},
    {value: 'ilJm2', label: 'Ile_w_opakowaniu_zbiorczym_(jm2)'},
    {value: 'ilJm3', label: 'Ile_w_kartonie_zbiorczym_(jm3)'},
    {value: 'ilPa', label: 'Ile_w_palecie'},
    {value: 'ilWa', label: 'Ile_w_warstwie'},
    {value: 'jm', label: 'Jednostka_miary_jednostki_(jm1)'},
    {value: 'jm2', label: 'Jednostka_miary_opakowania_zbiorczego_(jm2)'},
    {value: 'jm3', label: 'Jednostka_miary_kartonu_zbiorczego_(jm3)'},
    {value: 'vat', label: 'VAT'},
    {value: 'cena', label: 'Aktualna_cena'},
    {value: 'cenaJm2', label: 'Cena_dla_opakowania_zbiorczego_(jm2)'},
    {value: 'cenaJm3', label: 'Cena_dla_kartonu_zbiorczego_(jm3)'},
    {value: 'cenaS', label: 'Cena_sprzedaży'},
    {value: 'powod', label: 'Powod_promocji'},
    //{value: 'promUpust', label: 'Upust_promocyjny'},
    //{value: 'rodzajPromocji', label: 'Rodzaj_promocji'},
    {value: 'upust', label: 'Upust'},
    {value: 'upustProcentowy', label: 'Upust_procentowy'},
    {value: 'zapas', label: 'Zapas'},
    // {value: 'minSprz', label: 'Minimalna_ilosc_sprzedazy'},
    {value: 'dostepne', label: 'Czy_dostepne?'},
    //{value: 'koszyk', label: 'Ile_w_koszyku_w_momencie_exportu'},
];

export const prettyColumns_Zamowienia = [
    {value: 'symbol', label: 'Symbol'},
    {value: 'numer', label: 'Numer'},
    {value: 'liczbaPozycji', label: 'Liczba_pozycji'},
    {value: 'wartosc', label: 'Wartosc'},
    {value: 'dataZam', label: 'Data_zamowienia'},
    {value: 'wyslal', label: 'Wyslal'},
    {value: 'wystawiono', label: 'Wystawiono'},
    {value: 'status', label: 'Status_liczba'},
    {value: 'statusSlownie', label: 'Status_slownie'},
    {value: 'zamkniete', label: 'Czy_zamkniete?'},
];

export const prettyColumns_Rozrachunki = [
    {value: 'doSymbol', label: 'Symbol'},
    {value: 'doNumer', label: 'Numer'},
    {value: 'poz', label: 'Pozycja'},
    {value: 'doNrmag', label: 'Magazyn'},
    {value: 'dataWys', label: 'Data_wystawienia'},
    {value: 'kwota', label: 'Kwota'},
    {value: 'opis', label: 'Opis'},
    {value: 'termPl', label: 'Termin_platnosci'},
    {value: 'opoznienie', label: 'Opoznienie'},
    {value: 'nrRach', label: 'Nr_rach'},
];

export const prettyColumns_Faktury = [
    {value: 'symbol', label: 'Symbol'},
    {value: 'numer', label: 'Numer'},
    {value: 'numag', label: 'Magazyn'},
    {value: 'podstawa', label: 'Podstawa'},
    {value: 'dataWys', label: 'Data_wystawienia'},
    {value: 'termPl', label: 'Termin_platnosci'},
    {value: 'kwota', label: 'Kwota'},
    {value: 'uwagi', label: 'Uwagi'},
    {value: 'wystawil', label: 'Wystawil'},
];

export const prettyColumns_AdminProdukty = [
    {value: 'id', label: 'ID_Produktu'},
    {value: 'indeksTop', label: 'Indeks'},
    {value: 'producent', label: 'Producent'},
    {value: 'nazwa', label: 'Nazwa'},
    {value: 'zdjecie', label: 'Zdjecie'},
    {value: 'cenaKatalogowa', label: 'Cena_katalogowa'},
    {value: 'cena', label: 'Cena'},
    {value: 'idKt', label: 'ID_kategorii'},
    {value: 'idPk', label: 'ID_podkategorii'},
    {value: 'slug', label: 'Slug'},
    {value: 'url', label: 'URL'},
    {value: 'jednostkaMiara', label: 'Miara_jednostki'},
    {value: 'opakowanieMiara', label: 'Miara_opakowania'},
    {value: 'iloscOpakowanie', label: 'Ilosc_w_opakowaniu'},
    {value: 'ileOpakowanWarstwa', label: 'Ile_opakowan_w_warstwie'},
    {value: 'ileOpakowanPaleta', label: 'Ile_opakowan_w_palecie'},
    {value: 'promocja', label: 'Czy_jest_promocja?'},
    {value: 'czyDostepny', label: 'Czy_jest_dostepny?'}
];

export const prettyColumns_AdminKlienci = [
    {value: 'idKl', label: 'ID_Klienta'},
    {value: 'nazwa', label: 'Nazwa'},
    {value: 'imie', label: 'Imie'},
    {value: 'nazwisko', label: 'Nazwisko'},
    {value: 'firma', label: 'Firma'},
    {value: 'email', label: 'Email'},
    {value: 'telefon', label: 'Telefon'},
    {value: 'czyAktywny', label: 'Czy_jest_aktywny?'},
    {value: 'czyB2B', label: 'Czy_jest_B2B?'},
];

export const prettyColumns_AdminPromocje = [
    {value: 'id', label: 'ID'},
    {value: 'name', label: 'Nazwa_promocji'},
    {value: 'dateAvailability', label: 'Czas_waznosci'},
    {value: 'czyAktywna', label: 'Czy_promocja_aktywna?'},
    {value: 'indeksTop', label: 'Indeks'},
    {value: 'producent', label: 'Producent'},
    {value: 'nazwa', label: 'Nazwa'},
    {value: 'zdjecie', label: 'Zdjecie'},
    {value: 'cenaKatalogowa', label: 'Cena_katalogowa'},
    {value: 'cena', label: 'Cena'},
    {value: 'idKt', label: 'ID_kategorii'},
    {value: 'idPk', label: 'ID_podkategorii'},
    {value: 'slug', label: 'Slug'},
    {value: 'url', label: 'URL'},
    {value: 'jednostkaMiara', label: 'Miara_jednostki'},
    {value: 'opakowanieMiara', label: 'Miara_opakowania'},
    {value: 'iloscOpakowanie', label: 'Ilosc_w_opakowaniu'},
    {value: 'ileOpakowanWarstwa', label: 'Ile_opakowan_w_warstwie'},
    {value: 'ileOpakowanPaleta', label: 'Ile_opakowan_w_palecie'},
    {value: 'promocja', label: 'Czy_jest_promocja?'},
    {value: 'czyDostepny', label: 'Czy_jest_dostepny?'}
];