let isDevelopMode = false;

export const APP_VERSION = () => {
    return isDevelopMode ? 'DEV' : 'PROD'
};

export const TEST_VERSION = false;

export const APP_UPDATE = () => {
    return '2024-02-27 10:00'
};

export const APP_LOCALSTORAGE_VERSION = () => {
    return '2021-12-07 15:30'
};

export const APP_CODE = () => {
    return btoa('famix.top');
};

export const APP_CZAS_AKTUALIZACJI_OFERTY = () => {
    // ile godzin do następnego pobrania strony 0 (99999)
    return 2
};

export const HOST = ('https://e.famix.com.pl/');

export const HOST_API = (isDevelopMode || ((window.location.origin.indexOf('localhost') > -1) || (window.location.origin.indexOf('famix.top') > -1) || (window.location.origin.indexOf('10.10.10') > -1)) && !(window.location.origin.indexOf('famix.topsa') > -1)) ? `https://e.famix.com.pl/api/` : `${window.location.origin}/api/`;
// export const HOST_API = (isDevelopMode || ((window.location.origin.indexOf('localhost') > -1) || (window.location.origin.indexOf('famix.top') > -1) || (window.location.origin.indexOf('10.10.10') > -1)) && !(window.location.origin.indexOf('famix.topsa') > -1)) ? `http://centos7.top:8083/api/` : `${window.location.origin}/api/`;
// export const HOST_API = (HOST + 'api/');
// export const HOST_API = ('http://10.10.10.191:8082/api/');
// export const HOST_API = ('http://10.10.10.129:8083/api/'); //Kamil api
