import accessRoleTypes from "../utilities/accessibilityConfig/accessRoleTypes";
import localStorageTypes from "../utilities/localStorage/localStorageTypes";
import axios from "axios";
import {APP_CODE, APP_LOCALSTORAGE_VERSION, HOST_API} from "../ApplicationConfig";
import OfertaService from "./OfertaService";
import moment, {now} from "moment";

const HOST = HOST_API + 'login/';

export default class LoginService {


    static logUser = (login: string, password: string) => {
        return axios.post(HOST,{"password": password, "username": login})

    };

    static logoutUser = () =>{
      return axios.request({
          method:'get',
          url: HOST+'logOut',
      })
    };

    static czyRola(rola: string) {
        let user: any = LoginService.getCurrentUser();

        if (user !== null) {
            let role = user.authorities;

            for (let i = 0; i < role.length; i++) {
                if (role[i].authority === rola){
                    return true;
                }
            }

            return false;
        } else
        {
            return false;
        }
    }

    static czyAdmin() {
        return this.czyRola('ROLE_SYSTEM_ADMIN');
    }

    static czyKlientWlasciciel() {
        return this.czyRola('ROLE_KLIENT_WLASCICIEL');
    }

    static setBaneryInSession = (banery: any) => {
        sessionStorage.setItem('BANERY', JSON.stringify(banery));
    };

    static checkIfBaneryInSession = () => {
        let banery: any = sessionStorage.getItem('BANERY');

        if (Boolean(banery)) {
            banery = JSON.parse(banery);
            return banery
        } else return false;
    };

    static setUserInStorage = (user: any) => {
        localStorage.setItem(localStorageTypes.CURRENT_USER, JSON.stringify(Object.assign(user, {applicationCode: APP_CODE()})));
        localStorage.setItem(localStorageTypes.CURRENT_LOCALSTORAGE_VERSION, APP_LOCALSTORAGE_VERSION())
        localStorage.setItem(localStorageTypes.CURRENT_CZY_ODCZYTANO_POWIADOMIENIA,"true")
        localStorage.setItem('FAMIX_OSTATNIE_LOGOWANIE', JSON.stringify(moment().format('YYYY.MM.DD HH:mm')))
        OfertaService.updateSieciHandloweInLS(user.kontos)
    };

    static setUserInSesion = (user: any) => {
        sessionStorage.setItem(localStorageTypes.CURRENT_USER, JSON.stringify(Object.assign(user, {applicationCode: APP_CODE()})));
        localStorage.setItem(localStorageTypes.CURRENT_LOCALSTORAGE_VERSION, APP_LOCALSTORAGE_VERSION())
        localStorage.setItem(localStorageTypes.CURRENT_CZY_ODCZYTANO_POWIADOMIENIA,"true")
        OfertaService.updateSieciHandloweInLS(user.kontos)
    };

    static removeUserFromStorage = () => {
        localStorage.removeItem(localStorageTypes.CURRENT_USER)
        localStorage.removeItem(localStorageTypes.CURRENT_SIECI_ARRAY)
        localStorage.removeItem(localStorageTypes.GLOBAL_SETTINGS)
        localStorage.removeItem('FAMIX_OSTATNIE_LOGOWANIE')
    };

    static removeUserFromSession = () => {
        sessionStorage.removeItem('AKTUALIZACJA');
        sessionStorage.removeItem('totalElements');
        sessionStorage.removeItem(localStorageTypes.CURRENT_USER);
    };

    static removeUserFromSessionBezUzytkownika = () => {
        sessionStorage.removeItem('AKTUALIZACJA');
        sessionStorage.removeItem('totalElements');
    };

    static checkIfUserIsLogged = () => {
        let user: any = localStorage.getItem(localStorageTypes.CURRENT_USER);
        let userSesion: any = sessionStorage.getItem(localStorageTypes.CURRENT_USER);

        // console.log(Boolean(user), Boolean(userSesion));

        if (Boolean(user) || Boolean(userSesion)) {
            let loginUser = JSON.parse((Boolean(user)? user : userSesion));
            return (loginUser.applicationCode === APP_CODE()) //(user.role === accessRoleTypes.ROLE_USER || user.role === accessRoleTypes.ROLE_ADMIN)
        } else return false;
    };

    static localCzySesion = (): boolean => {
        let user: any = localStorage.getItem(localStorageTypes.CURRENT_USER);
        let userSesion: any = sessionStorage.getItem(localStorageTypes.CURRENT_USER);

        return ((Boolean(user)? true : false))
    }

    static getCurrentUser = () => {
        if (LoginService.checkIfUserIsLogged()) {
            // @ts-ignore
            let user: any = (this.localCzySesion())? localStorage.getItem(localStorageTypes.CURRENT_USER) : sessionStorage.getItem(localStorageTypes.CURRENT_USER);
            return JSON.parse(user)
        } else return null
    };

    static checkIfCurrentUserIsAdmin = () => {
        let user: any = LoginService.getCurrentUser();

        if (user !== null) {
            return (user.role === accessRoleTypes.ROLE_ADMIN)
        } else return false
    };

    static checkLocalStorageVersion = () => {
        if (Boolean(localStorage.getItem(localStorageTypes.CURRENT_LOCALSTORAGE_VERSION))) {
            return (localStorage.getItem(localStorageTypes.CURRENT_LOCALSTORAGE_VERSION) === APP_LOCALSTORAGE_VERSION())
        } else return false
    }

    static zmienHaslo = (params:any) =>{
        return axios.request({
            method: 'put',
            url: `${HOST}zmienHaslo`,
            data: JSON.stringify(params)
        })
    };

    static getZgody = () =>{
        return axios.request({
            method: 'get',
            url: `${HOST}getZgodyUzytkownika`
        })
    };

    static setZgody = (dane: any) =>{
        return axios.request({
            method: 'put',
            url: `${HOST}zmienZgode`,
            data: dane
        })
    };
}
