import React from 'react';
import {IconBriefcase, IconEnvelope, IconLogout, IconNotification, IconPhone, IconSettings, IconUser} from "../../icons/FontAwesome";
import {withRouter, RouteComponentProps, Link} from 'react-router-dom';
import LoginService from "../../../services/LoginService";
import zdj from '../../../resources/images/useravatar.png';
import {famix_getFirmaOrKontrahentFromLS} from "../../../utilities/famix_others/famix_getAnotherData";
import OverflowTextWrapper from "../../typographyComponents/overflowText/OverflowTextWrapper";
import {displayNotificationInfo, displayNotificationSuccess, displayNotificationWarning} from "../../../utilities/notifications/NotificationsManager";
import localStorageTypes from "../../../utilities/localStorage/localStorageTypes";
import axios from "axios";
import PowiadomieniaService from "../../../services/PowiadomieniaService";

// interface Props extends RouteComponentProps {
//     props: any,
//     nowePowiadomienia: any
// }

interface Props {
    props: any,
    nowePowiadomienia: any
}

class CurrentUserHeaderComponent extends React.Component<Props, any> {

    state = {
        showUserInfo: false
    };

    componentDidMount() {
        // this.pobierzNieodczytane();
    }

    pobierzNieodczytane = () => {
        PowiadomieniaService.getNieodczytaneKomunikatyCount().then((response) => {
            console.log(response.data);
        }).catch((e) => {
            console.log('error');
        })
    }

    onClickLogout = () => {

        displayNotificationInfo('Trwa wylogowywanie', 'Proszę nie zamykać aplikacji');

        LoginService.logoutUser()
            .then((response: any) => {
                if (response && response.status < 300) {
                    displayNotificationSuccess("Pomyślnie wylogowano", '');
                    delete axios.defaults.headers.common["Authorization"];
                    delete axios.defaults.headers.common["kontrahent"];
                    delete axios.defaults.headers.common["magazyn"];
                    LoginService.removeUserFromStorage();
                    LoginService.removeUserFromSession();
                    //@ts-ignore
                    this.props.history.push('/')
                } else {
                    displayNotificationWarning('Wylogowano tylko po stronie klienta', 'Wystąpił błąd podczas próby wylogowania się');
                    LoginService.removeUserFromStorage();
                    LoginService.removeUserFromSession();
                    //@ts-ignore
                    this.props.history.push('/')
                }
            })
            .catch((error: any) => {

                displayNotificationWarning('Wylogowano tylko po stronie klienta', 'Wystąpił błąd: ' + error.message);
                LoginService.removeUserFromStorage();
                LoginService.removeUserFromSession();
                //@ts-ignore
                this.props.history.push('/')
            })

    };

    toggleUserInfo = () => {
        this.setState((prevState: any) => ({showUserInfo: !prevState.showUserInfo}));
    };

    render() {

        let {nowePowiadomienia} = this.props;

        return (
            <span id={'current-user-header'}>
                <label>Zalogowany jako:</label>
                <span id={'user-powiadomienia'} className={(nowePowiadomienia !== false) ? 'not-readed' : ''}
                      title={
                          // (localStorage.getItem(localStorageTypes.CURRENT_CZY_ODCZYTANO_POWIADOMIENIA)==='false') ?
                          (nowePowiadomienia !== false)?
                          'Zobacz nowe powiadomienia'
                              :
                          'Brak nowych powiadomień - pokaż powiadomienia archiwalne'
                      }>
                    <Link to={'/powiadomienia'} style={{color: 'inherit'}} onClick={()=>localStorage.setItem(localStorageTypes.CURRENT_CZY_ODCZYTANO_POWIADOMIENIA,"true")}><IconNotification/></Link>
                </span>
                <span id={'user-settings'} title={'Pokaż ustawienia'}>
                    <Link to={'/ustawienia'} style={{color: 'inherit'}}><IconSettings/></Link>
                </span>
                <span onClick={this.toggleUserInfo}>
                    <span className={'username'}>{LoginService.getCurrentUser() && LoginService.getCurrentUser().username ? LoginService.getCurrentUser().username : '-'}</span>
                    {
                        this.state.showUserInfo &&
                        <div id={'CurrentUserHeaderInfo'}>
                            <div id={'triangle'}></div>
                            <div>
                                <div id={'imgContainer'}>
                                    <img src={zdj} alt={'Zdjęcie klienta'}/>
                                </div>
                                <div id={'detailsContainer'}>
                                    <div>
                                        <IconUser/><span>{LoginService.getCurrentUser() && LoginService.getCurrentUser().username ? LoginService.getCurrentUser().username : '-'}</span>
                                    </div>
                                    <div>
                                        <IconPhone/><span>{LoginService.getCurrentUser() && LoginService.getCurrentUser().telefon ? LoginService.getCurrentUser().telefon : '-'}</span>
                                    </div>
                                    <div>
                                        <IconEnvelope/><span>{LoginService.getCurrentUser() && LoginService.getCurrentUser().email ? LoginService.getCurrentUser().email : '-'}</span>
                                    </div>
                                    <div title={'Firma / kontrahent'}>
                                        {/*<IconBriefcase/>*/}
                                        <hr style={{marginTop: 5, marginBottom: 5}}/>
                                        <span style={{display: 'inline'}}>{famix_getFirmaOrKontrahentFromLS()}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </span>
            <span id={'user-logout'} onClick={this.onClickLogout}><IconLogout/></span>
        </span>
        );
    }
}

//@ts-ignore
export default withRouter(CurrentUserHeaderComponent);
