import React, {Component} from 'react';
import {Button, Col, FormGroup, Row} from "react-bootstrap";
import {IconFileTxt} from "../../../sharedComponents/icons/FontAwesome";
import {isMobileDevice} from "../../../utilities/rwd/rwdUtilities";
import {Form} from 'react-bootstrap';
import {TextAlert} from "../../../sharedComponents/typographyComponents/TextAlert/TextAlert";
import ZamowieniaService from "../../../services/ZamowieniaService";
import TopLoading from "../../../sharedComponents/loader/TopLoading";
import {saveTextFileAndDownload} from "../../../utilities/files/fileOperations";
import {displayNotificationError, displayNotificationSuccess} from "../../../utilities/notifications/NotificationsManager";

interface Props {
    braki: any,
    zamowienie: any,
}

class ZamowieniaBrakiPopup extends Component<Props> {

    state = {
        isLoadingPlik: false,
        isErrorPlik: false,
        responsePlik: ''
    };

    onClickPobierzPlik = () => {
        let {zamowienie} = this.props;

        this.setState({
            isLoadingPlik: true,
            isErrorPlik: false,
            responsePlik: null
        });

        ZamowieniaService.getBrakiZamowieniaPlik(
            zamowienie.symbol,
            zamowienie.numer,
            zamowienie.statPo
        )
            .then((response: any) => {
                if (response && response.status < 300) {
                    this.setState({
                        isLoadingPlik: false,
                        isErrorPlik: false,
                        responsePlik: response.data
                    });

                    saveTextFileAndDownload(response.data, `braki-zamowienia-${zamowienie.symbol}${zamowienie.numer}.txt`)

                    displayNotificationSuccess('Pomyślnie zaciagnięto dane', 'Plik pobierze się automatycznie')

                } else {
                    this.setState({
                        isLoadingPlik: false,
                        isErrorPlik: true,
                        responsePlik: response.data
                    });
                    displayNotificationError('Wystąpił błąd podczas próby pozyskania pliku', '')
                }
            })
            .catch((error: any) => {
                this.setState({
                    isLoadingPlik: false,
                    isErrorPlik: true,
                    responsePlik: error.message
                });
                displayNotificationError('Wystąpił błąd podczas próby pozyskania pliku', error.message)
            })
    };

    render() {
        if (this.props.braki.length > 0)
            return (
                <div id={'ZamowieniaBrakiPopup'}>
                    <div className={'pozycja-row'}>

                        {
                            !isMobileDevice() &&
                            <Row>
                                <Col md={6} lg={5} xl={5}>
                                    Produkt
                                </Col>
                                <Col md={4} lg={3} xl={3}>
                                    Ean
                                </Col>
                                <Col md={2} lg={2} xl={2}>
                                    Ilość
                                </Col>
                                <Col md={2} lg={2} xl={2}>
                                    JM
                                </Col>
                            </Row>
                        }
                    </div>
                    {
                        this.props.braki.map((item: any) => (
                            <div className={'pozycja-row'}>
                                <Row>
                                    <Col md={6} lg={5} xl={5}>
                                        {
                                            isMobileDevice() &&
                                            <label>Produkt:</label>
                                        }
                                        {item.nazwa}
                                    </Col>
                                    <Col md={4} lg={3} xl={3}>
                                        {
                                            isMobileDevice() &&
                                            <label>EAN:</label>
                                        }
                                        {item.ean}
                                    </Col>
                                    <Col md={2} lg={2} xl={2}>
                                        {
                                            isMobileDevice() &&
                                            <label>Ilość:</label>
                                        }
                                        {item.ilosc}
                                    </Col>
                                    <Col md={2} lg={2} xl={2}>
                                        {
                                            isMobileDevice() &&
                                            <label>JM:</label>
                                        }
                                        {item.jm}
                                    </Col>
                                </Row>
                            </div>
                        ))
                    }
                    <div className={'pozycja-row'}>
                        <Form inline={true}>
                            <FormGroup>
                                <Button variant={'primary'} size={'sm'} disabled={this.state.isLoadingPlik} onClick={this.onClickPobierzPlik}><IconFileTxt/> Pobierz plik
                                    zawierający braki</Button>
                            </FormGroup>
                            <TopLoading isLoading={this.state.isLoadingPlik} isError={this.state.isErrorPlik} loadingText={'Trwa pobieranie pliku'}
                                        errorMessage={'Wystąpił błąd podczas próby pobrania pliku'} isCentered={false} isBig={false}>
                                {
                                    Boolean(this.state.responsePlik) &&
                                    <TextAlert type={'success'}>Pomyślnie pobrano plik tekstowy</TextAlert>
                                }
                            </TopLoading>
                        </Form>
                    </div>
                </div>
            );

        if (this.props.braki.length === 0)
            return (<TextAlert type={'info'}>Zamówienie nie zawiera braków</TextAlert>)
    }
}

export default ZamowieniaBrakiPopup;