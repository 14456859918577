import React from 'react';
import {Button, Col, Row} from "react-bootstrap";
import {IconList} from "../../../../sharedComponents/icons/FontAwesome";
import {SmallerSelectStyles} from "../../../../utilities/forms/jsStyles";
import {isMobileDevice} from "../../../../utilities/rwd/rwdUtilities";
import Select from "react-select";

interface Props {
    reklamacje: any[],
    statusy: any[],
    handleClickPokaz: (row: any) => void;
    handleClickZmienStatus: (row: any) => void;
}

const AdminReklamacjeGrid: React.FC<Props> = ({reklamacje, statusy, handleClickPokaz, handleClickZmienStatus}) => {
    return (
        <div id={'AdminReklamacjeGrid'}>
            <Row>
                {reklamacje.map((reklamacja:any)=>(
                    <Col md={6} lg={4} xl={3}>
                        <div className={'reklamacja-container'}>
                            <div>
                                <div className={'smaller-grid-tills'}>
                                    <label>ID:</label>
                                    <span>{reklamacja.id}</span>
                                </div>
                                <div className={'smaller-grid-tills'}>
                                    <label>Faktura:</label>
                                    <span>{reklamacja.faktura}</span>
                                </div>
                                <div className={'smaller-grid-tills full-width'}>
                                    <label>Data reklamacji:</label>
                                    <span>{reklamacja.dataReklamacji}</span>
                                </div>
                            </div>
                            <div>
                                <label>
                                    Data wyst. faktury:
                                </label>
                                <span>
                                    {reklamacja.dataWystawienia}
                                </span>
                            </div>
                            <div>
                                <label>
                                    Klient:
                                </label>
                                <span>
                                    {reklamacja.klient}
                                </span>
                            </div>
                            <div>
                                <label>
                                    Status:
                                </label>
                                <div>
                                    <Select
                                        value={statusy.find((s: any) => s.value === reklamacja.status)}
                                        options={statusy}
                                        onChange={handleClickZmienStatus}
                                        styles={SmallerSelectStyles}
                                        isClearable={false}
                                        placeholder={'Wybierz...'}
                                        isSearchable={!isMobileDevice()}
                                        isDisabled={reklamacja.status==='rozwiązano'}
                                    />
                                </div>
                            </div>
                            <div>
                                <Button block={true} onClick={() => handleClickPokaz(reklamacja)}
                                        variant={'light'} size={'sm'}>
                                    <IconList/> Pokaż szczegóły
                                </Button>
                            </div>
                        </div>
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default AdminReklamacjeGrid;