import React from 'react';
import {Col, Row} from "react-bootstrap";
import DatePicker from 'react-datepicker'
import {IconCalendar} from "../icons/FontAwesome";
import "react-datepicker/dist/react-datepicker.css"

interface Props {
    startValue: string,
    endValue: string,
    disabledStart?: boolean,
    disabledEnd?: boolean,
    onChangeValueStart: any,
    onChangeValueEnd: any,
    isSmallerInput?:boolean
}

const Daterange: React.FC<Props> = ({startValue, endValue, disabledStart=false, disabledEnd=false, onChangeValueStart, onChangeValueEnd,isSmallerInput=true}) => {

    /*
    *                       <DatePicker
                                selected={startDate}
                                onChange={date => setStartDate(date)}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                              />
                              <DatePicker
                                selected={endDate}
                                onChange={date => setEndDate(date)}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                              />
    * */

    const onChangeDatepicker = (e: any, type: string) => {
        let date = new Date(e),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        let dateParsed = [date.getFullYear(), mnth, day].join("-");
        if (type === 'start') {
            onChangeValueStart(dateParsed)
        } else {
            onChangeValueEnd(dateParsed)
        }
    };

    const startDate = new Date(startValue);
    const endDate = new Date(endValue);


    return (
        <Row>
            <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                <div className={'datepicker-wrapper'}>
                    <DatePicker
                        selectsStart={true}
                        selected={startDate}
                        startDate={startDate}
                        endDate={endDate}

                        onChange={(e: any) => onChangeDatepicker(e, 'start')}

                        disabled={disabledStart}
                        dateFormat={"yyyy-MM-dd"}
                        className={(isSmallerInput)?'form-control form-control-sm':'form-control'}
                    />
                    <IconCalendar/>
                </div>
            </Col>
            <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                <div className={'datepicker-wrapper'}>
                    <DatePicker
                        selected={endDate}
                        selectsEnd={true}
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}

                        onChange={(e: any) => onChangeDatepicker(e, 'end')}

                        disabled={disabledEnd}
                        dateFormat={"yyyy-MM-dd"}
                        className={(isSmallerInput)?'form-control form-control-sm':'form-control'}
                    />
                    <IconCalendar/>
                </div>
            </Col>
        </Row>
    );
};

export default Daterange;