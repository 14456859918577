import {HOST_API} from "../ApplicationConfig";
import axios from 'axios';
import localStorageTypes from "../utilities/localStorage/localStorageTypes";
import {displayNotificationError, displayNotificationSuccess} from "../utilities/notifications/NotificationsManager";
import {isEmpty} from "lodash";
import KoszykService from "./KoszykService";

const HOST = HOST_API + 'oferta/';

export default class OfertaService {

    static getOferta = (page: number, size: number, params: any, sort: string = '') => {
        return axios.request({
            method: 'post',
            url: `${HOST}getOfertaPagedNew?page=${page}&size=${size}&paged=true${sort}`,
            data: JSON.stringify(params)
        })
    };

    static getProducenci = () => {
        return axios.request({
            method: 'get',
            url: `${HOST}getProducenci`
        })
    };

    static getBanery = () => {
        return axios.request({
            method: 'get',
            url: `${HOST}getBaneryForUser`
        })
    };

    static getKategorie = () => {
        return axios.request({
            method: 'get',
            url: `${HOST}getKategorie`
        })
    };

    static getSezony = () => {
        return axios.request({
            method: 'get',
            url: `${HOST}getSezony`
        })
    };

    static getSieciHandlowe = (kontos: string) => {
        return axios.request({
            method: 'get',
            url: `${HOST}getSieciHandlowe?kontos=${kontos}`
        })
    };

    static updateSieciHandloweInLS = (kontos: string) => {
        if(!KoszykService.isEmpty(kontos))
        {
            OfertaService.getSieciHandlowe(kontos)
                .then((response: any) => {
                    if (response && response.status < 300) {
                        //  displayNotificationSuccess('Pomyślnie ustawiono sieci handlowe', '')
                        localStorage.setItem(localStorageTypes.CURRENT_SIECI_ARRAY, JSON.stringify(response.data))
                    } else {
                        displayNotificationError('Błąd ustawienia sieci handlowych', 'Nie można pobrać danych')
                    }
                })
                .catch((error: any) => {
                    displayNotificationError('Błąd ustawienia sieci handlowych', 'Nie można pobrać danych')
                })
        }

    };

}
