import React, {Component, useEffect, useState} from 'react';
import {Form, FormGroup, InputGroup} from "react-bootstrap";
// @ts-ignore
import InputNumber from 'rc-input-number'
import 'rc-input-number/assets/index.css';

export interface Props {
    item: any;
    fieldForAmount: string;
    fieldForJM: string;
}

const IloscIJednostka: React.FC<Props> = ({
    item,
    fieldForAmount,
    fieldForJM
}) => {

    return (
        <div className={'ProductAddToBasketForm'}>

            <Form inline={true}>
                <FormGroup>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <div data-tip data-for={"jm" + item.indeks}>
                                <InputGroup.Text
                                    className={'inputgroup-prepend-text noedit'}>{item[fieldForJM]}
                                </InputGroup.Text>
                            </div>
                        </InputGroup.Prepend>
                        <InputNumber readOnly={true} disabled={true} controls={false} value={item[fieldForAmount]}/>
                    </InputGroup>
                </FormGroup>
            </Form>

        </div>
    );
}

export default IloscIJednostka;