import React, {Component} from 'react';
import {Row, Col, Button} from "react-bootstrap";
import {IconArrowDoubleLeft, IconArrowDoubleRight, IconTimes} from "../../../../sharedComponents/icons/FontAwesome";
import {displayNotificationError} from "../../../../utilities/notifications/NotificationsManager";
import {SmallerSelectStyles} from "../../../../utilities/forms/jsStyles";
import Select from "react-select";
import {isMobileDevice} from "../../../../utilities/rwd/rwdUtilities";

interface Props {
    savedFiles: boolean,
    produkty: any,
    gazetkaAreasMap: any,
    currentStrona: number,
    handleChangeCurrentPage: (type: string) => void,
    handleSendCanvasShape: (arr: any, imgDimensions: any) => void,
    handleChangeProductInArea: (area: object, product: any) => void;
    handleDeleteArea: (area: object) => void,
}

const IMG_SCALE = 0.5;

class PromocjaEditorForm extends Component<Props> {

    state = {

        imgDimensions: {width: 0, height: 0},
        currentShape: [{x: 0, y: 0}, {x: 0, y: 0}],

        currentCursorPosition: null

    };

    componentDidMount() {
    }

    componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {

        if (JSON.stringify(prevProps.gazetkaAreasMap) !== JSON.stringify(this.props.gazetkaAreasMap)) {
            this.initImageToCanvas();
        }
    }

    getCanvas = () => {
        return document.getElementById('gazetkaCanvas');
    };

    getCanvasContext = () => {
        let canvas: any = this.getCanvas();
        if (Boolean(canvas))
            return canvas.getContext("2d")
        else return null
    };

    getImage = () => {
        return document.getElementById('gazetkaImg')
    };


    initImageToCanvas = () => {
        let canvas: any = this.getCanvas();
        let ctx: any = this.getCanvasContext();
        let img: any = this.getImage();

        if (Boolean(canvas) && Boolean(ctx) && Boolean(img)) {

            img.onload = () => {
                let imgW = img.width * IMG_SCALE;
                let imgH = img.height * IMG_SCALE;

                this.setState({imgDimensions: {width: imgW, height: imgH}});

                canvas.setAttribute('width', imgW);
                canvas.setAttribute('height', imgH);

                ctx.drawImage(img, 0, 0, imgW, imgH);

                ctx.save();

                ctx.globalAlpha = 0.5;
                ctx.fillStyle = "black";

                ctx.fillRect(0, 0, imgW, imgH);
                ctx.restore();

                this.drawBeforeAddedShapes()

            };

        } else {
            displayNotificationError('Błąd ładowania obrazu!', 'Nie można rozpocząć pracy w edytorze')
        }

    };

    getCursorPositionOnCanvas = (event: any) => {

        let bounds = event.target.getBoundingClientRect()

        let x: any = event.clientX - bounds.left;
        let y: any = event.clientY - bounds.top;

        return {x: x, y: y};
    };

    onPressDownCanvas = (e: any) => {

        let arr = this.state.currentShape;
        arr[0] = this.getCursorPositionOnCanvas(e);
        arr[1] = {x: 0, y: 0}
        this.setState({currentShape: arr})

    };

    onPressUpCanvas = (e: any) => {

        let arr = this.state.currentShape;
        arr[1] = this.getCursorPositionOnCanvas(e);
        this.setState({currentShape: arr})
        this.drawShapeOnCanvas(arr);

        this.props.handleSendCanvasShape(arr, this.state.imgDimensions)
    };

    onMoveOnCanvas = (e: any) => {

        this.setCurrentPositionOnCanvas(this.getCursorPositionOnCanvas(e))

        if (this.state.currentShape[0].x > 0 && this.state.currentShape[1].x === 0) {

            let a = [this.state.currentShape[0], this.getCursorPositionOnCanvas(e)]

            this.drawShapeOnCanvas(a)

        }
    };

    onMouseLeaveCanvas = () => {
        this.setState({currentCursorPosition: null})
    };

    setCurrentPositionOnCanvas = (arr: any) => {
        this.setState({currentCursorPosition: arr})
    };

    drawShapeOnCanvas = (arr: any, clear: boolean = true) => {


        if (arr.length === 2) {

            let ctx = this.getCanvasContext();
            let img: any = this.getImage();

            let beginX = arr[0].x;
            let beginY = arr[0].y;

            let widthX = (arr[1].x - arr[0].x);
            let widthY = (arr[1].y - arr[0].y);

            if (clear)
                this.clearCanvasArea()

            ctx.save();
            ctx.beginPath();
            ctx.clearRect(beginX, beginY, widthX, widthY);
            ctx.rect(beginX, beginY, widthX, widthY);
            ctx.clip();
            ctx.drawImage(img, 0, 0, this.state.imgDimensions.width, this.state.imgDimensions.height);
            ctx.restore();
        }

    };

    drawBeforeAddedShapes = (arrayMap: any = this.props.gazetkaAreasMap, elementOfArray: any = this.props.currentStrona) => {
        if (arrayMap[elementOfArray]) {

            let areas: any = arrayMap[elementOfArray].areas;

            areas.forEach((area: any) => {
                this.drawShapeOnCanvas(area.canvasArea, false)
            })
        }
    };

    clearCanvasArea = () => {
        let ctx: any = this.getCanvasContext()
        if (Boolean(ctx)) {
            ctx.save();
            ctx.beginPath();
            ctx.clearRect(0, 0, this.state.imgDimensions.width, this.state.imgDimensions.height);
            ctx.rect(0, 0, this.state.imgDimensions.width, this.state.imgDimensions.height);
            ctx.clip();
            ctx.drawImage(this.getImage(), 0, 0, this.state.imgDimensions.width, this.state.imgDimensions.height);

            ctx.globalAlpha = 0.5;
            ctx.fillStyle = "black";
            ctx?.fillRect(0, 0, this.state.imgDimensions.width, this.state.imgDimensions.height);
            ctx.restore();

            this.drawBeforeAddedShapes()
        }
    };

    checkIfCursorIsInShape = (area: any) => {
        if (Boolean(area) && Boolean(this.state.currentCursorPosition)) {

            let shape: any = area.canvasArea;
            //@ts-ignore
            let x: any = this.state.currentCursorPosition.x;
            //@ts-ignore
            let y: any = this.state.currentCursorPosition.y;
            return ((shape[0].x <= x && x <= shape[1].x) && (shape[0].y <= y && y <= shape[1].y))
        } else return false;
    };

    render() {

        let {gazetkaAreasMap, currentStrona} = this.props;

        const customProduktComponent = (props: any) => (
            <div {...props.innerProps} style={{
                cursor: "pointer",
                padding: "2px 0",
                margin: '0 15px',
                borderBottom: '1px dotted #ddd',
                fontSize: '0.8rem'
            }}>
                <span>{props.data.producent} {props.data.nazwa}</span>
            </div>
        );

        return (
            <div>
                {
                    (gazetkaAreasMap.length > 0 && this.props.savedFiles) ?

                        <Row>
                            <Col>
                                <div>
                                    <Button variant={'light'} disabled={currentStrona === 0}
                                            onClick={() => this.props.handleChangeCurrentPage("-")}>
                                        <IconArrowDoubleLeft/> poprzedna strona
                                    </Button>
                                    <Button variant={'light'} disabled={currentStrona === gazetkaAreasMap.length - 1}
                                            onClick={() => this.props.handleChangeCurrentPage("+")}>
                                        następna strona <IconArrowDoubleRight/>
                                    </Button>
                                </div>
                                <div>
                                    <canvas id={'gazetkaCanvas'} style={{cursor: 'crosshair'}}
                                            onMouseDownCapture={this.onPressDownCanvas}
                                            onMouseUpCapture={this.onPressUpCanvas}
                                            onMouseMoveCapture={this.onMoveOnCanvas}
                                            onMouseOut={this.onMouseLeaveCanvas}
                                    />
                                    <img src={gazetkaAreasMap[currentStrona].plik}
                                         alt={gazetkaAreasMap[currentStrona].name}
                                         id={'gazetkaImg'}
                                         style={{display: 'none'}}
                                    />
                                </div>

                            </Col>
                            <Col>
                                <div>
                                    <h5>Selected shapes:</h5>
                                    {
                                        gazetkaAreasMap[currentStrona].areas.map((area: any) => (
                                            <Row style={{
                                                paddingTop: '5px',
                                                paddingBottom: '5px',
                                                borderTop: '1px solid #ddd',
                                                background:
                                                // @ts-ignore
                                                    (this.checkIfCursorIsInShape(area)) ?
                                                        'rgba(45,136,188,0.2)'
                                                        :
                                                        "transparent"
                                            }}>
                                                <Col xl={3}>
                                                    {area.name}
                                                </Col>
                                                <Col xl={8}>
                                                    <Select
                                                        value={area.product}
                                                        options={this.props.produkty}
                                                        onChange={(product: any) => this.props.handleChangeProductInArea(area, product)}
                                                        isClearable={false}
                                                        getOptionLabel={({producent, nazwa}: any) => (`${producent}, ${nazwa}`)}
                                                        getOptionValue={({id}: any) => id}
                                                        styles={SmallerSelectStyles}
                                                        components={{Option: customProduktComponent}}
                                                        placeholder={'Wybierz produkt...'}
                                                        isSearchable={!isMobileDevice()}
                                                    />
                                                </Col>
                                                <Col xl={1}>
                                                    <Button variant={'light'} size={'sm'}
                                                            onClick={() => {
                                                                this.props.handleDeleteArea(area);
                                                                this.clearCanvasArea()
                                                            }}>
                                                        <IconTimes/>
                                                    </Button>
                                                </Col>
                                            </Row>
                                        ))
                                    }
                                </div>
                                <div style={{marginTop: '15px'}}>
                                    <h5>current shape:</h5>
                                    {JSON.stringify(this.state.currentShape)}
                                </div>
                                <div>
                                    <h5>current cursor position:</h5>
                                    {JSON.stringify(this.state.currentCursorPosition)}
                                </div>
                                <div>
                                    <h5>prop gazetkaAreasMap</h5>
                                    <textarea value={JSON.stringify(gazetkaAreasMap[currentStrona], null, 3)}
                                              disabled={true}
                                              rows={20} className={'form-control'}></textarea>
                                </div>


                            </Col>
                        </Row>
                        :
                        <text className={'text-danger'}>Nie zapisano plików w poprzedniej zakładce</text>
                }
            </div>
        );
    }
}

export default PromocjaEditorForm;