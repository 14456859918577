export const API_getProducts = () => [
    {
        id: 1,
        indeks: 'TOP-00011',
        producent: 'FOODCARE',
        nazwa: '4MOVE Izotonik Multifruit PET 750ml',
        imagePath: 'https://selgros24.pl/images/prodImages/4MOVE_4MOVE_izotonik_multifruit_750ml_63083851_0_350_350.jpg',
        ilJm2: 12,
        jm2: 'dis',
        jm: 'szt',
        promocja: null,
        cenaKatalogowa: 3.13,
        cena: 3.13,
        czyDostepny: true,
        idKt: 3,
        idPk: 34,
        slug: 'top-00011-4move-izotonik-miltifruit-pet-750-ml',
        url: '/oferta/napoje-i-woda/napoje-niegazowane/top-00011-4move-izotonik-miltifruit-pet-750-ml',
        ileOpakowanWarstwa: 10,
        ileOpakowanPaleta: 100,
        ileKoszyk: 24,
    },
    {
        id: 2,
        indeks: 'TOP-00012',
        producent: 'FOODCARE',
        nazwa: '4MOVE Vitamin Magnez 330ml',
        imagePath: 'https://www.leclerc.rzeszow.pl/foto_shop/174/5900552067269_T1.jpg',
        ilJm2: 24,
        jm2: 'dis',
        jm: 'szt',
        promocja: null,
        cenaKatalogowa: 3.16,
        cena: 3.06,
        czyDostepny: true,
        idKt: 3,
        idPk: 34,
        slug: 'top-00012-4move-vitamin-magnez-330ml',
        url: '/oferta/napoje-i-woda/napoje-niegazowane/top-00012-4move-vitamin-magnez-330ml',
        ileOpakowanWarstwa: 10,
        ileOpakowanPaleta: 100,
        ileKoszyk: 0,
    },
    {
        id: 3,
        indeks: 'TOP-00013',
        producent: 'WRIGLEYS',
        nazwa: 'Airwaves Classic 10 drażetek testowy długa nazwa produktu test',
        imagePath: 'https://esklepikszkolny.pl/zasoby/import/4/42070047.jpg',
        ilJm2: 30,
        jm2: 'dis',
        jm: 'szt',
        promocja: 1,
        cenaKatalogowa: 1.58,
        cena: 1.34,
        czyDostepny: false,
        idKt: 7,
        idPk: 74,
        slug: 'top-00013-airwaves-classic-10-drazetek',
        url: '/oferta/slodycze-i-przekaski/gumy-do-zucia/top-00013-airwaves-classic-10-drazetek',
        ileOpakowanWarstwa: 100,
        ileOpakowanPaleta: 500,
        ileKoszyk: 30,
    },
    {
        id: 4,
        indeks: 'TOP-00014',
        producent: 'WRIGLEYS',
        nazwa: 'Airwaves Cherry menthol 10 drażetek',
        imagePath: 'https://esklepikszkolny.pl/zasoby/import/4/42102120.jpg',
        ilJm2: 30,
        jm2: 'dis',
        jm: 'szt',
        promocja: 1,
        cenaKatalogowa: 1.58,
        cena: 1.34,
        czyDostepny: true,
        idKt: 7,
        idPk: 74,
        slug: 'top-00014-airwaves-cherry-10-drazetek',
        url: '/oferta/slodycze-i-przekaski/gumy-do-zucia/top-00014-airwaves-cherry-10-drazetek',
        ileOpakowanWarstwa: 100,
        ileOpakowanPaleta: 500,
        ileKoszyk: 0,
    },
    {
        id: 5,
        indeks: 'TOP-00015',
        producent: 'MONDELEZ',
        nazwa: 'Alpen Gold czekolada Nussbeisser Almond 100g',
        imagePath: 'https://selgros24.pl/images/prodImages/Nussbeisser_Nussbeisser_Almond_100g_52233749_0_350_350.jpg',
        ilJm2: 20,
        jm2: 'krt',
        jm: 'szt',
        promocja: null,
        cenaKatalogowa: 5.5,
        cena: 5.5,
        czyDostepny: true,
        idKt: 7,
        idPk: 72,
        slug: 'top-00015-alpen-gold-nussbeisser-almond-100g',
        url: '/oferta/slodycze-i-przekaski/czekolady-i-czekoladki/top-00015-alpen-gold-nussbeisser-almond-100g',
        ileOpakowanWarstwa: 20,
        ileOpakowanPaleta: 480,
        ileKoszyk: 40,
    },
    {
        id: 6,
        indeks: 'TOP-00016',
        producent: 'Dr.Gerard',
        nazwa: 'Biszkopciki Jaśki morelowe luz',
        imagePath: 'https://dodomku.pl/images/products_new/400x400/00046112.jpg',
        ilJm2: 0.5,
        jm2: 'krt',
        jm: 'kg',
        promocja: null,
        cenaKatalogowa: 19.05,
        cena: 16.48,
        czyDostepny: true,
        idKt: 7,
        idPk: 78,
        slug: 'top-00016-dr-gerard-biszkopciki-jaski-morelowe-luz',
        url: '/oferta/slodycze-i-przekaski/pozostale-slodycze/top-00016-dr-gerard-biszkopciki-jaski-morelowe-luz',
        ileOpakowanWarstwa: 5,
        ileOpakowanPaleta: 50,
        ileKoszyk: 0,
    },
    {
        id: 7,
        indeks: 'TOP-00017',
        producent: 'Storck',
        nazwa: 'Toffifee 12',
        imagePath: 'https://image.ceneostatic.pl/data/products/76072939/i-storck-toffifee-100g.jpg',
        ilJm2: 10,
        jm2: 'dis',
        jm: 'szt',
        promocja: null,
        cenaKatalogowa: 6.44,
        cena: 6.78,
        czyDostepny: true,
        idKt: 7,
        idPk: 78,
        slug: 'top-00017-storck-toffifee-12',
        url: '/oferta/slodycze-i-przekaski/pozostale-slodycze/top-00017-storck-toffifee-12',
        ileOpakowanWarstwa: 10,
        ileOpakowanPaleta: 100,
        ileKoszyk: 0,
    },
    {
        id: 8,
        indeks: 'TOP-00018',
        producent: 'Storck',
        nazwa: 'Nimm2 90g',
        imagePath: 'https://image.ceneostatic.pl/data/products/15168447/i-nimm2-pomaranczowe-i-cytrynowe-cukierki-z-sokiem-owocowym-i-witaminami-90g.jpg',
        ilJm2: 25,
        jm2: 'dis',
        jm: 'szt',
        promocja: null,
        cenaKatalogowa: 3.30,
        cena: 3.50,
        czyDostepny: true,
        idKt: 7,
        idPk: 73,
        slug: 'top-00018-storck-nimm2-90g',
        url: '/oferta/slodycze-i-przekaski/cukierki-i-draze/top-00018-storck-nimm2-90g',
        ileOpakowanWarstwa: 10,
        ileOpakowanPaleta: 50,
        ileKoszyk: 0,
    },
    {
        id: 9,
        indeks: 'TOP-00019',
        producent: 'Storck',
        nazwa: 'Ice fresh 125g',
        imagePath: 'https://image.ceneostatic.pl/data/products/15168425/f-storck-cukierki-ice-fresh-125g.jpg',
        ilJm2: 20,
        jm2: 'dis',
        jm: 'szt',
        promocja: null,
        cenaKatalogowa: 3.29,
        cena: 3.59,
        czyDostepny: true,
        idKt: 7,
        idPk: 73,
        slug: 'top-00019-storck-ice-fresh-125g',
        url: '/oferta/slodycze-i-przekaski/cukierki-i-draze/top-00019-storck-ice-fresh-125g',
        ileOpakowanWarstwa: 10,
        ileOpakowanPaleta: 50,
        ileKoszyk: 0,
    },
    {
        id: 10,
        indeks: 'TOP-00020',
        producent: 'Jutrzenka',
        nazwa: 'Jeżyki 140g',
        imagePath: 'https://image.ceneostatic.pl/data/products/10638495/i-jutrzenka-jezyki-classic-140g.jpg',
        ilJm2: 5,
        jm2: 'dis',
        jm: 'szt',
        promocja: null,
        cenaKatalogowa: 3.28,
        cena: 3.28,
        czyDostepny: true,
        idKt: 7,
        idPk: 78,
        slug: 'top-00020-jutrzenka-jezyki-140g',
        url: '/oferta/slodycze-i-przekaski/pozostale-slodycze/top-00020-jutrzenka-jezyki-140g',
        ileOpakowanWarstwa: 10,
        ileOpakowanPaleta: 30,
        ileKoszyk: 0,
    },
    {
        id: 11,
        indeks: 'TOP-00021',
        producent: 'MONDELEZ',
        nazwa: 'Milka 100g',
        imagePath: 'https://thumbs.dreamstime.com/z/bar-milka-mondelez-alpenmilch-dojna-czekolada-91437973.jpg',
        ilJm2: 20,
        jm2: 'krt',
        jm: 'szt',
        promocja: null,
        cenaKatalogowa: 4.5,
        cena: 4.5,
        czyDostepny: true,
        idKt: 7,
        idPk: 72,
        slug: 'top-00021-mondelez-milka-100g',
        url: '/oferta/slodycze-i-przekaski/czekolady-i-czekoladki/top-00021-mondelez-milka-100g',
        ileOpakowanWarstwa: 20,
        ileOpakowanPaleta: 480,
        ileKoszyk: 0,
    },
];

export const API_getPromocje = () => [
    {
        id: 1,
        name: 'Przykładowa promocja na gumy do żucia Airwaves na miesiąc marzec 2020',
        dateAvailability: '2020-03-01 - 2020-03-31',
        czyAktywna: true,
        czyGazetka: false,
        okladka: 'https://images-na.ssl-images-amazon.com/images/I/71Xa54xrWNL._SL1500_.jpg',
        uwagi: 'Zakup promocji pakietowej prosimy zgłaszać do oddziału X. Ceny promocyjne przy "zakupie od x krt." podać w uwagach zamówienia.',
        produktyWPromocji: [
            {
                id: 3,
                indeks: 'TOP-00013',
                producent: 'WRIGLEYS',
                nazwa: 'Airwaves Classic 10 drażetek testowy długa nazwa produktu test',
                imagePath: 'https://esklepikszkolny.pl/zasoby/import/4/42070047.jpg',
                ilJm2: 30,
                ilJm3: 90,
                jm2: 'dis',
                jm: 'szt',
                promocja: 1,
                cenaKatalogowa: 1.58,
                cena: 1.34,
                czyDostepny: false,
                idKt: 7,
                idPk: 74,
                slug: 'top-00013-airwaves-classic-10-drazetek',
                url: '/oferta/slodycze-i-przekaski/gumy-do-zucia/top-00013-airwaves-classic-10-drazetek',
                ileOpakowanWarstwa: 100,
                ileOpakowanPaleta: 500,
                ileKoszyk: 30,
            },
            {
                id: 4,
                indeks: 'TOP-00014',
                producent: 'WRIGLEYS',
                nazwa: 'Airwaves Cherry menthol 10 drażetek',
                imagePath: 'https://esklepikszkolny.pl/zasoby/import/4/42102120.jpg',
                ilJm2: 30,
                ilJm3: 90,
                jm2: 'dis',
                jm: 'szt',
                promocja: 1,
                cenaKatalogowa: 1.58,
                cena: 1.34,
                czyDostepny: true,
                idKt: 7,
                idPk: 74,
                slug: 'top-00014-airwaves-cherry-10-drazetek',
                url: '/oferta/slodycze-i-przekaski/gumy-do-zucia/top-00014-airwaves-cherry-10-drazetek',
                ileOpakowanWarstwa: 100,
                ileOpakowanPaleta: 500,
                ileKoszyk: 0,
            }
        ]
    },
    {
        id: 2,
        name: 'Promocja na wszystkie produkty storck -20%',
        dateAvailability: '2020-03-01 - 2020-03-31',
        czyAktywna: false,
        czyGazetka: false,
        okladka: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/95/Logo_Storck.svg/1200px-Logo_Storck.svg.png',
        uwagi: '',
        produktyWPromocji: []
    },
    {
        id: 3,
        name: 'Przykładowa promocja 3',
        dateAvailability: '2020-03-01 - 2020-03-31',
        czyAktywna: false,
        czyGazetka: false,
        okladka: null,
        uwagi: '',
        produktyWPromocji: []
    },
    {
        id: 4,
        name: 'Przykładowa gazetka maj 2020',
        dateAvailability: '2020-05-01 - 2020-05-31',
        czyAktywna: true,
        czyGazetka: true,
        okladka: 'https://zapodaj.net/images/284b65f83f527.jpg',
        uwagi: 'Gazetka na miesiąc maj 2020. Ilość towaru ograniczona.',
        produktyWPromocji: [
            {
                id: 7,
                indeks: 'TOP-00017',
                producent: 'Storck',
                nazwa: 'Toffifee 12',
                imagePath: 'https://image.ceneostatic.pl/data/products/76072939/i-storck-toffifee-100g.jpg',
                ilJm2: 10,
                jm2: 'dis',
                jm: 'szt',
                promocja: null,
                cenaKatalogowa: 6.44,
                cena: 6.78,
                czyDostepny: true,
                idKt: 7,
                idPk: 78,
                slug: 'top-00017-storck-toffifee-12',
                url: '/oferta/slodycze-i-przekaski/pozostale-slodycze/top-00017-storck-toffifee-12',
                ileOpakowanWarstwa: 10,
                ileOpakowanPaleta: 100,
                ileKoszyk: 0,
            },
            {
                id: 8,
                indeks: 'TOP-00018',
                producent: 'Storck',
                nazwa: 'Nimm2 90g',
                imagePath: 'https://image.ceneostatic.pl/data/products/15168447/i-nimm2-pomaranczowe-i-cytrynowe-cukierki-z-sokiem-owocowym-i-witaminami-90g.jpg',
                ilJm2: 25,
                jm2: 'dis',
                jm: 'szt',
                promocja: null,
                cenaKatalogowa: 3.30,
                cena: 3.50,
                czyDostepny: true,
                idKt: 7,
                idPk: 73,
                slug: 'top-00018-storck-nimm2-90g',
                url: '/oferta/slodycze-i-przekaski/cukierki-i-draze/top-00018-storck-nimm2-90g',
                ileOpakowanWarstwa: 10,
                ileOpakowanPaleta: 50,
                ileKoszyk: 0,
            },
            {
                id: 9,
                indeks: 'TOP-00019',
                producent: 'Storck',
                nazwa: 'Ice fresh 125g',
                imagePath: 'https://image.ceneostatic.pl/data/products/15168425/f-storck-cukierki-ice-fresh-125g.jpg',
                ilJm2: 20,
                jm2: 'dis',
                jm: 'szt',
                promocja: null,
                cenaKatalogowa: 3.29,
                cena: 3.59,
                czyDostepny: true,
                idKt: 7,
                idPk: 73,
                slug: 'top-00019-storck-ice-fresh-125g',
                url: '/oferta/slodycze-i-przekaski/cukierki-i-draze/top-00019-storck-ice-fresh-125g',
                ileOpakowanWarstwa: 10,
                ileOpakowanPaleta: 50,
                ileKoszyk: 0,
            },
            {
                id: 11,
                indeks: 'TOP-00021',
                producent: 'MONDELEZ',
                nazwa: 'Milka 100g',
                imagePath: 'https://thumbs.dreamstime.com/z/bar-milka-mondelez-alpenmilch-dojna-czekolada-91437973.jpg',
                ilJm2: 20,
                jm2: 'krt',
                jm: 'szt',
                promocja: null,
                cenaKatalogowa: 4.5,
                cena: 4.5,
                czyDostepny: true,
                idKt: 7,
                idPk: 72,
                slug: 'top-00021-mondelez-milka-100g',
                url: '/oferta/slodycze-i-przekaski/czekolady-i-czekoladki/top-00021-mondelez-milka-100g',
                ileOpakowanWarstwa: 20,
                ileOpakowanPaleta: 480,
                ileKoszyk: 0,
            },
            {
                id: 5,
                indeks: 'TOP-00015',
                producent: 'MONDELEZ',
                nazwa: 'Alpen Gold czekolada Nussbeisser Almond 100g',
                imagePath: 'https://selgros24.pl/images/prodImages/Nussbeisser_Nussbeisser_Almond_100g_52233749_0_350_350.jpg',
                ilJm2: 20,
                jm2: 'krt',
                jm: 'szt',
                promocja: null,
                cenaKatalogowa: 5.5,
                cena: 5.5,
                czyDostepny: true,
                idKt: 7,
                idPk: 72,
                slug: 'top-00015-alpen-gold-nussbeisser-almond-100g',
                url: '/oferta/slodycze-i-przekaski/czekolady-i-czekoladki/top-00015-alpen-gold-nussbeisser-almond-100g',
                ileOpakowanWarstwa: 20,
                ileOpakowanPaleta: 480,
                ileKoszyk: 40,
            },
            {
                id: 1,
                indeks: 'TOP-00011',
                producent: 'FOODCARE',
                nazwa: '4MOVE Izotonik Multifruit PET 750ml',
                imagePath: 'https://selgros24.pl/images/prodImages/4MOVE_4MOVE_izotonik_multifruit_750ml_63083851_0_350_350.jpg',
                ilJm2: 12,
                jm2: 'dis',
                jm: 'szt',
                promocja: null,
                cenaKatalogowa: 3.13,
                cena: 3.13,
                czyDostepny: true,
                idKt: 3,
                idPk: 34,
                slug: 'top-00011-4move-izotonik-miltifruit-pet-750-ml',
                url: '/oferta/napoje-i-woda/napoje-niegazowane/top-00011-4move-izotonik-miltifruit-pet-750-ml',
                ileOpakowanWarstwa: 10,
                ileOpakowanPaleta: 100,
                ileKoszyk: 24,
            },
            {
                id: 2,
                indeks: 'TOP-00012',
                producent: 'FOODCARE',
                nazwa: '4MOVE Vitamin Magnez 330ml',
                imagePath: 'https://www.leclerc.rzeszow.pl/foto_shop/174/5900552067269_T1.jpg',
                ilJm2: 24,
                jm2: 'dis',
                jm: 'szt',
                promocja: null,
                cenaKatalogowa: 3.16,
                cena: 3.06,
                czyDostepny: true,
                idKt: 3,
                idPk: 34,
                slug: 'top-00012-4move-vitamin-magnez-330ml',
                url: '/oferta/napoje-i-woda/napoje-niegazowane/top-00012-4move-vitamin-magnez-330ml',
                ileOpakowanWarstwa: 10,
                ileOpakowanPaleta: 100,
                ileKoszyk: 0,
            },
        ],
        strony: [
            {
                "strona": 0,
                "plik": "https://zapodaj.net/images/284b65f83f527.jpg",
                "name": "gazetka-strona-0",
                "areas": [
                    {
                        "name": "s-0-a-1",
                        "shape": "poly",
                        "coords": [
                            10.5,
                            186,
                            128.5,
                            186,
                            128.5,
                            295,
                            10.5,
                            295
                        ],
                        "preFillColor": "rgba(45,136,188,0.2)",
                        "product": {
                            "id": 7,
                            "indeks": "TOP-00017",
                            "producent": "Storck",
                            "nazwa": "Toffifee 12",
                            "imagePath": "https://image.ceneostatic.pl/data/products/76072939/i-storck-toffifee-100g.jpg",
                            "ilJm2": 10,
                            "jm2": "dis",
                            "jm": "szt",
                            "promocja": null,
                            "cenaKatalogowa": 6.44,
                            "cena": 6.78,
                            "czyDostepny": true,
                            "idKt": 7,
                            "idPk": 78,
                            "slug": "top-00017-storck-toffifee-12",
                            "url": "/oferta/slodycze-i-przekaski/pozostale-slodycze/top-00017-storck-toffifee-12",
                            "ileOpakowanWarstwa": 10,
                            "ileOpakowanPaleta": 100,
                            "ileKoszyk": 0,
                        },
                        "canvasArea": [
                            {
                                "x": 10.5,
                                "y": 186
                            },
                            {
                                "x": 128.5,
                                "y": 295
                            }
                        ]
                    },
                    {
                        "name": "s-0-a-2",
                        "shape": "poly",
                        "coords": [
                            138.5,
                            204,
                            236.5,
                            204,
                            236.5,
                            302,
                            138.5,
                            302
                        ],
                        "preFillColor": "rgba(45,136,188,0.2)",
                        "product": {
                            "id": 9,
                            "indeks": "TOP-00019",
                            "producent": "Storck",
                            "nazwa": "Ice fresh 125g",
                            "imagePath": "https://image.ceneostatic.pl/data/products/15168425/f-storck-cukierki-ice-fresh-125g.jpg",
                            "ilJm2": 20,
                            "jm2": "dis",
                            "jm": "szt",
                            "promocja": null,
                            "cenaKatalogowa": 3.29,
                            "cena": 3.59,
                            "czyDostepny": true,
                            "idKt": 7,
                            "idPk": 73,
                            "slug": "top-00019-storck-ice-fresh-125g",
                            "url": "/oferta/slodycze-i-przekaski/cukierki-i-draze/top-00019-storck-ice-fresh-125g",
                            "ileOpakowanWarstwa": 10,
                            "ileOpakowanPaleta": 50,
                            "ileKoszyk": 0,
                        },
                        "canvasArea": [
                            {
                                "x": 138.5,
                                "y": 204
                            },
                            {
                                "x": 236.5,
                                "y": 302
                            }
                        ]
                    },
                    {
                        "name": "s-0-a-3",
                        "shape": "poly",
                        "coords": [
                            264.5,
                            202,
                            375.5,
                            202,
                            375.5,
                            305,
                            264.5,
                            305
                        ],
                        "preFillColor": "rgba(45,136,188,0.2)",
                        "product": {
                            "id": 8,
                            "indeks": "TOP-00018",
                            "producent": "Storck",
                            "nazwa": "Nimm2 90g",
                            "imagePath": "https://image.ceneostatic.pl/data/products/15168447/i-nimm2-pomaranczowe-i-cytrynowe-cukierki-z-sokiem-owocowym-i-witaminami-90g.jpg",
                            "ilJm2": 25,
                            "jm2": "dis",
                            "jm": "szt",
                            "promocja": null,
                            "cenaKatalogowa": 3.3,
                            "cena": 3.5,
                            "czyDostepny": true,
                            "idKt": 7,
                            "idPk": 73,
                            "slug": "top-00018-storck-nimm2-90g",
                            "url": "/oferta/slodycze-i-przekaski/cukierki-i-draze/top-00018-storck-nimm2-90g",
                            "ileOpakowanWarstwa": 10,
                            "ileOpakowanPaleta": 50,
                            "ileKoszyk": 0,
                        },
                        "canvasArea": [
                            {
                                "x": 264.5,
                                "y": 202
                            },
                            {
                                "x": 375.5,
                                "y": 305
                            }
                        ]
                    },
                    {
                        "name": "s-0-a-4",
                        "shape": "poly",
                        "coords": [
                            29.5,
                            444,
                            151.5,
                            444,
                            151.5,
                            547,
                            29.5,
                            547
                        ],
                        "preFillColor": "rgba(45,136,188,0.2)",
                        "product": {
                            "id": 11,
                            "indeks": "TOP-00021",
                            "producent": "MONDELEZ",
                            "nazwa": "Milka 100g",
                            "imagePath": "https://thumbs.dreamstime.com/z/bar-milka-mondelez-alpenmilch-dojna-czekolada-91437973.jpg",
                            "ilJm2": 20,
                            "jm2": "krt",
                            "jm": "szt",
                            "promocja": null,
                            "cenaKatalogowa": 4.5,
                            "cena": 4.5,
                            "czyDostepny": true,
                            "idKt": 7,
                            "idPk": 72,
                            "slug": "top-00021-mondelez-milka-100g",
                            "url": "/oferta/slodycze-i-przekaski/czekolady-i-czekoladki/top-00021-mondelez-milka-100g",
                            "ileOpakowanWarstwa": 20,
                            "ileOpakowanPaleta": 480,
                            "ileKoszyk": 0,
                        },
                        "canvasArea": [
                            {
                                "x": 29.5,
                                "y": 444
                            },
                            {
                                "x": 151.5,
                                "y": 547
                            }
                        ]
                    },
                    {
                        "name": "s-0-a-5",
                        "shape": "poly",
                        "coords": [
                            215.5,
                            444,
                            326.5,
                            444,
                            326.5,
                            534,
                            215.5,
                            534
                        ],
                        "preFillColor": "rgba(45,136,188,0.2)",
                        "product": {
                            "id": 5,
                            "indeks": "TOP-00015",
                            "producent": "MONDELEZ",
                            "nazwa": "Alpen Gold czekolada Nussbeisser Almond 100g",
                            "imagePath": "https://selgros24.pl/images/prodImages/Nussbeisser_Nussbeisser_Almond_100g_52233749_0_350_350.jpg",
                            "ilJm2": 20,
                            "jm2": "krt",
                            "jm": "szt",
                            "promocja": null,
                            "cenaKatalogowa": 5.5,
                            "cena": 5.5,
                            "czyDostepny": true,
                            "idKt": 7,
                            "idPk": 72,
                            "slug": "top-00015-alpen-gold-nussbeisser-almond-100g",
                            "url": "/oferta/slodycze-i-przekaski/czekolady-i-czekoladki/top-00015-alpen-gold-nussbeisser-almond-100g",
                            "ileOpakowanWarstwa": 20,
                            "ileOpakowanPaleta": 480,
                            "ileKoszyk": 40,
                        },
                        "canvasArea": [
                            {
                                "x": 215.5,
                                "y": 444
                            },
                            {
                                "x": 326.5,
                                "y": 534
                            }
                        ]
                    }
                ],
                "imgDimensions": {
                    "width": 400,
                    "height": 600
                }
            },
            {
                "strona": 1,
                "plik": "https://zapodaj.net/images/55ba303f79b45.jpg",
                "name": "gazetka-strona-1",
                "areas": [
                    {
                        "name": "s-1-a-1",
                        "shape": "poly",
                        "coords": [
                            39.5,
                            132,
                            374.5,
                            132,
                            374.5,
                            221,
                            39.5,
                            221
                        ],
                        "preFillColor": "rgba(45,136,188,0.2)",
                        "product": {
                            "id": 1,
                            "indeks": "TOP-00011",
                            "producent": "FOODCARE",
                            "nazwa": "4MOVE Izotonik Multifruit PET 750ml",
                            "imagePath": "https://selgros24.pl/images/prodImages/4MOVE_4MOVE_izotonik_multifruit_750ml_63083851_0_350_350.jpg",
                            "ilJm2": 12,
                            "jm2": "dis",
                            "jm": "szt",
                            "promocja": null,
                            "cenaKatalogowa": 3.13,
                            "cena": 3.13,
                            "czyDostepny": true,
                            "idKt": 3,
                            "idPk": 34,
                            "slug": "top-00011-4move-izotonik-miltifruit-pet-750-ml",
                            "url": "/oferta/napoje-i-woda/napoje-niegazowane/top-00011-4move-izotonik-miltifruit-pet-750-ml",
                            "ileOpakowanWarstwa": 10,
                            "ileOpakowanPaleta": 100,
                            "ileKoszyk": 24,
                        },
                        "canvasArea": [
                            {
                                "x": 39.5,
                                "y": 132
                            },
                            {
                                "x": 374.5,
                                "y": 221
                            }
                        ]
                    },
                    {
                        "name": "s-1-a-2",
                        "shape": "poly",
                        "coords": [
                            7.5,
                            265,
                            314.5,
                            265,
                            314.5,
                            347,
                            7.5,
                            347
                        ],
                        "preFillColor": "rgba(45,136,188,0.2)",
                        "product": {
                            "id": 2,
                            "indeks": "TOP-00012",
                            "producent": "FOODCARE",
                            "nazwa": "4MOVE Vitamin Magnez 330ml",
                            "imagePath": "https://www.leclerc.rzeszow.pl/foto_shop/174/5900552067269_T1.jpg",
                            "ilJm2": 24,
                            "jm2": "dis",
                            "jm": "szt",
                            "promocja": null,
                            "cenaKatalogowa": 3.16,
                            "cena": 3.06,
                            "czyDostepny": true,
                            "idKt": 3,
                            "idPk": 34,
                            "slug": "top-00012-4move-vitamin-magnez-330ml",
                            "url": "/oferta/napoje-i-woda/napoje-niegazowane/top-00012-4move-vitamin-magnez-330ml",
                            "ileOpakowanWarstwa": 10,
                            "ileOpakowanPaleta": 100,
                            "ileKoszyk": 0,
                        },
                        "canvasArea": [
                            {
                                "x": 7.5,
                                "y": 265
                            },
                            {
                                "x": 314.5,
                                "y": 347
                            }
                        ]
                    }
                ],
                "imgDimensions": {
                    "width": 400,
                    "height": 600
                }
            }
        ]
    },
    {
        id: 5,
        name: 'Testowa gazetka rozne rozmiary grafik',
        dateAvailability: '2020-05-01 - 2020-05-31',
        czyAktywna: true,
        czyGazetka: true,
        okladka: 'https://zapodaj.net/images/27af93bed5bd9.jpg',
        uwagi: 'Test różnych rozmiarów grafik ',
        produktyWPromocji: [
            {
                "id": 1,
                "indeks": "TOP-00011",
                "producent": "FOODCARE",
                "nazwa": "4MOVE Izotonik Multifruit PET 750ml",
                "imagePath": "https://selgros24.pl/images/prodImages/4MOVE_4MOVE_izotonik_multifruit_750ml_63083851_0_350_350.jpg",
                "ilJm2": 12,
                "jm2": "dis",
                "jm": "szt",
                "promocja": null,
                "cenaKatalogowa": 3.13,
                "cena": 3.13,
                "czyDostepny": true,
                "idKt": 3,
                "idPk": 34,
                "slug": "top-00011-4move-izotonik-miltifruit-pet-750-ml",
                "url": "/oferta/napoje-i-woda/napoje-niegazowane/top-00011-4move-izotonik-miltifruit-pet-750-ml",
                "ileOpakowanWarstwa": 10,
                "ileOpakowanPaleta": 100,
                "ileKoszyk": 24
            },
            {
                "id": 2,
                "indeks": "TOP-00012",
                "producent": "FOODCARE",
                "nazwa": "4MOVE Vitamin Magnez 330ml",
                "imagePath": "https://www.leclerc.rzeszow.pl/foto_shop/174/5900552067269_T1.jpg",
                "ilJm2": 24,
                "jm2": "dis",
                "jm": "szt",
                "promocja": null,
                "cenaKatalogowa": 3.16,
                "cena": 3.06,
                "czyDostepny": true,
                "idKt": 3,
                "idPk": 34,
                "slug": "top-00012-4move-vitamin-magnez-330ml",
                "url": "/oferta/napoje-i-woda/napoje-niegazowane/top-00012-4move-vitamin-magnez-330ml",
                "ileOpakowanWarstwa": 10,
                "ileOpakowanPaleta": 100,
                "ileKoszyk": 0
            },
            {
                "id": 3,
                "indeks": "TOP-00013",
                "producent": "WRIGLEYS",
                "nazwa": "Airwaves Classic 10 drażetek testowy długa nazwa produktu test",
                "imagePath": "https://esklepikszkolny.pl/zasoby/import/4/42070047.jpg",
                "ilJm2": 30,
                "jm2": "dis",
                "jm": "szt",
                "promocja": 1,
                "cenaKatalogowa": 1.58,
                "cena": 1.34,
                "czyDostepny": false,
                "idKt": 7,
                "idPk": 74,
                "slug": "top-00013-airwaves-classic-10-drazetek",
                "url": "/oferta/slodycze-i-przekaski/gumy-do-zucia/top-00013-airwaves-classic-10-drazetek",
                "ileOpakowanWarstwa": 100,
                "ileOpakowanPaleta": 500,
                "ileKoszyk": 30
            },
            {
                "id": 7,
                "indeks": "TOP-00017",
                "producent": "Storck",
                "nazwa": "Toffifee 12",
                "imagePath": "https://image.ceneostatic.pl/data/products/76072939/i-storck-toffifee-100g.jpg",
                "ilJm2": 10,
                "jm2": "dis",
                "jm": "szt",
                "promocja": null,
                "cenaKatalogowa": 6.44,
                "cena": 6.78,
                "czyDostepny": true,
                "idKt": 7,
                "idPk": 78,
                "slug": "top-00017-storck-toffifee-12",
                "url": "/oferta/slodycze-i-przekaski/pozostale-slodycze/top-00017-storck-toffifee-12",
                "ileOpakowanWarstwa": 10,
                "ileOpakowanPaleta": 100,
                "ileKoszyk": 0
            },
            {
                "id": 8,
                "indeks": "TOP-00018",
                "producent": "Storck",
                "nazwa": "Nimm2 90g",
                "imagePath": "https://image.ceneostatic.pl/data/products/15168447/i-nimm2-pomaranczowe-i-cytrynowe-cukierki-z-sokiem-owocowym-i-witaminami-90g.jpg",
                "ilJm2": 25,
                "jm2": "dis",
                "jm": "szt",
                "promocja": null,
                "cenaKatalogowa": 3.3,
                "cena": 3.5,
                "czyDostepny": true,
                "idKt": 7,
                "idPk": 73,
                "slug": "top-00018-storck-nimm2-90g",
                "url": "/oferta/slodycze-i-przekaski/cukierki-i-draze/top-00018-storck-nimm2-90g",
                "ileOpakowanWarstwa": 10,
                "ileOpakowanPaleta": 50,
                "ileKoszyk": 0
            },
            {
                "id": 11,
                "indeks": "TOP-00021",
                "producent": "MONDELEZ",
                "nazwa": "Milka 100g",
                "imagePath": "https://thumbs.dreamstime.com/z/bar-milka-mondelez-alpenmilch-dojna-czekolada-91437973.jpg",
                "ilJm2": 20,
                "jm2": "krt",
                "jm": "szt",
                "promocja": null,
                "cenaKatalogowa": 4.5,
                "cena": 4.5,
                "czyDostepny": true,
                "idKt": 7,
                "idPk": 72,
                "slug": "top-00021-mondelez-milka-100g",
                "url": "/oferta/slodycze-i-przekaski/czekolady-i-czekoladki/top-00021-mondelez-milka-100g",
                "ileOpakowanWarstwa": 20,
                "ileOpakowanPaleta": 480,
                "ileKoszyk": 0
            },
            {
                "id": 10,
                "indeks": "TOP-00020",
                "producent": "Jutrzenka",
                "nazwa": "Jeżyki 140g",
                "imagePath": "https://image.ceneostatic.pl/data/products/10638495/i-jutrzenka-jezyki-classic-140g.jpg",
                "ilJm2": 5,
                "jm2": "dis",
                "jm": "szt",
                "promocja": null,
                "cenaKatalogowa": 3.28,
                "cena": 3.28,
                "czyDostepny": true,
                "idKt": 7,
                "idPk": 78,
                "slug": "top-00020-jutrzenka-jezyki-140g",
                "url": "/oferta/slodycze-i-przekaski/pozostale-slodycze/top-00020-jutrzenka-jezyki-140g",
                "ileOpakowanWarstwa": 10,
                "ileOpakowanPaleta": 30,
                "ileKoszyk": 0
            }

        ],
        strony: [
            {
                "strona": 0,
                "plik": "https://zapodaj.net/images/7819badd07146.jpg",
                "name": "gazetka-strona-0",
                "areas": [
                    {
                        "name": "s-0-a-1",
                        "shape": "poly",
                        "coords": [
                            68.5,
                            267,
                            283.5,
                            267,
                            283.5,
                            340,
                            68.5,
                            340
                        ],
                        "preFillColor": "rgba(45,136,188,0.2)",
                        "product": {
                            "id": 1,
                            "indeks": "TOP-00011",
                            "producent": "FOODCARE",
                            "nazwa": "4MOVE Izotonik Multifruit PET 750ml",
                            "imagePath": "https://selgros24.pl/images/prodImages/4MOVE_4MOVE_izotonik_multifruit_750ml_63083851_0_350_350.jpg",
                            "ilJm2": 12,
                            "jm2": "dis",
                            "jm": "szt",
                            "promocja": null,
                            "cenaKatalogowa": 3.13,
                            "cena": 3.13,
                            "czyDostepny": true,
                            "idKt": 3,
                            "idPk": 34,
                            "slug": "top-00011-4move-izotonik-miltifruit-pet-750-ml",
                            "url": "/oferta/napoje-i-woda/napoje-niegazowane/top-00011-4move-izotonik-miltifruit-pet-750-ml",
                            "ileOpakowanWarstwa": 10,
                            "ileOpakowanPaleta": 100,
                            "ileKoszyk": 24
                        },
                        "canvasArea": [
                            {
                                "x": 68.5,
                                "y": 267
                            },
                            {
                                "x": 283.5,
                                "y": 340
                            }
                        ]
                    },
                    {
                        "name": "s-0-a-2",
                        "shape": "poly",
                        "coords": [
                            75.5,
                            441,
                            485.5,
                            441,
                            485.5,
                            499,
                            75.5,
                            499
                        ],
                        "preFillColor": "rgba(45,136,188,0.2)",
                        "product": {
                            "id": 2,
                            "indeks": "TOP-00012",
                            "producent": "FOODCARE",
                            "nazwa": "4MOVE Vitamin Magnez 330ml",
                            "imagePath": "https://www.leclerc.rzeszow.pl/foto_shop/174/5900552067269_T1.jpg",
                            "ilJm2": 24,
                            "jm2": "dis",
                            "jm": "szt",
                            "promocja": null,
                            "cenaKatalogowa": 3.16,
                            "cena": 3.06,
                            "czyDostepny": true,
                            "idKt": 3,
                            "idPk": 34,
                            "slug": "top-00012-4move-vitamin-magnez-330ml",
                            "url": "/oferta/napoje-i-woda/napoje-niegazowane/top-00012-4move-vitamin-magnez-330ml",
                            "ileOpakowanWarstwa": 10,
                            "ileOpakowanPaleta": 100,
                            "ileKoszyk": 0
                        },
                        "canvasArea": [
                            {
                                "x": 75.5,
                                "y": 441
                            },
                            {
                                "x": 485.5,
                                "y": 499
                            }
                        ]
                    },
                    {
                        "name": "s-0-a-3",
                        "shape": "poly",
                        "coords": [
                            90.5,
                            718,
                            244.5,
                            718,
                            244.5,
                            745,
                            90.5,
                            745
                        ],
                        "preFillColor": "rgba(45,136,188,0.2)",
                        "product": {
                            "id": 3,
                            "indeks": "TOP-00013",
                            "producent": "WRIGLEYS",
                            "nazwa": "Airwaves Classic 10 drażetek testowy długa nazwa produktu test",
                            "imagePath": "https://esklepikszkolny.pl/zasoby/import/4/42070047.jpg",
                            "ilJm2": 30,
                            "jm2": "dis",
                            "jm": "szt",
                            "promocja": 1,
                            "cenaKatalogowa": 1.58,
                            "cena": 1.34,
                            "czyDostepny": false,
                            "idKt": 7,
                            "idPk": 74,
                            "slug": "top-00013-airwaves-classic-10-drazetek",
                            "url": "/oferta/slodycze-i-przekaski/gumy-do-zucia/top-00013-airwaves-classic-10-drazetek",
                            "ileOpakowanWarstwa": 100,
                            "ileOpakowanPaleta": 500,
                            "ileKoszyk": 30
                        },
                        "canvasArea": [
                            {
                                "x": 90.5,
                                "y": 718
                            },
                            {
                                "x": 244.5,
                                "y": 745
                            }
                        ]
                    }
                ],
                "imgDimensions": {
                    "width": 600,
                    "height": 960
                }
            },
            {
                "strona": 1,
                "plik": "https://zapodaj.net/images/ab0644b1f7f57.jpg",
                "name": "gazetka-strona-1",
                "areas": [
                    {
                        "name": "s-1-a-1",
                        "shape": "poly",
                        "coords": [
                            27.5,
                            282,
                            203.5,
                            282,
                            203.5,
                            336,
                            27.5,
                            336
                        ],
                        "preFillColor": "rgba(45,136,188,0.2)",
                        "product": {
                            "id": 7,
                            "indeks": "TOP-00017",
                            "producent": "Storck",
                            "nazwa": "Toffifee 12",
                            "imagePath": "https://image.ceneostatic.pl/data/products/76072939/i-storck-toffifee-100g.jpg",
                            "ilJm2": 10,
                            "jm2": "dis",
                            "jm": "szt",
                            "promocja": null,
                            "cenaKatalogowa": 6.44,
                            "cena": 6.78,
                            "czyDostepny": true,
                            "idKt": 7,
                            "idPk": 78,
                            "slug": "top-00017-storck-toffifee-12",
                            "url": "/oferta/slodycze-i-przekaski/pozostale-slodycze/top-00017-storck-toffifee-12",
                            "ileOpakowanWarstwa": 10,
                            "ileOpakowanPaleta": 100,
                            "ileKoszyk": 0
                        },
                        "canvasArea": [
                            {
                                "x": 27.5,
                                "y": 282
                            },
                            {
                                "x": 203.5,
                                "y": 336
                            }
                        ]
                    },
                    {
                        "name": "s-1-a-2",
                        "shape": "poly",
                        "coords": [
                            44.5,
                            376,
                            296.5,
                            376,
                            296.5,
                            500,
                            44.5,
                            500
                        ],
                        "preFillColor": "rgba(45,136,188,0.2)",
                        "product": {
                            "id": 8,
                            "indeks": "TOP-00018",
                            "producent": "Storck",
                            "nazwa": "Nimm2 90g",
                            "imagePath": "https://image.ceneostatic.pl/data/products/15168447/i-nimm2-pomaranczowe-i-cytrynowe-cukierki-z-sokiem-owocowym-i-witaminami-90g.jpg",
                            "ilJm2": 25,
                            "jm2": "dis",
                            "jm": "szt",
                            "promocja": null,
                            "cenaKatalogowa": 3.3,
                            "cena": 3.5,
                            "czyDostepny": true,
                            "idKt": 7,
                            "idPk": 73,
                            "slug": "top-00018-storck-nimm2-90g",
                            "url": "/oferta/slodycze-i-przekaski/cukierki-i-draze/top-00018-storck-nimm2-90g",
                            "ileOpakowanWarstwa": 10,
                            "ileOpakowanPaleta": 50,
                            "ileKoszyk": 0
                        },
                        "canvasArea": [
                            {
                                "x": 44.5,
                                "y": 376
                            },
                            {
                                "x": 296.5,
                                "y": 500
                            }
                        ]
                    }
                ],
                "imgDimensions": {
                    "width": 350,
                    "height": 560
                }
            },
            {
                "strona": 2,
                "plik": "https://zapodaj.net/images/27af93bed5bd9.jpg",
                "name": "gazetka-strona-2",
                "areas": [
                    {
                        "name": "s-2-a-1",
                        "shape": "poly",
                        "coords": [
                            30.5,
                            177,
                            180.5,
                            177,
                            180.5,
                            223,
                            30.5,
                            223
                        ],
                        "preFillColor": "rgba(45,136,188,0.2)",
                        "product": {
                            "id": 11,
                            "indeks": "TOP-00021",
                            "producent": "MONDELEZ",
                            "nazwa": "Milka 100g",
                            "imagePath": "https://thumbs.dreamstime.com/z/bar-milka-mondelez-alpenmilch-dojna-czekolada-91437973.jpg",
                            "ilJm2": 20,
                            "jm2": "krt",
                            "jm": "szt",
                            "promocja": null,
                            "cenaKatalogowa": 4.5,
                            "cena": 4.5,
                            "czyDostepny": true,
                            "idKt": 7,
                            "idPk": 72,
                            "slug": "top-00021-mondelez-milka-100g",
                            "url": "/oferta/slodycze-i-przekaski/czekolady-i-czekoladki/top-00021-mondelez-milka-100g",
                            "ileOpakowanWarstwa": 20,
                            "ileOpakowanPaleta": 480,
                            "ileKoszyk": 0
                        },
                        "canvasArea": [
                            {
                                "x": 30.5,
                                "y": 177
                            },
                            {
                                "x": 180.5,
                                "y": 223
                            }
                        ]
                    },
                    {
                        "name": "s-2-a-2",
                        "shape": "poly",
                        "coords": [
                            233.5,
                            181,
                            383.5,
                            181,
                            383.5,
                            222,
                            233.5,
                            222
                        ],
                        "preFillColor": "rgba(45,136,188,0.2)",
                        "product": {
                            "id": 10,
                            "indeks": "TOP-00020",
                            "producent": "Jutrzenka",
                            "nazwa": "Jeżyki 140g",
                            "imagePath": "https://image.ceneostatic.pl/data/products/10638495/i-jutrzenka-jezyki-classic-140g.jpg",
                            "ilJm2": 5,
                            "jm2": "dis",
                            "jm": "szt",
                            "promocja": null,
                            "cenaKatalogowa": 3.28,
                            "cena": 3.28,
                            "czyDostepny": true,
                            "idKt": 7,
                            "idPk": 78,
                            "slug": "top-00020-jutrzenka-jezyki-140g",
                            "url": "/oferta/slodycze-i-przekaski/pozostale-slodycze/top-00020-jutrzenka-jezyki-140g",
                            "ileOpakowanWarstwa": 10,
                            "ileOpakowanPaleta": 30,
                            "ileKoszyk": 0
                        },
                        "canvasArea": [
                            {
                                "x": 233.5,
                                "y": 181
                            },
                            {
                                "x": 383.5,
                                "y": 222
                            }
                        ]
                    }
                ],
                "imgDimensions": {
                    "width": 450,
                    "height": 300
                }
            }
        ]
    },
    {
        id: 6,
        name: 'Testowa gazetka z stonki',
        dateAvailability: '2020-05-01 - 2020-05-31',
        czyAktywna: true,
        czyGazetka: true,
        okladka: 'https://zapodaj.net/images/28c827409cbf3.jpg',
        uwagi: 'Test wyglądu i działania przykładowej gazetki ',
        produktyWPromocji: [
            {
                "id": 7,
                "indeks": "TOP-00017",
                "producent": "Storck",
                "nazwa": "Toffifee 12",
                "imagePath": "https://image.ceneostatic.pl/data/products/76072939/i-storck-toffifee-100g.jpg",
                "ilJm2": 10,
                "jm2": "dis",
                "jm": "szt",
                "promocja": null,
                "cenaKatalogowa": 6.44,
                "cena": 6.78,
                "czyDostepny": true,
                "idKt": 7,
                "idPk": 78,
                "slug": "top-00017-storck-toffifee-12",
                "url": "/oferta/slodycze-i-przekaski/pozostale-slodycze/top-00017-storck-toffifee-12",
                "ileOpakowanWarstwa": 10,
                "ileOpakowanPaleta": 100,
                "ileKoszyk": 0
            },
            {
                "id": 8,
                "indeks": "TOP-00018",
                "producent": "Storck",
                "nazwa": "Nimm2 90g",
                "imagePath": "https://image.ceneostatic.pl/data/products/15168447/i-nimm2-pomaranczowe-i-cytrynowe-cukierki-z-sokiem-owocowym-i-witaminami-90g.jpg",
                "ilJm2": 25,
                "jm2": "dis",
                "jm": "szt",
                "promocja": null,
                "cenaKatalogowa": 3.3,
                "cena": 3.5,
                "czyDostepny": true,
                "idKt": 7,
                "idPk": 73,
                "slug": "top-00018-storck-nimm2-90g",
                "url": "/oferta/slodycze-i-przekaski/cukierki-i-draze/top-00018-storck-nimm2-90g",
                "ileOpakowanWarstwa": 10,
                "ileOpakowanPaleta": 50,
                "ileKoszyk": 0
            },
            {
                "id": 9,
                "indeks": "TOP-00019",
                "producent": "Storck",
                "nazwa": "Ice fresh 125g",
                "imagePath": "https://image.ceneostatic.pl/data/products/15168425/f-storck-cukierki-ice-fresh-125g.jpg",
                "ilJm2": 20,
                "jm2": "dis",
                "jm": "szt",
                "promocja": null,
                "cenaKatalogowa": 3.29,
                "cena": 3.59,
                "czyDostepny": true,
                "idKt": 7,
                "idPk": 73,
                "slug": "top-00019-storck-ice-fresh-125g",
                "url": "/oferta/slodycze-i-przekaski/cukierki-i-draze/top-00019-storck-ice-fresh-125g",
                "ileOpakowanWarstwa": 10,
                "ileOpakowanPaleta": 50,
                "ileKoszyk": 0
            }
        ],
        strony: [
            {
                "strona": 0,
                "plik": "https://zapodaj.net/images/28c827409cbf3.jpg",
                "name": "gazetka-strona-0",
                "areas": [
                    {
                        "name": "s-0-a-2",
                        "shape": "poly",
                        "coords": [
                            301.5,
                            185,
                            507.5,
                            185,
                            507.5,
                            356,
                            301.5,
                            356
                        ],
                        "preFillColor": "rgba(45,136,188,0.2)",
                        "product": {
                            "id": 7,
                            "indeks": "TOP-00017",
                            "producent": "Storck",
                            "nazwa": "Toffifee 12",
                            "imagePath": "https://image.ceneostatic.pl/data/products/76072939/i-storck-toffifee-100g.jpg",
                            "ilJm2": 10,
                            "jm2": "dis",
                            "jm": "szt",
                            "promocja": null,
                            "cenaKatalogowa": 6.44,
                            "cena": 6.78,
                            "czyDostepny": true,
                            "idKt": 7,
                            "idPk": 78,
                            "slug": "top-00017-storck-toffifee-12",
                            "url": "/oferta/slodycze-i-przekaski/pozostale-slodycze/top-00017-storck-toffifee-12",
                            "ileOpakowanWarstwa": 10,
                            "ileOpakowanPaleta": 100,
                            "ileKoszyk": 0
                        },
                        "canvasArea": [
                            {
                                "x": 301.5,
                                "y": 185
                            },
                            {
                                "x": 507.5,
                                "y": 356
                            }
                        ]
                    }
                ],
                "imgDimensions": {
                    "width": 508.5,
                    "height": 800
                }
            },
            {
                "strona": 1,
                "plik": "https://zapodaj.net/images/052e6ecfa6cce.jpg",
                "name": "gazetka-strona-1",
                "areas": [
                    {
                        "name": "s-1-a-1",
                        "shape": "poly",
                        "coords": [
                            106.5,
                            158,
                            213.5,
                            158,
                            213.5,
                            248,
                            106.5,
                            248
                        ],
                        "preFillColor": "rgba(45,136,188,0.2)",
                        "product": {
                            "id": 8,
                            "indeks": "TOP-00018",
                            "producent": "Storck",
                            "nazwa": "Nimm2 90g",
                            "imagePath": "https://image.ceneostatic.pl/data/products/15168447/i-nimm2-pomaranczowe-i-cytrynowe-cukierki-z-sokiem-owocowym-i-witaminami-90g.jpg",
                            "ilJm2": 25,
                            "jm2": "dis",
                            "jm": "szt",
                            "promocja": null,
                            "cenaKatalogowa": 3.3,
                            "cena": 3.5,
                            "czyDostepny": true,
                            "idKt": 7,
                            "idPk": 73,
                            "slug": "top-00018-storck-nimm2-90g",
                            "url": "/oferta/slodycze-i-przekaski/cukierki-i-draze/top-00018-storck-nimm2-90g",
                            "ileOpakowanWarstwa": 10,
                            "ileOpakowanPaleta": 50,
                            "ileKoszyk": 0
                        },
                        "canvasArea": [
                            {
                                "x": 106.5,
                                "y": 158
                            },
                            {
                                "x": 213.5,
                                "y": 248
                            }
                        ]
                    }
                ],
                "imgDimensions": {
                    "width": 329.5,
                    "height": 515
                }
            },
            {
                "strona": 2,
                "plik": "https://zapodaj.net/images/a3e4aca8e1045.jpg",
                "name": "gazetka-strona-2",
                "areas": [
                    {
                        "name": "s-2-a-1",
                        "shape": "poly",
                        "coords": [
                            22.5,
                            431,
                            154.5,
                            431,
                            154.5,
                            598,
                            22.5,
                            598
                        ],
                        "preFillColor": "rgba(45,136,188,0.2)",
                        "product": {
                            "id": 9,
                            "indeks": "TOP-00019",
                            "producent": "Storck",
                            "nazwa": "Ice fresh 125g",
                            "imagePath": "https://image.ceneostatic.pl/data/products/15168425/f-storck-cukierki-ice-fresh-125g.jpg",
                            "ilJm2": 20,
                            "jm2": "dis",
                            "jm": "szt",
                            "promocja": null,
                            "cenaKatalogowa": 3.29,
                            "cena": 3.59,
                            "czyDostepny": true,
                            "idKt": 7,
                            "idPk": 73,
                            "slug": "top-00019-storck-ice-fresh-125g",
                            "url": "/oferta/slodycze-i-przekaski/cukierki-i-draze/top-00019-storck-ice-fresh-125g",
                            "ileOpakowanWarstwa": 10,
                            "ileOpakowanPaleta": 50,
                            "ileKoszyk": 0
                        },
                        "canvasArea": [
                            {
                                "x": 22.5,
                                "y": 431
                            },
                            {
                                "x": 154.5,
                                "y": 598
                            }
                        ]
                    }
                ],
                "imgDimensions": {
                    "width": 400,
                    "height": 613.5
                }
            }
        ]
    }

];

export const API_getClients = () => [
    {
        idKl: 1,
        nazwa: 'TOP S.A.',
        imie: '',
        nazwisko: '',
        firma: 'TOP S.A.',
        email: 'topsa@topsa.com.pl',
        telefon: '123123123',
        czyAktywny: true,
        czyB2B: true,
    }, {
        idKl: 2,
        nazwa: 'Sample z.o.o',
        imie: '',
        nazwisko: '',
        firma: 'Sample z.o.o.',
        email: 'sample@sample.com',
        telefon: '55555555',
        czyAktywny: false,
        czyB2B: true,
    }, {
        idKl: 3,
        nazwa: 'Jan Kowalski',
        imie: 'Jan',
        nazwisko: 'Kowalski',
        firma: '',
        email: 'jkowalski@test.pl',
        telefon: '999888777',
        czyAktywny: true,
        czyB2B: false,
    }, {
        idKl: 4,
        nazwa: 'Andrzej Nowak',
        imie: 'Andrzej',
        nazwisko: 'Nowak',
        firma: 'Company pl',
        email: 'anowak@company.pl',
        telefon: '565656666',
        czyAktywny: true,
        czyB2B: true,
    },
];

export const API_getUstawieniaAplikacji = () => [
    {
        id: 1,
        sekcja: 'Koszyk',
        pozycja: 'Po ilu minutach czyszczenie [min]',
        wartosc: 30,
    },
    {
        id: 2,
        sekcja: 'Koszyk',
        pozycja: 'Minimalna wartość zamówienia [zł]',
        wartosc: 100,
    },
    {
        id: 3,
        sekcja: 'Aplikacja',
        pozycja: 'Czas bezaktywności użytkownika [min]',
        wartosc: 15,
    }
];

export const API_Admin_Komunikaty = () => [
    {
        id: 1,
        temat: 'Testowy temat',
        tresc: 'Testowa tresc',
        kontrahent: 'wszyscy',
        terminWaznosci: '2020-08-01 20:00:00',
        czyPokazywacPonownie: false,
        czyB2B: true,
        czyWysylacEmail: false,
        dataOstatniegoWyswietlenia: '2020-07-20 15:35:23',
        dataDostarczeniaEmail: null,
        dataWpisu: '2020-07-12 12:19:43',
        dataZmiany: null
    },
    {
        id: 2,
        temat: 'Spodziewane problemy z dostępem',
        tresc: 'W dniach: - 3 października (czwartek) około godz. 15.00 - 4 października (piątek) od godz. 15.00 mogą wystąpić czasowe problemy z dostępem do serwisu e.famix.com.pl. Związane jest to ze zmianą lokalizacji naszego magazynu w Rzeszowie. Przepraszamy za ewentualne niedogodności i zapraszamy ponownie!',
        kontrahent: 'wszyscy',
        czyPokazywacPonownie: true,
        terminWaznosci: '2020-07-01 15:00:00',
        czyB2B: true,
        czyWysylacEmail: true,
        dataOstatniegoWyswietlenia: '2020-07-20 15:35:23',
        dataDostarczeniaEmail: '2020-07-12 12:19:43',
        dataWpisu: '2020-07-12 12:19:43',
        dataZmiany: '2020-07-15 11:29:59'
    },
    {
        id: 3,
        temat: 'To jest testowy komunikat',
        tresc: 'Testowa tresc. To jest testowy komunikat. To jest testowy komunikat. To jest testowy komunikat. To jest testowy komunikat. To jest testowy komunikat.',
        kontrahent: 'FAMIX',
        czyPokazywacPonownie: false,
        terminWaznosci: '2020-08-01 20:00:00',
        czyB2B: false,
        czyWysylacEmail: false,
        dataOstatniegoWyswietlenia: null,
        dataDostarczeniaEmail: null,
        dataWpisu: '2020-07-12 12:19:43',
        dataZmiany: null
    },
    {
        id: 4,
        temat: 'Aktualizacja serwisu',
        tresc: 'W dniu 7 kwietnia 2020 (wtorek), od godz. 19.00 do 20.00 planujemy aktualizację serwisu e.famix.com.pl. W trakcie aktualizacji zostaną usunięte dotychczasowe koszyki, dlatego prosimy o ich wysłanie do Famixu przed tym terminem.',
        kontrahent: 'wszyscy',
        czyPokazywacPonownie: true,
        terminWaznosci: '2020-08-01 20:00:00',
        czyB2B: true,
        czyWysylacEmail: true,
        dataOstatniegoWyswietlenia: '2020-07-20 15:35:23',
        dataDostarczeniaEmail: '2020-07-12 12:19:43',
        dataWpisu: '2020-07-12 12:19:43',
        dataZmiany: null
    },
];

export const API_Admin_Logi = () => [
    {
        id: 1,
        data: '2020-01-01 15:44:11',
        typ: 'info',
        wartosc: 'Przypisano zdjęcie do produktu o ID 943',
        userid: 2,
        username: 'jkowalski',
        czyKlient: false,
    },
    {
        id: 5,
        data: '2020-01-01 13:11:58',
        typ: 'error',
        wartosc: 'Błąd logowania do systemu',
        userid: -1,
        username: '',
        czyKlient: false,
    },
    {
        id: 4,
        data: '2020-01-01 12:22:49',
        typ: 'info',
        wartosc: 'Wysłano zamówienie o numerze ZY/9999',
        userid: 145,
        username: 'sklep_x',
        czyKlient: true,
    },
    {
        id: 4,
        data: '2020-01-01 12:22:19',
        typ: 'debug',
        wartosc: 'Dodano produkt o ID 364 do koszyka w ilości 140 szt',
        userid: 145,
        username: 'sklep_x',
        czyKlient: true,
    },
    {
        id: 3,
        data: '2020-01-01 09:55:04',
        typ: 'info',
        wartosc: 'Dodano promocję o ID 5',
        userid: 1,
        username: 'admin',
        czyKlient: false,
    }
];

export const API_Admin_Reklamacje = () =>[
    {
        id:1,
        dataReklamacji:'2020-06-19 13:42',
        klient: 'Sklep X',
        faktura:'FK/143',
        dataWystawienia:'2020-06-12',
        uwaga:'<div>WOSEB81 - towar uległ uszkodzeniu, opakowanie nie jest szczelne <br/>PRYMA137 - niepoprawny opis produktu, z oferty wynika, że przyprawa nie zawiera cytryny</div>',
        status:'nowe',
        pozycje:[
            {
                pozycja:2,
                indeks:'WOSEB81',
                producent:'WOSEBA',
                nazwa:'Kawa ziarno Mocca Fix Gold 1 kg',
                jm:'SZT',
                cena:6.21,
                zamowiono:16,
                powod:'towar uszkodzony lub niezdatny do spożycia',
            },
            {
                pozycja:6,
                indeks:'PRYMA137',
                producent:'PRYMAT',
                nazwa:'Przyprawa do ryb z nutą cytryny',
                jm:'SZT',
                cena:1.04,
                zamowiono:75,
                powod:'towar niezgodny z opisem',
            },
        ]
    },
    {
        id:2,
        dataReklamacji:'2020-07-11 20:55',
        klient: 'PSS Społem TEST',
        faktura:'FK/3232',
        dataWystawienia:'2020-07-01',
        uwaga:'<div>AGAXX117 - wysłano niepoprawną ilość produktu - zamiast 45 SZT wysłano 42 SZT</div>',
        status:'rozwiązano',
        pozycje:[
            {
                pozycja:14,
                indeks:'AGAXX117',
                producent:'AGA',
                nazwa:'Wiórka kokosowe 100g/40SZT',
                jm:'SZT',
                cena:1.47,
                zamowiono:45,
                powod:'inne',
            },
        ]
    },
];