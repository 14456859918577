import React, {Component} from 'react';
import LayoutComponent from "../../../sharedComponents/layout/LayoutComponent";

class AdminHomeModule extends Component {
    render() {
        return (
            <LayoutComponent breadcrumbs={[{url:"/admin",name:'Panel adm.'}]} isAdminPanel={true}>
                <div id={'ADMIN_MODULE'}>
                    admin module
                </div>
            </LayoutComponent>
        );
    }
}

export default AdminHomeModule;