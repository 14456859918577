import {saveAs} from 'file-saver';

export const saveAnyFileAndDownload = (content: any, filename: string, extension: string) => {

    let type = "text/plain";

    if(extension === 'xml')
        type = 'application/xml';

    if(extension === 'html')
        type = 'application/html';

    if(extension === 'json')
        type = 'application/json';

    if(extension === 'pdf')
        type = 'application/pdf';

    if(extension === 'doc')
        type = 'application/msword';

    if(extension === 'odt')
        type = 'application/vnd.oasis.opendocument.text';

    if(extension === 'docx')
        type = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

    if(extension === 'xls')
        type = 'application/vnd.ms-excel';

    if(extension === 'xlsx')
        type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

    if(extension === 'csv')
        type = 'text/csv';

    if(extension === 'zip')
        type = 'application/zip';

    if(extension === 'rar')
        type = 'application/vnd.rar';

    if(extension === '7z')
        type = 'application/x-7z-compressed';

    if(extension === 'tar')
        type = 'application/x-tar';

    var blob = new Blob([content], {type: `${type};charset=utf-8`});
    saveAs(blob, filename);
}

export const saveTextFileAndDownload = (content: string, filename: string) => {
    var blob = new Blob([content], {type: "text/plain;charset=utf-8"});
    saveAs(blob, filename);
};

/*
export const saveAnyFileAndDownload2 = (responseData: any, filename: string, fileextension: string) => {
    const url = window.URL.createObjectURL(new Blob([responseData]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${filename}.${fileextension}`); //or any other extension
    document.body.appendChild(link);
    link.click();
};
*/
