import React from 'react';
import NavbarComponent from "./headerComponents/NavbarComponent";
import HeaderUpperComponent from "./headerComponents/HeaderUpperComponent";
import LoginService from "../../services/LoginService";
import {Carousel} from "react-bootstrap";
import {Link} from "react-router-dom";
import {HOST_API} from "../../ApplicationConfig";

interface Props {
    banery: any
}

interface State {
    data: any
}

class BanerComponent extends React.Component<Props, State> {

    // state = {
    //     //data: LoginService.checkIfBaneryInSession()
    // };


    render() {

        let {banery} = this.props;

        return (
                <div>
                    {Boolean(banery) ?
                        <Carousel interval={9000} controls={banery.length > 1}>
                            {banery.map((item: any, index: any) =>
                                <Carousel.Item key={index}>
                                    {/*<a href={item.linkTo}>*/}
                                    {/*    {(item.urlImage.includes('.mp4'))?*/}
                                    {/*        <video width="1310" height="150" autoPlay loop className="d-block w-100">*/}
                                    {/*            <source src={item.urlImage} type="video/mp4" />*/}
                                    {/*        </video>*/}
                                    {/*        :*/}
                                    {/*        <img*/}
                                    {/*            className="d-block w-100"*/}
                                    {/*            src={item.urlImage}*/}
                                    {/*            alt="First slide"*/}
                                    {/*        />*/}
                                    {/*    }*/}
                                    {/*</a>*/}

                                    {/*w budowie*/}
                                    {(item.fileType.includes('mp4'))?
                                        <video width="1310" height="150" autoPlay loop className="d-block w-100">
                                            <source src={HOST_API + `public/klient/getImgForBaner/${item.id}`} type="video/mp4" />
                                        </video>
                                        :
                                        (item.fileType.includes('statyczny'))?
                                            <a href={item.linkTo}>
                                                <img
                                                    className="d-block w-100"
                                                    src={item.urlImage}
                                                    alt="First slide"
                                                />
                                            </a>
                                            :
                                            <a href={item.urlLink}>
                                                <img
                                                    className="d-block w-100"
                                                    src={HOST_API + `public/klient/getImgForBaner/${item.id}`}
                                                    alt="First slide"
                                                />
                                            </a>

                                    }
                                </Carousel.Item>
                            )}
                        </Carousel>
                        :
                                null
                    }
            </div>
        );
    }
}

export default BanerComponent;
