import React, {Component} from 'react';
import {FormControl} from "react-bootstrap";

interface Props{
    value:any
}

class AdminKonfiguracjaZmienWartoscForm extends React.Component<Props> {
    render() {
        return (
            <div>
                <FormControl value={this.props.value} size={'sm'}/>
            </div>
        );
    }
}

export default AdminKonfiguracjaZmienWartoscForm;