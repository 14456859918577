import React, {Component} from 'react';
import {Form, Row, Col, Button, FormGroup, FormControl, ButtonToolbar} from "react-bootstrap";
import AddFormMargin from "../../../../sharedComponents/formComponents/AddFormMargin";
import {IconFilter, IconTrash} from "../../../../sharedComponents/icons/FontAwesome";
import {displayNotificationInfo, displayNotificationWarning} from "../../../../utilities/notifications/NotificationsManager";
import {isMobileDevice} from "../../../../utilities/rwd/rwdUtilities";

interface Props {
    handleClickSzukaj: (params: any) => void;
}

class AdminKlienciFilterbox extends React.Component<Props> {

    state = {
        params: {
            email: "",
            // kto: "",
            role: "",
            telefon: "",
            topNazwa: "",
            zgodaEmail: false,
            zgodaMob: false,
            nieAktywni: false,
            zablokowani: false
            // system: 'Wszystkie'
        }
    };

    onChangeInput = (key: string, e: any) => {
        let val = e.target.value;

        let {params}: any = this.state;
        params[key] = val;
        this.setState({params});

    };

    onChangeCheckBox = (key: string, e: any) => {
        let val = e.target.checked;

        let {params}: any = this.state;
        params[key] = val;
        this.setState({params}, () => this.onClickSzukaj());
    };

    onClickSzukaj = () => {

        let {params}: any = this.state;

        this.props.handleClickSzukaj(params)

    };

    handleKeyPress(target: any) {
        if (target.charCode==13){
            this.onClickSzukaj()
        }
    }

    render() {
        let {params} = this.state;

        return (
            <div id={'AdminKlienciFilterbox'}>
                <Form>
                    <Row>
                        <Col md={12} lg={2} xl={2}>
                            <FormGroup>
                                <Form.Label>Kontrahent:</Form.Label>
                                <FormControl size={'sm'} value={params.topNazwa} onChange={(e: any) => this.onChangeInput('topNazwa', e)} onKeyPress={this.handleKeyPress.bind(this)}/>
                            </FormGroup>
                        </Col>
                        {/*<Col md={6} lg={3} xl={2}>*/}
                        {/*    <FormGroup>*/}
                        {/*        <Form.Label>Użytkownik:</Form.Label>*/}
                        {/*        <FormControl size={'sm'} onChange={(e: any) => this.onChangeInput('kto', e)}/>*/}
                        {/*    </FormGroup>*/}
                        {/*</Col>*/}
                        <Col md={12} lg={2} xl={2}>
                            <FormGroup>
                                <Form.Label>Telefon:</Form.Label>
                                <FormControl size={'sm'} value={params.telefon} onChange={(e: any) => this.onChangeInput('telefon', e)} onKeyPress={this.handleKeyPress.bind(this)}/>
                            </FormGroup>
                        </Col>
                        <Col md={12} lg={2} xl={2}>
                            <FormGroup>
                                <Form.Label>Email:</Form.Label>
                                <FormControl size={'sm'} value={params.email} onChange={(e: any) => this.onChangeInput('email', e)} onKeyPress={this.handleKeyPress.bind(this)}/>
                            </FormGroup>
                        </Col>
                        {/*style={{maxWidth: '470px'}}*/}
                        <Col md={12} lg={4} xl={4} style={{padding: '0'}}>
                            <AddFormMargin hideForMobileDevices991={true}/>
                            <FormGroup>
                                <Form.Check inline={true} type="checkbox"
                                            label="Zgoda e-mail"
                                            checked={params.zgodaEmail}
                                            onClick={(e: any) => this.onChangeCheckBox('zgodaEmail', e)}
                                    // id={'czyPokazywacPonownieCheckbox'}
                                />
                                <Form.Check inline={true} type="checkbox"
                                            label="Zgoda mobilne"
                                            checked={params.zgodaMob}
                                            onClick={(e: any) => this.onChangeCheckBox('zgodaMob', e)}
                                    // id={'czyPokazywacPonownieCheckbox'}
                                />
                                <Form.Check inline={true} type="checkbox"
                                            label="Nie aktywni"
                                            checked={params.nieAktywni}
                                            onClick={(e: any) => this.onChangeCheckBox('nieAktywni', e)}
                                    // id={'czyPokazywacPonownieCheckbox'}
                                />
                                <Form.Check inline={true} type="checkbox"
                                            label="Zablokowani"
                                            checked={params.zablokowani}
                                            onClick={(e: any) => this.onChangeCheckBox('zablokowani', e)}
                                    // id={'czyPokazywacPonownieCheckbox'}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={12} lg={2} xl={2}>
                            <FormGroup>
                                <AddFormMargin hideForMobileDevices991={true}/>
                                <ButtonToolbar>
                                <Button type={'button'} variant={'primary'} size={'sm'} onClick={this.onClickSzukaj}>
                                    <IconFilter/> Filtruj
                                </Button>

                                {
                                    (
                                        this.state.params &&
                                        (Boolean(this.state.params.topNazwa) || Boolean(this.state.params.email) || Boolean(this.state.params.role) || Boolean(this.state.params.telefon) )
                                    ) &&
                                    <Button type={'button'} variant={'light'} size={'sm'} className={'text-danger'}
                                            onClick={() => {
                                                this.setState({
                                                    params: {
                                                        email: "",
                                                        // kto: "",
                                                        role: "",
                                                        telefon: "",
                                                        topNazwa: ""
                                                    }
                                                }, () => this.onClickSzukaj())
                                            }}
                                            title={'Usuń wszystkie zastosowane filtry'}
                                            // disabled={isButtonDisabled}
                                    >
                                        <IconTrash/>
                                        {
                                            isMobileDevice() &&
                                            ' Wyczyść'
                                        }
                                    </Button>
                                }
                                </ButtonToolbar>
                            </FormGroup>
                        </Col>

                    </Row>
                </Form>
            </div>
        );
    }
}

export default AdminKlienciFilterbox;