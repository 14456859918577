import {HOST_API} from "../ApplicationConfig";
import axios from 'axios';

const HOST = HOST_API + 'public/klient/';

export default class PublicKlientService {

    static getResetujHaslo = (username:string) => {
        return axios.request({
            method: 'get',
            url: `${HOST}resetujHaslo?username=${username}`,
        })
    };

    static postUstawNoweHaslo = (params: any) => {
        return axios.request({
            method: 'post',
            url: `${HOST}ustawNoweHaslo`,
            data: JSON.stringify(params)
        })
    };


}
