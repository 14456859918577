import React from 'react'
import {Button, ButtonToolbar} from "react-bootstrap";
import {IconEdit, IconTrash} from "../icons/FontAwesome";

interface TableActionButtons_EditDeleteProps {
    handleClickEdit: any,
    handleClickDelete: any
}

export const TableActionButtons_EditDelete: React.FC<TableActionButtons_EditDeleteProps> = ({handleClickEdit, handleClickDelete}) => (
    <ButtonToolbar className={'TableButtons'}>
        <Button variant={'light'}
                size={'sm'}
                onClick={handleClickEdit} title={'Kliknij aby edytować rekord'}
                className={'edit'}
        >
            <IconEdit/>
        </Button>
        <Button variant={'light'}
                size={'sm'}
                onClick={handleClickDelete} title={'Kliknij aby usunąć komunikat'}
                className={'delete'}
        >
            <IconTrash/>
        </Button>
    </ButtonToolbar>
);

interface TableActionButtons_EditAnyActionProps {
    handleClickEdit: any,
    handleClickAnyAction: any,
    actionIcon: any,
    actionTitle: string,
    actionClassname?: string,
}

export const TableActionButtons_EditAnyAction: React.FC<TableActionButtons_EditAnyActionProps> = ({handleClickEdit, handleClickAnyAction, actionIcon, actionTitle, actionClassname = ''}) => (
    <ButtonToolbar className={'TableButtons'}>
        <Button variant={'light'}
                size={'sm'}
                onClick={handleClickAnyAction} title={actionTitle}
                className={actionClassname}
        >
            {actionIcon}
        </Button>
        <Button variant={'light'}
                size={'sm'}
                onClick={handleClickEdit} title={'Kliknij aby edytować rekord'}
                className={'edit'}
        >
            <IconEdit/>
        </Button>
    </ButtonToolbar>
);

interface TableActionButtons_Edit2AnyActionsProps {
    handleClickEdit: any,
    handleClick1Action: any,
    action1Icon: any,
    action1Title: string,
    action1Classname?: string,
    handleClick2Action: any,
    action2Icon: any,
    action2Title: string,
    action2Classname?: string,
}

export const TableActionButtons_Edit2AnyActions: React.FC<TableActionButtons_Edit2AnyActionsProps> = (
    {
        handleClickEdit,
        handleClick1Action,
        action1Icon,
        action1Title,
        action1Classname,
        handleClick2Action,
        action2Icon,
        action2Title,
        action2Classname,
    }
) => (
    <ButtonToolbar className={'TableButtons'}>
        <Button variant={'light'}
                size={'sm'}
                onClick={handleClick1Action} title={action1Title}
                className={action1Classname}
        >
            {action1Icon}
        </Button>
        <Button variant={'light'}
                size={'sm'}
                onClick={handleClick2Action} title={action2Title}
                className={action2Classname}
        >
            {action2Icon}
        </Button>
        <Button variant={'light'}
                size={'sm'}
                onClick={handleClickEdit} title={'Kliknij aby edytować rekord'}
                className={'edit'}
        >
            <IconEdit/>
        </Button>
    </ButtonToolbar>
);

interface TableActionButtons_2AnyActionsProps {
    handleClick1Action: any,
    action1Icon: any,
    action1Title: string,
    action1Classname?: string,
    handleClick2Action: any,
    action2Icon: any,
    action2Title: string,
    action2Classname?: string,
}

export const TableActionButtons_2AnyActions: React.FC<TableActionButtons_2AnyActionsProps> = (
    {
        handleClick1Action,
        action1Icon,
        action1Title,
        action1Classname,
        handleClick2Action,
        action2Icon,
        action2Title,
        action2Classname,
    }
) => (
    <ButtonToolbar className={'TableButtons'}>
        <Button variant={'light'}
                size={'sm'}
                onClick={handleClick1Action} title={action1Title}
                className={action1Classname}
        >
            {action1Icon}
        </Button>
        <Button variant={'light'}
                size={'sm'}
                onClick={handleClick2Action} title={action2Title}
                className={action2Classname}
        >
            {action2Icon}
        </Button>
    </ButtonToolbar>
);

interface TableActionsButtons_AnyActionProps {
    children: any,
    handleClickAction: any,
    tooltip: string,
    isDisabled?:boolean
}

export const TableActionsButtons_AnyAction: React.FC<TableActionsButtons_AnyActionProps> = ({children, tooltip, handleClickAction,isDisabled=false}) => (
    <ButtonToolbar className={'TableButtons'}>
        <Button variant={'light'}
                size={'sm'}
                onClick={handleClickAction} title={tooltip}
                disabled={isDisabled}
        >
            {children}
        </Button>
    </ButtonToolbar>
);