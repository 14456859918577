import React from 'react';
import logo from '../../../resources/images/famixlogo.png'
import CurrentUserHeaderComponent from "./CurrentUserHeaderComponent";
import BasketHeaderComponent from "./BasketHeaderComponent";
import ToggleNavigationButton from "./ToggleNavigationButton";
import SieciSelectHeaderComponent from "./SieciSelectHeaderComponent";
import LoginService from "../../../services/LoginService";
import {Button} from "react-bootstrap";
import SieciSelectAdmin from "./SieciSelectAdmin";
import {isSMMobileDevice} from "../../../utilities/rwd/rwdUtilities";
import {TEST_VERSION} from "../../../ApplicationConfig";

interface Props {
    handleToggleNavigation: any,
    isAdminPanel?: boolean,
    stanKoszykaObject:any,
    handleChangeSiec:(siecObj:any) =>void;
    nowePowiadomienia: any;
    setStyleContrast: () => void;
}

const HeaderUpperComponent: React.FC<Props> = ({isAdminPanel = false, handleToggleNavigation,stanKoszykaObject,handleChangeSiec, nowePowiadomienia, setStyleContrast}: Props) => {
    const propsyfix = {
        nowePowiadomienia: nowePowiadomienia
    }

    return (
        <div id={'HeaderUpper'}>
            <img src={logo} alt={'famix logo'}/>

            <div className={'my-auto'}>
                <a href="#" style={{color: '#999a99', fontSize: '1.25rem', paddingRight: 5}}
                    onClick={() => document.body.style.fontSize = '1.5rem'}>A</a>
                <a href="#" style={{color: '#999a99', fontSize: '1rem', paddingRight: 5}}
                   onClick={() => document.body.style.fontSize = '1.25rem'}>A</a>
                <a href="#" style={{color: '#999a99', fontSize: '0.75rem', paddingRight: 10}}
                   onClick={() => document.body.style.fontSize = '1rem'}>A</a>
                <i className="fa fa-adjust" onClick={() => setStyleContrast()}
                   style={{color: '#999a99', paddingRight: 5, cursor: 'pointer'}}/>
            </div>

            {(TEST_VERSION) &&
                <div className={'my-auto'}>
                    <h3 style={{color: 'red', margin: '0'}}>
                        Wersja testowa platformy B2B
                    </h3>
                </div>
            }

            <div id={'current-user-and-basket'}>
                {
                    (isAdminPanel && !isSMMobileDevice()) &&
                    <Button href={'/'} size={'sm'} style={{height: '27px', marginTop: '8px', marginRight: '5px'}}>Widok klienta</Button>
                }
                {
                    (LoginService.czyAdmin() && !isSMMobileDevice()) &&
                    <Button href={'/admin/klienci'} size={'sm'} style={{height: '27px', marginTop: '8px', marginRight: '5px'}}>ADMIN PANEL</Button>
                }
                {/*{*/}
                {/*    (!isAdminPanel && LoginService.czyAdmin() && !isSMMobileDevice()) &&*/}
                {/*    <SieciSelectAdmin handleChangeSiec={handleChangeSiec}/>*/}
                {/*}*/}
                {
                    !isAdminPanel &&
                    <SieciSelectHeaderComponent handleChangeSiec={handleChangeSiec}/>
                }
                <CurrentUserHeaderComponent {...propsyfix}/>
                {
                    !isAdminPanel &&
                    <BasketHeaderComponent stanKoszykaObject={stanKoszykaObject}/>
                }
                <ToggleNavigationButton handleToggleNavigation={() => handleToggleNavigation()}/>
            </div>
        </div>
    );
};

export default HeaderUpperComponent;
