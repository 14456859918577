import React from 'react';
import {Col, Row} from "react-bootstrap";
import OverflowTextWrapper from "../../../../sharedComponents/typographyComponents/overflowText/OverflowTextWrapper";
import {TableFormatters_formatBoolean} from "../../../../sharedComponents/tableComponents/tableFormatters";

interface Props {
    logi: any[]
}


const AdminLogiGrid:React.FC<Props> = ({logi}) => {

    const formatTypRow = (cell: string) => {
        let className = 'light';
        let fontColor = '#333';

        if (cell === 'error') {
            className = 'danger';
            fontColor = "#fff";
        }
        if (cell === 'debug') {
            className = 'warning';
        }

        return <span className={`badge badge-${className}`} style={{fontSize: '0.65rem', display:'inline-block', color:fontColor}}>{cell}</span>
    };

    return (
        <div id={'AdminLogiGrid'}>
            <Row>
                {
                    logi.map((log:any)=>(
                        <Col md={6} lg={4} xl={3}>
                            <div className={'log-container'}>
                                <div>
                                    <div className={'smaller-grid-tills'}>
                                        <label>ID:</label>
                                        <span>{log.id}</span>
                                    </div>
                                    <div className={'smaller-grid-tills'}>
                                        <label>Typ:</label>
                                        <span>{formatTypRow(log.typ)}</span>
                                    </div>
                                    <div className={'smaller-grid-tills full-width'}>
                                        <label>Data:</label>
                                        <span>{log.data}</span>
                                    </div>
                                </div>
                                <div>
                                    <label>
                                        Wartość:
                                    </label>
                                    <span>
                                        <OverflowTextWrapper text={log.wartosc} howManyLettersVisible={45}/>
                                    </span>
                                </div>
                                <div>
                                    <label>
                                        User:
                                    </label>
                                    <span>
                                        <i className={'badge badge-secondary'}>{log.userid}</i> {log.username}
                                    </span>
                                </div>
                                <div>
                                    <label>
                                        Czy klient?:
                                    </label>
                                    <span>
                                        <TableFormatters_formatBoolean value={log.czyKlient} titleTrue={'Jest klientem'} titleFalse={'Jest pracownikiem'} />
                                    </span>
                                </div>
                            </div>
                        </Col>
                    ))
                }
            </Row>

        </div>
    );
};

export default AdminLogiGrid;