import {HOST_API} from "../ApplicationConfig";
import axios from 'axios';

const HOST = HOST_API + 'rozrachunki/';

export default class RozrachunkiService {

    static getRozrachunki = (page: number, size: number, poTerminie: any, sort: string = '') => {
        return axios.request({
            method: 'get',
            url: `${HOST}getRozrachunki?page=${page}&size=${size}&paged=true&poTerminie=${poTerminie}${sort}`
        })
    };

    static getNaleznosci = () => {
        return axios.request({
            method: 'get',
            url: `${HOST}getPlatnosci`
        })
    };

}