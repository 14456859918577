import React, {Component} from 'react';
import TopCard from "../../../../sharedComponents/TopCard";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import tgrid from "../../../../utilities/table/tgrid";
import {API_getUstawieniaAplikacji} from "../../../../_static_data/response";
import {
    TableActionButtons_2AnyActions,
    TableActionsButtons_AnyAction
} from "../../../../sharedComponents/tableComponents/tableButtons";
import {IconEdit, IconSave, IconTimes} from "../../../../sharedComponents/icons/FontAwesome";
import AdminKonfiguracjaZmienWartoscForm from "./AdminKonfiguracjaZmienWartoscForm";

class AdminKonfiguracjaContainer extends Component {

    state={
      currentRow:null
    };

    formatAkcje = (cell:any,row:any) =>{
        if(this.state.currentRow === null){
            return <TableActionsButtons_AnyAction handleClickAction={()=>this.setState({currentRow:row})} tooltip={'Edytuj wybraną wartość'}><IconEdit/></TableActionsButtons_AnyAction>
        }else{
            //@ts-ignore
            if(row.id === this.state.currentRow.id){
                return <TableActionButtons_2AnyActions handleClick1Action={()=>{}} action1Icon={<IconSave/>} action1Title={'Zapisz wartość'}
                                                       handleClick2Action={()=>this.setState({currentRow:null})} action2Icon={<IconTimes/>} action2Title={'Anuluj edycję'} action2Classname={'text-dark'}
                                                       />
            }
            else return <div style={{height:'30.8px'}}></div>;
        }
    };

    formatWartosc = (cell:any,row:any) =>{
        //@ts-ignore
        if(Boolean(this.state.currentRow) && row.id === this.state.currentRow.id){
            //@ts-ignore
            return <AdminKonfiguracjaZmienWartoscForm value={this.state.currentRow.wartosc}/>
        }
        else{
              return cell
        }
    };

    render() {

        const data = API_getUstawieniaAplikacji();

        return (
            <div id={'UstawieniaAdministratoraContainer'}>
                <TopCard heading={'Ustawienia systemu'} hasPadding={false}>
                    <BootstrapTable
                        data={data}
                        hover={true}>
                        <TableHeaderColumn isKey={true} dataField={'id'}  thStyle={tgrid.t2} tdStyle={tgrid.t2}
                                           dataSort={true}
                        >ID</TableHeaderColumn>
                        <TableHeaderColumn dataField={'sekcja'} thStyle={tgrid.t4} tdStyle={tgrid.t4}
                                           dataSort={true}
                        >Sekcja</TableHeaderColumn>
                        <TableHeaderColumn dataField={'pozycja'} thStyle={tgrid.t12} tdStyle={tgrid.t12}
                                           dataSort={true}
                        >Pozycja</TableHeaderColumn>
                        <TableHeaderColumn dataField={'wartosc'} thStyle={tgrid.t4} tdStyle={tgrid.t4}
                                           dataSort={true}
                                           dataFormat={this.formatWartosc}
                        >Wartość</TableHeaderColumn>
                        <TableHeaderColumn dataField={'id'} thStyle={tgrid.t3} tdStyle={tgrid.t3}
                                           dataFormat={this.formatAkcje}
                        >Akcje</TableHeaderColumn>
                    </BootstrapTable>
                </TopCard>
            </div>
        );
    }
}

export default AdminKonfiguracjaContainer;