import React, {Component} from 'react';
import {Button, Col, Row} from "react-bootstrap";
import okladkaDefault from "../../resources/images/nopicture.png";

//@ts-ignore
import {LazyLoadImage} from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import IconPromocja from "../oferta/components/IconPromocja";
import PromocjeService from "../../services/PromocjeService";

interface Props {
    promocja: any,
    idProm: any
    // showPicture:boolean
}

class PromocjaInfo extends Component<Props> {

    // getLabel = (promocja: string) => {
    //     switch (promocja) {
    //         case 'PROM_PROST_CU':
    //             return 'Promocja cenowa';
    //         case 'PROM_PAK_G':
    //             return 'Promocja pakietowa - gratis';
    //         case 'PROM_PAK_CU':
    //             return 'Promocja pakietowa - cena upust';
    //         case 'PROM_PROG_CU':
    //             return 'Promocja progowa - cena upust';
    //         case 'PROM_PAK_L':
    //             return 'Promocja lojalnościowa';
    //         case 'PROM_PROG_G':
    //             return 'Promocja progowa - gratis';
    //     }
    // };

    render() {

        let {promocja, idProm} =this.props;

        return (
            <div className={'promocjaInfo'}><Row>
                <Col xl={12} style={{padding: '0'}}>
                    <Row>
                        <Col>
                            <h6 className={'heading-label'}>
                                Nazwa promocji:
                            </h6>
                            <h6>{promocja.NAZWA}</h6>
                        </Col>
                        <Col>
                            <h6 className={'heading-label'}>
                                Typ promocji:
                            </h6>
                            <div className={'typ'}><IconPromocja typ={promocja.OZN}/><h6 > Promocja {PromocjeService.getLabel(promocja.RODZAJ)}</h6></div>
                        </Col>
                        <Col>
                            <h6 className={'heading-label'}>
                                Obowiązuje:
                            </h6>
                            <h6>
                                Od: {promocja.DATA_OD}<br/>
                                Do: {promocja.DATA_DO}
                            </h6>
                        </Col>
                        <Col>
                            <h6 className={'heading-label'}>
                                ID promocji:
                            </h6>
                            <h6>{idProm}</h6>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={6}>
                            <h6 className={'heading-label'}>
                                Opis promocji:
                            </h6>
                            <h6 style={{whiteSpace: 'pre-line'}}>
                                {promocja.OPIS_WWW}
                            </h6>
                        </Col>
                        <Col xl={3}>
                            <h6 className={'heading-label'}>
                                Informacje:
                            </h6>
                            <h6>
                                {promocja.PAKIET}
                            </h6>
                        </Col>
                        <Col xl={3}>
                            <h6 className={'heading-label'}>
                                Info:
                            </h6>
                            <h6 style={{whiteSpace: 'pre-line'}}>
                                {promocja.INFO}
                            </h6>
                        </Col>
                    </Row>
                    {/*<Row>*/}
                    {/*    <Col>*/}
                    {/*        <h6 className={'heading-label'}>*/}
                    {/*            Informacje:*/}
                    {/*        </h6>*/}
                    {/*        <h6>*/}
                    {/*            {promocja.PAKIET}*/}
                    {/*        </h6>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                </Col>

                    {/*<Col style={{textAlign: 'end'}} xl={3} className={'align-self-end'}>*/}
                    {/*    <Button variant="primary" size={'sm'} style={{display: 'inline-block'}} onClick={() => alert('w budowie...')}>Dodaj pakiet</Button>*/}
                    {/*    <Button variant="secondary" size={'sm'} style={{display: 'inline-block', marginLeft: '5px'}} onClick={() => alert('w budowie...')}>Usuń pakiet</Button>*/}
                    {/*</Col>*/}
                </Row>
            </div>
        );
    }
}

export default PromocjaInfo;