import React, {Component} from 'react';
import PopupWrapper from "./popups/PopupWrapper";
import defaultimg from '../resources/images/nopicture.png';

interface Props {
    children: any,
    img: string
}

class TopImageWithBiggerView extends React.Component<Props> {

    state = {
        biggerView: false,
        error: false
    };

    //onClick={() => this.setState({biggerView: true})}

    render() {
        return (
            <>
                <div>
                    {this.props.children}
                </div>
                {
                    this.state.biggerView &&
                    <PopupWrapper header={'Podgląd zdjęcia'} onClose={() => this.setState({biggerView: false})}>
                        <div className={'image-in-bigger-view'}>
                            {
                                ((!this.state.error) && Boolean(this.props.img)) ?
                                    <img src={this.props.img} onError={() => this.setState({error: true})} alt={'Zdjęcie produktu'}/>
                                    :
                                    <img src={defaultimg} alt={'Brak zdjęcia produktu'}/>

                            }
                        </div>
                    </PopupWrapper>
                }
            </>
        );
    }
}

export default TopImageWithBiggerView;
