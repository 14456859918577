import {HOST_API} from "../ApplicationConfig";
import axios from 'axios';

const HOST = HOST_API + 'faktury/';

export default class FakturyService {

    static getFaktury = (page: number, size: number, data: any, sort: string = '') => {
        return axios.request({
            method: 'get',
            url: `${HOST}getFaktury?data=${data}&page=${page}&size=${size}&paged=true${sort}`, //&sort=m.indeks,asc`,
        })
    };

    static getPozycjeFaktury = (params: any) => {
        return axios.request({
            method: 'post',
            url: `${HOST}getPozycjeFaktury`,
            data: JSON.stringify(params)
        })
    };

    static getPozycjeFakturyNew = (data: any) => {
        return axios.request({
            method: 'post',
            url: `${HOST}getPozycjeFakturyNew`,
            data: data
        })
    };

    static getPozycjeFakturyNew2 = (page: number, size: number, data: any, sort: string = '') => {
        return axios.request({
            method: 'post',
            url: `${HOST}getPozycjeFakturyPageable?page=${page}&size=${size}&paged=true${sort}`,
            data: data
        })
    };

}
