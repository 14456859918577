import React, {Component} from 'react';
import {Button, ButtonToolbar, Col, Form, FormGroup, ProgressBar, Row} from "react-bootstrap";
import {
    IconCheckCircle,
    IconEye,
    IconEyeSlash,
    IconFileTxt,
    IconImportFile, IconInfo,
    IconTimes, IconTimesCircle, IconWarning
} from "../../../sharedComponents/icons/FontAwesome";
import Select from "react-select";
import {SmallerSelectStyles} from "../../../utilities/forms/jsStyles";
import AddFormMargin from "../../../sharedComponents/formComponents/AddFormMargin";
//@ts-ignore
import Files from 'react-files'
import {
    displayNotificationError, displayNotificationInfo,
    displayNotificationSuccess
} from "../../../utilities/notifications/NotificationsManager";
import {TextAlert} from "../../../sharedComponents/typographyComponents/TextAlert/TextAlert";
import {isMobileDevice} from "../../../utilities/rwd/rwdUtilities";
import KoszykService from "../../../services/KoszykService";
import {_omit} from "../../../utilities/lodash/lodashUtilities";
import axios from "axios";
import Shared_ProductAddToBasketForm
    from "../../../sharedComponents/_reusable/ProductAddToBasket/Shared_ProductAddToBasketForm";
import KoszykModule from "../KoszykModule";
import TopLoading from "../../../sharedComponents/loader/TopLoading";
import {formatMoney} from "../../../utilities/formatters/formatMoney";
import OfertaService from "../../../services/OfertaService";
import moment from "moment";
import ApplicationGlobalSettingsService from "../../../services/ApplicationGlobalSettingsService";
import {ExportToCsv} from "export-to-csv";

interface Props {
    onClose: () => void,
    odswiez: () => void,
    handleChangeQuantityInBasket: (previousAmount: any, amount: any, item: any) => void,
    czyEdycja: boolean
}

class KoszykImportContainer extends React.Component<Props> {

    state = {
        showHelp: false,
        helpMessages: [
            {
                symbol: '0',
                info: 'Każda nowa linia w pliku tekstowym oznacza nową pozycję w koszyku. Pozycja rozpoznawana jest po ' +
                    'indeksie zamawianego produktu oraz zamawianej ilości. Wartości te muszą zostać rozdzielone ' +
                    'średnikiem. Przykładowa zawartość pliku:',
                przyklad:'FERRE46;2;\nWOSEB79;2; '
            },
            {symbol: '1', info: 'Każda nowa linia w pliku tekstowym oznacza nową pozycję w koszyku. Pozycja ' +
                    'rozpoznawana jest po kodzie paskowym zamawianego produktu oraz zamawianej ilości podanej w ' +
                    'podstawowej jednostce miary FAMIX. Wartości te muszą zostać rozdzielone średnikiem.\n' +
                    'Przykładowa zawartość pliku:',
                przyklad:'5900127000752;2;\n5901414203030;10;'
            },
            {symbol: '2', info: 'Przykładowa zawartość pliku:',
                przyklad:'TypPolskichLiter:LA\n' +
                    'TypDok:ZAM\n' +
                    'NrDok:ZAM/14/32\n' +
                    'Data:31.03.2014\n' +
                    'DataRealizacji:01.04.2014\n' +
                    'DotyczyDok:z 2014-03-31\n' +
                    'Magazyn:Mag nr 1\n' +
                    'SposobPlatn:GOT\n' +
                    'TerminPlatn:0\n' +
                    'IndeksCentralny:NIE\n' +
                    'NazwaWystawcy:NAZWA SKLEPU SIECI, ULICA, KOD,\n' +
                    'AdresWystawcy:35-000 Rzeszow, \n' +
                    'KodWystawcy:_____\n' +
                    'MiastoWystawcy:Rzeszow\n' +
                    'UlicaWystawcy:ADRES SKLEPU DOSTAWY\n' +
                    'NIPWystawcy:8130000000\n' +
                    'BankWystawcy:\n' +
                    'KontoWystawcy:\n' +
                    'TelefonWystawcy:(017) 85-00-000\n' +
                    'NrWystawcyWSieciSklepow:0\n' +
                    'NrWystawcyObcyWSieciSklepow:\n' +
                    'NazwaOdbiorcy:Przedsiobiorstwo Handlowe FAMIX S i G Tarnowscy Sp.j.\n' +
                    'AdresOdbiorcy:35-234 Rzeszow, \n' +
                    'KodOdbiorcy:35-234\n' +
                    'MiastoOdbiorcy:Rzeszow\n' +
                    'UlicaOdbiorcy:Ul.Trembeckiego 11\n' +
                    'NIPOdbiorcy:813-10-27-\n' +
                    'BankOdbiorcy:\n' +
                    'KontoOdbiorcy:\n' +
                    'TelefonOdbiorcy:0-17 85 32 870, 85 26 105\n' +
                    'NrOdbiorcyWSieciSklepow:0\n' +
                    'NrOdbiorcyObcyWSieciSklepow:\n' +
                    'IloscLinii:5\n' +
                    'Linia:Nazwa{paluszki lajkonik 300g bahlsen}Kod{5900320002614}Vat{23}Jm{szt}Asortyment{chipsy i przekĽski}Sww{}PKWiU{15.82.13-50.00}Ilosc{10}Cena{n3.12}Wartosc{n31.20}IleWOpak{1}CenaSp{b4.89}TowId{5672}\n' +
                    'Linia:Nazwa{bahlsen paluszki lajkonik 70g}Kod{5900320002775}Vat{23}Jm{szt}Asortyment{chipsy i przekĽski}Sww{}PKWiU{15.82.13-50.00}Ilosc{30}Cena{n1.03}Wartosc{n30.90}IleWOpak{1}CenaSp{b1.69}TowId{11139}\n' +
                    'Linia:Nazwa{prymat ziele angielskie 15g}Kod{5901135000420}Vat{23}Jm{szt}Asortyment{przyprawy}Sww{}PKWiU{15.87.20-10.74}Ilosc{20}Cena{n0.96}Wartosc{n19.20}IleWOpak{1}CenaSp{b1.59}TowId{564}\n' +
                    'Linia:Nazwa{nestle wafel princessa maxi kokos 49g goplana}Kod{5900862024570}Vat{23}Jm{szt}Asortyment{ciastka}Sww{}PKWiU{}Ilosc{28}Cena{n0.96}Wartosc{n26.88}IleWOpak{1}CenaSp{b1.59}TowId{5277}\n' +
                    'Linia:Nazwa{wrigleys guma orbit white spearmint 14g dražetki}Kod{42113164}Vat{8}Jm{szt}Asortyment{gumy,lizaki i inne}Sww{}PKWiU{15.89.14-90.99}Ilosc{30}Cena{n1.21}Wartosc{n36.30}IleWOpak{1}CenaSp{b1.99}TowId{24656}\n' +
                    'Stawka:Vat{23}SumaNet{65.09}SumaVat{15.45}\n' +
                    'Stawka:Vat{8}SumaNet{5.16}SumaVat{1.39}\n' +
                    'Stawka:Vat{5}SumaNet{0.00}SumaVat{0.00}\n' +
                    'DoZaplaty:80.13'
            },
            {symbol: '3', info: '?',
                przyklad:'?'
            },
            {symbol: '4', info: 'Przykładowa zawartość pliku:',
                przyklad:
                    '5908260251154 25 1,19\n' +
                    '5900397012042 8 5,9\n' +
                    '5900862213554 6 3,1\n' +
                    '5900397731950 8 5,1\n' +
                    '7300400122573 12 4,41\n'
            },
            {symbol: '5', info: 'Przykładowa zawartość pliku:',
                przyklad:'TypPolskichLiter:LA\n' +
                    'TypDok:ZAM\n' +
                    'NrDok:ZAM/14/32\n' +
                    'Data:31.03.2014\n' +
                    'DataRealizacji:01.04.2014\n' +
                    'DotyczyDok:z 2014-03-31\n' +
                    'Magazyn:Mag nr 1\n' +
                    'SposobPlatn:GOT\n' +
                    'TerminPlatn:0\n' +
                    'IndeksCentralny:NIE\n' +
                    'NazwaWystawcy:NAZWA SKLEPU SIECI, ULICA, KOD,\n' +
                    'AdresWystawcy:35-000 Rzeszow, \n' +
                    'KodWystawcy:_____\n' +
                    'MiastoWystawcy:Rzeszow\n' +
                    'UlicaWystawcy:ADRES SKLEPU DOSTAWY\n' +
                    'NIPWystawcy:8130000000\n' +
                    'BankWystawcy:\n' +
                    'KontoWystawcy:\n' +
                    'TelefonWystawcy:(017) 85-00-000\n' +
                    'NrWystawcyWSieciSklepow:0\n' +
                    'NrWystawcyObcyWSieciSklepow:\n' +
                    'NazwaOdbiorcy:Przedsiobiorstwo Handlowe FAMIX S i G Tarnowscy Sp.j.\n' +
                    'AdresOdbiorcy:35-234 Rzeszow, \n' +
                    'KodOdbiorcy:35-234\n' +
                    'MiastoOdbiorcy:Rzeszow\n' +
                    'UlicaOdbiorcy:Ul.Trembeckiego 11\n' +
                    'NIPOdbiorcy:813-10-27-\n' +
                    'BankOdbiorcy:\n' +
                    'KontoOdbiorcy:\n' +
                    'TelefonOdbiorcy:0-17 85 32 870, 85 26 105\n' +
                    'NrOdbiorcyWSieciSklepow:0\n' +
                    'NrOdbiorcyObcyWSieciSklepow:\n' +
                    'IloscLinii:5\n' +
                    'Linia:Nazwa{paluszki lajkonik 300g bahlsen}Kod{5900320002614}Vat{23}Jm{szt}Asortyment{chipsy i przekĽski}Sww{}PKWiU{15.82.13-50.00}Ilosc{10}Cena{n3.12}Wartosc{n31.20}IleWOpak{1}CenaSp{b4.89}TowId{5672}\n' +
                    'Linia:Nazwa{bahlsen paluszki lajkonik 70g}Kod{5900320002775}Vat{23}Jm{szt}Asortyment{chipsy i przekĽski}Sww{}PKWiU{15.82.13-50.00}Ilosc{30}Cena{n1.03}Wartosc{n30.90}IleWOpak{1}CenaSp{b1.69}TowId{11139}\n' +
                    'Linia:Nazwa{prymat ziele angielskie 15g}Kod{5901135000420}Vat{23}Jm{szt}Asortyment{przyprawy}Sww{}PKWiU{15.87.20-10.74}Ilosc{20}Cena{n0.96}Wartosc{n19.20}IleWOpak{1}CenaSp{b1.59}TowId{564}\n' +
                    'Linia:Nazwa{nestle wafel princessa maxi kokos 49g goplana}Kod{5900862024570}Vat{23}Jm{szt}Asortyment{ciastka}Sww{}PKWiU{}Ilosc{28}Cena{n0.96}Wartosc{n26.88}IleWOpak{1}CenaSp{b1.59}TowId{5277}\n' +
                    'Linia:Nazwa{wrigleys guma orbit white spearmint 14g dražetki}Kod{42113164}Vat{8}Jm{szt}Asortyment{gumy,lizaki i inne}Sww{}PKWiU{15.89.14-90.99}Ilosc{30}Cena{n1.21}Wartosc{n36.30}IleWOpak{1}CenaSp{b1.99}TowId{24656}\n' +
                    'Stawka:Vat{23}SumaNet{65.09}SumaVat{15.45}\n' +
                    'Stawka:Vat{8}SumaNet{5.16}SumaVat{1.39}\n' +
                    'Stawka:Vat{5}SumaNet{0.00}SumaVat{0.00}\n' +
                    'DoZaplaty:80.13'
            },
            {symbol: '6', info: 'Przykładowa zawartość pliku:',
                przyklad:'TypPolskichLiter:LA\n' +
                    'TypDok:ZAM\n' +
                    'NrDok:ZAM/14/32\n' +
                    'Data:31.03.2014\n' +
                    'DataRealizacji:01.04.2014\n' +
                    'DotyczyDok:z 2014-03-31\n' +
                    'Magazyn:Mag nr 1\n' +
                    'SposobPlatn:GOT\n' +
                    'TerminPlatn:0\n' +
                    'IndeksCentralny:NIE\n' +
                    'NazwaWystawcy:NAZWA SKLEPU SIECI, ULICA, KOD,\n' +
                    'AdresWystawcy:35-000 Rzeszow, \n' +
                    'KodWystawcy:_____\n' +
                    'MiastoWystawcy:Rzeszow\n' +
                    'UlicaWystawcy:ADRES SKLEPU DOSTAWY\n' +
                    'NIPWystawcy:8130000000\n' +
                    'BankWystawcy:\n' +
                    'KontoWystawcy:\n' +
                    'TelefonWystawcy:(017) 85-00-000\n' +
                    'NrWystawcyWSieciSklepow:0\n' +
                    'NrWystawcyObcyWSieciSklepow:\n' +
                    'NazwaOdbiorcy:Przedsiobiorstwo Handlowe FAMIX S i G Tarnowscy Sp.j.\n' +
                    'AdresOdbiorcy:35-234 Rzeszow, \n' +
                    'KodOdbiorcy:35-234\n' +
                    'MiastoOdbiorcy:Rzeszow\n' +
                    'UlicaOdbiorcy:Ul.Trembeckiego 11\n' +
                    'NIPOdbiorcy:813-10-27-\n' +
                    'BankOdbiorcy:\n' +
                    'KontoOdbiorcy:\n' +
                    'TelefonOdbiorcy:0-17 85 32 870, 85 26 105\n' +
                    'NrOdbiorcyWSieciSklepow:0\n' +
                    'NrOdbiorcyObcyWSieciSklepow:\n' +
                    'IloscLinii:5\n' +
                    'Linia:Nazwa{paluszki lajkonik 300g bahlsen}Kod{5900320002614}Vat{23}Jm{szt}Asortyment{chipsy i przekĽski}Sww{}PKWiU{15.82.13-50.00}Ilosc{10}Cena{n3.12}Wartosc{n31.20}IleWOpak{1}CenaSp{b4.89}TowId{5672}\n' +
                    'Linia:Nazwa{bahlsen paluszki lajkonik 70g}Kod{5900320002775}Vat{23}Jm{szt}Asortyment{chipsy i przekĽski}Sww{}PKWiU{15.82.13-50.00}Ilosc{30}Cena{n1.03}Wartosc{n30.90}IleWOpak{1}CenaSp{b1.69}TowId{11139}\n' +
                    'Linia:Nazwa{prymat ziele angielskie 15g}Kod{5901135000420}Vat{23}Jm{szt}Asortyment{przyprawy}Sww{}PKWiU{15.87.20-10.74}Ilosc{20}Cena{n0.96}Wartosc{n19.20}IleWOpak{1}CenaSp{b1.59}TowId{564}\n' +
                    'Linia:Nazwa{nestle wafel princessa maxi kokos 49g goplana}Kod{5900862024570}Vat{23}Jm{szt}Asortyment{ciastka}Sww{}PKWiU{}Ilosc{28}Cena{n0.96}Wartosc{n26.88}IleWOpak{1}CenaSp{b1.59}TowId{5277}\n' +
                    'Linia:Nazwa{wrigleys guma orbit white spearmint 14g dražetki}Kod{42113164}Vat{8}Jm{szt}Asortyment{gumy,lizaki i inne}Sww{}PKWiU{15.89.14-90.99}Ilosc{30}Cena{n1.21}Wartosc{n36.30}IleWOpak{1}CenaSp{b1.99}TowId{24656}\n' +
                    'Stawka:Vat{23}SumaNet{65.09}SumaVat{15.45}\n' +
                    'Stawka:Vat{8}SumaNet{5.16}SumaVat{1.39}\n' +
                    'Stawka:Vat{5}SumaNet{0.00}SumaVat{0.00}\n' +
                    'DoZaplaty:80.13'
            },
            {symbol: '7', info: 'Przykładowa zawartość pliku:',
                przyklad:
                    'HDR|75079|O|20200418|20200418|||||20200509|6812056608|6760076644\'\n' +
                    'LIN|75079|20200418|||||1|5900397009547||AGROS ŁOWICZ DŻEM OWOCE LEŚNE N/S 280G/8SZT|8|8|1|3.14|8|S|2.01\'\n' +
                    'LIN|75079|20200418|||||2|5900397012042||AGROS ŁOWICZ KONFITURA JAGODA 240G/8SZT|8|8|1|5.9|8|S|3.78\'\n' +
                    'LIN|75079|20200418|||||3|5900397734685||AGROS ŁOWICZ KONFITURA MALINA 240G/8SZT|8|8|1|4.88|8|S|3.12\'\n' +
                    'LIN|75079|20200418|||||4|5900397731950||AGROS ŁOWICZ ŻURAWINA DO MIĘS I SERÓW 230G/8SZT|8|8|1|5.1|8|S|3.26\'\n'+
                    'VAT|943.69|23|S|217.05\'\n' +
                    'VAT|778.90|5|S|38.95\'\n' +
                    'VAT|1543.77|8|S|123.50\'\n' +
                    'TOT|3266.36|379.50|3645.86\'\n'
            },
            {symbol: '8', info: 'Przykładowa zawartość pliku:',
                przyklad:
                    'HDR|75079|O|20200418|20200418|||||20200509|6812056608|6760076644\'\n' +
                    'LIN|75079|20200418|||||1|5900397009547||AGROS ŁOWICZ DŻEM OWOCE LEŚNE N/S 280G/8SZT|8|8|1|3.14|8|S|2.01\'\n' +
                    'LIN|75079|20200418|||||2|5900397012042||AGROS ŁOWICZ KONFITURA JAGODA 240G/8SZT|8|8|1|5.9|8|S|3.78\'\n' +
                    'LIN|75079|20200418|||||3|5900397734685||AGROS ŁOWICZ KONFITURA MALINA 240G/8SZT|8|8|1|4.88|8|S|3.12\'\n' +
                    'LIN|75079|20200418|||||4|5900397731950||AGROS ŁOWICZ ŻURAWINA DO MIĘS I SERÓW 230G/8SZT|8|8|1|5.1|8|S|3.26\'\n'+
                    'VAT|943.69|23|S|217.05\'\n' +
                    'VAT|778.90|5|S|38.95\'\n' +
                    'VAT|1543.77|8|S|123.50\'\n' +
                    'TOT|3266.36|379.50|3645.86\'\n'
            },
            {symbol: '9', info: 'Przykładowa zawartość pliku:',
                przyklad:
                    'ARTYKUL;INDEKS;KRESKI;ILOSC;WYKONANO;CENA_ZAKUP;CENA_EWID;POZ;IND_ZEWN;JM;SWW;PKWIU;LOKAL;STAN;POZ1;UWAGI_C;\n' +
                    'BARON CZEKOLADA MLECZNA 4X15G;QM27446;5907443606606;12;0;2.63;3.65;1761563;.;SZT;.;.;.;0;;.;\n' +
                    'Wafle 180g orzechowe /14SZT;QM27448;5902978060213;14;0;2.63;3.65;1761563;.;SZT;.;.;.;0;;.;\n' +
                    'Baton Snickers 50g /40SZT;QM27447;5000159461122;36;0;2.63;3.65;1761563;.;SZT;.;.;.;0;;.;\n' +
                    'Galaretka Mieszanka Krakowska luz /2.8KG;QM27449;5900102410842;5.6;0;2.63;3.65;1761563;.;SZT;.;.;.;0;;.;\n'
            },
        ],
        typeOptions:[
            {typ: 'po indeksie', symbol: '0', id: 1},
            {typ: 'po kodzie paskowym', symbol: '1', id: 2},
            {typ: 'KC Firma', symbol: '1', id: 3},
            {typ: 'PC Market', symbol: '2', id: 4},
            // {typ: 'format LGA', symbol: '3'},
            {typ: 'univax', symbol: '4', id: 5},
            {typ: 'amadeo (PC)', symbol: '5', id: 6},
            {typ: 'avita', symbol: '6', id: 7},
            {typ: 'magnat (EDI)', symbol: '7', id: 8},
            {typ: 'mclanemc', symbol: '8', id: 9},
            {typ: 'Hermes', symbol: '9', id: 10},
        ],
        importJson: [],
        // selectedType: {typ: 'po indeksie', symbol: '0'},
        // @ts-ignore
        selectedType: ((localStorage.getItem('FAMIX_IMPORT_TYP'))? (JSON.parse(localStorage.getItem('FAMIX_IMPORT_TYP'))) : ({typ: 'po indeksie', symbol: '0'})),
        textarea: '',
        plik: null,
        formData: null,
        timerId: null,
        progres: 0,
        poprawne: true,
        korekty: true,
        brak: true,
        currentFile: null,
        loading: false,
        exception: false,
        czyStartImport: false,
        etap: 'START IMPORTU',
        pSezonowe: false,
        kData: false
    };

    componentWillUnmount() {
        //@ts-ignore
        window.clearInterval(this.state.timerId);
    }

    toggleHelp = () => {
        this.setState((prevstate: any) => ({
            showHelp: !prevstate.showHelp
        }))
    };


    onSelectType = (e: any) => {
        // this.setState({selectedType: e}, () =>{
        //     let formData = new FormData();
        //     // @ts-ignore
        //     formData.append('file', this.state.plik);
        //     formData.append('typ', this.state.selectedType.symbol);
        //
        //     this.setState({formData: formData});
        // }
        // );

        this.setState({selectedType: e});

        localStorage.setItem('FAMIX_IMPORT_TYP', JSON.stringify(e));

        //alert(Boolean(this.state.importJson.length))
    };

    onChooseFile = (e: any) => {
        if (Boolean(e) && e.length > 0) {
            //displayNotificationSuccess('Pomyślnie wybrano plik', 'Plik został załadowany do pola tekstowego')
            //this.setState({plik: e[0]});

            this.setState({
                plik: e[0]
            }, () => {
                // let data = {
                //     'file': this.state.plik,
                //     'typ': this.state.selectedType.symbol
                // }

                // const formData = new FormData();
                // // @ts-ignore
                // formData.append('file', this.state.plik);
                // formData.append('typ', this.state.selectedType.symbol);
                //
                // this.setState({formData: formData});

                //this.wyslijPlik(formData);
            })


            //this.moveFileIntoTextarea(e[0])
        }
    };

    wyslijPlik = () => {

        this.setState({loading: true, czyStartImport: true});

        if(ApplicationGlobalSettingsService.czyStareDane()){
            this.pobierzOferte();
        } else {
            this.startImport();
        }

    };

    startImport = () => {
        let formData = new FormData();
        //@ts-ignore
        formData.append('file', this.state.plik);
        formData.append('typ', this.state.selectedType.symbol);

        KoszykService.wyslijPlikImport(formData, this.state.kData, this.state.pSezonowe)
            .then((response: any) => {
                if (response && response.status < 300) {

                    console.log(response.data.content);
                    var timerId = window.setInterval(this.sprawdzStatusImportu.bind(this), 1000);
                    this.setState({timerId: timerId});

                    //this.sprawdzStatusImportu();

                } else {
                    console.log('error')
                }
            })
            .catch((e: any) => {
                // console.log(e)
                // this.setState({loading: false, czyStartImport: false});
                this.props.onClose();
                displayNotificationError('Błąd importu', e.response.data);
            })
    }

    onClickPobierzBraki = () => {
        let transformedData: any = [];

        this.state.importJson.filter((t: any) => t.css === 'ErrorRaportPozycja').forEach((obj: any) => {

            let transformedObj: any = {};

            transformedObj = Object.assign(transformedObj, {['Kod_EAN']: obj.indeksKodPaskZpliku, ['Ilosc']: obj.ilosc});

            transformedData.push(transformedObj);
        });

        const options = {
            filename: 'famix-import-braki-' + new Date().toJSON().substr(0, 19),
            fieldSeparator: ";",
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: true,
            title: 'FAMIX Import braki ' + new Date().toJSON().substr(0, 19),
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            headers: null
        };

        // @ts-ignore
        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(transformedData);

    }

    pobierzOferte = () => {

        this.setState({etap: 'POBIERANIE OFERTY'});

        let params = {
            czyEdycja: this.props.czyEdycja,
            indeks: "",
            kategoria: "",
            kodPaskowy: "",
            nazwa: "",
            nazwam: "",
            producent: "",
            sezon: "",
            wykluczKrotkaData: false,
            wykluczProduktySezonowe: false
        };

        OfertaService.getOferta(99999, 10, params)
            .then((response: any) => {

                    sessionStorage.setItem('totalElements', response.data.totalElements);
                    sessionStorage.setItem('AKTUALIZACJA', JSON.stringify(moment().format('YYYY.MM.DD HH:mm')));
                    this.setState({page: 0});
                    this.startImport();

            }).catch((error: any) => {

            })
    };

    sprawdzStatusImportu = () => {
        KoszykService.getImportStatus()
            .then((response: any) => {
                if (response && response.status < 300) {

                    console.log(response.data.content);

                    this.setState({progres: Math.trunc((response.data.progres*100)/response.data.wszystkich), etap: response.data.etap})

                    if(response.data.zakonczono === 1)
                    {
                        if(response.data.exception === null)
                        {
                            //@ts-ignore
                            window.clearInterval(this.state.timerId);
                            this.setState({importJson: response.data.raport, loading: false, exception: false});
                            this.props.odswiez();
                        } else
                        {
                            //@ts-ignore
                            window.clearInterval(this.state.timerId);
                            this.setState({exception: response.data.exception, loading: false});
                            this.props.odswiez();
                        }
                    }

                    //this.setState({importJson: response.data.raport});

                } else {
                    console.log('error')
                    this.setState({loading: false});
                }
            })
            .catch((error: any) => {
                console.log('error')
                this.setState({loading: false});
            })
    };

    onAkceptuj = () => {
        this.props.onClose();
        //this.props.odswiez();
    };

    onFileError = (e: any) => {
        let message = 'Nastąpił błąd podczas próby wybrania pliku'
        if (Boolean(e)) {
            message = e.message;
        }

        displayNotificationError('Błąd wyboru pliku', message)
    };

    onChangeTextarea = (e: any) => {
        this.setState({textarea: e.target.value})
    }

    moveFileIntoTextarea = (file: any) => {
        const reader = new FileReader();
        reader.onload = function () {
            const text = reader.result;
            //@ts-ignore
            this.setState({textarea: text})
        }.bind(this)
        reader.readAsText(file)
    };

    handleChangeInBasket = (previousAmount: any, amount: any, item: any) => {
        //console.log(previousAmount, amount, item);
        this.props.handleChangeQuantityInBasket(previousAmount, amount, item);
    }

    // deleteFromBasket = (item: any) => {
    //     KoszykService.deletePozycjaKoszyka(item.indeks)
    //         .then((response: any) => {
    //             if (response && response.status < 300) {
    //                 //this.setState({shouldKoszykUpdate: true}, () => this.odswiezIloscPozycjiWKoszykuLokalnie(item));
    //                 displayNotificationInfo('Usunięto produkt z koszyka', 'Usunięto produkt o nazwie ' + item.nazwa)
    //             } else {
    //                 displayNotificationError("Nie usunięto produktu z koszyka", "Wystąpił błąd podczas próby wykonania akcji")
    //             }
    //         })
    //         .catch((error: any) => {
    //             displayNotificationError("Wystąpił błąd podczas próby usunięcia produktu z koszyka", error.message)
    //         })
    // };

    filtr = (css: any) => {
        let {poprawne, korekty, brak} = this.state;

        if(css.includes('RightRaportPozycja'))
        {
            return poprawne;
        }

        if(css.includes('WarningRaportPozycja'))
        {
            return korekty;
        }

        if(css.includes('ErrorRaportPozycja'))
        {
            return brak;
        }

    }


    render() {

        let {showHelp, selectedType, typeOptions, kData, pSezonowe, textarea, currentFile, exception, importJson, timerId, progres, formData, plik, poprawne, korekty, brak, loading, etap, czyStartImport} = this.state;

        // const importowane = importJson.map((element:any)=>(
        //     <li>
        //         <div className="element"><i id='koszyk' className="fa fa-shopping-basket"/><div className="naz"><h2>{element.indeks} {element.nazwa}</h2></div> </div>
        //     </li>
        // ));

        return (
            <div id={'KoszykImportContainer'}>
                <Form>
                    {(!czyStartImport)?
                        <>
                        <Row>
                            <Col md={3} lg={3} xl={3}>
                                <FormGroup>
                                    <Form.Label>Typ importu:</Form.Label>
                                    <Select
                                        getOptionLabel={({typ}: any) => typ}
                                        getOptionValue={({id}: any) => id}
                                        options={typeOptions}
                                        value={selectedType}
                                        onChange={this.onSelectType}
                                        menuPosition={"fixed"}
                                        styles={SmallerSelectStyles}
                                        placeholder={'Wybierz typ...'}
                                        isSearchable={!isMobileDevice()}
                                    />
                                </FormGroup>
                            </Col>
                            {/*<Col md={6} lg={4} xl={4}>*/}
                            {/*    <FormGroup>*/}
                            {/*        <AddFormMargin hideForMobileDevices767={true}/>*/}
                            {/*        <Form.Check inline={true} type="checkbox" label="Wyklucz produkty sezonowe"/>*/}
                            {/*        <Form.Check inline={true} type="checkbox" label="Wyklucz produkty z krótką datą"/>*/}
                            {/*    </FormGroup>*/}
                            {/*</Col>*/}

                            <Col md={3} lg={3} xl={3}>
                                <FormGroup>
                                    <AddFormMargin hideForMobileDevices767={true} marginTop={'25px'}/>
                                    <Files
                                        className='file-dropzone'
                                        onChange={this.onChooseFile}
                                        onError={this.onFileError}
                                        multiple={false}
                                        accepts={['.txt', '.edi', '.csv']}
                                        maxFileSize={10000000}
                                        minFileSize={0}
                                        clickable
                                    >
                                        <IconFileTxt/>
                                        {
                                            //@ts-ignore
                                            Boolean(plik) ? `${plik.name.substring(0,23)}${(plik.name.length > 23) && '...'}`
                                                :
                                                'Kliknij aby wczytać plik'
                                        }
                                    </Files>
                                </FormGroup>
                            </Col>


                            <Col md={3} lg={3} xl={3}>
                                <AddFormMargin hideForMobileDevices767={true} marginTop={'15px'}/>
                                <Form.Check inline={true} type="checkbox" label="wyklucz produkty sezonowe"
                                            checked={pSezonowe}
                                            id={'pSezonowe'}
                                            onClick={()=>{this.setState((prevState: any)=>({pSezonowe: !prevState.pSezonowe}))}}
                                />
                                <Form.Check inline={true} type="checkbox" label="wyklucz z krótką datą"
                                            checked={kData}
                                            id={'kData'}
                                            onClick={()=>{this.setState((prevState: any)=>({kData: !prevState.kData}))}}
                                />
                            </Col>
                            {/*<Col md={3} lg={3} xl={4}>*/}
                            {/*    <AddFormMargin hideForMobileDevices767={true}/>*/}
                            {/*    <Form.Check inline={true} type="checkbox" label="wyklucz z krótką datą"*/}
                            {/*                checked={kData}*/}
                            {/*                id={'kData'}*/}
                            {/*                onClick={()=>{this.setState((prevState: any)=>({kData: !prevState.kData}))}}*/}
                            {/*    />*/}
                            {/*</Col>*/}

                            <Col md={3} lg={3} xl={3} className={'align-self-end'}>
                                <FormGroup style={{textAlign: 'center'}}>
                                    {/*<AddFormMargin hideForMobileDevices767={true}/>*/}
                                    <Button style={{margin: '3px'}} disabled={!Boolean(plik && selectedType)} variant={'primary'} size={'sm'} onClick={() => this.wyslijPlik()}><IconImportFile/> Zaimportuj pozycje</Button>
                                </FormGroup>
                            </Col>
                        </Row>
                        </>

                        :
                        (Boolean(importJson.length) && exception === false) &&
                        <Row style={{borderBottom: '1px solid #4f4d83'}}>
                            <Col>
                                {/*<FormGroup>*/}
                                {/*<AddFormMargin hideForMobileDevices767={true}/>*/}
                                <label style={{marginRight: '15px'}}>
                                    <input
                                        style={{marginRight: '5px'}}
                                        name="isGoing"
                                        type="checkbox"
                                        checked={poprawne}
                                        onChange={() => {this.setState({poprawne: !poprawne})}} />
                                    <i style={{color: '#19ae3b'}}><IconCheckCircle/></i> Poprawne
                                </label>

                                <label style={{marginRight: '15px'}}>
                                    <input
                                        style={{marginRight: '5px'}}
                                        name="isGoing"
                                        type="checkbox"
                                        checked={korekty}
                                        onChange={() => {this.setState({korekty: !korekty})}} />
                                    <i style={{color: '#ba8b00'}}><IconWarning/></i> Do korekty
                                </label>

                                <label style={{marginRight: '15px'}}>
                                    <input
                                        style={{marginRight: '5px'}}
                                        name="isGoing"
                                        type="checkbox"
                                        checked={brak}
                                        onChange={() => {this.setState({brak: !brak})}} />
                                    <i style={{color: '#a71d2a'}}><IconWarning/></i> Brak produktu w kartotece
                                </label>

                                <Button variant={'primary'} size={'sm'} onClick={() => this.onClickPobierzBraki()}>Pobierz braki</Button>
                                {/*<Form.Check inline={true} type="checkbox" label="WarningRaportPozycja"/>*/}
                                {/*<Form.Check inline={true} type="checkbox" label="ErrorRaportPozycja"/>*/}
                                {/*</FormGroup>*/}
                            </Col>
                        </Row>
                    }
                    {/*<Row>
                        <Col>
                            <FormGroup>
                                <Form.Label>Produkty do zaimportowania:</Form.Label>
                                <textarea className={'form-control form-control-sm'} rows={7} value={textarea}
                                          onChange={this.onChangeTextarea}> </textarea>
                            </FormGroup>
                        </Col>
                    </Row> */}
                    <Row>
                        <Col>
                            {(Boolean(importJson.length) && exception === false)?
                            <div id={'IMPORT_WYNIKI'}>
                                <ul>
                                    {

                                        importJson
                                            //@ts-ignore
                                            //.sort((a, b) => a.css > b.css ? 1 : -1)
                                            .filter(a => (this.filtr(a.css)))
                                            .map((element: any) => (
                                            <div id={element.css}>
                                                <li>
                                                    <h5> {(element.css === 'RightRaportPozycja') &&
                                                    <i><IconCheckCircle/></i>} {(element.css === 'WarningRaportPozycja') &&
                                                    <i><IconWarning/></i>} {(element.css === 'ErrorRaportPozycja') &&
                                                    <i><IconTimesCircle/></i>} {element.lp}. {element.komunikat}</h5>

                                                    {
                                                        (element.pozycje) ?
                                                            <table>
                                                                <tr>
                                                                    <th>INDEKS</th>
                                                                    <th>NAZWA</th>
                                                                    <th>CENA SPRZ.</th>
                                                                    <th>NR. KAT.</th>
                                                                    <th>Ilość</th>
                                                                </tr>
                                                                {
                                                                    element.pozycje.map((pozycja: any) => (
                                                                        <tr>
                                                                            <td style={{width: '110px'}}>{pozycja.indeks}</td>
                                                                            <td>{pozycja.nazwaPokaz? pozycja.nazwaPokaz : pozycja.nazwa}</td>
                                                                            <td style={{width: '120px'}}>{formatMoney(pozycja.cena)}</td>
                                                                            <td style={{width: '120px'}}>{pozycja.nrKat}</td>
                                                                            <td style={{width: '140px'}}>
                                                                                {/*<input value={pozycja.koszyk} onBlur={()=>console.log('zmien ilosc')}/>*/}
                                                                                <Shared_ProductAddToBasketForm item={pozycja} fieldForAmount={'koszyk'}
                                                                                                               inline={true}
                                                                                                               showButton={false}
                                                                                                               czyZmieniacJm={false}
                                                                                                               handleChangeInBasket={this.handleChangeInBasket}
                                                                                                               alwaysDostepne={true}/>
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </table>
                                                            :
                                                            null
                                                    }

                                                </li>
                                            </div>
                                        ))
                                    }
                                </ul>
                            </div>
                                :
                                (!loading)?
                                        (exception)?
                                            <div style={{minHeight: '200px', padding: '100px 20px', color: 'red'}}>{exception}</div>
                                        :
                                            <div style={{minHeight: '200px', padding: '100px 20px'}}>Wybierz typ importu a następnie wskaż plik z dysku...</div>
                                    :
                                    <div style={{minHeight: '100px', padding: '20px 20px'}}>
                                        <TopLoading isLoading={loading} isError={false} isCentered={false} isBig={false} loadingText={etap}>
                                        </TopLoading>
                                        <ProgressBar variant={'warning'} now={progres} label={`${progres}%`} />
                                    </div>
                            }
                        </Col>
                    </Row>
                    {/*<div id={'importBelka'}>*/}
                    <Row>
                        <Col>
                            <Button variant={'light'} size={'sm'} onClick={this.toggleHelp}>
                                {
                                    showHelp ?
                                        <><IconEyeSlash/> Ukryj pomoc</>
                                        :
                                        <><IconEye/> Pokaż pomoc</>
                                }
                            </Button>
                        </Col>
                        <Col>
                            <div className={'float-right'}>
                                <ButtonToolbar>
                                    {(!Boolean(timerId)) &&
                                        <Button variant={'light'} size={'sm'} onClick={()=>this.props.onClose()}><IconTimes/> Anuluj</Button>
                                    }
                                    {(Boolean(timerId)) &&
                                        <Button disabled={!Boolean(importJson.length > 0)} variant={'primary'} size={'sm'} onClick={() => this.onAkceptuj()}>Akceptuj</Button>
                                    }
                                </ButtonToolbar>
                            </div>
                        </Col>
                    </Row>
                    {/*</div>*/}
                    {
                        (showHelp && Boolean(selectedType)) &&
                        <Row>
                            <Col>
                                {
                                    Boolean(this.state.helpMessages.find((item:any)=>item.symbol===selectedType.symbol)) &&
                                        <div id={'helpbox'}>
                                            <TextAlert type={'info'} marginBottom={true}>
                                                {
                                                    //@ts-ignore
                                                    this.state.helpMessages.find((item:any)=>item.symbol===selectedType.symbol)['info']
                                                }
                                            </TextAlert>
                                            <textarea rows={5} className={'form-control form-control-sm'} value={
                                                //@ts-ignore
                                                this.state.helpMessages.find((item:any)=>item.symbol===selectedType.symbol)['przyklad']
                                            }
                                                      disabled={true}> </textarea>
                                        </div>
                                }

                            </Col>
                        </Row>
                    }
                </Form>
            </div>
        );
    }
}

export default KoszykImportContainer;
