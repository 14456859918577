import React from 'react';
//@ts-ignore
import {LazyLoadImage} from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import {IconMultistar} from "../../../sharedComponents/icons/FontAwesome";
import Shared_ProductImage from "../../../sharedComponents/_reusable/ProductImageLazyLoadingWithErrorHandling/Shared_ProductImage";

interface Props{
    item: any,
    openPromocjePopup: (indeks: any) => void,
}

const ProductImage:React.FC<Props> = ({item, openPromocjePopup}) => {
    return (
        <div id={'ProductImage'}>
            {
                (Boolean(item.upustProcentowy) && item.upustProcentowy > 0) &&
                <div className={'product-promocja'} onClick={() => openPromocjePopup(item)}>
                    <div className={'triangle'}></div>
                    <div className={'inner'}>
                        <IconMultistar/> Promocja
                    </div>
                </div>
            }
            <Shared_ProductImage item={item} />
        </div>
    );
};

export default ProductImage;