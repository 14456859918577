import React from 'react';
import LayoutComponent from "../../sharedComponents/layout/LayoutComponent";
import {Button, Card, Col, Row} from "react-bootstrap";
import {isMobileDevice} from "../../utilities/rwd/rwdUtilities";
import TopTableGridSwitcher from "../../sharedComponents/TopTableGridSwitcher";
import OfertaTable from "./components/OfertaTable";
import OfertaGrid from "./components/OfertaGrid";
import OfertaFilterbox from "./components/OfertaFilterbox";
import {IconImportFile, IconLoading} from "../../sharedComponents/icons/FontAwesome";
import TopVarietyOptions from "../../sharedComponents/TopVarietyOptions";
import ApplicationGlobalSettingsService from "../../services/ApplicationGlobalSettingsService";
import OfertaService from "../../services/OfertaService";
import TopLoading from "../../sharedComponents/loader/TopLoading";
import {_omit} from "../../utilities/lodash/lodashUtilities";
//@ts-ignore
import {displayNotificationError, displayNotificationInfo} from "../../utilities/notifications/NotificationsManager";
import KoszykService from "../../services/KoszykService";
import moment from "moment";
import PopupWrapper from "../../sharedComponents/popups/PopupWrapper";
import KoszykImportContainer from "../koszyk/importComponents/KoszykImportContainer";
import PromocjeService from "../../services/PromocjeService";
import PromocjePopup from "./components/PromocjePopup";
import PromocjaModuleBody from "../promocja/PromocjaModuleBody";
import PopupActiveLink from "../../sharedComponents/popups/PopupActiveLink";
import PromocjaActiveLink from "./components/PromocjaActiveLink";
import KartaProduktu from "./components/KartaProduktu";
import {famix_replace} from "../../utilities/famix_others/famix_operations";
// import any = jasmine.any;


interface MatchParams {
    path: string;
}

export interface RouteComponentProps<MatchParams> {
    match: any;
}

interface Props extends RouteComponentProps<MatchParams> {
}

interface State {
    shouldKoszykUpdate: boolean,
    currentView: boolean, // table - true, grid - false
    produkty: any,
    producenci: any,
    kategorie: any,
    sezony: any,
    isOfertaTreeCollapsed: boolean,
    typeOfertaTree: any, // true - 'categories', // lub false 'producers'
    //   collapsedMenu: 1,    //which li in sidebar menu should be collapsed
    isRequestLoading: boolean,
    isRequestError: boolean,
    params: any,
    // sortowanie: any,
    sort: object[],
    page: number,
    size: number,
    pageableObject: any,
    hasMoreForGrid: boolean,
    canLoadMore: boolean,

    stanKoszyka: any,
    edycjaSymbol: string,
    edycjaNumer: number,

    czyEdycja: boolean,
    czyStareDane: boolean,
    showImportPopup: boolean,
    zakupNaKrt: boolean,
    showPromocjePopup: boolean,
    promocje: any,
    zmianaOferta: boolean,
    itemProm: any,
    loading: boolean,
    showPromocjaPopup: boolean,
    idPromocji: any,
    clickIndeksRow: any,
    nazwaPromocji: string,
    showPopupActiveLink: boolean,
    indeksActiveLink: any,
    idUmActiveLink: any,
    showPopupKartaProduktu: boolean,
    kartaProduktuHeader: any,
    timerStronaZeroId: any
}

class OfertaModule extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        let tableSize: any = (localStorage.getItem('FAMIX_CURRENT_TABLESIZE'))? localStorage.getItem('FAMIX_CURRENT_TABLESIZE') : 10;

        this.state = {
            shouldKoszykUpdate: false,

            currentView: true, // table - true, grid - false
            produkty: [],
            producenci: [],
            kategorie: [],
            sezony: [],
            isOfertaTreeCollapsed: true,
            typeOfertaTree: true, // true - 'categories', // lub false 'producers'
            //   collapsedMenu: 1,    //which li in sidebar menu should be collapsed

            isRequestLoading: false,
            isRequestError: false,

            params: {
                "czyEdycja": "",
                "indeks": "",
                "kategoria": "",
                "kodPaskowy": "",
                "nazwa": "",
                "nazwam": "",
                "producent": "",
                "sezon": "",
                "wykluczKrotkaData": false,
                "wykluczProduktySezonowe": false
                // "totalElements": 0
            },

            // sortowanie: (Boolean(ApplicationGlobalSettingsService.getGlobalSettingsConfiguration('sortowanie'))) ? (ApplicationGlobalSettingsService.getGlobalSettingsConfiguration('sortowanie')) : '',
            sort: (Boolean(ApplicationGlobalSettingsService.getGlobalSettingsConfiguration('sort'))) ? (ApplicationGlobalSettingsService.getGlobalSettingsConfiguration('sort')) : [],
            // sort: [],


            page: 0,
            size: tableSize !== undefined ? parseInt(tableSize) : 10,
            pageableObject: {
                currentSize: 12,
                currentPage: 0,
                totalElements: 0,
            },
            hasMoreForGrid: true,
            canLoadMore: true,

            stanKoszyka: null,
            edycjaSymbol: "",
            edycjaNumer: 0,

            czyEdycja: false,
            czyStareDane: true,
            showImportPopup: false,
            // zakupNaSzt: (ApplicationGlobalSettingsService.getSesionStorage('zakupNaSzt')) !== undefined ? (ApplicationGlobalSettingsService.getSesionStorage('zakupNaSzt')) : false,
            zakupNaKrt: (ApplicationGlobalSettingsService.getGlobalSettingsConfiguration('zakupNaKrt')) !== null ? (ApplicationGlobalSettingsService.getGlobalSettingsConfiguration('zakupNaKrt')) : true,
            showPromocjePopup: false,
            promocje: [],
            zmianaOferta: false,
            itemProm: [],
            loading: false,
            showPromocjaPopup: false,
            idPromocji: null,
            clickIndeksRow: null,
            nazwaPromocji: '',
            showPopupActiveLink: false,
            indeksActiveLink: '',
            idUmActiveLink: '',
            showPopupKartaProduktu: false,
            kartaProduktuHeader: '',
            timerStronaZeroId: null
        };
    }

    componentDidMount() {

        if (isMobileDevice())
            window.scrollTo(0, 0);

        this.setState({
            currentView: isMobileDevice() ? false : (((ApplicationGlobalSettingsService.getGlobalSettingsModuleView('Oferty')) !== null)? ApplicationGlobalSettingsService.getGlobalSettingsModuleView('Oferty') : true),
            sort: (Boolean(ApplicationGlobalSettingsService.getGlobalSettingsConfiguration('sort'))) ? (ApplicationGlobalSettingsService.getGlobalSettingsConfiguration('sort')) : [],
            // zakupNaSzt: (ApplicationGlobalSettingsService.getSesionStorage('zakupNaSzt')) !== undefined? (ApplicationGlobalSettingsService.getSesionStorage('zakupNaSzt')) : false
            zakupNaKrt: (ApplicationGlobalSettingsService.getGlobalSettingsConfiguration('zakupNaKrt')) !== null ? (ApplicationGlobalSettingsService.getGlobalSettingsConfiguration('zakupNaKrt')) : true

        }, () => this.setState({
            size: (this.state.currentView)? this.state.size : 12
        }));

        this.setState({
            isRequestLoading: true,
            isRequestError: false,
        });

        if (ApplicationGlobalSettingsService.czyStareDane()){

            this.setState({czyStareDane: true, page: 99999});

        } else {

            this.setState({czyStareDane: false});

        }

        this.pobierzKategorie();
        this.pobierzProducentow();
        this.pobierzSezony();
        this.pobierzPodsumowanieKoszyka();

        let {match} = this.props;

        if (match)
            if (match.params)
                if (match.params.indeks)
                    this.setState({indeksActiveLink: match.params.indeks, idUmActiveLink: match.params.idum},() => this.setState({showPopupActiveLink: true}));

        window.addEventListener('scroll', this.toggleVisible);
        var timerId = window.setInterval(this.pobierzStroneZero, 7320000);
        this.setState({timerStronaZeroId: timerId});
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.toggleVisible);
        window.clearInterval(this.state.timerStronaZeroId);
    }

    pobierzStroneZero = () => {
        if(ApplicationGlobalSettingsService.czyStareDane()) {
            this.setState({page: 99999, loading: true}, () => this.pobierzOferte());
        }
    }

    toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300){
            //@ts-ignore
            document.getElementById('myBtn').style.opacity = '1';
            // document.getElementById('myBtn').style.display = 'block';
        }
        else if (scrolled <= 300){
            //@ts-ignore
            document.getElementById('myBtn').style.opacity = '0';
            // document.getElementById('myBtn').style.display = 'none';
        }
    };

    scrollToTop = () =>{
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
    };

    czyFiltr = (): boolean => {
        let par = this.state.params;

        return par.indeks !== '' || par.kategoria !== '' || par.kodPaskowy !== '' || par.nazwa !== '' || par.nazwam !== '' || par.producent !== '' || par.sezon !== '';
    }

    generateSortString = (): string => {
        let sortowanie = this.state.sort;
        let sortString = '';

        for (let i=0; sortowanie.length > i; i++)
        {
            // @ts-ignore
            sortString = sortString + '&sort=' + sortowanie[i].kolumna + ',' + sortowanie[i].kierunek;
        }

        return sortString;

    }



    pobierzPodsumowanieKoszyka = () => {
        KoszykService.getStanKoszyka()
            .then((response: any) => {
                this.setState({
                    stanKoszyka: response.data,
                    czyEdycja: (response.data.numer !== 0),
                    edycjaSymbol: response.data.symbol,
                    edycjaNumer: response.data.numer
                }, () => this.pobierzOferte());
            })
            .catch((error: any) => {
                console.log('error');
            })
    };

    /* get functions  */

    pobierzProducentow = () => {
        OfertaService.getProducenci()
            .then((response: any) => {
                if (response.status < 300)
                    this.setState({producenci: response.data});
            })
            .catch((error: any) => {

            })
    };

    pobierzKategorie = () => {
        OfertaService.getKategorie()
            .then((response: any) => {
                if (response.status < 300)
                    this.setState({kategorie: response.data});
            })
            .catch((error: any) => {

            })
    };

    pobierzSezony = () => {
        OfertaService.getSezony()
            .then((response: any) => {
                if (response.status < 300)
                    this.setState({sezony: response.data});
            })
            .catch((error: any) => {

            })
    };

    pobierzOferte = (params = this.state.params, page = this.state.page, size = this.state.size, sort = this.state.sort) => {

        this.setState({
            // isRequestLoading: true,
            isRequestError: false,
        });

        params.czyEdycja = this.state.czyEdycja;
        params.nazwa = famix_replace(params.nazwa, ['@', '#', '!', '(', ')', '-', 'X', 'x', '/', ';', '*', '_', '"'], ' ');

        OfertaService.getOferta(page, size, params, this.generateSortString())
            .then((response: any) => {
                    if (response && response.status < 300) {

                        let ileElementow = sessionStorage.getItem('totalElements');

                        this.setState({
                            isRequestLoading: false,
                            isRequestError: false,
                            loading: false,
                            produkty: response.data.content,
                            // currentView: isMobileDevice() ? false : ApplicationGlobalSettingsService.getGlobalSettingsModuleView('Oferty'),
                            pageableObject: Object.assign({}, _omit(response.data, "content"), {currentPage: page}, {currentSize: size}, {allElements: (this.czyFiltr())? response.data.totalElements : ((page === 99999)? response.data.totalElements : (ileElementow)? ileElementow : response.data.totalElements)}),
                            hasMoreForGrid: !(((this.czyFiltr())? response.data.totalElements : ((page === 99999)? response.data.totalElements : (ileElementow)? ileElementow : response.data.totalElements)) === response.data.content.length),
                            canLoadMore: true,
                            params: Object.assign({}, _omit(params, "params"),{totalElements: response.data.totalElements})
                        }, () => this.onGlobalZakupJmChange(true));

                        if (page === 99999) {
                            sessionStorage.setItem('totalElements', response.data.totalElements);
                            sessionStorage.setItem('AKTUALIZACJA', JSON.stringify(moment().format('YYYY.MM.DD HH:mm')));
                            this.setState({page: 0});
                        }

                    } else {
                        this.setState({
                            isRequestLoading: false,
                            isRequestError: true,
                            loading: false,
                            hasMoreForGrid: false,
                            canLoadMore: false
                        });
                    }
                }
            )
            .catch((error: any) => {
                this.setState({
                    isRequestLoading: false,
                    isRequestError: true,
                    loading: false,
                    hasMoreForGrid: false,
                    canLoadMore: false
                });
            })
    };


    /* refresh functions */

    odswiezIloscWOfercieLokalnie = (amount: any, item: any, typOpak?: string) => {
        let {produkty} = this.state;
        let produkt: any = produkty.find((p: any) => p.indeks === item.indeks);

        if (Boolean(produkt)) {
            produkt.koszyk = amount
            produkt.koszykIlosc = amount
            if(typOpak) produkt.zakupJm = typOpak
        }

        this.setState({produkty, zmianaOferta: !this.state.zmianaOferta})
    };

    onGlobalZakupJmChange = (cdm=false) => {

        let zakupNaKrt = cdm? this.state.zakupNaKrt : !this.state.zakupNaKrt;
        let {produkty} = this.state;
        // console.log('ON jednostka GLOBAL');
        produkty.forEach((item: any) => {
            if (item.koszykIlosc === 0 && !KoszykService.isEmpty(item.jm2) && !KoszykService.isEmpty(item.jm3)) {
                if (item.jm === 'SZT' && item.jm2 === 'SZT' && item.jm3 === 'KRT') {
                    item.zakupJm = zakupNaKrt ? item.jm3 : item.jm;
                    //	console.log(item.indeks+"  Zakup JM:"+item.zakupJm);
                }
            }
        });

        if(cdm)
        {
            this.setState({ produkty: produkty });
        } else
        {
            this.setState({ zakupNaKrt: zakupNaKrt, produkty: produkty }, () => ApplicationGlobalSettingsService.setGlobalSettingsConfigurationSection('zakupNaKrt', zakupNaKrt));
        }

    };

    odswiez = (amount: any, item: any, typOpak?: string) => {

        this.setState({shouldKoszykUpdate: true}, () => this.odswiezIloscWOfercieLokalnie(amount, item, typOpak));

    }

    /* helpers functions  */

    renderBreadcrumbs = (categories: any[], params: any) => {
        let breadcrumbs = [{name: 'Oferta', url: '/oferta'}];

        let kategoria;

        if (params.kategoria) {
            kategoria = categories.find((c: any) => c.url === params.kategoria);
            if (kategoria) {
                breadcrumbs.push({name: kategoria.nazwa, url: '/' + params.kategoria})
            }
        }
        if (params.podkategoria) {
            if (kategoria) {
                let podkategoria = kategoria.podkategorie.find((p: any) => p.url === params.podkategoria)
                if (podkategoria) {
                    breadcrumbs.push({name: podkategoria.nazwa, url: '/' + params.podkategoria})
                }
            }
        }

        return breadcrumbs
    };

    onChangeView = (currentView: boolean) => {
        let tableSize: any = (localStorage.getItem('FAMIX_CURRENT_TABLESIZE'))? localStorage.getItem('FAMIX_CURRENT_TABLESIZE') : 10;

        this.setState({
            currentView,
            page: 0,
            size: (!currentView? 12 : tableSize !== undefined ? parseInt(tableSize) : 10),
            produkty: [],
            loading: true,
            isRequestLoading: true,
            isRequestError: false
        }, () => this.pobierzOferte());
    };

    /* table functions  */

    onChangePageInTable = (page: number, size: number) => {
        let strona = page;
        if (strona === 1 && ApplicationGlobalSettingsService.czyStareDane()) {
            strona = 99999;
        } else {
            strona = page-1;
        }

        this.setState({
            loading: true,
            page: strona,
            size: size
        }, () => this.pobierzOferte())
    };

    onChangeSizeInTable = (size: number) => {
        localStorage.setItem('FAMIX_CURRENT_TABLESIZE', String(size))
        this.setState({
            loading: true,
            size
        }, () => this.pobierzOferte())
    };


    /* grid functions  */

    loadMoreElementsForGrid = (e: any) => {

        if (this.state.canLoadMore && !this.state.isRequestLoading) {

            this.setState({canLoadMore: false, isRequestLoading: true})

            let {produkty}: any = this.state;

            this.setState((prevState: any) => ({page: prevState.page + 1}), () => {
                OfertaService.getOferta(this.state.page, 12, this.state.params, this.generateSortString())
                    .then(response => {
                        if (response.status < 300) {

                            this.setState({
                                isRequestLoading: false,
                                isRequestError: false,
                                pageableObject: Object.assign({}, _omit(response.data, "content"), {currentPage: this.state.page}, {currentSize: 12}),
                                canLoadMore: true,
                                hasMoreForGrid: (response.data.totalPages > this.state.page)
                            });

                            response.data.content.forEach((item: any) => {
                                produkty.push(item)
                            });

                            this.setState({produkty});

                        } else {
                            this.setState({
                                canLoadMore: true,
                                isRequestLoading: false,
                                isRequestError: true,
                                hasMoreForGrid: false
                            });
                        }
                    })
                    .catch(error => {
                        this.setState({
                            canLoadMore: true,
                            isRequestLoading: false,
                            isRequestError: true,
                            hasMoreForGrid: false
                        });
                    })
            })

        }

    };


    /* filterbox functions  */

    onSelectOrClearValue = (type: string, value: any) => {
        let {params}: any = this.state;
        if (value === null)
            params[type] = null;
        else {
            if (type === 'producent')
                params[type] = value.producent;
            else if (type === 'kategoria')
                params[type] = value.grupa;
            else if (type === 'sezon')
                params[type] = value.grupa;
        }
        this.onClickFiltruj(params);
    };


    onClickFiltruj = (params: any) => {
        this.setState({params: params, page: 0, loading: true}, () => this.pobierzOferte())
    };

    /* sort functions */

    onChangeSortowanie = (sort: any) => {
        // let sor = sort.value;
        // let sortString = sort.map((s: any, index: number) => {
        //     return s.kolumna+','+s.kierunek
        // }).toString();

        // setTimeout(() => {
        //     this.setState({sort: sort, page: (this.czyStareDane())? 99999 : 0, loading: true}, () => this.pobierzOferte());
        // }, 2000);
        // this.setState({sort: sort});

        this.setState({sort: sort, page: (ApplicationGlobalSettingsService.czyStareDane())? 99999 : 0, loading: true}, () => this.pobierzOferte());

        let data = ApplicationGlobalSettingsService.getLocalStorageConfigurationObject();
        //console.log(sort);
        data["sort"] = sort;
        ApplicationGlobalSettingsService.setLocalStorageConfigurationObject(data);

    };

    /* basket functions */

    onChangeInBasket = (previousAmount: any, amount: any, item: any, opakowanie: string) => {

        if (amount === previousAmount) {
            // brak zmiany
        }
        if (amount === 0 && previousAmount !== 0) {
            // usunięto produkt z koszyka
            // KoszykService.deletePozycjaKoszyka(item.indeks)
            //     .then((response: any) => {
            //         console.log(response)
            //         if (response && response.status < 300) {
            //             this.setState({shouldKoszykUpdate: true}, () => this.odswiezIloscWOfercieLokalnie(amount, item));
            //             displayNotificationInfo('Usunięto produkt z koszyka', 'Usunięto produkt o nazwie ' + item.nazwa)
            //         } else {
            //             displayNotificationError("Nie usunięto produktu z koszyka", "Wystąpił błąd podczas próby wykonania akcji")
            //         }
            //     })
            //     .catch((error: any) => {
            //         console.log(error)
            //         console.log(error.message)
            //         displayNotificationError("Wystąpił błąd podczas próby dodania bądź edytowania ilości produktu w koszyku", error.message)
            //     })

            // 16.08.2021

            // KoszykService.addOrEditPozycjaKoszyka(Object.assign(item, {ilosc: 0, sprzedazJm: item.zakupJm, czyEdycja: this.state.czyEdycja}))
            //     .then((response: any) => {
            //         if (response && response.status < 300) {
            //             this.setState({shouldKoszykUpdate: true}, () => this.odswiezIloscWOfercieLokalnie(amount, item));
            //             displayNotificationInfo('Usunięto produkt z koszyka', 'Usunięto produkt o nazwie ' + item.nazwa)
            //         } else {
            //             displayNotificationError("Nie usunięto produktu z koszyka", "Wystąpił błąd podczas próby wykonania akcji")
            //         }
            //
            //     })
            //     .catch((error: any) => {
            //         displayNotificationError("Wystąpił błąd podczas próby usunięcia produktu z koszyka", error.message)
            //     })

            this.deleteFromBasket(item);
        }
        if ((amount !== previousAmount && previousAmount === 0) || (amount !== previousAmount && previousAmount > 0 && amount > 0)) {
            // dodano produkt do koszyka lub zmieniono ilość produktu w koszyku

            KoszykService.addOrEditPozycjaKoszyka(Object.assign(item, {ilosc: amount, sprzedazJm: opakowanie, czyEdycja: this.state.czyEdycja}))
                .then((response: any) => {

                    let jm = item.zakupJm;

                    if (response && response.status < 300) {
                        this.setState({shouldKoszykUpdate: true}, () => this.odswiezIloscWOfercieLokalnie(amount, item, opakowanie));
                        if (amount !== previousAmount && previousAmount === 0)  // dodano produkt
                            displayNotificationInfo('Dodano produkt do koszyka', `Dodano produkt ${item.nazwa} w ilości ${amount} ${jm}`)
                        if (amount !== previousAmount && previousAmount > 0 && amount > 0)  // edytowano produkt
                            displayNotificationInfo('Zmieniono ilość produktu w koszyku', `Zmieniono ilość produktu ${item.nazwa} z ${previousAmount} na ${amount} ${jm}`)

                    } else {
                        if (amount !== previousAmount && previousAmount === 0)
                            displayNotificationError("Nie dodano produktu do koszyka", "Wystąpił błąd podczas próby wykonania akcji")
                        if (amount !== previousAmount && previousAmount > 0 && amount > 0)
                            displayNotificationError("Nie edytowano ilości produktu w koszyka", "Wystąpił błąd podczas próby wykonania akcji")
                    }

                })
                .catch((error: any) => {
                    if (amount !== previousAmount && previousAmount === 0)
                        displayNotificationError("Nie dodano produktu do koszyka", error.message)
                    if (amount !== previousAmount && previousAmount > 0 && amount > 0)
                        displayNotificationError("Nie edytowano ilości produktu w koszyka", error.message)
                })
        }
    };

    onChangeInImport = (previousAmount: any, amount: any, item: any) => {
        if (previousAmount === amount) {
            // brak zmian
        } else {
            if (previousAmount > 0 && amount === 0) {
                // usunięcie z koszyka
                this.deleteFromBasket(item);
            } else {
                // zmiana ilości w koszyku
                KoszykService.addOrEditPozycjaKoszyka(Object.assign(item, {ilosc: amount, sprzedazJm: item.zakupJm, czyEdycja: this.state.czyEdycja}))
                    .then((response: any) => {
                        if (response && response.status < 300) {
                            this.setState({shouldKoszykUpdate: true}, () => {this.odswiezIloscWOfercieLokalnie(amount, item); }); //this.pobierzPodsumowanieKoszyka();
                            displayNotificationInfo('Zmieniono ilość produktu w koszyku', `Zmieniono ilość produktu ${item.nazwa} z ${previousAmount} na ${amount} ${item.jm}`)
                        } else {
                            displayNotificationError("Nie edytowano ilości produktu w koszyka", "Wystąpił błąd podczas próby wykonania akcji")
                        }

                    })
                    .catch((error: any) => {
                        displayNotificationError("Wystąpił błąd podczas próby dodania bądź edytowania ilości produktu w koszyku", error.message)
                    })
            }
        }
    };

    deleteFromBasket = (item: any) => {
        KoszykService.addOrEditPozycjaKoszyka(Object.assign(item, {ilosc: 0, sprzedazJm: item.zakupJm, czyEdycja: this.state.czyEdycja}))
            .then((response: any) => {
                if (response && response.status < 300) {
                    this.setState({shouldKoszykUpdate: true}, () => {this.odswiezIloscWOfercieLokalnie(0, item); }); //this.pobierzPodsumowanieKoszyka();
                    displayNotificationInfo('Usunięto produkt z koszyka', 'Usunięto produkt o nazwie ' + item.nazwa)
                } else {
                    displayNotificationError("Nie usunięto produktu z koszyka", "Wystąpił błąd podczas próby wykonania akcji")
                }

            })
            .catch((error: any) => {
                displayNotificationError("Wystąpił błąd podczas próby usunięcia produktu z koszyka", error.message)
            })
    };

    openPromocjePopup = (item: any) => {

        // 07.04.2022 zakomentowane aby pokazywalo liste promocji
        // if(item.ikonyTab.length === 1)
        // {
        //     PromocjeService.getPromocjeIndeks(item.indeks).then((response) => {
        //         // window.location.replace('/promocje/'+response.data[0].idUm);
        //         this.setState({showPromocjaPopup: true, idPromocji: response.data[0].idUm, clickIndeksRow: item});
        //     }).catch((e) => {
        //         displayNotificationError("Nie wczytano promocji", "Wystąpił błąd podczas próby wykonania akcji");
        //     })
        // } else
        // {
        //     this.setState({showPromocjePopup: true, itemProm: item, clickIndeksRow: item});
        // }

        this.setState({showPromocjePopup: true, itemProm: item, clickIndeksRow: item});

    }

    openKartaProduktuPopup = (item: any) => {

        this.setState({showPopupKartaProduktu: true, itemProm: item, kartaProduktuHeader: `Karta produktu ${item.producent} ${item.nazwa}`});

    }

    render() {

        const paramsMatch = this.props.match.params;
        let {showImportPopup, showPopupKartaProduktu, kartaProduktuHeader, nazwaPromocji, loading, indeksActiveLink, idUmActiveLink, showPromocjaPopup, idPromocji, clickIndeksRow, itemProm, promocje, zmianaOferta, showPromocjePopup, showPopupActiveLink, shouldKoszykUpdate, zakupNaKrt, params, currentView, produkty, isOfertaTreeCollapsed, typeOfertaTree, producenci, kategorie, sezony, isRequestLoading, isRequestError, pageableObject, hasMoreForGrid, czyEdycja, edycjaSymbol, edycjaNumer} = this.state;

        return (
            <LayoutComponent breadcrumbs={this.renderBreadcrumbs(kategorie, paramsMatch)}
                             shouldKoszykUpdate={shouldKoszykUpdate}
                             handleShouldKoszykUpdateChange={() => this.setState({shouldKoszykUpdate: false})}
                             handleChangeSiec={(siecObj: any) => {
                                 this.setState({page: 99999, loading: true}, () => {
                                     this.pobierzOferte();
                                 })
                             }}
                             ustawCzyEdycja={(czyEdycja, symbol, numer) => this.setState({czyEdycja: czyEdycja, edycjaSymbol: symbol, edycjaNumer: numer})}
                             anulowanoEdycje={() => {this.setState({loading: true}); this.pobierzOferte();}}
            >
                <div id={'OFERTA_MODULE'}>

                    <Row>
                        <Col style={{textAlign: 'end'}}>
                            <div style={{display: 'inline-block'}}>
                                <Button variant={'light'} className={''} title={'Import zamówień'} size={'sm'} id={'importBtn'} onClick={() => {this.setState({showImportPopup: true})}} style={{marginBottom: '5px', color: '#605D8D'}}>
                                    <IconImportFile/> Import zamówień
                                </Button>
                                {/*<TopVarietyOptions options={[*/}
                                {/*    {*/}
                                {/*        id: 'KoszykImportSwitcher',*/}
                                {/*        handleClick: () => {this.setState({showImportPopup: true})},*/}
                                {/*        icon: <IconImportFile/>,*/}
                                {/*        text: 'Import'*/}
                                {/*    }*/}
                                {/*]}/>*/}
                            </div>
                            <div style={{display: 'inline-block'}}>
                                <TopTableGridSwitcher currentView={currentView} quantity={
                                    // @ts-ignore
                                    Boolean(pageableObject) ? pageableObject.totalElements : 0
                                }
                                                      handleChangeView={this.onChangeView}/>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} style={{padding: '0'}}>
                            <OfertaFilterbox handleClickFiltruj={this.onClickFiltruj}
                                             kategorie={kategorie}
                                             producenci={producenci}
                                             sezony={sezony}
                                             params={params}
                                             handleSelectOrClearValue={this.onSelectOrClearValue}
                                             isButtonDisabled={isRequestLoading}
                            />
                            {
                                currentView ?
                                    <TopLoading isLoading={isRequestLoading} isError={isRequestError}>
                                        <OfertaTable produkty={produkty}
                                                     pageableObject={pageableObject}
                                                     handleChangePage={this.onChangePageInTable}
                                                     handleChangeSizePerPage={this.onChangeSizeInTable}
                                                     handleChangeInBasket={this.onChangeInBasket}
                                                     odswiezIlosc={this.odswiez}
                                                     odswiezIloscLokalnie={this.odswiezIloscWOfercieLokalnie}
                                                     czyEdycja={this.state.czyEdycja}
                                                     handleChangeSortowanie={this.onChangeSortowanie}
                                                     sortProps={this.state.sort}
                                                     changeJm={this.onGlobalZakupJmChange}
                                                     zakupNaKtr={zakupNaKrt}
                                                     openPromocjePopup={(indeks) => this.openPromocjePopup(indeks)}
                                                     changeOferta={zmianaOferta}
                                                     loading={loading}
                                        />
                                    </TopLoading>
                                    :

                                    <OfertaGrid data={produkty} isLoading={isRequestLoading} hasMore={hasMoreForGrid}
                                                handleLoadMoreForGrid={this.loadMoreElementsForGrid}
                                                handleChangeInBasket={this.onChangeInBasket}
                                                odswiezIloscLokalnie={this.odswiezIloscWOfercieLokalnie}
                                                openPromocjePopup={(indeks) => this.openPromocjePopup(indeks)}
                                                openKartaProduktuPopup={(indeks) => this.openKartaProduktuPopup(indeks)}
                                    />

                            }

                        </Col>
                    </Row>
                </div>
                {
                    showImportPopup &&
                    <PopupWrapper header={'Import pozycji do koszyka'} shouldNotCloseWithoutClick={true}
                                  onClose={() => this.setState({showImportPopup: false})}>
                        <KoszykImportContainer onClose={() => {this.setState({showImportPopup: false}); }} odswiez={() => {this.pobierzPodsumowanieKoszyka(); this.setState({shouldKoszykUpdate: true});}} czyEdycja={czyEdycja} handleChangeQuantityInBasket={this.onChangeInImport} />
                    </PopupWrapper>
                }
                {
                    showPopupKartaProduktu &&
                    <PopupWrapper header={kartaProduktuHeader} onClose={() => this.setState({showPopupKartaProduktu: false})} classname={'POPUP_WRAPPER'}>
                        <KartaProduktu item={itemProm} edycja={czyEdycja} alwaysDostepne={true} odswiezIlosc={this.odswiez} onClose={() => this.setState({showPopupKartaProduktu: false})} openPromocjePopup={(indeks) => this.openPromocjePopup(indeks)}/>
                    </PopupWrapper>
                }
                {
                    showPromocjePopup &&
                    <PromocjePopup item={itemProm} onClickPrzejdz={(idUm: any) => this.setState({showPromocjaPopup: true, idPromocji: idUm})} closePopup={() => this.setState({showPromocjePopup: false})}/>
                }
                {
                    showPromocjaPopup &&
                    <PopupWrapper header={`Promocja: `+nazwaPromocji} onClose={() => this.setState({showPromocjaPopup: false})} classname={'POPUP_WRAPPER_OLD'}>
                        <PromocjaModuleBody czyEdycja={czyEdycja} czyZOferty={true} czyZLinku={false} idUm={idPromocji} kategorie={kategorie} clickIndeksRow={clickIndeksRow} producenci={producenci} sezony={sezony} odswiez={this.odswiez} odswierzKoszyk={() => {this.setState({shouldKoszykUpdate: true});}} ustawNazwePromocji={(nazwa) => this.setState({nazwaPromocji: nazwa})}/>
                    </PopupWrapper>
                }
                {
                    showPopupActiveLink &&
                    <PopupActiveLink header={``} onClose={() => this.setState({showPopupActiveLink: false})} classname={'popupActiveLink'}>
                        <PromocjaActiveLink indeks={indeksActiveLink} idUm={idUmActiveLink} odswierzKoszyk={() => {this.pobierzOferte(); this.setState({shouldKoszykUpdate: true});}}/>
                    </PopupActiveLink>
                }

                <button onClick={this.scrollToTop} id={"myBtn"} title="Wróć na górę"><i className="fa fa-arrow-up"/></button>

            </LayoutComponent>
        );
    }
}

export default OfertaModule;
