import React from 'react';
import TopCard from "../../../../sharedComponents/TopCard";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import tgrid from "../../../../utilities/table/tgrid";
import {
    TableActionButtons_Edit2AnyActions,
} from "../../../../sharedComponents/tableComponents/tableButtons";
import {IconBars, IconLock, IconLockOpen} from "../../../../sharedComponents/icons/FontAwesome";
import {TableFormatters_formatBoolean} from "../../../../sharedComponents/tableComponents/tableFormatters";
import {changeNestedArrayToFlat} from "../../../../utilities/arrays/arraysUtilities";
import {prettyColumns_AdminPromocje} from "../../../../utilities/exports/prettyColumnToExport";
import AdminPromocjeProduktyTable from "./AdminPromocjeProduktyTable";


interface Props {
    promocje: object[]
}

class AdminPromocjeTable extends React.Component<Props> {


    state = {
        currentPromocja: null,
    };

    formatAkcje = (cell: number, row: any) => {
        return <TableActionButtons_Edit2AnyActions handleClickEdit={() => {
        }}
                                                   handleClick1Action={() => {
                                                       this.onClickShowPozycje(row)
                                                   }}
                                                   action1Icon={<IconBars/>}
                                                   action1Title={'Pokaż szczegóły'}
                                                   handleClick2Action={() => {
                                                   }}
                                                   action2Icon={row.czyAktywna ? <IconLock/> : <IconLockOpen/>}
                                                   action2Title={row.czyAktywna ? 'Zablokuj promocję' : 'Odblokuj promocję'}
        />
    };

    formatAktywnosc = (cell: boolean) => {
        return <TableFormatters_formatBoolean value={cell} titleTrue={'Promocja aktywna'}
                                              titleFalse={'Promocja nieaktywna'}/>
    };

    onClickShowPozycje = (row: object) => {
        if (Boolean(this.state.currentPromocja)) {
            this.setState({
                currentPromocja: null,
            })
        }
        this.setState({
            currentPromocja: row,
        })
    };

    expandComponent = (row: any) => {
        // @ts-ignore
        const expandableData = (this.state.currentPromocja) ? this.state.currentPromocja.produktyWPromocji : [];
        if (Boolean(expandableData) && expandableData.length > 0)
            return (<AdminPromocjeProduktyTable data={expandableData}
                                                topCardHeading={'Produkty w promocji'}
                                                hideActionButtons={true}/>);
        else
            return <h6 className={'heading-label'}>Brak zdefiniowanych produktów w promocji</h6>
    };

    render() {
        let {promocje} = this.props;

        return (
            <div id={'AdminPromocjeTable'}>
                <TopCard heading={'Promocje w systemie'}
                         hasPadding={false}
                         enableExport={true}
                         dataToExport={changeNestedArrayToFlat(promocje, 'produktyWPromocji')}
                         columnsNames={prettyColumns_AdminPromocje}>
                    <BootstrapTable data={promocje}
                                    hover={true}
                                    options={{onlyOneExpanding: true}}
                                    expandableRow={(row) => {
                                        //@ts-ignore
                                        return (this.state.currentPromocja) ? (this.state.currentPromocja.id === row.id) : false
                                    }}
                                    expandComponent={this.expandComponent}
                                    containerClass={'smaller-table'}>
                        <TableHeaderColumn dataField={'id'}
                                           thStyle={tgrid.t2}
                                           tdStyle={tgrid.t2}
                                           dataSort={true}
                                           expandable={false}
                                           isKey={true}>ID</TableHeaderColumn>
                        <TableHeaderColumn dataField={'name'}
                                           thStyle={tgrid.t10}
                                           tdStyle={tgrid.t10}
                                           dataSort={true}
                                           expandable={false}
                        >Nazwa</TableHeaderColumn>
                        <TableHeaderColumn dataField={'dateAvailability'}
                                           thStyle={tgrid.t4}
                                           tdStyle={tgrid.t4}
                                           dataSort={true}
                                           expandable={false}
                        >Data ważności</TableHeaderColumn>
                        <TableHeaderColumn dataField={'czyAktywna'}
                                           thStyle={tgrid.t2}
                                           tdStyle={tgrid.t2}
                                           dataSort={true}
                                           dataFormat={this.formatAktywnosc}
                                           expandable={false}
                        >Aktywna?</TableHeaderColumn>
                        <TableHeaderColumn dataField={'id'}
                                           thStyle={tgrid.t2}
                                           tdStyle={tgrid.t2}
                                           dataSort={true}
                                           dataFormat={this.formatAkcje}
                                           expandable={false}
                        >Akcje</TableHeaderColumn>
                    </BootstrapTable>
                </TopCard>
            </div>
        );
    }
}

export default AdminPromocjeTable;