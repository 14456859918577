import React, {Component} from 'react';
import LayoutComponent from "../../sharedComponents/layout/LayoutComponent";
import {API_getPromocje} from "../../_static_data/response";
// import PromocjaProstaContainer from './promocjaProstaComponents/PromocjaProstaContainer';
import PromocjaGazetkaContainer from "./gazetkaComponents/PromocjaGazetkaContainer";
import {displayNotificationInfo, displayNotificationSuccess} from "../../utilities/notifications/NotificationsManager";
import {TextAlert} from "../../sharedComponents/typographyComponents/TextAlert/TextAlert";
import {isMobileDevice} from "../../utilities/rwd/rwdUtilities";
import {Accordion, Button, Card, Col, Form, Row} from "react-bootstrap";
import PromocjeService from "../../services/PromocjeService";
import TopLoading from "../../sharedComponents/loader/TopLoading";
import IconPromocja from "../oferta/components/IconPromocja";
import {prettyColumns_Oferta} from "../../utilities/exports/prettyColumnToExport";
import TopCard from "../../sharedComponents/TopCard";
import OfertaService from "../../services/OfertaService";
import {_omit} from "../../utilities/lodash/lodashUtilities";
import moment from "moment";
import ApplicationGlobalSettingsService from "../../services/ApplicationGlobalSettingsService";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import TopIndeksWithBiggerView from "../../sharedComponents/TopIndeksWithBiggerView";
import {IconCart, IconTimes} from "../../sharedComponents/icons/FontAwesome";
import Shared_ProductAddToBasketForm
    from "../../sharedComponents/_reusable/ProductAddToBasket/Shared_ProductAddToBasketForm";
import {TableImage_WithPreview} from "../../sharedComponents/tableComponents/tableImages";
import ReactTooltip from "react-tooltip";
import Shared_ProductImage
    from "../../sharedComponents/_reusable/ProductImageLazyLoadingWithErrorHandling/Shared_ProductImage";
import {TableFormatters_formatMoney} from "../../sharedComponents/tableComponents/tableFormatters";
import {famix_zaokraglijIloscDlaJM1} from "../../utilities/famix_others/famix_operations";
import PromocjaInfo from "./PromocjaInfo";
import KoszykService from "../../services/KoszykService";
import {isEmpty} from "lodash";
import Switch from "react-switch";
import PromocjaModuleBody from "./PromocjaModuleBody";

interface MatchParams {
    path: string;
}

export interface RouteComponentProps<MatchParams> {
    match: any;
}

interface Props extends RouteComponentProps<MatchParams> {
    czyZLinku: boolean
}

interface State {
    promocjaInfo: any,
    promocjaProgowa: any,
    progi: any,
    isRequestLoading: boolean,
    isRequestError: boolean,
    isRequestLoadingOferta: boolean,
    isRequestErrorOferta: boolean,
    produkty: any,
    pageableObject: any,
    params: any,
    sort: object[],
    page: number,
    size: number,
    rodzajPromocji: string,
    czyEdycja: boolean,
    edycjaSymbol: string,
    edycjaNumer: number,
    shouldKoszykUpdate: boolean,
    idPromocji: any,
    producenci: any,
    kategorie: any,
    sezony: any,
    promocja: any
}

class PromocjaModule extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            promocjaInfo: {NAZWA: ''},
            shouldKoszykUpdate: false,
            rodzajPromocji: '',
            promocjaProgowa: [],
            progi: [],
            isRequestLoading: true,
            isRequestError: false,
            isRequestLoadingOferta: true,
            isRequestErrorOferta: false,
            produkty: [],
            pageableObject: {
                currentSize: 12,
                currentPage: 0,
                totalElements: 0,
            },
            params: {
                "czyEdycja": "",
                "indeks": "",
                "kategoria": "",
                "kodPaskowy": "",
                "nazwa": "",
                "nazwam": "",
                "producent": "",
                "sezon": "",
                "wykluczKrotkaData": false,
                "wykluczProduktySezonowe": false,
                "idProm": ""
            },
            sort: [],
            page: 0,
            size: 10,
            czyEdycja: false,
            edycjaSymbol: "",
            edycjaNumer: 0,
            idPromocji: null,
            producenci: [],
            kategorie: [],
            sezony: [],
            promocja: null
        }
    }

    componentDidMount() {

        if(isMobileDevice())
            window.scrollTo(0,0);

        // displayNotificationInfo('Ten widok zawiera dane statyczne', '')

        if (Boolean(this.props.match))
            if (Boolean(this.props.match.params))
                if (Boolean(this.props.match.params.id))
                {
                    // this.pobierzPromocjeInfo(this.props.match.params.id);
                    // this.pobierzOferte();
                    this.setState({idPromocji: this.props.match.params.id})
                    // this.getPromocja('4');
                }

    }

    // getPromocja = (id: any) => {
    //     let promocja = API_getPromocje().find((p: any) => p.id === parseInt(id));
    //     if (promocja) {
    //         this.setState({promocja});
    //     }
    // };

    // pobierzPromocjeInfo = (id: any) => {
    //     this.setState({
    //         isRequestLoading: true,
    //         isRequestError: false,
    //     });
    //
    //     PromocjeService.getPromocjaInfo(id).then((response) => {
    //         this.setState({promocjaInfo: response.data, isRequestLoading: false, isRequestError: false});
    //
    //         if(response.data.OZN === 'P')
    //         {
    //             this.setState({rodzajPromocji: 'P'});
    //             this.pobierzPromocjaProgowa(id);
    //         } else
    //         {
    //             this.pobierzOferte();
    //         }
    //
    //     }).catch((e) => {
    //         console.log('error');
    //         this.setState({isRequestLoading: false, isRequestError: true});
    //     })
    // }
    //
    // pobierzPromocjaProgowa = (id: any) => {
    //     this.setState({
    //         isRequestLoading: true,
    //         isRequestError: false,
    //     });
    //
    //     PromocjeService.getPromocjaProgrowa(false, id).then((response) => {
    //
    //         this.setState({promocjaProgowa: response.data, progi: response.data.progi, isRequestLoading: false, isRequestError: false});
    //
    //     }).catch((e) => {
    //         console.log('error');
    //         this.setState({isRequestLoading: false, isRequestError: true});
    //     })
    //
    // }

    // getPromocja = (id: any) => {
    //     let promocja = API_getPromocje().find((p: any) => p.id === parseInt(id));
    //     if (promocja) {
    //         this.setState({promocja});
    //     }
    // };

    pobierzOferte = (params = this.state.params, page = this.state.page, size = this.state.size, sort = this.state.sort) => {

        this.setState({
            isRequestLoadingOferta: true,
            isRequestErrorOferta: false,
        });

        params.czyEdycja = false;
        params.idProm = this.props.match.params.id;

        // let sortString = sort.map((s: any, index: number) => {
        //     return s.kolumna+' '+s.kierunek
        // }).toString();

        OfertaService.getOferta(page, size, params, "")
            .then((response: any) => {
                    if (response && response.status < 300) {

                        let ileElementow = sessionStorage.getItem('totalElements');

                        this.setState({
                            isRequestLoadingOferta: false,
                            isRequestErrorOferta: false,
                            produkty: response.data.content,
                            // currentView: true,
                            pageableObject: Object.assign({}, _omit(response.data, "content"), {currentPage: page}, {currentSize: size}, {allElements: response.data.totalElements}),
                            // hasMoreForGrid: !(((this.czyFiltr())? response.data.totalElements : ((page === 99999)? response.data.totalElements : (ileElementow)? ileElementow : response.data.totalElements)) === response.data.content.length),
                            // canLoadMore: true,
                            params: Object.assign({}, _omit(params, "params"),{totalElements: response.data.totalElements})
                        });

                        if (page === 99999) {
                            sessionStorage.setItem('totalElements', response.data.totalElements);
                            sessionStorage.setItem('AKTUALIZACJA', JSON.stringify(moment().format('YYYY.MM.DD HH:mm')));
                        }

                    } else {
                        this.setState({
                            isRequestLoadingOferta: false,
                            isRequestErrorOferta: true,
                            // hasMoreForGrid: false,
                            // canLoadMore: false
                        });
                    }
                }
            )
            .catch((error: any) => {
                this.setState({
                    isRequestLoadingOferta: false,
                    isRequestErrorOferta: true,
                    // hasMoreForGrid: false,
                    // canLoadMore: false
                });
            })
    };

    onChangePageInTable = (page: number, size: number) => {
        let strona = page;
        if (strona === 1) {
            strona = 0;
        } else {
            strona = page-1;
        }

        this.setState({
            page: strona,
            size: size
        }, () => this.pobierzOferte())
    };

    onChangeSizeInTable = (size: number) => {
        localStorage.setItem('FAMIX_CURRENT_TABLESIZE', String(size))
        this.setState({
            size
        }, () => this.pobierzOferte())
    };

    onClickAddProductToBasketByClick = (product: any, currentPageCounter: number) => {
        let promocja: any = this.state.promocjaInfo;
        let areas: any = promocja.strony[currentPageCounter].areas;

        let area: any = areas.find((a: any) => a.product.id === product.id)

        let produkt: any = area.product;

        /*        produkt.ileKoszyk = produkt.ileKoszyk + produkt.iloscOpakowanie;
                    this.setState({promocja}, () => {
                    displayNotificationInfo('Dodano do koszyka', `Dodano do koszyka ${produkt.ilJm2} ${produkt.jm} produktu ${produkt.nazwa}`)
                })
        */

        displayNotificationInfo('Tutaj będzie dodanie do koszyka', `Dodano do koszyka ${produkt.ilJm2} ${produkt.jm} produktu ${produkt.nazwa}`)
    };

    formatZdjecieRow = (cell: string, row: any) => {
        return (
            <div>
                <div data-tip data-for={"punkt" + row.indeks}>
                    <TableImage_WithPreview item={row} desc={'Produkt ' + row.nazwa}/>
                </div>

                <ReactTooltip place="right" type="light" effect="float" id={"punkt" + row.indeks} className={'image-tooltip'} globalEventOff='click'>
                    <div id={'ProductImage'} data-event-off='click'>
                        <Shared_ProductImage item={row} />
                    </div>
                </ReactTooltip>
            </div>
        )
    };

    formatMoneyRow = (cell: number, row: object) => {
        return <TableFormatters_formatMoney value={cell}/>
    };

    formatOpakowanieRow = (cell: any, row: any) => {
        return(
            <div className={'formatOpakowanieRow'}>
                {
                    (row.jm !== row.jm2) &&
                    <div>
                        {row.jm2} ({famix_zaokraglijIloscDlaJM1(row.jm, row.ilJm2)} {row.jm})
                    </div>
                }
                {
                    (row.jm2 !== row.jm3) &&
                    <div>
                        {row.jm3} ({famix_zaokraglijIloscDlaJM1(row.jm, row.ilJm3)} {row.jm})
                    </div>
                }
                {
                    (row.jm === row.jm2 === row.jm3) &&
                    <span>
                        -
                    </span>
                }
            </div>
        )
    };

    columns = [{
        dataField: 'imagePath',
        text: 'Zdjęcie',
        formatter: this.formatZdjecieRow
    }, {
        dataField: 'indeks',
        text: 'Indeks',
        // headerFormatter: () => {return <>Indeks &nbsp;{this.ikonaSort('indeks')}</>},
        align: 'center',
        headerStyle: {cursor: 'pointer'},
        // formatter: (cell: any, row: any, index: any, extraData: any) => (
        //     {cell}
        //     // <TopIndeksWithBiggerView item={row} zmienInput={this.zmienInput} odswiezIlosc={this.props.odswiezIlosc} edycja={this.props.czyEdycja}><a id='indeks'>{cell}</a></TopIndeksWithBiggerView>
        // ),
        // onSort: this.onSortChange,
        // sort: true
    }, {
        dataField: 'producent',
        text: 'Producent',
        // headerFormatter: () => {return <>Producent &nbsp;{this.ikonaSort('cast(PRODUCENT as varchar(60) character set win1250) collate PXW_PLK ')}</>},
        align: 'center',
        headerStyle: {cursor: 'pointer'},
        // onSort: this.onSortChange,
        // sort: true
    }, {
        dataField: 'nazwaPokaz',
        text: 'Nazwa',
        // headerFormatter: () => {return <>Nazwa &nbsp;{this.ikonaSort('cast(NAZWA as varchar(60) character set win1250) collate PXW_PLK ')}</>},
        align: 'center',
        headerStyle: {cursor: 'pointer'},
        // onSort: this.onSortChange,
        // sort: true
    }, {
        dataField: 'nrKat',
        text: 'EAN szt.',
        align: 'center'
    }, {
        dataField: 'cenaS',
        text: 'Cena cennik.',
        // headerFormatter: () => {return <>Cena cennik. &nbsp;{this.ikonaSort('cena_s')}</>},
        align: 'center',
        headerStyle: {cursor: 'pointer'},
        formatter: this.formatMoneyRow,
        // onSort: this.onSortChange,
        // sort: true
    }, {
        dataField: 'cena',
        text: 'Cena prom.',
        formatter: this.formatMoneyRow
    },
        //     {
        //     dataField: 'upustProcentowy',
        //     text: 'Prom.',
        //     style: {maxWidth: '70px'},
        //     align: 'center',
        //     formatter: this.formatPromocjaRow
        // },
        {
            dataField: 'jm',
            text: 'Opak / karton',
            formatter: this.formatOpakowanieRow
        }
        , {
            dataField: 'zakupJm',
            text: 'Koszyk',
            headerFormatter: () => {return( <><IconCart/>&nbsp; Koszyk</>)},
            style: {width: '145px'},
            // formatExtraData: changeProps || this.props.changeOferta,
            formatter: (cell: any, row: any, index: any, extraData: any) => (
                <Shared_ProductAddToBasketForm item={row} fieldForAmount={'koszykIlosc'} inline={true} odswiezIloscLokalnie={() => console.log('od')} showButton={false} handleChangeInBasket={() => alert('Edycja ilosci w budowie...')}/>
            )
        }
    ];

    columnsProg = [
        {
        dataField: 'indeks',
        text: 'Indeks',
        // headerFormatter: () => {return <>Indeks &nbsp;{this.ikonaSort('indeks')}</>},
        align: 'center',
        headerStyle: {cursor: 'pointer'},
        // formatter: (cell: any, row: any, index: any, extraData: any) => (
        //     {cell}
        //     // <TopIndeksWithBiggerView item={row} zmienInput={this.zmienInput} odswiezIlosc={this.props.odswiezIlosc} edycja={this.props.czyEdycja}><a id='indeks'>{cell}</a></TopIndeksWithBiggerView>
        // ),
        // onSort: this.onSortChange,
        // sort: true
    }, {
        dataField: 'producent',
        text: 'Producent',
        // headerFormatter: () => {return <>Producent &nbsp;{this.ikonaSort('cast(PRODUCENT as varchar(60) character set win1250) collate PXW_PLK ')}</>},
        align: 'center',
        headerStyle: {cursor: 'pointer'},
        // onSort: this.onSortChange,
        // sort: true
    }, {
        dataField: 'nazwa',
        text: 'Nazwa',
        // headerFormatter: () => {return <>Nazwa &nbsp;{this.ikonaSort('cast(NAZWA as varchar(60) character set win1250) collate PXW_PLK ')}</>},
        align: 'center',
        headerStyle: {cursor: 'pointer'},
        // onSort: this.onSortChange,
        // sort: true
    }, {
        dataField: 'nrKat',
        text: 'EAN szt.',
        align: 'center'
    }, {
        dataField: 'cenaS',
        text: 'Cena cennik.',
        // headerFormatter: () => {return <>Cena cennik. &nbsp;{this.ikonaSort('cena_s')}</>},
        align: 'center',
        headerStyle: {cursor: 'pointer'},
        formatter: this.formatMoneyRow,
        // onSort: this.onSortChange,
        // sort: true
    }, {
        dataField: 'cena',
        text: 'Cena prom.',
        formatter: this.formatMoneyRow
    }, {
            dataField: 'progIl',
            text: 'Min. ilość',
            align: 'center',
            formatter: (cell: any, row: any, index: any, extraData: any) => (
                <>{cell} ({row.jm})</>
            )
        },

        {
            dataField: 'jm',
            text: 'Opak / karton',
            formatter: this.formatOpakowanieRow
        }
        , {
            dataField: 'zakupJm',
            text: 'Koszyk',
            headerFormatter: () => {return( <><IconCart/>&nbsp; Koszyk</>)},
            style: {width: '145px'},
            // formatExtraData: changeProps || this.props.changeOferta,
            formatter: (cell: any, row: any, index: any, extraData: any) => (
                row.koszykIlosc !== null &&
                <Shared_ProductAddToBasketForm item={row} fieldForAmount={'koszykIlosc'} inline={true} odswiezIloscLokalnie={() => console.log('od')} showButton={false} handleChangeInBasket={() => alert('Edycja ilosci w budowie...')}/>
            )
        }
    ];

    // getLabel = (promocja: string) => {
    //     switch (promocja) {
    //         case 'C':
    //             return 'Promocja cenowa';
    //         case 'P':
    //             return 'Promocja pakietowa';
    //         case 'L':
    //             return 'Promocja lojalnościowa';
    //         case 'D':
    //             return 'Promocja dla klienta';
    //     }
    // };

    rowStyle = (row: any, rowIndex: number) => {
        const style: any = {};

        // if (row.id > 3) {
        //     style.backgroundColor = '#c8e6c9';
        // } else {
        //     style.backgroundColor = '#00BFFF';
        // }

        // if (rowIndex > 2) {
        //     style.fontWeight = 'bold';
        //     style.color = 'white';
        // }

        style.color = '#777777';
        style.backgroundColor = '#bfbfbf';

        return style;
    };

    // onDodajPakiet = (prog: number) => {
    //     let idUm = this.props.match.params.id;
    //     let edycja = this.state.czyEdycja;
    //
    //     PromocjeService.dodajPakiet(idUm, prog, edycja).then((response) => {
    //         displayNotificationSuccess('Dodano pakiet do koszyka', 'Próg '+prog);
    //         this.setState({shouldKoszykUpdate: true});
    //     }).catch((e) => {
    //         console.log('error dodaj pakiet')
    //     })
    // }

    formatPromocjaHeader = () => {
        return(<a href={'/oferta'}><div id={'popup-close-icon'} title={'Kliknij, aby wrócić do oferty'}>
                <IconTimes/>
            </div></a>
        )
    };

    render() {
        let {promocjaInfo, idPromocji, czyEdycja, producenci, kategorie, sezony, isRequestError, isRequestLoading, produkty, pageableObject, isRequestLoadingOferta, isRequestErrorOferta, shouldKoszykUpdate, rodzajPromocji, promocjaProgowa, progi} = this.state;

        //@ts-ignore
        const breadcrumbs = [{name: 'Promocje', url: '/promocje'}, {
            //@ts-ignore
            name: this.state.promocja ? `Nr.  ${this.state.promocja.id}` : 'Dana promocja',
            //@ts-ignore
            url: this.state.promocja ? `/${this.state.promocja.id}` : ''
        }];

        return (
            <LayoutComponent breadcrumbs={breadcrumbs} baner={false}
                             shouldKoszykUpdate={shouldKoszykUpdate}
                             handleShouldKoszykUpdateChange={() => this.setState({shouldKoszykUpdate: false})}
                             ustawCzyEdycja={(czyEdycja, symbol, numer) => this.setState({czyEdycja: czyEdycja, edycjaSymbol: symbol, edycjaNumer: numer})}
            >
                {/*<TextAlert type={'danger'} marginBottom={true}>Ten widok zawiera dane statyczne</TextAlert>*/}
                <TopCard heading={'Promocja: '+promocjaInfo.NAZWA} hasPadding={false} pobierzOferte={false}
                         rightComponent={this.formatPromocjaHeader()}>

                    {idPromocji !== null &&
                        <PromocjaModuleBody czyEdycja={czyEdycja} czyZOferty={false} czyZLinku={this.props.czyZLinku} idUm={idPromocji} odswierzKoszyk={() => this.setState({shouldKoszykUpdate: true})} ustawNazwePromocji={(nazwa) => this.setState({promocjaInfo: {'NAZWA': nazwa}})}/>
                    }

                </TopCard>
            </LayoutComponent>
        );
    }
}

export default PromocjaModule;