import React from 'react';
import {Button, Col, Row} from "react-bootstrap";
import OverflowTextWrapper from "../../../../sharedComponents/typographyComponents/overflowText/OverflowTextWrapper";
import {TableFormatters_formatBoolean} from "../../../../sharedComponents/tableComponents/tableFormatters";
import {IconEdit, IconTrash} from "../../../../sharedComponents/icons/FontAwesome";
import TopLoaderComponent from "../../../../sharedComponents/loader/TopLoaderComponent";
//@ts-ignore
import InfiniteScroll from 'react-infinite-scroller';

interface Props {
    komunikaty: any,
    handleClickEdit:(komunikat:any)=>void;
    handleClickDelete:(komunikat:any)=>void;
    isLoading?:boolean,
    hasMore?: boolean,
    handleLoadMoreForGrid?: any
}

const AdminKomunikatyGrid: React.FC<Props> = ({komunikaty,handleClickEdit,handleClickDelete, isLoading,hasMore,handleLoadMoreForGrid}) => {
    return (
        <div id={'AdminKomunikatyGrid'}>
            <InfiniteScroll
                initialLoad={false}
                pageStart={0}
                loadMore={handleLoadMoreForGrid}
                hasMore={hasMore}
                loader={(isLoading)?<TopLoaderComponent /> : (komunikaty.length>0 && hasMore) ? <h3 style={{color:'#ddd', textAlign:'center'}}>Scrolluj aby załadować wiecej wyników</h3> : ''}
                threshold={-10}
            >
            <Row style={{paddingBottom: '25px'}}>
                {
                    komunikaty.map((item: any) => (
                        <Col md={6} lg={4} xl={3}>
                            <div className={'admin-komunikaty-container'}>
                                <div>
                                    <div className={'smaller-grid-tills'}>
                                        <label>ID:</label>
                                        <span>{item.id}</span>
                                    </div>
                                    <div className={'smaller-grid-tills'}>
                                        <label>Data wpisu:</label>
                                        <span>{item.dataWpisu}</span>
                                    </div>
                                    <div className={'smaller-grid-tills full-width'}>
                                        <label>Komunikat:</label>
                                        <span><OverflowTextWrapper text={item.komunikat} howManyLettersVisible={30}/></span>
                                    </div>
                                </div>
                                <div>
                                    <label>
                                        Kontrahent:
                                    </label>
                                    <span>
                                        {item.kontrahent}
                                    </span>
                                </div>
                                <div>
                                    <label>
                                        Data zmiany:
                                    </label>
                                    <span>
                                        {item.dataZmiany ? item.dataZmiany :'-'}
                                    </span>
                                </div>
                                <div>
                                    <label>
                                        Ważny do:
                                    </label>
                                    <span>
                                        {item.waznosc ? item.waznosc :'-'}
                                    </span>
                                </div>
                                <div>
                                    <label>
                                        Czy pokazywać?
                                    </label>
                                    <span>
                                        <TableFormatters_formatBoolean value={!item.niePokazuj} titleTrue={'Jest widoczny'} titleFalse={'Nie jest widoczny'} />
                                    </span>
                                </div>
                                <div>
                                    <label>
                                        Czy B2B?
                                    </label>
                                    <span>
                                        <TableFormatters_formatBoolean value={item.czyKomunikatB2b} titleTrue={'Jest B2B'} titleFalse={'Nie jest B2B'} />
                                    </span>
                                </div>
                                <div>
                                    <label>
                                        Czy wysyłać email?
                                    </label>
                                    <span>
                                        <TableFormatters_formatBoolean value={item.czyEmail} titleTrue={'Jest wysyłany na email'} titleFalse={'Nie jest wysyłany na email'} />
                                    </span>
                                </div>
                                <div>
                                    <label>
                                        Wyświetlono B2B:
                                    </label>
                                    <span>
                                        {item.dataWyswietleniaB2b ? item.dataWyswietleniaB2b :'-'}
                                    </span>
                                </div>
                                <div>
                                    <label>
                                        Email wysłano:
                                    </label>
                                    <span>
                                        {item.dataWysylkiEmail ? item.dataWysylkiEmail :'-'}
                                    </span>
                                </div>
                                <div>
                                    <Button block={true} onClick={() => handleClickEdit(item)} variant={'light'} size={'sm'}>
                                        <div className={'text-warning'}>
                                        <IconEdit/> Edytuj
                                        </div>
                                    </Button>
                                </div>
                                <div>
                                    <Button block={true} onClick={() => handleClickDelete(item)} variant={'light'} size={'sm'}>
                                        <div className={'text-danger'}>
                                            <IconTrash/> Usuń
                                        </div>
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    ))
                }
            </Row>
            </InfiniteScroll>
            {
                (!isLoading && komunikaty.length === 0) &&
                <h3 style={{color: '#ccc', textAlign: 'center'}}>Nie znaleziono rekordów spełniających kryteria</h3>
            }
            {
                (!isLoading && !hasMore && komunikaty.length > 0) &&
                <h3 style={{color: '#ccc', textAlign: 'center'}}>To już wszystkie znalezione wyniki</h3>
            }
        </div>
    );
};

export default AdminKomunikatyGrid;