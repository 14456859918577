import React from 'react';
import logo from "../../resources/images/famixlogo.png";
//@ts-ignore
import {LazyLoadImage} from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import {TEST_VERSION} from "../../ApplicationConfig";

interface Props {
    children: any
}

const MiddleCenterCardWrapper: React.FC<Props> = ({children}) => {
    return (
        <div id={'MiddleCenterCardWrapper'}>
            <div id={'img-container'}>
                {(TEST_VERSION)&&
                    <h3 style={{color: 'red', textAlign: 'center'}}>
                        Wersja testowa platformy B2B
                    </h3>
                }
                <LazyLoadImage src={logo} alt={'Logo Famix'} effect={'blur'}/>
            </div>
            <div id={'form-container'}>
                {children}
            </div>
        </div>
    );
};

export default MiddleCenterCardWrapper;
