import React, {Component} from 'react';
import {IconDownload, IconExportPdf, IconFileCSV, IconInfo, IconSettings} from "../icons/FontAwesome";
import PopupWrapper from "../popups/PopupWrapper";
import {Col, Form, FormGroup, ProgressBar, Row} from "react-bootstrap";
import Select from "react-select";
import {SmallerSelectStyles} from "../../utilities/forms/jsStyles";
//@ts-ignore
import {ExportToCsv} from 'export-to-csv';
import {formatMoney} from "../../utilities/formatters/formatMoney";
import {TextAlert} from "../typographyComponents/TextAlert/TextAlert";
import {isMobileDevice} from "../../utilities/rwd/rwdUtilities";
import ZamowieniaService from "../../services/ZamowieniaService";
import {displayNotificationError, displayNotificationSuccess} from "../../utilities/notifications/NotificationsManager";
import {saveAnyFileAndDownload} from "../../utilities/files/fileOperations";

interface Props {

}

class PobierzOferte extends Component<Props> {

    state = {

    };


    onClickPobierz = () => {
        alert('pobieramy')
    };

    onClickSettings = () => {
        this.setState({showModal: true})
    };

    render() {

        return (
            <>
                <div className={'pobierzOferte'}>
                    <span title={'Pobierz oferte'} onClick={this.onClickPobierz}>
                        <IconDownload/>
                        {/*<ProgressBar variant={'warning'} now={50} label={`Trwa generowanie oferty do pobrania ${50}%`} animated={true}/>*/}
                    </span>
                </div>
                {/*{*/}
                {/*    showModal &&*/}
                {/*    <PopupWrapper header={'Opcje exportu'} onClose={() => {*/}
                {/*        this.setState({showModal: false})*/}
                {/*    }}>*/}
                {/*        <Form id={'TopExportForm'}>*/}
                {/*            <Row>*/}
                {/*                <Col md={5} lg={4} xl={3}>*/}
                {/*                    <FormGroup>*/}
                {/*                        <Form.Label>*/}
                {/*                            Separator*/}
                {/*                        </Form.Label>*/}
                {/*                        <Select*/}
                {/*                            value={settings.separator}*/}
                {/*                            options={separators}*/}
                {/*                            onChange={this.onChangeSeparator}*/}
                {/*                            isClearable={false}*/}
                {/*                            styles={SmallerSelectStyles}*/}
                {/*                            isSearchable={!isMobileDevice()}*/}
                {/*                        />*/}
                {/*                    </FormGroup>*/}
                {/*                </Col>*/}
                {/*                <Col md={7} lg={8} xl={9}>*/}
                {/*                    <FormGroup>*/}
                {/*                        <Form.Label>*/}
                {/*                            Kolumny do exportu*/}
                {/*                        </Form.Label>*/}
                {/*                        <Select*/}
                {/*                            value={settings.columnsToExport}*/}
                {/*                            options={columnsAll}*/}
                {/*                            onChange={this.onChangeColumnsToExport}*/}
                {/*                            isMulti={true}*/}
                {/*                            isClearable={false}*/}
                {/*                            styles={SmallerSelectStyles}*/}
                {/*                            isSearchable={!isMobileDevice()}*/}
                {/*                        />*/}
                {/*                    </FormGroup>*/}
                {/*                </Col>*/}
                {/*                <Col lg={12}>*/}
                {/*                    <TextAlert type={'info'}>Zmiany zapisują się automatycznie</TextAlert>*/}
                {/*                </Col>*/}
                {/*            </Row>*/}
                {/*        </Form>*/}

                {/*    </PopupWrapper>*/}
                {/*}*/}
            </>
        );
    }
}

export default PobierzOferte;
