import {HOST_API} from "../ApplicationConfig";
import axios from 'axios';

const HOST = HOST_API + 'koszyk/';

export default class KoszykService {

    static getStanKoszyka = () => {
        return axios.request({
            method: 'get',
            url: `${HOST}getKoszykIloscWartosc`
        })
    };

    // static getKoszyk = (page: number, size: number, edycja: boolean) => {
    //     return axios.request({
    //         method: 'get',
    //         url: `${HOST}getKoszyk/${edycja}?page=${page}&size=${size}&paged=true`, //&sort=m.indeks,asc`,
    //     })
    // };

    static getKoszyk = (page: number, size: number, sort: string = '', edycja: boolean) => {
        return axios.request({
            method: 'get',
            url: `${HOST}getKoszyk/${edycja}?page=${page}&size=${size}&paged=true${sort}`, //&sort=m.indeks,asc`,
        })
    };

    // 02.02.2023 wersja ktora sprawdza wygasle promocje i podejmuje akcje
    // WERYFIKACJA = 0
    // USUN_WYGASLE = 1
    // DODJ_PONOWNIE = 2
    static getKoszykV2 = (page: number, size: number, sort: string = '', edycja: boolean, akcja: number = 0) => {
        return axios.request({
            method: 'get',
            url: `${HOST}getKoszykCheck/${edycja}/${akcja}?page=${page}&size=${size}&paged=true${sort}`, //&sort=m.indeks,asc`,
        })
    };

    static addOrEditPozycjaKoszyka = (obj: any) => {
        return axios.request({
            method: 'put',
            url: `${HOST}addEditPozycjeKoszykaNew`,
            data: JSON.stringify(obj)
        })
    };

    static addOrEditPozycjaKoszykaPromPakG = (obj: any) => {
        return axios.request({
            method: 'put',
            url: HOST_API + `promocje/addEditItemPromPakG`,
            data: JSON.stringify(obj)
        })
    };

    static addOrEditPozycjaKoszykaPromPakPCU = (obj: any) => {
        return axios.request({
            method: 'put',
            url: HOST_API + `promocje/addEditItemPromPakCu`,
            data: JSON.stringify(obj)
        })
    };

    static addEditKoszykActiveLink = (obj: any) => {
        return axios.request({
            method: 'put',
            url: `${HOST}addEditKoszykActiveLink`,
            data: JSON.stringify(obj)
        })
    };

    static deletePozycjaKoszyka = (indeks: string) => {
        return axios.request({
            method: 'delete',
            url: `${HOST}delPozycjaKoszyka?indeks=${indeks}`
        })
    };

    static deleteWygaslaPozycjaKoszyka = (id: any, czyEdycja: boolean) => {
        return axios.request({
            method: 'delete',
            url: `${HOST}deletePozycjaFromKoszyk/${czyEdycja}/${id}`
        })
    };

    static deletePromocjeZKoszyka = (data: any) => {
        return axios.request({
            method: 'delete',
            url: `${HOST}delPromocjaAll`,
            data: data
        })
    };

    static wyslijZamowienieZKoszyka = (obj: any) => {
        return axios.request({
            method: 'post',
            url: `${HOST}wyslijZamowienie`,
            data: JSON.stringify(obj)
        })
    };

    static wyslijZamowienieZKoszykaPoEdycji = (numer: number, symbol: string, uwagi: string) => {
        return axios.request({
            method: 'post',
            url: `${HOST}wyslijZamowieniePoEdycji`,
            data: {
                numer: numer,
                symbol: symbol,
                uwagi: uwagi
            }
        })
    };

    static getWarunkiDostawy = () => {
        return axios.request({
            method: 'get',
            url: `${HOST}getWarunkiDostawy`,
        })
    };

    static wyslijPlikImport = (obj: any, kData: boolean, pSezon: boolean) => {
        return axios.request({
            method: 'post',
            headers: {'content-type': 'multipart/form-data'},
            url: `${HOST}startImportKoszyka?kData=${kData}&pSezon=${pSezon}`,
            data: obj
        })
    };

    static getImportStatus = () => {
        return axios.request({
            method: 'get',
            url: `${HOST}getImportStatus`,
        })
    };

    static getPdfKoszyk = () => {
        return axios.request({
            method: 'get',
            url: `${HOST}exportKoszykToPDF`,
            responseType:"blob"
        })
    };

    static getExcelKoszyk = () => {
        return axios.request({
            method: 'get',
            url: `${HOST}exportKoszykToExcel`,
            responseType:"blob"
        })
    };

    static isEmpty = (value: any) => {
        return value === undefined || value === null || value === '' || value === 'null';
    }

    static isEmptyIdUm = (value: any) => {
        return value === undefined || value === null || value === '' || value === 'null' || value === 0 || value === '0';
    }

}
