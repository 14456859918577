import React, {Component} from 'react';
import OfertaService from "../../../services/OfertaService";
import PromocjeService from "../../../services/PromocjeService";
import {map} from "lodash";
import KoszykService from "../../../services/KoszykService";

interface Props {
    typ?: string,
    promocje?: any
}

interface State {
    // promocje: any
}

class IconPromocja extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            // promocje: []
        }
    }

    componentDidMount() {

    }

    getLabel = (promocja: string) => {
        switch (promocja) {
            case 'C':
                return 'Promocja cenowa';
            case 'P':
                return 'Promocja pakietowa';
            case 'L':
                return 'Promocja lojalnościowa';
            case 'D':
                return 'Promocja dla klienta';
        }
    };

    render() {

        let {promocje, typ} = this.props;

        return (
            KoszykService.isEmpty(typ)?
                promocje !== null &&
                promocje.map((prom: any) => {
                    return <div className={'iconProm promocja-'+prom} title={this.getLabel(prom)}>
                        <span>{prom}</span>
                    </div>
                })
                :
                <div className={'iconProm promocja-'+typ} >
                    <span>{typ}</span>
                </div>

        );
    }
}

export default IconPromocja;
