import React from 'react';
import HeaderComponent from "./HeaderComponent";
import BreadcrumbsComponent from "./BreadcrumbsComponent";
import FooterComponent from "./FooterComponent";
import {Button, Col, Container, Row} from 'react-bootstrap';
import KoszykService from "../../services/KoszykService";
import BanerComponent from "./BanerComponent";
import LoginService from "../../services/LoginService";
import OfertaService from "../../services/OfertaService";
import ApplicationGlobalSettingsService from "../../services/ApplicationGlobalSettingsService";
import {isEmpty} from "lodash";
import UstawieniaService from "../../services/UstawieniaService";
import localStorageTypes from "../../utilities/localStorage/localStorageTypes";
import ZamowieniaService from "../../services/ZamowieniaService";
import {displayNotificationError, displayNotificationInfo} from "../../utilities/notifications/NotificationsManager";
import {exists} from "fs";
import baner from './../../resources/images/baner.png';
import PromocjeService from "../../services/PromocjeService";

interface Props {
    children: any,
    breadcrumbs: any[],
    baner?: boolean,
    isAdminPanel?: boolean,
    shouldKoszykUpdate?: boolean,
    handleShouldKoszykUpdateChange?: () => void;
    anulowanoEdycje?: () => void;
    handleChangeSiec?: (siecObj: any) => void;
    ustawCzyEdycja?: (czyEdycja: boolean, symbol: any, numer: any) => void;
}

class LayoutComponent extends React.Component<Props> {

    state = {
        stanKoszyka: {
            symbol: '',
            numer: ''
        },
        isLoadingStan: false,
        isErrorStan: false,
        // banery: [{
        //     urlImage: baner,
        //     linkTo: '/informacje#aplikacjaMobilna'
        // }],
        // w budowie
        banery: [],
        banerAdminZgoda: ApplicationGlobalSettingsService.getGlobalSettingsConfiguration('pokazBaner'),
        czyEdycja: false,
        edycjaSymbol: "",
        edycjaNumer: 0,
        styleContrast: ((localStorage.getItem('FAMIX_CZYKONTRAST')) !== null)? String(localStorage.getItem('FAMIX_CZYKONTRAST')) : ''
    };

    componentDidMount() {
        this.pobierzPodsumowanieKoszyka();
        // w budowie
        this.pobierzBaneryForUser();
        // this.pobierzBanery();
        this.setState({
            banerAdminZgoda: ApplicationGlobalSettingsService.getGlobalSettingsConfiguration('pokazBaner'),
            styleContrast: ((localStorage.getItem('FAMIX_CZYKONTRAST')) !== null)? localStorage.getItem('FAMIX_CZYKONTRAST') : ''
        }, () => {
            if(isEmpty(this.state.banerAdminZgoda))
            {
                this.pobierzUstawienia();
            }
        });
    }

    pobierzBaneryForUser = () => {
        PromocjeService.getBaneryForUser().then((response) => {
            if(response.data.length > 0){
                this.setState({banery: response.data});
            } else {
                this.setState({
                    banery: [{
                        fileType: 'statyczny',
                        urlImage: baner,
                        linkTo: '/informacje#aplikacjaMobilna'
                    }],
                })
            }
        }).catch((e) => {
            console.log('error');
        })
    }

    pobierzUstawienia = () => {
        UstawieniaService.getUstawienia()
            .then((response: any) => {

                if (response.status < 300) {
                    if(!ApplicationGlobalSettingsService.getGlobalSettingsConfiguration('sort'))
                    {
                        //alert('nie ma')
                        ApplicationGlobalSettingsService.setLocalStorageConfigurationObject(Object.assign({}, response.data, {"sort":[]}))
                    }
                    else
                    {
                        ApplicationGlobalSettingsService.setLocalStorageConfigurationObject(Object.assign({}, response.data, {"sort":ApplicationGlobalSettingsService.getGlobalSettingsConfiguration('sort')}))
                    }

                    this.setState({banerAdminZgoda: response.data.pokazBaner});

                } else {
                    this.setState({banerAdminZgoda: false});
                }
            })
            .catch((error: any) => {
                this.setState({banerAdminZgoda: false});
            })
    }

    pobierzBanery = () => {
        if(!Boolean(LoginService.checkIfBaneryInSession()))
        {
            OfertaService.getBanery()
                .then((response: any) => {
                    if (response.status < 300)
                        this.setState({banery: response.data});
                        LoginService.setBaneryInSession(response.data);
                })
                .catch((error: any) => {
                    console.log('Error banery');
                })
        }
        else
        {
            this.setState({banery: LoginService.checkIfBaneryInSession()});
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
        if (prevProps.shouldKoszykUpdate !== this.props.shouldKoszykUpdate && this.props.shouldKoszykUpdate) {
            this.pobierzPodsumowanieKoszyka()
            //@ts-ignore
            this.props.handleShouldKoszykUpdateChange();
        }
    }

    pobierzPodsumowanieKoszyka = () => {

        this.setState({
            isLoadingStan: true,
            isErrorStan: false,
        });

        KoszykService.getStanKoszyka()
            .then((response: any) => {
                if (response && response.status < 300)
                {
                    this.setState({
                        stanKoszyka: response.data,
                        isLoadingStan: false,
                        isErrorStan: false,
                        czyEdycja: (response.data.numer !== 0),
                        edycjaSymbol: response.data.symbol,
                        edycjaNumer: response.data.numer
                    }, () => {
                        //@ts-ignore
                        if(this.props.ustawCzyEdycja) this.props.ustawCzyEdycja(this.state.czyEdycja, this.state.edycjaSymbol, this.state.edycjaNumer)});

                } else
                    this.setState({
                        isLoadingStan: false,
                        isErrorStan: true,
                    });
            })
            .catch((error: any) => {
                this.setState({
                    isLoadingStan: false,
                    isErrorStan: true,
                })
            })
    };

    onClickContrast = (style: string) => {
        localStorage.setItem('FAMIX_CZYKONTRAST', style);
        this.setState({styleContrast: style});
        if(style === 'contrast'){
            document.body.style.background = 'black';
        } else {
            document.body.style.background = '';
        }
    }

    onClickAnulujEdycje = () => {
        ZamowieniaService.anulujEdycje().then((response) => {
            this.pobierzPodsumowanieKoszyka()
            //@ts-ignore
            if(this.props.handleShouldKoszykUpdateChange !== undefined) this.props.handleShouldKoszykUpdateChange();
            //@ts-ignore
            if(this.props.anulowanoEdycje !== undefined) this.props.anulowanoEdycje();
            displayNotificationInfo('Anulowano edycje zamówienia', '');
        }).catch((e) => {
            displayNotificationError('Błąd anulowania edycji', '');
        })
    }

    render() {
        let {
            children, breadcrumbs, isAdminPanel = false, baner = true, handleChangeSiec = () => {
            }
        } = this.props;
        let {stanKoszyka, isLoadingStan, isErrorStan, banery, banerAdminZgoda, czyEdycja, styleContrast} = this.state;
        return (
            <div className={styleContrast}>
                <div id={'LAYOUT'}>

                    <Container fluid={true}>
                        <HeaderComponent isAdminPanel={isAdminPanel} setStyleContrast={(style: string) => this.onClickContrast(style)} stanKoszykaObject={{stanKoszyka, isLoadingStan, isErrorStan}} handleChangeSiec={
                            (e:any) => {
                                handleChangeSiec(e);
                                this.pobierzPodsumowanieKoszyka()
                            }
                        }/>
                    </Container>

                    {(!isAdminPanel && baner && banerAdminZgoda) &&
                        <Container fluid={true}>
                            <BanerComponent banery={banery}/>
                        </Container>
                    }

                    {(czyEdycja && !isAdminPanel)&&
                    <Container fluid={true}>
                        <div id={'edycjaZam'}>
                            <div className={'promButton'} style={{display: 'inline-block'}}>
                                <Button onClick={this.onClickAnulujEdycje} size={'sm'}>Anuluj edycje</Button>
                            </div>
                            <div className={'komu'}>
                                <i className={'fa fa-cart-arrow-down'}/> &nbsp;Jesteś w trybie edycji zamówienia - {stanKoszyka.symbol}{stanKoszyka.numer}
                            </div>
                        </div>
                    </Container>
                    }

                    <Container fluid={true}>
                        {/*<BreadcrumbsComponent breadcrumbs={breadcrumbs}/>*/}
                    </Container>
                    <main>
                        <Container fluid={true}>
                            {children}
                        </Container>
                    </main>
                    <FooterComponent/>
                </div>
            </div>
        );
    }
}

export default LayoutComponent;
