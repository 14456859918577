import React from 'react';
import LayoutComponent from "../../sharedComponents/layout/LayoutComponent";
import ZamowieniaTable from "./components/ZamowieniaTable";
import ZamowieniaFilterbox from "./components/ZamowieniaFilterbox";
import {isMobileDevice} from "../../utilities/rwd/rwdUtilities";
import PopupWrapper from "../../sharedComponents/popups/PopupWrapper";
import ZamowieniaPozycjePopup from "./components/ZamowieniaPozycjePopup";
import ZamowieniaBrakiPopup from "./components/ZamowieniaBrakiPopup";
import ZamowieniaService from "../../services/ZamowieniaService";
import {_omit} from "../../utilities/lodash/lodashUtilities";
import {getFirstDayOfCurrentMonthAndYear} from "../../utilities/calendar_date/calendar_dateUtilities";
import TopLoading from "../../sharedComponents/loader/TopLoading";
import WydrukiService from "../../services/WydrukiService";
import Shared_WydrukOrExportContainer from "../../sharedComponents/_reusable/wydrukOrExport/Shared_WydrukOrExportContainer";

interface MatchParams {
    path: string;
}

export interface RouteComponentProps<MatchParams> {
    match: any;
}

interface Props extends RouteComponentProps<MatchParams> {
}

interface State {
    zamowienia: any,
    currentView: boolean, // table - true, grid - false
    currentZamowienie: any,

    currentPozycje: any,
    currentBraki: any,

    isLoadingPozycje: boolean,
    isErrorPozycje: boolean,

    showPozycje: boolean,
    showBraki: boolean,
    showExporty: boolean,

    params: any,

    page: number,
    size: number,
    pageableObject: any,
    hasMoreForGrid: boolean,
    canLoadMore: boolean,
    isRequestLoading: boolean,
    isRequestError: boolean,

    metodyExportow: any,
    sort: object[],
    loading: boolean
}

class ZamowieniaModule extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        let tableSize: any = (Boolean(localStorage.getItem('FAMIX_CURRENT_TABLESIZE_ZAMOWIENIA'))) ? localStorage.getItem('FAMIX_CURRENT_TABLESIZE_ZAMOWIENIA') : 10

        this.state = {
            zamowienia: [],
            currentView: true, // table - true, grid - false
            currentZamowienie: null,

            currentPozycje: [],
            currentBraki: [],

            isLoadingPozycje: false,
            isErrorPozycje: false,

            showPozycje: false,
            showBraki: false,
            showExporty: false,

            params: {
                // "czyPokazacZamowieniaPolaczone": true,
                "dataZamowien": "",
                "symbolZam": "",
                "statusPo": null
            },

            page: 0,
            size: tableSize !== undefined ? parseInt(tableSize) : 12,
            pageableObject: {
                currentPage: 0,
                currentSize: 0,
                totalElements: 0
            },
            hasMoreForGrid: true,
            canLoadMore: true,
            isRequestLoading: true,
            isRequestError: false,

            metodyExportow: [],
            sort: [{kierunek: "desc", kolumna: "data_zam"}, {kierunek: "desc", kolumna: "numer"}],
            loading: false
        };
    }

    componentDidMount() {

        if (isMobileDevice())
            window.scrollTo(0, 0);

        this.pobierzMozliwosciExportow()

        this.setState({
            currentView: true, //isMobileDevice() ? false : ApplicationGlobalSettingsService.getGlobalSettingsModuleView('Zamowien'),
            params: {
                "czyPokazacZamowieniaPolaczone": true,
                "dataZamowien": getFirstDayOfCurrentMonthAndYear(),
                "symbolZam": "",
                "statusPo": null
            },
        }, () => this.pobierzZamowienia());
    }

    generateSortString = (): string => {
        let sortowanie = this.state.sort;
        let sortString = '';

        for (let i=0; sortowanie.length > i; i++)
        {
            // @ts-ignore
            sortString = sortString + '&sort=' + sortowanie[i].kolumna + ',' + sortowanie[i].kierunek;
        }

        return sortString;

    }

    /* get functions  */

    pobierzZamowienia = (page = this.state.page, size = this.state.size, params = this.state.params) => {
        this.setState({
            // isRequestLoading: true,
            isRequestError: false,
        });

        ZamowieniaService.getZamowienia(page, size, params, this.generateSortString())
            .then((response: any) => {
                if (response && response.status < 300) {
                    // console.log(response)
                    this.setState({
                        isRequestLoading: false,
                        isRequestError: false,
                        loading: false,
                        zamowienia: response.data.content,
                        pageableObject: Object.assign({}, _omit(response.data, "content"), {currentPage: page}, {currentSize: size}),
                        hasMoreForGrid: !(response.data.totalElements === response.data.content.length),
                        canLoadMore: true
                    });
                } else {
                    this.setState({
                        isRequestLoading: false,
                        isRequestError: true,
                        loading: false,
                        hasMoreForGrid: false,
                        canLoadMore: false
                    });
                }
            })
            .catch((error: any) => {
                this.setState({
                    isRequestLoading: false,
                    isRequestError: true,
                    loading: false,
                    hasMoreForGrid: false,
                    canLoadMore: false
                });
            })
    };

    pobierzMozliwosciExportow = () => {
        WydrukiService.getWydrukiZamowienia().then((response: any) => {
            if (response && response.status < 300) {
                this.setState({metodyExportow: response.data}, () => this.state.metodyExportow.push({recno: 3, formaty: "csv;", opisFormatu: "Zamówienie CSV", faktCzyZamow: "", repFormat: "", urlFormat: "csv"}, {recno: 4, formaty: "poIndeksie;csv", opisFormatu: "Po indeksie", faktCzyZamow: "", repFormat: "", urlFormat: "csv"}) );
            }
        })
    };

    /* table functions  */

    onChangePageInTable = (page: number, size: number) => {
        this.setState({
            page: page - 1,
            loading: true,
            size: size
        }, () => this.pobierzZamowienia())
    };

    onChangeSizeInTable = (size: number) => {
        localStorage.setItem('FAMIX_CURRENT_TABLESIZE_ZAMOWIENIA', String(size))
        this.setState({
            loading: true,
            size
        }, () => this.pobierzZamowienia())
    };

    /* grid functions  */


    // loadMoreElementsForGrid = (e: any) => {
    //
    //     if (this.state.canLoadMore) {
    //         this.setState({canLoadMore: false, isRequestLoading: true})
    //
    //         let {zamowienia} = this.state;
    //
    //         this.setState((prevState: any) => ({page: prevState.page + 1}), () => {
    //             ZamowieniaService.getZamowienia(this.state.page, 12, this.state.params)
    //                 .then(response => {
    //                     if (response.status < 300) {
    //
    //                         this.setState({
    //                             isRequestLoading: false,
    //                             isRequestError: false,
    //                             pageableObject: Object.assign({}, _omit(response.data, "content"), {currentPage: this.state.page}, {currentSize: 12}),
    //                             canLoadMore: true,
    //                             hasMoreForGrid: (response.data.totalPages > this.state.page)
    //                         });
    //
    //                         response.data.content.forEach((item: any) => {
    //                             //@ts-ignore
    //                             zamowienia.push(item)
    //                         });
    //
    //                         this.setState({zamowienia});
    //
    //                     } else {
    //                         this.setState({
    //                             canLoadMore: true,
    //                             isRequestLoading: false,
    //                             isRequestError: true,
    //                             hasMoreForGrid: false
    //                         });
    //                     }
    //                 })
    //                 .catch(error => {
    //                     this.setState({
    //                         canLoadMore: true,
    //                         isRequestLoading: false,
    //                         isRequestError: true,
    //                         hasMoreForGrid: false
    //                     });
    //                 })
    //         })
    //     }
    // };

    /* filterbox functions  */

    onClickFiltruj = (params: any) => {
        this.setState({params: params, page: 0, loading: true}, () => this.pobierzZamowienia())
    };

    onChangeSortowanie = (sort: any) => {

        this.setState({sort: sort, page: 0, loading: true}, () => this.pobierzZamowienia());

    };

    onClickPokazPozycje = (zamowienie: any) => {
        this.setState({
            currentZamowienie: zamowienie,
            showPozycje: true,
            isLoadingPozycje: true,
            isErrorPozycje: false,
        });

        ZamowieniaService.getPozycjeZamowienia(0, 1000, {
            "numer": zamowienie.numer,
            "symbol": zamowienie.symbol
        })
            .then((response: any) => {
                if (response && response.status < 300) {
                    this.setState({
                        currentPozycje: response.data.content,
                        isLoadingPozycje: false,
                        isErrorPozycje: false,
                    });
                } else {
                    this.setState({
                        currentPozycje: [],
                        isLoadingPozycje: false,
                        isErrorPozycje: true,
                    });
                }
            })
            .catch((error: any) => {
                this.setState({
                    currentPozycje: [],
                    isLoadingPozycje: false,
                    isErrorPozycje: true,
                });
            })
    };

    onClickZamknijPozycjeLubBraki = () => {
        this.setState({
            currentZamowienie: null,
            currentPozycje: [],
            showPozycje: false,
            showBraki: false,
            showExporty: false,
        });
    };

    onClickPokazBraki = (zamowienie: any) => {
        this.setState({
            currentZamowienie: zamowienie,
            showBraki: true,
            isLoadingPozycje: true,
            isErrorPozycje: false,
        });

        ZamowieniaService.getBrakiZamowienia({
            "numer": zamowienie.numer,
            "symbol": zamowienie.symbol,
            "status": zamowienie.statPo
        })
            .then((response: any) => {
                if (response && response.status < 300) {
                    this.setState({
                        currentBraki: response.data.content,
                        isLoadingPozycje: false,
                        isErrorPozycje: false,
                    });
                } else {
                    this.setState({
                        currentBraki: [],
                        isLoadingPozycje: false,
                        isErrorPozycje: true,
                    });
                }
            })
            .catch((error: any) => {
                this.setState({
                    currentBraki: [],
                    isLoadingPozycje: false,
                    isErrorPozycje: true,
                });
            })
    };

    onClickPokazMenuExportow = (zamowienie: any) => {
        this.setState({
            currentZamowienie: zamowienie,
            showExporty: true
        });
    };

    render() {

        const {zamowienia, currentZamowienie, showPozycje, showBraki, showExporty, metodyExportow, isRequestLoading, isRequestError, pageableObject, params, loading} = this.state;
        const breadcrumbs = [{name: 'Zamówienia', url: '/zamowienia'}];

        return (
            <LayoutComponent breadcrumbs={breadcrumbs}
                             handleChangeSiec={()=>{
                                 this.setState({loading: true, isRequestLoading: true});
                                 this.pobierzZamowienia()
                             }}
            >
                <div id={'ZAMOWIENIA_MODULE'}>
                    <ZamowieniaFilterbox handleClickFiltruj={this.onClickFiltruj}/>
                    {/*<TopTableGridSwitcher currentView={currentView} quantity={*/}
                    {/*    // @ts-ignore*/}
                    {/*    Boolean(pageableObject) ? pageableObject.totalElements : 0*/}
                    {/*}*/}
                    {/*                      handleChangeView={(currentView: boolean) => {this.setState({currentView});*/}
                    {/*                          let data = ApplicationGlobalSettingsService.getLocalStorageConfigurationObject();*/}
                    {/*                          //@ts-ignore*/}
                    {/*                          data['tabelaDomyslnymWidokiemZamowien'] = currentView;*/}

                    {/*                          ApplicationGlobalSettingsService.setLocalStorageConfigurationObject(data);*/}
                    {/*                      }}/>*/}
                    {/*{*/}
                    {/*    currentView ?*/}
                    {/*        <TopLoading isLoading={isRequestLoading} isError={isRequestError}>*/}
                    {/*            <ZamowieniaTable data={zamowienia}*/}
                    {/*                             handleClickPokazBraki={this.onClickPokazBraki}*/}
                    {/*                             pageableObject={pageableObject}*/}
                    {/*                             handleChangePage={this.onChangePageInTable}*/}
                    {/*                             handleChangeSizePerPage={this.onChangeSizeInTable}*/}
                    {/*                             handleClickPokazMenuExportow={this.onClickPokazMenuExportow}*/}
                    {/*                             parametryPdf={params}*/}
                    {/*                             odswiez={this.pobierzZamowienia}*/}
                    {/*            />*/}
                    {/*        </TopLoading>*/}
                    {/*        :*/}
                    {/*        <ZamowieniaGrid zamowienia={zamowienia} handleClickPokazPozycje={this.onClickPokazPozycje} handleClickPokazBraki={this.onClickPokazBraki}*/}
                    {/*                        isLoading={isRequestLoading} hasMore={hasMoreForGrid} handleLoadMoreForGrid={this.loadMoreElementsForGrid}*/}
                    {/*                        handleClickPokazMenuExportow={this.onClickPokazMenuExportow}*/}
                    {/*        />*/}
                    {/*}*/}

                    <TopLoading isLoading={isRequestLoading} isError={isRequestError}>
                        <ZamowieniaTable data={zamowienia}
                                         handleClickPokazBraki={this.onClickPokazBraki}
                                         pageableObject={pageableObject}
                                         handleChangePage={this.onChangePageInTable}
                                         handleChangeSizePerPage={this.onChangeSizeInTable}
                                         handleClickPokazMenuExportow={this.onClickPokazMenuExportow}
                                         parametryPdf={params}
                                         odswiez={this.pobierzZamowienia}
                                         handleChangeSortowanie={this.onChangeSortowanie}
                                         sortProps={this.state.sort}
                                         loading={loading}
                        />
                    </TopLoading>

                </div>
                {
                    (showPozycje && Boolean(currentZamowienie)) &&
                    //@ts-ignore
                    <PopupWrapper header={`Pozycje zamówienia ${currentZamowienie.symbol}/${currentZamowienie.numer}`} onClose={this.onClickZamknijPozycjeLubBraki}>
                        <TopLoading isLoading={this.state.isLoadingPozycje} isError={this.state.isErrorPozycje}>
                            <ZamowieniaPozycjePopup pozycje={this.state.currentPozycje}/>
                        </TopLoading>
                    </PopupWrapper>
                }
                {
                    (showBraki && Boolean(currentZamowienie)) &&
                    //@ts-ignore
                    <PopupWrapper header={`Braki zamówienia ${currentZamowienie.symbol}/${currentZamowienie.numer}`} onClose={this.onClickZamknijPozycjeLubBraki}>
                        <TopLoading isLoading={this.state.isLoadingPozycje} isError={this.state.isErrorPozycje}>
                            <ZamowieniaBrakiPopup zamowienie={currentZamowienie} braki={this.state.currentBraki}/>
                        </TopLoading>
                    </PopupWrapper>
                }
                {
                    (showExporty && Boolean(currentZamowienie)) &&
                    //@ts-ignore
                    <PopupWrapper header={`Menu exportów zamówienia ${currentZamowienie.symbol}/${currentZamowienie.numer}`} onClose={this.onClickZamknijPozycjeLubBraki} withoutOverflowY={true}>
                        <Shared_WydrukOrExportContainer typy={metodyExportow} handleClickZamknij={this.onClickZamknijPozycjeLubBraki} zamowienie={currentZamowienie}/>
                    </PopupWrapper>
                }
            </LayoutComponent>
        );
    }
}

export default ZamowieniaModule;
