import React, {Component} from 'react';
import LayoutComponent from "../../../sharedComponents/layout/LayoutComponent";
import {API_getPromocje} from "../../../_static_data/response";
import AdminPromocjeTable from "./components/AdminPromocjeTable";
import AdminPromocjeForm from "./components/AdminPromocjeForm";
import {Button} from "react-bootstrap";
import {IconAdd} from "../../../sharedComponents/icons/FontAwesome";
import PromocjaEditorContainer from './editorComponents/PromocjaEditorContainer';

class AdminPromocjeModule extends Component {

    state = {
        showForm: false,
    };

    render() {

        const promocje = API_getPromocje();
        let {showForm} = this.state;


        return (
            <LayoutComponent breadcrumbs={
                [{url: "/admin", name: 'PA'}, {url: "/promocje", name: 'Promocje'}]
            } isAdminPanel={true}>
                <div id={'ADMIN_MODULE'}>
                    <div id={'PROMOCJE_MODULE'}>
                        {
                            showForm ?
                                <AdminPromocjeForm/>
                                :
                                <div>
                                    <div className={'float-right'}>
                                        <Button variant={'success'}
                                                size={'sm'}
                                                onClick={() =>
                                                    this.setState({
                                                        showForm: true,
                                                    })
                                                }><IconAdd/> Dodaj</Button>
                                    </div>
                                    <div className={'clearfix'}></div>
                                </div>
                        }
                        <AdminPromocjeTable promocje={promocje}/>
                    </div>
                    <div>
                        <PromocjaEditorContainer />
                    </div>
                </div>
            </LayoutComponent>
        );
    }
}

export default AdminPromocjeModule;