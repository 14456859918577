import React, {Component} from 'react';
import {Button, Col, Form, FormGroup, Row} from "react-bootstrap";
import AddFormMargin from "../../../sharedComponents/formComponents/AddFormMargin";
import {IconFilter} from "../../../sharedComponents/icons/FontAwesome";

interface Props{
    handleClickFiltruj: (params: any) => void;
    czyPoTerminie: boolean
}

class RozrachunkiFilterbox extends Component<Props> {

    state = {
        czyTylkoPoTerminie: false
     //   dateStart: "2020-03-01",
    //    dateEnd: "2020-03-05",
    };

    componentDidMount() {
        this.setState({czyTylkoPoTerminie: this.props.czyPoTerminie});
    }

    /*    onChangeDateStart = (dateStart: string) => {
            this.setState({dateStart})
        };
        onChangeDateEnd = (dateEnd: string) => {
            this.setState({dateEnd})
        };*/

    onClickFiltruj = () =>{
      this.props.handleClickFiltruj({
          czyTylkoPoTerminie: this.state.czyTylkoPoTerminie
      })
    };

    render() {

        let {czyTylkoPoTerminie} = this.state;

        return (
            <div id={"RozrachunkiFilterbox"}>
                <Form>
                    <Row>
                        {/*<Col md={6} lg={5} xl={4}>
                            <FormGroup>
                                <Form.Label>Rozrachunki z okresu: <Req/></Form.Label>
                                <Daterange startValue={dateStart}
                                           endValue={dateEnd}
                                           disabledStart={false}
                                           disabledEnd={false}
                                           onChangeValueStart={this.onChangeDateStart}
                                           onChangeValueEnd={this.onChangeDateEnd}
                                />
                            </FormGroup>
                        </Col>*/}
                        <Col md={6} lg={4} xl={3}>
                                <AddFormMargin hideForMobileDevices767={true}/>
                                <Form.Check inline={true} type="checkbox" label="Pokaż tylko rozrachunki po terminie"
                                            className={'text-danger'} checked={czyTylkoPoTerminie}
                                            id={'TylkoPoTerminieCheckbox'}
                                            onClick={()=>{this.setState((prevState: any)=>({czyTylkoPoTerminie: !prevState.czyTylkoPoTerminie}),()=>this.onClickFiltruj())}}
                                />
                        </Col>
                       {/* <Col md={6} lg={2} xl={4}>
                            <FormGroup>
                                <AddFormMargin hideForMobileDevices991={true}/>
                                <Button type={'button'} variant={'primary'} size={'sm'} onClick={this.onClickFiltruj}>
                                    <IconFilter/> Filtruj
                                </Button>
                            </FormGroup>
                        </Col>*/}
                    </Row>
                </Form>
            </div>
        );
    }
}

export default RozrachunkiFilterbox;