import React, {Component} from 'react';
import defaultimg from '../../../resources/images/nopicture.png';

//@ts-ignore
import {LazyLoadImage} from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

interface Props {
    item: any
}

class Shared_ProductImage extends Component<Props> {

    state = {
        error: false
    };

    render() {

        let {item}: any = this.props;
        let {error} = this.state;

        return (
            <LazyLoadImage
                src={
                    error ?
                        defaultimg
                        :
                        item.imagePath
                        // item.imagePath.replace('.JPG', '.jpg')
                }
                alt={'Produkt ' + item.nazwa} effect={'blur'}
                onError={() => {
                    this.setState({error: true})
                }}
            />
        );
    }
}

export default Shared_ProductImage;
