import React from 'react';
import {Col, Form, FormControl, FormGroup, Row} from "react-bootstrap";
import Select from "react-select";
import {SmallerSelectStyles} from "../../../../utilities/forms/jsStyles";
// @ts-ignore
import InputNumber from 'rc-input-number';
import {formatMoney} from "../../../../utilities/formatters/formatMoney";

interface Props {
    pozycja: any,
    handleChangeReklamacjaPozycji: any,
    jakaRekl: number,
    powody: any,
    reklCalej: boolean
}

class FakturaReklamacjaPozycjaItem extends React.Component<Props> {

    state = {
        czyUwagi: true,
        czyReklamacja: this.props.pozycja.czyReklamacja,
        pozycjaState: this.props.pozycja
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any) {
        if(prevProps.reklCalej !== this.props.reklCalej){
            // console.log(prevProps.reklCalej, this.props.reklCalej)
            this.setState({czyReklamacja: this.props.reklCalej, czyUwagi: false});
        }
    }

    onChangeCheckbox = (indeks: string, e: any) =>{
        this.onChangeUwagi(indeks, '');
        this.props.handleChangeReklamacjaPozycji(indeks, 'powodRekl', null);
        this.setState({czyUwagi: false});
        this.props.handleChangeReklamacjaPozycji(indeks, 'czyReklamacja', e.target.checked)
    };

    onClickSelectPowod = (indeks: string, e: any) => {
        this.props.handleChangeReklamacjaPozycji(indeks, 'powodRekl', e.value)
    };

    onChangeIlosc = (indeks: string, e: any) => {
        this.props.handleChangeReklamacjaPozycji(indeks, 'ilosc', e)
    };

    onChangeUwagi = (indeks: string, e: any) => {
        this.props.handleChangeReklamacjaPozycji(indeks, 'uwagiKlient', e)
    };

    onChangeCzyUwagi = (indeks: string) => {
        this.onChangeUwagi(indeks, '');
        this.setState({czyUwagi: !this.state.czyUwagi});
    }

    render(){
        let {poz, indeks, producent, nazwa, powodRekl, ilosc, jm, uwagiKlient, cena} = this.props.pozycja;
        let {powody} = this.props;
        let {czyReklamacja} = this.state;

        return (
            <div className={'FakturaReklamacjaPozycjaItem' + ((czyReklamacja)? ' doReklamacji' : '')}>
                <Row className={'item'}>
                    <Col sm={2} md={2} lg={1} xl={1} style={{maxWidth: '80px', marginTop: 'auto', marginBottom: 'auto', marginLeft: '5px'}}>
                        <FormGroup>
                            # {poz}
                        </FormGroup>
                    </Col>
                    <Col sm={7} md={7} lg={4} xl={4} style={{paddingLeft: '0', maxWidth: '400px'}}>
                        <FormGroup>
                            <h6 style={{color: '#60499b'}}>
                                {indeks}
                            </h6>
                            <h6>
                                {producent}, {nazwa}
                            </h6>
                        </FormGroup>
                    </Col>
                    <Col sm={1} md={1} lg={1} xl={1} style={{textAlign: 'right', marginTop: 'auto', marginBottom: 'auto', paddingLeft: '0', maxWidth: '90px'}}>
                        <h6 style={{margin: '0'}}>{ilosc} {jm}</h6>
                    </Col>
                    {
                        (this.props.jakaRekl === 2) &&
                        <Col sm={1} md={1} lg={1} xl={1} style={{textAlign: 'right', marginTop: 'auto', marginBottom: 'auto', maxWidth: '70px', paddingLeft: '0'}}>
                            <h6 style={{margin: '0'}}>{formatMoney(cena)}</h6>
                        </Col>
                    }
                    <Col sm={3} md={3} lg={3} xl={2} style={{maxWidth: '135px', paddingRight: '0'}}>
                        <FormGroup>
                            <Form.Check id={'czyReklamacjaCheckbox'+indeks} inline={true} checked={czyReklamacja} type="checkbox" label="Do reklamacji" onClick={(e:any)=> {this.setState({czyReklamacja: !this.state.czyReklamacja}); this.onChangeCheckbox(indeks,e)}}/>
                        </FormGroup>
                    </Col>
                    <Col md={12} lg={4} xl={4} style={{marginTop: 'auto', padding: '0'}}>
                        {
                            //@ts-ignore
                            // (document.getElementById('czyReklamacjaCheckbox'+indeks) && document.getElementById('czyReklamacjaCheckbox'+indeks).checked) &&
                            (czyReklamacja && !this.props.reklCalej) &&
                            <Row>
                                {(this.props.jakaRekl === 1) &&
                                <Col lg={3} xl={3} className={'inputIlosc'}>
                                    <FormGroup>
                                        {/*<input type={'number'} max={ilosc} min={1} placeholder={'Podaj ilość'} onChange={(e: any)=> this.onChangeIlosc(indeks, e.target.value)}/>*/}
                                        <InputNumber
                                            // value={(nowa !== null)? nowa : amount}
                                            defaultValue={ilosc}
                                            onChange={(e: any)=> this.onChangeIlosc(indeks, e)}
                                            // disabled={(alwaysDostepne) ? false : !item.dostepne}
                                            precision={(jm.indexOf('KG')) > -1 ? 2 : 0}
                                            step={(jm.indexOf('KG')) > -1 ? 0.1 : 1}
                                            min={1} max={ilosc}
                                            // onPressEnter={this.onPressEnter}
                                            // id={'input-' + item.indeks}
                                            // onBlur={() => this.onBlurInput(inline, showButton, item)}
                                            // onClick={this.onClickInput}
                                        />
                                    </FormGroup>
                                </Col>
                                }
                                <Col lg={8} xl={6} className={'selectPowod'}>
                                    <FormGroup>
                                        <Select
                                            value={powodRekl}
                                            options={powody}
                                            onChange={(e:any)=> this.onClickSelectPowod(indeks,e)}
                                            placeholder={'Wybierz powód'}
                                            styles={SmallerSelectStyles}
                                            isSearchable={false}
                                        />
                                    </FormGroup>
                                </Col>
                                {/*<Col xl={2}>*/}
                                {/*    <FormControl size={'sm'} placeholder={'Uwagi'} value={uwagiKlient} onChange={(e: any) => this.onChangeUwagi(indeks, e)} />*/}
                                {/*</Col>*/}

                                {/*<Col xl={3} style={{padding: '0'}}>*/}
                                {/*    <FormGroup>*/}
                                {/*        <Form.Check id={'czyReklamacjaczyUwagi'+indeks} inline={true} checked={this.state.czyUwagi} type="checkbox" label="Dodaj uwagi" onClick={() => this.onChangeCzyUwagi(indeks)}/>*/}
                                {/*    </FormGroup>*/}
                                {/*</Col>*/}

                            </Row>

                        }
                    </Col>

                </Row>
                {(czyReklamacja && !this.props.reklCalej) &&
                <Row style={{padding: '5px'}}>
                    <Col>
                        <FormControl size={'sm'} placeholder={'Uwagi'} value={uwagiKlient} onChange={(e: any) => this.onChangeUwagi(indeks, e.target.value)} />
                    </Col>
                </Row>
                }

            </div>
        );
    }

}

export default FakturaReklamacjaPozycjaItem;