import {displayNotificationInfo} from "../notifications/NotificationsManager";

export const famix_zaokraglijIloscDlaJM1 = (jm1: string, ilosc: any) => {
    return (jm1.indexOf('KG')>-1) ? parseFloat(ilosc).toFixed(2) : Math.round(ilosc)
};

export const famix_przeliczIloscDlaJednostekMiary = (jednostkaObecna: "jednostka" | "opakowanie" | "karton", jednostkaDocelowa: "jednostka" | "opakowanie" | "karton", ilosc: number = 1, item: any) => {

    console.log(jednostkaObecna, jednostkaDocelowa, ilosc)

    if (jednostkaObecna === jednostkaDocelowa) return ilosc;

    if (jednostkaObecna === "jednostka") {
        if (jednostkaDocelowa === 'opakowanie') {

            if (ilosc % item.ilJm2 === 0) {
                // rowne dzielenie
                return ilosc / item.ilJm2
            } else {
                displayNotificationInfo('Nastąpiło zaokrąglenie ilości', "Nastąpiło zaokrąglenie ilości podczas konwersji z jednostek do opakowań zbiorczych")
                let wynik = ilosc / item.ilJm2;
                return (wynik < 1) ? 1 : Math.floor(wynik)
            }
        }

        if (jednostkaDocelowa === 'karton') {
            if (ilosc % item.ilJm3 === 0) {
                // rowne dzielenie
                return ilosc / item.ilJm3
            } else {
                displayNotificationInfo('Nastąpiło zaokrąglenie ilości', "Nastąpiło zaokrąglenie ilości podczas konwersji z jednostek do kartonów")
                let wynik = ilosc / item.ilJm3;
                return (wynik < 1) ? 1 : Math.floor(wynik)
            }
        }
    }

    if (jednostkaObecna === "opakowanie") {
        if (jednostkaDocelowa === 'jednostka') {
            return famix_zaokraglijIloscDlaJM1(item.jm, item.ilJm2 * ilosc)
        }
        if (jednostkaDocelowa === 'karton') {
            let iloscOpakowanWKartonie = (item.ilJm3 / item.ilJm2)
            console.log(iloscOpakowanWKartonie)
            if (ilosc % iloscOpakowanWKartonie === 0) {
                // rowne dzielenie
                return ilosc / iloscOpakowanWKartonie
            } else {
                displayNotificationInfo('Nastąpiło zaokrąglenie ilości', "Nastąpiło zaokrąglenie ilości podczas konwersji z opakowań do kartonów")
                let wynik = ilosc / iloscOpakowanWKartonie;
                return (wynik < 1) ? 1 : Math.floor(wynik)
            }
        }
    }

    if (jednostkaObecna === "karton") {
        if (jednostkaDocelowa === 'jednostka') {
            return famix_zaokraglijIloscDlaJM1(item.jm, item.ilJm3 * ilosc)
        }
        if (jednostkaDocelowa === 'opakowanie') {
            return (ilosc * item.ilJm3) / item.ilJm2
        }
    }

};

export const famix_replace = (text: string, znaki: string[], na: string) => {
    let poprawiony = text;

    znaki.forEach((z) => {
        poprawiony = poprawiony.replace(z, na)
    })

    return poprawiony
};