import React, {Component} from 'react';
import MiddleCenterCardWrapper from "../../../sharedComponents/wrappers/MiddleCenterCardWrapper";
import {Button, Form, FormControl, FormGroup} from "react-bootstrap";
import {IconEnvelope, IconSend} from "../../../sharedComponents/icons/FontAwesome";
import {displayNotificationError, displayNotificationInfo} from "../../../utilities/notifications/NotificationsManager";
import PublicKlientService from "../../../services/PublicKlientService";
import TopLoading from "../../../sharedComponents/loader/TopLoading";
import {Link} from "react-router-dom";

class ResetHaslaModule extends Component {

    state = {
        login: '',
        czyWyslano: false,
        isLoading: false,
        isError: false,
    };

    onChangeInput = (e: any) => {
        this.setState({login: e.target.value});
    };

    onClickResetuj = () => {
        if (Boolean(this.state.login)) {

            this.setState({
                czyWyslano: true,
                isLoading: true,
                isError: false,
            });

            PublicKlientService.getResetujHaslo(this.state.login)
                .then((response: any) => {
                    if (response && response.status < 300) {
                        this.setState({
                            isLoading: false,
                            isError: false,
                        });
                        displayNotificationInfo('Wysłano wiadomość email oraz wiadomość sms', '')
                    } else {
                        this.setState({
                            isLoading: false,
                            isError: true,
                        });
                        displayNotificationError('Wystąpił błąd podczas próby wysyłki danych', '')
                    }
                })
                .catch((error: any) => {
                    this.setState({
                        isLoading: false,
                        isError: true,
                    });
                    displayNotificationError('Wystąpił błąd podczas próby wysyłki danych', error.message)
                })
        } else {
            displayNotificationError('Proszę uzupełnić pole login', '')
        }
    };

    render() {

        let {czyWyslano, isLoading, isError} = this.state;

        return (
            <div id={'RESET_HASLA_MODULE'}>
                <MiddleCenterCardWrapper>
                    {
                        czyWyslano ?
                            <div id={'po-wyslaniu'}>
                                <TopLoading isLoading={isLoading} isError={isError} loadingText={'Trwa przetwarzanie...'}
                                            errorMessage={'Wystąpił błąd podczas przetwarzania żądania. Proszę spróbować ponownie'}>
                                    <div id={'success'}>
                                        <h4>
                                            <IconEnvelope/> <span>Pomyślnie wysłano dane</span>
                                        </h4>
                                        <h6>
                                            Wysłano wiadomość email zawierającą link do zmiany hasła
                                        </h6>
                                    </div>
                                </TopLoading>
                            </div>
                            :
                            <Form>
                                <FormGroup>
                                    <Form.Label>Login:</Form.Label>
                                    <FormControl
                                        onChange={this.onChangeInput}
                                        autoFocus
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Button variant={'primary'}
                                            block={true}
                                            onClick={this.onClickResetuj}
                                    >
                                        <IconSend/> Resetuj hasło
                                    </Button>
                                </FormGroup>
                                <FormGroup>
                                    <h6>
                                        Aby zresetować hasło, należy podać login konta - na powiązany adres email zostanie przesłana wiadomość zawierająca dane
                                        potrzebne do operacji zmiany hasła.
                                    </h6>
                                </FormGroup>
                            </Form>
                    }
                    {
                        !isLoading &&
                        <Link to={'/'}>
                            Powrót do logowania
                        </Link>
                    }
                </MiddleCenterCardWrapper>
            </div>
        );
    }
}

export default ResetHaslaModule;