import {HOST_API} from "../ApplicationConfig";
import axios from 'axios';

const HOST = HOST_API + 'zamowienia/';

export default class ZamowieniaService {

    static getZamowienia = (page: number, size: number, params: any, sort: string = '') => {
        return axios.request({
            method: 'post',
            url: `${HOST}getZamowieniaNew?page=${page}&size=${size}&paged=true${sort}`,
            data: JSON.stringify(params)
        })
    };

    static getPozycjeZamowienia = (page: number, size: number, params: any) => {
        return axios.request({
            method: 'post',
            url: `${HOST}getPozycjeZamowieniaNew?page=${page}&size=${size}&paged=true`, //&sort=m.indeks,asc`,
            data: JSON.stringify(params)
        })
    };

    static getPozycjeZamowienia2 = (page: number, size: number, params: any, sort: string = '') => {
        return axios.request({
            method: 'post',
            url: `${HOST}getPozycjeZamowieniaPageable?page=${page}&size=${size}&paged=true${sort}`,
            data: JSON.stringify(params)
        })
    };

    static getBrakiZamowienia = (params: any) => {
        return axios.request({
            method: 'post',
            url: `${HOST}getBrakiZamowienia`,
            data: JSON.stringify(params)
        })
    };

    static getBrakiZamowieniaPlik = (symbol: any, numer: any, status: any) => {
        return axios.request({
            method: 'get',
            url: `${HOST}getBrakiZamowieniaPlik?numer=${numer}&status=${status}&symbol=${symbol}`
        })
    };

    static getPdfZamowienia = (parametry: any) => {
        return axios.request({
            method: 'post',
            url: `${HOST}exportZamowieniaToPDF`,
            data: JSON.stringify(parametry),
            responseType:"blob"
        })
    };

    static getZamowienieDoEdycji = (sym: any, nr: any) => {
        return axios.request({
            method: 'post',
            url: `${HOST}getZamowienieDoEdycji`,
            data: {
                numer: nr,
                symbol: sym,
                kanal: "W"
            }
        })
    };

    static anulujZamowienie = (sym: any, nr: any) => {
        return axios.request({
            method: 'post',
            url: `${HOST}anulujZamowienie`,
            data: {
                numer: nr,
                symbol: sym,
                kanal: "W"
            }
        })
    };

    static anulujEdycje = () => {
        return axios.request({
            method: 'get',
            url: `${HOST}anulujEdycje`
        })
    };

}
