import React, {Component} from 'react';
import {IconExcel, IconExportPdf, IconFileCSV, IconInfo, IconSettings} from "../icons/FontAwesome";
import PopupWrapper from "../popups/PopupWrapper";
import {Col, Form, FormGroup, Row} from "react-bootstrap";
import Select from "react-select";
import {SmallerSelectStyles} from "../../utilities/forms/jsStyles";
//@ts-ignore
import {ExportToCsv} from 'export-to-csv';
import {formatMoney} from "../../utilities/formatters/formatMoney";
import {TextAlert} from "../typographyComponents/TextAlert/TextAlert";
import {isMobileDevice} from "../../utilities/rwd/rwdUtilities";
import ZamowieniaService from "../../services/ZamowieniaService";
import {
    displayNotificationError,
    displayNotificationInfo,
    displayNotificationSuccess, displayNotificationWarning
} from "../../utilities/notifications/NotificationsManager";
import {saveAnyFileAndDownload} from "../../utilities/files/fileOperations";
import KoszykService from "../../services/KoszykService";

interface Props {

}

class TopExportKoszyk extends Component<Props> {

    onClickExport = () => {

        KoszykService.getExcelKoszyk().then((response)=>{
            if(response.status){

                displayNotificationSuccess('Pomyślnie zaciagnięto dane', 'Plik pobierze się automatycznie');

                // @ts-ignore
                saveAnyFileAndDownload(response.data, 'Famix-koszyk', 'xlsx');

            }
        }).catch(e => {
            displayNotificationError('Wystąpił błąd podczas próby exportu do PDF', '');
        })

    };

    onClickExportPdf = () => {

        KoszykService.getPdfKoszyk().then((response)=>{
            if(response.status){

                displayNotificationSuccess('Pomyślnie zaciagnięto dane', 'Plik pobierze się automatycznie');

                // @ts-ignore
                saveAnyFileAndDownload(response.data, 'Famix-koszyk', 'pdf');
                // saveAnyFileAndDownload(response.data, 'Zamowienia'+JSON.stringify(parametry.dataZamowien), 'pdf');

            }
        }).catch(e => {
            displayNotificationError('Wystąpił błąd podczas próby exportu do PDF', '');
        })

    }

    render() {
        return (
            <>
                <div className={'TopExport'}>
                    <span onClick={this.onClickExportPdf} title={'Kliknij, aby wyeksportować widoczne dane do pliku PDF'} style={{marginLeft: '3px'}}>
                        <IconExportPdf/>
                    </span>
                    <span onClick={this.onClickExport} title={'Kliknij, aby wyeksportować widoczne dane do pliku EXCEL'} style={{marginRight: '3px'}}>
                        <IconExcel/>
                    </span>
                </div>
            </>
        );
    }
}

export default TopExportKoszyk;
