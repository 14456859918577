import React, {Component} from 'react';
import LayoutComponent from "../../sharedComponents/layout/LayoutComponent";
import LoginService from "../../services/LoginService";
import {Tab, Tabs} from "react-bootstrap";
import UstawieniaKontaContainer from "./components/UstawieniaKontaTabContainer/UstawieniaKontaContainer";
import UstawieniaAplikacjiContainer from "./components/UstawieniaAplikacjiTabComponents/UstawieniaAplikacjiContainer";
import {isMobileDevice} from "../../utilities/rwd/rwdUtilities";

class UstawieniaModule extends Component {

    componentDidMount() {
        if(isMobileDevice())
            window.scrollTo(0,0);
    }


    render() {
        return (
            <LayoutComponent breadcrumbs={[{url: "/ustawienia", name: 'Ustawienia'}]}
                             isAdminPanel={LoginService.checkIfCurrentUserIsAdmin()}>
                <div id={'USTAWIENIA_MODULE'}>
                    <Tabs defaultActiveKey="konto" id="uncontrolled-tab-example">
                        <Tab eventKey="konto" title="Ustawienia konta">
                            <UstawieniaKontaContainer />
                        </Tab>
                        <Tab eventKey="aplikacja" title="Ustawienia aplikacji">
                            <UstawieniaAplikacjiContainer />
                        </Tab>
                    </Tabs>
                </div>
            </LayoutComponent>
        );
    }
}

export default UstawieniaModule;