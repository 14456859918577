import React from 'react';
import Select from "react-select";
import {HeaderSmallerSelectStyles} from "../../../utilities/forms/jsStyles";
import {isMobileDevice} from "../../../utilities/rwd/rwdUtilities";
import localStorageTypes from "../../../utilities/localStorage/localStorageTypes";
import {SiecContext} from "../../../SiecContext";
import LoginService from "../../../services/LoginService";
import ApplicationGlobalSettingsService from "../../../services/ApplicationGlobalSettingsService";
import AdminService from "../../../services/AdminService";
import {displayNotificationError} from "../../../utilities/notifications/NotificationsManager";

interface Props {
    handleChangeSiec: (siecObj: any) => void;
}

interface State {
    sieci: any,
    kontrahent: any
}

class SieciSelectAdmin extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            sieci: [],
            kontrahent: null,
        };
    }

    znajdzSiecPoKontrahencie = (kontrahent: string) => {
        let siec = null;
        //@ts-ignore
        let sieci = JSON.parse(localStorage.getItem(localStorageTypes.CURRENT_SIECI_ARRAY));
        if (sieci.find((s: any) => s.kontrahent = kontrahent))
            siec = sieci.find((s: any) => s.kontrahent = kontrahent);
        return siec
    };

    componentDidMount() {
        this.pobierzSieci();
    }

    pobierzSieci = () => {
        AdminService.getSieciAdmin('')
            .then((response) => {
                if (response && response.status < 300)
                {
                  this.setState({sieci: response.data});
                } else displayNotificationError('Nie można pobrać kontrahentów', '')
            })
            .catch((error) => {
                displayNotificationError('Nie można pobrać kontrahentów', '')
            })
    }

    render() {

        let siec = this.context;

        return (
            <div id={'SieciSelectHeaderComponentAdmin'}>

                <Select
                    getOptionLabel={({kontrahent}: any) => kontrahent}
                    getOptionValue={({kontrahent}: any) => kontrahent}
                    value={
                        // Boolean(siec.kontrahent) ?
                        //     this.znajdzSiecPoKontrahencie(siec.kontrahent)
                        //     :
                        //     LoginService.checkIfUserIsLogged() ?
                                {"kontrahent": LoginService.getCurrentUser().kontrahent}
                                // : null
                    }
                    options={
                        this.state.sieci
                        // Boolean(localStorage.getItem(localStorageTypes.CURRENT_SIECI_ARRAY)) ?
                        //     //@ts-ignore
                        //     JSON.parse(localStorage.getItem(localStorageTypes.CURRENT_SIECI_ARRAY)) : []
                    }
                    onChange={(e: any) => {
                        LoginService.removeUserFromSessionBezUzytkownika();
                        siec.changeKontrahentAdmin(e);
                        //@ts-ignore
                        this.props.handleChangeSiec(e)
                    }}
                    styles={HeaderSmallerSelectStyles}
                    isClearable={false}
                    placeholder={'Wybierz...'}
                    isSearchable={!isMobileDevice()}
                    // isDisabled={LoginService.czyKlientWlasciciel()}
                />

            </div>
        );
    }
}

SieciSelectAdmin.contextType = SiecContext;

export default SieciSelectAdmin;
