import React, {Component} from 'react';
import LayoutComponent from "../../../sharedComponents/layout/LayoutComponent";
import LoginService from "../../../services/LoginService";
import OfertaService from "../../../services/OfertaService";
import AdminReklamacjeTable from "../admin_reklamacje/components/AdminReklamacjeTable";
import AdminBaneryTable from "./components/AdminBaneryTable";
import AdminService from "../../../services/AdminService";
import {Button, Col, Row} from "react-bootstrap";
import {IconAdd, IconCheck, IconGrid, IconTable, IconTimes} from "../../../sharedComponents/icons/FontAwesome";
import Switch from "react-switch";
import UstawieniaService from "../../../services/UstawieniaService";
import ApplicationGlobalSettingsService from "../../../services/ApplicationGlobalSettingsService";
import {
    displayNotificationError,
    displayNotificationSuccess
} from "../../../utilities/notifications/NotificationsManager";
import './../../../styles/modules/AdminBaneryModule.css';
import PopupWrapper from "../../../sharedComponents/popups/PopupWrapper";
import AdminKomunikatyForm from "../admin_komunikaty/components/AdminKomunikatyForm";
import AdminBaneryForm from "./components/AdminBaneryForm";
import TopLoading from "../../../sharedComponents/loader/TopLoading";
import PromocjeService from "../../../services/PromocjeService";

class AdminBaneryModule extends Component {

    state={
        banery: [],
        banerAdminZgoda: false,
        data: [],
        loading: true,
        loadingBanery: true,
        showFormularz: false,
        baner: false,
        isRequestLoading: true,
        isRequestError: false
    }

    componentDidMount() {
        this.pobierzBanery();
        this.pobierzUstawienia();
    }

    pobierzUstawienia = () => {
        this.setState({loading: true});

        UstawieniaService.getUstawienia()
            .then((response: any) => {

                if (response.status < 300) {
                    if(!ApplicationGlobalSettingsService.getGlobalSettingsConfiguration('sort'))
                    {
                        //alert('nie ma')
                        ApplicationGlobalSettingsService.setLocalStorageConfigurationObject(Object.assign({}, response.data, {"sort":[]}))
                    }
                    else
                    {
                        ApplicationGlobalSettingsService.setLocalStorageConfigurationObject(Object.assign({}, response.data, {"sort":ApplicationGlobalSettingsService.getGlobalSettingsConfiguration('sort')}))
                    }

                    this.setState({data: response.data, banerAdminZgoda: response.data.pokazBaner, loading: false});

                } else {
                    this.setState({banerAdminZgoda: false, loading: false});
                }
            })
            .catch((error: any) => {
                this.setState({banerAdminZgoda: false, loading: false});
            })
    }

    onChangeValue = (item: any, newValue: any) => {
        let data = {
            pozycja: 'pokazBaner',
            wartosc: newValue
        }

        AdminService.zmienUstawienia(data)
            .then((response: any) => {
                if (response && response.status < 300) {

                    let {data} = this.state;
                    //@ts-ignore
                    data[item] = newValue;
                    this.setState({data});

                    this.setState({banerAdminZgoda: newValue});
                    ApplicationGlobalSettingsService.setLocalStorageConfigurationObject(data);

                } else {
                    displayNotificationError('Wystąpił błąd podczas próby zapisania nowych ustawień', '')
                }
            })
            .catch((error: any) => {
                displayNotificationError('Wystąpił błąd podczas próby zapisania nowych ustawień', '')
            })
    };

    pobierzBanery = () => {
        this.setState({loadingBanery: true,
                            isRequestLoading: true,
                            isRequestError: false
        });

        AdminService.getBanery()
            .then((response: any) => {
                if (response.status < 300)
                    this.setState({banery: response.data,
                        loadingBanery: false,
                        isRequestLoading: false,
                        isRequestError: false
                    });
                LoginService.setBaneryInSession(response.data);
            })
            .catch((error: any) => {
                this.setState({loadingBanery: false,
                    isRequestLoading: false,
                    isRequestError: true});
                console.log('Error banery');
            })

    }

    onClickDodaj = (data: any) => {
        PromocjeService.dodajNowyBaneru(data).then((response) => {
            displayNotificationSuccess('Pomyślnie dodano baner', '');
            this.setState({showFormularz: false});
            this.pobierzBanery();
        }).catch((e) => {
            displayNotificationError('Wystąpił błąd podczas próby dodania nowego baneru', '');
        })
    }

    onClickEdytuj = (data: any) => {
        AdminService.updateBaner(data).then((response) => {
            displayNotificationSuccess('Pomyślnie edytowano baner', '');
            this.setState({showFormularz: false});
            this.pobierzBanery();
        }).catch((e) => {
            displayNotificationError('Wystąpił błąd podczas próby edycji baneru', '');
        })
    }

    onClickDeleteBaner = (id: any) => {
        if(window.confirm('Czy na pewno usunąć baner?')) {
            AdminService.deleteBaner(id).then((response) => {
                displayNotificationSuccess('Usunięto baner', '');
                this.pobierzBanery();
            }).catch((e) => {
                displayNotificationError('Wystąpił błąd podczas próby usunięcia baneru', '');
            })
        }
    }

    onClickEditBaner = (baner: any) => {
        this.setState({baner: baner, showFormularz: true});
    }

    render() {
        let {banerAdminZgoda, loading, showFormularz, baner, loadingBanery, isRequestLoading, isRequestError} = this.state;

        return (
            <LayoutComponent breadcrumbs={[{url: "/admin", name: 'PA'}, {url: "/banery", name: 'Banery'}]} isAdminPanel={true}>
                <div id={'ADMIN_BANERY_MODULE'}>

                    {(LoginService.czyAdmin()) &&
                    <div className={'UstawieniaAplikacjiItem'}>
                        {(!loading)?
                        <Row>

                            <Col sm={6} md={7} lg={8} xl={2}>
                                <h4>Pokaż baner</h4>
                            </Col>
                            <Col sm={6} md={5} lg={4} xl={6}>
                                <div className={'switch-component'}>
                                    <div>
                                        <span
                                            className={!banerAdminZgoda ? 'option option-false active' : 'option option-false'}><IconTimes/> nie</span>
                                    </div>
                                    <div>
                                        <Switch onChange={(e: any) => this.onChangeValue('pokazBaner', e)}
                                                checked={banerAdminZgoda}
                                                offColor="#fefefe"
                                                onColor="#fefefe"
                                        />
                                    </div>
                                    <div>
                                        <span
                                            className={banerAdminZgoda ? 'option option-true active' : 'option option-true'}><IconCheck/> tak</span>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={6} md={5} lg={4} xl={4} style={{textAlign: 'end'}}>
                                <Button variant={'success'} size={'sm'} id={'add-komunikat-btn'} onClick={() => this.setState({showFormularz: true})}><IconAdd/> Dodaj</Button>
                            </Col>
                        </Row>
                            :
                            <h6 className={'heading-label'}>Ładowanie...</h6>
                        }
                    </div>
                    }


                    <TopLoading isLoading={isRequestLoading} isError={isRequestError}>
                        <AdminBaneryTable banery={this.state.banery}
                                          loading={loadingBanery}
                                              handleClickPokaz={() => {
                                                  alert('tu zmiana statusu..')
                                              }}
                                              handleClickZmienStatus={() => {
                                                  alert('tu zmiana statusu..')
                                              }}
                                          handleClickDelete={this.onClickDeleteBaner}
                                          handleClickEdit={this.onClickEditBaner}
                        />
                    </TopLoading>

                    {
                        showFormularz &&
                        <PopupWrapper header={Boolean(baner) ? 'Edytuj baner' : 'Dodaj nowy baner'}
                                      shouldNotCloseWithoutClick={true} withoutOverflowY={false}
                                      onClose={() => this.setState({showFormularz: false, baner: false})}
                        >
                            <AdminBaneryForm handleClickAnuluj={() => this.setState({showFormularz: false, baner: false})} handleClickZapiszBaner={this.onClickDodaj} handleClickEdytujBaner={this.onClickEdytuj} baner={baner}/>
                        </PopupWrapper>
                    }
                </div>
            </LayoutComponent>
        );
    }
}

export default AdminBaneryModule;
