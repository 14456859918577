import React, {Component} from 'react';

//@ts-ignore
import ImageMapper from 'react-image-mapper';
import {IconExternalLink} from "../../../sharedComponents/icons/FontAwesome";

interface Props {
    strona: any,
    handleChangeCurrentArea: (area: any) => void
    // handleClickCurrentArea: (area: any) => void
    current: number,
    handleChangecurrentPageCounter: (type:any)=>void,
    disablePrev: boolean,
    disableNext: boolean,
}

class PromocjaGazetkaSlider extends Component<Props> {

    state = {
        IMG_SCALE: 1
    };

    componentDidMount() {
        this.calculateAndSetImageScale()
    }

    componentDidUpdate(prevProps: any) {
        if(JSON.stringify(prevProps.strona) !== JSON.stringify(this.props.strona))
            this.calculateAndSetImageScale()
    }

    calculateAndSetImageScale = () => {
        let container: any = document.getElementById('PromocjaGazetkaSlider');

        let imgWidth = 0;

        if (Boolean(this.props.strona))
            if (Boolean(this.props.strona.imgDimensions))
                if (Boolean(this.props.strona.imgDimensions.width))
                    imgWidth = this.props.strona.imgDimensions.width

        let scale = 1;

        if (Boolean(imgWidth) && Boolean(container)) {
            scale = (container.clientWidth / imgWidth) * (99/100)
        }

        this.setState({IMG_SCALE: scale})

    };

    onEnterArea = (area: any) => {
        this.props.handleChangeCurrentArea(area)
    };

    onLeaveArea = () => {
        this.props.handleChangeCurrentArea(null)
    };

    // onClickArea = (area: any) => {
    //     this.props.handleClickCurrentArea(area)
    // };


    render() {

        let {strona, disablePrev, disableNext} = this.props;
        let {IMG_SCALE} = this.state;

        return (
            <div id={'PromocjaGazetkaSlider'}>
                    {/*<ImageMapper src={strona}*/}
                    {/*//              map={*/}
                    {/*//     {*/}
                    {/*//         // name: strona.name,*/}
                    {/*//         areas: Array.from(strona.areas, (area: any) =>*/}
                    {/*//             (*/}
                    {/*//                 {*/}
                    {/*//                     ...area,*/}
                    {/*//                     coords: Array.from(area.coords, (coord: number) => coord * IMG_SCALE)*/}
                    {/*//                 }*/}
                    {/*//             )*/}
                    {/*//         ),*/}
                    {/*//     }*/}
                    {/*// }*/}
                    {/*             width={625}*/}
                    {/*//              height={strona.imgDimensions.height * IMG_SCALE}*/}
                    {/*//              onMouseEnter={this.onEnterArea}*/}
                    {/*//              onMouseLeave={this.onLeaveArea}*/}
                    {/*//              onClick={this.onClickArea}*/}
                    {/*/>*/}

                <div className="nawigacja btnPoprzednia" style={{visibility: disablePrev? 'hidden' : 'visible'}} title={'Poprzednia strona'} onClick={()=> this.props.handleChangecurrentPageCounter('-')}><i className="fa fa-chevron-left" /></div>
                <div className="nawigacja btnNatepna" style={{visibility: disableNext? 'hidden' : 'visible'}} title={'Następna strona'} onClick={()=> this.props.handleChangecurrentPageCounter('+')}><i className="fa fa-chevron-right" /></div>
                <img src={strona}/>
                <a href={strona} target={'_blank'}><IconExternalLink/> Zobacz grafikę w nowym oknie</a>
            </div>
        );
    }
}

export default PromocjaGazetkaSlider;