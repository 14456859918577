import React from 'react';
import LayoutComponent from "../../sharedComponents/layout/LayoutComponent";
import KoszykTable from "./components/KoszykTable";
import KoszykSummary from "./components/KoszykSummary";
import KoszykOrderForm from "./components/KoszykOrderForm";
import KoszykGrid from "./components/KoszykGrid";
import {isMobileDevice} from "../../utilities/rwd/rwdUtilities";
import TopTableGridSwitcher from "../../sharedComponents/TopTableGridSwitcher";
import {Button, Col, Modal, Row, Table} from "react-bootstrap";
import {IconCheck, IconImages, IconImportFile, IconTimes, IconTrash} from "../../sharedComponents/icons/FontAwesome";
import PopupWrapper from "../../sharedComponents/popups/PopupWrapper";
import KoszykImportContainer from "./importComponents/KoszykImportContainer";
import TopVarietyOptions from "../../sharedComponents/TopVarietyOptions";
import ApplicationGlobalSettingsService from "../../services/ApplicationGlobalSettingsService";
import KoszykService from "../../services/KoszykService";
import TopLoading from "../../sharedComponents/loader/TopLoading";
import {_omit} from "../../utilities/lodash/lodashUtilities";
import {displayNotificationError, displayNotificationInfo, displayNotificationSuccess} from "../../utilities/notifications/NotificationsManager";
import TopLoaderComponent from "../../sharedComponents/loader/TopLoaderComponent";
import {Link} from "react-router-dom";
import {formatMoney} from "../../utilities/formatters/formatMoney";
import './../../styles/sharedComponents/GlobalStyle.css';
import './../../styles/modules/ProduktModule.css';
import {TableFormatters_formatMoney} from "../../sharedComponents/tableComponents/tableFormatters";
import Shared_ProductAddToBasketForm
    from "../../sharedComponents/_reusable/ProductAddToBasket/Shared_ProductAddToBasketForm";
import Shared_ProductAddToBasketForm_function
    from "../../sharedComponents/_reusable/ProductAddToBasket/Shared_ProductAddToBasketForm_function";
import IloscIJednostka from "../../sharedComponents/_reusable/ProductAddToBasket/IloscIJednostka";

interface MatchParams {
    path: string;
}

export interface RouteComponentProps<MatchParams> {
    match: any;
}

interface Props extends RouteComponentProps<MatchParams> {
}


class KoszykModule extends React.Component<Props> {

    state = {
        shouldKoszykUpdate: false,
        produktyWKoszyku: [],
        koszykWygasle: [],
        wylaczone: [],

        currentView: true, // table - true, grid - false
        displayImages: false,
        showImportPopup: false,

        isRequestLoading: false,
        isRequestError: false,
        page: 0,
        size: (Boolean(localStorage.getItem('FAMIX_CURRENT_TABLESIZE_KOSZYK'))) ? Number(localStorage.getItem('FAMIX_CURRENT_TABLESIZE_KOSZYK')) : 12,
        pageableObject: {
            currentPage: 0,
            currentSize: 0,
            totalElements: 0
        },
        hasMoreForGrid: true,
        canLoadMore: true,
        loading: false,

        czyKliknietoWyslijZamowienie: false,
        isLoadingWysylka: false,
        responseWysylka: {status: 'error', message: ''},

        warunkiDostawy: null,
        isLoadingWarunki: false,
        isErrorWarunki: false,

        wartoscKoszyka: 0,

        stanKoszyka: null,
        isLoadingStan: false,
        isErrorStan: false,
        edycjaSymbol: "",
        edycjaNumer: 0,

        czyEdycja: false,
        zmianaOferta: false,
        uwagiRest: '',

        showModalDeletePromocje: false,
        showModalPoTerminie: false,
        showModalWyczyscKoszyk: false,
        showModalWylaczone: false,
        delRow: null,

        sort: []
    };

    componentDidMount() {

        if(isMobileDevice())
            window.scrollTo(0,0);

        this.setState({
            currentView: isMobileDevice() ? false : ApplicationGlobalSettingsService.getGlobalSettingsModuleView('Koszyka'),
            displayImages: ApplicationGlobalSettingsService.getGlobalSettingsConfiguration('koszykDomyslnieWyswietlajZdjecia')
        }, () => this.setState({
            size: (this.state.currentView)? this.state.size : 12
        }));

        this.setState({
            isLoadingStan: true,
            isErrorStan: false,
            isLoadingWarunki: true,
            isErrorWarunki: false,
            isRequestLoading: true,
            isRequestError: false,
        });

        this.pobierzWarunkiDostawy();
        // this.pobierzProduktyWKoszyku();
        // this.pobierzPodsumowanieKoszyka();
    }

    /* get functions */

    pobierzPodsumowanieKoszyka = () => {
        this.setState({
            isLoadingStan: true,
            isErrorStan: false,
        });

        KoszykService.getStanKoszyka()
            .then((response: any) => {
                if (response && response.status < 300) {
                    this.setState({
                        stanKoszyka: response.data,
                        wartoscKoszyka: response.data.wartosc,
                        isLoadingStan: false,
                        isErrorStan: false,
                        czyEdycja: (response.data.numer !== 0),
                        edycjaSymbol: response.data.symbol,
                        edycjaNumer: response.data.numer,
                        uwagiRest: response.data.uwagi
                    }, () => this.pobierzProduktyWKoszyku());
                } else
                    this.setState({
                        isLoadingStan: false,
                        isErrorStan: true,
                    });
            })
            .catch((error: any) => {
                this.setState({
                    isLoadingStan: false,
                    isErrorStan: true,
                })
            })
    };

    pobierzSamoPodsumowanieKoszyka = () => {
        this.setState({
            isLoadingStan: true,
            isErrorStan: false,
        });

        KoszykService.getStanKoszyka()
            .then((response: any) => {
                if (response && response.status < 300) {
                    this.setState({
                        stanKoszyka: response.data,
                        wartoscKoszyka: response.data.wartosc,
                        isLoadingStan: false,
                        isErrorStan: false,
                        czyEdycja: (response.data.numer !== 0),
                        edycjaSymbol: response.data.symbol,
                        edycjaNumer: response.data.numer
                    });
                } else
                    this.setState({
                        isLoadingStan: false,
                        isErrorStan: true,
                    });
            })
            .catch((error: any) => {
                this.setState({
                    isLoadingStan: false,
                    isErrorStan: true,
                })
            })
    };

    generateSortString = (): string => {
        let sortowanie = this.state.sort;
        let sortString = '';

        for (let i=0; sortowanie.length > i; i++)
        {
            // @ts-ignore
            sortString = sortString + '&sort=' + sortowanie[i].kolumna + ',' + sortowanie[i].kierunek;
        }

        return sortString;

    }

    pobierzProduktyWKoszyku = (page = this.state.page, size: number = this.state.size, akcja: number = 0) => {

        this.setState({
            // isRequestLoading: true,
            isRequestError: false,
        });

        KoszykService.getKoszykV2(page, size, this.generateSortString(), this.state.czyEdycja, akcja)
            .then((response: any) => {
                if (response && response.status < 300) {

                    if(response.data.czyWylaczone) {
                        this.pobierzSamoPodsumowanieKoszyka();
                        this.setState({showModalWylaczone: true, wylaczone: response.data.wylaczone, shouldKoszykUpdate: true});
                    }

                    if(response.data.czyWygasle)
                    {
                        this.setState({showModalPoTerminie: true, koszykWygasle: response.data.koszykWygasle});
                    } else {
                        this.setState({
                            isRequestLoading: false,
                            isRequestError: false,
                            loading: false,
                            produktyWKoszyku: response.data.koszyk.content,
                            pageableObject: Object.assign({}, _omit(response.data.koszyk, "content"), {currentPage: page}, {currentSize: size}),
                            hasMoreForGrid: !(response.data.koszyk.totalElements === response.data.koszyk.content.length),
                            canLoadMore: true
                        });
                    }

                    if((akcja === 1) || akcja === 2){
                        this.pobierzSamoPodsumowanieKoszyka();
                        this.setState({showModalPoTerminie: false, shouldKoszykUpdate: true});
                    }

                } else {
                    this.setState({
                        isRequestLoading: false,
                        isRequestError: true,
                        loading: false,
                        hasMoreForGrid: false,
                        canLoadMore: false
                    });
                }
            })
            .catch((error: any) => {
                this.setState({
                    isRequestLoading: false,
                    isRequestError: true,
                    loading: false,
                    hasMoreForGrid: false,
                    canLoadMore: false
                });
            })

        // KoszykService.getKoszyk(page, size, this.generateSortString(), this.state.czyEdycja)
        //     .then((response: any) => {
        //         if (response && response.status < 300) {
        //
        //             this.setState({
        //                 isRequestLoading: false,
        //                 isRequestError: false,
        //                 loading: false,
        //                 produktyWKoszyku: response.data.content,
        //                 pageableObject: Object.assign({}, _omit(response.data, "content"), {currentPage: page}, {currentSize: size}),
        //                 hasMoreForGrid: !(response.data.totalElements === response.data.content.length),
        //                 canLoadMore: true
        //             });
        //
        //         } else {
        //             this.setState({
        //                 isRequestLoading: false,
        //                 isRequestError: true,
        //                 loading: false,
        //                 hasMoreForGrid: false,
        //                 canLoadMore: false
        //             });
        //         }
        //     })
        //     .catch((error: any) => {
        //         this.setState({
        //             isRequestLoading: false,
        //             isRequestError: true,
        //             loading: false,
        //             hasMoreForGrid: false,
        //             canLoadMore: false
        //         });
        //     })
    };

    pobierzWarunkiDostawy = () => {
        this.setState({
            isLoadingWarunki: true,
            isErrorWarunki: false,
        });
        KoszykService.getWarunkiDostawy()
            .then((response: any) => {
                if (response && response.status < 300) {
                    this.setState({
                        warunkiDostawy: response.data,
                        isLoadingWarunki: false,
                        isErrorWarunki: false,
                    }, () => this.pobierzPodsumowanieKoszyka());
                } else {
                    this.setState({
                        isLoadingWarunki: false,
                        isErrorWarunki: true,
                    });
                    displayNotificationError('Nie można załadować koszyka', 'Błąd pobierania warunków dostawy')
                }
            })
            .catch((error: any) => {
                this.setState({
                    isLoadingWarunki: false,
                    isErrorWarunki: true,
                });
                displayNotificationError('Błąd pobierania warunków dostawy. ', error.message)
            })
    };

    /* refresh functions */
    odswiezIloscWKoszykuLokalnie = (amount: any, item: any, czyZImport: boolean) => {
        let {produktyWKoszyku} = this.state;
        let produkt: any = produktyWKoszyku.find((p: any) => p.indeks === item.indeks);
        let itemImport = item

        // if (item.jm.indexOf("KG") > -1) {
        //
        // } else {
        //     if (item.jm2.indexOf("DIS") > -1) {
        //
        //     }
        // }

        if (Boolean(produkt)) {
            produkt.koszyk = amount;
            produkt.koszykIlosc = amount;
            produkt.wartosc = amount * item.cena * item.ilJm2;
        } else {
            itemImport.koszykIlosc = amount;
            // @ts-ignore
            produktyWKoszyku.unshift(itemImport);
        }
        this.setState({produktyWKoszyku})
    };

    odswiezIloscPozycjiWKoszykuLokalnie = (deletedItem: any) => {
        let {produktyWKoszyku} = this.state;

        if(KoszykService.isEmptyIdUm(deletedItem.idUm)){
            let index: any = produktyWKoszyku.findIndex((p: any) => (p.indeks === deletedItem.indeks) && KoszykService.isEmptyIdUm(p.idUm));
            if ((Boolean(index) && index > -1) || index === 0) {
                produktyWKoszyku.splice(index, 1)
                this.setState({produktyWKoszyku})
            }
        } else {
            let newKoszyk: any = produktyWKoszyku.filter((i: any) => i.idUm !== deletedItem.idUm);
            this.setState({produktyWKoszyku: newKoszyk});
        }

    };

    /* table functions  */

    onChangePageInTable = (page: number, size: number) => {
        this.setState({
            loading: true,
            page: page - 1,
            size: size
        }, () => this.pobierzProduktyWKoszyku())
    };

    onChangeSizeInTable = (size: number) => {
        localStorage.setItem('FAMIX_CURRENT_TABLESIZE_KOSZYK', String(size))
        this.setState({
            loading: true,
            size
        }, () => this.pobierzProduktyWKoszyku())
    };

    /* grid functions  */

    loadMoreElementsForGrid = (e: any) => {

        if (this.state.canLoadMore) {

            this.setState({canLoadMore: false, isRequestLoading: true})

            let {produktyWKoszyku} = this.state;

            this.setState((prevState: any) => ({page: prevState.page + 1}), () => {
                KoszykService.getKoszyk(this.state.page, 12, this.generateSortString(), this.state.czyEdycja)
                    .then(response => {
                        if (response.status < 300) {

                            this.setState({
                                isRequestLoading: false,
                                isRequestError: false,
                                pageableObject: Object.assign({}, _omit(response.data, "content"), {currentPage: this.state.page}, {currentSize: 12}),
                                canLoadMore: true,
                                hasMoreForGrid: (response.data.totalPages > this.state.page)
                            });

                            response.data.content.forEach((item: any) => {
                                //@ts-ignore
                                produktyWKoszyku.push(item)
                            });

                            this.setState({produktyWKoszyku});

                        } else {
                            this.setState({
                                canLoadMore: true,
                                isRequestLoading: false,
                                isRequestError: true,
                                hasMoreForGrid: false
                            });
                        }
                    })
                    .catch(error => {
                        this.setState({
                            canLoadMore: true,
                            isRequestLoading: false,
                            isRequestError: true,
                            hasMoreForGrid: false
                        });
                    })
            });
        }
    };

    /* basket functions */

    onChangeQuantityInBasket = (previousAmount: any, amount: any, item: any, czyZImportu: boolean = false) => {
        if (previousAmount === amount) {
            // brak zmian
        } else {
            if (previousAmount > 0 && amount === 0) {
                // usunięcie z koszyka
                this.deleteFromBasket(item);
            } else {
                // zmiana ilości w koszyku
                KoszykService.addOrEditPozycjaKoszyka(Object.assign(item, {ilosc: amount, sprzedazJm: item.zakupJm, czyEdycja: this.state.czyEdycja}))
                    .then((response: any) => {
                        if (response && response.status < 300) {
                            this.setState({shouldKoszykUpdate: true}, () => {this.odswiezIloscWKoszykuLokalnie(amount, item, czyZImportu); this.pobierzSamoPodsumowanieKoszyka();});
                            displayNotificationInfo('Zmieniono ilość produktu w koszyku', `Zmieniono ilość produktu ${item.nazwa} z ${previousAmount} na ${amount} ${item.zakupJm}`)
                        } else {
                            displayNotificationError("Nie edytowano ilości produktu w koszyka", "Wystąpił błąd podczas próby wykonania akcji")
                        }

                    })
                    .catch((error: any) => {
                        displayNotificationError("Wystąpił błąd podczas próby dodania bądź edytowania ilości produktu w koszyku", error.message)
                    })
            }
        }
    };

    deleteFromBasket = (item: any) => {

        // KoszykService.deletePozycjaKoszyka(item.indeks)
        //     .then((response: any) => {
        //         if (response && response.status < 300) {
        //             this.setState({shouldKoszykUpdate: true}, () => {this.odswiezIloscPozycjiWKoszykuLokalnie(item); this.pobierzPodsumowanieKoszyka();});
        //             displayNotificationInfo('Usunięto produkt z koszyka', 'Usunięto produkt o nazwie ' + item.nazwa)
        //         } else {
        //             displayNotificationError("Nie usunięto produktu z koszyka", "Wystąpił błąd podczas próby wykonania akcji")
        //         }
        //     })
        //     .catch((error: any) => {
        //         displayNotificationError("Wystąpił błąd podczas próby usunięcia produktu z koszyka", error.message)
        //     })

        // 16.08.2021

        if(KoszykService.isEmpty(item.idUm) || item.idUm === 0 || item.idUm === '0'){
            this.usunPozycje(item);
        } else {
            this.setState({showModalDeletePromocje: true, delRow: item});
        }

    };

    usunPozycje = (item: any) => {
        let {czyEdycja, edycjaSymbol, edycjaNumer, produktyWKoszyku} = this.state;

        if((!czyEdycja) || (produktyWKoszyku.length > 1))
        {
            KoszykService.addOrEditPozycjaKoszyka(Object.assign(item, {ilosc: 0, sprzedazJm: item.zakupJm, czyEdycja: this.state.czyEdycja}))
                .then((response: any) => {
                    if (response && response.status < 300) {
                        this.setState({shouldKoszykUpdate: true}, () => {this.odswiezIloscPozycjiWKoszykuLokalnie(item); this.pobierzSamoPodsumowanieKoszyka();});

                        if(KoszykService.isEmptyIdUm(item.idUm)){
                            displayNotificationInfo('Usunięto produkt z koszyka', 'Usunięto produkt o nazwie ' + item.nazwa);
                        } else {
                            displayNotificationInfo('Usunięto promocje', 'Usunięto promocje o id: ' + item.idUm);
                        }

                    } else {
                        displayNotificationError("Nie usunięto produktu z koszyka", "Wystąpił błąd podczas próby wykonania akcji")
                    }

                })
                .catch((error: any) => {
                    displayNotificationError("Wystąpił błąd podczas próby usunięcia produktu z koszyka", error.message)
                })

        } else {

            alert('Zamówienie ' + edycjaSymbol+edycjaNumer + ' zostało otwarte do edycji! Proszę zakończyć edycję.')

        }
    }

    /* other functions */

    handleChangeView = (currentView: boolean) => {
        // this.setState({currentView})

        // let tableSize: any = (localStorage.getItem('FAMIX_CURRENT_TABLESIZE'))? localStorage.getItem('FAMIX_CURRENT_TABLESIZE') : 10;

        this.setState({
            currentView,
            page: 0,
            size: 12,
            produktyWKoszyku: [],
            loading: true,
            isRequestLoading: true,
            isRequestError: false
        }, () => this.pobierzProduktyWKoszyku());
    };

    onClickShowImport = () => {
        this.setState({showImportPopup: true})
    };

    onClickToggleImages = () => {
        this.setState((prevState: any) => ({displayImages: !prevState.displayImages}))
    };

    onClickWyczyscKoszyk = () =>{
        let {czyEdycja, edycjaSymbol, edycjaNumer} = this.state;

        if(!czyEdycja)
        {
            this.setState({showModalWyczyscKoszyk: true});
        } else
        {
            alert('Zamówienie ' + edycjaSymbol+edycjaNumer + ' zostało otwarte do edycji! Proszę zakończyć edycję.')
        }

    };

    wyczyscKoszyk = () => {
        KoszykService.deletePozycjaKoszyka('')
            .then((response: any) => {
                if (response && response.status < 300) {
                    this.setState({shouldKoszykUpdate: true, showModalWyczyscKoszyk: false}, () => {this.setState({produktyWKoszyku:[]}); this.pobierzPodsumowanieKoszyka();});
                    displayNotificationInfo('Wyczyszczono koszyk', 'Usunięto wszystkie produkty z koszyka')
                } else {
                    displayNotificationError("Nie wyczyszczono koszyka", "Wystąpił błąd podczas próby wykonania akcji")
                }
            })
            .catch((error: any) => {
                displayNotificationError("Wystąpił błąd podczas próby wyczyszczenia koszyka", error.message)
            })
    }

    onClickWyslijZamowienie = (obj: any) => {
        let {czyEdycja, edycjaNumer, edycjaSymbol} = this.state;

        // todo obsluzyc ladniej wiadomosci po zrobieniu requesta

        this.setState({
            czyKliknietoWyslijZamowienie: true,
            isLoadingWysylka: true,
        });

        if (!czyEdycja)
        {
            KoszykService.wyslijZamowienieZKoszyka(obj)
                .then((response: any) => {
                    if (response && response.status < 300) {
                        if (response.data.success)
                            displayNotificationSuccess('Zamówienie zostało wysłane', 'Numer zamówienia to ' + response.data.nrZamowienia)
                        this.setState({
                            isLoadingWysylka: false,
                            responseWysylka: {status: response.data.success ? 'ok' : 'error', message: response.data.success ? response.data.nrZamowienia : response.data.errorMessage}
                        })

                    } else {
                        displayNotificationError('Wystapił bład podczas próby wysłania zamówienia', '')
                        this.setState({
                            isLoadingWysylka: false,
                            responseWysylka: {status: 'error', message: response.data.errorMessage}
                        })
                    }

                })
                .catch((error: any) => {
                    console.log(error)
                    displayNotificationError('Wystapił bład podczas próby wysłania zamówienia', error.message)
                    this.setState({
                        isLoadingWysylka: false,
                        responseWysylka: {status: 'error', message: ''}
                    })
                })
        } else
        {
            KoszykService.wyslijZamowienieZKoszykaPoEdycji(edycjaNumer, edycjaSymbol, obj.uwagi).then((response) => {
                if (response && response.status < 300) {
                    displayNotificationSuccess('Zamówienie zostało zaktualizowane', 'Numer zamówienia to '+edycjaSymbol+edycjaNumer);
                    this.setState({
                        shouldKoszykUpdate: true,
                        isLoadingWysylka: false,
                        responseWysylka: {
                            status: 'ok',
                            message: `${edycjaSymbol}${edycjaNumer}`
                        }
                    });
                } else {
                    displayNotificationError('Wystąpił błąd podczas aktualizacji zamówienia', '')
                    this.setState({
                        isLoadingWysylka: false,
                        responseWysylka: {status: 'error', message: response.data.errorMessage}
                    })
                }
            }).catch((e) => {
                displayNotificationError('Wystąpił błąd podczas aktualizacji zamówienia', e.message);
                this.setState({
                    isLoadingWysylka: false,
                    responseWysylka: {status: 'error', message: e.message}
                })
            })
        }

    };

    odswiezIloscWOfercieLokalnie = (amount: any, item: any, typOpak?: string) => {
        let {produktyWKoszyku} = this.state;
        let produkt: any = produktyWKoszyku.find((p: any) => p.indeks === item.indeks);
        if (Boolean(produkt)) {
            produkt.koszyk = amount
            produkt.koszykIlosc = amount
            if(typOpak) produkt.zakupJm = typOpak
        }
        // console.log(typOpak)
        this.setState({produktyWKoszyku, zmianaOferta: !this.state.zmianaOferta})
    };

    odswiez = (amount: any, item: any, typOpak?: string) => {

        //this.odswiezIloscWOfercieLokalnie(amount, item);
        this.setState({shouldKoszykUpdate: true}, () => {this.odswiezIloscWOfercieLokalnie(amount, item, typOpak); this.pobierzSamoPodsumowanieKoszyka();});

        //let pro = this.state.produkty;
        //pro[1].koszykIlosc=2;
        //this.setState({produkty: pro});
        //this.pobierzOferte();
    }

    onChangeSortowanie = (sort: any) => {
        // let sor = sort.value;
        // let sortString = sort.map((s: any, index: number) => {
        //     return s.kolumna+','+s.kierunek
        // }).toString();

        // setTimeout(() => {
        //     this.setState({sort: sort, page: (this.czyStareDane())? 99999 : 0, loading: true}, () => this.pobierzOferte());
        // }, 2000);
        // this.setState({sort: sort});

        this.setState({sort: sort, page: 0, loading: true}, () => this.pobierzProduktyWKoszyku());

        // let data = ApplicationGlobalSettingsService.getLocalStorageConfigurationObject();
        // //console.log(sort);
        // data["sort"] = sort;
        // ApplicationGlobalSettingsService.setLocalStorageConfigurationObject(data);

    };

    usunPozWyg = (id: any) => {
        let {koszykWygasle} = this.state;

        if(koszykWygasle.length === 1){
            this.pobierzProduktyWKoszyku(this.state.page, this.state.size, 1);
        } else {
            KoszykService.deleteWygaslaPozycjaKoszyka(id, this.state.czyEdycja).then((response) => {
                koszykWygasle.splice(koszykWygasle.findIndex((a: any) => a.id === id) , 1);
                this.setState({koszykWygasle});
            }).catch((e) => {
                displayNotificationError('Wystąpił błąd podczas próby usunięcia indeksu', '');
            })
        }

    }

    render() {

        let {shouldKoszykUpdate, showModalPoTerminie, showModalWyczyscKoszyk, showModalWylaczone, wylaczone, koszykWygasle, uwagiRest, isLoadingWarunki, isErrorWarunki, warunkiDostawy, wartoscKoszyka, produktyWKoszyku, currentView, loading, displayImages, showImportPopup, isRequestLoading, isRequestError, pageableObject, hasMoreForGrid, czyKliknietoWyslijZamowienie, isLoadingWysylka, responseWysylka, isLoadingStan, isErrorStan, stanKoszyka, czyEdycja, edycjaSymbol, edycjaNumer, zmianaOferta} = this.state;
        const breadcrumbs = [{name: 'Koszyk', url: '/koszyk'}]

        return (
            <LayoutComponent breadcrumbs={breadcrumbs}
                             shouldKoszykUpdate={shouldKoszykUpdate}
                             handleShouldKoszykUpdateChange={() => this.setState({shouldKoszykUpdate: false})}
                             handleChangeSiec={()=>{
                                 this.setState({isLoadingWarunki: true, isLoadingStan: true, isRequestLoading: true});
                                 this.pobierzWarunkiDostawy()
                             }}
                             ustawCzyEdycja={(czyEdycja, symbol, numer) => this.setState({czyEdycja: czyEdycja, edycjaSymbol: symbol, edycjaNumer: numer})}
                             anulowanoEdycje={() => {
                                 this.setState({isLoadingWarunki: true, isLoadingStan: true, isRequestLoading: true});
                                 this.pobierzWarunkiDostawy()
                             }}
            >
                <div id={'KOSZYK_MODULE'}>
                    {/*<TopLoading isLoading={isLoadingWarunki} isError={isErrorWarunki} loadingText={'Trwa ładowanie warunków dostawy...'}>*/}
                        {
                            czyKliknietoWyslijZamowienie ?
                                <div>
                                    {
                                        isLoadingWysylka ?
                                            <TopLoaderComponent isBig={true} isCentered={true} loadingText={"Trwa wysyłanie zamówienia..."}/>
                                            :
                                            responseWysylka.status === 'ok' ?
                                                <div className={'KoszykWysylka WysylkaSukces'}>
                                                    <div>
                                                        <div>
                                                            <IconCheck/>
                                                        </div>
                                                        <div>
                                                            {(!czyEdycja)? <h3>Pomyślnie złożono zamówienie!</h3> : <h3>Pomyślnie edytowano zamówienie!</h3>}
                                                            <h5>Numer zamówienia to: {responseWysylka.message}</h5>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <Link to={'/oferta'}>
                                                            <Button variant={'outline-secondary'}>Wróć do oferty</Button>
                                                        </Link>
                                                        <Link to={'/zamowienia'}>
                                                            <Button variant={'outline-primary'}>Zobacz zamówienia</Button>
                                                        </Link>
                                                    </div>

                                                </div>
                                                :
                                                <div className={'KoszykWysylka WysylkaError'}>
                                                    <div>
                                                        <div>
                                                            <IconTimes/>
                                                        </div>
                                                        <div>
                                                            <h3>Nie udało się złożyć zamówienia!</h3>
                                                            <h5>Wystąpił błąd podczas wysyłki</h5>
                                                            <h6>Szczegóły: {responseWysylka.message}</h6>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <Button variant={'outline-secondary'}
                                                                onClick={() => this.setState({
                                                                    czyKliknietoWyslijZamowienie: false,
                                                                    isLoadingWysylka: false,
                                                                    responseWysylka: {status: 'error', message: ''}
                                                                })}
                                                        >Wróć do koszyka</Button>
                                                    </div>
                                                </div>
                                    }
                                </div>
                                :
                                <>

                                        <Row>
                                            <Col md={6} lg={6} xl={6}>
                                                <TopVarietyOptions
                                                    options={[
                                                        // {id: 'KoszykImportSwitcher', handleClick: this.onClickShowImport, icon: <IconImportFile/>, text: 'Import'},
                                                        // {
                                                        //     id: 'KoszykZdjeciaSwitcher',
                                                        //     handleClick: this.onClickToggleImages,
                                                        //     icon: <IconImages/>,
                                                        //     text: (displayImages) ? 'Ukryj zdjęcia' : 'Pokaż zdjęcia',
                                                        //     isActive: displayImages
                                                        // },
                                                        isMobileDevice() &&
                                                        {
                                                            id:'KoszykWyczysWszystko',
                                                            handleClick:this.onClickWyczyscKoszyk,
                                                            icon:<IconTrash/>,
                                                            text:'Wyczyść koszyk',
                                                            isActive: false,
                                                            className:'text-danger'
                                                        }
                                                    ]}
                                                />
                                            </Col>
                                            <Col md={6} lg={6} xl={6} style={{textAlign: 'end', paddingRight: '0'}}>
                                                <div style={{display: 'inline-block'}}>
                                                    <Button variant={'light'} className={''} title={'Import zamówień'} size={'sm'} id={'importBtn'} onClick={this.onClickShowImport} style={{marginBottom: '5px', color: '#605D8D'}}>
                                                        <IconImportFile/> Import zamówień
                                                    </Button>
                                                    {/*<TopVarietyOptions options={[*/}
                                                    {/*    {id: 'KoszykImportSwitcher', handleClick: this.onClickShowImport, icon: <IconImportFile/>, text: 'Import zamówień'}*/}
                                                    {/*]}/>*/}
                                                </div>
                                                <div style={{display: 'inline-block'}}>
                                                    {
                                                        produktyWKoszyku.length > 0 &&
                                                            <TopTableGridSwitcher currentView={currentView} quantity={Boolean(pageableObject) ? pageableObject.totalElements : 0}
                                                                                  handleChangeView={this.handleChangeView}/>
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    {
                                        currentView ?
                                            <TopLoading isLoading={isRequestLoading} isError={isRequestError} loadingText={'Trwa ładowanie koszyka...'}>
                                                <KoszykTable data={produktyWKoszyku} displayImages={displayImages}
                                                             pageableObject={pageableObject}
                                                             handleChangePage={this.onChangePageInTable}
                                                             handleChangeSizePerPage={this.onChangeSizeInTable}
                                                             handleChangeQuantityInBasket={this.onChangeQuantityInBasket}
                                                             handleChangeSortowanie={this.onChangeSortowanie}
                                                             sortProps={this.state.sort}
                                                             handleDeleteItem={this.deleteFromBasket}
                                                             odswiezIlosc={this.odswiez}
                                                             changeOferta={zmianaOferta}
                                                             czyEdycja={this.state.czyEdycja}
                                                             loading={loading}
                                                />
                                            </TopLoading>
                                            :
                                            <KoszykGrid data={produktyWKoszyku} displayImages={displayImages} isLoading={isRequestLoading}
                                                        hasMore={hasMoreForGrid} handleLoadMoreForGrid={this.loadMoreElementsForGrid}
                                                        handleChangeQuantityInBasket={this.onChangeQuantityInBasket}
                                                        handleDeleteItem={this.deleteFromBasket}
                                            />
                                    }
                                    <Row>
                                        <Col md={3} lg={3} xl={2} className={'my-auto'}>
                                            {
                                                (!isMobileDevice() && !czyEdycja) &&
                                                <Button variant={'light'} className={'text-danger'} title={'Usuwa wszystkie produkty koszyka'} size={'sm'} id={'wyczyscKoszykBtn'} onClick={this.onClickWyczyscKoszyk}>
                                                    <IconTrash/> Wyczyść koszyk
                                                </Button>
                                            }
                                        </Col>
                                        <Col md={3} lg={3} xl={4} className={'my-auto'}>
                                            {stanKoszyka &&
                                                <div className={'punkty'}>
                                                    <label>Ilość punktów lojalnościowych:</label>
                                                    <span>
                                                        {
                                                            //@ts-ignore
                                                            stanKoszyka.punkty
                                                        } pkt
                                                    </span>
                                                </div>
                                            }
                                        </Col>
                                        <Col md={6} lg={6} xl={6}>
                                            {/*<KoszykSummary shouldKoszykUpdate={shouldKoszykUpdate} handleUpdateWartoscKoszyka={(wartoscKoszyka: any) => this.setState({wartoscKoszyka})}/>*/}
                                            <div id={'KoszykSummary'}>
                                                <TopLoading isLoading={isLoadingStan} isError={isErrorStan} isCentered={false} isBig={false} loadingText={'Trwa ładowanie podsumowania'}>
                                                    {stanKoszyka &&
                                                        <div id={'summary-content'}>
                                                            <label>Podsumowanie:</label>
                                                            <div>
                                                                <div>
                                                                    <label>Ilość pozycji:</label>
                                                                    <span>
                                                                        {
                                                                            //@ts-ignore
                                                                            stanKoszyka.iloscPozycji
                                                                        }
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <label>Wartość netto:</label>
                                                                    <span>
                                                                        {
                                                                            //@ts-ignore
                                                                            formatMoney(stanKoszyka.wartosc)
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </TopLoading>
                                            </div>
                                        </Col>
                                    </Row>
                                    <TopLoading isLoading={isLoadingWarunki} isError={isErrorWarunki} isBig={false} isCentered={false} loadingText={'Trwa ładowanie warunków dostawy...'}>
                                    <KoszykOrderForm warunkiDostawy={warunkiDostawy}
                                                     wartoscKoszyka={wartoscKoszyka}
                                                     czyZablokowacPrzycisk={produktyWKoszyku.length === 0}
                                                     handleClickWyslijZamowienie={this.onClickWyslijZamowienie}
                                                     czyEdycja={czyEdycja}
                                                     uwagiRest={uwagiRest}
                                    />
                                    </TopLoading>
                                </>
                        }
                    {/*</TopLoading>*/}
                </div>
                { //odswiez={() => {this.pobierzWarunkiDostawy(); window.location.reload()}}
                    showImportPopup &&
                    <PopupWrapper header={'Import pozycji do koszyka'} shouldNotCloseWithoutClick={true}
                                  onClose={() => this.setState({showImportPopup: false})}>
                        <KoszykImportContainer onClose={() => this.setState({showImportPopup: false})} odswiez={() => {this.pobierzWarunkiDostawy(); this.setState({shouldKoszykUpdate: true});}} czyEdycja={czyEdycja} handleChangeQuantityInBasket={this.onChangeQuantityInBasket} />
                    </PopupWrapper>
                }

                <Modal show={showModalPoTerminie} onHide={() => console.log('onHide')} size={'lg'}>
                    <Modal.Header>
                        <Modal.Title>Uwaga! Wygasły niektóre promocje</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={'modalPoTerminie'}>
                        Na niektórych produktach znajdujących się w koszyku, promocja została zakończona.
                        {/*Cena sprzedaży zostanie zmieniona na ogólną lub zostanie usunięty gratis.*/}

                        <br/><br/>

                        {(koszykWygasle.filter((g: any) => g.typ === 'G').length > 0) &&
                        <>
                            <h5>Gratisy które zostaną usunięte z koszyka: </h5>
                            <ul style={{paddingLeft: 0, marginBottom: '15px'}}>
                                {koszykWygasle.filter((g: any) => g.typ === 'G').map((p: any) => {
                                    return <li style={{listStyleType: 'none'}}>
                                        <i className="fa fa-gift" style={{fontSize: '20px', color: '#F9BD0A'}}
                                           title={'Gratis'}/> {p.nazwa}
                                    </li>
                                })}
                            </ul>
                        </>
                        }

                        <h5>Produkty powiązane z promocjami: </h5>
                        <Table striped bordered style={{marginBottom: '15px'}}>
                            <thead>
                            <tr>
                                <th>Indeks</th>
                                <th>Nazwa</th>
                                <th>Cena poprzednia</th>
                                <th>Cena obecnie</th>
                                <th>Ilość z zamówienia</th>
                                <th>Akcja</th>
                            </tr>
                            </thead>
                            <tbody>
                                {koszykWygasle.filter((g: any) => g.typ != 'G').map((p: any) => {
                                    return <tr>
                                        <td>{p.indeks}</td>
                                        <td>{p.nazwa}</td>
                                        <td><TableFormatters_formatMoney value={p.cena}/></td>
                                        <td><TableFormatters_formatMoney value={p.cenaAktualna}/></td>
                                        <td><IloscIJednostka item={p} fieldForAmount={'koszykIlosc'} fieldForJM={'zakupJm'}/></td>
                                        <td style={{textAlign: 'center'}}><Button variant="outline-danger" className={'btnUsunPozW'} size={'sm'} onClick={() => this.usunPozWyg(p.id)}>Usuń</Button></td>
                                    </tr>
                                })}
                            </tbody>
                        </Table>

                        Możesz teraz usunąć te pozycje z zamówienia lub zaktualizować ceny.

                    </Modal.Body>
                    <Modal.Footer style={{justifyContent: 'space-between'}}>
                        <Button variant="primary" onClick={() => this.pobierzProduktyWKoszyku(this.state.page, this.state.size, 1)}>
                            Usuń produkty
                        </Button>
                        <Button variant="success" onClick={() => this.pobierzProduktyWKoszyku(this.state.page, this.state.size, 2)}>
                            Aktualizuj ceny
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showModalWylaczone} onHide={() => this.setState({showModalWylaczone: false})} size={'lg'}>
                    <Modal.Header closeButton>
                        <Modal.Title>Uwaga! Wyłączone produkty</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={'modalPoTerminie'}>
                        Niektóre produkty w twoim koszyku zostały wyłączone i zakończyła się ich sprzedaż.

                        <br/><br/>

                        <h5>Z twojego koszyka zostały usunięte następujące produkty: </h5>
                        <Table striped bordered style={{marginBottom: '15px'}}>
                            <thead>
                            <tr>
                                <th>Indeks</th>
                                <th>Nazwa</th>
                            </tr>
                            </thead>
                            <tbody>
                            {wylaczone.map((p: any) => {
                                return <tr>
                                    <td>{p.indeks}</td>
                                    <td>{p.nazwa}</td>
                                </tr>
                            })}
                            </tbody>
                        </Table>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" onClick={() => this.setState({showModalWylaczone: false})}>
                            OK
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showModalWyczyscKoszyk} onHide={() => this.setState({showModalWyczyscKoszyk: false})} size={'lg'}>
                    <Modal.Header closeButton>
                        <Modal.Title>Wyczyść koszyk</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Czy na pewno wyczyścić koszyk? Akcja ta spowoduje usunięcie wszystkich produków z koszyka.</Modal.Body>
                    <Modal.Footer style={{justifyContent: 'space-between'}}>
                        <Button variant="primary" onClick={() => this.setState({showModalWyczyscKoszyk: false})}>
                            Anuluj
                        </Button>
                        <Button variant="success" onClick={this.wyczyscKoszyk}>
                            Tak, wyczyść koszyk
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showModalDeletePromocje} onHide={() => this.setState({showModalDeletePromocje: false})}>
                    <Modal.Header closeButton>
                        <Modal.Title>Usuwanie promocji</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Kliknięcie przycisku usuń spowoduje usunięcie wszystkich pozycji powiązanych z tą promocją. Czy napewno usunąć?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.setState({showModalDeletePromocje: false})}>
                            Anuluj
                        </Button>
                        <Button variant="primary" onClick={() => {this.usunPozycje(this.state.delRow);; this.setState({showModalDeletePromocje: false})}}>
                            Tak, usuń promocje z koszyka
                        </Button>
                    </Modal.Footer>
                </Modal>
            </LayoutComponent>
        );
    }
}

export default KoszykModule;
