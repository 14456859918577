import React, {Component, useEffect, useState} from 'react';
import {displayNotificationError, displayNotificationInfo} from "../../../utilities/notifications/NotificationsManager";
import {Button, Col, Form, FormGroup, InputGroup, Row} from "react-bootstrap";
import {IconCartPlus, IconRefresh} from "../../icons/FontAwesome";

// @ts-ignore
import InputNumber from 'rc-input-number'
import 'rc-input-number/assets/index.css';
import {set} from "lodash";


export interface Props {
    item: any;
    odswiezIloscLokalnie: any;
    handleChangeInBasket: any;
}

const MIN = 0;
const MAX = 9999;

const Shared_ProductAddToBasketForm_function: React.FC<Props> = ({
    item,
    odswiezIloscLokalnie,
    handleChangeInBasket
}) => {

    const [itemprops, setItem]: any = useState([]);
    const [amount, setAmount] = useState(0);
    const [nowa, setNowa]: any = useState(null);

    useEffect(() => {
        console.log('effect')

        if(nowa === null){
            setAmount(item.koszykIlosc);
        }

        setItem(item)
    })

    function onChangeAmount(nowa: number) {
        setNowa(nowa);
        setAmount(nowa);
    }

    function onPressEnter(e: any) {
        e.preventDefault();
        if (nowa !== null)
            aktualizujZamowienie(true);
    }

    function aktualizujZamowienie(czyWyslanoEnterem = false) {

        console.log(czyWyslanoEnterem)

        let amountNowa = nowa;
        // let {item} = this.props;
        let czyPoprawne = true;
        let czyPoprawnaIloscSztuk = true;

        let zaokraglonaAmount: any;


        //@ts-ignore
        if (isNaN(amountNowa) || amountNowa === '' || amountNowa === null) {
            //czyPoprawne = false;
            // @ts-ignore
            amountNowa = 0;
        } else {

            if (amountNowa < MIN || amountNowa > MAX) {
                czyPoprawne = false
            }

            if (itemprops.jm.indexOf('KG') === -1) {
                // @ts-ignore
                if (amountNowa.toString().indexOf('.') > -1 || amountNowa.toString().indexOf(',') > -1) {
                    czyPoprawne = false
                }
            }

            if (itemprops.jm === 'KG' || itemprops.jm2 === 'DIS') {
                zaokraglonaAmount = amountNowa
            }
        }

        // zaokraglenie dla wartosci wpisywanej z palca. Np produkt jest pakowany po 12 sztuk, wiec mozna kupic tylko wielokrotnosc 12 sztuk: 12, 24, 36 itd. Dodać ograniczenie zaokraglajace, np jak uzytkownik wpisze 40 to aplikacja przyjmie 36 i wyswietli odpowiedni komunikat

        if (czyPoprawne) {
            let previousAmount = itemprops.koszykIlosc;
            if (previousAmount === null || previousAmount === undefined || previousAmount === '')
                previousAmount = 0;

            console.log('wchodzi czyPoprawne')

            if (!czyPoprawnaIloscSztuk) {

                console.log('wchodzi !czyPoprawnaIloscSztuk')

                //@ts-ignore
                if (Boolean(document.getElementById('input-' + item.indeks))) {
                    //@ts-ignore
                    document.getElementById('input-' + item.indeks).value = zaokraglonaAmount;
                }


                displayNotificationInfo(`Zaokrąglono podaną ilość produktu`, `Ilość produktu w tej jednostce musi być wielokrotnością liczby ${(item.jm === item.jm2) ? item.ilJm3 : item.ilJm2}. Do koszyka dodano zaokrągloną ilość.`)


                handleChangeInBasket(previousAmount, zaokraglonaAmount, itemprops, itemprops.zakupJm);
                // setNowa(null);
                // setAmount(zaokraglonaAmount);

                // this.forceUpdate(() => {
                //
                //     this.setState({amount: zaokraglonaAmount});
                //
                //     //@ts-ignore
                //     if (Boolean(document.getElementById('input-' + item.indeks))) {
                //         //@ts-ignore
                //         document.getElementById('input-' + item.indeks).value = zaokraglonaAmount;
                //     }
                // })

            } else {
                //alert(previousAmount+' - '+amount)
                handleChangeInBasket(previousAmount, amountNowa, itemprops, itemprops.zakupJm);
                // setNowa(null);
                // alert(amountNowa)
                setAmount(amountNowa);
            }
        } else {
            displayNotificationError('Błąd dodania produktu do koszyka', 'Podano nieprawidłową ilość produktu')
        }


    }

    function onBlurInput(inline: boolean, showButton: boolean, item: any) {
        console.log('onBlurInput')
        console.log('Po aktualizacji:')
        console.log('koszyk: ' + itemprops.koszyk)
        console.log('koszykIlosc: ' + itemprops.koszykIlosc)
        // if (this.state.czyZaokraglonoIWyslanoEnterem) {
        //     this.setState({czyZaokraglonoIWyslanoEnterem: false})
        // } else {

            if (nowa !== null) {
                aktualizujZamowienie()
            }

        // }
    }

    function onClickInput(){
        //console.log('test click ' + this.state.amount)
        // let {item} = this.props;
        // let {amount} = this.state;

        if (amount === 0) {
            //@ts-ignore
            if (Boolean(document.getElementById('input-' + item.indeks))) {
                //@ts-ignore
                document.getElementById('input-' + item.indeks).value = '';
            }
        }
    }


    return (
        <div className={'ProductAddToBasketForm'}>

            <Form inline={true}>
                <FormGroup>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <div data-tip data-for={"jm" + item.indeks}>
                                <InputGroup.Text
                                    // onClick={() => this.setState({selectJm: !selectJm})}
                                    className={'inputgroup-prepend-text noedit'}>{item.zakupJm}
                                    {/*className={'inputgroup-prepend-text'}>{zakupOpakowanieTyp.jm}*/}
                                </InputGroup.Text>
                            </div>

                        </InputGroup.Prepend>
                        <InputNumber
                            value={amount}
                            onChange={onChangeAmount}
                            disabled={false}
                            precision={!(itemprops.zakupJm === 'SZT') ? 0 : (itemprops.jm.indexOf('KG')) > -1 ? 2 : 0}
                            step={(itemprops.zakupJm === 'SZT') ? 1 : (itemprops.jm === itemprops.jm2) ? itemprops.ilJm3 : itemprops.ilJm2}
                            min={MIN} max={MAX}
                            onPressEnter={onPressEnter}
                            id={'input-' + item.indeks}
                            onBlur={() => onBlurInput(true, false, itemprops)}
                            onClick={onClickInput}
                        />
                        {/*<InputNumber*/}
                        {/*    value={(nowa !== null) ? nowa : amount}*/}
                        {/*    onChange={this.onChangeAmount}*/}
                        {/*    disabled={(alwaysDostepne) ? false : !item.dostepne}*/}
                        {/*    precision={!(zakupOpakowanieTyp.value === 'SZT') ? 0 : (product.jm.indexOf('KG')) > -1 ? 2 : 0}*/}
                        {/*    step={!(zakupOpakowanieTyp.value === 'SZT') ? 1 : (product.jm === product.jm2) ? product.ilJm3 : product.ilJm2}*/}
                        {/*    min={MIN} max={MAX}*/}
                        {/*    onPressEnter={this.onPressEnter}*/}
                        {/*    id={'input-' + item.indeks}*/}
                        {/*    onBlur={() => this.onBlurInput(inline, showButton, item)}*/}
                        {/*    onClick={onClickInput}*/}
                        {/*/>*/}
                    </InputGroup>
                </FormGroup>
            </Form>

        </div>
    );
}

export default Shared_ProductAddToBasketForm_function;