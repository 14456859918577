import React from 'react';
import {formatMoney} from "../../utilities/formatters/formatMoney";
import {IconCheck, IconMultistar, IconPromo, IconTimes} from "../icons/FontAwesome";

interface TableFormatters_formatMoneyProps {
    value: number
}

export const TableFormatters_formatMoney: React.FC<TableFormatters_formatMoneyProps> = ({value}) => {
    return (
        <div className={"text-center"}>{formatMoney(value)}</div>
    );
};


interface TableFormatters_formatPromocjaProps {
    value: any
}

export const TableFormatters_formatPromocja: React.FC<TableFormatters_formatPromocjaProps> = ({value}) => {
    return (<div className={'TableFormatters PromocjaTable'} title={'Promocja na ten produkt'}><IconPromo/></div>)
};


interface TableFormatters_formatBooleanProps {
    value: boolean,
    titleTrue: string,
    titleFalse: string,
    classnameTrue?: string,
    classnameFalse?: string
}

export const TableFormatters_formatBoolean: React.FC<TableFormatters_formatBooleanProps> = ({value, titleTrue, titleFalse, classnameTrue = "text-success", classnameFalse = 'text-danger'}) => {
    if (value) return <div className={'TableFormatters BooleanTable ' + classnameTrue} title={titleTrue}><IconCheck/></div>
    else return <div className={'TableFormatters BooleanTable ' + classnameFalse} title={titleFalse}><IconTimes/></div>
};


