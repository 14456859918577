import React from 'react';
import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {isMobileDevice} from "../../../utilities/rwd/rwdUtilities";
import {IconCheck, IconExternalLink, IconTimes} from "../../../sharedComponents/icons/FontAwesome";
import {formatMoney} from "../../../utilities/formatters/formatMoney";
import {
    Icon_Check_success,
    Icon_Exclamation_danger,
    Icon_Exclamation_warning
} from "../../../sharedComponents/icons/IconsWithColor";
import {TextAlert} from "../../../sharedComponents/typographyComponents/TextAlert/TextAlert";

interface Props {
    pozycje: any
}

const ZamowieniaPozycjePopup: React.FC<Props> = ({pozycje}) => {

    const renderIleZamowionoIleWydanoRow = (ileZamowiono: number, ileWydano: number, jednostkaMiara: string) => {
        let icon: any = '';
        let tooltip = '';

        if (ileZamowiono === ileWydano) {
            icon = <Icon_Check_success/>
            tooltip = 'Wydano pełną ilość zamówionego towaru'
        } else if (ileWydano === 0) {
            icon = <Icon_Exclamation_danger/>
            tooltip = 'Nie wydano zamówionego towaru'
        } else {
            icon = <Icon_Exclamation_warning/>
            tooltip = 'Wydano niepełną ilość zamówionego towaru'
        }

        return (
            <>
                {
                    isMobileDevice() &&
                    <label>Ile zamówiono/ zrealizowano:</label>
                }
                <span title={tooltip}>{ileWydano}/{ileZamowiono} {jednostkaMiara} {icon}</span>
            </>
        )

    };

    return (
        <div id={'ZamowieniaPozycjePopup'}>
            {
                pozycje.length > 0 &&
                <div className={'pozycja-row'}>
                    <Row>
                        <Col md={2} lg={1} xl={1}>
                            Pozycja:
                        </Col>
                        <Col md={3} lg={4} xl={4}>
                            Informacje o produkcie:
                        </Col>
                        <Col md={3} lg={2} xl={2}>
                            Ile zamów. / ile zrealiz.:
                        </Col>
                        <Col md={1} lg={1} xl={1}>
                            Zamknięte?
                        </Col>
                        <Col md={3} lg={3} xl={3}>
                            Wystawiono:
                        </Col>
                    </Row>
                </div>
            }
            {
                pozycje.map((pozycja: any) => (
                    <div className={'pozycja-row'}>
                        <Row>
                            <Col md={2} lg={1} xl={1}>
                                # {pozycja.poz}
                            </Col>
                            <Col md={3} lg={4} xl={4}>
                                <div>
                                    <Link to={'/oferta/'+pozycja.indeks} target={'_blank'}>
                                        {pozycja.indeks} <IconExternalLink/>
                                    </Link>
                                </div>
                                <h6>
                                    {pozycja.producent}, {pozycja.nazwa}
                                </h6>
                                <div>
                                    {
                                        isMobileDevice() &&
                                        <label>Cena:</label>
                                    }
                                    <span>{formatMoney(pozycja.cena)}/{pozycja.jm}</span>
                                </div>
                            </Col>
                            <Col md={3} lg={2} xl={2}>
                                <div className={'bigger-lineheight'}>
                                    {
                                        renderIleZamowionoIleWydanoRow(pozycja.zamowiono, pozycja.wydano, pozycja.jm)
                                    }
                                </div>
                            </Col>
                            <Col md={1} lg={1} xl={1}>
                                <div className={'bigger-lineheight'}>
                                    {
                                        isMobileDevice() &&
                                        <label>Zamknięte?:</label>
                                    }
                                    {
                                        pozycja.zamkniete ?
                                            <span className={'text-success text-bold'}><IconCheck/> tak</span>
                                            :
                                            <span className={'text-grey text-bold'}><IconTimes/> nie</span>
                                    }
                                </div>
                            </Col>
                            <Col md={3} lg={3} xl={3}>
                                <div>
                                    {
                                        isMobileDevice() &&
                                        <label>Wystawiono:</label>
                                    }
                                    <span>
                                        {pozycja.dataZam}
                                    </span>
                                </div>
                                <div>
                                    <label>Przyjął: </label>
                                    <span>{pozycja.przyjal}</span>
                                </div>
                            </Col>
                        </Row>
                    </div>
                ))
            }
            {
                pozycje.length === 0 &&
                <TextAlert type={'info'}>Brak pozycji na zamówieniu</TextAlert>
            }
        </div>
    );
};

export default ZamowieniaPozycjePopup;
