import React, {Component} from 'react';
import MiddleCenterCardWrapper from "../../../sharedComponents/wrappers/MiddleCenterCardWrapper";
import {Button, Form, FormControl, FormGroup} from "react-bootstrap";
import {IconKey, IconRefresh} from "../../../sharedComponents/icons/FontAwesome";
import {displayNotificationError, displayNotificationSuccess} from "../../../utilities/notifications/NotificationsManager";
import {regexp_password} from "../../../utilities/regexps/regexps";
import TopLoading from "../../../sharedComponents/loader/TopLoading";
import PublicKlientService from "../../../services/PublicKlientService";

interface MatchParams {
    name: string;
}

export interface RouteComponentProps<P> {
}

interface Props extends RouteComponentProps<MatchParams> {
    match: any
}

class NoweHasloModule extends Component {

    state = {
        displayWskazowkaDoSMS: false,
        displayWskazowkaDoHasla: false,
        sms: '',
        haslo: '',
        hasloPowtorzone: '',
        czyWyslano: false,
        isLoading: false,
        isError: false
    };

    componentDidMount() {
        //@ts-ignore
        let czyJestToken = false;
        let {match}: any = this.props;
        if (Boolean(match))
            if (Boolean(match.params))
                if (Boolean(match.params.token))
                    czyJestToken = true

        if (!czyJestToken)
            displayNotificationError('Wystąpił błąd uniemożliwiający operację zmiany hasła', 'Brakuje tokena przekazanego z wiadomości email. Proszę ponowić próbę')
    }

    onChangeSms = (e: any) => {
        this.setState({sms: e.target.value});
    };

    onChangeHaslo = (e: any) => {
        this.setState({haslo: e.target.value})
    };

    onChangePowtorzHaslo = (e: any) => {
        this.setState({hasloPowtorzone: e.target.value})
    };

    onClickZapisz = () => {


        let {match}: any = this.props;
        if (Boolean(match))
            if (Boolean(match.params))
                if (Boolean(match.params.token))

                    if (Boolean(this.state.haslo) && Boolean(this.state.hasloPowtorzone)) {
                        if (regexp_password.test(this.state.haslo)) {
                            if (this.state.haslo === this.state.hasloPowtorzone) {

                                let obj = {
                                    "emailToken": match.params.token,
                                    "newPassword": this.state.haslo,
                                    "smsToken": this.state.sms
                                };
                                this.wyslijZmienioneHaslo(obj)

                            } else {
                                displayNotificationError("Podane hasła nie są identyczne", '')
                            }
                        } else {
                            displayNotificationError('Podane hasło nie spełnia wymagań', "Hasło musi posiadać co najmniej 8 znaków i powinno zawierać: małą literę, wielką literę, cyfrę, znak specjalny. Dozwolone znaki specjalne: ! @ # $ % ^ &")
                        }
                    } else {
                        displayNotificationError("Proszę uzupełnić wszystkie pola", '')
                    }

    };

    wyslijZmienioneHaslo = (obj: any) => {

        this.setState({
            czyWyslano: true,
            isLoading: true,
            isError: false
        });

        PublicKlientService.postUstawNoweHaslo(obj)
            .then((response: any) => {
                if (response && response.status < 300) {
                    this.setState({
                        isLoading: false,
                        isError: false
                    });
                    displayNotificationSuccess("Pomyślnie zmieniono hasło", "Za 3 sekundy nastąpi przekierowanie do panelu logowania")
                    setTimeout(() => {
                        //@ts-ignore
                        this.props.history.push('/')
                    }, 3000)
                } else {
                    this.setState({
                        isLoading: false,
                        isError: true
                    });
                    displayNotificationError('Wystąpił błąd podczas próby zmiany hasła', "Podano nieprawidłowe dane")
                }
            })
            .catch((error: any) => {
                this.setState({
                    isLoading: false,
                    isError: true
                });
                displayNotificationError('Wystąpił błąd podczas próby zmiany hasła', error.message)
            });
    };

    render() {

        let {displayWskazowkaDoSMS, displayWskazowkaDoHasla, czyWyslano, isError, isLoading} = this.state;

        return (
            <div id={'NOWE_HASLO_MODULE'}>
                <MiddleCenterCardWrapper>
                    {
                        czyWyslano ?
                            <div id={'po-wyslaniu'}>
                                <TopLoading isLoading={isLoading} isError={isError} loadingText={'Trwa przetwarzanie...'}
                                            errorMessage={'Wystąpił błąd podczas przetwarzania żądania. Proszę spróbować ponownie'}>
                                    <div id={'success'}>
                                        <h4>
                                            <IconKey/> <span>Pomyślnie zmieniono hasło</span>
                                        </h4>
                                        <h6>
                                            Pomyślnie zmieniono hasło. Nastąpi automatyczne przekierowanie do logowania za 3 sekundy.
                                        </h6>
                                    </div>
                                </TopLoading>
                                {
                                    isError &&
                                    <a onClick={()=>{
                                        this.setState({
                                            sms: '',
                                            haslo: '',
                                            hasloPowtorzone: '',
                                            czyWyslano: false
                                        });
                                    }}><IconRefresh/> Spróbuj ponownie</a>
                                }
                            </div>
                            :
                            <Form>
                                {/*<FormGroup>*/}
                                {/*    <Form.Label>Kod SMS:</Form.Label>*/}
                                {/*    <FormControl*/}
                                {/*        onChange={this.onChangeSms}*/}
                                {/*        autoFocus*/}
                                {/*        onFocus={() => this.setState({displayWskazowkaDoSMS: true})}*/}
                                {/*        onBlur={() => this.setState({displayWskazowkaDoSMS: false})}*/}
                                {/*    />*/}
                                {/*</FormGroup>*/}
                                <h4 style={{textAlign: 'center', color: '#f9ba00'}}>Zmiana hasła</h4>
                                <FormGroup>
                                    <Form.Label>Hasło:</Form.Label>
                                    <FormControl
                                        onChange={this.onChangeHaslo}
                                        onFocus={() => this.setState({displayWskazowkaDoHasla: true})}
                                        onBlur={() => this.setState({displayWskazowkaDoHasla: false})}
                                        type={'password'}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Form.Label>Powtórz hasło:</Form.Label>
                                    <FormControl
                                        onChange={this.onChangePowtorzHaslo}
                                        onFocus={() => this.setState({displayWskazowkaDoHasla: true})}
                                        onBlur={() => this.setState({displayWskazowkaDoHasla: false})}
                                        type={'password'}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Button variant={'primary'}
                                            block={true}
                                            onClick={this.onClickZapisz}
                                    >
                                        <IconKey/> Zmień hasło
                                    </Button>
                                </FormGroup>
                                {
                                    displayWskazowkaDoSMS &&
                                    <FormGroup>
                                        <h6>
                                            Kod SMS jest 6-cio cyfrowym numerem i został wysłany na numer telefonu powiązany z kontem, dla którego zmieniane jest hasło.
                                        </h6>
                                    </FormGroup>
                                }
                                {
                                    displayWskazowkaDoHasla &&
                                    <FormGroup>
                                        <h6>
                                            Hasło musi posiadać co najmniej 8 znaków i powinno zawierać: małą literę, wielką literę, cyfrę, znak specjalny. Dozwolone znaki specjalne: ! @ # $ % ^ &
                                        </h6>
                                    </FormGroup>
                                }
                            </Form>
                    }
                </MiddleCenterCardWrapper>
            </div>
        );
    }
}

export default NoweHasloModule;