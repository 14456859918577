import React, {Component} from 'react';
import TopCard from "../../../../sharedComponents/TopCard";
import {Form, Row, Col, FormGroup, FormControl, ButtonToolbar, Button} from "react-bootstrap";
import Req from "../../../../sharedComponents/formComponents/Req";
import AddFormMargin from "../../../../sharedComponents/formComponents/AddFormMargin";
import {IconSave, IconTimes} from "../../../../sharedComponents/icons/FontAwesome";
import {displayNotificationError, displayNotificationInfo} from "../../../../utilities/notifications/NotificationsManager";
import Select from "react-select";
import {SmallerSelectStyles} from "../../../../utilities/forms/jsStyles";
import AsyncSelect from 'react-select/async';
import AdminService from "../../../../services/AdminService";
import {regexp_password} from "../../../../utilities/regexps/regexps";
import {TextAlert} from "../../../../sharedComponents/typographyComponents/TextAlert/TextAlert";
import {isSMMobileDevice} from "../../../../utilities/rwd/rwdUtilities";

interface Props {
    handleClickAnuluj: () => void;
    handleClickZapisz: (klient:any) => void;
    itemEdycja?: any
}

class AdminKlienciForm extends Component<Props> {

    state = {
        role: [
            {value: 'Admin', label: 'Admin'},
            {value: 'Klient', label: 'Klient'},
            {value: 'Klientwlasciciel', label: 'Klient - właściciel'},
        ],
        rola: {value: 'Klient', label: 'Klient'},
        kontrahent: null,

        klientModel: {
            "email": "",
            "haslo": "",
            "login": "",
            "telefon": "",
            "nazwaTop": "",
            "pracownik": "",
            "rolaKlientPracownik": 1,
            "rolaKlientWlasciciel": 0,
            "rolaSystemAdmin": 0,
            "zgodaEmail": 0,
            "zgodaMob": 0,
            "aktywny": 1
        },

        edytujHaslo: false,

        pokazWskazowkeDoHasla: false,
        wskazowkaDoHaslaAlertColor: 'info'
    };

    componentDidMount(): void {
        if(this.props.itemEdycja)
        {
            let klient = {
                "email": this.props.itemEdycja.email,
                "haslo": "",
                "login": this.props.itemEdycja.login,
                "telefon": this.props.itemEdycja.telefon,
                "nazwaTop": this.props.itemEdycja.kontrahent,
                "pracownik": this.props.itemEdycja.pracownik,
                "rolaKlientPracownik": this.props.itemEdycja.roleKlientPracownik,
                "rolaKlientWlasciciel": this.props.itemEdycja.roleKlientWlasciciel,
                "rolaSystemAdmin": this.props.itemEdycja.roleSystemAdmin,
                "zgodaEmail": this.props.itemEdycja.zgodaEmail,
                "zgodaMob": this.props.itemEdycja.zgodaMob,
                "aktywny": this.props.itemEdycja.aktywny,
                "id": this.props.itemEdycja.id
            };

            this.ustawRole(klient);

            this.setState({klientModel: klient, kontrahent: {nazwa: klient.nazwaTop}});
        }
    }

    loadKontrahentOptions = (inputValue: string, callback: any) => {
        if (inputValue.length >= 3)

            AdminService.getKontrahenci(inputValue.toUpperCase())
                .then((response) => {
                    if (response && response.status < 300)
                        callback(response.data)
                    else displayNotificationError('Nie można pobrać kontrahentów', '')
                })
                .catch((error) => {
                    displayNotificationError('Nie można pobrać kontrahentów', '')
                })

    };

    ustawRole = (klient: any) => {
        if(klient.rolaKlientPracownik){
            this.setState({rola: {value: 'Klient', label: 'Klient'}})
        } else if(klient.rolaKlientWlasciciel){
            this.setState({rola: {value: 'Klientwlasciciel', label: 'Klient - właściciel'}})
        } else if(klient.rolaSystemAdmin){
            this.setState({rola: {value: 'Admin', label: 'Admin'}})
        } else {
            this.setState({rola: {value: 'Klient', label: 'Klient'}})
        }
    }

    onChangeRola = (rola: any) => {
        this.setState({rola});

        if (rola.value === 'Klient') {
            this.onChangeInput('rolaKlientPracownik', 1);
            this.onChangeInput('rolaKlientWlasciciel', 0);
            this.onChangeInput('rolaSystemAdmin', 0);
        }
        if (rola.value === 'Klientwlasciciel') {
            this.onChangeInput('rolaKlientPracownik', 0);
            this.onChangeInput('rolaKlientWlasciciel', 1);
            this.onChangeInput('rolaSystemAdmin', 0);
        }
        if (rola.value === 'Admin') {
            this.onChangeInput('rolaKlientPracownik', 0);
            this.onChangeInput('rolaKlientWlasciciel', 0);
            this.onChangeInput('rolaSystemAdmin', 1);
        }
    };

    onChangeInput = (key: string, value: any) => {
        let {klientModel}: any = this.state;
        klientModel[key] = value;
        this.setState({klientModel});
    };

    onClickZapisz = () => {
        let isOk = true;
        let {klientModel}: any = this.state;
        let {itemEdycja} = this.props;

        if (!Boolean(this.state.kontrahent)) {
            isOk = false;
            displayNotificationError('Nie wybrano kontrahenta Top', '')
        }
        if (!Boolean(klientModel.login)) {
            isOk = false;
            displayNotificationError('Nie podano loginu', '')
        }
        if (!Boolean(klientModel.haslo) && !this.props.itemEdycja) {
            isOk = false;
            displayNotificationError('Nie podano hasła', '')
        } else {
            if (!regexp_password.test(klientModel.haslo) && !this.props.itemEdycja) {
                isOk = false;
                displayNotificationError('Podane hasło nie spełnia wymagań', 'Hasło musi posiadać co najmniej 8 znaków i powinno zawierać: małą literę, wielką literę, cyfrę, znak specjalny. Dozwolone znaki specjalne: ! @ # $ % ^ &')
            }
        }

        if(this.props.itemEdycja && this.state.edytujHaslo)
        {
            if (!regexp_password.test(klientModel.haslo)) {
                isOk = false;
                displayNotificationError('Podane hasło nie spełnia wymagań', 'Hasło musi posiadać co najmniej 8 znaków i powinno zawierać: małą literę, wielką literę, cyfrę, znak specjalny. Dozwolone znaki specjalne: ! @ # $ % ^ &')
            }
        }

        if (isOk) {
            this.props.handleClickZapisz(klientModel)
        }

    };

    render() {

        let {rola, kontrahent, pokazWskazowkeDoHasla, wskazowkaDoHaslaAlertColor, klientModel, edytujHaslo} = this.state;
        let {itemEdycja} = this.props;

        return (
            <div id={'AdminKlienciForm'}>
                <TopCard heading={itemEdycja? 'Edytuj klienta' : 'Dodaj'} hasPadding={true}>
                    <Form>
                        <Row>
                            <Col md={6} lg={3} xl={4}>
                                <FormGroup>
                                    <Form.Label>Kontrahent TOP:<Req/></Form.Label>
                                    <AsyncSelect
                                        getOptionLabel={({nazwa}: any) => nazwa}
                                        getOptionValue={({nazwa}: any) => nazwa}
                                        cacheOptions
                                        loadOptions={this.loadKontrahentOptions}
                                        value={kontrahent}
                                        isDisabled={itemEdycja}
                                        onChange={(kontrahent) => {
                                            this.setState({kontrahent})
                                            this.onChangeInput('nazwaTop', kontrahent.nazwa)
                                        }}
                                        styles={SmallerSelectStyles}
                                        placeholder={'Wybierz...'}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6} lg={3} xl={2}>
                                <FormGroup>
                                    <Form.Label>Login:<Req/></Form.Label>
                                    <FormControl size={'sm'} value={klientModel.login} onChange={(e: any) => this.onChangeInput('login', e.target.value)}/>
                                </FormGroup>
                            </Col>
                            <Col md={6} lg={3} xl={2}>
                                <FormGroup>
                                    <Form.Label>
                                        {itemEdycja &&
                                        <Form.Check inline={true} type="checkbox"
                                                    style={{fontSize: '0.75em', height: 'unset', marginRight: '3px'}}
                                                    checked={edytujHaslo}
                                                    onClick={(e: any) => {this.setState({edytujHaslo: e.target.checked}); this.onChangeInput('haslo', '');}}
                                                    // title={'Zaznacz jeśli chcesz wprowadzić nowe hasło'}
                                        />
                                        }
                                        Hasło:<Req/></Form.Label>
                                    <FormControl size={'sm'} type={'password'} onChange={(e: any) => this.onChangeInput('haslo', e.target.value)}
                                                 onFocus={() => this.setState({wskazowkaDoHaslaAlertColor: 'info', pokazWskazowkeDoHasla: true})}
                                                 disabled={itemEdycja && !edytujHaslo}
                                                 value={klientModel.haslo}
                                                 onBlur={(e: any) => {
                                                     if (!regexp_password.test(e.target.value)) {
                                                         this.setState({wskazowkaDoHaslaAlertColor: 'danger'});
                                                     } else {
                                                         this.setState({wskazowkaDoHaslaAlertColor: 'success'}, () => setTimeout(() => this.setState({pokazWskazowkeDoHasla: false}), 1500));
                                                     }
                                                 }}
                                    />
                                </FormGroup>
                                {
                                    (pokazWskazowkeDoHasla && isSMMobileDevice()) &&
                                    <FormGroup>
                                        <TextAlert
                                            type={wskazowkaDoHaslaAlertColor}>
                                            Hasło musi posiadać co najmniej 8 znaków i powinno zawierać: małą literę, wielką literę, cyfrę, znak specjalny. Dozwolone znaki
                                            specjalne: ! @ # $ % ^ &
                                        </TextAlert>
                                    </FormGroup>
                                }
                            </Col>
                            <Col md={3} lg={2} xl={2}>
                                <FormGroup>
                                    <Form.Label>Rola:<Req/></Form.Label>
                                    <Select
                                        options={this.state.role}
                                        value={rola}
                                        onChange={this.onChangeRola}
                                        isClearable={false}
                                        styles={SmallerSelectStyles}
                                        placeholder={'Wybierz...'}
                                        isSearchable={true}
                                    />
                                </FormGroup>
                            </Col>
                            {/*<Col md={3} lg={2} xl={2}>
                                <FormGroup>
                                    <AddFormMargin hideForMobileDevices767={true}/>
                                    <Form.Check inline={true} type="checkbox" label="Aktywny"/>
                                </FormGroup>
                            </Col>*/}
                        {/*</Row>*/}
                        {/*{*/}
                        {/*    (pokazWskazowkeDoHasla && !isSMMobileDevice()) &&*/}
                        {/*    <Row>*/}
                        {/*        <Col>*/}
                        {/*            <FormGroup>*/}
                        {/*                <TextAlert type={wskazowkaDoHaslaAlertColor}>*/}
                        {/*                    Hasło musi posiadać co najmniej 8 znaków i powinno zawierać: małą literę, wielką literę, cyfrę, znak specjalny. Dozwolone znaki*/}
                        {/*                    specjalne: !*/}
                        {/*                    @ # $ % ^ &*/}
                        {/*                </TextAlert>*/}
                        {/*            </FormGroup>*/}
                        {/*        </Col>*/}
                        {/*    </Row>*/}
                        {/*}*/}
                        {/*<Row>*/}
                            <Col md={6} lg={3} xl={4}>
                                <FormGroup>
                                    <Form.Label>Pracownik:</Form.Label>
                                    <FormControl size={'sm'} value={klientModel.pracownik} onChange={(e: any) => this.onChangeInput('pracownik', e.target.value)}/>
                                </FormGroup>
                            </Col>
                            <Col md={6} lg={3} xl={3}>
                                <FormGroup>
                                    <Form.Label>Email:</Form.Label>
                                    <FormControl size={'sm'} value={klientModel.email} onChange={(e: any) => this.onChangeInput('email', e.target.value)}/>
                                </FormGroup>
                            </Col>
                            <Col md={6} lg={3} xl={3}>
                                <FormGroup>
                                    <Form.Label>Telefon:</Form.Label>
                                    <FormControl size={'sm'} value={klientModel.telefon} onChange={(e: any) => this.onChangeInput('telefon', e.target.value)}/>
                                </FormGroup>
                            </Col>
                            {/*<Col md={6} lg={3} xl={2}>
                                <FormGroup>
                                    <Form.Label>Telefon:</Form.Label>
                                    <FormControl size={'sm'}/>
                                </FormGroup>
                            </Col>*/}

                        </Row>
                        <Row>
                            <Col md={6} lg={3} xl={{ span: 4, offset: 0 }}>
                                <Form.Check inline={true} type="checkbox"
                                            label="Czy aktywny?"
                                            checked={!!klientModel.aktywny}
                                            onClick={(e: any) => this.onChangeInput('aktywny', e.target.checked? 1 : 0)}
                                    // id={'czyPokazywacPonownieCheckbox'}
                                />
                                <Form.Check inline={true} type="checkbox"
                                            label="Zgoda e-mail"
                                            checked={!!klientModel.zgodaEmail}
                                            onClick={(e: any) => this.onChangeInput('zgodaEmail', e.target.checked? 1 : 0)}
                                            // id={'czyPokazywacPonownieCheckbox'}
                                />
                                <Form.Check inline={true} type="checkbox"
                                            label="Zgoda mobilne"
                                            checked={!!klientModel.zgodaMob}
                                            onClick={(e: any) => this.onChangeInput('zgodaMob', e.target.checked? 1 : 0)}
                                            // id={'czyPokazywacPonownieCheckbox'}
                                />
                            </Col>
                            <Col md={6} lg={3} xl={{ span: 2, offset: 6 }}>
                                <FormGroup>
                                    <AddFormMargin/>
                                    <ButtonToolbar>
                                        <Button variant={'light'} size={'sm'}
                                                onClick={() => {
                                                    this.props.handleClickAnuluj()
                                                }}><IconTimes/> Anuluj</Button>
                                        <Button variant={'primary'} size={'sm'}
                                                onClick={this.onClickZapisz}><IconSave/> Zapisz</Button>
                                    </ButtonToolbar>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </TopCard>
            </div>
        );
    }
}

export default AdminKlienciForm;