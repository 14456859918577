import React from 'react';
import {Button} from "react-bootstrap";
import {IconBars} from "../../icons/FontAwesome";

interface Props{
    handleToggleNavigation: any
}

const ToggleNavigationButton: React.FC<Props> = ({handleToggleNavigation}) => {
    return (
        <Button id={'toggleNavigationButton'} onClick={()=>handleToggleNavigation()} variant={'primary'} type={'button'}><IconBars/></Button>
    );
};

export default ToggleNavigationButton;