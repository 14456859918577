import React, {Component} from 'react';
// @ts-ignore
import overlayFactory from 'react-bootstrap-table2-overlay';
// @ts-ignore
import InputNumber from 'rc-input-number';
import {IconCartPlus, IconMultistar, IconRefresh} from "../../../sharedComponents/icons/FontAwesome";
import {Link} from "react-router-dom";
import Shared_ProductImage
    from "../../../sharedComponents/_reusable/ProductImageLazyLoadingWithErrorHandling/Shared_ProductImage";
import {famix_zaokraglijIloscDlaJM1} from "../../../utilities/famix_others/famix_operations";
import Shared_ProductAddToBasketForm
    from "../../../sharedComponents/_reusable/ProductAddToBasket/Shared_ProductAddToBasketForm";
import {formatMoney} from "../../../utilities/formatters/formatMoney";
import KoszykService from "../../../services/KoszykService";
import OfertaService from "../../../services/OfertaService";
import TopLoading from "../../../sharedComponents/loader/TopLoading";
import {Button, Form, FormGroup, InputGroup} from "react-bootstrap";
import {displayNotificationError, displayNotificationInfo} from "../../../utilities/notifications/NotificationsManager";
import PromocjeService from "../../../services/PromocjeService";
import moment from "moment";



interface Props {
    indeks: any,
    idUm: any,
    odswierzKoszyk?: () => void,
}

interface State {
    product: any,
    czyEdycja: boolean,
    isError: any,
    isLoading: boolean,
    amount: number,
    nowa: any,
    dodawanie: boolean,
    poTerminie: boolean,
    promocjaInfo: any
}

class PromocjaActiveLink extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            product: null,
            isError: null,
            czyEdycja: false,
            isLoading: false,
            amount: 0,
            nowa: null,
            dodawanie: false,
            poTerminie: false,
            promocjaInfo: []
        }
    }

    componentDidMount() {
        this.findProductByIndex(this.props.indeks, this.props.idUm);
        this.pobierzPodsumowanieKoszyka();
    }

    pobierzPodsumowanieKoszyka = () => {
        KoszykService.getStanKoszyka()
            .then((response: any) => {
                this.setState({
                    // stanKoszyka: response.data,
                    czyEdycja: (response.data.numer !== 0),
                    // edycjaSymbol: response.data.symbol,
                    // edycjaNumer: response.data.numer
                });
            })
            .catch((error: any) => {
                console.log('error');
            })
    };

    findProductByIndex = (index: any, idUm: any) => {

        this.setState({
            isLoading: true,
            isError: null
        });

        PromocjeService.getPromocjaInfo(idUm).then((response) => {
            this.setState({promocjaInfo: response.data});

            if(response.data.DATA_DO < moment().format('YYYY-MM-DD')){
                this.setState({poTerminie: true, isError: false, isLoading: false});
            } else {
                OfertaService.getOferta(0, 1, {indeks: index, idProm: Number(idUm), czyEdycja: this.state.czyEdycja})
                    .then((response: any) => {

                        this.setState({
                            product: response.data.content[0],
                            isLoading: false,
                            amount: response.data.content[0].koszykIlosc
                        });

                    })
                    .catch((error: any) => {
                        this.setState({isError: true, isLoading: false});
                    })
            }
        }).catch((error: any) => {
            this.setState({isError: true, isLoading: false});
        })

    };

    // onChangeIlosc = (e: any, index: number) => {
    //     let {komunikaty} = this.state;
    //
    //     // @ts-ignore
    //     komunikaty[index].komunikat.ilosc = e;
    //
    //     this.setState({komunikaty});
    // }

    onClickButtonDodaj = (item: any) => {

        let data = {
            indeks: item.indeks,
            ilosc: (this.state.nowa !== null)? this.state.nowa : this.state.amount,
            zakupJm: item.jm,
            idUm: Number(this.props.idUm)
        }

        if(this.state.nowa !== null)
        {
            this.setState({dodawanie: true});

            KoszykService.addEditKoszykActiveLink(data).then((response) => {

                if(data.ilosc !== 0){
                    displayNotificationInfo('Dodano produkt do koszyka', `Dodano produkt ${item.nazwa} w ilości ${data.ilosc} ${data.zakupJm}`);
                } else {
                    displayNotificationInfo('Usunięto produkt z koszyka', 'Usunięto produkt o nazwie ' + item.nazwa)
                }

                this.setState({dodawanie: false});
                // @ts-ignore
                this.props.odswierzKoszyk();
            }).catch((e) => {
                displayNotificationError("Nie dodano produktu do koszyka", "Wystąpił błąd podczas próby wykonania akcji");
                this.setState({dodawanie: false});
            })
        }

    }

    handleChangeInBasket = () => {

    }

    render() {

        let {product, isLoading, isError, dodawanie, amount, nowa, poTerminie, promocjaInfo} = this.state;

        return (
            <div className={'activeLinkBody'}>
                <div className={'product-container'}>
                    <TopLoading isLoading={isLoading} isError={isError}
                                errorMessage={'Wystąpił błąd z pobraniem karty produktu. Proszę sprawdzić poprawność adresu URL.'}>
                        {(poTerminie) ?
                            <div style={{textAlign: 'center'}}>
                                <h5>Promocja wygasła dnia <br/>{promocjaInfo.DATA_DO}</h5>
                                <p>Zapraszamy do skorzystania z innych <a href={'/promocje'}>promocji</a></p>
                            </div>
                            :
                            <div>
                            {(product) ?
                            <>
                                <div className={'product-image'}>
                                    {
                                        (Boolean(product.upustProcentowy) && product.upustProcentowy > 0) &&
                                        <div className={'product-promocja'}>
                                            <div className={'triangle'}></div>
                                            <div className={'inner'}>
                                                <IconMultistar/> Promocja
                                            </div>
                                        </div>
                                    }
                                    <div className={'product-cena'}>
                                        <div className={'triangle'}></div>
                                        <div className={'inner'}>
                                            <h1>
                                                {formatMoney(product.cena)}
                                            </h1>
                                            <h4>
                                                /{product.jm}
                                            </h4>
                                        </div>
                                    </div>
                                    {/*<Link to={'/oferta/' + product.indeks}>*/}
                                    <Shared_ProductImage item={product}/>
                                    {/*</Link>*/}
                                </div>
                                <div className={'product-description'}>
                                    <h3 title={product.producent + ', ' + product.nazwa}>
                                        {/*<Link to={'/oferta/' + product.indeks}>*/}
                                        {/* <OverflowTextWrapper text={item.producent + ', ' + item.nazwa}
                                                                     howManyLettersVisible={45}/>*/}
                                        {
                                            (product.producent + ', ' + product.nazwa).length > 45 ?
                                                (product.producent + ', ' + product.nazwa).substr(0, 42) + '...'
                                                :
                                                (product.producent + ', ' + product.nazwa)
                                        }
                                        {/*</Link>*/}
                                    </h3>

                                    <h6>
                                        Indeks: {product.indeks}
                                    </h6>
                                    <h6 style={{color: '#777'}}>
                                        EAN szt: {product.nrKat}
                                    </h6>
                                    {
                                        (product.jm !== product.jm2) ?
                                            <h6>{`W opakowaniu (${product.jm2}) : ${famix_zaokraglijIloscDlaJM1(product.jm, product.ilJm2)} ${product.jm}`}</h6>
                                            :
                                            <h6>{`W kartonie (${product.jm3}) : ${famix_zaokraglijIloscDlaJM1(product.jm, product.ilJm3)} ${product.jm}`}</h6>
                                    }
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    {/*<Shared_ProductAddToBasketForm item={product} fieldForAmount={'koszykIlosc'} czyZmieniacJm={false} alwaysDostepne={true} inline={false} showButton={false} showButtonLabel={true}*/}
                                    {/*handleChangeInBasket={this.handleChangeInBasket} showPriceInNotInline={false}/>*/}

                                    <Form inline={true}>
                                        <div>
                                            <InputGroup.Text
                                                // onClick={() => this.setState({selectJm: !selectJm})}
                                                className={'inputgroup-prepend-text'}>{product.zakupJm}
                                                {/*className={'inputgroup-prepend-text'}>{zakupOpakowanieTyp.jm}*/}
                                            </InputGroup.Text>
                                        </div>
                                        <FormGroup style={{maxWidth: '100px', marginRight: '5px'}}>
                                            <InputNumber
                                                value={(nowa !== null) ? nowa : amount}
                                                onChange={(e: any) => this.setState({nowa: e})}
                                                // disabled={dodawanie}
                                                precision={0}
                                                step={1}
                                                min={0}
                                                // onPressEnter={this.onPressEnter}
                                                // id={'input-' + powiadomienie.komunikat.indeks}
                                                // onBlur={() => this.onBlurInput(inline, showButton, item)}
                                                // onClick={() => this.onClickInput(index, powiadomienie.komunikat.indeks)}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Button variant={'primary'} size={'sm'} disabled={dodawanie}
                                                    onClick={() => this.onClickButtonDodaj(product)}>
                                                {
                                                    (!Boolean(product['koszykIlosc'])) ?
                                                        <><IconCartPlus/> Dodaj</>
                                                        :
                                                        <><IconRefresh/> Zmień</>
                                                }
                                            </Button>
                                        </FormGroup>
                                    </Form>
                                </div>
                            </>
                                :
                                <div style={{textAlign: 'center'}}>
                                    <h5>Promocja wygasła</h5>
                                    <p>Zapraszamy do skorzystania z innych <a href={'/promocje'}>promocji</a></p>
                                </div>
                        }</div>
                        }

                    </TopLoading>
                </div>
            </div>


        );
    }
}

export default PromocjaActiveLink;