import React from 'react';

interface Props {
    options: any     // [{id, handleClick, icon, text, isActive}]
}

const TopVarietyOptions: React.FC<Props> = ({options}) => {
    return (
        <div id={'TopVarietyOptions'}>
            {
                options.map((option: any) =>
                    <div className={'option'}>
                        <div id={option.id} onClick={option.handleClick} className={option.className ? option.className : option.isActive ? 'active' : ''}>
                            {option.icon} <span className={'txt'}>{option.text}</span>
                        </div>
                    </div>
                )
            }
        </div>
    );
};

export default TopVarietyOptions;