import React, {Component} from 'react';
import {Button, ButtonToolbar, Col, Form, FormControl, FormGroup, FormLabel, Row} from "react-bootstrap";
import Select from "react-select";
import {SmallerSelectStyles} from "../../../../utilities/forms/jsStyles";
import {isMobileDevice} from "../../../../utilities/rwd/rwdUtilities";
import AddFormMargin from "../../../../sharedComponents/formComponents/AddFormMargin";
import Req from "../../../../sharedComponents/formComponents/Req";
import Datepicker from "../../../../sharedComponents/formComponents/Datepicker";
import {IconFilter, IconSave, IconSend, IconTimes, IconTrash} from "../../../../sharedComponents/icons/FontAwesome";
import {
    displayNotificationError,
    displayNotificationInfo, displayNotificationSuccess, displayNotificationWarning
} from "../../../../utilities/notifications/NotificationsManager";
import AdminService from "../../../../services/AdminService";
import PopupWrapper from "../../../../sharedComponents/popups/PopupWrapper";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import {_omit} from "../../../../utilities/lodash/lodashUtilities";
import Dropzone from "react-dropzone";
import PromocjeService from "../../../../services/PromocjeService";
import {now} from "moment";
import moment from 'moment';
import {isEmpty} from "../../../../utilities/arrays/arraysUtilities";
import KoszykService from "../../../../services/KoszykService";
import {TableFormatters_formatMoney} from "../../../../sharedComponents/tableComponents/tableFormatters";
import {HOST, HOST_API} from "../../../../ApplicationConfig";
import axios from "axios";
import {type} from "os";

interface Props{
    baner?:any,
    handleClickAnuluj : () => void;
    handleClickZapiszBaner : (data: any) => void
    handleClickEdytujBaner : (data: any) => void
}

interface State{
    tresc: string,
    kontrahentWarunek: string,
    waznyOd: any,
    waznyDo: any,
    czyPonownie: boolean,
    dlaB2B: boolean,
    czyNaEmail: boolean,
    czyMob: boolean,
    kontrahenci: any,
    showPopup: boolean,
    pageableObject: any,
    size: number,
    page: number,
    bezterminowo: boolean,
    plik: any,
    opis: string,
    promocja: any,

    timeStartError: boolean,
    timeEndError: boolean,
    dataEventStart: any,
    dataEventEnd: any,
    timeEventStart: any,
    timeEventEnd: any,
    wybranaPromocja: any,
    promocje: any,
    urlLink: string,

    szukajNazwa: string,
    szukajID: any,
    szukajCzyGaz: boolean
}

class AdminBaneryForm extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            tresc: '',
            kontrahentWarunek: '',
            waznyOd: new Date().toJSON().substr(0,10),
            waznyDo: null,
            czyPonownie: false,
            dlaB2B: false,
            czyNaEmail: false,
            czyMob: false,
            kontrahenci: [],
            showPopup: false,
            pageableObject: {
                currentPage: 0,
                currentSize: 0,
                totalElements: 0
            },
            size: 10,
            page: 0,
            bezterminowo: true,
            plik: null,
            opis: '',
            timeStartError: false,
            timeEndError: false,
            dataEventStart: moment().format("YYYY-MM-DD"),
            dataEventEnd: moment().format("YYYY-MM-DD"),
            timeEventStart: '00:00',
            timeEventEnd: '23:59',
            wybranaPromocja: [],
            promocje: [],
            szukajNazwa: '',
            szukajID: null,
            szukajCzyGaz: false,
            promocja: null,
            urlLink: ''
        }
    }

    componentDidMount() {
        // displayNotificationInfo('Formularz statycznyy','Nie jest obsłużony')
        let {baner} = this.props;

        if(baner){
            // let reader = new FileReader();
            // reader.readAsDataURL(HOST_API + `public/klient/getImgForBaner/${baner.id}`);
            // reader.onload = () => {
            //     // console.log(reader)
            //     // console.log(files[0].type)
            //
            //     let result: any = reader.result
            //     let base64 = result.substr(result.indexOf(',') + 1, result.length - 1)
            // }

            AdminService.getImgBaner(baner.id).then((response) => {
                let bin = Buffer.from(response.data, 'binary');
                let base64ImageString = bin.toString('base64');

                this.setState({plik: {
                        "nazwa": 'plik',
                        "dane": base64ImageString,
                        // "daneBin": Buffer.from(response.data, 'binary'),
                        // "rozmiar": response.headers.contentLength,
                        // "typ": response.headers.contentType
                    }})

            }).catch((e) => {
                console.log('error')
            })

            this.setState({
                opis: baner.tekst,
                dataEventStart: this.formatDateFromObject(baner.dataOd),
                dataEventEnd: this.formatDateFromObject(baner.dataDo),
                timeEventStart: this.formatTimeFromObject(baner.dataOd),
                timeEventEnd: this.formatTimeFromObject(baner.dataDo),
                promocja: (isEmpty(baner.idUm) || baner.idUm === 0)? null : {idUm: baner.idUm, nazwa: baner.nazwaProm},
                urlLink: isEmpty(baner.urlLink)? '' : baner.urlLink
            })
        }

        this.pobierzPromocje();
    }

    pobierzPromocje = (page = this.state.page, size = this.state.size) => {
        let data = {
            idUm: this.state.szukajID,
            nazwa: this.state.szukajNazwa,
            czyGa: this.state.szukajCzyGaz
        }

        AdminService.getPromocjeDlaBanerow(page, size, data).then((response) => {
            this.setState({
                promocje: response.data.content,
                pageableObject: Object.assign({}, _omit(response.data, "content"), {currentPage: page}, {currentSize: size}),
            });
        }).catch((e) => {
            console.log('error');
        })
    }

    onChangeTresc = (e: any) => {
        this.setState({tresc: e.target.value});
    }

    onChangeKontrahent = (e: any) => {
        this.setState({kontrahentWarunek: e.target.value});
    }

    onChangeDateOd = (e: any) => {
        let {waznyDo} = this.state;

        if(e > waznyDo){
            this.setState({waznyDo: null});
        }

        this.setState({waznyOd: e});
    }

    onChangeDate = (e: any) => {
        let {waznyOd} = this.state;
        this.setState({waznyDo: (e === '' || e < waznyOd)? null : e});
    }

    onClickWyslij = () => {

        if(isEmpty(this.state.plik) && !Boolean(this.props.baner)){
            displayNotificationError('Brak pliku!','Proszę wybrać plik baneru.');
        } else {
            if(isEmpty(this.state.promocja)){
                displayNotificationError('Brak wybranej promocji!','Wybierz promocje.');
            } else {
                if(isEmpty(this.state.opis)){
                    displayNotificationError('Brak opisu!','Uzupełnij opis.');
                } else {
                    if (this.state.dataEventStart > this.state.dataEventEnd) {
                        displayNotificationError('Złe daty!', 'Data początkowa nie może być większa od daty końcowej.');
                    } else {
                        let data = new FormData();

                        data.append('tekst', this.state.opis);
                        data.append('dataOd', this.state.dataEventStart + ' ' + this.state.timeEventStart + ':00');
                        data.append('dataDo', this.state.dataEventEnd + ' ' + this.state.timeEventEnd + ':00');
                        if(!isEmpty(this.state.promocja)) {
                            data.append('idUm', this.state.promocja.idUm);
                            data.append('nazwaProm', this.state.promocja.nazwa);
                        }
                        if(Boolean(this.state.plik)) if(Boolean(this.state.plik.daneBin)) data.append('file', this.state.plik.daneBin);
                        data.append('urlLink', this.state.urlLink);

                        if(Boolean(this.props.baner)){
                            data.append('id', this.props.baner.id);
                            data.append('czyGa', this.props.baner.czyGa);
                            this.props.handleClickEdytujBaner(data);
                        } else {
                            this.props.handleClickZapiszBaner(data);
                        }
                    }
                }
            }
        }

    }

    // onClickSprawdz = (page = this.state.page, size = this.state.size) => {
    //
    //     AdminService.getKontrahForKomunikaty(this.state.kontrahentWarunek, page, size).then((response) => {
    //         this.setState({
    //             kontrahenci: response.data.content,
    //             pageableObject: Object.assign({}, _omit(response.data, "content"), {currentPage: page}, {currentSize: size}),
    //             showPopup: true
    //         });
    //     }).catch((e) => {
    //         displayNotificationError('Wystąpił błąd podczas próby sprawdzania warunku', '')
    //     })
    //
    // }

    onChangeSizeInTable = (size: number) => {
        this.setState({
            size
        }, () => this.pobierzPromocje())
    };

    onChangePageInTable = (page: number, size: number) => {
        this.setState({
            page: page - 1,
            size: size
        }, () => this.pobierzPromocje())
    };

    onClickWybierz = (row: any) => {
        console.log(row)
        let url = '';

        if(row.czyGa === 1){
            url = HOST + 'promocje/prom/AG';
        } else {
            url = HOST + 'promocje/' + row.idUm;
        }

        this.setState({promocja: row, dataEventStart: row.dataOd, dataEventEnd: row.dataDo, urlLink: url, showPopup: false});
    }

    formatAkcja = (cell: number, row: object) => {
        return <Button variant={'primary'} size={'sm'} className={'btnWybierz'}
                       onClick={()=>{this.onClickWybierz(row)}}>Wybierz</Button>
    };

    columns = [{
        dataField: 'idUm',
        text: 'ID'
    }, {
        dataField: 'nazwa',
        text: 'Nazwa'
    }, {
        dataField: 'czyGa',
        text: 'Gazetka'
    }, {
        dataField: 'dataOd',
        text: 'Data od'
    }, {
        dataField: 'dataDo',
        text: 'Data do'
    }, {
        dataField: 'idUm',
        text: 'Akcja',
        formatter: this.formatAkcja,
        style: {width: '80px'},
        align: 'center'
    }];

    onDrop(files: any) {
        let i = new Image()
        // let ii = new VideoTrack()
        console.log(files)

        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = () => {
            // console.log(reader)
            // console.log(files[0].type)

            let result: any = reader.result
            let base64 = result.substr(result.indexOf(',') + 1, result.length - 1)

            if(files[0].type.includes('video')){

                let plik = {
                    "nazwa": files[0].name,
                    "dane": base64,
                    "daneBin": files[0],
                    "rozmiar": files[0].size,
                    "typ": files[0].type
                }

                this.setState({plik: plik});

            } else {
                i.src = result;

                i.onload = () => {
                    // console.log(i.width);
                    if(i.width !== 1310 || i.height !== 150)
                    {
                        displayNotificationWarning('Zły rozmiar baneru!', 'Wymagany rozmiar to 1310x150px.', 5000);

                    }
                    else
                    {
                        // gdy ok
                        let plik = {
                            "nazwa": files[0].name,
                            "dane": base64,
                            "daneBin": files[0],
                            "rozmiar": files[0].size,
                            "typ": files[0].type
                        }

                        this.setState({plik: plik});
                    }

                    // let obj = this.state.restobject
                    // obj['plikRequestWeb'] = plik
                    // this.setState({restobject: obj})

                }
            }


        };
    }

    onChangeOpis = ({target}: any) => {
        this.setState({opis: target.value});
    };

    onChangeLink = ({target}: any) => {
        this.setState({urlLink: target.value});
    };

    onChangeDataEventStart = (e: any) => {

        console.log('wybrano date start: ',e.target.value)
        this.setState({dataEventStart: e.target.value})
        let t = e.target.value;

        if (e.target.value) {
            if (e.target.value != this.state.dataEventStart) {
                // this.setState({czas: '8:00', czasMinuty: 480});
                // document.getElementsByName("time-picker-start")[0].value = '08:00';
                // document.getElementsByName("time-picker-end")[0].value = '16:00';
            }
            else {
                console.log('ten sam')
                if (this.state.timeEventEnd && this.state.timeEventStart) {
                    if (this.state.timeEventEnd > this.state.timeEventStart) {

                        let s = new Date(t + " " + this.state.timeEventStart);
                        let e = new Date(this.state.dataEventEnd + " " + this.state.timeEventEnd);
                        // this.setState({czas: this.countHours(s, e)});
                        console.log('count hours')
                    }
                }
            }
        }
    }

    onChangeTimeEventStart = (e: any) => {

        console.log('wybrano godzine start: ',e.target.value)

        this.setState({timeEventStart: e.target.value})
        let time = e.target.value;

        if (Boolean(this.state.timeEventEnd) && (this.state.dataEventStart == this.state.dataEventEnd) && time < this.state.timeEventEnd) {
            let s = new Date(this.state.dataEventStart + " " + time);
            let e = new Date(this.state.dataEventEnd + " " + this.state.timeEventEnd);
            // this.setState({czas: this.countHours(s, e)});
        } else {
            // this.setState({czas: '--:--', czasMinuty: 0});
        }
    }

    onChangeDataEventEnd = (e: any) => {

        console.log('wybrano date koniec: ',e.target.value)
        this.setState({dataEventEnd: e.target.value})
        let t = e.target.value;
        if (e.target.value) {
            if (e.target.value != this.state.dataEventEnd) {
                // this.setState({czas: '8:00', czasMinuty: 480});
                // document.getElementsByName("time-picker-start")[0].value = '08:00';
                // document.getElementsByName("time-picker-end")[0].value = '16:00';
            }
            else {
                if (this.state.timeEventEnd && this.state.timeEventStart) {
                    if (this.state.timeEventEnd > this.state.timeEventStart) {
                        let s = new Date(this.state.dataEventStart + " " + this.state.timeEventStart);
                        let e = new Date(t + " " + this.state.timeEventEnd);
                        // this.setState({czas: this.countHours(s, e)});
                    }
                }
            }
        }
    }

    onChangeTimeEventEnd = (e: any) => {

        console.log('wybrano godzine start: ',e.target.value)

        this.setState({timeEventEnd: e.target.value})
        let time = e.target.value;
        if (Boolean(this.state.timeEventStart) && (this.state.dataEventStart == this.state.dataEventEnd) && this.state.timeEventStart < time) {
            let s = new Date(this.state.dataEventStart + " " + this.state.timeEventStart);
            let e = new Date(this.state.dataEventEnd + " " + time);
            // this.setState({czas: this.countHours(s, e)});
        } else {
            // this.setState({czas: '--:--', czasMinuty: 0});
        }
    }

    formatTimeFromObject = (obj: any) => {
        return moment(obj).format("HH:mm")
    }

    formatDateFromObject = (obj: any) => {
        return moment(obj).format("YYYY-MM-DD")
    }

    handleKeyPress(target: any) {
        if (target.charCode==13){
            this.pobierzPromocje();
        }
    }

    onChangeNazwa = ({target}: any) => {
        this.setState({szukajNazwa: target.value});
    };

    onChangeID = ({target}: any) => {
        this.setState({szukajID: target.value});
    };

    render() {
        let {szukajNazwa, szukajID, showPopup, szukajCzyGaz, promocja, promocje, pageableObject, urlLink, opis} = this.state;
        let {baner} = this.props;

        return (
            <div id={'AdminKomunikatyForm'}>
                <Form>
                    {/*<Row>*/}
                    {/*    <Col>*/}
                    {/*       <FormGroup>*/}
                    {/*           <Form.Label>*/}
                    {/*               Temat <Req/>*/}
                    {/*           </Form.Label>*/}
                    {/*           <FormControl size={'sm'} defaultValue={Boolean(komunikat)?komunikat.temat:''} />*/}
                    {/*       </FormGroup>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                    <Row>
                        <Col>
                            <FormGroup>
                                <Form.Label>
                                    Baner <span style={{color: 'red'}}>*</span>
                                </Form.Label>
                                <Dropzone onDrop={this.onDrop.bind(this)} accept={['.jpg', '.jpeg', '.png', '.mp4']}>
                                    {({getRootProps, getInputProps}) => (
                                        <section>
                                            <div {...getRootProps()} className={'dropzone-field'} style={{backgroundImage: (this.state.plik)? `url(data:${this.state.plik.typ};base64,${this.state.plik.dane})` : ''}}>
                                                <input {...getInputProps()}/>
                                                {
                                                    //@ts-ignore
                                                    Boolean(this.state.plik) ? <label>{`Wczytano ${this.state.plik.nazwa}, kliknij aby zmienić`}</label>
                                                        :
                                                        <p>Przeciągnij tutaj lub kliknij by dodać plik <br/>.jpg, .jpeg, .png lub .mp4 <br/>(wymagany rozmiar to 1310x150px)</p>
                                                }
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <FormGroup>
                                <Form.Label>
                                    Promocja <span style={{color: 'red'}}>*</span>
                                </Form.Label>
                                {promocja &&
                                    <label style={{marginLeft: '15px'}}> [{promocja.idUm}] {promocja.nazwa}</label>
                                }
                                <Button variant={'light'} size={'sm'} style={{marginLeft: '15px'}} onClick={() => this.setState({showPopup: true})}>{promocja? 'Zmień' : 'Wybierz promocje'}</Button>
                            </FormGroup>
                        </Col>
                    </Row>
                    {/*<Row>*/}
                    {/*    <Col>*/}
                    {/*        <Form.Label style={{marginRight: '15px', verticalAlign: 'text-top'}}>*/}
                    {/*            Oddział*/}
                    {/*        </Form.Label>*/}
                    {/*        <Form.Check inline={true} type="radio"*/}
                    {/*                    label="Wszystkie"*/}
                    {/*                    checked={dlaB2B}*/}
                    {/*                    onClick={() => {this.setState({dlaB2B: !dlaB2B})}}*/}
                    {/*                    id={'czyOWszystkie'}*/}
                    {/*        />*/}
                    {/*        <Form.Check inline={true} type="radio"*/}
                    {/*                    label="Rzeszów"*/}
                    {/*                    checked={dlaB2B}*/}
                    {/*                    onClick={() => {this.setState({dlaB2B: !dlaB2B})}}*/}
                    {/*                    id={'czyOWszystkie'}*/}
                    {/*        />*/}
                    {/*        <Form.Check inline={true} type="radio"*/}
                    {/*                    label="Kraków"*/}
                    {/*                    checked={dlaB2B}*/}
                    {/*                    onClick={() => {this.setState({dlaB2B: !dlaB2B})}}*/}
                    {/*                    id={'czyOWszystkie'}*/}
                    {/*        />*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                    <Row>
                        <Col>
                           <FormGroup>
                               <Form.Label>
                                   Opis <span style={{color: 'red'}}>*</span>
                               </Form.Label>
                               <FormControl size={'sm'} value={opis} onChange={this.onChangeOpis}/>
                           </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <FormGroup>
                                <Form.Label>
                                    Link
                                </Form.Label>
                                <FormControl size={'sm'} value={urlLink} onChange={this.onChangeLink}/>
                                {(!isEmpty(urlLink)) &&
                                <div style={{marginTop: '5px'}}>TEST - <a href={urlLink} target="_blank">{urlLink}</a></div>
                                }
                            </FormGroup>
                        </Col>
                    </Row>
                    {/*<Row>*/}
                    {/*    <Col xs={12} sm={9}>*/}
                    {/*        <FormGroup>*/}
                    {/*            /!*<Form.Label>*!/*/}
                    {/*            /!*    Kontrahent: <Req/>*!/*/}
                    {/*            /!*</Form.Label>*!/*/}
                    {/*            /!*<Select*!/*/}
                    {/*            /!*    value={null}*!/*/}
                    {/*            /!*    options={[{value:'Famix',label:'Famix'},{value:'Testowy',label:'Testowy'}]}*!/*/}
                    {/*            /!*    onChange={()=>{}}*!/*/}
                    {/*            /!*    styles={SmallerSelectStyles}*!/*/}
                    {/*            /!*    isClearable={true}*!/*/}
                    {/*            /!*    placeholder={'Wybierz...'}*!/*/}
                    {/*            /!*    isSearchable={!isMobileDevice()}*!/*/}
                                {/*/>*/}
                    {/*            <Form.Label>*/}
                    {/*                Kontrahent:*/}
                    {/*            </Form.Label>*/}
                    {/*            <textarea className={'form-control form-control-sm'} rows={3} value={kontrahentWarunek} onChange={this.onChangeKontrahent}/>*/}
                    {/*        </FormGroup>*/}
                    {/*    </Col>*/}
                    {/*    <Col xs={12} sm={3} className={'align-self-center'}>*/}
                    {/*        <Button variant={'light'} size={'sm'} onClick={() => this.onClickSprawdz()}><i className="fa fa-eye" aria-hidden="true"/> Testuj warunek</Button>*/}
                    {/*    </Col>*/}
                    {/*    */}
                    {/*</Row>*/}
                    {/*<Row>*/}
                    {/*    /!*<Col md={6} lg={6} xl={6}>*!/*/}
                    {/*    /!*       *!/*/}
                    {/*    /!*</Col>*!/*/}
                    {/*    <Col md={6} lg={3} xl={3}>*/}
                    {/*        <FormGroup>*/}
                    {/*            <Form.Label>*/}
                    {/*                Data od: <Req/>*/}
                    {/*            </Form.Label>*/}
                    {/*            <Datepicker value={waznyOd? waznyOd : new Date().toJSON().substr(0,10)} onChange={this.onChangeDateOd}/>*/}
                    {/*        </FormGroup>*/}
                    {/*    </Col>*/}

                    {/*    <Col md={6} lg={3} xl={3}>*/}
                    {/*        <FormGroup>*/}
                    {/*        <Form.Label>*/}
                    {/*            /!*<Form.Check inline={true} type="checkbox"*!/*/}
                    {/*            /!*            label="Komunikat ważny do:"*!/*/}
                    {/*            /!*            checked={dlaB2B}*!/*/}
                    {/*            /!*            onClick={() => {this.setState({dlaB2B: !dlaB2B})}}*!/*/}
                    {/*            /!*            id={'czyB2BCheckbox'}*!/*/}
                                {/*/>*/}
                    {/*            Data do:*/}
                    {/*        </Form.Label>*/}
                    {/*        <Datepicker value={waznyDo? waznyDo : null} onChange={this.onChangeDate}/>*/}
                    {/*        </FormGroup>*/}
                    {/*    </Col>*/}

                    {/*</Row>*/}

                    <Row>
                        <Col>
                            <div className={(this.state.timeStartError) ? "has-error" : ''}>
                                <FormLabel>Czas początkowy <span style={{color: 'red'}}>*</span></FormLabel>
                                <Row>
                                    <Col xs={6} lg={6} className={(this.state.dataEventStart > this.state.dataEventEnd) ? 'has-error' : ''}>
                                        <FormControl type={'date'} onChange={this.onChangeDataEventStart} value={this.state.dataEventStart}
                                                     defaultValue={this.state.dataEventStart}/>
                                    </Col>
                                    <Col xs={6} lg={6} className={(this.state.timeEventStart >= this.state.timeEventEnd) ? 'has-error' : ''}>
                                        <FormControl name={'time-picker-start'}
                                                     type={'time'} onChange={this.onChangeTimeEventStart}
                                                     defaultValue={'00:00'}
                                                     disabled={false}/>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col>
                            <div className={(this.state.timeEndError) ? "has-error" : ''}>
                                <FormLabel>Czas końcowy <span style={{color: 'red'}}>*</span></FormLabel>
                                <Row>
                                    <Col xs={6} lg={6} className={(this.state.dataEventStart > this.state.dataEventEnd) ? 'has-error' : ''}>
                                        <FormControl type={'date'} onChange={this.onChangeDataEventEnd} value={this.state.dataEventEnd}
                                                     defaultValue={this.state.dataEventEnd}/>
                                    </Col>
                                    <Col xs={6} lg={6} className={(this.state.timeEventStart >= this.state.timeEventEnd) ? 'has-error' : ''}>
                                        <FormControl name={'time-picker-end'}
                                                     type={'time'} onChange={this.onChangeTimeEventEnd}
                                                     defaultValue={'23:59'}
                                                     disabled={false}/>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>

                    {/*<Row>*/}
                    {/*    <Col>*/}
                    {/*        <FormGroup>*/}
                    {/*            /!*<AddFormMargin hideForMobileDevices767={true} />*!/*/}
                    {/*            /!*<Form.Check inline={true} type="checkbox"*!/*/}
                    {/*            /!*            label="Czy pokazywać komunikat ponownie?"*!/*/}
                    {/*            /!*            checked={czyPonownie}*!/*/}
                    {/*            /!*            onClick={() => {this.setState({czyPonownie: !czyPonownie})}}*!/*/}
                    {/*            /!*            id={'czyPokazywacPonownieCheckbox'}*!/*/}
                                {/*/>*/}
                    {/*            <Form.Check inline={true} type="checkbox"*/}
                    {/*                        label="Nowe okno"*/}
                    {/*                        checked={dlaB2B}*/}
                    {/*                        onClick={() => {this.setState({dlaB2B: !dlaB2B})}}*/}
                    {/*                        id={'czyNoweOkno'}*/}
                    {/*            />*/}
                    {/*        </FormGroup>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}

                    {/*<Row>*/}
                    {/*    <Col>*/}
                    {/*        <FormGroup>*/}
                    {/*            <AddFormMargin hideForMobileDevices767={true} />*/}
                    {/*            <Form.Check inline={true} type="checkbox"*/}
                    {/*                        label="Czy pokazywać komunikat ponownie?"*/}
                    {/*                        checked={false}*/}
                    {/*                        onClick={() => {}}*/}
                    {/*                        id={'czyPokazywacPonownieCheckbox'}*/}
                    {/*            />*/}
                    {/*            <Form.Check inline={true} type="checkbox"*/}
                    {/*                        label="Czy komunikat dla klientów B2B?"*/}
                    {/*                        checked={false}*/}
                    {/*                        onClick={() => {}}*/}
                    {/*                        id={'czyB2BCheckbox'}*/}
                    {/*            />*/}
                    {/*            <Form.Check inline={true} type="checkbox"*/}
                    {/*                        label="Czy wysyłać komunikat na email?"*/}
                    {/*                        checked={false}*/}
                    {/*                        onClick={() => {}}*/}
                    {/*                        id={'czyWysylacEmailCheckbox'}*/}
                    {/*            />*/}
                    {/*        </FormGroup>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                    <Row style={{marginTop: '15px'}}>
                        <Col>
                            <div className={'float-right'}>
                                <ButtonToolbar>
                                    <Button variant={'light'} size={'sm'} onClick={() => this.props.handleClickAnuluj()}><IconTimes/> Anuluj</Button>
                                    <Button variant={'primary'} size={'sm'}
                                            onClick={()=>{this.onClickWyslij()}}>
                                        {
                                            Boolean(baner)?
                                                <><IconSave/> Zapisz zmiany</>
                                                :
                                                <><IconSave/> Zapisz baner</>
                                        }
                                    </Button>
                                </ButtonToolbar>
                            </div>
                        </Col>
                    </Row>
                </Form>

                {
                    showPopup &&
                    <PopupWrapper header={'Promocje'}
                                  shouldNotCloseWithoutClick={true} withoutOverflowY={false}
                                  onClose={() => this.setState({showPopup: false})}
                    >
                        <div className={'promocjePopUp'}>
                            <Row>
                                <Col xl={3}>
                                    <FormGroup>
                                        <FormControl onKeyPress={this.handleKeyPress.bind(this)} size={"sm"}
                                                     placeholder={'Szukaj promocje po ID...'}
                                                     onChange={this.onChangeID}
                                                     value={szukajID}
                                            // disabled={szukajnazwa? true : false}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xl={5}>
                                    <FormGroup>
                                        <FormControl onKeyPress={this.handleKeyPress.bind(this)} size={"sm"}
                                                     placeholder={'Szukaj promocje po nazwie...'}
                                                     onChange={this.onChangeNazwa}
                                                     value={szukajNazwa}
                                            // disabled={szukajnazwa? true : false}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xl={2}>
                                    <FormGroup>
                                        {/*<AddFormMargin hideForMobileDevices991={true}/>*/}
                                        <Form.Check inline={true} type="checkbox"
                                                    label="Tylko gazetki"
                                                    checked={szukajCzyGaz}
                                                    onClick={() => {
                                                        this.setState((prevState: any) => ({szukajCzyGaz: !prevState.szukajCzyGaz}), () => this.pobierzPromocje())
                                                    }}
                                                    id={'czyGaz'}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xl={2}>
                                    <FormGroup>
                                        <Button type={'button'} variant={'primary'} size={'sm'}
                                                onClick={() => this.pobierzPromocje()}
                                                disabled={false}
                                        >
                                            <IconFilter/> Filtruj
                                        </Button>

                                        {
                                            (Boolean(this.state.szukajCzyGaz) || Boolean(this.state.szukajNazwa) || Boolean(this.state.szukajID))
                                            &&
                                            <Button type={'button'} variant={'light'} size={'sm'} className={'text-danger'}
                                                    onClick={() => {
                                                        this.setState({
                                                            szukajNazwa: '',
                                                            szukajID: '',
                                                            szukajCzyGaz: false
                                                        }, () => this.pobierzPromocje())
                                                    }}
                                                    title={'Usuń wszystkie zastosowane filtry'}
                                                // disabled={isButtonDisabled}
                                            >
                                                <IconTrash/>
                                                {
                                                    isMobileDevice() &&
                                                    ' Wyczyść'
                                                }
                                            </Button>
                                        }
                                    </FormGroup>
                                </Col>
                            </Row>
                            <BootstrapTable remote keyField='idUm' data={ promocje } columns={ this.columns } hover noDataIndication={'Brak pasujących wyników'}
                                            pagination={(paginationFactory({
                                                page: (pageableObject.currentPage) + 1,
                                                sizePerPage: pageableObject.currentSize,
                                                totalSize: pageableObject.totalElements,
                                                sizePerPageList: [10, 12, 20, 50, 100],
                                                onSizePerPageChange: this.onChangeSizeInTable,
                                                onPageChange: this.onChangePageInTable
                                            }))}
                            />
                        </div>

                    </PopupWrapper>
                }

            </div>
        );
    }
}

export default AdminBaneryForm;