import React from 'react';
import TopCard from "../../../../sharedComponents/TopCard";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import {TableActionsButtons_AnyAction} from "../../../../sharedComponents/tableComponents/tableButtons";
import {IconList} from "../../../../sharedComponents/icons/FontAwesome";
import Select from "react-select";
import {SmallerSelectStyles} from "../../../../utilities/forms/jsStyles";
import {isMobileDevice} from "../../../../utilities/rwd/rwdUtilities";

interface Props {
    reklamacje: any[],
    statusy: any[],
    handleClickPokaz: (row: any) => void;
    handleClickZmienStatus: (row: any) => void;
}

const AdminReklamacjeTable: React.FC<Props> = ({reklamacje, statusy, handleClickPokaz, handleClickZmienStatus}) => {

    const formatAkcjeRow = (cell: any, row: any) => {
        return <TableActionsButtons_AnyAction handleClickAction={()=>handleClickPokaz(row)} tooltip={'Pokaż szczegóły reklamacji'}><IconList/></TableActionsButtons_AnyAction>
    };

    const formatStatusRow = (cell: any, row: any) => {

        let status = statusy.find((s: any) => s.value === cell)

        return <Select
                    value={status}
                    options={statusy}
                    onChange={handleClickZmienStatus}
                    styles={SmallerSelectStyles}
                    isClearable={false}
                    placeholder={'Wybierz...'}
                    isSearchable={!isMobileDevice()}
                    isDisabled={cell==='rozwiązano'}
                />
    };

    return (
        <div id={'AdminReklamacjeTable'}>
            <TopCard heading={'Ustawienia systemu'} hasPadding={false}>
                <BootstrapTable
                    data={reklamacje}
                    hover={true}>
                    <TableHeaderColumn isKey={true} dataField={'id'}
                                       hidden={true}
                                       dataSort={true}
                    >ID</TableHeaderColumn>
                    <TableHeaderColumn dataField={'dataReklamacji'}
                                       width={'150'}
                                       dataSort={true}
                    >Data rekl.</TableHeaderColumn>
                    <TableHeaderColumn dataField={'klient'}
                                       width={'150'}
                                       dataSort={true}
                    >Klient</TableHeaderColumn>
                    <TableHeaderColumn dataField={'faktura'}
                                       width={'150'}
                                       dataSort={true}
                    >Faktura</TableHeaderColumn>
                    <TableHeaderColumn dataField={'dataWystawienia'}
                                       width={'150'}
                                       dataSort={true}
                    >Data wyst. faktury</TableHeaderColumn>
                    <TableHeaderColumn dataField={'status'}
                                       width={'150'}
                                       dataSort={true}
                                       dataFormat={formatStatusRow}
                    >Status</TableHeaderColumn>
                    <TableHeaderColumn dataField={'id'}
                                       width={'100'}
                                       dataSort={true}
                                       dataFormat={formatAkcjeRow}
                    >Pokaż</TableHeaderColumn>
                </BootstrapTable>
            </TopCard>
        </div>
    );
};

export default AdminReklamacjeTable;