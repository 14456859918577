import React, {Component} from 'react';
import {Button, ButtonToolbar, Col, Form, FormControl, FormGroup, Row} from "react-bootstrap";
import Select from "react-select";
import {SmallerSelectStyles} from "../../../../utilities/forms/jsStyles";
import {isMobileDevice} from "../../../../utilities/rwd/rwdUtilities";
import AddFormMargin from "../../../../sharedComponents/formComponents/AddFormMargin";
import Req from "../../../../sharedComponents/formComponents/Req";
import Datepicker from "../../../../sharedComponents/formComponents/Datepicker";
import {IconSave, IconSend, IconTimes} from "../../../../sharedComponents/icons/FontAwesome";
import {
    displayNotificationError,
    displayNotificationInfo, displayNotificationSuccess
} from "../../../../utilities/notifications/NotificationsManager";
import AdminService from "../../../../services/AdminService";
import PopupWrapper from "../../../../sharedComponents/popups/PopupWrapper";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import {_omit} from "../../../../utilities/lodash/lodashUtilities";

interface Props{
    komunikat?:any,
    handleClickAnuluj : () => void;
    handleClickWyslij : (data: any) => void
}

interface State{
    tresc: string,
    kontrahentWarunek: string,
    waznyOd: any,
    waznyDo: any,
    czyPonownie: boolean,
    dlaB2B: boolean,
    czyNaEmail: boolean,
    czyMob: boolean,
    kontrahenci: any,
    showPopup: boolean,
    pageableObject: any,
    size: number,
    page: number,
    bezterminowo: boolean
}

class AdminKomunikatyForm extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            tresc: '',
            kontrahentWarunek: '',
            waznyOd: new Date().toJSON().substr(0,10),
            waznyDo: null,
            czyPonownie: false,
            dlaB2B: false,
            czyNaEmail: false,
            czyMob: false,
            kontrahenci: [],
            showPopup: false,
            pageableObject: {
                currentPage: 0,
                currentSize: 0,
                totalElements: 0
            },
            size: 20,
            page: 0,
            bezterminowo: true
        }
    }

    componentDidMount() {
        // displayNotificationInfo('Formularz statycznyy','Nie jest obsłużony')
        let {komunikat} = this.props;

        if(komunikat){
            this.setState({
                tresc: komunikat.komunikat,
                kontrahentWarunek: komunikat.kontrahent,
                waznyOd: komunikat.waznyOd,
                waznyDo: komunikat.waznosc,
                czyPonownie: komunikat.niePokazuj,
                dlaB2B: komunikat.czyKomunikatB2b,
                czyNaEmail: komunikat.czyEmail,
                czyMob: komunikat.czyMob
            })
        }
    }

    onChangeTresc = (e: any) => {
        this.setState({tresc: e.target.value});
    }

    onChangeKontrahent = (e: any) => {
        this.setState({kontrahentWarunek: e.target.value});
    }

    onChangeDateOd = (e: any) => {
        let {waznyDo} = this.state;

        if(e > waznyDo){
            this.setState({waznyDo: null});
        }

        this.setState({waznyOd: e});
    }

    onChangeDate = (e: any) => {
        let {waznyOd} = this.state;
        this.setState({waznyDo: (e === '' || e < waznyOd)? null : e});
    }

    onClickWyslij = () => {
        let data = {
            czyB2b: this.state.dlaB2B,
            czyEmail: this.state.czyNaEmail,
            czyMob: this.state.czyMob,
            czyPokazywacPonownie: this.state.czyPonownie,
            komunikat: this.state.tresc,
            kontrahent: this.state.kontrahentWarunek,
            waznyOd: this.state.waznyOd,
            waznyDo: this.state.waznyDo
        }

        this.props.handleClickWyslij(data)
        // AdminService.addKomunikat(data).then((response) => {
        //     this.setState({showPopup: false, kontrahenci: []});
        //     displayNotificationSuccess('Pomyślnie wysłano komunikat', '');
        // }).catch((e) => {
        //     displayNotificationError('Wystąpił błąd podczas próby wysłania komunikatu', '')
        // })
    }

    onClickSprawdz = (page = this.state.page, size = this.state.size) => {

        AdminService.getKontrahForKomunikaty(this.state.kontrahentWarunek, page, size).then((response) => {
            this.setState({
                kontrahenci: response.data.content,
                pageableObject: Object.assign({}, _omit(response.data, "content"), {currentPage: page}, {currentSize: size}),
                showPopup: true
            });
        }).catch((e) => {
            displayNotificationError('Wystąpił błąd podczas próby sprawdzania warunku', '')
        })

    }

    onChangeSizeInTable = (size: number) => {
        this.setState({
            size
        }, () => this.onClickSprawdz())
    };

    onChangePageInTable = (page: number, size: number) => {
        this.setState({
            page: page - 1,
            size: size
        }, () => this.onClickSprawdz())
    };

    columns = [{
        dataField: 'nazwa',
        text: 'Nazwa'
    }, {
        dataField: 'adres1',
        text: 'Adres',
        formatter: (cell: any, row: any) => (
            cell + ' ' + row.adres2
        )
    }];

    render() {
        let {tresc, czyPonownie, czyNaEmail, czyMob, waznyDo, waznyOd, kontrahentWarunek, dlaB2B, showPopup, kontrahenci, pageableObject, bezterminowo} = this.state;
        let {komunikat} = this.props;

        return (
            <div id={'AdminKomunikatyForm'}>
                <Form>
                    {/*<Row>*/}
                    {/*    <Col>*/}
                    {/*       <FormGroup>*/}
                    {/*           <Form.Label>*/}
                    {/*               Temat <Req/>*/}
                    {/*           </Form.Label>*/}
                    {/*           <FormControl size={'sm'} defaultValue={Boolean(komunikat)?komunikat.temat:''} />*/}
                    {/*       </FormGroup>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                    <Row>
                        <Col>
                           <FormGroup>
                               <Form.Label>
                                   Treść
                               </Form.Label>
                               <textarea className={'form-control form-control-sm'} rows={3} value={tresc} onChange={this.onChangeTresc}/>
                           </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={9}>
                            <FormGroup>
                                {/*<Form.Label>*/}
                                {/*    Kontrahent: <Req/>*/}
                                {/*</Form.Label>*/}
                                {/*<Select*/}
                                {/*    value={null}*/}
                                {/*    options={[{value:'Famix',label:'Famix'},{value:'Testowy',label:'Testowy'}]}*/}
                                {/*    onChange={()=>{}}*/}
                                {/*    styles={SmallerSelectStyles}*/}
                                {/*    isClearable={true}*/}
                                {/*    placeholder={'Wybierz...'}*/}
                                {/*    isSearchable={!isMobileDevice()}*/}
                                {/*/>*/}
                                <Form.Label>
                                    Kontrahent:
                                </Form.Label>
                                <textarea className={'form-control form-control-sm'} rows={3} value={kontrahentWarunek} onChange={this.onChangeKontrahent}/>
                            </FormGroup>
                        </Col>
                        <Col xs={12} sm={3} className={'align-self-center'}>
                            <Button variant={'light'} size={'sm'} onClick={() => this.onClickSprawdz()}><i className="fa fa-eye" aria-hidden="true"/> Testuj warunek</Button>
                        </Col>
                        {/*<Col xs={12} sm={6}>*/}
                        {/*    <FormGroup>*/}
                        {/*        <AddFormMargin hideForMobileDevices575={true} />*/}
                        {/*        <Form.Check inline={true} type="checkbox"*/}
                        {/*                    label="Wszyscy kontrahenci"*/}
                        {/*                    checked={false}*/}
                        {/*                    onClick={() => {}}*/}
                        {/*                    id={'wszyscyKontrahenciCheckbox'}*/}
                        {/*        />*/}
                        {/*    </FormGroup>*/}
                        {/*</Col>*/}
                    </Row>
                    <Row>
                        {/*<Col md={6} lg={6} xl={6}>*/}
                        {/*       */}
                        {/*</Col>*/}
                        <Col md={6} lg={3} xl={3}>
                            <FormGroup>
                                <Form.Label>
                                    Komunikat ważny od: <Req/>
                                </Form.Label>
                                <Datepicker value={waznyOd? waznyOd : new Date().toJSON().substr(0,10)} onChange={this.onChangeDateOd}/>
                            </FormGroup>
                        </Col>

                        <Col md={6} lg={3} xl={3}>
                            <FormGroup>
                            <Form.Label>
                                {/*<Form.Check inline={true} type="checkbox"*/}
                                {/*            label="Komunikat ważny do:"*/}
                                {/*            checked={dlaB2B}*/}
                                {/*            onClick={() => {this.setState({dlaB2B: !dlaB2B})}}*/}
                                {/*            id={'czyB2BCheckbox'}*/}
                                {/*/>*/}
                                Komunikat ważny do:
                            </Form.Label>
                            <Datepicker value={waznyDo? waznyDo : null} onChange={this.onChangeDate}/>
                            </FormGroup>
                        </Col>

                    </Row>

                    <Row>
                        <Col>
                            <FormGroup>
                                {/*<AddFormMargin hideForMobileDevices767={true} />*/}
                                {/*<Form.Check inline={true} type="checkbox"*/}
                                {/*            label="Czy pokazywać komunikat ponownie?"*/}
                                {/*            checked={czyPonownie}*/}
                                {/*            onClick={() => {this.setState({czyPonownie: !czyPonownie})}}*/}
                                {/*            id={'czyPokazywacPonownieCheckbox'}*/}
                                {/*/>*/}
                                <Form.Check inline={true} type="checkbox"
                                            label="Czy komunikat dla klientów B2B?"
                                            checked={dlaB2B}
                                            onClick={() => {this.setState({dlaB2B: !dlaB2B})}}
                                            id={'czyB2BCheckbox'}
                                />
                                <Form.Check inline={true} type="checkbox"
                                            label="Czy wysyłać komunikat na email?"
                                            checked={czyNaEmail}
                                            onClick={() => {this.setState({czyNaEmail: !czyNaEmail})}}
                                            id={'czyWysylacEmailCheckbox'}
                                />
                                <Form.Check inline={true} type="checkbox"
                                            label="Czy wysyłać komunikat na urządzenia mobilne?"
                                            checked={czyMob}
                                            onClick={() => {this.setState({czyMob: !czyMob})}}
                                            id={'czyWysylacMobCheckbox'}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    {/*<Row>*/}
                    {/*    <Col>*/}
                    {/*        <FormGroup>*/}
                    {/*            <AddFormMargin hideForMobileDevices767={true} />*/}
                    {/*            <Form.Check inline={true} type="checkbox"*/}
                    {/*                        label="Czy pokazywać komunikat ponownie?"*/}
                    {/*                        checked={false}*/}
                    {/*                        onClick={() => {}}*/}
                    {/*                        id={'czyPokazywacPonownieCheckbox'}*/}
                    {/*            />*/}
                    {/*            <Form.Check inline={true} type="checkbox"*/}
                    {/*                        label="Czy komunikat dla klientów B2B?"*/}
                    {/*                        checked={false}*/}
                    {/*                        onClick={() => {}}*/}
                    {/*                        id={'czyB2BCheckbox'}*/}
                    {/*            />*/}
                    {/*            <Form.Check inline={true} type="checkbox"*/}
                    {/*                        label="Czy wysyłać komunikat na email?"*/}
                    {/*                        checked={false}*/}
                    {/*                        onClick={() => {}}*/}
                    {/*                        id={'czyWysylacEmailCheckbox'}*/}
                    {/*            />*/}
                    {/*        </FormGroup>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                    <Row>
                        <Col>
                            <div className={'float-right'}>
                                <ButtonToolbar>
                                    <Button variant={'light'} size={'sm'} onClick={() => this.props.handleClickAnuluj()}><IconTimes/> Anuluj</Button>
                                    <Button variant={'primary'} size={'sm'}
                                            onClick={()=>{this.onClickWyslij()}}>
                                        {
                                            Boolean(komunikat)?
                                                <><IconSave/> Zapisz komunikat</>
                                                :
                                                <><IconSend/> Wyślij komunikat</>
                                        }
                                    </Button>
                                </ButtonToolbar>
                            </div>
                        </Col>
                    </Row>
                </Form>

                {
                    showPopup &&
                    <PopupWrapper header={'Kontrahenci'}
                                  shouldNotCloseWithoutClick={true} withoutOverflowY={false}
                                  onClose={() => this.setState({showPopup: false})}
                    >
                        <div id={'kontrahWarunek'}>
                            <BootstrapTable remote keyField='numer' data={ kontrahenci } columns={ this.columns } hover noDataIndication={'Brak pasujących wyników'}
                                            pagination={(paginationFactory({
                                                page: (pageableObject.currentPage) + 1,
                                                sizePerPage: pageableObject.currentSize,
                                                totalSize: pageableObject.totalElements,
                                                sizePerPageList: [10, 12, 20, 50, 100],
                                                onSizePerPageChange: this.onChangeSizeInTable,
                                                onPageChange: this.onChangePageInTable
                                            }))}
                            />
                        </div>

                    </PopupWrapper>
                }

            </div>
        );
    }
}

export default AdminKomunikatyForm;