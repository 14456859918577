import React from 'react';
import TopImageWithBiggerView from "../TopImageWithBiggerView";
//@ts-ignore
import {LazyLoadImage} from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import {Link} from "react-router-dom";
import Shared_ProductImage from "../_reusable/ProductImageLazyLoadingWithErrorHandling/Shared_ProductImage";

interface TableImage_WithURLProps {
    item: any,
    url?: string,
}

export const TableImage_WithURL: React.FC<TableImage_WithURLProps> = ({item, url = ""}) => {
    return (
        <div className={'TableImages TableImage'}>
            <Link to={url} target={"_blank"}>
                <Shared_ProductImage item={item} />
            </Link>
        </div>
    );
};

interface TableImage_WithPreviewProps {
    item: any,
    desc: string
}

export const TableImage_WithPreview: React.FC<TableImage_WithPreviewProps> = ({item, desc}) => {
    return (
        <div className={'TableImages TableImageWithPreview'}>
            <TopImageWithBiggerView img={item.imagePath.replace('.JPG', '.jpg')}>
                <Shared_ProductImage item={item} />
            </TopImageWithBiggerView>
        </div>
    );
};

