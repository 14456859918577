import React from 'react';
import {IconCheckCircle, IconExclamation, IconInfo} from "../../icons/FontAwesome";

interface Props {
    children: any,
    type: "info" | "success" | "danger" | "warning" |any,
    inline?: boolean,
    marginTop?: boolean,
    marginBottom?: boolean,
    marginLeft?: boolean,
    withIcon?: boolean,
}

export const TextAlert: React.FC<Props> = ({children, type, inline = false, marginTop = false, marginBottom = false, marginLeft = false, withIcon = true}) => {

    let classname = 'TextAlert ' + type;
    if (inline)
        classname += ' inline';
    if (marginTop)
        classname += ' add-margin-top';
    if (marginBottom)
        classname += ' add-margin-bottom';
    if (marginLeft)
        classname += ' add-margin-left';

    let icon = <IconInfo/>;
    if (type === 'success') icon = <IconCheckCircle/>;
    if (type === 'danger' || type === 'warning') icon = <IconExclamation/>;
    if (!withIcon) icon = <span></span>;

    return (
        <div className={classname}>
            {icon}{children}
        </div>
    );
};