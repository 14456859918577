export default{

    t1:{ width: '4%'},
    t2:{ width: '8%'},
    t3:{ width: '12%'},
    t4:{ width: '16%'},
    t5:{ width: '20%'},
    t6:{ width: '24%'},
    t7:{ width: '28%'},
    t8:{ width: '32%'},
    t9:{ width: '36%'},
    t10:{ width: '40%'},
    t11:{ width: '44%'},
    t12:{ width: '48%'},
    t13:{ width: '52%'},
    t14:{ width: '56%'},
    t15:{ width: '60%'},
    t16:{ width: '64%'},
    t17:{ width: '68%'},
    t18:{ width: '72%'},
    t19:{ width: '76%'},
    t20:{ width: '80%'},
    t21:{ width: '84%'},
    t22:{ width: '88%'},
    t23:{ width: '92%'},
    t24:{ width: '96%'},
    t25:{ width: '100%'},

    /* half */

    t1h:{ width: '6%'},
    t2h:{ width: '10%'},
    t3h:{ width: '14%'},
    t4h:{ width: '18%'},
    t5h:{ width: '22%'},

}