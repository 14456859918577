import React from 'react';
import DatePicker from 'react-datepicker'
import {IconCalendar} from "../icons/FontAwesome";
import "react-datepicker/dist/react-datepicker.css"

interface Props {
    value: string,
    onChange: any,
    disabled?: boolean,
    isSmall?: boolean,
}

const Datepicker: React.FC<Props> = ({value, onChange, disabled = false, isSmall = true}) => {

    const onChangeDatepicker = (e: any) => {
        let date = new Date(e),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        let dateParsed = [date.getFullYear(), mnth, day].join("-");
        onChange(dateParsed)
    };

    return (
        <div className={'datepicker-wrapper'}>
            <DatePicker
                // selected={new Date(value)}
                selected={(value === null)? null : new Date(value)}
                onChange={onChangeDatepicker}
                disabled={disabled}
                popperPlacement="top-start"
                dateFormat={"yyyy-MM-dd"}
                placeholderText={'bezterminowo'}
                className={isSmall ? 'form-control form-control-sm' : 'form-control'}
            />
            <IconCalendar/>
        </div>
    );
};

export default Datepicker;