import React, {Component} from 'react';
import {Col, Row} from "react-bootstrap";
import okladkaDefault from "../../resources/images/nopicture.png";

//@ts-ignore
import {LazyLoadImage} from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

interface Props {
    promocja: any,
}

class PromocjaHeader extends Component<Props> {
    render() {

        let {promocja} =this.props;

        return (
            <div id={'PromocjaHeader'}>
                    <Row>
                        <Col md={12} lg={12} xl={12}>
                            <div className={'promocja-description'}>
                                <h2>
                                    {promocja.nazwa}
                                </h2>
                                <h6>
                                    Ważna: {promocja.dataOd} - {promocja.dataDo}
                                </h6>
                                {
                                    Boolean(promocja.opis) &&
                                    <p className={'promocja-uwagi'}>
                                        {promocja.opis}
                                    </p>
                                }
                            </div>
                        </Col>
                    </Row>
            </div>
        );
    }
}

export default PromocjaHeader;