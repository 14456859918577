import React, {Component} from 'react';
import {Button, Col, Form, FormGroup, Row} from "react-bootstrap";
import Req from "../../../sharedComponents/formComponents/Req";
import AddFormMargin from "../../../sharedComponents/formComponents/AddFormMargin";
import {IconFilter} from "../../../sharedComponents/icons/FontAwesome";
import {getFirstDayOfCurrentMonthAndYear, getMonthObjFromDate, getYearObjFromDate, monthsArray, yearsArray} from "../../../utilities/calendar_date/calendar_dateUtilities";
import Select from "react-select";
import {SmallerSelectStyles} from "../../../utilities/forms/jsStyles";
import {isMobileDevice} from "../../../utilities/rwd/rwdUtilities";

interface Props {
    handleClickFiltruj: (data: string) => void;
    onClickReklamacjeWys: () => void;
}

class FakturyFilterbox extends Component<Props> {

    state = {
        selectedYear: null,
        selectedMonth: null,
    };

    componentDidMount() {
        this.setYearAndMonth()
    }

    setYearAndMonth = () => {
        this.setState({
            selectedYear: getYearObjFromDate(getFirstDayOfCurrentMonthAndYear()),
            selectedMonth: getMonthObjFromDate(getFirstDayOfCurrentMonthAndYear()),
        });
    };

    onChangeYear = (e: any) => {
        this.setState({selectedYear: e}, () => this.onClickFiltruj())
    };

    onChangeMonth = (e: any) => {
        this.setState({selectedMonth: e}, () => this.onClickFiltruj())
    };

    onClickFiltruj = () => {

        if (Boolean(this.state.selectedYear) && Boolean(this.state.selectedMonth)) {

            //@ts-ignore
            let data = `${this.state.selectedYear.value}-${this.state.selectedMonth.valueString}-01`

            this.props.handleClickFiltruj(data)
        }
    };

    render() {

        let {selectedYear, selectedMonth} = this.state;

        return (
            <div id={'FakturyFilterbox'}>
                <Form>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={4} xl={3} style={{padding: '0'}}>
                            <FormGroup>
                                <Form.Label>Faktury z okresu (rok / miesiąc): <Req/></Form.Label>
                                <Row>
                                    <Col xs={5} sm={5} md={5} lg={5} xl={5} style={{padding: '0'}}>
                                        <Select
                                            options={yearsArray}
                                            value={selectedYear}
                                            onChange={this.onChangeYear}
                                            isClearable={false}
                                            styles={SmallerSelectStyles}
                                            placeholder={'Wybierz rok...'}
                                            isSearchable={!isMobileDevice()}
                                        />
                                    </Col>
                                    <Col xs={7} sm={7} md={7} lg={7} xl={7} id={'miesiac'}>
                                        <Select
                                            options={monthsArray}
                                            value={selectedMonth}
                                            onChange={this.onChangeMonth}
                                            isClearable={false}
                                            styles={SmallerSelectStyles}
                                            placeholder={'Wybierz miesiąc...'}
                                            isSearchable={!isMobileDevice()}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Col>
                        <Col xs={6} sm={7} md={3} lg={2} xl={4} style={{padding: '0'}}>
                            <FormGroup>
                                <AddFormMargin hideForMobileDevices575={true} />
                                <Button type={'button'} variant={'primary'} size={'sm'} onClick={this.onClickFiltruj}>
                                    <IconFilter/> Filtruj
                                </Button>
                            </FormGroup>
                        </Col>
                        <Col xs={6} sm={5} md={3} lg={6} xl={5} style={{textAlign: 'right', paddingRight: '0'}}>
                            <AddFormMargin hideForMobileDevices575={true} />
                            <Button type={'button'} variant={'light'} size={'sm'} onClick={this.props.onClickReklamacjeWys}>
                                Wysłane reklamacje
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
}

export default FakturyFilterbox;
