import React, {Component} from 'react';
import LayoutComponent from "../../../sharedComponents/layout/LayoutComponent";
import AdminKomunikatyTable from "./components/AdminKomunikatyTable";
import TopTableGridSwitcher from "../../../sharedComponents/TopTableGridSwitcher";
import AdminKomunikatyGrid from "./components/AdminKomunikatyGrid";
import PopupWrapper from "../../../sharedComponents/popups/PopupWrapper";
import {Button, Col, Row} from "react-bootstrap";
import {IconAdd} from "../../../sharedComponents/icons/FontAwesome";
import AdminKomunikatyForm from "./components/AdminKomunikatyForm";
import {isMobileDevice} from "../../../utilities/rwd/rwdUtilities";
import AdminService from "../../../services/AdminService";
import TopLoading from "../../../sharedComponents/loader/TopLoading";
import {_omit} from "../../../utilities/lodash/lodashUtilities";
import {
    displayNotificationError,
    displayNotificationSuccess
} from "../../../utilities/notifications/NotificationsManager";

class AdminKomunikatyModule extends Component {

    state = {
        currentView: true, // table - true, grid - false
        komunikaty: [],
        komunikat: false,
        showFormularz: false,

        isRequestLoading: true,
        isRequestError: false,

        page: 0,
        size: 12,
        pageableObject: {
            currentPage: 0,
            currentSize: 0,
            totalElements: 0
        },
        hasMoreForGrid: true,
        canLoadMore: true,
        loading: false
    };

    componentDidMount() {

        if(isMobileDevice())
            window.scrollTo(0,0);

        this.getKomunikaty();
        if (isMobileDevice())
            this.setState({
                currentView: false
            });
    }

    getKomunikaty = (page: number = this.state.page, size: number = this.state.size) => {

        this.setState({
            // isRequestLoading: true,
            isRequestError: false,
        });

        AdminService.getKomunikaty(page, size)
            .then((response) => {
                if (response && response.status < 300) {
                    this.setState({
                        komunikaty: response.data.content,
                        isRequestLoading: false,
                        isRequestError: false,
                        loading: false,
                        pageableObject: Object.assign({}, _omit(response.data, "content"), {currentPage: page}, {currentSize: size}),
                        hasMoreForGrid: !(response.data.totalElements === response.data.content.length),
                        canLoadMore: true
                    });
                } else
                    this.setState({
                        isRequestLoading: false,
                        isRequestError: true,
                        loading: false,
                        hasMoreForGrid: false,
                        canLoadMore: false
                    });
            })
            .catch((error: any) => {
                this.setState({
                    isRequestLoading: false,
                    isRequestError: true,
                    loading: false,
                    hasMoreForGrid: false,
                    canLoadMore: false
                });
            })
    };

    onClickEdit = (komunikat: any) => {
        this.setState({
            komunikat,
            showFormularz: true
        });
    };

    onClickDelete = (komunikat: any) => {
        // alert('usuniecie komunikatu o id: ' + komunikat.id);

        AdminService.delKomunikat(komunikat.id).then((response) => {

            if(response.data === 1)
            {
                this.getKomunikaty();
                displayNotificationSuccess('Usunięto komunikat', '');
            } else
            {
                displayNotificationError('Wystąpił błąd podczas próby usunięcia komunikatu', '');
            }

        }).catch((e) => {
            displayNotificationError('Wystąpił błąd podczas próby usunięcia komunikatu', '');
        })
    };


    /* table functions  */

    onChangePageInTable = (page: number, size: number) => {
        this.setState({
            page: page - 1,
            size: size,
            loading: true
        }, () => this.getKomunikaty())
    };

    onChangeSizeInTable = (size: number) => {
        this.setState({
            size,
            loading: true
        }, () => this.getKomunikaty())
    };

    /* grid functions */

    loadMoreElementsForGrid = (e: any) => {

        if (this.state.canLoadMore) {

            this.setState({canLoadMore: false, isRequestLoading: true})

            let {komunikaty}: any = this.state;

            this.setState((prevState: any) => ({page: prevState.page + 1}), () => {

                AdminService.getKomunikaty(this.state.page, this.state.size)
                    .then(response => {
                        if (response.status < 300) {

                            this.setState({
                                isRequestLoading: false,
                                isRequestError: false,
                                pageableObject: Object.assign({}, _omit(response.data, "content"), {currentPage: this.state.page}, {currentSize: 12}),
                                canLoadMore: true,
                                hasMoreForGrid: (response.data.totalPages > this.state.page)
                            });

                            response.data.content.forEach((item: any) => {
                                komunikaty.push(item)
                            });

                            this.setState({komunikaty});

                        } else {
                            this.setState({
                                canLoadMore: true,
                                isRequestLoading: false,
                                isRequestError: true,
                                hasMoreForGrid: false
                            });
                        }
                    })
                    .catch(error => {
                        this.setState({
                            canLoadMore: true,
                            isRequestLoading: false,
                            isRequestError: true,
                            hasMoreForGrid: false
                        });
                    })
            })

        }

    };

    onClickWyslij = (data: any) => {
        let {komunikat} = this.state;

        if(komunikat === false)
        {
            AdminService.addKomunikat(data).then((response) => {
                this.setState({showFormularz: false, komunikat: false})
                this.getKomunikaty();
                displayNotificationSuccess('Pomyślnie wysłano komunikat', '');
            }).catch((e) => {
                this.setState({showFormularz: false, komunikat: false})
                displayNotificationError('Wystąpił błąd podczas próby wysłania komunikatu', '')
            })
        } else
        {
            // @ts-ignore
            data['id'] = komunikat.id;
            // @ts-ignore
            data['niePokazuj'] = komunikat.niePokazuj;

            AdminService.updKomunikat(data).then((response) => {
                this.setState({showFormularz: false, komunikat: false})
                this.getKomunikaty();
                displayNotificationSuccess('Pomyślnie zapisano komunikat', '');
            }).catch((e) => {
                this.setState({showFormularz: false, komunikat: false})
                displayNotificationError('Wystąpił błąd podczas próby edycji komunikatu', '')
            })

        }

    }

    render() {

        let {komunikaty, currentView, komunikat, showFormularz, pageableObject, isRequestLoading, isRequestError,hasMoreForGrid, loading} = this.state;

        return (
            <LayoutComponent breadcrumbs={[{url: "/admin", name: 'PA'}, {url: "/komunikaty", name: 'Komunikaty'}]} isAdminPanel={true}>
                <div id={'ADMIN_MODULE'}>
                    <div id={'KOMUNIKATY_MODULE'}>
                        <Row>
                            <Col xs={4} sm={4}>
                                <Button variant={'success'} size={'sm'} id={'add-komunikat-btn'} onClick={() => this.setState({showFormularz: true})}><IconAdd/> Dodaj</Button>
                            </Col>
                            <Col xs={8} sm={8}>
                                <TopTableGridSwitcher currentView={currentView} quantity={komunikaty.length} handleChangeView={(currentView: boolean) => this.setState({currentView})}/>

                            </Col>
                        </Row>
                        {
                            currentView ?
                                <TopLoading isLoading={isRequestLoading} isError={isRequestError}>
                                    <AdminKomunikatyTable komunikaty={komunikaty}
                                                          handleClickEdit={this.onClickEdit}
                                                          handleClickDelete={this.onClickDelete}
                                                          pageableObject={pageableObject}
                                                          handleChangePage={this.onChangePageInTable}
                                                          handleChangeSizePerPage={this.onChangeSizeInTable}
                                                          loading={loading}
                                    />
                                </TopLoading>
                                :
                                <AdminKomunikatyGrid komunikaty={komunikaty} handleClickEdit={this.onClickEdit} handleClickDelete={this.onClickDelete}
                                                     isLoading={isRequestLoading} hasMore={hasMoreForGrid} handleLoadMoreForGrid={this.loadMoreElementsForGrid}
                                />
                        }
                        {
                            showFormularz &&
                            <PopupWrapper header={Boolean(komunikat) ? 'Edytuj komunikat' : 'Dodaj nowy komunikat'}
                                          shouldNotCloseWithoutClick={true} withoutOverflowY={false}
                                          onClose={() => this.setState({showFormularz: false, komunikat: false})}
                            >
                                <AdminKomunikatyForm handleClickAnuluj={() => this.setState({showFormularz: false, komunikat: false})} handleClickWyslij={this.onClickWyslij} komunikat={komunikat}/>
                            </PopupWrapper>
                        }
                    </div>
                </div>
            </LayoutComponent>
        );
    }
}

export default AdminKomunikatyModule;