import React from 'react';
import {Col, Row} from "react-bootstrap";
//@ts-ignore
import Switch from "react-switch";
import {IconCheck, IconCircle, IconCircleEmpty, IconGrid, IconTable, IconTimes} from "../../../../sharedComponents/icons/FontAwesome";
import LoginService from "../../../../services/LoginService";


interface Props {
    item: any,
    handleChangeValue: (item: any, newValue: any) => void;
}

const UstawieniaAplikacjiItem: React.FC<Props> = ({item, handleChangeValue}) => {

    const renderFriendlyName = (key: string) => {
        // if (key === "drzewoWofercie") return 'Wyświetlaj domyślnie drzewo w ofercie';
        // if (key === "tabelaDomyslnymTypemDrzewa") return 'Domyślny typ drzewa w ofercie';
        if (key === "tabelaDomyslnymWidokiemOferty") return 'Domyślny widok w ofercie';
        // if (key === "tabelaDomyslnymWidokiemPromocji") return 'Domyślny widok w promocjach';
        // if (key === "tabelaDomyslnymWidokiemProduktowPromocji") return 'Domyślny widok w produktach danej promocji';
        // if (key === "tabelaDomyslnymWidokiemZamowien") return 'Domyślny widok w zamówieniach';
        // if (key === "tabelaDomyslnymWidokiemFaktur") return 'Domyślny widok w fakturach';
        // if (key === "tabelaDomyslnymWidokiemRozrachunkow") return 'Domyślny widok w rozrachunkach';
        if (key === "pokazBaner") return 'Pokaż baner';
        if (key === "zakupNaKrt") return 'Domyślny zakup (szt/krt)';
        if (key === "tabelaDomyslnymWidokiemKoszyka") return 'Domyślny widok w koszyku';
        if (key === "koszykDomyslnieWyswietlajZdjecia") return 'Wyświetlaj zdjęcia w koszyku';
    };

    const returnSwitchComponent = () => {

        // jeśli true to tabela, jeśli false to grid

        if (item.key.indexOf('tabelaDomyslnymWidokiem') > -1 ){
            return (
                <div className={'switch-component'}>
                    <div>
                        <span className={!item.value? 'option active' : 'option'}><IconGrid/> grid</span>
                    </div>
                    <div>
                        <Switch onChange={(e: any) => handleChangeValue(item, e)}
                                checked={item.value}
                                offColor="#fefefe"
                                onColor="#fefefe"
                        />
                    </div>
                    <div>
                        <span className={item.value?'option active' : 'option'}><IconTable/> tabela</span>
                    </div>
                </div>

            )
        }
        else if (item.key === 'zakupNaKrt') {
            return (
                <div className={'switch-component'}>
                    <div>
                        <span className={!item.value? 'option active' : 'option'}><i className="fa fa-lemon-o"/> SZT</span>
                    </div>
                    <div>
                        <Switch onChange={(e: any) => handleChangeValue(item, e)}
                                checked={item.value}
                                offColor="#fefefe"
                                onColor="#fefefe"
                        />
                    </div>
                    <div>
                        <span className={item.value ? 'option active' : 'option'}><i className="fa fa-cube"/> KRT</span>
                    </div>
                </div>

            )
        }
            // jesli true to kategorie jeśli false to producenci
        // else if (item.key === 'tabelaDomyslnymTypemDrzewa') {
        //     return (
        //         <div className={'switch-component'}>
        //             <div>
        //                 <span className={!item.value? 'option active' : 'option'}><IconCircleEmpty/> producenci</span>
        //             </div>
        //             <div>
        //                 <Switch onChange={(e: any) => handleChangeValue(item, e)}
        //                         checked={item.value}
        //                         offColor="#fefefe"
        //                         onColor="#fefefe"
        //                 />
        //             </div>
        //             <div>
        //                 <span className={item.value ? 'option active' : 'option'}><IconCircle/> kategorie</span>
        //             </div>
        //         </div>
        //
        //     )
        // }
        else {
            return (
                <div className={'switch-component'}>
                    <div>
                        <span className={!item.value ? 'option option-false active' : 'option option-false'}><IconTimes/> nie</span>
                    </div>
                    <div>
                        <Switch onChange={(e: any) => handleChangeValue(item, e)}
                                checked={item.value}
                                offColor="#fefefe"
                                onColor="#fefefe"
                        />
                    </div>
                    <div>
                        <span className={item.value ? 'option option-true active' : 'option option-true'}><IconCheck/> tak</span>
                    </div>
                </div>

            )
        }
    };

    return (
        (item.key !== "pokazBaner")?
            <div className={'UstawieniaAplikacjiItem'}>
                <Row>
                    <Col sm={6} md={7} lg={8} xl={8}>
                        {renderFriendlyName(item.key)}
                    </Col>
                    <Col sm={6} md={5} lg={4} xl={4}>
                        {returnSwitchComponent()}
                    </Col>
                </Row>
            </div>
            :
            <></>
        // :
        //     (LoginService.czyAdmin())?
        //         <div className={'UstawieniaAplikacjiItem'}>
        //             <Row>
        //                 <Col sm={6} md={7} lg={8} xl={8}>
        //                     {renderFriendlyName(item.key)}
        //                 </Col>
        //                 <Col sm={6} md={5} lg={4} xl={4}>
        //                     {returnSwitchComponent()}
        //                 </Col>
        //             </Row>
        //         </div>
        //         :
        //         <div></div>
    );
};

export default UstawieniaAplikacjiItem;