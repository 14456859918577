import React, {Component} from 'react';

interface Props {
    text: any,
    howManyLettersVisible: number
}

class OverflowTextWrapper extends React.Component<Props> {

    state = {
        isCollapsed: false
    };

    onClickComponent = () => {
        this.setState((prevState: any) => ({    // prevState?
            isCollapsed: !prevState.isCollapsed
        }));
    };

    render() {

        let {text, howManyLettersVisible} = this.props;
        let {isCollapsed} = this.state;

        if (Boolean(text))
            return (
                <div className={'OVERFLOW_TEXT_WRAPPER'} title={(text.length > howManyLettersVisible)?'Kliknij aby rozwinąć bądź zwinąć':''}
                     onClick={this.onClickComponent}>
                    {
                        text.length > howManyLettersVisible ?
                            isCollapsed ?
                                text
                                :
                                text.substr(0, howManyLettersVisible-3) + '...'
                            :
                            text
                    }
                </div>
            );
        else return (<span></span>)
    }
}

export default OverflowTextWrapper;