import React from 'react';
import {Button} from 'react-bootstrap';
import {IconCart, IconLoading} from "../../icons/FontAwesome";
import {Link} from 'react-router-dom';
import {formatMoney} from "../../../utilities/formatters/formatMoney";
import {isMobileDevice} from "../../../utilities/rwd/rwdUtilities";

interface Props {
    stanKoszykaObject: any
}

const BasketHeaderComponent: React.FC<Props> = ({stanKoszykaObject}) => {
    return (
        <Link to={'/koszyk'} id={'basket-header'}>
            <Button type={'button'} variant={'light'} onClick={() => {
            }}>
                {
                    stanKoszykaObject.isLoadingStan ?
                        <><IconLoading/> {!isMobileDevice() && <span className={'loader-label'}>Trwa odświeżanie</span>}</>
                        :
                        stanKoszykaObject.isErrorStan ?
                            <span>error</span>
                            :
                            stanKoszykaObject.stanKoszyka &&
                            <>
                                <IconCart/>
                                {
                                    isMobileDevice()?
                                        (stanKoszykaObject.stanKoszyka.iloscPozycji >0 )&&<span className={'badge badge-primary'}>{stanKoszykaObject.stanKoszyka.iloscPozycji}</span>
                                        :
                                        <span>
                                            Koszyk ({stanKoszykaObject.stanKoszyka.iloscPozycji}) {formatMoney(stanKoszykaObject.stanKoszyka.wartosc)}
                                        </span>
                                }
                            </>
                }
            </Button>
        </Link>
    );
};

export default BasketHeaderComponent;