import React, {Component} from 'react';
import {IconFileTxt, IconSave, IconTimes} from "../../../../sharedComponents/icons/FontAwesome";
import {
    displayNotificationError,
    displayNotificationSuccess
} from "../../../../utilities/notifications/NotificationsManager";

//@ts-ignore
import Files from 'react-files'
import {Button} from "react-bootstrap";
import {_remove, _omit} from "../../../../utilities/lodash/lodashUtilities";
//@ts-ignore
import DragSortableList from 'react-drag-sortable'

interface Props {
    handleClickZapisz: (arr:any) =>void
}

class PromocjaDefinicjaForm extends Component<Props> {

    state = {
        currentFiles: []
    };

    onChooseFile = (files: any) => {
        if (Boolean(files) && files.length > 0) {
            displayNotificationSuccess('Pomyślnie wybrano pliki', 'Pliki zostały wczytane w aplikacji')
            this.setState({currentFiles: files});

            this.sendFilesToParent(files)
        }
    };

    onFileError = (e: any) => {
        let message = 'Nastąpił błąd podczas próby wybrania plików'
        if (Boolean(e)) {
            message = e.message;
        }

        displayNotificationError('Błąd wyboru plików', message)
    };

    onClickDelete = (file:any) =>{
        let files = this.state.currentFiles;
        _remove(files,"id",file.id)
        this.setState({currentFiles:files})

        this.sendFilesToParent(files)
    };

    onClickSave = () => {

        this.sendFilesToParent(this.state.currentFiles)
    };

    sendFilesToParent = (files:any) =>{
        let arr = Array.from(files, (file: any,index:number) =>
            ({
                strona: index,
                plik: file.preview.url,
                name: 'gazetka-strona-'+index,
                areas: [],
                imgDimensions: {width: 0, height: 0},
            }));

        this.props.handleClickZapisz(arr)
    };

    onSortChange = (sortedArray:any) =>{

        let arr = Array.from(sortedArray, (file:any)=>(
            _omit(file,"content")
        ))

        this.setState({currentFiles:arr});
        this.sendFilesToParent(arr)
    };

    render() {
        return (
            <div>
                <Files
                    className='file-dropzone'
                    onChange={this.onChooseFile}
                    onError={this.onFileError}
                    multiple={true}
                    accepts={['.jpg', '.jpeg', '.png']}
                    maxFileSize={10000000}
                    minFileSize={0}
                    clickable
                >
                    <IconFileTxt/> Kliknij aby wczytać bądź przenieś pliki .jpg .jpeg lub .png
                </Files>

                {
                    (this.state.currentFiles.length>0) &&
                        <div>
                            Dodane pliki (kliknij i przeciągnij aby zmienić kolejność):
                        </div>
                }

                <DragSortableList items={

                    Array.from(this.state.currentFiles,(file:any)=>(
                        {
                            ...file,
                            content:(
                                <div
                                    style={{display: 'inline-block', margin: '5px', padding: '15px', border: '1px solid #ddd'}}>
                                    <img src={file.preview.url} alt={'zaladownay obraz'}
                                         style={{height: '100px', marginRight: '5px'}} id={file.id}/>
                                    <span className={'text-danger'} style={{cursor: 'pointer'}} onClick={()=>this.onClickDelete(file)}><IconTimes/></span>
                                </div>
                            )
                        }
                    ))
                }
                                  placeholder={<div style={{background:'#ddd',display:'inline-block'}}>Drop me here!</div>}
                                  onSort={this.onSortChange}
                                  dropBackTransitionDuration={0.3}
                                  type="grid"
                />

                {/*<div>
                    <Button onClick={this.onClickSave}><IconSave/> Zapisz</Button>
                </div>*/}
            </div>
        );
    }
}

export default PromocjaDefinicjaForm;