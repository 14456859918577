import React from 'react';
import {BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom";
// import 'font-awesome/css/font-awesome.min.css';
import LoginModule from "./modules/login/LoginModule";
import InformacjeModule from "./modules/informacje/InformacjeModule";
import OfertaModule from "./modules/oferta/OfertaModule";
import PromocjeModule from "./modules/promocje/PromocjeModule";
import PromocjaModule from './modules/promocja/PromocjaModule';
import ZamowieniaModule from "./modules/zamowienia/ZamowieniaModule";
import FakturyModule from "./modules/faktury/FakturyModule";
import RozrachunkiModule from "./modules/rozrachunki/RozrachunkiModule";
import KoszykModule from "./modules/koszyk/KoszykModule";
import ProduktModule from "./modules/produkt/ProduktModule";
import AdminHomeModule from "./modules/admin_modules/admin_home/AdminHomeModule";
import LoginService from "./services/LoginService";
import AdminPromocjeModule from "./modules/admin_modules/admin_promocje/AdminPromocjeModule";
import AdminKlienciModule from "./modules/admin_modules/admin_klienci/AdminKlienciModule";
import UstawieniaModule from "./modules/ustawienia/UstawieniaModule";
import axios from 'axios';
//@ts-ignore;
import {NotificationContainer} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import ApplicationGlobalSettingsService from "./services/ApplicationGlobalSettingsService";
import KatalogModule from "./modules/katalog/KatalogModule";
import OfertaService from "./services/OfertaService";
import {SiecContext} from "./SiecContext";
import PowiadomieniaModule from "./modules/powiadomienia/PowiadomieniaModule";
import localStorageTypes from "./utilities/localStorage/localStorageTypes";
import ResetHaslaModule from "./modules/reset_hasla_modules/reset_hasla/ResetHaslaModule";
import NoweHasloModule from "./modules/reset_hasla_modules/nowe_haslo/NoweHasloModule";
import AdminKomunikatyModule from "./modules/admin_modules/admin_komunikaty/AdminKomunikatyModule";
import QuickAddModule from "./modules/quickAdd/QuickAddModule";
import AdminLogiModule from "./modules/admin_modules/admin_logi/AdminLogiModule";
import AdminKonfiguracjaModule from "./modules/admin_modules/admin_konfiguracja/AdminKonfiguracjaModule";
import AdminReklamacjeModule from "./modules/admin_modules/admin_reklamacje/AdminReklamacjeModule";
import AdminBaneryModule from "./modules/admin_modules/admin_banery/AdminBaneryModule";
import 'font-awesome/css/font-awesome.min.css';
import {displayNotificationError, displayNotificationInfo} from "./utilities/notifications/NotificationsManager";
import NotFoundModule from "./modules/notFound/NotFoundModule";
import "./styles/Contrast.css";
import PromocjeGrid from "./modules/promocje/components/PromocjeGrid";
import { encode, decode } from 'js-base64';
import * as path from "path";
import Unavailable from "./sharedComponents/Unavailable";
import moment from "moment";

axios.interceptors.response.use(function (response:any) {
    // Do something with response data
    return response;
}, function (error:any) {
    // console.log(error)
    // console.log(error.message)
    // console.log(error.request.status);

    if(error.request.status === 401){
        displayNotificationInfo('Brak uprawnień', 'Proszę się ponownie zalogować');
        axios.defaults.headers.common.Authorization = '';
        axios.defaults.headers.common.kontrahent = '';
        LoginService.removeUserFromStorage();
        LoginService.removeUserFromSession();
        window.location.reload();
    }

    if(error.request.status === 500){
        if(error.response.data.message.includes('Edycja zamówienia została zakończona')){
            // displayNotificationError("Edycja zakończona", error.response.data.message)
            setTimeout(() => {
                window.location.reload();
            }, 5500);
            return Promise.reject({message: error.response.data.message});
        }
    }

    return Promise.reject(error);
});

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';
axios.defaults.headers.common.kanal = 'W';

if (LoginService.checkIfUserIsLogged() && !LoginService.checkLocalStorageVersion()) {
    // alert('Wykryto nieaktualną wersję localStorage. Proszę się ponownie zalogować');
    displayNotificationInfo('Wykryto nieaktualną wersję', 'Proszę się ponownie zalogować');
    axios.defaults.headers.common.Authorization = '';
    axios.defaults.headers.common.kontrahent = '';
    LoginService.removeUserFromStorage();
    LoginService.removeUserFromSession();
    window.location.reload();
}

if (LoginService.checkIfUserIsLogged()) {
    if (LoginService.getCurrentUser().token)
        if (LoginService.getCurrentUser().token.indexOf('Basic') > -1) {
            axios.defaults.headers.common.Authorization = LoginService.getCurrentUser().token;
            axios.defaults.headers.common.kontrahent = encode(LoginService.getCurrentUser().kontrahent);
            axios.defaults.headers.common.magazyn = LoginService.getCurrentUser().magazyn;
            OfertaService.updateSieciHandloweInLS(LoginService.getCurrentUser().kontos);
            localStorage.setItem(localStorageTypes.CURRENT_CZY_ODCZYTANO_POWIADOMIENIA,"true")
        } else {
            axios.defaults.headers.common.Authorization = '';
            LoginService.removeUserFromStorage();
        }

    if(Boolean(ApplicationGlobalSettingsService.czyZalogowanyDlugo())){
        displayNotificationInfo('Proszę się ponownie zalogować', '');
        axios.defaults.headers.common.Authorization = '';
        axios.defaults.headers.common.kontrahent = '';
        LoginService.removeUserFromStorage();
        LoginService.removeUserFromSession();
    }
}


interface Props {
    appData: any
}

class App extends React.Component<Props> {

    changeKontrahent = (siecObj: any) => {

        axios.defaults.headers.common.kontrahent = encode(siecObj.kontrahent);

        let user = LoginService.getCurrentUser();
        user.kontrahent = siecObj.kontrahent;
        user.firma = siecObj.firma;
        user.firma2 = siecObj.firma2;
        LoginService.setUserInStorage(user);

        this.setState({
            kontrahent:siecObj.kontrahent
        });
    };

    changeKontrahentAdmin = (siecObj: any) => {

        axios.defaults.headers.common.kontrahent = encode(siecObj.kontrahent);
        axios.defaults.headers.common.magazyn = siecObj.magazyn;

        console.log(siecObj)

        let user = LoginService.getCurrentUser();
        user.kontrahent = siecObj.kontrahent;
        user.firma = siecObj.firma;
        user.firma2 = siecObj.firma2;
        user.magazyn = siecObj.magazyn;
        if(LoginService.localCzySesion())
            LoginService.setUserInStorage(user);
        else
            LoginService.setUserInSesion(user);

        this.setState({
            kontrahent: siecObj.kontrahent
        });
    };

    state = {
        kontrahent: LoginService.checkIfUserIsLogged() ? LoginService.getCurrentUser().kontrahent : '',
        changeKontrahent: this.changeKontrahent,
        changeKontrahentAdmin: this.changeKontrahentAdmin
    };

    czyAwaria = (): boolean => {
        return (JSON.parse(this.props.appData).awaria)
    }

    czyGodzSerwisowa = (): boolean => {
        return (moment().isAfter(moment(JSON.parse(this.props.appData).start_time, 'HH:mm')) && moment().isBefore(moment(JSON.parse(this.props.appData).end_time, 'HH:mm')))
    }

    czyZaplPrzerwa = (): boolean => {
        return (moment().isAfter(moment(JSON.parse(this.props.appData).serwis_start_time, 'YYYY.MM.DD HH:mm')) && moment().isBefore(moment(JSON.parse(this.props.appData).serwis_end_time, 'YYYY.MM.DD HH:mm')))
    }

    czySobota = (): boolean => {
        return ((Number(moment().format('d')) === 6) && (moment().isAfter(moment('22:00', 'HH:mm')))) || ((Number(moment().format('d')) === 7) && moment().isBefore(moment('6:00', 'HH:mm')))
    }

    render() {

        if (LoginService.checkIfUserIsLogged()) {
            ApplicationGlobalSettingsService.setGlobalSettingsConfiguration();
        }
        console.log(moment().format('d'))

        return (
            <div className="App">
                {this.czyAwaria()? <Unavailable appData={this.props.appData} awaria/> : <>
                    {this.czyGodzSerwisowa()? <Unavailable appData={this.props.appData}/> : <>
                        {this.czySobota()? <Unavailable appData={"{\"start_time\":\"" + '22:00' + "\",\"end_time\":\"" + '6:00' + "\"}"}/> : <>
                            {this.czyZaplPrzerwa()? <Unavailable appData={"{\"start_time\":\"" + moment(JSON.parse(this.props.appData).serwis_start_time).format('DD.MM.YYYY HH:mm') + "\",\"end_time\":\"" + moment(JSON.parse(this.props.appData).serwis_end_time).format('DD.MM.YYYY HH:mm') + "\"}"}/> :
                                <SiecContext.Provider value={this.state}>
                                    <Router>
                                        <Switch>
                                            <Route exact path={'/'}
                                                   render={(props: any) => (LoginService.checkIfUserIsLogged()) ?
                                                       <Redirect to={'/oferta'}/> : <Redirect to={'/login'}/>}/>
                                            <Route exact path={'/oferta'}
                                                   render={(props: any) => (LoginService.checkIfUserIsLogged()) ?
                                                       <OfertaModule {...props} /> : <Redirect to={'/login'}/>}/>
                                            {/*
                    <Route exact path={'/oferta/:kategoria'}
                           render={(props: any) => (LoginService.checkIfUserIsLogged()) ? <OfertaModule {...props} /> : <Redirect to={'/login'}/>}/>
                    <Route exact path={'/oferta/:kategoria/:podkategoria'}
                           render={(props: any) => (LoginService.checkIfUserIsLogged()) ? <OfertaModule {...props} /> : <Redirect to={'/login'}/>}/>
                    <Route exact path={'/oferta/:kategoria/:podkategoria/:produkt'}
                           render={(props: any) => (LoginService.checkIfUserIsLogged()) ? <ProduktModule {...props} /> : <Redirect to={'/login'}/>}/>
                           */}
                                            <Route exact path={'/oferta/:indeks'}
                                                   render={(props: any) => (LoginService.checkIfUserIsLogged()) ?
                                                       <ProduktModule {...props} /> : <Redirect to={'/login'}/>}/>
                                            <Route exact path={'/oferta/activelink/:indeks/:idum'}
                                                   render={(props: any) => (LoginService.checkIfUserIsLogged()) ?
                                                       <OfertaModule {...props} /> :
                                                       <LoginModule {...props} active={true} appData={this.props.appData}/>}/>
                                            <Route exact path={'/informacje'}
                                                   render={(props: any) => (LoginService.checkIfUserIsLogged()) ?
                                                       <InformacjeModule {...props} /> : <Redirect to={'/login'}/>}/>
                                            <Route exact path={'/promocje'}
                                                   render={(props: any) => (LoginService.checkIfUserIsLogged()) ?
                                                       <PromocjeModule {...props} /> : <Redirect to={'/login'}/>}/>
                                            <Route exact path={'/promocje/:id'}
                                                   render={(props: any) => (LoginService.checkIfUserIsLogged()) ?
                                                       <PromocjaModule {...props} czyZLinku={true}/> :
                                                       <Redirect to={'/login'}/>}/>
                                            <Route exact path={'/promocje/prom/:id'}
                                                   render={(props: any) => (LoginService.checkIfUserIsLogged()) ?
                                                       <PromocjeGrid {...props} /> : <Redirect to={'/login'}/>}/>
                                            <Route exact path={'/zamowienia'}
                                                   render={(props: any) => (LoginService.checkIfUserIsLogged()) ?
                                                       <ZamowieniaModule {...props} /> : <Redirect to={'/login'}/>}/>
                                            <Route exact path={'/faktury'}
                                                   render={(props: any) => (LoginService.checkIfUserIsLogged()) ?
                                                       <FakturyModule {...props} /> : <Redirect to={'/login'}/>}/>
                                            <Route exact path={'/rozrachunki'}
                                                   render={(props: any) => (LoginService.checkIfUserIsLogged()) ?
                                                       <RozrachunkiModule {...props} /> : <Redirect to={'/login'}/>}/>
                                            <Route exact path={'/koszyk'}
                                                   render={(props: any) => (LoginService.checkIfUserIsLogged()) ?
                                                       <KoszykModule {...props} /> : <Redirect to={'/login'}/>}/>

                                            <Route exact path={'/ustawienia'}
                                                   render={(props: any) => (LoginService.checkIfUserIsLogged()) ?
                                                       <UstawieniaModule {...props} /> : <Redirect to={'/login'}/>}/>

                                            <Route exact path={'/powiadomienia'}
                                                   render={(props: any) => (LoginService.checkIfUserIsLogged()) ?
                                                       <PowiadomieniaModule {...props} /> : <Redirect to={'/login'}/>}/>


                                            {/*<Route exact path={'/quickadd'}*/}
                                            {/*       render={(props: any) => (LoginService.checkIfUserIsLogged()) ? <QuickAddModule {...props} /> : <Redirect to={'/login'}/>}/>*/}


                                            <Route exact path={'/login'}
                                                   render={(props: any) => (LoginService.checkIfUserIsLogged()) ?
                                                       <Redirect to={'/'}/> :
                                                       <LoginModule {...props} appData={this.props.appData} />}/>

                                            <Route exact path={'/reset-hasla'}
                                                   render={(props: any) => (LoginService.checkIfUserIsLogged()) ?
                                                       <Redirect to={'/'}/> :
                                                       <ResetHaslaModule {...props} />}/>

                                            <Route exact path={'/ustaw-nowe-haslo/:token'}
                                                   render={(props: any) => (LoginService.checkIfUserIsLogged()) ?
                                                       <Redirect to={'/'}/> :
                                                       <NoweHasloModule {...props} />}/>


                                            <Route exact path={'/admin'}
                                                   render={(props: any) => (LoginService.czyAdmin()) ?
                                                       <Redirect to={'/admin/home'}/> : <Redirect to={'/'}/>}/>
                                            <Route exact path={'/admin/home'}
                                                   render={(props: any) => (LoginService.czyAdmin()) ?
                                                       <AdminHomeModule {...props} /> : <Redirect to={'/'}/>}/>
                                            <Route exact path={'/admin/promocje'}
                                                /*render={(props: any) => (LoginService.checkIfCurrentUserIsAdmin()) ? <AdminPromocjeModule {...props} /> : <Redirect to={'/'}/>}*/
                                                   render={(props: any) => (LoginService.czyAdmin()) ?
                                                       <AdminPromocjeModule {...props} /> : <Redirect to={'/login'}/>}
                                            />
                                            <Route exact path={'/admin/banery'}
                                                   render={(props: any) => (LoginService.czyAdmin()) ?
                                                       <AdminBaneryModule {...props} /> : <Redirect to={'/'}/>}/>
                                            <Route exact path={'/admin/klienci'}
                                                   render={(props: any) => (LoginService.czyAdmin()) ?
                                                       <AdminKlienciModule {...props} /> : <Redirect to={'/'}/>}/>

                                            <Route exact path={'/admin/reklamacje'}
                                                   render={(props: any) => (LoginService.czyAdmin()) ?
                                                       <AdminReklamacjeModule {...props} /> : <Redirect to={'/'}/>}/>

                                            <Route exact path={'/admin/komunikaty'}
                                                   render={(props: any) => (LoginService.czyAdmin()) ?
                                                       <AdminKomunikatyModule {...props} /> : <Redirect to={'/'}/>}/>

                                            <Route exact path={'/admin/konfiguracja'}
                                                   render={(props: any) => (LoginService.czyAdmin()) ?
                                                       <AdminKonfiguracjaModule {...props} /> : <Redirect to={'/'}/>}/>

                                            <Route exact path={'/admin/logi'}
                                                   render={(props: any) => (LoginService.czyAdmin()) ?
                                                       <AdminLogiModule {...props} /> : <Redirect to={'/'}/>}/>


                                            {/*<Route exact path={'/katalog'} render={(props: any) => (<KatalogModule {...props}/>)}/>*/}


                                            <Route component={() => <NotFoundModule/>}/>
                                        </Switch>
                                    </Router>
                                    <NotificationContainer/>
                                </SiecContext.Provider>
                            }</>}</>}</>}
            </div>
        );
    }
}

export default App;
