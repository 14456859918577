import React from 'react';
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import TopCard from "../../../../sharedComponents/TopCard";
import {TableFormatters_formatBoolean} from "../../../../sharedComponents/tableComponents/tableFormatters";

interface Props {
    logi: any[]
}

const AdminLogiTable: React.FC<Props> = ({logi}) => {

    const formatTypRow = (cell: string) => {
        let className = 'light';

        if (cell === 'error') className = 'danger';
        if (cell === 'debug') className = 'warning';

        return <span className={`badge badge-${className}`} style={{fontSize: '0.65rem'}}>{cell}</span>
    };

    const formatUseridRow = (cell: any, row: any) => {
        if (cell > 0)
            return <><span className={'badge badge-secondary'} style={{fontSize: '0.65rem'}}>{cell}</span> {row.username}</>
        else return ''
    };

    const formatCzyKlientRow = (cell: boolean) => {
        return <TableFormatters_formatBoolean value={cell} titleFalse={'jest pracownikiem'} titleTrue={'jest klientem'}/>
    };

    return (
        <div id={'AdminLogiTable'}>
            <TopCard heading={'Ustawienia systemu'} hasPadding={false}>
                <BootstrapTable
                    data={logi}
                    hover={true}>
                    <TableHeaderColumn isKey={true} dataField={'id'}
                                       width={'100'}
                                       dataSort={true}
                    >ID</TableHeaderColumn>
                    <TableHeaderColumn dataField={'data'}
                                       width={'150'}
                                       dataSort={true}
                    >Data</TableHeaderColumn>
                    <TableHeaderColumn dataField={'typ'}
                                       width={'100'}
                                       dataSort={true}
                                       dataFormat={formatTypRow}
                    >Typ</TableHeaderColumn>
                    <TableHeaderColumn dataField={'wartosc'}
                                       width={'400'}
                                       dataSort={true}
                    >Wartość</TableHeaderColumn>
                    <TableHeaderColumn dataField={'czyKlient'}
                                       width={'100'}
                                       dataSort={true}
                                       dataFormat={formatCzyKlientRow}
                    >Klient?</TableHeaderColumn>
                    <TableHeaderColumn dataField={'userid'}
                                       width={'150'}
                                       dataSort={true}
                                       dataFormat={formatUseridRow}
                    >User ID</TableHeaderColumn>
                </BootstrapTable>
            </TopCard>
        </div>
    );
};

export default AdminLogiTable;