import React from 'react';
import TopCard from "../../../sharedComponents/TopCard";
import avatar from '../../../resources/images/useravatar.png';
import {IconEnvelope, IconPhone} from "../../../sharedComponents/icons/FontAwesome";
import LoginService from "../../../services/LoginService";
import axios from "axios";
import UstawieniaService from "../../../services/UstawieniaService";
import { QRCode } from "react-qr-svg";
import {TextAlert} from "../../../sharedComponents/typographyComponents/TextAlert/TextAlert";
import ios from './../../../resources/images/appstore.png';
import android from './../../../resources/images/googleplay.png';
import {Col, Row} from "react-bootstrap";
import mobile from './../../../resources/images/mobile-mockup.png';

interface Props {
}

interface State {
}

class InformacjeAplikacjaComponent extends React.Component<Props, State> {

    render() {
        return (
            <div id={'AplikacjeComponent'}>
                <TopCard heading={'Aplikacja mobilna'} hasPadding={true}>

                    <Row>
                        <Col xl={9} className={'my-auto'}>
                            <h6 className={'heading-label'} style={{margin: '5px 0 15px', fontStyle: 'inherit'}}>
                                Aplikacja mobilna eHurt pozwala na pełny dostęp przez klienta do oferty handlowej firmy Famix,
                                promocji, złożenia zamówienia, informacji o postępie złożonego zamówienia, rozrachunkach,
                                możliwości pobrania faktury lub zgłoszenia reklamacji.
                            </h6>

                            <h6 className={'heading-label'} style={{margin: '5px 0 15px', fontStyle: 'inherit'}}>
                                <p>
                                    Famix zawsze pod ręką! - nasza najnowsza aplikacja mobilna pozwala na więcej:
                                </p>
                                <ul>
                                    <li>Szybkie składanie zamówień, również na sklepie skanując kody EAN w komórce,</li>
                                    <li>Podgląd zdjęć produktów, także oferty sezonowe,</li>
                                    <li>Możliwość podglądu i edycji wszystkich zamówień złożonych elektronicznie,</li>
                                    <li>Możesz wydrukować lub pobrać fakturę do importu, do programu sklepowego,</li>
                                    <li>Wspólny koszyk pomiędzy ehurtem i aplikacją mobilną,</li>
                                    <li>Bądź pierwszy! specjalne dedykowane promocje, nawet w wersjach do wyczerpania zapasu,</li>
                                    <li>Ceny z uwzględnieniem wszystkich rabatów stałych i promocyjnych!</li>
                                </ul>
                                <p>
                                    Prezentacja aplikacji mobilnej Famix - <a href={'https://youtu.be/5pW38N_D74w'} target={'_blank'}>https://youtu.be/5pW38N_D74w</a>
                                </p>
                            </h6>
                        </Col>
                        <Col xl={3} className={'mx-auto'}>
                            <img src={mobile} alt={'Aplikacja mobilna Famix'} style={{maxWidth: '250px', width: '100%'}}/>
                        </Col>
                    </Row>


                    <h3 style={{textAlign: 'center', padding: '5px', borderRadius: '3px', margin: '5px 0 25px 0', background: 'rgba(233,170,1,0.73)'}}>Zacznij korzystać już dziś!</h3>

                    <Row>
                        <Col>
                            <h3 style={{textAlign: 'center'}}>iOS</h3>
                            <div className={'box-inner'}>
                                <div style={{paddingLeft: '0'}}>
                                    <QRCode
                                        bgColor="#FFFFFF"
                                        fgColor="#000000"
                                        level="L"
                                        style={{ width: 150 }}
                                        value={'https://apps.apple.com/pl/app/famix-ehurt/id1611742037?l=pl'}
                                    />
                                </div>
                                <div style={{paddingLeft: '15px'}}>

                                    <TextAlert type={"info"}>
                                        Aplikację mobilną na system iOS można pobrać po zeskanowaniu kodu QR lub <a href={'https://apps.apple.com/pl/app/famix-ehurt/id1611742037?l=pl'}>klikając tutaj</a>.
                                    </TextAlert>

                                    <a href={'https://apps.apple.com/pl/app/famix-ehurt/id1611742037?l=pl'}>
                                        <img src={ios} alt={'ios'}/>
                                    </a>

                                </div>
                            </div>
                        </Col>

                        <Col>
                            <h3 style={{textAlign: 'center'}}>Android</h3>
                            <div className={'box-inner'}>
                                <div style={{paddingLeft: '0'}}>
                                    <QRCode
                                        bgColor="#FFFFFF"
                                        fgColor="#000000"
                                        level="L"
                                        style={{ width: 150 }}
                                        value={'https://play.google.com/store/apps/details?id=topsa.famix.ehurt'}
                                    />
                                </div>
                                <div style={{paddingLeft: '15px'}}>

                                    <TextAlert type={"info"}>
                                        Aplikację mobilną na system Android można pobrać po zeskanowaniu kodu QR lub <a href={'https://play.google.com/store/apps/details?id=topsa.famix.ehurt'}>klikając tutaj</a>.
                                    </TextAlert>

                                    <a href={'https://play.google.com/store/apps/details?id=topsa.famix.ehurt'}>
                                        <img src={android} alt={'android'}/>
                                    </a>

                                </div>
                            </div>
                        </Col>

                    </Row>

                    <hr style={{marginTop: '35px'}}/>
                    <Row>
                        <Col>
                            <h6>Po zainstalowaniu zaloguj się loginem i hasłem - takim samym, jak do ehurtu Famix.</h6>
                        </Col>
                    </Row>

                </TopCard>
            </div>
        );
    }
};

export default InformacjeAplikacjaComponent;