import React, {Component} from 'react';
import {isMobileDevice} from "../../utilities/rwd/rwdUtilities";
import LoginService from "../../services/LoginService";
import LayoutComponent from "../../sharedComponents/layout/LayoutComponent";
import {Accordion, Badge, Button, Col, Form, FormGroup, Row} from "react-bootstrap";
import {IconCartPlus, IconCheck, IconNotification} from "../../sharedComponents/icons/FontAwesome";
import PowiadomieniaService from "../../services/PowiadomieniaService";
import TopLoading from "../../sharedComponents/loader/TopLoading";
//@ts-ignore
import {LazyLoadImage} from 'react-lazy-load-image-component';
import {formatMoney} from "../../utilities/formatters/formatMoney";
// @ts-ignore
import InputNumber from 'rc-input-number';
import {Link} from "react-router-dom";
import KoszykService from "../../services/KoszykService";
import {displayNotificationError, displayNotificationInfo} from "../../utilities/notifications/NotificationsManager";

class PowiadomieniaModule extends Component {

    state = {
        activeKey: "1",
        komunikaty: [],
        isLoadingStan: true,
        isErrorStan: false,
        dodawanie: false,
        shouldKoszykUpdate: false
    };

    componentDidMount() {
        if (isMobileDevice())
            window.scrollTo(0, 0);
        this.pobierzPowiadomienia();
    }

    pobierzPowiadomienia = () => {
        this.setState({
            isLoadingStan: true,
            isErrorStan: false
        });

        PowiadomieniaService.getKomunikatyList().then((response) => {

            this.parsujKomunikat(response.data);

        }).catch((e) => {
            this.setState({
                isLoadingStan: false,
                isErrorStan: true
            });
            console.log('error');
        })
    }

    parsujKomunikat = (komuni: any) => {

        let parse = komuni;

        parse.filter((kom: any) => kom.typ === 'A').forEach((kom: any) => {
            kom.komunikat = JSON.parse(kom.komunikat);
            kom.komunikat.ilosc = 0;
        });

        this.setState({komunikaty: parse, isLoadingStan: false, isErrorStan: false});
    }

    onClickPowiadomienie = (id: any, odczytano: boolean) => {
        let {komunikaty} = this.state;
        let powiadomienie = komunikaty.find((k: any) => k.id === id);

        if (!odczytano && powiadomienie)
        {
            PowiadomieniaService.potwierdzOdczyt(id).then((response) => {
                // @ts-ignore
                powiadomienie.dataOdczytu = 'odczytano';
                this.setState({komunikaty});
            }).catch((e) => {
                console.log('error');
            })
        }

    }

    // onClickInput = (index: number, indeks: any) => {
    //     let {komunikaty} = this.state;
    //
    //     // @ts-ignore
    //     let amount = komunikaty[index].komunikat.ilosc;
    //
    //     // alert(indeks)
    //
    //     if (amount===0){
    //         //@ts-ignore
    //         if (Boolean(document.getElementById('input-' + indeks))) {
    //             //@ts-ignore
    //             document.getElementById('input-' + indeks).value = '';
    //         }
    //     }
    // }

    onPressEnter = (e: any, item: any) => {
        e.preventDefault();
        this.onClickButtonDodaj(item);
    };

    onChangeIlosc = (e: any, index: number) => {
        let {komunikaty} = this.state;

        // @ts-ignore
        komunikaty[index].komunikat.ilosc = e;

        this.setState({komunikaty});
    }

    onClickButtonDodaj = (item: any) => {

        let data = {
            indeks: item.indeks,
            ilosc: item.ilosc,
            zakupJm: item.jm,
            idUm: item.idUmowy
        }

        if(item.ilosc > 0)
        {
            this.setState({dodawanie: true});

            KoszykService.addEditKoszykActiveLink(data).then((response) => {
                displayNotificationInfo('Dodano produkt do koszyka', `Dodano produkt ${item.nazwa} w ilości ${item.ilosc} ${item.jm}`);
                this.setState({dodawanie: false, shouldKoszykUpdate: true});
            }).catch((e) => {
                displayNotificationError("Nie dodano produktu do koszyka", "Wystąpił błąd podczas próby wykonania akcji");
                this.setState({dodawanie: false});
            })
        }

    }

    render() {

        let {komunikaty, isErrorStan, isLoadingStan, dodawanie, shouldKoszykUpdate} = this.state;

        return (
            <LayoutComponent breadcrumbs={[{url: "/powiadomienia", name: 'Powiadomienia'}]}
                             shouldKoszykUpdate={shouldKoszykUpdate}
                             handleShouldKoszykUpdateChange={() => this.setState({shouldKoszykUpdate: false})}
                             isAdminPanel={LoginService.checkIfCurrentUserIsAdmin()}>
                <div id={'POWIADOMIENIA_MODULE'}>
                    <TopLoading isLoading={isLoadingStan} isError={isErrorStan} isCentered={true} isBig={false} loadingText={'Trwa ładowanie powiadomień'}>
                    {(komunikaty.length > 0)?
                        <Accordion>
                            {
                                komunikaty.map((powiadomienie: any, index) => (
                                    (powiadomienie.typ === 'A')?
                                        <div className={'powiadomienie-row'}>
                                            <Accordion.Toggle as={Row} variant="link" eventKey={powiadomienie.id} onClick={ () => this.onClickPowiadomienie(powiadomienie.id, powiadomienie.dataOdczytu)}>
                                                <Col title={'Kliknij aby rozwinąć lub zwinąć'} style={{padding: '0'}}>
                                                    <div className={!powiadomienie.dataOdczytu ? 'powiadomienie-header nowe' : 'powiadomienie-header'}>
                                                        <div>{powiadomienie.komunikat.tytul.substring(0,20)}{(powiadomienie.komunikat.tytul.length > 20) && '...'}</div>
                                                        <div className={'status'}>
                                                            {
                                                                powiadomienie.dataOdczytu ?
                                                                    <span><IconCheck/> odczytano</span>
                                                                    :
                                                                    <span><IconNotification/> nowe</span>
                                                            }
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Accordion.Toggle>
                                            <Row>
                                                <Accordion.Collapse eventKey={powiadomienie.id}>
                                                    <Col xl={12}>
                                                        <div className={'powiadomienie-content'}>

                                                            <Row style={{marginBottom: '15px'}}>
                                                                <Col md={4} lg={3} xl={3}>
                                                                    <LazyLoadImage
                                                                        src={
                                                                            powiadomienie.komunikat.produktUrl.replace('.JPG', '.jpg')
                                                                        }
                                                                        alt={'Produkt ' + powiadomienie.komunikat.nazwa} effect={'blur'}
                                                                        onError={() => {
                                                                            this.setState({error: true})
                                                                        }}
                                                                    />
                                                                </Col>
                                                                <Col md={8} lg={9} xl={9}>


                                                                    <div className={'product-description'}>
                                                                        <h3 title={powiadomienie.komunikat.nazwa}>
                                                                            {powiadomienie.komunikat.nazwa}
                                                                     {/*       <Link to={'/oferta/' + product.indeks}>*/}
                                                                     {/*           /!* <OverflowTextWrapper text={item.producent + ', ' + item.nazwa}*/}
                                                                     {/*howManyLettersVisible={45}/>*!/*/}
                                                                     {/*           {*/}
                                                                     {/*               (product.producent + ', ' + product.nazwa).length > 45 ?*/}
                                                                     {/*                   (product.producent + ', ' + product.nazwa).substr(0,42)+'...'*/}
                                                                     {/*                   :*/}
                                                                     {/*                   (product.producent + ', ' + product.nazwa)*/}
                                                                     {/*           }*/}
                                                                     {/*       </Link>*/}
                                                                        </h3>

                                                                        <h6>
                                                                            Indeks: {powiadomienie.komunikat.indeks}
                                                                        </h6>
                                                                        {/*<h6 style={{color: '#777'}}>*/}
                                                                        {/*    EAN szt: {product.nrKat}*/}
                                                                        {/*</h6>*/}
                                                                        {/*{*/}
                                                                        {/*    (product.jm !== product.jm2) ?*/}
                                                                        {/*        <h6>{`W opakowaniu (${product.jm2}) : ${famix_zaokraglijIloscDlaJM1(product.jm, product.ilJm2)} ${product.jm}`}</h6>*/}
                                                                        {/*        :*/}
                                                                        {/*        <h6>{`W kartonie (${product.jm3}) : ${famix_zaokraglijIloscDlaJM1(product.jm, product.ilJm3)} ${product.jm}`}</h6>*/}
                                                                        {/*}*/}
                                                                    </div>

                                                                    <p style={{whiteSpace: 'pre-line'}}>
                                                                        <div dangerouslySetInnerHTML={{ __html: powiadomienie.komunikat.opisHtml }} />
                                                                    </p>

                                                                    <h1 id={'price-container'}>
                                                                        <Badge variant={'warning'}>
                                                                            {
                                                                                formatMoney(powiadomienie.komunikat.cena)
                                                                            }
                                                                        </Badge>
                                                                    </h1>
                                                                    <Form inline={true} style={{marginBottom: '15px'}}>
                                                                        <FormGroup style={{maxWidth: '100px', marginRight: '5px'}}>
                                                                            <InputNumber
                                                                                value={(powiadomienie.komunikat.ilosc)? powiadomienie.komunikat.ilosc : 0}
                                                                                onChange={(e: any) => this.onChangeIlosc(e, index)}
                                                                                // disabled={dodawanie}
                                                                                precision={0}
                                                                                step={1}
                                                                                min={0}
                                                                                onPressEnter={(e: any) => this.onPressEnter(e, powiadomienie.komunikat)}
                                                                                id={'input-' + powiadomienie.komunikat.indeks}
                                                                                // onBlur={() => this.onBlurInput(inline, showButton, item)}
                                                                                // onClick={() => this.onClickInput(index, powiadomienie.komunikat.indeks)}
                                                                            />
                                                                        </FormGroup>
                                                                        <FormGroup>
                                                                            <Button variant={'primary'} size={'sm'} disabled={dodawanie} onClick={() => this.onClickButtonDodaj(powiadomienie.komunikat)}>
                                                                                <><IconCartPlus/> Dodaj</>
                                                                            </Button>
                                                                        </FormGroup>
                                                                    </Form>
                                                                    <Link to={'./oferta/activelink/' + powiadomienie.komunikat.indeks + '/' + powiadomienie.komunikat.idUmowy}>ActiveLink</Link>
                                                                </Col>
                                                            </Row>

                                                            <div>
                                                                Wysłano: {powiadomienie.dataWpisu}
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Accordion.Collapse>
                                            </Row>
                                        </div>
                                        :
                                        <div className={'powiadomienie-row'}>
                                            <Accordion.Toggle as={Row} variant="link" eventKey={powiadomienie.id} onClick={ () => this.onClickPowiadomienie(powiadomienie.id, powiadomienie.dataOdczytu)}>
                                                <Col title={'Kliknij aby rozwinąć lub zwinąć'} style={{padding: '0'}}>
                                                    <div className={!powiadomienie.dataOdczytu ? 'powiadomienie-header nowe' : 'powiadomienie-header'}>
                                                        <div>{powiadomienie.komunikat.substring(0,20)}{(powiadomienie.komunikat.length > 20) && '...'}</div>
                                                        <div className={'status'}>
                                                            {
                                                                powiadomienie.dataOdczytu ?
                                                                    <span><IconCheck/> odczytano</span>
                                                                    :
                                                                    <span><IconNotification/> nowe</span>
                                                            }
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Accordion.Toggle>
                                            <Row>
                                                <Accordion.Collapse eventKey={powiadomienie.id}>
                                                    <Col>
                                                        <div className={'powiadomienie-content'}>
                                                            <p style={{whiteSpace: 'pre-line'}}>
                                                                {powiadomienie.komunikat}
                                                                {/*<div dangerouslySetInnerHTML={{ __html: powiadomienie.komunikat }} />*/}
                                                            </p>
                                                            <div>
                                                                Wysłano: {powiadomienie.dataWpisu}
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Accordion.Collapse>
                                            </Row>
                                        </div>

                                ))
                            }
                        </Accordion>
                        :
                        <h3 style={{color: '#ccc', textAlign: 'center'}}>Brak nowych powiadomień</h3>
                    }
                    </TopLoading>

                </div>
            </LayoutComponent>
        );
    }
}

export default PowiadomieniaModule;