import React from 'react';
import {formatMoney} from "../../../../utilities/formatters/formatMoney";
import FakturyService from "../../../../services/FakturyService";
import BootstrapTable from "react-bootstrap-table-next";
import {TextAlert} from "../../../../sharedComponents/typographyComponents/TextAlert/TextAlert";
// import FakturyTableExpandablePozycjeTable from "./FakturyTableExpandablePozycjeTable";
import TopLoading from "../../../../sharedComponents/loader/TopLoading";
import ReklamacjeService from "../../../../services/ReklamacjeService";

interface Props {
    row: object[]
}

class ReklamacjeExpand extends React.Component<Props> {

    state = {
        currentFaktura: null,
        currentPozycje: [],
        isLoadingPozycje: false,
        isErrorPozycje: false,
        expanded: []
    };

    componentDidMount() {
        this.pobierzPozycje(this.props.row);
    }

    pobierzPozycje = (row: any) => {
        this.setState({
            currentFaktura: row,
            isLoadingPozycje: true,
            isErrorPozycje: false,
        });

        ReklamacjeService.getPozycjeReklamacji(row.reklamacja).then((response) => {
            this.setState({
                                currentPozycje: response.data,
                                isLoadingPozycje: false
                            });
        }).catch((e) => {
            this.setState({isLoadingPozycje: false, isErrorPozycje: true});
        })

        // FakturyService.getPozycjeFaktury({ "data": row.dataWys, "podstawa": row.podstawa })
        //     .then((response: any) => {
        //         if (response && response.status < 300) {
        //             this.setState({
        //                 currentPozycje: response.data,
        //                 isLoadingPozycje: false
        //             })
        //         } else {
        //             this.setState({isLoadingPozycje: false, isErrorPozycje: true})
        //         }
        //     })
        //     .catch((error: any) => {
        //         this.setState({isLoadingPozycje: false, isErrorPozycje: true})
        //     })

    };

    formatMoneyRow = (cell: number) => {
        return <div className={"text-right"}>{formatMoney(cell)}</div>
    };

    columns = [{
        dataField: 'indeks',
        text: 'Indeks',
        align: 'center'
    }, {
        dataField: 'nazwa',
        text: 'Nazwa'
    }, {
        dataField: 'ilosc',
        text: 'Ilość',
        align: 'center'
    }, {
        dataField: 'jm',
        text: 'JM'
    }, {
        dataField: 'blad',
        text: 'Powód'
    },  {
        dataField: 'uwagiKlient',
        text: 'Uwagi'
    }, {
        dataField: 'uwagiFamix',
        text: 'Uwagi Famix'
    }];

    render() {
        let {currentPozycje} = this.state;

        return (
            <div id={'fakturyExpand'}>
                <TopLoading isLoading={this.state.isLoadingPozycje} isError={this.state.isErrorPozycje} isBig={false}>
                    <>
                        {
                            this.state.isErrorPozycje && <TextAlert type={'warning'} marginTop={true} marginBottom={true}>Wystąpił błąd podczas próby pobrania pozycji</TextAlert>
                        }
                        {
                            this.state.currentPozycje.length === 0 ?
                                <TextAlert type={'info'} marginTop={true} marginBottom={true}>Brak pozycji na tej fakturze</TextAlert>
                                :
                                <BootstrapTable remote keyField='poz' data={ currentPozycje } columns={ this.columns } hover noDataIndication={'Brak pasujących wyników'}/>
                        }
                    </>
                </TopLoading>

            </div>
        );
    }
}

export default ReklamacjeExpand;
