import React from 'react';
import {formatMoney} from "../../../../utilities/formatters/formatMoney";
import FakturyService from "../../../../services/FakturyService";
import BootstrapTable from "react-bootstrap-table-next";
import {TextAlert} from "../../../../sharedComponents/typographyComponents/TextAlert/TextAlert";
import TopLoading from "../../../../sharedComponents/loader/TopLoading";
import ZamowieniaService from "../../../../services/ZamowieniaService";
import {
    Icon_Check_success,
    Icon_Exclamation_danger,
    Icon_Exclamation_warning
} from "../../../../sharedComponents/icons/IconsWithColor";
import paginationFactory from "react-bootstrap-table2-paginator";
// @ts-ignore
import overlayFactory from 'react-bootstrap-table2-overlay';
import {changeSortowanie} from "../../../../utilities/arrays/arraysUtilities";
import {_omit} from "../../../../utilities/lodash/lodashUtilities";

interface Props {
    row: object[]
}

interface State {
    currentFaktura: any,
    currentPozycje: object[],
    isLoadingPozycje: boolean,
    isErrorPozycje: boolean,
    expanded: object[],
    page: number,
    size: number,
    pageableObject: any,
    sort: any,
    loading: boolean
}

class ZamowieniaExpand extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            currentFaktura: this.props.row,
            currentPozycje: [],
            isLoadingPozycje: true,
            isErrorPozycje: false,
            expanded: [],
            page: 0,
            size: 10,
            pageableObject: {
                currentPage: 0,
                currentSize: 0,
                totalElements: 0
            },
            sort: [{kierunek: "asc", kolumna: "poz"}],
            loading: false
        }
    }

    componentDidMount() {
        // this.pobierzPozycje(this.props.row);
        this.pobierzPozycje2();
    }

    pobierzPozycje = (row: any) => {
        this.setState({
            currentFaktura: row,
            isLoadingPozycje: true,
            isErrorPozycje: false,
        });

        ZamowieniaService.getPozycjeZamowienia(0, 1000, {
            "numer": row.numer,
            "symbol": row.symbol
        })
            .then((response: any) => {
                if (response && response.status < 300) {
                    this.setState({
                        currentPozycje: response.data,
                        isLoadingPozycje: false
                    })
                } else {
                    this.setState({isLoadingPozycje: false, isErrorPozycje: true})
                }
            })
            .catch((error: any) => {
                this.setState({isLoadingPozycje: false, isErrorPozycje: true})
            })

    };

    generateSortString = (): string => {
        let sortowanie = this.state.sort;
        let sortString = '';

        for (let i=0; sortowanie.length > i; i++)
        {
            // @ts-ignore
            sortString = sortString + '&sort=' + sortowanie[i].kolumna + ',' + sortowanie[i].kierunek;
        }

        return sortString;

    }

    pobierzPozycje2 = (page = this.state.page, size = this.state.size) => {
        // this.setState({
        //     // currentFaktura: row,
        //     // isLoadingPozycje: true,
        //     isErrorPozycje: false,
        //     loading: true
        // });

        ZamowieniaService.getPozycjeZamowienia2(page, size, {
            "numer": this.state.currentFaktura.numer,
            "symbol": this.state.currentFaktura.symbol
        }, this.generateSortString()).then((response) => {

                    this.setState({
                        currentPozycje: response.data.content,
                        pageableObject: Object.assign({}, _omit(response.data, "content"), {currentPage: page}, {currentSize: size}),
                        loading: false,
                        isLoadingPozycje: false
                    })

            }).catch((error) => {
                this.setState({isLoadingPozycje: false, isErrorPozycje: true})
            })

    };

    formatIleZamowionoIleWydanoRow = (cell:any,row:any) =>{
        let ileZamowiono = row.iloscWpr;
        let ileWydano = cell;
        let icon: any = '';
        let tooltip='';

        if (ileZamowiono === ileWydano) {
            icon = <Icon_Check_success/>
            tooltip = 'Wydano pełną ilość zamówionego towaru'
        } else if (ileWydano === 0) {
            // icon = <Icon_Exclamation_danger/>
            tooltip = 'Nie wydano zamówionego towaru'
        } else {
            // icon = <Icon_Exclamation_warning/>
            tooltip = 'Wydano niepełną ilość zamówionego towaru'
        }

        return (
            <>
                <span title={tooltip}>{ileZamowiono}/{ileWydano} {icon}</span>
            </>
        )
    };

    formatMoneyRow = (cell: number) => {
        return <div className={"text-right"}>{formatMoney(cell)}</div>
    };

    ikonaSort = (typ: string) => {
        let {sort} = this.state;
        let index = -1;

        for (let i = 0; sort.length > i; i++)
        {
            if (sort[i].kolumna === typ)
            {
                index = i;
                break;
            }
        }

        if (index !== -1)
        {
            if (sort[index].kierunek === "asc")
            {
                return <i className="fa fa-sort-asc" />
            } else
                return <i className="fa fa-sort-desc" />
        } else
            return <i className="fa fa-sort" style={{color: '#aeaeae'}}/>

    }

    onSortChange = (sortName: any, sortOrder: any) => {

        // @ts-ignore
        this.onChangeSortowanie(changeSortowanie(this.state.sort, sortName));

    }

    onChangeSortowanie = (sort: any) => {

        this.setState({sort: sort, page: 0, loading: true}, () => this.pobierzPozycje2());

    };

    columns = [{
        dataField: 'poz',
        text: 'Poz.',
        headerFormatter: () => {return <>Poz. &nbsp;{this.ikonaSort('poz')}</>},
        headerStyle: {cursor: 'pointer'},
        onSort: this.onSortChange,
        sort: true
    }, {
        dataField: 'indeks',
        text: 'Indeks',
        align: 'center',
        headerFormatter: () => {return <>Indeks &nbsp;{this.ikonaSort('indeks')}</>},
        headerStyle: {cursor: 'pointer'},
        onSort: this.onSortChange,
        sort: true
    }, {
        dataField: 'nazwa',
        text: 'Nazwa',
        headerFormatter: () => {return <>Nazwa &nbsp;{this.ikonaSort('nazwa')}</>},
        headerStyle: {cursor: 'pointer'},
        onSort: this.onSortChange,
        sort: true
    }, {
        dataField: 'cena',
        text: 'Cena',
        formatter: this.formatMoneyRow,
        headerFormatter: () => {return <>Cena &nbsp;{this.ikonaSort('cena')}</>},
        headerStyle: {cursor: 'pointer'},
        onSort: this.onSortChange,
        sort: true
    }, {
        dataField: 'wydano',
        text: 'Ile zamówiono / wydano',
        align: 'center',
        formatter: this.formatIleZamowionoIleWydanoRow,
        headerFormatter: () => {return <>Ile zamówiono / wydano &nbsp;{this.ikonaSort('wydano')}</>},
        headerStyle: {cursor: 'pointer'},
        onSort: this.onSortChange,
        sort: true
    }, {
        dataField: 'jmWpr',
        text: 'JM',
        align: 'center'
    }, {
        dataField: 'przyjal',
        text: 'Przyjął'
    }];

    handleChangePage = (page: number, size: number) => {
        this.setState({
            page: page - 1,
            loading: true,
            size: size
        }, () => this.pobierzPozycje2())
    };

    handleChangeSizePerPage = (size: number, page: any) => {
        this.setState({size, loading: true}, () => this.pobierzPozycje2());
    };

    render() {
        let {currentPozycje, pageableObject, isLoadingPozycje, isErrorPozycje, loading} = this.state;

        return (
            <div id={'zamowieniaExpand'}>
                <TopLoading isLoading={isLoadingPozycje} isError={isErrorPozycje} isBig={false}>
                {/*    <>*/}
                        {/*{*/}
                        {/*    this.state.isErrorPozycje && <TextAlert type={'warning'} marginTop={true} marginBottom={true}>Wystąpił błąd podczas próby pobrania pozycji</TextAlert>*/}
                        {/*}*/}
                        {/*{*/}
                        {/*    this.state.currentPozycje.length === 0 ?*/}
                        {/*        <TextAlert type={'info'} marginTop={true} marginBottom={true}>Brak pozycji na tym zamówieniu</TextAlert>*/}
                        {/*        :*/}
                                <BootstrapTable remote keyField='poz' data={ currentPozycje } columns={ this.columns } hover noDataIndication={'Brak pozycji na tym zamówieniu'}
                                                loading={loading}
                                                overlay={ overlayFactory({ spinner: true, styles: { overlay: (base: any) => ({...base, background: 'rgba(96,93,141,0.3)'}) } }) }
                                                pagination={(paginationFactory({
                                                    page: (pageableObject.currentPage) + 1,
                                                    sizePerPage: pageableObject.currentSize,
                                                    totalSize: pageableObject.totalElements,
                                                    sizePerPageList: [10, 12, 20, 50, 100],
                                                    hideSizePerPage: true,
                                                    onSizePerPageChange: this.handleChangeSizePerPage,
                                                    onPageChange: this.handleChangePage
                                                }))}
                                />
                        {/*}*/}
                    {/*</>*/}
                </TopLoading>

            </div>
        );
    }
}

export default ZamowieniaExpand;
