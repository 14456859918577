import {SettingsModel} from "../utilities/models/SettingsModel";
import localStorageTypes from "../utilities/localStorage/localStorageTypes";
import {Component} from "react";
import UstawieniaService from "./UstawieniaService";
import {wait} from "@testing-library/react";
import moment from "moment";
import LoginService from "./LoginService";
import {APP_CZAS_AKTUALIZACJI_OFERTY} from "./../ApplicationConfig";

export default class ApplicationGlobalSettingsService extends Component {

    static setLocalStorageConfigurationObject = (settings: any) => {
        localStorage.setItem(localStorageTypes.GLOBAL_SETTINGS, JSON.stringify(settings));
    };

    static getLocalStorageConfigurationObject = () => {
        //@ts-ignore
        return JSON.parse(localStorage.getItem(localStorageTypes.GLOBAL_SETTINGS));
    };

    static setGlobalSettingsConfiguration = () => {

        // gdy wystąpi błąd pobrania, będą załadowane ustawienia domyślne
        //  if(Boolean(settings)){
        //      ApplicationGlobalSettingsService.setLocalStorageConfigurationObject(SettingsModel)
        //  }

        UstawieniaService.getUstawienia()
            .then((response: any) => {

                if (response.status < 300) {
                    if(!ApplicationGlobalSettingsService.getGlobalSettingsConfiguration('sort'))
                    {
                        //alert('nie ma')
                        ApplicationGlobalSettingsService.setLocalStorageConfigurationObject(Object.assign({}, response.data, {"sort":[]}))
                    }
                    else
                    {
                        ApplicationGlobalSettingsService.setLocalStorageConfigurationObject(Object.assign({}, response.data, {"sort":ApplicationGlobalSettingsService.getGlobalSettingsConfiguration('sort')}))
                    }

                } else {
                }
            })
            .catch((error: any) => {
            });

    };

    static getGlobalSettingsConfiguration = (section: string) => {
        let settings = ApplicationGlobalSettingsService.getLocalStorageConfigurationObject();
        if (Boolean(settings)) {
            return settings[section]
        } else return null
    };

    static setGlobalSettingsConfigurationSection = (section: string, value: any) => {
        let settings = ApplicationGlobalSettingsService.getLocalStorageConfigurationObject();
        if (Boolean(settings)) {
            settings[section] = value;
            ApplicationGlobalSettingsService.setLocalStorageConfigurationObject(settings);
            return true;
        } else return false
    };

    static getGlobalSettingsModuleView = (module: string) => {
        return ApplicationGlobalSettingsService.getGlobalSettingsConfiguration('tabelaDomyslnymWidokiem'+module);
    };

    // SESION

    static getSesionStorage= (key: string) => {
        // @ts-ignore
        let settings = JSON.parse(sessionStorage.getItem(key));
        if (Boolean(settings)) {
            return (settings)
        } else return undefined
    };

    static setSesionStorage = (key: string, value: any) => {
        sessionStorage.setItem(key, value);
    };

    static czyStareDane = (): boolean => {
        if(sessionStorage.getItem('AKTUALIZACJA'))
        {
            // @ts-ignore
            let ostatnia = moment(JSON.parse(sessionStorage.getItem('AKTUALIZACJA'))).add(APP_CZAS_AKTUALIZACJI_OFERTY(), 'hours').format('YYYY.MM.DD HH:mm');
            let teraz = moment().format('YYYY.MM.DD HH:mm');

            return moment(teraz).isAfter(moment(ostatnia));

        } else
        {
            return true;
        }
    }

    static czyZalogowanyDlugo = (): boolean => {
        if(Boolean(localStorage.getItem('FAMIX_OSTATNIE_LOGOWANIE')) && LoginService.localCzySesion())
        {
            // @ts-ignore
            let ostatnia = moment(JSON.parse(localStorage.getItem('FAMIX_OSTATNIE_LOGOWANIE'))).add(3, 'weeks').format('YYYY.MM.DD HH:mm');
            let teraz = moment().format('YYYY.MM.DD HH:mm');

            return moment(teraz).isAfter(moment(ostatnia));

        } else
        {
            return false;
        }
    }

   /* static setGlobalSettingsValue = (section: string, position: string, value: string) => {


        // todo: tymczasowe rozwiazanie - przerobić na wysyłanie do api i ustawienie odpowiedzi do localStorage poprzez wywolanie funkcji set


        let arr = Array.from(ApplicationGlobalSettingsService.getLocalStorageConfigurationObject());
        if (Boolean(arr) && arr.length > 0) {
            let obj = arr.find((s: any) => s.section === section && s.position === position);
            if (Boolean(obj)) {
                //@ts-ignore
                obj.value = value;
                ApplicationGlobalSettingsService.setLocalStorageConfigurationObject(arr)
            }
        }
    };*/
}
