import React, {Component} from 'react';
import ApplicationGlobalSettingsService from "../../../../services/ApplicationGlobalSettingsService";
import {TextAlert} from "../../../../sharedComponents/typographyComponents/TextAlert/TextAlert";
import UstawieniaAplikacjiItem from "./UstawieniaAplikacjiItem";
import UstawieniaService from "../../../../services/UstawieniaService";
import {displayNotificationError} from "../../../../utilities/notifications/NotificationsManager";

class UstawieniaAplikacjiContainer extends Component {

    state = {
        data: []
    };

    componentDidMount() {
        this.setState({
            data: ApplicationGlobalSettingsService.getLocalStorageConfigurationObject()
        }, () => {
            if(this.state.data === null)
            {
                this.pobierzUstawienia();
            }
        });
    }

    pobierzUstawienia = () => {
        UstawieniaService.getUstawienia()
            .then((response: any) => {

                if (response.status < 300) {
                    if(!ApplicationGlobalSettingsService.getGlobalSettingsConfiguration('sort'))
                    {
                        //alert('nie ma')
                        ApplicationGlobalSettingsService.setLocalStorageConfigurationObject(Object.assign({}, response.data, {"sort":[]}))
                    }
                    else
                    {
                        ApplicationGlobalSettingsService.setLocalStorageConfigurationObject(Object.assign({}, response.data, {"sort":ApplicationGlobalSettingsService.getGlobalSettingsConfiguration('sort')}))
                    }

                    this.setState({data: response.data});

                } else {

                }
            })
            .catch((error: any) => {

            })
    }

    onChangeValue = (item: any, newValue: any) => {

        UstawieniaService.setUstawienia(item.key, newValue)
            .then((response: any) => {
                if (response && response.status < 300) {

                    let {data} = this.state;
                    //@ts-ignore
                    data[item.key] = newValue;
                    this.setState({data});

                    ApplicationGlobalSettingsService.setLocalStorageConfigurationObject(data);

                } else {
                    displayNotificationError('Wystąpił błąd podczas próby zapisania nowych ustawień', '')
                }
            })
            .catch((error: any) => {
                displayNotificationError('Wystąpił błąd podczas próby zapisania nowych ustawień', '')
            })
    };

    render() {

        let {data} = this.state;

        return (
            <div id={'UstawieniaAplikacjiContainer'}>
                {(data) &&
                    Object.keys(data).filter((el) => el !== 'sort').map((key: any) => (
                        <UstawieniaAplikacjiItem item={{key, value: data[key]}} handleChangeValue={this.onChangeValue}/>
                    ))
                }
                <TextAlert type={'warning'} marginTop={true}>
                    Na urządzeniach mobilnych domyślnym widokiem jest grid
                </TextAlert>
            </div>
        );
    }
}

export default UstawieniaAplikacjiContainer;