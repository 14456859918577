import React, {Component} from 'react';
import LayoutComponent from "../../../sharedComponents/layout/LayoutComponent";
import TopTableGridSwitcher from "../../../sharedComponents/TopTableGridSwitcher";
import AdminReklamacjeTable from "./components/AdminReklamacjeTable";
import {API_Admin_Reklamacje} from "../../../_static_data/response";
import PopupWrapper from "../../../sharedComponents/popups/PopupWrapper";
import AdminReklamacjaPopupSzczegoly from "./components/AdminReklamacjaPopupSzczegoly";
import AdminReklamacjeGrid from "./components/AdminReklamacjeGrid";
import {isMobileDevice} from "../../../utilities/rwd/rwdUtilities";

class AdminReklamacjeModule extends Component {

    state = {
        currentView: true, // table - true, grid - false
        reklamacje: [],
        statusy: [
            {value: 'nowe', label: 'nowe'},
            {value: 'w trakcie', label: 'w trakcie'},
            {value: 'rozwiązano', label: 'rozwiązano'},
        ],
        reklamacja: null,
        showSzczegoly: false
    };

    componentDidMount() {
        this.setState({
            reklamacje: API_Admin_Reklamacje(),
            currentView:!isMobileDevice()
        });
    }

    handleClickPokaz = (reklamacja: any) => {
        this.setState({reklamacja, showSzczegoly: true});
    };


    render() {

        let {currentView, reklamacje, statusy, reklamacja, showSzczegoly} = this.state;

        return (
            <LayoutComponent breadcrumbs={[{url: "/admin", name: 'PA'}, {url: "/admin/reklamacje", name: 'Reklamacje'}]} isAdminPanel={true}>
                <div id={'ADMIN_MODULE'}>
                    <div id={'ADMIN_REKLAMACJE'}>
                        <TopTableGridSwitcher currentView={currentView} quantity={0} handleChangeView={(currentView) => this.setState({currentView})}/>
                        {
                            currentView ?
                                <AdminReklamacjeTable reklamacje={reklamacje} statusy={statusy}
                                                      handleClickPokaz={this.handleClickPokaz}
                                                      handleClickZmienStatus={() => {
                                                          alert('tu zmiana statusu..')
                                                      }}
                                />
                                :
                                <AdminReklamacjeGrid reklamacje={reklamacje} statusy={statusy}
                                                     handleClickPokaz={this.handleClickPokaz}
                                                     handleClickZmienStatus={() => {
                                                         alert('tu zmiana statusu..')
                                                     }}
                                />
                        }
                        {
                            (showSzczegoly && Boolean(reklamacja)) &&
                            <PopupWrapper header={
                                //@ts-ignore
                                `Szczegóły reklamacji faktury ${reklamacja.faktura}`
                            } onClose={() => this.setState({showSzczegoly: false, reklamacja: null})}>
                                <AdminReklamacjaPopupSzczegoly reklamacja={reklamacja}/>
                            </PopupWrapper>
                        }
                    </div>
                </div>
            </LayoutComponent>
        );
    }
}

export default AdminReklamacjeModule;