import React, {Component} from 'react';
import {IconExportPdf, IconFileCSV, IconInfo, IconSettings} from "../icons/FontAwesome";
import PopupWrapper from "../popups/PopupWrapper";
import {Col, Form, FormGroup, Row} from "react-bootstrap";
import Select from "react-select";
import {SmallerSelectStyles} from "../../utilities/forms/jsStyles";
//@ts-ignore
import {ExportToCsv} from 'export-to-csv';
import {formatMoney} from "../../utilities/formatters/formatMoney";
import {TextAlert} from "../typographyComponents/TextAlert/TextAlert";
import {isMobileDevice} from "../../utilities/rwd/rwdUtilities";
import ZamowieniaService from "../../services/ZamowieniaService";
import {
    displayNotificationError,
    displayNotificationInfo,
    displayNotificationSuccess, displayNotificationWarning
} from "../../utilities/notifications/NotificationsManager";
import {saveAnyFileAndDownload} from "../../utilities/files/fileOperations";

interface Props {
    data?: any;
    columnsNames?: any;
    parametryPdf?: object[];
}

class TopExport extends Component<Props> {

    state = {
        separators: [
            {value: ";", label: "; średnik"},
            {value: " ", label: "spacja"},
            {value: "\t", label: "tabulator"},
            {value: "/", label: "/ ukośnik"},
            {value: ",", label: ", przecinek"},
        ],
        settings: {
            separator: {value: ";", label: "; średnik"},
            columnsToExport: []
        },
        showModal: false,

        columnsAll: [],
    };

    componentDidMount() {
        this.prepareColumns(this.props.data)
    }

    prepareColumns = (data: any) => {
        if (this.props.columnsNames.length > 0) {
            this.setState({
                settings: {
                    separator: {value: ";", label: "; średnik"},
                    columnsToExport: Array.from(this.props.columnsNames)
                },
                columnsAll: Array.from(this.props.columnsNames),
            })
        } else {
            let columnsNames: any = [];

            if (data.length > 0) {
                let obj = data[0];

                Object.keys(obj).forEach(key => {
                    columnsNames.push({
                        value: key,
                        label: key
                    })
                })

            }

            this.setState({
                settings: {
                    separator: {value: ";", label: "; średnik"},
                    columnsToExport: columnsNames
                },
                columnsAll: columnsNames
            })
        }
    };

    onClickExport = () => {

        if(this.props.data.length > 0)
        {
            let transformedData: any = [];

            this.props.data.forEach((obj: any) => {

                let transformedObj: any = {};

                this.state.settings.columnsToExport.forEach((column: any) => {

                    let val: any = obj[column.value];

                    if (val === true) {
                        val = 'tak'
                    } else if (val === false) {
                        val = 'nie'
                    } else if (val === null || val === undefined) {
                        val = '-'
                    } else if (column.value === "cena" || column.value === "cenaKatalogowa" || column.value === "wartoscNetto" || column.value === "wartoscBrutto" || column.value === "kwota" || column.value === "kwotaNetto" || column.value === "kwotaBrutto" || column.value === "zaplata") {
                        val = formatMoney(val)
                    } else {
                        val = val.toString();
                    }

                    transformedObj = Object.assign(transformedObj, {[column.label]: val})

                });

                transformedData.push(transformedObj)
            });


            const options = {
                filename: 'famix-export-' + new Date().toJSON().substr(0, 19),
                fieldSeparator: this.state.settings.separator.value,
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                showTitle: true,
                title: 'FAMIX Export - ' + new Date().toJSON().substr(0, 19),
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,
                headers: this.props.columnsNames.length > 0 ? this.props.columnsNames : null
            };

            const csvExporter = new ExportToCsv(options);
            csvExporter.generateCsv(transformedData);
        } else
        {
            displayNotificationInfo('Brak danych do exportu', '');
        }

    };

    onClickExportPdf = () => {
        let parametry = this.props.parametryPdf;

        if (parametry) {

            ZamowieniaService.getPdfZamowienia(parametry).then((response)=>{
                if(response.status){

                    displayNotificationSuccess('Pomyślnie zaciagnięto dane', 'Plik pobierze się automatycznie');

                    // @ts-ignore
                    saveAnyFileAndDownload(response.data, 'Zamowienia'+JSON.stringify(parametry.dataZamowien), 'pdf');

                }
            }).catch(e => {
                displayNotificationError('Wystąpił błąd podczas próby exportu do PDF', '');
            })

        }

    }

    onClickSettings = () => {
        this.setState({showModal: true})
    };

    onChangeSeparator = (e: any) => {
        let settings = this.state.settings;
        settings.separator = e;
        this.setState({settings})
    };

    onChangeColumnsToExport = (e: any) => {
        let settings = this.state.settings;
        settings.columnsToExport = e;
        this.setState({settings})
    };

    render() {

        let {showModal, settings, separators, columnsAll} = this.state;

        return (
            <>
                <div className={'TopExport'}>
                    {
                        this.props.parametryPdf &&
                        <span onClick={this.onClickExportPdf} title={'Kliknij, aby wyeksportować widoczne dane do pliku PDF'} style={{marginLeft: '3px'}}>
                            <IconExportPdf/>
                        </span>
                    }
                    <span onClick={this.onClickExport} title={'Kliknij, aby wyeksportować widoczne dane do pliku CSV'} style={{marginRight: '3px'}}>
                        <IconFileCSV/>
                    </span>
                    <span onClick={this.onClickSettings} title={'Kliknij, aby zmienić ustawienia exportu'}>
                        <IconSettings/>
                    </span>
                </div>
                {
                    showModal &&
                    <PopupWrapper header={'Opcje exportu'} onClose={() => {
                        this.setState({showModal: false})
                    }}>
                        <Form id={'TopExportForm'}>
                            <Row>
                                <Col md={5} lg={4} xl={3}>
                                    <FormGroup>
                                        <Form.Label>
                                            Separator
                                        </Form.Label>
                                        <Select
                                            value={settings.separator}
                                            options={separators}
                                            onChange={this.onChangeSeparator}
                                            isClearable={false}
                                            menuPosition={"fixed"}
                                            styles={SmallerSelectStyles}
                                            isSearchable={!isMobileDevice()}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={7} lg={8} xl={9}>
                                    <FormGroup>
                                        <Form.Label>
                                            Kolumny do exportu
                                        </Form.Label>
                                        <Select
                                            value={settings.columnsToExport}
                                            options={columnsAll}
                                            onChange={this.onChangeColumnsToExport}
                                            isMulti={true}
                                            isClearable={false}
                                            menuPosition={"fixed"}
                                            styles={SmallerSelectStyles}
                                            isSearchable={!isMobileDevice()}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg={12}>
                                    <TextAlert type={'info'}>Zmiany zapisują się automatycznie</TextAlert>
                                </Col>
                            </Row>
                        </Form>

                    </PopupWrapper>
                }
            </>
        );
    }
}

export default TopExport;
