import React, {Component} from 'react';
import {Button, Col, Form, FormGroup, Row, Badge} from "react-bootstrap";
// @ts-ignore
import InputNumber from 'rc-input-number'
import 'rc-input-number/assets/index.css';
import Select from 'react-select';
import {IconCartPlus, IconRefresh} from "../../icons/FontAwesome";
import {formatMoney} from "../../../utilities/formatters/formatMoney";
import {displayNotificationError, displayNotificationInfo} from "../../../utilities/notifications/NotificationsManager";
import {TextAlert} from "../../typographyComponents/TextAlert/TextAlert";
import {NormalSelectStyles} from "../../../utilities/forms/jsStyles";
import {famix_przeliczIloscDlaJednostekMiary, famix_zaokraglijIloscDlaJM1} from "../../../utilities/famix_others/famix_operations";
import {isMobileDevice} from "../../../utilities/rwd/rwdUtilities";
import {Decimal} from 'decimal.js';

const MIN = 0;
const MAX = 99999999;

interface Props {
    item: any,
    handleDeleteProductFromBasket: (item: any) => void;
    handleAddOrChangeProductQuantityInBasket: (czyDodawanie: boolean, item: any, iloscSztuk: number, ilosc: number, typOpakowania: string, jm: string) => void;
}

interface State {
    amount: number,
    czyZaokraglonoIWyslanoEnterem: boolean,
    zakupOpakowanieTyp: any,
    options: any,
}

class Shared_ProductCardAddForm extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            amount: 0,
            zakupOpakowanieTyp: this.valueSelect(this.props.item),
            czyZaokraglonoIWyslanoEnterem: false,
            options: []
        };
    }

    componentDidMount() {
        this.ustawZakupOpakowanieTypOrazIlosc(this.props.item)
        //if(this.props.item.koszykIlosc!==0) this.setState({zakupOpakowanieTyp: this.valueSelect()})
        //if(this.props.item.koszykIlosc===0) alert('ok')
        this.generateSelectOptions();
    }

    componentDidUpdate(prevProps: any) {
        if (JSON.stringify(prevProps.item) !== JSON.stringify(this.props.item))
            this.ustawZakupOpakowanieTypOrazIlosc(this.props.item)

        let input: any = document.getElementById('input-' + this.props.item.indeks);
        if (input.value != this.state.amount) {
            input.value = this.props.item.koszykIlosc
        }
    }

    generateSelectOptions = () => {

        let {item} = this.props;
        let options = [];
        if (item.jm.indexOf('KG') <= -1 && item.jm2.indexOf('DIS') <= -1) {
            options.push({value: 'jednostka', label: `Zakup po jednostce (${item.jm})`, jm: item.jm});
        }
        if (item.jm !== item.jm2)
            options.push({value: 'opakowanie', label: `Zakup po opakowaniu (${item.jm2})`, jm: item.jm2});

        if (item.jm2 !== item.jm3)
            options.push({value: 'karton', label: `Zakup po kartonie (${item.jm3})`, jm: item.jm3});

        this.setState({options})
    };

    ustawZakupOpakowanieTypOrazIlosc = (item: any) => {

        // let zakupOpakowanieTyp = this.generateSelectOptions()[0];
        let amount = 0;

        // if (localStorage.OPAKOWANIE_TYP !== undefined && localStorage.OPAKOWANIE_TYP !== null){
        //     zakupOpakowanieTyp = JSON.parse(localStorage.OPAKOWANIE_TYP)
        // }

        if (Boolean(item.koszykIlosc)) {
            amount = item.koszykIlosc
        }

        this.setState({
            amount: amount,
            //zakupOpakowanieTyp: zakupOpakowanieTyp
        })

    };

    onChangeZakupOpakowanieTyp = (typ: any) => {
        this.setState({zakupOpakowanieTyp: typ});
        localStorage.setItem('OPAKOWANIE_TYP', JSON.stringify(typ))

        this.setState({
            //@ts-ignore
            amount: famix_przeliczIloscDlaJednostekMiary(this.state.zakupOpakowanieTyp.value, typ.value, this.state.amount, this.props.item)
        })
    };

    onChangeAmount = (amount: any) => {
        this.setState({amount: amount});
    };

    renderObliczCene = (zakupOpakowanieTyp: any) => {
        let {item} = this.props;

        if (zakupOpakowanieTyp.value === 'jednostka') {
            return item.cena
        }
        if (zakupOpakowanieTyp.value === 'opakowanie') {
            return item.cenaJm2
        }
        if (zakupOpakowanieTyp.value === 'karton') {
            return item.cenaJm3
        }
    };

    renderWygenerujTypZaCoCena = (zakupOpakowanieTyp: any) => {
        let {item} = this.props;

        if (zakupOpakowanieTyp.value === 'jednostka') {
            return item.jm
        }
        if (zakupOpakowanieTyp.value === 'opakowanie') {
            return `opakowanie (${famix_zaokraglijIloscDlaJM1(item.jm, item.ilJm2)} ${item.jm})`
        }
        if (zakupOpakowanieTyp.value === 'karton') {
            return `karton (${famix_zaokraglijIloscDlaJM1(item.jm, item.ilJm3)} ${item.jm})`
        }

    };

    // generateSelectOptions = () => {
    //
    //     let {item} = this.props;
    //     let options = [];
    //     if (item.jm.indexOf('KG') <= -1 && item.jm2.indexOf('DIS') <= -1) {
    //         options.push({value: 'jednostka', label: `Zakup po jednostce (${item.jm})`, jm: item.jm});
    //     }
    //     if (item.jm !== item.jm2)
    //         options.push({value: 'opakowanie', label: `Zakup po opakowaniu (${item.jm2})`, jm: item.jm2});
    //
    //     if (item.jm2 !== item.jm3)
    //         options.push({value: 'karton', label: `Zakup po kartonie (${item.jm3})`, jm: item.jm3});
    //
    //     return options
    // };

    // valueSelect = () => {
    //     let {item} = this.props;
    //
    //     if (item.jm === item.jm2) return ({value: 'jednostka', label: `Zakup po jednostce (${item.jm})`, jm: item.jm})
    //     if (item.jm !== item.jm2) return ({value: 'opakowanie', label: `Zakup po opakowaniu (${item.jm2})`, jm: item.jm2})
    //
    //     /*
    //     if (item.jm === item.jm2){
    //         this.setState({zakupOpakowanieTyp: ({value: 'jednostka', label: `Zakup po jednostce (${item.jm})`, jm: item.jm})});
    //         return ({value: 'jednostka', label: `Zakup po jednostce (${item.jm})`, jm: item.jm})
    //     }
    //
    //     if (item.jm !== item.jm2){
    //         this.setState({zakupOpakowanieTyp: ({value: 'opakowanie', label: `Zakup po opakowaniu (${item.jm2})`, jm: item.jm2})});
    //         return ({value: 'opakowanie', label: `Zakup po opakowaniu (${item.jm2})`, jm: item.jm2})
    //     }
    //
    //      */
    // }

    valueSelect = (item: any) => {
        if (item.zakupJm.indexOf('SZT') > -1) {
            return{value: 'jednostka', label: `Zakup po jednostce (${item.jm})`, jm: item.jm};
        }
        if (item.zakupJm.indexOf('DIS') > -1)
            return{value: 'opakowanie', label: `Zakup po opakowaniu (${item.jm2})`, jm: item.jm2};

        if (item.zakupJm.indexOf('KRT') > -1)
            return{value: 'karton', label: `Zakup po kartonie (${item.jm3})`, jm: item.jm3};

        if (item.zakupJm.indexOf('STD') > -1)
            return{value: 'opakowanie', label: `Zakup po opakowaniu (${item.jm3})`, jm: item.jm3};
    }

    onPressEnter = (e: any) => {
        e.preventDefault();
        this.dodajDoZamowienia(true)
    };

    onClickButtonDodaj = () => {
        this.dodajDoZamowienia()
        //console.log(famix_przeliczIloscDlaJednostekMiary("karton", "opakowanie", this.state.amount, this.props.item))
    };


    dodajDoZamowienia = (czyWyslanoEnterem = false) => {


        let {amount, zakupOpakowanieTyp} = this.state;
        let {item} = this.props;

        let czyPoprawne = true;
        let czyPoprawnaIloscSztuk = true;


        let ilosc = amount; // to wysylamy do koszyka, zawsze bedzie w jednostce miary 1 (szt / kg)


        //@ts-ignore
        if (isNaN(amount) || amount === '' || amount === null) {
            czyPoprawne = false;
        } else {

            if (amount < MIN || amount > MAX) {
                czyPoprawne = false
            }

            if (this.props.item.jm.indexOf('KG') === -1) {
                if (amount.toString().indexOf('.') > -1 || amount.toString().indexOf(',') > -1) {
                    czyPoprawne = false
                }
            }
        }


        if (!Boolean(zakupOpakowanieTyp)) {
            czyPoprawne = false;
        } else {

            // walidacja by byla odpowienia ilosc sztuk: ilosc % ilosc sztuk w jednostce miary opakowania badz kartonu (jesli jm === jm2)
            // jesli produkt ma jednostke miary  STD , walidacja jest pomijana

            if (zakupOpakowanieTyp.value === 'jednostka') {
                if (item.jm !== 'STD') {
                    if (item.jm === item.jm2) {
                        // ilosc sztuk w kartonach
                        console.log('jm = jm2');

                        /*
                        if (Decimal.mod(amount, item.ilJm3).toNumber() !== 0) {
                            //    czyPoprawne = false;
                            czyPoprawnaIloscSztuk = false;

                            let dzielenie = Decimal.div(amount, item.ilJm3).toNumber();
                            if (dzielenie < 1) {
                                // klient probuje zamowic mniej niz 1 karton towaru - zmiana na ilosc sztuk w jednym kartonie
                                ilosc = item.ilJm3
                            } else {
                                // klient probuje zamowic wiecej niz 1 karton towaru - zaokraglamy w dół. Jesli zamowi 3.3 kartonu, to system wklepie 3 kartony
                                ilosc = parseFloat((item.ilJm3 * Math.floor(dzielenie)).toFixed(2))
                            }
                        }
                         */
                    } else {
                        console.log('jm != jm2');
                        console.log(amount)
                        // ilosc sztuk w opakowaniach
                        if (Decimal.mod(amount, item.ilJm2).toNumber() !== 0) {
                            //    czyPoprawne = false;
                            czyPoprawnaIloscSztuk = false;

                            let dzielenie = Decimal.div(amount, item.ilJm2).toNumber();
                            if (dzielenie < 1) {
                                // klient probuje zamowic mniej niz 1 karton towaru - zmiana na ilosc sztuk w jednym kartonie
                                ilosc = item.ilJm2
                            } else {
                                // klient probuje zamowic wiecej niz 1 karton towaru - zaokraglamy w dół. Jesli zamowi 3.3 kartonu, to system wklepie 3 kartony
                                ilosc = parseFloat((item.ilJm2 * Math.floor(dzielenie)).toFixed(2))
                            }

                            // let dzielenie = Decimal.div(amount, item.ilJm2).toNumber();
                            // if (dzielenie < 1) {
                            //     // klient probuje zamowic mniej niz 1 opakowanie towaru - zmiana na ilosc sztuk w jednym opakowaniu
                            //     ilosc = item.ilJm2
                            // } else {
                            //     // klient probuje zamowic wiecej niz 1 opakowanie towaru - zaokraglamy w dół. Jesli zamowi 5.8 kartonu, to system wklepie 5 opakowania
                            //     ilosc = parseFloat((item.ilJm2 * Math.floor(dzielenie)).toFixed(2))
                            // }

                        }
                    }
                }
            }
        }

        if (czyPoprawne) {

            let previousAmount = item.koszykIlosc;

            if (previousAmount === null || previousAmount === undefined || previousAmount === '')
                previousAmount = 0;


            if (previousAmount === amount) {
                // brak akcji
                console.log('brak akcji');
            } else if (amount === 0) {
                this.props.handleDeleteProductFromBasket(item)
            } else {
                // zakupOpakowanieTyp.value === 'jednostka' brak przeksztalcen

                if (zakupOpakowanieTyp.value !== 'jednostka') {
                    //alert('przelicz ilosc '+amount)
                    //@ts-ignore
                    ilosc = famix_przeliczIloscDlaJednostekMiary(zakupOpakowanieTyp.value, 'jednostka', amount, item)
                    //alert(ilosc);
                }

                // previousAmount === 0 && amount > 0     <- dodanie produktu do koszuka, jesli fałsz to edycja ilosci produktu w koszyku

                if (!czyPoprawnaIloscSztuk) {

                    console.log('nie poprawna ilosc');
                    console.log('poprawiona ilosc: '+ilosc);

                    // displayNotificationInfo(`Zaokrąglono podaną ilość produktu`, `Ilość produktu w tej jednostce musi być wielokrotnością liczby ${(item.jm === item.jm2) ? item.ilJm3 : item.ilJm2}. Do koszyka dodano zaokrągloną ilość.`)

                    if (czyWyslanoEnterem) {
                        this.setState({czyZaokraglonoIWyslanoEnterem: true})
                    }

                    this.forceUpdate(() => {

                        this.setState({amount: ilosc});

                        //@ts-ignore
                        if (Boolean(document.getElementById('input-' + item.indeks))) {
                            //@ts-ignore
                            document.getElementById('input-' + item.indeks).value = ilosc;
                        }


                    });

                    this.props.handleAddOrChangeProductQuantityInBasket((previousAmount === 0 && amount > 0), item, ilosc, ilosc, zakupOpakowanieTyp.value, zakupOpakowanieTyp.jm)

                    /*
                    this.forceUpdate(() => {

                        this.setState({amount: ilosc});

                        //@ts-ignore
                        if (Boolean(document.getElementById('input-' + item.indeks))) {
                            //@ts-ignore
                            document.getElementById('input-' + item.indeks).value = ilosc;
                        }


                    });

                     */
                } else {
                    console.log('poprawna ilosc');
                    this.props.handleAddOrChangeProductQuantityInBasket((previousAmount === 0 && amount > 0), item, ilosc, amount, zakupOpakowanieTyp.value, zakupOpakowanieTyp.jm)
                }
            }
        } else {
            displayNotificationError('Błąd dodania produktu do koszyka', 'Podano nieprawidłową ilość produktu')
        }

    };

    onClickInput = () => {
        //console.log('test click ' + this.state.amount)
        let {item} = this.props;
        let {amount} = this.state;

        if (amount===0){
            //@ts-ignore
            if (Boolean(document.getElementById('input-' + item.indeks))) {
                //@ts-ignore
                document.getElementById('input-' + item.indeks).value = '';
            }
        }
    }

    /*{(item.koszykIlosc===0)?zakupOpakowanieTyp:this.valueSelect()}*/
    render() {

        let {item} = this.props;
        let {amount, zakupOpakowanieTyp, options} = this.state;

        return (
            <div id={'Shared_ProductCardAddForm'}>
                <h1 id={'price-container'}>
                    <Badge variant={'warning'}>
                        {
                            formatMoney(this.renderObliczCene(zakupOpakowanieTyp))
                        }
                    </Badge>
                    <span>
                        / {this.renderWygenerujTypZaCoCena(zakupOpakowanieTyp)}
                    </span>
                </h1>
                <div id={'form-container'}>
                    <label>
                        Wybierz typ zakupu oraz ilość towaru
                    </label>
                    <Form inline={true}>
                        <FormGroup>
                            <Select
                                id={'SelectZakupOpakowanieTyp'}
                                value={zakupOpakowanieTyp}
                                options={options}
                                // isDisabled={!item.dostepne}
                                isClearable={false}
                                onChange={this.onChangeZakupOpakowanieTyp}
                                styles={NormalSelectStyles}
                                isSearchable={!isMobileDevice()}
                            />
                        </FormGroup>
                        <FormGroup>
                            <InputNumber
                                value={amount}
                                onChange={this.onChangeAmount}
                                // disabled={!item.dostepne}
                                precision={!(zakupOpakowanieTyp.value === 'jednostka') ? 0 : (item.jm.indexOf('KG')) > -1 ? 2 : 0}
                                step={!(zakupOpakowanieTyp.value === 'jednostka') ? 1 : (item.jm === item.jm2) ? item.ilJm3 : item.ilJm2}
                                min={MIN} max={MAX}
                                onPressEnter={this.onPressEnter}
                                id={'input-' + item.indeks}
                                onBlur={() => {
                                    if (this.state.czyZaokraglonoIWyslanoEnterem)
                                        this.setState({amount: this.props.item.koszykIlosc, czyZaokraglonoIWyslanoEnterem: false})
                                }}
                                onClick={this.onClickInput}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Button variant={'primary'} onClick={this.onClickButtonDodaj}
                                    // disabled={!item.dostepne}
                            >
                                {
                                    (item.koszykIlosc && item.koszykIlosc > 0) ?
                                        <><IconRefresh/> Zmień</>
                                        :
                                        <><IconCartPlus/> Dodaj</>
                                }
                            </Button>
                        </FormGroup>
                        {
                            (item.koszykIlosc && item.koszykIlosc > 0) ?
                                <TextAlert type={'info'}>Produkt jest już dodany do koszyka - możesz zmienić jego ilość</TextAlert>
                                :
                                ""
                        }
                    </Form>
                </div>
            </div>
        );
    }
}

export default Shared_ProductCardAddForm;
