import React from 'react';
import {APP_UPDATE, APP_VERSION} from "../../ApplicationConfig";
import {Col, Row} from "react-bootstrap";

const FooterComponent = () => {
    return (
        <footer>
            <Row>
                <Col>
                    <span className={'realizacja'}>Realizacja: <a href="http://topsa.com.pl/" target="_blank">TOP S.A.</a>  - oprogramowanie: <a href={"https://topsa.com.pl/produkty/top-ehurt"} target="_blank"> Top eHurt</a></span>
                </Col>
                <Col>
                    <span>2023 &copy; TOP S.A.</span>
                    <span className={'divider'}>|</span>
                    <span>
                        VER: {APP_VERSION()} {APP_UPDATE()}
                    </span>
                </Col>
            </Row>
        </footer>
    );
};

export default FooterComponent;