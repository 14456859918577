import React, {Component} from 'react';
import TopCard from "../../../../sharedComponents/TopCard";
import {Button, ButtonToolbar, Col, Form, FormControl, FormGroup, Row} from "react-bootstrap";
import Req from "../../../../sharedComponents/formComponents/Req";
import AddFormMargin from "../../../../sharedComponents/formComponents/AddFormMargin";
import Daterange from "../../../../sharedComponents/formComponents/Daterange";
import Select from "react-select";
import {API_getProducts} from "../../../../_static_data/response";
import {IconSave, IconTimes} from "../../../../sharedComponents/icons/FontAwesome";
import {SmallerSelectStyles} from "../../../../utilities/forms/jsStyles";
import {isMobileDevice} from "../../../../utilities/rwd/rwdUtilities";

interface Props {

}

class AdminPromocjeForm extends React.Component<Props> {

    state = {
        produktySelected: []
    };

    onChangeProdukty = (arr: any) => {
        this.setState({
            produktySelected: arr
        })
    };

    render() {

        let {produktySelected} = this.state;
        const produkty = API_getProducts();

        const customProduktComponent = (props: any) => (
            <div {...props.innerProps} style={{cursor: "pointer", padding: "0 15px"}}>
                <span>{props.data.producent} {props.data.nazwa}</span>
            </div>
        );

        return (
            <div id={'AdminPromocjeForm'}>
                <TopCard hasPadding={true} heading={'Dodaj / edytuj promocję'}>
                    <Form>
                        <Row>
                            <Col md={6} lg={4} xl={4}>
                                <FormGroup>
                                    <Form.Label>Nazwa: <Req/></Form.Label>
                                    <FormControl size={'sm'}/>
                                </FormGroup>
                            </Col>
                            <Col md={6} lg={4} xl={2}>
                                <FormGroup>
                                    <AddFormMargin hideForMobileDevices767={true}/>
                                    <Form.Check type="checkbox" label="Promocja aktywna"/>
                                </FormGroup>
                            </Col>
                            <Col md={6} lg={8} xl={6}>
                                <FormGroup>
                                    <Form.Label>Zakres dat wazności: <Req/></Form.Label>
                                    <Daterange startValue={'2020-01-01'} endValue={'2020-01-01'}
                                               onChangeValueStart={() => {
                                               }} onChangeValueEnd={() => {
                                    }}/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Form.Label>Produkty objęte promocją: <Req/></Form.Label>
                                    <Select
                                        value={produktySelected}
                                        options={produkty}
                                        onChange={this.onChangeProdukty}
                                        isMulti={true}
                                        getOptionLabel={({nazwa}: any) => nazwa}
                                        getOptionValue={({id}: any) => id}
                                        styles={SmallerSelectStyles}
                                        components={{Option: customProduktComponent}}
                                        placeholder={'Wybierz...'}
                                        isSearchable={!isMobileDevice()}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                    <AddFormMargin/>
                                    <div className={'float-right'}>
                                        <ButtonToolbar>
                                            <Button variant={'light'} size={'sm'}
                                                    onClick={()=>{}}><IconTimes/> Anuluj</Button>
                                            <Button variant={'primary'} size={'sm'}
                                                    onClick={()=>{}}><IconSave/> Zapisz</Button>
                                        </ButtonToolbar>
                                    </div>
                                    <div className={'clearfix'}></div>
                            </Col>
                        </Row>
                    </Form>
                </TopCard>
            </div>
        );
    }
}

export default AdminPromocjeForm;