import React from 'react';
import TopCard from "../../../sharedComponents/TopCard";
import {TextAlert} from "../../../sharedComponents/typographyComponents/TextAlert/TextAlert";
import ZamowieniaService from "../../../services/ZamowieniaService";
import {getFirstDayOfCurrentMonthAndYear} from "../../../utilities/calendar_date/calendar_dateUtilities";
import TopLoading from "../../../sharedComponents/loader/TopLoading";
import {Link} from "react-router-dom";

class InformacjeZamowieniaComponent extends React.Component {

    state={
        zamowienia:[],
        isLoading:false,
        isError:false,
    };

    componentDidMount() {
        this.pobierzZamowieniaZOstatniegoMiesiaca()
    }


    pobierzZamowieniaZOstatniegoMiesiaca = () =>{
        this.setState({
            isLoading:true,
            isError:false,
        });
      ZamowieniaService.getZamowienia(0,999,
          {
              "czyPokazacZamowieniaPolaczone": true,
              "dataZamowien": getFirstDayOfCurrentMonthAndYear(),
              "symbolZam": ""
          })
          .then((response:any)=>{
              if(response && response.status<300){
                  this.setState({
                      zamowienia:response.data.content,
                      isLoading:false,
                      isError:false,
                  })
              }else{
                  this.setState({
                      isLoading:false,
                      isError:true,
                  })
              }
          })
          .catch((error:any)=>{
              this.setState({
                  isLoading:false,
                  isError:true,
              })
          })
    };

    render() {
        return (
            <div id={'InformacjeZamowieniaComponent'}>
                <TopCard heading={'Zamówienia'} hasPadding={true}>
                    <h6 className={'heading-label'}>
                        Zamówienia z bieżącego miesiąca
                    </h6>
                    <TopLoading isLoading={this.state.isLoading} isError={this.state.isError} errorMessage={'Wystąpił błąd podczas próby pobrania zamówień'} isBig={false}>
                        {
                            this.state.zamowienia.length === 0?
                                <TextAlert type={"info"}>Brak zamówień</TextAlert>
                                :
                                <TextAlert type={"info"}>Znaleziono {this.state.zamowienia.length} zamówień. <Link to={'/zamowienia'}>Kliknij tutaj</Link> aby je zobaczyć.</TextAlert>
                        }
                    </TopLoading>
                </TopCard>
            </div>
        );
    }
}

export default InformacjeZamowieniaComponent;