import React from 'react';
import TopCard from "../../../../sharedComponents/TopCard";
// import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import BootstrapTable from "react-bootstrap-table-next";
import {
    TableActionButtons_EditDelete,
    TableActionsButtons_AnyAction
} from "../../../../sharedComponents/tableComponents/tableButtons";
import {IconEdit, IconList, IconTrash} from "../../../../sharedComponents/icons/FontAwesome";
import Select from "react-select";
import {SmallerSelectStyles} from "../../../../utilities/forms/jsStyles";
import {isMobileDevice} from "../../../../utilities/rwd/rwdUtilities";
import paginationFactory from "react-bootstrap-table2-paginator";
// @ts-ignore
import overlayFactory from 'react-bootstrap-table2-overlay';
import AdminKlienciTable from "../../admin_klienci/components/AdminKlienciTable";
import {Button, ButtonToolbar} from "react-bootstrap";
import moment from "moment";
import { HOST_API } from '../../../../ApplicationConfig';
import {isEmpty} from "../../../../utilities/arrays/arraysUtilities";

interface Props {
    banery: any[],
    handleClickPokaz: (row: any) => void;
    handleClickZmienStatus: (row: any) => void;
    handleClickDelete: (idUm: any) => void;
    handleClickEdit: (baner: any) => void;
    loading: boolean;
}

class AdminBaneryTable extends React.Component<Props> {

    formatAkcjeRow = (cell: any, row: any) => {
        return <ButtonToolbar className={'TableButtons'}>
            <Button variant={'light'}
                    size={'sm'}
                    onClick={() => this.props.handleClickEdit(row)}
                    title={'Kliknij aby edytować baner'}
                    className={'edit'}
            >
                <IconEdit/>
            </Button>
            <Button variant={'light'}
                    size={'sm'}
                    onClick={() => this.props.handleClickDelete(cell)}
                    title={'Kliknij aby usunąć baner'}
                    className={'delete'}
            >
                <IconTrash/>
            </Button>
        </ButtonToolbar>
    };

    formatData = (cell: any, row: any) => {
        return moment(cell).format('YYYY-MM-DD HH:mm');
    }

    formatBaner = (cell: any, row: any) => {
        return <img className="d-block w-100"
                   src={HOST_API + `public/klient/getImgForBaner/${cell}`}
                   alt="Baner"
                />;
    }

    formatLink = (cell: any, row: any) => {
        if(!isEmpty(cell)){
            return <a href={cell} target="_blank">{cell}</a>
        } else {
            return ''
        }
    }

    render() {

        let {banery, loading} = this.props;

        const columns = [{
            dataField: 'id',
            text: 'ID',
            align: 'center'
        }, {
            dataField: 'id',
            text: 'Baner',
            align: 'center',
            style: {width: '300px'},
            formatter: this.formatBaner
        }, {
            dataField: 'tekst',
            text: 'Opis',
            align: 'center'
        }, {
            dataField: 'urlLink',
            text: 'Link do promocji',
            align: 'center',
            formatter: this.formatLink
        },
        //     {
        //     dataField: 'nazwaOddzialu',
        //     text: 'Oddział',
        //     align: 'center'
        // },
            {
            dataField: 'dataOd',
            text: 'Data od',
            align: 'center',
            style: {width: '120px'},
            formatter: this.formatData
        }, {
            dataField: 'dataDo',
            text: 'Data do',
            align: 'center',
            style: {width: '120px'},
            formatter: this.formatData
        }, {
            dataField: 'id',
            text: 'Akcje',
            style: {width: '68px'},
            formatter: this.formatAkcjeRow
        }];

        return (
            <div id={'AdminBaneryTable'}>
                <TopCard heading={'Banery'} hasPadding={false}>
                    <BootstrapTable remote keyField='id' data={banery} columns={columns} hover
                                    noDataIndication={'Brak pasujących wyników'}
                                    loading={loading}
                                    overlay={overlayFactory({
                                        spinner: true,
                                        styles: {overlay: (base: any) => ({...base, background: 'rgba(96,93,141,0.3)'})}
                                    })}
                        // pagination={(paginationFactory({
                        //     page: (pageableObject.currentPage) + 1,
                        //     sizePerPage: pageableObject.currentSize,
                        //     totalSize: pageableObject.totalElements,
                        //     sizePerPageList: [10, 12, 20, 50, 100],
                        //     onSizePerPageChange: handleChangeSizePerPage,
                        //     onPageChange: handleChangePage
                        // }))}
                    />
                </TopCard>
            </div>
        )
    }
};

export default AdminBaneryTable;
