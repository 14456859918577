import React, {useState} from 'react';
import TopCard from "../../../sharedComponents/TopCard";
// import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import {Link} from "react-router-dom";
import {IconPlus, IconMinus, IconLock, IconLockOpen, IconLoading} from "../../../sharedComponents/icons/FontAwesome";
import {TableImage_WithPreview} from "../../../sharedComponents/tableComponents/tableImages";
import {
    TableFormatters_formatBoolean,
    TableFormatters_formatMoney,
    TableFormatters_formatPromocja
} from "../../../sharedComponents/tableComponents/tableFormatters";
import {prettyColumns_Oferta} from "../../../utilities/exports/prettyColumnToExport";
import OverflowTextWrapper from "../../../sharedComponents/typographyComponents/overflowText/OverflowTextWrapper";
import {IconCart} from "../../../sharedComponents/icons/FontAwesome";
import Shared_ProductAddToBasketForm from "../../../sharedComponents/_reusable/ProductAddToBasket/Shared_ProductAddToBasketForm";
import {famix_zaokraglijIloscDlaJM1} from "../../../utilities/famix_others/famix_operations";
import TopIndeksWithBiggerView from "../../../sharedComponents/TopIndeksWithBiggerView";
// @ts-ignore;
import ReactTooltip from "react-tooltip";
import Shared_ProductImage
    from "../../../sharedComponents/_reusable/ProductImageLazyLoadingWithErrorHandling/Shared_ProductImage";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {SizePerPageDropdownStandalone} from 'react-bootstrap-table2-paginator';
// @ts-ignore
import overlayFactory from 'react-bootstrap-table2-overlay';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import {changeSortowanie} from "../../../utilities/arrays/arraysUtilities";
import Switch from "react-switch";
import IconPromocja from "./IconPromocja";
import {Form} from "react-bootstrap";

interface Props {
    produkty: object[],
    czyWidokPromocji?: boolean,
    pageableObject?: any,
    handleChangeSizePerPage?: (e: number) => void,
    handleChangePage?: (e: any, a: any) => void,
    handleChangeInBasket: (previousAmount: any, amount: any, item: any, opakowanie: string) => void,
    odswiezIlosc: (amount: any, item: any) => void,
    odswiezIloscLokalnie?: (amount: any, item: any, typOpak?: string) => void,
    czyEdycja: boolean,
    handleChangeSortowanie?: (sortowanie: any) => void,
    sortProps?: any,
    changeJm: () => void,
    zakupNaKtr?: boolean,
    openPromocjePopup: (indeks: any) => void,
    changeOferta: boolean,
    loading: boolean
}

interface State {
    sizeColumn: any,
    editSizeColumn: boolean,
    sort: any,
    sortNew: any,
    changeProps: boolean
}

class OfertaTable extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            sizeColumn: {
                "zdjecie": 70,
                "indeks": 100,
                "producent": 100,
                "nazwa": 210,
                "ean": 110,
                "cenac": 90,
                "cenas": 90,
                "prom": 60,
                "dost": 60,
                "opakkarton": 96,
                "koszyk": 120,
            },
            editSizeColumn: false,
            sort: "",
            sortNew: [],
            changeProps: false
        }
    }

    formatZdjecieRow = (cell: string, row: any) => {
        // console.log('zdjecie');
        return (
            <div>
                <div data-tip data-for={"punkt" + row.indeks}>
                    <TableImage_WithPreview item={row} desc={'Produkt ' + row.nazwa}/>
                </div>

                <ReactTooltip place="right" type="light" effect="float" id={"punkt" + row.indeks} className={'image-tooltip'} globalEventOff='click'>
                    <div id={'ProductImage'} data-event-off='click'>
                        <Shared_ProductImage item={row} />
                    </div>
                </ReactTooltip>
            </div>
            )
    };

    formatMoneyRow = (cell: number, row: object) => {
        return <TableFormatters_formatMoney value={cell}/>
    };

    formatPromocjaRow = (cell: any, row: any) => {
        // if (cell !== null && cell > 0)
        //     return (<TableFormatters_formatPromocja value={cell}/>);
        return <div className={'polePromocji'} onClick={() => this.props.openPromocjePopup(row)}><IconPromocja promocje={row.ikonyTab}/></div>
    };

    formatOpakowanieRow = (cell: any, row: any) => {
        return(
            <div className={'formatOpakowanieRow'}>
                {
                    (row.jm !== row.jm2) &&
                    <div>
                        {row.jm2} ({famix_zaokraglijIloscDlaJM1(row.jm, row.ilJm2)} {row.jm})
                    </div>
                }
                {
                    (row.jm2 !== row.jm3) &&
                    <div>
                        {row.jm3} ({famix_zaokraglijIloscDlaJM1(row.jm, row.ilJm3)} {row.jm})
                    </div>
                }
                {
                    (row.jm === row.jm2 === row.jm3) &&
                    <span>
                        -
                    </span>
                }
            </div>
        )
    };

    formatDostepnoscRow = (cell: boolean) => {
        return <TableFormatters_formatBoolean value={cell} titleTrue={'Produkt jest dostępny'}
                                              titleFalse={'Produkt jest niedostępny'}/>
    };


    // zmienInput = (item: any, ilosc: any) => {
    //     //alert('ok')
    //     //console.log('item')
    //     //console.log(item)
    //     let ile = ilosc;
    //
    //     //let jm = item.jm
    //
    //     if (item.jm2 === ('DIS') || item.jm === ('KG')){
    //         //jm = item.jm2;
    //         ile = ((ile * item.ilJm3) / item.ilJm2);
    //         //@ts-ignore
    //         //document.getElementById('input-' + item.indeks).value = ile;
    //         //alert(ile);
    //         //this.props.odswiezIloscWOfercieLokalnie(ilosc,item);
    //     }
    //
    //     //@ts-ignore
    //     //document.getElementById('input-' + indeks).value = ilosc;
    //     ////@ts-ignore
    //     //document.getElementById('input-' + indeks).focus();
    //
    // }

    formatNazwaRow = (cell: string) => {
        let {sortProps} = this.props;
        return <div className={'tekstCenter'}>{cell}</div>
        // <OverflowTextWrapper text={cell} howManyLettersVisible={this.props.czyWidokPromocji ? 85 : 55}/>
    };

    formatTekstCenter = (cell: string) => {
        return <div className={'tekstCenter'}>{cell}</div>
    };

    formatProducentRow = (cell: string) => {
        return <div className={'tekstCenter'}>{cell}</div>
        // <OverflowTextWrapper text={cell} howManyLettersVisible={this.props.czyWidokPromocji ? 22 : 10}/>
    };

    onSortChange = (sortName: any, sortOrder: any) => {

        // @ts-ignore
        this.props.handleChangeSortowanie(changeSortowanie(this.props.sortProps, sortName));

    }

    ikonaSort = (typ: string) => {
        let {sortProps} = this.props;
        let index = -1;

        for (let i = 0; sortProps.length > i; i++)
        {
            if (sortProps[i].kolumna === typ)
            {
                index = i;
                break;
            }
        }

        if (index !== -1)
        {
            if (sortProps[index].kierunek === "asc")
            {
                return <i className="fa fa-sort-asc" />
            } else
                return <i className="fa fa-sort-desc" />
        } else
            return <i className="fa fa-sort" style={{color: '#aeaeae'}}/>

    }

    // static getDerivedStateFromProps: React.GetDerivedStateFromProps<
    //     Props, any> = (nextProps, prevState) => { //console.log(nextProps.item[nextProps.fieldForAmount], prevState.amount)
    //
    //     if(nextProps.data !== prevState.oferta)
    //     {
    //         return {oferta: nextProps.data}
    //     }
    //     return null;
    // };

    // shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>, nextContext: any): boolean {
    //
    //     console.log('should')
    //
    //     return true
    //
    // }

    // componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    //     console.log('up');
    //     this.setState({oferta: !this.state.oferta});
    // }

    formatKoszykHeader = () => {
            return(<div className='changeJM'>
                <label> SZT</label>
                    <div style={{marginTop: 'auto', marginBottom: 'auto', display: 'inline-block'}}>
                        <Switch onChange={ () => {this.props.changeJm(); this.setState({changeProps: this.state.changeProps});}}
                                checked={Boolean(this.props.zakupNaKtr)}
                                handleDiameter={13}
                                height={7}
                                width={20}
                                checkedIcon={false}
                                uncheckedIcon={false}
                                offColor={'#605D8D'}
                                onColor={'#605D8D'}
                        />
                    </div>

                <Form.Label> KRT</Form.Label>
            </div>
        )
    };




    render(){
        const {produkty, pageableObject, handleChangeSizePerPage, handleChangePage, loading} = this.props;
        const {changeProps} = this.state;

        // console.log('render')

        const columns = [{
            dataField: 'imagePath',
            text: 'Zdjęcie',
            formatter: this.formatZdjecieRow
        }, {
            dataField: 'indeks',
            text: 'Indeks',
            headerFormatter: () => {return <>Indeks &nbsp;{this.ikonaSort('indeks')}</>},
            align: 'center',
            headerStyle: {cursor: 'pointer'},
            formatter: (cell: any, row: any, index: any, extraData: any) => (
                <TopIndeksWithBiggerView item={row} openPromocjePopup={this.props.openPromocjePopup} odswiezIlosc={this.props.odswiezIlosc} alwaysDostepne={true} edycja={this.props.czyEdycja}><a id='indeks'>{cell}</a></TopIndeksWithBiggerView>
            ),
            onSort: this.onSortChange,
            sort: true
        }, {
            dataField: 'producent',
            text: 'Producent',
            headerFormatter: () => {return <>Producent &nbsp;{this.ikonaSort('cast(PRODUCENT as varchar(60) character set win1250) collate PXW_PLK ')}</>},
            align: 'center',
            headerStyle: {cursor: 'pointer'},
            onSort: this.onSortChange,
            sort: true
        }, {
            dataField: 'nazwaPokaz',
            text: 'Nazwa',
            headerFormatter: () => {return <>Nazwa &nbsp;{this.ikonaSort('cast(NAZWA as varchar(60) character set win1250) collate PXW_PLK ')}</>},
            align: 'center',
            headerStyle: {cursor: 'pointer'},
            onSort: this.onSortChange,
            sort: true
        }, {
            dataField: 'nrKat',
            text: 'EAN szt.',
            headerFormatter: () => {return <>EAN szt. &nbsp;{this.ikonaSort('nr_kat')}</>},
            align: 'center',
            headerStyle: {cursor: 'pointer'},
            onSort: this.onSortChange,
            sort: true
        }, {
            dataField: 'cenaS',
            text: 'Cena cennik.',
            headerFormatter: () => {return <>Cena cennik. &nbsp;{this.ikonaSort('cena_s')}</>},
            align: 'center',
            headerStyle: {cursor: 'pointer'},
            formatter: this.formatMoneyRow,
            onSort: this.onSortChange,
            sort: true
        }, {
            dataField: 'cena',
            text: 'Cena sprz.',
            headerFormatter: () => {return <>Cena sprz. &nbsp;{this.ikonaSort('cena')}</>},
            headerStyle: {cursor: 'pointer'},
            formatter: this.formatMoneyRow,
            onSort: this.onSortChange,
            sort: true
        }, {
            dataField: 'upustProcentowy',
            text: 'Prom.',
            headerFormatter: () => {return <>Prom. &nbsp;{this.ikonaSort('upust_procentowy')}</>},
            style: {maxWidth: '70px'},
            headerStyle: {cursor: 'pointer'},
            align: 'center',
            formatter: this.formatPromocjaRow,
            onSort: this.onSortChange,
            sort: true
        }, {
            dataField: 'jm',
            text: 'Opak / karton',
            formatter: this.formatOpakowanieRow
        }, {
            dataField: 'zakupJm',
            text: 'Koszyk',
            headerFormatter: () => {return( <><IconCart/>&nbsp; Koszyk&nbsp;{this.ikonaSort('koszyk_ilosc')}</>)},
            style: {width: '145px'},
            formatExtraData: changeProps || this.props.changeOferta,
            formatter: (cell: any, row: any, index: any, extraData: any) => (
                <Shared_ProductAddToBasketForm item={row} fieldForAmount={'koszykIlosc'} alwaysDostepne={true} inline={true} odswiezIloscLokalnie={this.props.odswiezIloscLokalnie} showButton={false} handleChangeInBasket={this.props.handleChangeInBasket}/>
            ),
            headerStyle: {cursor: 'pointer'},
            onSort: this.onSortChange,
            sort: true
        }];

        return (
            <div id={'OfertaTable'}>
                <TopCard heading={'Produkty w ofercie'} hasPadding={false} pobierzOferte={false} rightComponent={ this.formatKoszykHeader()}
                         columnsNames={prettyColumns_Oferta}>

                    {/*<div className={'famix-loader big'}>*/}
                    {/*    <div>*/}
                    {/*        <IconLoading/>*/}
                    {/*    </div>*/}
                    {/*    /!*<div>*!/*/}
                    {/*    /!*    {loadingText}*!/*/}
                    {/*    /!*</div>*!/*/}
                    {/*</div>*/}
                    <BootstrapTable remote keyField='indeks' data={ produkty } columns={ columns } hover noDataIndication={'Brak pasujących wyników'}
                                    headerClasses={'headerbs'}
                                    loading={loading}
                                    // overlay={ overlayFactory({ spinner: true, styles: { overlay: (base: any) => ({...base, background: 'rgba(200,200,200,0.5)'}) } }) }
                                    overlay={ overlayFactory({ spinner: true, styles: { overlay: (base: any) => ({...base, background: 'rgba(96,93,141,0.3)'}) } }) }
                                    pagination={(paginationFactory({
                                        page: (pageableObject.currentPage === 99999)? 1 : pageableObject.currentPage+1,
                                        sizePerPage: pageableObject.currentSize,
                                        totalSize: pageableObject.allElements,
                                        sizePerPageList: [10, 12, 20, 50, 100],
                                        onSizePerPageChange: handleChangeSizePerPage,
                                        onPageChange: handleChangePage
                                    }))}
                    />

                </TopCard>
            </div>
        );
    }
};

export default OfertaTable;
