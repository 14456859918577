import React from 'react';
import {formatMoney} from "../../../utilities/formatters/formatMoney";
import TopCard from "../../../sharedComponents/TopCard";
import {IconList, IconPrint, IconReklamacja} from "../../../sharedComponents/icons/FontAwesome";
import {prettyColumns_Faktury} from "../../../utilities/exports/prettyColumnToExport";
import {TableActionsButtons_AnyAction} from "../../../sharedComponents/tableComponents/tableButtons";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import FakturyExpand from "./tableComponents/FaturyExpand";
import {Button} from "react-bootstrap";
import {changeSortowanie} from "../../../utilities/arrays/arraysUtilities";
// @ts-ignore
import overlayFactory from 'react-bootstrap-table2-overlay';

interface Props {
    data: object[],
    handleClickPrzygotujReklamacje: (faktura: any) => void;
    handleClickPokazMenuWydrukow: (faktura: any) => void;
    pageableObject?: any,
    handleChangeSizePerPage?: (e: number) => void;
    handleChangePage?: (e: any, a: any) => void;
    handleChangeSortowanie?: (sortowanie: any) => void,
    sortProps?: any,
    loading: boolean
}

class FakturyTable extends React.Component<Props> {

    state = {
        currentFaktura: null,
        currentPozycje: [],
        isLoadingPozycje: false,
        isErrorPozycje: false,
        expanded: []
    };

    formatFakturaDrukRow = (cell: any,row:any) => {
        return <TableActionsButtons_AnyAction handleClickAction={() => {
            this.props.handleClickPokazMenuWydrukow(row)
        }} tooltip={'Pokaż menu wydruków'}><IconPrint/></TableActionsButtons_AnyAction>

    };

    formatReklamacjaRow = (cell: number, row: object) => {
        return <TableActionsButtons_AnyAction handleClickAction={() => this.onClickShowPopupReklamacja(row)} tooltip={'Przygotuj reklamację'}><IconReklamacja/></TableActionsButtons_AnyAction>
    };

    formatMoneyRow = (cell: number) => {
        return <div className={"text-right"}>{formatMoney(cell)}</div>
    };

    onClickShowPopupReklamacja = (row: object) => {
        this.props.handleClickPrzygotujReklamacje(row)
    };

    expandRow = {
        renderer: (row: any) => (
            <div>
                <FakturyExpand row={row}/>
            </div>
        ),
        onlyOneExpanding: true,
        expandByColumnOnly: true,
        showExpandColumn: true,
        onExpand: (row: any, isExpand: any, rowIndex: any, e: any) => {
            e.stopPropagation();
        },
        expandHeaderColumnRenderer: ({ }) => {
            return <>Poz.</>
        },
        // @ts-ignore
        expandColumnRenderer: ({expanded} ) => {
            return <div className={'TableButtons'}><Button variant={'light'} size={'sm'}><IconList/></Button></div>
        }
    };

    ikonaSort = (typ: string) => {
        let {sortProps} = this.props;
        let index = -1;

        for (let i = 0; sortProps.length > i; i++)
        {
            if (sortProps[i].kolumna === typ)
            {
                index = i;
                break;
            }
        }

        if (index !== -1)
        {
            if (sortProps[index].kierunek === "asc")
            {
                return <i className="fa fa-sort-asc" />
            } else
                return <i className="fa fa-sort-desc" />
        } else
            return <i className="fa fa-sort" style={{color: '#aeaeae'}}/>

    }

    onSortChange = (sortName: any, sortOrder: any) => {

        // @ts-ignore
        this.props.handleChangeSortowanie(changeSortowanie(this.props.sortProps, sortName));

    }

    columns = [{
        dataField: 'numerFaktury',
        text: 'Numer',
        headerFormatter: () => {return <>Numer &nbsp;{this.ikonaSort('numer')}</>},
        align: 'center',
        headerStyle: {cursor: 'pointer'},
        onSort: this.onSortChange,
        sort: true
    }, {
        dataField: 'magazyn',
        text: 'Magazyn',
        align: 'center',
        headerFormatter: () => {return <>Magazyn &nbsp;{this.ikonaSort('numag')}</>},
        headerStyle: {cursor: 'pointer'},
        onSort: this.onSortChange,
        sort: true
    }, {
        dataField: 'podstawa',
        text: 'Podstawa',
        headerFormatter: () => {return <>Podstawa &nbsp;{this.ikonaSort('podstawa')}</>},
        headerStyle: {cursor: 'pointer'},
        onSort: this.onSortChange,
        sort: true
    }, {
        dataField: 'dataWys',
        text: 'Data wystawienia',
        headerFormatter: () => {return <>Data wystawienia &nbsp;{this.ikonaSort('data_wys')}</>},
        headerStyle: {cursor: 'pointer'},
        onSort: this.onSortChange,
        sort: true,
        align: 'center'
    }, {
        dataField: 'termPl',
        text: 'Termin płatności',
        headerFormatter: () => {return <>Termin płatności &nbsp;{this.ikonaSort('term_pl')}</>},
        headerStyle: {cursor: 'pointer'},
        onSort: this.onSortChange,
        sort: true,
        align: 'center'
    }, {
        dataField: 'kwota',
        text: 'Kwota',
        formatter: this.formatMoneyRow,
        headerFormatter: () => {return <>Kwota &nbsp;{this.ikonaSort('kwota')}</>},
        headerStyle: {cursor: 'pointer'},
        onSort: this.onSortChange,
        sort: true
    }, {
        dataField: 'wystawil',
        text: 'Wystawił',
        headerFormatter: () => {return <>Wystawił &nbsp;{this.ikonaSort('wystawil')}</>},
        headerStyle: {cursor: 'pointer'},
        onSort: this.onSortChange,
        sort: true
    }, {
        dataField: 'numer',
        text: 'Exp',
        formatter: this.formatFakturaDrukRow
    }, {
        dataField: 'numer',
        text: 'Rekl.',
        formatter: this.formatReklamacjaRow
    }];

    render() {
        let {data, pageableObject, handleChangeSizePerPage, handleChangePage, loading} = this.props;

        return (
            <>
                <div id={'FakturyTable'}>
                    <TopCard heading={'Faktury w systemie'} hasPadding={false}
                             enableExport={true}
                             dataToExport={data}
                             columnsNames={prettyColumns_Faktury}
                    >

                        <BootstrapTable remote keyField='numerFaktury' data={ data } columns={ this.columns } hover noDataIndication={'Brak pasujących wyników'}
                                        expandRow={this.expandRow}
                                        loading={loading}
                                        overlay={ overlayFactory({ spinner: true, styles: { overlay: (base: any) => ({...base, background: 'rgba(96,93,141,0.3)'}) } }) }
                                        pagination={(paginationFactory({
                                            page: (pageableObject.currentPage) + 1,
                                            sizePerPage: pageableObject.currentSize,
                                            totalSize: pageableObject.totalElements,
                                            sizePerPageList: [10, 12, 20, 50, 100],
                                            onSizePerPageChange: handleChangeSizePerPage,
                                            onPageChange: handleChangePage
                                        }))}
                        />

                    </TopCard>
                </div>
            </>
        );
    }
}

export default FakturyTable;
