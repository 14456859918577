import React, {Component} from 'react';
import LayoutComponent from "../../../sharedComponents/layout/LayoutComponent";
import AdminKonfiguracjaContainer from "./components/AdminKonfiguracjaContainer";

class AdminKonfiguracjaModule extends Component {
    render() {
        return (
            <LayoutComponent breadcrumbs={[{url: "/admin", name: 'PA'}, {url: "/admin/konfiguracja", name: 'Konfiguracja'}]} isAdminPanel={true}>
                <div id={'ADMIN_MODULE'}>
                    <AdminKonfiguracjaContainer />
                </div>
            </LayoutComponent>
        );
    }
}

export default AdminKonfiguracjaModule;