import React from 'react';
import LayoutComponent from "../../sharedComponents/layout/LayoutComponent";
import {Button, Col, Row, Tab, Tabs} from "react-bootstrap";
import PromocjeOgolneContainer from "./promocjeTabs/PromocjeOgolneContainer";
import PromocjeDedykowaneContainer from "./promocjeTabs/PromocjeDedykowaneContainer";
import PromocjeService from "../../services/PromocjeService";
import {TextAlert} from "../../sharedComponents/typographyComponents/TextAlert/TextAlert";
import {displayNotificationError, displayNotificationInfo} from "../../utilities/notifications/NotificationsManager";
import {isMobileDevice} from "../../utilities/rwd/rwdUtilities";
import prom from '../../sharedComponents/icons/prom.png';
import logo from '../../resources/images/famixlogos.png';
import AddFormMargin from "../../sharedComponents/formComponents/AddFormMargin";
import PromocjeGrid from "./components/PromocjeGrid";
import {Link} from "react-router-dom";
import iconmpt from '../../resources/images/mpt.png';
import TopLoading from "../../sharedComponents/loader/TopLoading";

interface MatchParams {
    path: string;
}

export interface RouteComponentProps<MatchParams> {
    match: any;
}

interface Props extends RouteComponentProps<MatchParams> {
}

class PromocjeModule extends React.Component<Props> {

    state={
        grupy: [],
        klik: false,
        isLoading: false,
        isError: false,
    };

    componentDidMount() {

        if(isMobileDevice())
            window.scrollTo(0,0);

        this.pobierzTypyPromocji();

    }

    pobierzTypyPromocji = () => {
        this.setState({
            isLoading: true,
            isError: false
        });

        PromocjeService.getPromocjeGrupyNew().then((response) => {
            this.setState({
                grupy: response.data,
                isLoading: false,
                isError: false
            });
        }).catch((e) => {
            displayNotificationError('Nie udało się pobrać grupy promocji', '');
            this.setState({
                isLoading: false,
                isError: true
            });
        })
    }

    renderIkona = (typ: string) => {
        switch (typ) {
            case 'AG':
                return <i className="fa fa-newspaper-o"/>
            case 'CPM':
                return <i className="fa fa-calendar"/>;
            case 'MPT':
                return <img src={iconmpt} style={{width: '20px', marginBottom: '5px'}} alt={'mpt'}/>;
            case 'NOW':
                return <i className="fa fa-fire"/>;
            case 'PDK':
                return <i className="fa fa-male"/>;
            case 'PLSKL':
                return <i className="fa fa-window-maximize"/>;
            case 'PRSEZ':
                return <><i className="fa fa-sun-o"/>&nbsp;
                <i className="fa fa-snowflake-o"/></>;
            case 'PT':
                return <i className="fa fa-tags"/>;
            case 'WYP':
                return <i className="fa fa-cubes"/>;
        }
    }

    render() {
        let {grupy, isError, isLoading} = this.state;
        const breadcrumbs = [{name: 'Promocje', url: '/promocje'}]

        return (
            <LayoutComponent breadcrumbs={breadcrumbs}
                             handleChangeSiec={()=>{
                                 this.setState({isLoading: true});
                                 this.pobierzTypyPromocji()
                             }}
            >
                <Row style={{marginTop: '15px'}}>
                    <Col xs={1} sm={2} md={2} lg={1} xl={1} style={{padding: '0'}} className={'align-items-center'}>
                        <img src={logo} alt={'prom'} style={{width: '11vmin'}}/>
                    </Col>
                    <Col xs={9} sm={9} md={9} lg={10} xl={10}>
                        <h1 style={{fontSize: '9vmin', color: '#383666'}}>Promocje</h1>
                    </Col>
                    <Col xs={1} sm={1} md={1} lg={1} xl={1} style={{textAlign: 'right'}}>
                        <h1 style={{fontSize: '9vmin', color: '#F9BA00'}}>%</h1>
                    </Col>
                </Row>
                <hr/>
                <div id={'PROMOCJE_MODULE'}>
                    {/*<Tabs defaultActiveKey="ogolne" id="uncontrolled-tab-example">*/}
                    {/*    <Tab eventKey="ogolne" title="Promocje ogólne">*/}
                    {/*        <PromocjeOgolneContainer/>*/}
                    {/*    </Tab>*/}
                    {/*    <Tab eventKey="dedykowane" title="Promocje dedykowane">*/}
                    {/*        <PromocjeDedykowaneContainer/>*/}
                    {/*    </Tab>*/}
                    {/*</Tabs>*/}

                    <TopLoading isLoading={this.state.isLoading} isError={this.state.isError} errorMessage={'Wystąpił błąd podczas próby pobrania grupy promocji'} isBig={true}>
                        <div className={'menuPromocje'}>
                            <Row>
                                {grupy.map((grupa: any, index: any) => {
                                    return <Col lg={6} xl={4} key={index}>
                                        <Link to={'./promocje/prom/'+grupa.grupa}>
                                            <div className={'buttonPromocja'}>
                                                <h3>{this.renderIkona(grupa.grupa)} {grupa.nazwa} </h3>
                                            </div>
                                        </Link>
                                    </Col>
                                })}
                            </Row>
                        </div>
                    </TopLoading>

                </div>
            </LayoutComponent>
        );
    }
}

export default PromocjeModule;
