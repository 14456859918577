import React, {Component} from 'react';
import {Button, ButtonToolbar, Col, Form, FormControl, FormGroup, Row} from "react-bootstrap";
import FakturaReklamacjaPozycjaItem from "./FakturaReklamacjaPozycjaItem";
import {IconSend, IconTimes} from "../../../../sharedComponents/icons/FontAwesome";
import {
    displayNotificationError,
    displayNotificationSuccess
} from "../../../../utilities/notifications/NotificationsManager";
import {TextAlert} from "../../../../sharedComponents/typographyComponents/TextAlert/TextAlert";
import {SmallerSelectStyles} from "../../../../utilities/forms/jsStyles";
import Select from "react-select";
import "./../../../../styles/modules/ReklamacjeModule.css";
import ReklamacjeService from "../../../../services/ReklamacjeService";

interface Props {
    faktura: any,
    pozycje: any,
    handleClickAnuluj: () => void;
    closeMaleOkno: () => void;
}

interface State {
    pozycjeReklamacja: any,
    jakaReklamacja: number,
    rodzajRekl: string,
    powody: any,
    powodyCF: any,
    realizacja: any,
    powodReklCalej: any,
    czyReklamacjaCalej: boolean
}

class FakturaReklamacjaContainer extends React.Component<Props, State> {

    state = {
        pozycjeReklamacja: [{
            uwagiKlient: ''
        }],
        jakaReklamacja: 0,
        rodzajRekl: '',
        powody: [],
        powodyCF: [],
        realizacja: {value:'Faktura korygująca',label:'Faktura korygująca'},
        powodReklCalej: {
            value: '',
            label: ''
        },
        czyReklamacjaCalej: false
    };

    componentDidMount() {
        // this.prepareForForm(this.props.pozycje);
    }

    prepareForForm = (pozycje: any) => {
        let {rodzajRekl} = this.state;

        //@ts-ignore
        let arr: object[] = Array.from(pozycje, (item: any) => (
                {
                    ...item,
                    czyReklamacja: false,
                    powodRekl: null,
                    realizacja: 'Faktura korygująca',
                    uwagiKlient: '',
                    symbolWz: item.symbol,
                    numerWz: item.numer,
                    pozycja: item.poz,
                    rodzajRekl: rodzajRekl
                }
            )
        );

        this.setState({pozycjeReklamacja: arr})
    };

    onChangeReklamacjaPozycji = (indeks: string, key: string, value: any) => {
        let arr = this.state.pozycjeReklamacja;
        //@ts-ignore
        let obj = arr.find((p: any) => p.indeks === indeks);
        //@ts-ignore
        obj[key] = value;
        this.setState({pozycjeReklamacja: arr})
    };

    onChangeReklamacjaWszystkie = (key: string, value: any) => {
        let arr = this.state.pozycjeReklamacja;
        //@ts-ignore
        // let obj = arr.find((p: any) => p.indeks === indeks);
        //@ts-ignore
        arr.forEach(function(item: any, index, array) {
            item[key] = value;
        });

        this.setState({pozycjeReklamacja: arr})

    };

    // onChangeUwagi = (e: any) => {
    //     this.setState({
    //         uwagiReklamacja: e.target.value
    //     })
    // };

    onClickWyslij = () => {

        let pozycjeDoReklamacji = Array.from(this.state.pozycjeReklamacja.filter((poz: any) => poz.czyReklamacja));

        if (pozycjeDoReklamacji.length === 0) {
            displayNotificationError('Błąd wysłania reklamacji', 'Nie zaznaczono żadnej pozycji do reklamacji');
        } else {
            if (pozycjeDoReklamacji.find((poz: any) => poz.powodRekl === null)) {
                displayNotificationError('Błąd wysłania reklamacji', 'Wszystkie pozycje oznaczone do reklamacji muszą zawierać powód');
            } else {
                if (pozycjeDoReklamacji.find((poz: any) => poz.ilosc === null)) {
                    displayNotificationError('Błąd wysłania reklamacji', 'Wszystkie pozycje oznaczone do reklamacji muszą zawierać ilość');
                } else {
                    // alert('Wysłano')
                    // console.log('do reklamacji:')
                    // console.log(pozycjeDoReklamacji)

                    ReklamacjeService.addReklamacje(pozycjeDoReklamacji).then((response) => {
                        this.props.handleClickAnuluj();
                        displayNotificationSuccess('Wysłano reklamacje', '');
                    }).catch((e) => {
                        displayNotificationError('Wystąpił błąd podczas próby wysłania reklamacji', '');
                    })

                }
            }
        }

    };

    onClickReklCenowa = () => {
        this.setState({jakaReklamacja: 2, rodzajRekl: 'Cena'}, () => {
            this.pobierzPowody();
        });
    }

    onClickReklIlosciowa = () => {
        this.setState({jakaReklamacja: 1, rodzajRekl: 'Ilość'}, () => {
            this.pobierzPowody();
        });
    }

    generujOpcjePowody = (rest: any) => {
        let options: any = [];

        rest.map((p: any) => {
            options.push({value: p.blad, label: p.blad});
        })

        return options;
    }

    pobierzPowody = () => {
        let {rodzajRekl} = this.state;
        this.props.closeMaleOkno();

        this.prepareForForm(this.props.pozycje);

        ReklamacjeService.getPowodyReklamacjiPozycje(rodzajRekl).then((response) => {
            this.setState({powody: this.generujOpcjePowody(response.data)});
        }).catch((e) => {
            console.log('error');
        })

        ReklamacjeService.getPowodyReklamacjiCF(rodzajRekl).then((response) => {
            this.setState({powodyCF: this.generujOpcjePowody(response.data)});
        }).catch((e) => {
            console.log('error');
        })

        // ReklamacjeService.getPowodyReklamacji(rodzajRekl).then((response) => {
        //     this.setState({powody: this.generujOpcjePowody(response.data)});
        // }).catch((e) => {
        //     console.log('error');
        // })
    }

    onChangePowodCalej = (e: any) => {
        this.setState({powodReklCalej: e})
        this.onChangeReklamacjaWszystkie('powodRekl', e.value);
    }

    onCheckReklCalej = (e: any) => {
        let {rodzajRekl, realizacja} = this.state;
        this.setState({czyReklamacjaCalej: !this.state.czyReklamacjaCalej, powodReklCalej: {
                value: '',
                label: ''
            }});
        // this.onChangeReklamacjaWszystkie('czyReklamacja', e.target.checked);
        // this.onChangeReklamacjaWszystkie('powodRekl', null);
        // this.onChangeReklamacjaWszystkie('uwagiKlient', '');

        let arr: object[] = Array.from(this.props.pozycje, (item: any) => (
                {
                    ...item,
                    czyReklamacja: e.target.checked,
                    powodRekl: null,
                    realizacja: realizacja.value,
                    uwagiKlient: '',
                    symbolWz: item.symbol,
                    numerWz: item.numer,
                    pozycja: item.poz,
                    rodzajRekl: rodzajRekl
                }
            )
        );

        this.setState({pozycjeReklamacja: arr})
    }

    reklamacjaSwitch = () => {
        let {jakaReklamacja, czyReklamacjaCalej, pozycjeReklamacja} =this.state;
        let {faktura, pozycje} = this.props;

        switch (jakaReklamacja) {
            case 0:
                return (
                    <div className={'wyborRekl'}>
                        <Button variant={'light'} size={'lg'} onClick={this.onClickReklIlosciowa}>reklamacja ilościowa</Button>
                        <Button variant={'light'} size={'lg'} onClick={this.onClickReklCenowa}>reklamacja cenowa</Button>
                    </div>
                )
            case 1:
                return (
                    <>
                        <Form>
                            {
                                pozycje.map(
                                    (pozycja: any) =>
                                        <FakturaReklamacjaPozycjaItem
                                            jakaRekl={jakaReklamacja}
                                            pozycja={pozycja}
                                            handleChangeReklamacjaPozycji={this.onChangeReklamacjaPozycji}
                                            powody={this.state.powody}
                                            reklCalej={czyReklamacjaCalej}
                                        />
                                )
                            }
                            {
                                pozycje.length === 0 &&
                                <TextAlert type={'info'}>Ta faktura nie posiada pozycji</TextAlert>
                            }
                        </Form>
                        <hr color={'#ffffff'}/>
                        <Row id={'podsumowanie'}>
                            <Col xl={4}>
                                <FormGroup>
                                    <Form.Label>Sposób realizacji reklamacji</Form.Label>
                                    <Select
                                        value={this.state.realizacja}
                                        options={
                                            [
                                                {value:'Faktura korygująca',label:'Faktura korygująca'},
                                                {value:'Dowóz towaru',label:'Dowóz towaru'}
                                            ]
                                        }
                                        onChange={(e: any)=> {this.setState({realizacja: e}); this.onChangeReklamacjaWszystkie('realizacja', e.value);}}
                                        // placeholder={'Wybierz powód reklamacji'}
                                        styles={SmallerSelectStyles}
                                        isSearchable={false}
                                        menuPosition={'fixed'}
                                        menuPlacement={'top'}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xl={4}>
                                <FormGroup>
                                    <Form.Check id={'czyReklamacjaCalejCheckbox'} inline={true} checked={this.state.czyReklamacjaCalej} type="checkbox" label="Reklamacja całej faktury" onClick={(e: any)=> this.onCheckReklCalej(e)}/>
                                    <Select
                                        value={this.state.powodReklCalej}
                                        options={this.state.powodyCF}
                                        isDisabled={!this.state.czyReklamacjaCalej}
                                        onChange={(e: any)=> this.onChangePowodCalej(e)}
                                        placeholder={'Wybierz powód'}
                                        styles={SmallerSelectStyles}
                                        isSearchable={false}
                                        menuPosition={'fixed'}
                                        menuPlacement={'top'}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xl={4} style={{textAlign: 'end'}} className={'align-self-center'}>
                                <div >
                                    {/*<ButtonToolbar>*/}
                                    <Button variant={'light'} size={'sm'} style={{marginRight: '10px'}} onClick={() => this.props.handleClickAnuluj()}><IconTimes/> Anuluj</Button>
                                    <Button variant={'primary'} size={'sm'} onClick={this.onClickWyslij}><IconSend/> Wyślij reklamację</Button>
                                    {/*</ButtonToolbar>*/}
                                </div>
                            </Col>

                            {(this.state.powodReklCalej.value.includes('inne')) &&
                            <Col xl={12}>
                                <FormGroup>
                                    <Form.Label>Powód reklamacji/uwagi</Form.Label>
                                    <FormControl size={'sm'} placeholder={'Wpisz powód reklamacji lub uwagi do całej faktury...'} value={pozycjeReklamacja[0].uwagiKlient} onChange={(e: any) => this.onChangeReklamacjaWszystkie('uwagiKlient', e.target.value)} />
                                </FormGroup>
                            </Col>
                            }

                        </Row>

                    </>


                )
            case 2:
                return (
                    <>
                        <Form>
                            {
                                pozycje.map(
                                    (pozycja: any) =>
                                        <FakturaReklamacjaPozycjaItem
                                            jakaRekl={jakaReklamacja}
                                            pozycja={pozycja}
                                            handleChangeReklamacjaPozycji={this.onChangeReklamacjaPozycji}
                                            powody={this.state.powody}
                                            reklCalej={czyReklamacjaCalej}
                                        />
                                )
                            }
                            {
                                pozycje.length === 0 &&
                                <TextAlert type={'info'}>Ta faktura nie posiada pozycji</TextAlert>
                            }
                        </Form>
                        <hr color={'#ffffff'}/>
                        <Row id={'podsumowanie'}>
                            <Col xl={4}>
                                <FormGroup>
                                    <Form.Label>Sposób realizacji reklamacji</Form.Label>
                                    <Select
                                        value={this.state.realizacja}
                                        options={
                                            [
                                                {value:'Faktura korygująca',label:'Faktura korygująca'},
                                                {value:'Dowóz towaru',label:'Dowóz towaru'}
                                            ]
                                        }
                                        onChange={(e: any)=> {this.setState({realizacja: e}); this.onChangeReklamacjaWszystkie('realizacja', e.value);}}
                                        // placeholder={'Wybierz powód reklamacji'}
                                        styles={SmallerSelectStyles}
                                        isSearchable={false}
                                        menuPosition={'fixed'}
                                        menuPlacement={'top'}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xl={4}>
                                <FormGroup>
                                    <Form.Check id={'czyReklamacjaCalejCheckbox'} inline={true} checked={this.state.czyReklamacjaCalej} type="checkbox" label="Reklamacja całej faktury" onClick={(e: any)=> this.onCheckReklCalej(e)}/>
                                    <Select
                                        value={this.state.powodReklCalej}
                                        options={this.state.powodyCF}
                                        isDisabled={!this.state.czyReklamacjaCalej}
                                        onChange={(e: any)=> this.onChangePowodCalej(e)}
                                        placeholder={'Wybierz powód'}
                                        styles={SmallerSelectStyles}
                                        isSearchable={false}
                                        menuPosition={'fixed'}
                                        menuPlacement={'top'}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xl={4} style={{textAlign: 'end'}} className={'align-self-center'}>
                                <div >
                                    {/*<ButtonToolbar>*/}
                                        <Button variant={'light'} size={'sm'} style={{marginRight: '10px'}} onClick={() => this.props.handleClickAnuluj()}><IconTimes/> Anuluj</Button>
                                        <Button variant={'primary'} size={'sm'}
                                                onClick={this.onClickWyslij}><IconSend/> Wyślij reklamację</Button>
                                    {/*</ButtonToolbar>*/}
                                </div>
                            </Col>

                            {(this.state.powodReklCalej.value.includes('inne')) &&
                            <Col xl={12}>
                                <FormGroup>
                                    <Form.Label>Powód reklamacji/uwagi</Form.Label>
                                    <FormControl size={'sm'} placeholder={'Wpisz powód reklamacji lub uwagi do całej faktury...'} value={pozycjeReklamacja[0].uwagiKlient} onChange={(e: any) => this.onChangeReklamacjaWszystkie('uwagiKlient', e.target.value)} />
                                </FormGroup>
                            </Col>
                            }

                        </Row>

                    </>


                )
        }
    }

    render() {

        let {jakaReklamacja} = this.state;
        let {faktura, pozycje} = this.props;

        return (
            <div id={'FakturaReklamacjaContainer'}>
                {this.reklamacjaSwitch()}
            </div>
        );
    }
}

export default FakturaReklamacjaContainer;