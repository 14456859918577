import React from 'react';
import {Col, Row} from "react-bootstrap";
import {formatMoney} from "../../../utilities/formatters/formatMoney";
import {Link} from "react-router-dom";
import OverflowTextWrapper from "../../../sharedComponents/typographyComponents/overflowText/OverflowTextWrapper";
import {IconTimes} from "../../../sharedComponents/icons/FontAwesome";
//@ts-ignore
import InfiniteScroll from 'react-infinite-scroller';
import TopLoaderComponent from "../../../sharedComponents/loader/TopLoaderComponent";
import {TextAlert} from "../../../sharedComponents/typographyComponents/TextAlert/TextAlert";
import Shared_ProductImage from "../../../sharedComponents/_reusable/ProductImageLazyLoadingWithErrorHandling/Shared_ProductImage";
import Shared_ProductAddToBasketForm from "../../../sharedComponents/_reusable/ProductAddToBasket/Shared_ProductAddToBasketForm";
import {TableActionsButtons_AnyAction} from "../../../sharedComponents/tableComponents/tableButtons";

interface Props {
    data: object[],
    displayImages: boolean,
    isLoading?: boolean,
    hasMore?: boolean,
    handleLoadMoreForGrid?: any,
    handleChangeQuantityInBasket: (previousAmount: any, amount: any, item: any) => void,
    handleDeleteItem:(item:any)=>void,
}

const KoszykGrid: React.FC<Props> = ({data, displayImages, isLoading, hasMore, handleLoadMoreForGrid,handleChangeQuantityInBasket,handleDeleteItem}) => {

    let formatIloscRow = (row: any) => {

        switch (row.typ) {
            case 'G':
                return <div style={{margin: '0'}}>{row.koszykIlosc} ({row.zakupJm})</div>
            case 'P':
                return <div style={{margin: '0'}}>{row.koszykIlosc} ({row.zakupJm})</div>
            default:
                return <Shared_ProductAddToBasketForm item={row} fieldForAmount={'koszykIlosc'}
                                                      inline={true}
                                                      showButton={false}
                                                      handleChangeInBasket={handleChangeQuantityInBasket}
                                                      alwaysDostepne={true}/>
        }

    };

    let formatUsunRow = (row: any) => {

        switch (row.typ) {
            case 'G':
                return <div className={'product-grid-delete'} style={{cursor: 'unset', color: '#F9BD0A'}}><i className="fa fa-gift" /> GRATIS</div>
            default:
                return <div className={'product-grid-delete'} onClick={()=>handleDeleteItem(row)}>
                    <div className={'text-danger'}>
                        <IconTimes/> Usuń z koszyka
                    </div>
                </div>
        }

    };

    return (
        <div id={'KoszykGrid'}>
            <InfiniteScroll
                initialLoad={false}
                pageStart={0}
                loadMore={handleLoadMoreForGrid}
                hasMore={hasMore}
                loader={(isLoading) ? <TopLoaderComponent loadingText={'Trwa ładowanie koszyka...'}/> : (data.length > 0 && hasMore) ? <h3 style={{color: '#ddd', textAlign: 'center'}}>Scrolluj aby załadować wiecej wyników</h3> : ''}
                threshold={-10}
            >
                <Row>
                    {
                        data.map((item: any) =>
                            <Col xs={12} sm={6} md={6} lg={4} xl={3}>
                                <div className={'product-container'}>
                                    {
                                        displayImages &&
                                        <div className={'product-image'}>
                                            <Link to={'/oferta/' + item.indeks} target={"_blank"}>
                                                <Shared_ProductImage item={item}/>
                                            </Link>
                                        </div>
                                    }
                                    <table>
                                        <tr>
                                            <td>Producent:</td>
                                            <td><OverflowTextWrapper text={item.producent} howManyLettersVisible={25}/></td>
                                        </tr>
                                        <tr>
                                            <td>Nazwa:</td>
                                            <td>
                                                <OverflowTextWrapper text={item.nazwa} howManyLettersVisible={25}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Indeks:</td>
                                            <td>{item.indeks}</td>
                                        </tr>
                                        <tr>
                                            <td>Opakowanie:</td>
                                            <td>{`${item.jm3} (${item.opakowania})`}</td>
                                        </tr>
                                        <tr>
                                            <td>Cena:</td>
                                            <td>{formatMoney(item.cena)}/{item.jm}</td>
                                        </tr>
                                        <tr>
                                            <td>VAT:</td>
                                            <td>{item.vat}%</td>
                                        </tr>
                                        <tr>
                                            <td>Wartość:</td>
                                            <td>{formatMoney(item.wartosc)}</td>
                                        </tr>
                                        <tr>
                                            <td>Ilość:</td>
                                            <td>
                                                {/*<Shared_ProductAddToBasketForm item={item} fieldForAmount={'koszykIlosc'}*/}
                                                {/*                               inline={true}*/}
                                                {/*                               showButton={false}*/}
                                                {/*                               handleChangeInBasket={handleChangeQuantityInBasket}*/}
                                                {/*                               alwaysDostepne={true}/>*/}
                                                {formatIloscRow(item)}
                                            </td>
                                        </tr>
                                    </table>
                                    {/*<div className={'product-grid-delete'} onClick={()=>handleDeleteItem(item)}>*/}
                                    {/*    <div className={'text-danger'}>*/}
                                    {/*        <IconTimes/> Usuń z koszyka*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {formatUsunRow(item)}
                                </div>
                            </Col>)
                    }
                </Row>
            </InfiniteScroll>
            {
                (!isLoading && data.length === 0) &&
                <h3 style={{color: '#ccc', textAlign: 'center'}}>Brak produktów w koszyku</h3>
            }
            {
                (!isLoading && !hasMore && data.length > 0) &&
                <h3 style={{color: '#ccc', textAlign: 'center'}}>To już wszystkie produkty w koszyku</h3>
            }
        </div>
    );
};

export default KoszykGrid;
