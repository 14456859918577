import {HOST_API} from "../ApplicationConfig";
import axios from 'axios';

const HOST = HOST_API + 'reklamacje/';

export default class ReklamacjeService {

    // static getReklamacje = (page: number, size: number, sort: string = '') => {
    //     return axios.request({
    //         method: 'get',
    //         url: `${HOST}getReklamacje?page=${page}&size=${size}&paged=true${sort}`, //&sort=m.indeks,asc`,
    //     })
    // };

    static getReklamacje = (page: number, size: number, sort: string = '', param: any) => {
        return axios.request({
            method: 'post',
            url: `${HOST}getReklamacjeWhere?page=${page}&size=${size}&paged=true${sort}`, //&sort=m.indeks,asc`,
            data: param
        })
    };

    static getPozycjeReklamacji = (id: number) => {
        return axios.request({
            method: 'get',
            url: `${HOST}getPozycjeReklamacji?idRekl=${id}`,
        })
    };

    static getPowodyReklamacji = (rodzajRekl: string) => {
        return axios.request({
            method: 'get',
            url: `${HOST}getPowodyReklamacji?rodzajRekl=${rodzajRekl}`,
        })
    };

    static getPowodyReklamacjiPozycje = (rodzajRekl: string) => {
        return axios.request({
            method: 'get',
            url: `${HOST}getPowodyReklamacjiPozycje?rodzajRekl=${rodzajRekl}`,
        })
    };

    static getPowodyReklamacjiCF = (rodzajRekl: string) => {
        return axios.request({
            method: 'get',
            url: `${HOST}getPowodyReklamacjiFaktura?rodzajRekl=${rodzajRekl}`,
        })
    };

    static addReklamacje = (data: any) => {
        return axios.request({
            method: 'put',
            url: `${HOST}addReklamacje`,
            data: data
        })
    };

    // static getPozycjeFaktury = (params: any) => {
    //     return axios.request({
    //         method: 'post',
    //         url: `${HOST}getPozycjeFaktury`,
    //         data: JSON.stringify(params)
    //     })
    // };
    //
    // static getPozycjeFakturyNew = (data: any) => {
    //     return axios.request({
    //         method: 'post',
    //         url: `${HOST}getPozycjeFakturyNew`,
    //         data: data
    //     })
    // };

}
