import React from 'react';
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import {Button, Col, Form, FormControl, FormGroup, Row} from "react-bootstrap";
import ReklamacjeService from "../../../../services/ReklamacjeService";
import {
    displayNotificationError,
    displayNotificationSuccess
} from "../../../../utilities/notifications/NotificationsManager";
import {_omit} from "../../../../utilities/lodash/lodashUtilities";
import {IconFilter, IconList, IconPrint, IconTrash} from "../../../../sharedComponents/icons/FontAwesome";
import ReklamacjeExpand from "./ReklamacjeExpand";
import TopLoading from "../../../../sharedComponents/loader/TopLoading";
import {TableActionsButtons_AnyAction} from "../../../../sharedComponents/tableComponents/tableButtons";
import WydrukiService from "../../../../services/WydrukiService";
import TopLoaderComponent from "../../../../sharedComponents/loader/TopLoaderComponent";
import {changeSortowanie, changeSortowanieReklamacje} from "../../../../utilities/arrays/arraysUtilities";
// @ts-ignore
import overlayFactory from 'react-bootstrap-table2-overlay';
import Req from "../../../../sharedComponents/formComponents/Req";
import Select from "react-select";
import {monthsArray, yearsArray} from "../../../../utilities/calendar_date/calendar_dateUtilities";
import {SmallerSelectStyles} from "../../../../utilities/forms/jsStyles";
import {isMobileDevice} from "../../../../utilities/rwd/rwdUtilities";
import AddFormMargin from "../../../../sharedComponents/formComponents/AddFormMargin";

interface Props {

}

class ReklamacjeTable extends React.Component<Props> {

    state = {
        currentFaktura: null,
        currentPozycje: [],
        isRequestLoading: true,
        isRequestError: false,
        loading: false,
        expanded: [],
        reklamacje: [],
        pageableObject: {
            currentPage: 0,
            currentSize: 0,
            totalElements: 0
        },
        size: 10,
        page: 0,
        isLoadingWydruk: false,
        isErrorWydruk: false,
        pobranoPlik: false,
        pobieranyIndex: -1,
        sort: [{kierunek: "desc", kolumna: "reklamacja"}, {kierunek: "asc", kolumna: "status"}],
        numer: 0,
        status: {value: '', label: 'Wszystkie'},
        statusOptions: [
            {value: '', label: 'Wszystkie'},
            {value: 'ZA', label: 'Załatwiona'},
            {value: 'RP', label: 'Rozpatrzona'},
            {value: 'RN', label: 'Odrzucona'},
            {value: 'ZG', label: 'Zgłoszona'},
        ]
    };

    componentDidMount() {
        this.pobierzReklamacje();
    }

    generateSortString = (): string => {
        let sortowanie = this.state.sort;
        let sortString = '';

        for (let i=0; sortowanie.length > i; i++)
        {
            // @ts-ignore
            sortString = sortString + '&sort=' + sortowanie[i].kolumna + ',' + sortowanie[i].kierunek;
        }

        return sortString;

    }

    pobierzReklamacje = (page = this.state.page, size = this.state.size) => {
        this.setState({
            // isRequestLoading: true,
            isRequestError: false
        })

        let param = {
            numer: this.state.numer,
            status: this.state.status.value
        }

        ReklamacjeService.getReklamacje(page, size, this.generateSortString(), param).then((response) => {
            this.setState({
                reklamacje: response.data.content,
                pageableObject: Object.assign({}, _omit(response.data, "content"), {currentPage: page}, {currentSize: size}),
                isRequestLoading: false,
                isRequestError: false,
                loading: false
            });
        }).catch((e) => {
            this.setState({
                isRequestLoading: false,
                isRequestError: true,
                loading: false
            })
            displayNotificationError('Wystąpił błąd podczas próby pobrania reklamacji', '')
        })
    }

    expandRow = {
        renderer: (row: any) => (
            <div>
                <ReklamacjeExpand row={row}/>
            </div>
        ),
        onlyOneExpanding: true,
        expandByColumnOnly: true,
        showExpandColumn: true,
        onExpand: (row: any, isExpand: any, rowIndex: any, e: any) => {
            e.stopPropagation();
        },
        expandHeaderColumnRenderer: ({ }) => {
            return <>Poz.</>
        },
        // @ts-ignore
        expandColumnRenderer: ({expanded} ) => {
            return <div className={'TableButtons'}><Button variant={'light'} size={'sm'}><IconList/></Button></div>
        }
    };

    onChangeSizeInTable = (size: number) => {
        this.setState({
            loading: true,
            size
        }, () => this.pobierzReklamacje())
    };

    onChangePageInTable = (page: number, size: number) => {
        this.setState({
            page: page - 1,
            loading: true,
            size: size
        }, () => this.pobierzReklamacje())
    };

    formatReklDruk = (cell: any, row:any, index: number) => {
        if(this.state.pobieranyIndex === index)
        {
            return <TopLoaderComponent loadingText={''} isBig={false} isCentered={true}/>
        } else
        {
            return <TableActionsButtons_AnyAction handleClickAction={() => {
                this.pobierzWydrukDlaKierowcy(row, index)
            }} tooltip={'Pobierz protokół reklamacyjny'}><IconPrint/></TableActionsButtons_AnyAction>
        }
    };

    pobierzWydrukDlaKierowcy = (row: any, index: number) => {

        this.setState({
            isLoadingWydruk: true,
            isErrorWydruk: false,
            pobranoPlik: false,
            pobieranyIndex: index
        });

        let dane = {
            dataWys: row.dataWys,
            faktCzyZamow: "NETFAK",
            formaty: "PDF;pdf",
            numer: row.reklamacja,
            opisFormatu: "Faktura PDF",
            recno: 269, //249,
            repFormat: "PDF",
            symbol: "RR",
            urlFormat: "pdf"
        }

        WydrukiService.getWydruk(btoa(JSON.stringify(dane)))
            .then((response: any) => {
                if (response && response.status < 300) {
                    this.setState({
                        isLoadingWydruk: false,
                        isErrorWydruk: false,
                        pobranoPlik: true,
                        pobieranyIndex: -1
                    });

                    displayNotificationSuccess('Pomyślnie zaciagnięto dane', 'Plik pobierze się automatycznie')

                    let nazwa = response.data.fileName;
                    let plik = response.data.plik;

                    let buff = new Buffer(plik, 'base64');

                    const url = window.URL.createObjectURL(new Blob([buff]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', nazwa);
                    document.body.appendChild(link);
                    link.click();

                } else {
                    this.setState({
                        isLoadingWydruk: false,
                        isErrorWydruk: true,
                        pobieranyIndex: -1
                    });
                    displayNotificationError('Wystąpił błąd podczas próby pobrania wydruku', '')
                }
            })
            .catch((error: any) => {
                this.setState({
                    isLoadingWydruk: false,
                    isErrorWydruk: true,
                    pobieranyIndex: -1
                });
                displayNotificationError('Wystąpił błąd podczas próby pobrania wydruku', error.message)
            })

    }

    ikonaSort = (typ: string) => {
        let {sort} = this.state;
        let index = -1;

        for (let i = 0; sort.length > i; i++)
        {
            if (sort[i].kolumna === typ)
            {
                index = i;
                break;
            }
        }

        if (index !== -1)
        {
            if (sort[index].kierunek === "asc")
            {
                return <i className="fa fa-sort-asc" />
            } else
                return <i className="fa fa-sort-desc" />
        } else
            return <i className="fa fa-sort" style={{color: '#aeaeae'}}/>

    }

    onChangeSortowanie = (sort: any) => {

        this.setState({sort: sort, page: 0, loading: true}, () => this.pobierzReklamacje());

    };

    onSortChange = (sortName: any, sortOrder: any) => {

        // @ts-ignore
        this.onChangeSortowanie(changeSortowanieReklamacje(this.state.sort, sortName));

    }

    onChangeStatus = (e: any) => {
        this.setState({status: e, loading: true}, () => this.pobierzReklamacje())
    };

    handleKeyPress(target: any) {
        if (target.charCode==13){
            this.setState({loading: true}, () => this.pobierzReklamacje());
        }
    }

    render() {
        let {pageableObject, reklamacje, isRequestError, isRequestLoading, statusOptions, status} = this.state;

        const columns = [{
            dataField: 'nrReklamacji',
            text: 'Numer reklamacji',
            align: 'center',
            headerFormatter: () => {return <>Numer reklamacji &nbsp;{this.ikonaSort('reklamacja')}</>},
            headerStyle: {cursor: 'pointer'},
            onSort: this.onSortChange,
            sort: true
        }, {
            dataField: 'nrFaktury',
            text: 'Nr faktury',
            align: 'center',
            headerFormatter: () => {return <>Nr faktury &nbsp;{this.ikonaSort('fk_num')}</>},
            headerStyle: {cursor: 'pointer'},
            onSort: this.onSortChange,
            sort: true
        },
            //     {
            //     dataField: 'magazyn',
            //     text: 'Magazyn',
            //     align: 'center'
            // },
            {
                dataField: 'nrKorekty',
                text: 'Nr korekty',
                align: 'center',
                headerFormatter: () => {return <>Nr korekty &nbsp;{this.ikonaSort('nr_korekty')}</>},
                headerStyle: {cursor: 'pointer'},
                onSort: this.onSortChange,
                sort: true
            }, {
                dataField: 'dataWpr',
                text: 'Data zgłoszenia',
                align: 'center',
                headerFormatter: () => {return <>Data zgłoszenia &nbsp;{this.ikonaSort('3')}</>},
                headerStyle: {cursor: 'pointer'},
                onSort: this.onSortChange,
                sort: true
            }, {
                dataField: 'dataWys',
                text: 'Data wystawienia FK',
                align: 'center',
                headerFormatter: () => {return <>Data wystawienia FK &nbsp;{this.ikonaSort('data_wys')}</>},
                headerStyle: {cursor: 'pointer'},
                onSort: this.onSortChange,
                sort: true
            }, {
                dataField: 'powodRekl',
                text: 'Powód reklamacji',
                align: 'center',
                headerFormatter: () => {return <>Powód reklamacji &nbsp;{this.ikonaSort('grupa')}</>},
                headerStyle: {cursor: 'pointer'},
                onSort: this.onSortChange,
                sort: true
            }, {
                dataField: 'realizacja',
                text: 'Realizacja',
                // formatter: this.formatMoneyRow
                headerFormatter: () => {return <>Realizacja &nbsp;{this.ikonaSort('realizacja')}</>},
                headerStyle: {cursor: 'pointer'},
                onSort: this.onSortChange,
                sort: true
            }, {
                dataField: 'statusOpis',
                text: 'Status',
                headerFormatter: () => {return <>Status &nbsp;{this.ikonaSort('status')}</>},
                headerStyle: {cursor: 'pointer'},
                onSort: this.onSortChange,
                sort: true
            }, {
                dataField: 'reklamacja',
                text: 'Exp',
                align: 'center',
                formatExtraData: this.state.isLoadingWydruk,
                formatter: this.formatReklDruk
            }];

        return (
            <>
                <div id={'ReklamacjeTable'}>

                    <TopLoading isLoading={isRequestLoading} isError={isRequestError}>
                        <Form>
                        <Row>
                            <Col xs={12} sm={12} md={6} lg={4} xl={3} style={{padding: '0'}}>
                                <FormGroup>
                                    <Row>
                                        <Col xs={5} sm={5} md={5} lg={5} xl={5} id={'status'} style={{padding: '0'}}>
                                            <Form.Label>Status:</Form.Label>
                                            <Select
                                                options={statusOptions}
                                                value={status}
                                                onChange={this.onChangeStatus}
                                                isClearable={false}
                                                menuPosition={"fixed"}
                                                styles={SmallerSelectStyles}
                                                placeholder={'Status...'}
                                                isSearchable={!isMobileDevice()}
                                            />
                                        </Col>
                                        <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                                            {/*<FormGroup>*/}
                                                <Form.Label>Numer reklamacji:</Form.Label>
                                                <FormControl size={'sm'} maxLength={10} value={this.state.numer==0? '' : this.state.numer} onChange={(e: any) => this.setState({numer: e.target.value})} onKeyPress={this.handleKeyPress.bind(this)}/>
                                            {/*</FormGroup>*/}
                                        </Col>
                                    </Row>
                                </FormGroup>
                            </Col>

                            <Col xs={6} sm={7} md={3} lg={2} xl={9} style={{padding: '0'}}>
                                <FormGroup>
                                    <AddFormMargin hideForMobileDevices575={true} />
                                    <Button type={'button'} variant={'primary'} size={'sm'} onClick={() => {this.setState({loading: true}, () => this.pobierzReklamacje())}}>
                                        <IconFilter/> Filtruj
                                    </Button>


                                {
                                    (
                                        (Boolean(this.state.numer) || this.state.status.value != '')
                                    ) &&
                                    <Button type={'button'} variant={'light'} size={'sm'} className={'text-danger'} style={{marginLeft: '5px'}}
                                            onClick={() => {
                                                this.setState({
                                                    numer: 0,
                                                    status: {value: '', label: 'Wszystkie'},
                                                    loading: true
                                                }, () => this.pobierzReklamacje())
                                            }}
                                            title={'Usuń wszystkie zastosowane filtry'}
                                            // disabled={isButtonDisabled}
                                    >
                                        <IconTrash/>
                                        {
                                            isMobileDevice() &&
                                            ' Wyczyść'
                                        }
                                    </Button>
                                }
                            </FormGroup>
                            </Col>
                        </Row>
                        </Form>
                        <BootstrapTable remote keyField='nrReklamacji' data={ reklamacje } columns={ columns } hover noDataIndication={'Brak pasujących wyników'}
                                        expandRow={this.expandRow}
                                        loading={this.state.loading}
                                        overlay={ overlayFactory({ spinner: true, styles: { overlay: (base: any) => ({...base, background: 'rgba(96,93,141,0.3)'}) } }) }
                                        pagination={(paginationFactory({
                                            page: (pageableObject.currentPage) + 1,
                                            sizePerPage: pageableObject.currentSize,
                                            totalSize: pageableObject.totalElements,
                                            sizePerPageList: [10, 12, 20, 50, 100],
                                            onSizePerPageChange: this.onChangeSizeInTable,
                                            onPageChange: this.onChangePageInTable
                                        }))}
                        />
                    </TopLoading>

                </div>
            </>
        );
    }
}

export default ReklamacjeTable;
