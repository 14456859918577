import React, {FormEvent, FormEventHandler} from 'react';
import {Button, Form, FormControl, FormGroup} from "react-bootstrap";
import {IconExternalLink, IconUserPlus, IconUserSignIn} from "../../sharedComponents/icons/FontAwesome";
import {Link} from "react-router-dom";
import {APP_UPDATE, APP_VERSION} from "../../ApplicationConfig";
import {
    displayNotificationError,
    displayNotificationSuccess,
    displayNotificationWarning
} from "../../utilities/notifications/NotificationsManager";
import LoginService from "../../services/LoginService";
import ApplicationGlobalSettingsService from "../../services/ApplicationGlobalSettingsService";
import axios from 'axios';
import MiddleCenterCardWrapper from "../../sharedComponents/wrappers/MiddleCenterCardWrapper";
import {promisify} from "util";
import UstawieniaService from "../../services/UstawieniaService";
import feue from './../../resources/images/loga_unij_proj.png';
import {encode} from "js-base64";
import moment from "moment";

class LoginModule extends React.Component<any> {

    state = {
        login: '',
        password: '',
        showPassword: false,
        pamietajHaslo: true,
        contrast: ''
    };

    onChangeInput = (key: string, value: string) => {
        this.setState({[key]: value});
    };

    componentDidMount() {
        if(!LoginService.checkIfUserIsLogged())
        {
            delete axios.defaults.headers.common["Authorization"];
            delete axios.defaults.headers.common["kontrahent"];
            delete axios.defaults.headers.common["magazyn"];
            LoginService.removeUserFromStorage();
            LoginService.removeUserFromSession();
        }

        this.setState({contrast: ((localStorage.getItem('FAMIX_CZYKONTRAST')) !== null)? ((localStorage.getItem('FAMIX_CZYKONTRAST') === 'contrast')? 'contrast' : '') : ''}, () =>
        {
            if(this.state.contrast === 'contrast'){
                document.body.style.background = 'black';
            } else {
                document.body.style.background = '';
            }
        })
    }

    onClickLogin = () => {
        if (Boolean(this.state.login) && Boolean(this.state.password)) {
            LoginService.logUser(this.state.login, this.state.password)
                .then((response) => {
                    if (response && response.status < 300) {
                        displayNotificationSuccess('Pomyślnie zalogowano', '');
                        let token = 'Basic ' + encode(this.state.login + ':' + this.state.password)
                        let user = Object.assign(response.data,{token});
                        // let k = btoa(String(user.kontrahent))

                        axios.defaults.headers.common.Authorization = token;
                        axios.defaults.headers.common.kontrahent = encode(user.kontrahent);
                        console.log(user)

                        if(this.state.pamietajHaslo){
                            LoginService.setUserInStorage(user);
                        } else {
                            LoginService.setUserInSesion(user);
                        }

                        console.log(this.props)

                        setTimeout(() => {

                            UstawieniaService.getUstawienia()
                                .then((response: any) => {

                                    if(!ApplicationGlobalSettingsService.getGlobalSettingsConfiguration('sort'))
                                    {
                                        //alert('nie ma')
                                        ApplicationGlobalSettingsService.setLocalStorageConfigurationObject(Object.assign({}, response.data, {"sort":[]}));
                                        // this.props.history.push( '/');
                                        // this.props.history.goBack();
                                        if(this.props.active)
                                            this.props.history.push(this.props.history.location.pathname);
                                        else
                                            this.props.history.push( '/');
                                    }
                                    else
                                    {
                                        ApplicationGlobalSettingsService.setLocalStorageConfigurationObject(Object.assign({}, response.data, {"sort":ApplicationGlobalSettingsService.getGlobalSettingsConfiguration('sort')}));
                                        // this.props.history.push( '/');
                                        // this.props.history.goBack();
                                        if(this.props.active)
                                            this.props.history.push(this.props.history.location.pathname);
                                        else
                                            this.props.history.push( '/');
                                    }

                                })
                                .catch((error: any) => {
                                    // this.props.history.push( '/');
                                    // this.props.history.goBack();
                                    if(this.props.active)
                                        this.props.history.push(this.props.history.location.pathname);
                                    else
                                        this.props.history.push( '/');
                                });

                            // ApplicationGlobalSettingsService.setGlobalSettingsConfiguration();
                            // this.props.history.push( '/');


                        }, 2000)
                    }
                    else {
                        displayNotificationError('Błąd logowania', '');
                    }
                }).catch((error) => {
                displayNotificationError('Błąd logowania', error.response.data.message);
                //alert(error.response.data.message);
                //console.log(JSON.stringify(error))
                // console.log('e')
            })

        } else {
            displayNotificationWarning('Niepoprawne uzupełnienie formularza', "Proszę uzupełnić wszystkie pola!");
        }
    };

    _handleKeyPress = (e: any) => {
        if (e.key === 'Enter') {
            this.onClickLogin();
        }
    };

    setStyleContrast = () => {
        let {contrast} = this.state;

        if(contrast === 'contrast')
        {
            localStorage.setItem('FAMIX_CZYKONTRAST', '');
            this.setState({contrast: ''});
            document.body.style.background = '';
        } else {
            localStorage.setItem('FAMIX_CZYKONTRAST', 'contrast');
            this.setState({contrast: 'contrast'});
            document.body.style.background = 'black';
        }

    }

    render() {
        let {pamietajHaslo, contrast, showPassword} = this.state;

        return (
            <div className={'loginBox ' + contrast}>
                <div id={'LOGIN_MODULE'}>
                    <MiddleCenterCardWrapper>
                        <Form onKeyPress={this._handleKeyPress}>
                            <FormGroup>
                                <Form.Label>Login:</Form.Label>
                                <FormControl
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeInput('login', e.target.value)}
                                    autoFocus
                                />
                            </FormGroup>
                            <FormGroup style={{marginBottom: '4px'}}>
                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                    <Form.Label>Hasło:</Form.Label>
                                    <i className={(showPassword)? "fa fa-eye" : "fa fa-eye-slash"} id="togglePassword" style={{color: '#ffffffb3', cursor: 'pointer'}} onClick={() => this.setState({showPassword: !showPassword})} title={(showPassword)? 'Ukryj hasło' : 'Pokaż hasło'}/>
                                </div>
                                <FormControl type={(showPassword)? "text" : "password"}
                                             onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeInput('password', e.target.value)}/>
                            </FormGroup>
                            <Form.Check inline={true} type="checkbox" label="Zapamiętaj sesje"
                                        className={'text-black-50'} checked={pamietajHaslo}
                                        id={'pamietajHaslo'}
                                        onClick={()=>{this.setState((prevState: any)=>({pamietajHaslo: !prevState.pamietajHaslo}))}}
                            />
                            <FormGroup>
                                <Button variant={'primary'}
                                        block={true}
                                        onClick={this.onClickLogin}
                                >
                                    <IconUserSignIn/> Zaloguj się
                                </Button>
                            </FormGroup>
                            <FormGroup>
                                <div id={'forget-password-link'}>
                                    <Link to={'/reset-hasla'}>Zapomniałem hasła</Link>
                                </div>
                            </FormGroup>
                            {/*<FormGroup>*/}
                            {/*    <Button variant={'warning'} block={true} disabled={true}>*/}
                            {/*        <IconUserPlus/> Zarejestruj się*/}
                            {/*    </Button>*/}
                            {/*</FormGroup>*/}
                        </Form>
                        <div id={'app-version'}>
                            <span className={'realizacjaLogin'} style={{display: 'block'}}>Realizacja: <a href="http://topsa.com.pl/" target="_blank">TOP S.A.</a>  - oprogramowanie: <a href={"https://topsa.com.pl/produkty/top-ehurt"} target="_blank"> Top eHurt</a></span>
                        </div>



                    </MiddleCenterCardWrapper>
                    <div id={'katalog-link-container'}>
                        <div id={'ud'}><a href="#" style={{color: '#999a99', fontSize: '1.25rem', paddingRight: 5}}
                                          onClick={() => document.body.style.fontSize = '1.5rem'}>A</a>
                            <a href="#" style={{color: '#999a99', fontSize: '1rem', paddingRight: 5}}
                               onClick={() => document.body.style.fontSize = '1.25rem'}>A</a>
                            <a href="#" style={{color: '#999a99', fontSize: '0.75rem', paddingRight: 10}}
                               onClick={() => document.body.style.fontSize = '1rem'}>A</a>
                            <i className="fa fa-adjust" onClick={() => this.setStyleContrast()}
                               style={{color: '#999a99', paddingRight: 5, cursor: 'pointer'}}/>
                        </div>

                        <a href={'https://www.famix.com.pl/e-katalog#ecatalog'} target="_blank"><IconExternalLink/> Przejdź do katalogu</a>
                    </div>
                </div>

                {/*{(moment().isAfter(moment(JSON.parse(this.props.appData).serwis_start_time, 'YYYY.MM.DD HH:mm').subtract(5, 'days')) && moment().isBefore(moment(JSON.parse(this.props.appData).serwis_end_time, 'YYYY.MM.DD HH:mm'))) &&*/}
                {/*<div className={'infoPrzerwaTechniczna'}>*/}
                {/*    <label>W dniu {moment(JSON.parse(this.props.appData).serwis_start_time).format('DD.MM.YYYY')} w godzinach {moment(JSON.parse(this.props.appData).serwis_start_time).format('HH:mm')}-{moment(JSON.parse(this.props.appData).serwis_end_time).format('HH:mm')}, z powodu prac konserwacyjnych*/}
                {/*        nastąpi przerwa w dostępie do platformy zakupowej B2B e.famix.com.pl. <br/><br/>Przepraszamy*/}
                {/*        za utrudnienia.</label>*/}
                {/*</div>*/}
                {/*}*/}

                {(moment().isAfter(moment(JSON.parse(this.props.appData).serwis_start_time, 'YYYY.MM.DD HH:mm').subtract(5, 'days')) && moment().isBefore(moment(JSON.parse(this.props.appData).serwis_end_time, 'YYYY.MM.DD HH:mm'))) &&
                <div className={'infoPrzerwaTechniczna'}>
                    {(moment(JSON.parse(this.props.appData).serwis_start_time).format('DD.MM.YYYY') === moment(JSON.parse(this.props.appData).serwis_end_time).format('DD.MM.YYYY'))?
                        <label>W dniu {moment(JSON.parse(this.props.appData).serwis_start_time).format('DD.MM.YYYY')} od godziny {moment(JSON.parse(this.props.appData).serwis_start_time).format('HH:mm')} do godziny {moment(JSON.parse(this.props.appData).serwis_end_time).format('HH:mm')}, z powodu prac konserwacyjnych
                            nastąpi przerwa w dostępie do platformy zakupowej B2B e.famix.com.pl. <br/><br/>Przepraszamy
                            za utrudnienia.</label>
                        :
                        <label>W dniach od {moment(JSON.parse(this.props.appData).serwis_start_time).format('DD.MM.YYYY')} od godziny {moment(JSON.parse(this.props.appData).serwis_start_time).format('HH:mm')} do {moment(JSON.parse(this.props.appData).serwis_end_time).format('DD.MM.YYYY')} do godziny {moment(JSON.parse(this.props.appData).serwis_end_time).format('HH:mm')}, z powodu prac konserwacyjnych
                            nastąpi przerwa w dostępie do platformy zakupowej B2B e.famix.com.pl. <br/><br/>Przepraszamy
                            za utrudnienia.</label>
                    }

                </div>
                }

                <div style={{textAlign: "center", marginBottom: '15px'}}>
                    <img src={feue} style={{width: '100%', maxWidth: '800px'}} alt={'Fundusze Europejskie'}/>
                </div>

                <div className="footer-subsides-content">
                    <p>
                        Projekt dofinansowany z Funduszy Europejskich pt. „Wdrożenie rekomendacji strategii wzorniczej celem poprawy konkurencyjności firmy FAMIX Sp. jawna”.
                    </p>
                </div>

                <span className={'version'}>VER: {APP_VERSION()} {APP_UPDATE()}</span>

            </div>
        );
    }
}

export default LoginModule;
