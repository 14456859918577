import React from 'react';
import TopCard from "../../../sharedComponents/TopCard";
import avatar from '../../../resources/images/useravatar.png';
import {IconEnvelope, IconPhone} from "../../../sharedComponents/icons/FontAwesome";
import LoginService from "../../../services/LoginService";

const InformacjeOpiekunComponent = () => {
    return (
        <div id={'InformacjeOpiekunComponent'}>
            <TopCard heading={'Opiekun'} hasPadding={true}>
                <div className={'box-inner'}>
                    <img src={avatar} alt={'default avatar'}/>
                    <div className={'text-box-info'}>
                        <h6>{(LoginService.getCurrentUser() && (LoginService.getCurrentUser().opiekun ? LoginService.getCurrentUser().opiekun : 'Brak wskazanego opiekuna'))}</h6>
                        <h6 className={'heading-label'}>Opiekun handlowy</h6>
                        <h6>
                            <IconPhone/><span>{(LoginService.getCurrentUser() && (LoginService.getCurrentUser().opiekunTelefon ? LoginService.getCurrentUser().opiekunTelefon : 'Brak numeru telefonu'))}</span>
                        </h6>
                        <h6>
                            <IconEnvelope/><span>{(LoginService.getCurrentUser() && (LoginService.getCurrentUser().opiekunEmail ? LoginService.getCurrentUser().opiekunEmail : 'Brak adresu email'))}</span>
                        </h6>
                    </div>
                </div>
            </TopCard>
        </div>
    );
};

export default InformacjeOpiekunComponent;