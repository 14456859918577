import React from 'react';
import LayoutComponent from "../../sharedComponents/layout/LayoutComponent";
import FakturyFilterbox from "./components/FakturyFilterbox";
import FakturyTable from "./components/FakturyTable";
import {isMobileDevice} from "../../utilities/rwd/rwdUtilities";
import PopupWrapper from "../../sharedComponents/popups/PopupWrapper";
import FakturaReklamacjaContainer from "./components/reklamacjaComponents/FakturaReklamacjaContainer";
import FakturyPozycjePopup from "./components/FakturyPozycjePopup";
import FakturyService from "../../services/FakturyService";
import TopLoading from "../../sharedComponents/loader/TopLoading";
import {_omit} from "../../utilities/lodash/lodashUtilities";
import {getFirstDayOfCurrentMonthAndYear} from "../../utilities/calendar_date/calendar_dateUtilities";
import WydrukiService from "../../services/WydrukiService";
import Shared_WydrukOrExportContainer from "../../sharedComponents/_reusable/wydrukOrExport/Shared_WydrukOrExportContainer";
import ReklamacjeTable from "./components/reklamacjaComponents/ReklamacjeTable";

interface MatchParams {
    path: string;
}

export interface RouteComponentProps<MatchParams> {
    match: any;
}

interface Props extends RouteComponentProps<MatchParams> {
}

interface State {
    faktury: any,

    isRequestLoading: boolean,
    isRequestError: boolean,

    dataWystawieniaFaktury: string,

    page: number,
    size: number,
    pageableObject: any,
    hasMoreForGrid: boolean,
    canLoadMore: boolean,
    sort: object[],
    loading: boolean,

    currentView: boolean,
    currentFaktura: any,

    showPozycje: boolean,
    showReklamacja: boolean,
    showReklamacjeWyslane: boolean,
    showWydruki: boolean,

    currentPozycje: any,
    isLoadingPozycje: boolean,
    isErrorPozycje: boolean,

    mozliwosciWydrukow: any,
    reklamacje: any,

    maleOkno: boolean
}

class FakturyModule extends React.Component<Props, State> {


    constructor(props: Props) {
        super(props);

        let tableSize: any = (Boolean(localStorage.getItem('FAMIX_CURRENT_TABLESIZE_FAKTURY'))) ? localStorage.getItem('FAMIX_CURRENT_TABLESIZE_FAKTURY') : 10

        this.state = {
            faktury: [],

            isRequestLoading: false,
            isRequestError: false,

            dataWystawieniaFaktury: "",

            page: 0,
            size: tableSize !== undefined ? parseInt(tableSize) : 12,
            pageableObject: {
                currentPage: 0,
                currentSize: 0,
                totalElements: 0
            },
            hasMoreForGrid: true,
            canLoadMore: true,
            sort: [{kierunek: "desc", kolumna: "numer"}],
            loading: false,

            currentView: true, // table - true, grid - false
            currentFaktura: null,

            showPozycje: false,
            showReklamacja: false,
            showReklamacjeWyslane: false,
            showWydruki: false,

            currentPozycje: [],
            isLoadingPozycje: false,
            isErrorPozycje: false,

            mozliwosciWydrukow:[],
            reklamacje: [],

            maleOkno: true
        };
    }


    componentDidMount() {

        if(isMobileDevice())
            window.scrollTo(0,0);

        this.getMozliwosciWydrukowDlaFaktur();

        this.setState({
            currentView: true, //isMobileDevice() ? false : ApplicationGlobalSettingsService.getGlobalSettingsModuleView('Faktur'),
            isRequestLoading: true,
            dataWystawieniaFaktury: getFirstDayOfCurrentMonthAndYear(),
        }, () => this.pobierzFaktury());
    }

    generateSortString = (): string => {
        let sortowanie = this.state.sort;
        let sortString = '';

        for (let i=0; sortowanie.length > i; i++)
        {
            // @ts-ignore
            sortString = sortString + '&sort=' + sortowanie[i].kolumna + ',' + sortowanie[i].kierunek;
        }

        return sortString;

    }

    /* get functions */

    pobierzFaktury = (page = this.state.page, size = this.state.size, dataWystawieniaFaktury = this.state.dataWystawieniaFaktury, sort = this.state.sort) => {

        this.setState({
            // isRequestLoading: true,
            isRequestError: false,
        });

        FakturyService.getFaktury(page, size, dataWystawieniaFaktury, this.generateSortString())
            .then((response: any) => {
                if (response && response.status < 300) {

                    this.setState({
                        isRequestLoading: false,
                        isRequestError: false,
                        loading: false,
                        faktury: response.data.content,
                        pageableObject: Object.assign({}, _omit(response.data, "content"), {currentPage: page}, {currentSize: size}),
                        hasMoreForGrid: !(response.data.totalElements === response.data.content.length),
                        canLoadMore: true
                    });

                } else {
                    this.setState({
                        isRequestLoading: false,
                        isRequestError: true,
                        loading: false,
                        hasMoreForGrid: false,
                        canLoadMore: false
                    });
                }
            })
            .catch((error: any) => {
                this.setState({
                    isRequestLoading: false,
                    isRequestError: true,
                    loading: false,
                    hasMoreForGrid: false,
                    canLoadMore: false
                });
            })
    };

    getPozycjeFaktury = (faktura: any) => {
        // do popupów pozycje faktury oraz reklamacja

        let data = {
            data: faktura.dataWys,
            numer: faktura.numer,
            symbol: faktura.symbol
        }

        FakturyService.getPozycjeFakturyNew(data).then((response) => {
            this.setState({
                currentPozycje: response.data,
                isLoadingPozycje: false,
                isErrorPozycje: false,
            });
        }).catch((e) => {
            this.setState({
                currentPozycje: [],
                isLoadingPozycje: false,
                isErrorPozycje: true,
            });
        })

    };

    getMozliwosciWydrukowDlaFaktur = () =>{
        WydrukiService.getWydrukiFaktury()
            .then((response:any)=>{
                if(response && response.status<300){
                    this.setState({
                        mozliwosciWydrukow:response.data
                    }, () => this.state.mozliwosciWydrukow.push({recno: 3, formaty: "csv;", opisFormatu: "Zamówienie CSV", faktCzyZamow: "", repFormat: "", urlFormat: "csv"}) );
                }
            })
    };

    /* table functions  */

    onChangePageInTable = (page: number, size: number) => {
        this.setState({
            loading: true,
            page: page - 1,
            size: size
        }, () => this.pobierzFaktury())
    };

    onChangeSizeInTable = (size: number) => {
        localStorage.setItem('FAMIX_CURRENT_TABLESIZE_FAKTURY', String(size))
        this.setState({
            loading: true,
            size
        }, () => this.pobierzFaktury())
    };

    /* other functions */

    onClickFiltruj = (data: string) => {
        this.setState({
            dataWystawieniaFaktury: data,
            loading: true,
            isRequestLoading: true,
            page: 0,
            faktury: []
        }, () => this.pobierzFaktury())
    };

    onClickPrzygotujReklamacje = (faktura: any) => {
        this.setState({
            currentFaktura: faktura,
            showReklamacja: true,
            isLoadingPozycje: true,
            isErrorPozycje: false,
        }, () => this.getPozycjeFaktury(faktura));
    };

    onClickPokazPozycje = (faktura: any) => {
        this.setState({
            currentFaktura: faktura,
            showPozycje: true,
            isLoadingPozycje: true,
            isErrorPozycje: false,
        }, () => this.getPozycjeFaktury(faktura));
    };

    onClickPokazMenuWydrukow = (faktura:any) =>{
        this.setState({
            currentFaktura: faktura,
            showWydruki: true,
        });
    };

    onClickZamknijPozycje = () => {
        this.setState({
            currentFaktura: null,
            showPozycje: false,
            showReklamacja: false,
            showWydruki: false,
            currentPozycje: [],
            maleOkno: true
        });
    };

    onChangeSortowanie = (sort: any) => {

        this.setState({sort: sort, page: 0, loading: true}, () => this.pobierzFaktury());

    };

    onClickReklWys = (page = this.state.page, size = this.state.size) => {
        this.setState({showReklamacjeWyslane: true});
    }

    columns = [{
        dataField: 'nazwa',
        text: 'Nazwa'
    }, {
        dataField: 'adres1',
        text: 'Adres',
        formatter: (cell: any, row: any) => (
            cell + ' ' + row.adres2
        )
    }];

    render() {

        const {mozliwosciWydrukow, faktury, showReklamacjeWyslane, currentFaktura, showPozycje, showReklamacja,showWydruki, isRequestError, isRequestLoading, pageableObject, loading} = this.state;

        const breadcrumbs = [{name: 'Faktury', url: '/faktury'}]

        return (
            <LayoutComponent breadcrumbs={breadcrumbs}
                             handleChangeSiec={()=>{
                                 this.setState({loading: true, isRequestLoading: true});
                                 this.pobierzFaktury()
                             }}
            >
                <div id={'FAKTURY_MODULE'}>
                    <FakturyFilterbox handleClickFiltruj={this.onClickFiltruj} onClickReklamacjeWys={this.onClickReklWys}/>

                    <TopLoading isLoading={isRequestLoading} isError={isRequestError}>
                        <FakturyTable data={faktury}
                                      handleClickPrzygotujReklamacje={this.onClickPrzygotujReklamacje}
                                      handleClickPokazMenuWydrukow={this.onClickPokazMenuWydrukow}
                                      pageableObject={pageableObject}
                                      handleChangePage={this.onChangePageInTable}
                                      handleChangeSizePerPage={this.onChangeSizeInTable}
                                      handleChangeSortowanie={this.onChangeSortowanie}
                                      sortProps={this.state.sort}
                                      loading={loading}
                        />
                    </TopLoading>

                </div>
                {
                    (Boolean(currentFaktura) && showPozycje) &&
                    //@ts-ignore
                    <PopupWrapper header={`Pozycje faktury ${currentFaktura.symbol}/${currentFaktura.numer}`} onClose={this.onClickZamknijPozycje}>
                        <TopLoading isLoading={this.state.isLoadingPozycje} isError={this.state.isErrorPozycje}>
                            <FakturyPozycjePopup pozycje={this.state.currentPozycje}/>
                        </TopLoading>
                    </PopupWrapper>
                }
                {
                    (Boolean(currentFaktura) && showReklamacja) &&
                    // @ts-ignore
                        <div className={'reklamacjaPopUp'}>
                            <PopupWrapper header={`Reklamacja faktury ${currentFaktura.numerFaktury}`} onClose={this.onClickZamknijPozycje} classname={'POPUP_WRAPPER_OLD'} maleOkno={this.state.maleOkno}>
                                <TopLoading isLoading={this.state.isLoadingPozycje} isError={this.state.isErrorPozycje}>
                                    <FakturaReklamacjaContainer faktura={currentFaktura}
                                                                pozycje={this.state.currentPozycje}
                                                                handleClickAnuluj={this.onClickZamknijPozycje}
                                                                closeMaleOkno={() => this.setState({maleOkno: false})}
                                    />
                                </TopLoading>
                            </PopupWrapper>
                        </div>

                }
                {
                    (showReklamacjeWyslane) &&
                    // @ts-ignore
                    <PopupWrapper header={`Reklamacje`} onClose={() => this.setState({showReklamacjeWyslane: false})} classname={'POPUP_WRAPPER_OLD'}>
                        <ReklamacjeTable/>
                    </PopupWrapper>
                }
                {
                    (Boolean(currentFaktura) && showWydruki) &&
                    // @ts-ignore
                    <PopupWrapper header={`Menu wydruków faktury ${currentFaktura.symbol}/${currentFaktura.numer}`} onClose={this.onClickZamknijPozycje} withoutOverflowY={true}>
                           <Shared_WydrukOrExportContainer typy={mozliwosciWydrukow} faktura={currentFaktura} handleClickZamknij={this.onClickZamknijPozycje} />
                    </PopupWrapper>
                }
            </LayoutComponent>
        );
    }
}

export default FakturyModule;
