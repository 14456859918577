export const NormalSelectStyles = {
    control: (provided: any, state: any) => ({
        ...provided,
        borderColor: state.isFocused ? "#4f4d83 !important" : "hsl(0,0%,80%)",
        boxShadow: state.isFocused ? "0 0 0 2px rgba(77, 75, 115, 0.3)" : "none",
    }),
    menu: (provided: any) => ({
        ...provided,
        zIndex: 1001
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#4f4d83' : state.isFocused ? 'rgba(77, 75, 115, 0.2)' : state.selectProps.color,
        // backgroundColor: state.isSelected ? '#e9aa01' : state.isFocused ? "rgba(233, 170, 1,0.3)" : state.selectProps.color,
        // textShadow:state.isSelected ? "1px 1px 1px #bf8901" : "none",
        // fontWeight:state.isSelected ? "500" : "inherit",
    }),
};

export const SmallerSelectStyles = {
    control: (provided: any, state: any) => ({
        ...provided,
        minHeight: '1px',
        //     height: '30px',
        borderColor: state.isFocused ? "#4f4d83 !important" : "hsl(0,0%,80%)",
        boxShadow: state.isFocused ? "0 0 0 2px rgba(77, 75, 115, 0.3)" : "none",
    }),
    input: (provided: any) => ({
        ...provided,
        minHeight: '1px',
    }),
    dropdownIndicator: (provided: any) => ({
        ...provided,
        minHeight: '1px',
        paddingTop: '0',
        paddingBottom: '0',
    }),
    indicatorSeparator: (provided: any) => ({
        ...provided,
        minHeight: '15px',
        //   height: '15px',
    }),
    clearIndicator: (provided: any) => ({
        ...provided,
        minHeight: '1px',
        paddingTop: '0',
        paddingBottom: '0',
    }),
    valueContainer: (provided: any) => ({
        ...provided,
        minHeight: '1px',
        //    height: '30px',
        paddingTop: '0',
        paddingBottom: '0',
        fontSize: "0.8rem",
        lineHeight: "1rem"
    }),
    singleValue: (provided: any) => ({
        ...provided,
        minHeight: '1px',
        // paddingBottom: '2px',
        //  top:'45%'
    }),
    menu: (provided: any) => ({
        ...provided,
        zIndex: 1001
    }),
    menuList: (provided: any) => ({
        ...provided,
        fontSize: "0.8rem",
        lineHeight: "1rem"
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#4f4d83' : state.isFocused ? 'rgba(77, 75, 115, 0.2)' : state.selectProps.color,
        // backgroundColor: state.isSelected ? '#e9aa01' : state.isFocused ? "rgba(233, 170, 1,0.3)" : state.selectProps.color,
        // textShadow:state.isSelected ? "1px 1px 1px #bf8901" : "none",
        // fontWeight:state.isSelected ? "500" : "inherit",
    }),
};

export const HeaderSmallerSelectStyles = {
    control: (provided: any, state: any) => ({
        ...provided,
        minHeight: '1px',
        //     height: '30px',
        borderColor: state.isFocused ? "#e9aa01 !important" : "hsl(0,0%,80%)",
        boxShadow: state.isFocused ? "0 0 0 2px rgba(233, 170, 1, 0.3)" : "none",
    }),
    input: (provided: any) => ({
        ...provided,
        minHeight: '1px',
    }),
    dropdownIndicator: (provided: any) => ({
        ...provided,
        minHeight: '1px',
        paddingTop: '0',
        paddingBottom: '0',
    }),
    indicatorSeparator: (provided: any) => ({
        ...provided,
        minHeight: '12px',
        //   height: '15px',
    }),
    clearIndicator: (provided: any) => ({
        ...provided,
        minHeight: '1px',
        paddingTop: '0',
        paddingBottom: '0',
    }),
    valueContainer: (provided: any) => ({
        ...provided,
        minHeight: '1px',
        //    height: '30px',
        paddingTop: '0',
        paddingBottom: '0',
        fontSize: "0.65rem",
    }),
    singleValue: (provided: any) => ({
        ...provided,
        minHeight: '1px',
        // paddingBottom: '2px',
        //  top:'45%'
    }),
    menu: (provided: any) => ({
        ...provided,
        zIndex: 1001
    }),
    menuList: (provided: any) => ({
        ...provided,
        fontSize: "0.65rem",
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#e9aa01' : state.isFocused ? 'rgba(233, 170, 1, 0.2)' : state.selectProps.color,
        // backgroundColor: state.isSelected ? '#e9aa01' : state.isFocused ? "rgba(233, 170, 1,0.3)" : state.selectProps.color,
        // textShadow:state.isSelected ? "1px 1px 1px #bf8901" : "none",
        // fontWeight:state.isSelected ? "500" : "inherit",
    }),
};