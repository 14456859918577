import React from 'react';
import LayoutComponent from "../../sharedComponents/layout/LayoutComponent";
import RozrachunkiFilterbox from "./components/RozrachunkiFilterbox";
import RozrachunkiTable from "./components/RozrachunkiTable";
import {isMobileDevice} from "../../utilities/rwd/rwdUtilities";
import TopLoading from "../../sharedComponents/loader/TopLoading";
import RozrachunkiService from "../../services/RozrachunkiService";
import {_omit} from "../../utilities/lodash/lodashUtilities";
import RachunkiNaleznosciBox from "./components/RachunkiNaleznosciBox";

interface MatchParams {
    path: string;
}

export interface RouteComponentProps<MatchParams> {
    match: any;
}

interface Props extends RouteComponentProps<MatchParams> {
}

class RozrachunkiModule extends React.Component<Props> {

    state = {
        rozrachunki: [],
        currentView: true, // table - true, grid - false

        czyTylkoPoTerminie: false,
        isRequestLoading: true,
        isRequestError: false,
        loading: false,

        page: 0,
        size: (Boolean(localStorage.getItem('FAMIX_CURRENT_TABLESIZE_ROZRACHUNKI'))) ? Number(localStorage.getItem('FAMIX_CURRENT_TABLESIZE_ROZRACHUNKI')) : 12,
        pageableObject: {
            currentPage: 0,
            currentSize: 0,
            totalElements: 0
        },
        hasMoreForGrid: true,
        canLoadMore: true,

        naleznosci: [],
        isLoadingNaleznosci: true,
        isErrorNaleznosci: false,
        sort: [{kierunek: "desc", kolumna: "numer"}],
    };

    componentDidMount() {

        if(isMobileDevice())
            window.scrollTo(0,0);

        this.setState({
            currentView: true, //isMobileDevice() ? false : ApplicationGlobalSettingsService.getGlobalSettingsModuleView('Rozrachunkow')
        });

        this.pobierzRozrachunki();
        this.pobierzNaleznosci();
    }

    generateSortString = (): string => {
        let sortowanie = this.state.sort;
        let sortString = '';

        for (let i=0; sortowanie.length > i; i++)
        {
            // @ts-ignore
            sortString = sortString + '&sort=' + sortowanie[i].kolumna + ',' + sortowanie[i].kierunek;
        }

        return sortString;

    }

    onChangeSortowanie = (sort: any) => {

        this.setState({sort: sort, page: 0, loading: true}, () => this.pobierzRozrachunki());

    };

    /* get functions */

    pobierzRozrachunki = (page = this.state.page, size = this.state.size, czyTylkoPoTerminie = this.state.czyTylkoPoTerminie) => {
        this.setState({
            // isRequestLoading: true,
            isRequestError: false,
        });

        RozrachunkiService.getRozrachunki(page, size, czyTylkoPoTerminie, this.generateSortString())
            .then((response: any) => {
                    if (response && response.status < 300) {

                        this.setState({
                            isRequestLoading: false,
                            isRequestError: false,
                            loading: false,
                            rozrachunki: response.data.content,
                            pageableObject: Object.assign({}, _omit(response.data, "content"), {currentPage: page}, {currentSize: size}),
                            hasMoreForGrid: !(response.data.totalElements === response.data.content.length),
                            canLoadMore: true
                        });

                    } else {
                        this.setState({
                            isRequestLoading: false,
                            isRequestError: true,
                            loading: false,
                            hasMoreForGrid: false,
                            canLoadMore: false
                        });
                    }
                }
            )
            .catch((error: any) => {
                this.setState({
                    isRequestLoading: false,
                    isRequestError: true,
                    loading: false,
                    hasMoreForGrid: false,
                    canLoadMore: false
                });
            })
    };

    pobierzNaleznosci = () => {
        this.setState({
            isLoadingNaleznosci: true,
            isErrorNaleznosci: false,
        });
        RozrachunkiService.getNaleznosci()
            .then((response: any) => {
                if (response && response.status < 300) {
                    this.setState({
                        isLoadingNaleznosci: false,
                        naleznosci: response.data,
                    });
                } else {
                    this.setState({
                        isLoadingNaleznosci: false,
                        isErrorNaleznosci: true,
                    });
                }
            })
            .catch((error: any) => {
                this.setState({
                    isLoadingNaleznosci: false,
                    isErrorNaleznosci: true,
                });
            })
    };


    /* table functions  */

    onChangePageInTable = (page: number, size: number) => {
        this.setState({
            page: page - 1,
            loading: true,
            size: size
        }, () => this.pobierzRozrachunki())
    };

    onChangeSizeInTable = (size: number) => {
        localStorage.setItem('FAMIX_CURRENT_TABLESIZE_ROZRACHUNKI', String(size))
        this.setState({
            loading: true,
            size
        }, () => this.pobierzRozrachunki())
    };


    /* grid functions  */


    // loadMoreElementsForGrid = (e: any) => {
    //
    //     if (this.state.canLoadMore) {
    //
    //         this.setState({canLoadMore: false, isRequestLoading: true})
    //
    //         let {rozrachunki}: any = this.state;
    //
    //         this.setState((prevState: any) => ({page: prevState.page + 1}), () => {
    //
    //             RozrachunkiService.getRozrachunki(this.state.page, 12, this.state.czyTylkoPoTerminie)
    //                 .then(response => {
    //                     if (response.status < 300) {
    //
    //                         this.setState({
    //                             isRequestLoading: false,
    //                             isRequestError: false,
    //                             pageableObject: Object.assign({}, _omit(response.data, "content"), {currentPage: this.state.page}, {currentSize: 12}),
    //                             canLoadMore: true,
    //                             hasMoreForGrid: (response.data.totalPages > this.state.page)
    //                         });
    //
    //                         response.data.content.forEach((item: any) => {
    //                             rozrachunki.push(item)
    //                         });
    //
    //                         this.setState({rozrachunki});
    //
    //                     } else {
    //                         this.setState({
    //                             canLoadMore: true,
    //                             isRequestLoading: false,
    //                             isRequestError: true,
    //                             hasMoreForGrid: false
    //                         });
    //                     }
    //                 })
    //                 .catch(error => {
    //                     this.setState({
    //                         canLoadMore: true,
    //                         isRequestLoading: false,
    //                         isRequestError: true,
    //                         hasMoreForGrid: false
    //                     });
    //                 })
    //         })
    //
    //     }
    //
    // };

    /* others */

    onClickFiltruj = (params: any) => {
        let {czyTylkoPoTerminie} = params;
        this.setState({czyTylkoPoTerminie, page: 0, loading: true}, () => this.pobierzRozrachunki());
    };

    render() {

        const breadcrumbs = [{name: 'Rozrachunki', url: '/rozrachunki'}];
        let {rozrachunki, currentView, isRequestLoading, isRequestError, pageableObject, hasMoreForGrid, czyTylkoPoTerminie, isErrorNaleznosci, isLoadingNaleznosci, naleznosci, loading} = this.state;

        return (
            <LayoutComponent breadcrumbs={breadcrumbs} handleChangeSiec={()=> {this.pobierzRozrachunki(); this.pobierzNaleznosci(); this.setState({isRequestLoading: true})}}>
                <div id={'ROZRACHUNKI_MODULE'}>
                    <RachunkiNaleznosciBox isErrorNaleznosci={isErrorNaleznosci} isLoadingNaleznosci={isLoadingNaleznosci} naleznosci={naleznosci}/>
                    {!isRequestLoading &&
                        <RozrachunkiFilterbox handleClickFiltruj={this.onClickFiltruj} czyPoTerminie={czyTylkoPoTerminie}/>
                    }

                    {/*{*/}
                    {/*    currentView ?*/}
                    {/*        <TopLoading isLoading={isRequestLoading} isError={isRequestError}>*/}
                    {/*            <RozrachunkiTable rozrachunki={rozrachunki}*/}
                    {/*                              pageableObject={pageableObject}*/}
                    {/*                              handleChangePage={this.onChangePageInTable}*/}
                    {/*                              handleChangeSizePerPage={this.onChangeSizeInTable}*/}
                    {/*            />*/}
                    {/*        </TopLoading>*/}
                    {/*        :*/}
                    {/*        <RozrachunkiGrid rozrachunki={rozrachunki} isLoading={isRequestLoading} hasMore={hasMoreForGrid} handleLoadMoreForGrid={this.loadMoreElementsForGrid}/>*/}
                    {/*}*/}

                    <TopLoading isLoading={isRequestLoading} isError={isRequestError}>
                        <RozrachunkiTable rozrachunki={rozrachunki}
                                          pageableObject={pageableObject}
                                          handleChangePage={this.onChangePageInTable}
                                          handleChangeSizePerPage={this.onChangeSizeInTable}
                                          loading={loading}
                                          sortProps={this.state.sort}
                                          handleChangeSortowanie={this.onChangeSortowanie}
                        />
                    </TopLoading>

                </div>
            </LayoutComponent>
        );
    }
}

export default RozrachunkiModule;
