import {HOST_API} from "../ApplicationConfig";
import axios from 'axios';

const HOST = HOST_API + 'ustawienia/';

/*
{
    "drzewoWofercie":true,
    "tabelaDomyslnymTypemDrzewa":true,
    "tabelaDomyslnymWidokiemOferty":false,
    "tabelaDomyslnymWidokiemPromocji":false,
    "tabelaDomyslnymWidokiemProduktowPromocji":false,
    "tabelaDomyslnymWidokiemZamowien":false,
    "tabelaDomyslnymWidokiemFaktur":false,
    "tabelaDomyslnymWidokiemRozrachunkow":true,
    "tabelaDomyslnymWidokiemKoszyka":false,
    "koszykDomyslnieWyswietlajZdjecia":false
}
 */


export default class UstawieniaService {

    static getUstawienia = () => {
        return axios.request({
            method: 'get',
            url: `${HOST}getUstawieniaAplikacji`,
        })
    };

    static setUstawienia = (pozycja: string, wartosc: boolean) => {
        return axios.request({
            method: 'post',
            url: `${HOST}zmienUstawienie`,
            data: JSON.stringify({
                "pozycja": pozycja,
                "wartosc": wartosc
            })
        })
    };

    static getAppUrl= () => {
        return axios.request({
            method: 'get',
            url: `${HOST}getUrlAppMobilnej`,
        })
    };


}
