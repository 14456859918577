import React, {Component} from 'react';
import {Tab, Tabs} from "react-bootstrap";
import PromocjaEditorForm from "./PromocjaEditorForm";
import PromocjaPreview from "./PromocjaPreview";
import {_remove} from "../../../../utilities/lodash/lodashUtilities";
import {API_getProducts} from "../../../../_static_data/response";
import PromocjaDefinicjaForm from "./PromocjaDefinicjaForm";

class PromocjaEditorContainer extends Component {

    state = {

        produkty: [],
        gazetkaAreasMap: [],
        currentStrona: 0,
        areaCounter: 0,
        savedFiles: false,
    };

    componentDidMount() {

        this.setState({
            produkty: API_getProducts()
        });
    }

    onClickZapiszDefinicja = (arr: any) => {
        this.setState({gazetkaAreasMap: arr, savedFiles: true, currentStrona: 0})
    };

    setAreaCounter = () => {
        let {areas}: any = this.state.gazetkaAreasMap[this.state.currentStrona];

        if (areas.length === 0) {
            this.setState({areaCounter: 0})
        } else {
            let last = areas[areas.length - 1];
            this.setState({areaCounter: parseInt(last.name.substring(last.name.indexOf('a-') + 2, last.name.length))})
        }
    };

    getAreaCounter = () => {
        let counter = this.state.areaCounter + 1;
        this.setState({areaCounter: counter});
        return counter;
    }

    changeCanvasShapeToCoords = (arr: any) => {
        return [
            arr[0].x, arr[0].y,
            arr[1].x, arr[0].y,
            arr[1].x, arr[1].y,
            arr[0].x, arr[1].y
        ]
    };

    onChangeCurrentPage = (type: string) => {
        if (type === '-' && this.state.currentStrona >= 0) {
            this.setState((prevState: any) => ({currentStrona: prevState.currentStrona - 1}), () => this.setAreaCounter());
        }
        if (type === '+' && this.state.currentStrona < this.state.gazetkaAreasMap.length - 1) {
            this.setState((prevState: any) => ({currentStrona: prevState.currentStrona + 1}), () => this.setAreaCounter())
        }
    };

    onSendCanvasShape = (arr: any, dimensions: any) => {

        let area = {
            //   name: 'area-' + this.getAreaCounter(),
            name: `s-${this.state.currentStrona}-a-${this.getAreaCounter()}`,
            shape: "poly",
            coords: this.changeCanvasShapeToCoords(arr),
            preFillColor: "rgba(45,136,188,0.2)",
            product: null,
            canvasArea: Array.from(arr)
        }

        let gazetkaAreasMap = this.state.gazetkaAreasMap;
        let gazetkaArea: any = gazetkaAreasMap[this.state.currentStrona];
        gazetkaArea.areas.push(area);
        gazetkaArea.imgDimensions = dimensions;
        this.setState({gazetkaAreasMap})
    };

    onChangeProductInArea = (area: object, product: any) => {
        let gazetkaAreasMap = this.state.gazetkaAreasMap;
        let gazetkaArea: any = gazetkaAreasMap[this.state.currentStrona];
        //@ts-ignore
        let a = gazetkaArea.areas.find((a: any) => a.name === area.name);
        a.product = product;
        this.setState({gazetkaAreasMap})
    };

    onDeleteArea = (area: object) => {
        let gazetkaAreasMap = this.state.gazetkaAreasMap;
        let gazetkaArea: any = gazetkaAreasMap[this.state.currentStrona];

        //@ts-ignore
        _remove(gazetkaArea.areas, "name", area.name)

        this.setState({gazetkaAreasMap})
    };

    render() {

        return (
            <div style={{marginTop: '15px'}}>
                <h5>Przykład edytora promocji-gazetki</h5>
                <p>
                    Edytor pozwala na wybranie plików gazetki * oraz oznaczenie produktów na ów gazetce. Kolejne kroki
                    to:
                    <ol>
                        <li>
                            zakładka definicja - wybór plików i ustawienie ich kolejnosci
                        </li>
                        <li>
                            zakładka edytor - oznaczenie kolejnych obszarów poprzez kliknięcie i przeciągnięcie
                        </li>
                        <li>
                            zakładka edytor - przypisanie do kolejnych obszarów produktow w select
                        </li>
                        <li>
                            powtórzyć kroki 2-3 dla wszystkich stron gazetki
                        </li>
                        <li>
                            zakładka podglad - sprawdzić poprawność danych
                        </li>
                    </ol>
                </p>
                <p>
                    Edytor w budowie
                </p>

                <Tabs defaultActiveKey="definicja" id="uncontrolled-tab-example">
                    <Tab eventKey="definicja" title="Definicja">
                        <PromocjaDefinicjaForm
                            handleClickZapisz={this.onClickZapiszDefinicja}
                        />
                    </Tab>
                    <Tab eventKey="edytor" title="Edytor">
                        <PromocjaEditorForm
                            savedFiles={this.state.savedFiles}
                            produkty={this.state.produkty}
                            gazetkaAreasMap={this.state.gazetkaAreasMap}
                            currentStrona={this.state.currentStrona}
                            handleChangeCurrentPage={this.onChangeCurrentPage}
                            handleSendCanvasShape={this.onSendCanvasShape}
                            handleChangeProductInArea={this.onChangeProductInArea}
                            handleDeleteArea={this.onDeleteArea}
                        />
                    </Tab>
                    <Tab eventKey="podglad" title="Podgląd">
                        <PromocjaPreview
                            gazetkaAreasMap={this.state.gazetkaAreasMap}
                            currentStrona={this.state.currentStrona}
                            handleChangeCurrentPage={this.onChangeCurrentPage}
                        />
                    </Tab>
                </Tabs>
            </div>
        );
    }
}

export default PromocjaEditorContainer;