import React from 'react';
import {IconGrid, IconImages, IconServer, IconTable} from "./icons/FontAwesome";

interface Props {
    currentView: boolean; // table - true or grid - false
    quantity?: number,
    handleChangeView: (currentView:boolean)=>void;
}

const TopTableGridSwitcher: React.FC<Props> = ({currentView, quantity, handleChangeView}) => {
    return (
        <div id={'TopTableGridSwitcher'}>
            {/*<span>Ilość indeksów: {quantity}</span>*/}
            <span></span>
            <span className={currentView ? 'current' : ''} onClick={() => handleChangeView(true)}>
                {/*<IconTable/>*/}
                <IconServer/>
            </span>
            <span className={!currentView ? 'current' : ''} onClick={() => handleChangeView(false)}>
                <IconGrid/>
            </span>
        </div>
    );
};

export default TopTableGridSwitcher;
