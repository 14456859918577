import React from 'react';
import LayoutComponent from "../../sharedComponents/layout/LayoutComponent";
import {Col, Row} from "react-bootstrap";
import InformacjeUzytkownikComponent from "./components/InformacjeUzytkownikComponent";
import InformacjeZamowieniaComponent from "./components/InformacjeZamowieniaComponent";
import InformacjeFakturyComponent from "./components/InformacjeFakturyComponent";
import InformacjeOpiekunComponent from "./components/InformacjeOpiekunComponent";
import {isMobileDevice} from "../../utilities/rwd/rwdUtilities";
import InformacjeKontaktComponent from './components/InformacjeKontaktComponent';
import InformacjeAplikacjaComponent from "./components/InformacjeAplikacjaComponent";

interface MatchParams {
    path: string;
}

export interface RouteComponentProps<MatchParams> {
    match: any;
}

interface Props extends RouteComponentProps<MatchParams> {
}

class InformacjeModule extends React.Component<Props> {

    componentDidMount() {
        if(isMobileDevice())

            window.scrollTo(0,0);
    }

    render() {

    const breadcrumbs = [{name:'Informacje',url:'/informacje'}]

        return (
            <LayoutComponent breadcrumbs={breadcrumbs}
                             handleChangeSiec={()=>{
                                 // this.setState({isLoading: true});
                                 this.forceUpdate()
                             }}
            >
                <div id={'INFORMACJE_MODULE'}>
                    <Row>
                        <Col lg={6} xl={6}>
                            <InformacjeUzytkownikComponent/>
                            <InformacjeZamowieniaComponent/>
                            <InformacjeOpiekunComponent/>

                        </Col>
                        <Col lg={6} xl={6}>
                            <InformacjeFakturyComponent/>
                            {/*<InformacjeOpiekunComponent/>*/}
                            <InformacjeKontaktComponent/>
                        </Col>
                        <Col lg={12} xl={12} id={'aplikacjaMobilna'}>
                            <InformacjeAplikacjaComponent/>
                        </Col>
                    </Row>
                </div>
            </LayoutComponent>
        );
    }
}

export default InformacjeModule;
