import React, {Component} from 'react';
import OfertaService from "../../../services/OfertaService";
import PromocjeService from "../../../services/PromocjeService";
import {map} from "lodash";
import {Accordion, Button, Card, Col, Row} from "react-bootstrap";
import PopupWrapper from "../../../sharedComponents/popups/PopupWrapper";
import ProductImage from "../../produkt/components/ProductImage";
import ProductDescription from "../../produkt/components/ProductDescription";
import IconPromocja from "./IconPromocja";
import TopLoading from "../../../sharedComponents/loader/TopLoading";
import {IconMultistar} from "../../../sharedComponents/icons/FontAwesome";
import Shared_ProductImage
    from "../../../sharedComponents/_reusable/ProductImageLazyLoadingWithErrorHandling/Shared_ProductImage";
import KoszykService from "../../../services/KoszykService";
import {formatMoney} from "../../../utilities/formatters/formatMoney";

interface Props {
    item: any,
    // promocje: any,
    closePopup: () => void,
    onClickPrzejdz: (idUm: any) => void
}

interface State {
    promocje: any,
    isRequestLoading: boolean,
    isRequestError: boolean
}

class PromocjePopup extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            promocje: [],
            isRequestLoading: true,
            isRequestError: false
        }
    }

    componentDidMount() {
        this.pobierzPromocje();
    }

    pobierzPromocje = () => {
        this.setState({
            isRequestLoading: true,
            isRequestError: false,
        });

        PromocjeService.getPromocjeIndeks(this.props.item.indeks).then((response) => {
            this.setState({promocje: response.data, isRequestLoading: false, isRequestError: false});
        }).catch((e) => {
            console.log('error');
            this.setState({isRequestLoading: false, isRequestError: true});
        })
    }

    getLabel = (promocja: string) => {
        switch (promocja) {
            case 'C':
                return 'Promocja cenowa';
            case 'P':
                return 'Promocja pakietowa';
            case 'L':
                return 'Promocja lojalnościowa';
            case 'D':
                return 'Promocja dla klienta';
        }
    };

    czyPokazywacButton = (promocja: string): boolean => {
        switch (promocja) {
            case 'C':
                return true;
            case 'P':
                return true;
            case 'L':
                return true;
            case 'D':
                return true;
            default:
                return false;
        }
    }

    render() {

        let {item} = this.props;
        let {promocje, isRequestLoading, isRequestError} = this.state;

        return (
            <PopupWrapper header={'Produkt występuje w wielu promocjach'} onClose={() => this.props.closePopup()}>
                <div id={'PRODUKT_MODULE'}>
                    <div className={'prodPromInfo'}>
                        <Row>
                            <Col sm={12} md={8} lg={8} xl={8} className={'d-flex align-items-center'} style={{paddingLeft: '21px', textAlign: 'left'}}>
                                <ProductDescription item={item}/>
                            </Col>
                            <Col sm={12} md={4} lg={4} xl={4} className={'my-auto'}>
                                {/*<ProductImage item={item}/>*/}
                                <div id={'ProductImage'}>
                                    <Shared_ProductImage item={item} />
                                </div>
                            </Col>
                        </Row>
                    </div>

                {/*<Row>*/}
                {/*    <Col>*/}
                {/*        <i className="fa fa-angle-double-down animacjaProm"/> <label>Promocje</label>*/}
                {/*        /!*<hr style={{margin: '0'}}/>*!/*/}
                {/*    </Col>*/}
                {/*</Row>*/}

                    <TopLoading isLoading={isRequestLoading} isError={isRequestError}>
                    {/*<div className={'promContainer'}>*/}
                    {/*    /!*<Accordion>*!/*/}
                    {/*    {*/}
                    {/*        promocje.map((prom: any, index: any) => {*/}
                    {/*            return <div className={'promCard'}>*/}
                    {/*                    <Row className={'promCardHeader'}>*/}
                    {/*                        <Col className={'header-'+prom.ozn}>{this.getLabel(prom.ozn)}</Col>*/}
                    {/*                    </Row>*/}
                    {/*                    <Row>*/}
                    {/*                        <Col className={'promNazwa'}>*/}
                    {/*                            <h6>{prom.nazwa}</h6>*/}
                    {/*                        </Col>*/}
                    {/*                    </Row>*/}

                    {/*                    <Row className={'promOpis'}>*/}
                    {/*                        Opis:<br/>*/}
                    {/*                        {prom.opis}*/}
                    {/*                    </Row>*/}

                    {/*                    <Row className={'promTermin'}>*/}
                    {/*                        Obowiązuje:<br/>*/}
                    {/*                        Od: {prom.dataOd}<br/>*/}
                    {/*                        Do: {prom.dataDo}*/}
                    {/*                    </Row>*/}

                    {/*                    <Row style={{textAlign: 'right', paddingRight: '8px'}}>*/}
                    {/*                        <h6 className={'smalId'}>ID: {prom.idUm}</h6>*/}
                    {/*                    </Row>*/}

                    {/*                {(this.czyPokazywacButton(prom.ozn)) &&*/}
                    {/*                    <div className={'promButton'}>*/}
                    {/*                        <Button href={'promocje/' + prom.idUm}>PRZEJDŹ</Button>*/}
                    {/*                    </div>*/}
                    {/*                }*/}

                    {/*            </div>*/}
                    {/*            // return <Card className={'prom prom-header-'+prom.ozn} title={this.getLabel(prom.ozn)}>*/}
                    {/*            //     <Accordion.Toggle as={Card.Header} eventKey={index}>*/}
                    {/*            //         {prom.nazwa}*/}
                    {/*            //     </Accordion.Toggle>*/}
                    {/*            //     <Accordion.Collapse eventKey={index}>*/}
                    {/*            //         <Card.Body>Data od: {prom.dataOd}<br/> Data do: {prom.dataDo}</Card.Body>*/}
                    {/*            //     </Accordion.Collapse>*/}
                    {/*            // </Card>*/}
                    {/*        })*/}
                    {/*    }*/}
                    {/*    /!*</Accordion>*!/*/}
                    {/*</div>*/}

                        <div className={'promContainer2'}>
                            {promocje.map((prom: any, index: any) => {
                                return <div className={'promCard2'} key={index}>

                                        <Row className={'promCardHeader2 header-'+prom.ozn}>
                                            <Col xl={9}>
                                                <h5 className={'align-middle'}> {this.getLabel(prom.ozn)}</h5>
                                                <h6 className={'heading-label'}>{prom.nazwa}</h6>
                                            </Col>
                                            <Col xl={3} style={{paddingRight: '5px'}}>
                                                {(this.czyPokazywacButton(prom.ozn)) &&
                                                <div className={'promButton'}>
                                                    {/*<Button href={'promocje/' + prom.idUm} size={'sm'}>PRZEJDŹ</Button>*/}
                                                    <Button onClick={() => this.props.onClickPrzejdz(prom.idUm)} size={'sm'}>PRZEJDŹ</Button>
                                                </div>
                                                }
                                            </Col>
                                        </Row>
                                        {/*<Row>*/}
                                        {/*    <Col className={'promNazwa'}>*/}
                                        {/*        <h6>{prom.nazwa}</h6>*/}
                                        {/*    </Col>*/}
                                        {/*</Row>*/}

                                        <Row className={'promOpis'}>
                                            <Col xl={3}>
                                                <h6 className={'heading-label'}>Obowiązuje:</h6>
                                                <h6><i className={'heading-label'} style={{display: 'inline-block'}}>Od:</i> {prom.dataOd} <i className={'heading-label'} style={{display: 'inline-block'}}>Do:</i> {prom.dataDo}</h6>
                                            </Col>
                                            {(!KoszykService.isEmpty(prom.cena) &&
                                            <Col xl={3}>
                                                <h6 className={'heading-label'}>Najniższa cena wynikająca z pakietu:</h6>
                                                <h6>{formatMoney(prom.cena)}</h6>
                                            </Col>
                                            )}
                                            <Col xl={6}>
                                                <h6 className={'heading-label'}>Opis:</h6>
                                                <h6>{(prom.opisWww)? prom.opisWww : '-'}</h6>
                                            </Col>
                                        </Row>

                                        {/*<Row style={{textAlign: 'right', paddingRight: '8px'}}>*/}
                                        {/*    <h6 className={'smalId'}>ID: {prom.idUm}</h6>*/}
                                        {/*</Row>*/}



                                </div>
                            })}
                        </div>

                    </TopLoading>

                </div>
            </PopupWrapper>

        );
    }
}

export default PromocjePopup;
