export const AdminNavbarLinks = [
    // {
    //     key: 1,
    //     level: 1,
    //     name: 'Home',
    //     url: '/admin/home',
    //     icon: ''
    // },
    // {
    //     key: 2,
    //     level: 1,
    //     name: 'Promocje',
    //     url: '/admin/promocje',
    //     icon: ''
    // },
    {
        key: 3,
        level: 1,
        name: 'Banery',
        url: '/admin/banery',
        icon: ''
    },
    {
        key: 4,
        level: 1,
        name: 'Klienci',
        url: '/admin/klienci',
        icon: ''
    },
    // {
    //     key: 5,
    //     level: 1,
    //     name: 'Reklamacje',
    //     url: '/admin/reklamacje',
    //     icon: ''
    // },
    {
        key: 6,
        level: 1,
        name: 'Komunikaty',
        url: '/admin/komunikaty',
        icon: ''
    },
    // {
    //     key: 7,
    //     level: 1,
    //     name: 'Konfiguracja',
    //     url: '/admin/konfiguracja',
    //     icon: ''
    // },
    // {
    //     key: 8,
    //     level: 1,
    //     name: 'Logi',
    //     url: '/admin/logi',
    //     icon: ''
    // },
];
