import {HOST_API} from "../ApplicationConfig";
import axios from 'axios';

const HOST = HOST_API + 'wydruki/';

export default class WydrukiService {

    static getWydrukiFaktury = () => {
        return axios.request({
            method: 'get',
            url: `${HOST}getWydrukiFaktury`,
        })
    };
    static getWydrukiZamowienia = () => {
        return axios.request({
            method: 'get',
            url: `${HOST}getWydrukiZamowienia`,
        })
    };

    static getWydruk = (token: any) => {
        return axios.request({
            method: 'post',
            url: `${HOST}getWydruk`,
            data: JSON.stringify({token:token}),
            responseType:"json"
        })
    };


}
