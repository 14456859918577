import React from 'react';
import {Col, Row} from "react-bootstrap";
import {IconCheck, IconMultistar, IconTimes} from "../../../sharedComponents/icons/FontAwesome";
import {Link} from "react-router-dom";
import Shared_ProductAddToBasketForm from "../../../sharedComponents/_reusable/ProductAddToBasket/Shared_ProductAddToBasketForm";
//@ts-ignore
import InfiniteScroll from 'react-infinite-scroller';
import TopLoaderComponent from "../../../sharedComponents/loader/TopLoaderComponent";
import Shared_ProductImage from "../../../sharedComponents/_reusable/ProductImageLazyLoadingWithErrorHandling/Shared_ProductImage";
import {famix_zaokraglijIloscDlaJM1} from "../../../utilities/famix_others/famix_operations";

interface Props {
    data: object[],
    isLoading?: boolean,
    hasMore?: boolean,
    handleLoadMoreForGrid?: any,
    handleChangeInBasket: (previousAmount: any, amount: any, item: any, opakowanie: string) => void;
    odswiezIloscLokalnie?: (amount: any, item: any, typOpak?: string) => void;
    openPromocjePopup: (indeks: any) => void,
    openKartaProduktuPopup: (indeks: any) => void,
}

const OfertaGrid: React.FC<Props> = ({data, isLoading, openKartaProduktuPopup, hasMore, openPromocjePopup, handleLoadMoreForGrid, handleChangeInBasket, odswiezIloscLokalnie}) => {

    return (
        <div id={'OfertaGrid'}>
            <InfiniteScroll
                initialLoad={false}
                pageStart={0}
                loadMore={handleLoadMoreForGrid}
                hasMore={hasMore}
                loader={(isLoading) ? <TopLoaderComponent/> : (data.length > 0 && hasMore) ?
                    <h3 style={{color: '#ddd', textAlign: 'center'}}>Scrolluj aby załadować wiecej wyników</h3> : ''}
                threshold={-10}
            >
                <Row style={{paddingBottom: '25px'}}>
                    {data.map((item: any) => (
                        <Col xs={12} sm={6} md={6} lg={4} xl={3}>
                            <div className={'product-container'}>
                                {/*<div className={'product-dostepnosc'}>*/}
                                {/*    {*/}
                                {/*        item.dostepne ?*/}
                                {/*            <div className={'dostepny-grid'}><IconCheck/> Produkt dostępny</div>*/}
                                {/*            :*/}
                                {/*            <div className={'niedostepny-grid'}><IconTimes/> Produkt niedostępny</div>*/}
                                {/*    }*/}
                                {/*</div>*/}
                                <div className={'product-image'}>
                                    {
                                        (Boolean(item.upustProcentowy) && item.upustProcentowy > 0) &&
                                        <div className={'product-promocja'} onClick={() => openPromocjePopup(item)}>
                                            <div className={'triangle'}></div>
                                            <div className={'inner'}>
                                                <IconMultistar/> Promocja
                                            </div>
                                        </div>
                                    }
                                    {/*<Link to={'/oferta/' + item.indeks}>*/}
                                    <div onClick={() => openKartaProduktuPopup(item)} style={{cursor: "pointer"}}>
                                        <Shared_ProductImage item={item}/>
                                    </div>

                                    {/*</Link>*/}
                                </div>
                                <div className={'product-description'}>
                                        <h3 title={item.producent + ', ' + item.nazwa} onClick={() => openKartaProduktuPopup(item)} style={{cursor: "pointer"}}>
                                            {/*<Link to={'/oferta/' + item.indeks}>*/}
                                            {/* <OverflowTextWrapper text={item.producent + ', ' + item.nazwa}
                                                             howManyLettersVisible={45}/>*/}
                                            {
                                                (item.producent + ', ' + item.nazwa).length > 45 ?
                                                    (item.producent + ', ' + item.nazwa).substr(0,42)+'...'
                                                    :
                                                    (item.producent + ', ' + item.nazwa)
                                            }
                                            {/*</Link>*/}
                                        </h3>

                                    <h6>
                                        Indeks: {item.indeks}
                                    </h6>
                                    <h6 style={{color: '#777'}}>
                                        EAN szt: {item.nrKat}
                                    </h6>
                                    {
                                        (item.jm !== item.jm2) ?
                                            <h6>{`W opakowaniu (${item.jm2}) : ${famix_zaokraglijIloscDlaJM1(item.jm, item.ilJm2)} ${item.jm}`}</h6>
                                            :
                                            <h6>{`W kartonie (${item.jm3}) : ${famix_zaokraglijIloscDlaJM1(item.jm, item.ilJm3)} ${item.jm}`}</h6>
                                    }
                                </div>
                                <Shared_ProductAddToBasketForm item={item} fieldForAmount={'koszykIlosc'} czyZmieniacJm={true} alwaysDostepne={true} inline={false} showButton={false} showButtonLabel={true}
                                                               handleChangeInBasket={handleChangeInBasket} odswiezIloscLokalnie={odswiezIloscLokalnie}/>
                            </div>
                        </Col>
                    ))}
                </Row>
            </InfiniteScroll>
            {
                (!isLoading && data.length === 0) &&
                <h3 style={{color: '#ccc', textAlign: 'center'}}>Nie znaleziono produktów spełniających kryteria</h3>
            }
            {
                (!isLoading && !hasMore && data.length > 0) &&
                <h3 style={{color: '#ccc', textAlign: 'center'}}>To już wszystkie znalezione wyniki</h3>
            }

        </div>
    );
};

export default OfertaGrid;
