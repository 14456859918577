import React from 'react';
import TopCard from "../../../sharedComponents/TopCard";
import {IconTimes} from "../../../sharedComponents/icons/FontAwesome";
import {TableImage_WithPreview} from "../../../sharedComponents/tableComponents/tableImages";
import {TableFormatters_formatMoney} from "../../../sharedComponents/tableComponents/tableFormatters";
import {TableActionsButtons_AnyAction} from "../../../sharedComponents/tableComponents/tableButtons";
import OverflowTextWrapper from "../../../sharedComponents/typographyComponents/overflowText/OverflowTextWrapper";
import {Link} from "react-router-dom";
import Shared_ProductAddToBasketForm from "../../../sharedComponents/_reusable/ProductAddToBasket/Shared_ProductAddToBasketForm";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator'
import TopIndeksWithBiggerView from "../../../sharedComponents/TopIndeksWithBiggerView";
// @ts-ignore
import overlayFactory from 'react-bootstrap-table2-overlay';
import {Button, Modal} from "react-bootstrap";
import KoszykService from "../../../services/KoszykService";
import {changeSortowanie, changeSortowanieKoszyk, ikonaSort} from "../../../utilities/arrays/arraysUtilities";
// import './../../../styles/modules/ProduktModule.less';

interface Props {
    data: object[],
    displayImages: boolean,
    pageableObject?: any,
    handleChangeSizePerPage?: (e: number) => void,
    handleChangePage?: (e: any, a: any) => void,
    handleChangeQuantityInBasket: (previousAmount: any, amount: any, item: any) => void,
    handleChangeSortowanie?: (sortowanie: any) => void,
    handleDeleteItem:(item:any)=>void,
    odswiezIlosc: (amount: any, item: any) => void,
    changeOferta: boolean,
    czyEdycja: boolean,
    loading: boolean,
    sortProps?: any,
}

interface State {
    changeProps: boolean,
    showModalDeletePromocje: boolean,
    delRow: any,
}

class KoszykTable extends React.Component<Props, State> {
// const KoszykTable: React.FC<Props> = ({data, displayImages, pageableObject, handleChangeSizePerPage, handleChangePage, handleChangeQuantityInBasket,handleDeleteItem}) => {

    constructor(props: Props) {
        super(props);

        this.state = {
            changeProps: false,
            showModalDeletePromocje: false,
            delRow: null,
        }
    }

    formatMoneyRow = (cell: number, row: object) => {
        return <TableFormatters_formatMoney value={cell}/>
    };

    formatZdjecieRow = (cell: string, row: any) => {
        return <TableImage_WithPreview item={row} desc={'Produkt ' + row.nazwa}/>
    };

    formatIndeksRow = (cell: any, row: any) => {
        return <div className={'tekstCenter'}><Link to={'/oferta/' + cell}>{cell}</Link></div>
        // return <div className={'tekstCenter'}>{cell}</div>
    };

    formatIloscRow = (cell: number, row: any) => {

        switch (row.typ) {
            case 'G':
                if(KoszykService.isEmpty(row.koszykIlosc) || row.koszykIlosc === 0){
                    return <></>
                } else {
                    return <div style={{margin: '0'}}>{row.koszykIlosc} ({row.zakupJm})</div>
                }
            case 'P':
                return <div style={{margin: '0'}}>{row.koszykIlosc} ({row.zakupJm})</div>
            default:
                if(KoszykService.isEmpty(row.idUm) || row.idUm === 0 || row.idUm === '0'){
                    return <Shared_ProductAddToBasketForm item={row} fieldForAmount={'koszykIlosc'}
                                                          inline={true}
                                                          showButton={false}
                                                          handleChangeInBasket={this.props.handleChangeQuantityInBasket}
                                                          alwaysDostepne={true}/>
                } else {
                    if(KoszykService.isEmpty(row.koszykIlosc) || row.koszykIlosc === 0){
                        return <></>
                    } else {
                        return <div style={{margin: '0'}}>{row.koszykIlosc} ({row.zakupJm})</div>
                    }
                }
        }

    //     return( <div>{(row.typ === 'G')? <div style={{margin: '0'}}>{row.koszykIlosc} ({row.zakupJm})</div>
    //         :
    //         <Shared_ProductAddToBasketForm item={row} fieldForAmount={'koszykIlosc'}
    //                                        inline={true}
    //                                        showButton={false}
    //                                        handleChangeInBasket={this.props.handleChangeQuantityInBasket}
    //                                        alwaysDostepne={true}/>
    //         }
    //         </div>
    // )
    };

    formatProducentRow = (cell: any) => {
        return <div className={'tekstCenter'}><OverflowTextWrapper text={cell} howManyLettersVisible={15}/></div>
    };

    formatNazwaRow = (cell: any) => {
        return <div className={'tekstCenter'}><OverflowTextWrapper text={cell} howManyLettersVisible={42}/></div>
    };

    formatUsunRow = (cell: string,row:any) => {

        switch (row.typ) {
            case 'G':
                return <Link to={'/promocje/'+row.idUm}><i className="fa fa-gift" style={{fontSize: '30px', color: '#F9BD0A'}} title={'Gratis'}/></Link>
            // case 'P':
            //     return <TableActionsButtons_AnyAction
            //         handleClickAction={() => this.setState({showModalDeletePromocje: true, delRow: row})}
            //         tooltip={'Kliknij aby usunąć z koszyka'}
            //     >
            //         <div className={'text-danger'}>
            //             <IconTimes/>
            //         </div>
            //     </TableActionsButtons_AnyAction>
            default:
                if(KoszykService.isEmpty(row.idUm) || row.idUm === 0 || row.idUm === '0') {
                    // return <div style={{margin: '0'}} title={'Kliknij aby usunąć z koszyka'}><Button size={'sm'} href={'/promocje/'+row.idUm} style={{width: '25px', height: '25px', padding: '3px 5px', background: 'white'}}><i className="fa fa-times" style={{color: 'red'}}/></Button></div>
                    return <TableActionsButtons_AnyAction
                        handleClickAction={() => this.props.handleDeleteItem(row)}
                        tooltip={'Kliknij aby usunąć z koszyka'}
                    >
                        <div className={'text-danger'}>
                            <IconTimes/>
                        </div>
                    </TableActionsButtons_AnyAction>
                } else {
                    return <div style={{margin: '0'}} title={'Edytuj'}><Button size={'sm'} href={'/promocje/'+row.idUm} style={{width: '25px', height: '25px', padding: '3px 5px'}}><i className="fa fa-pencil"/></Button></div>
                }
        }

        // return (<div className={'tekstCenter'}>
        //     {(row.typ === 'G')?
        //             <i className="fa fa-gift" style={{fontSize: '30px', color: '#F9BD0A'}}/>
        //         :
        //             <TableActionsButtons_AnyAction
        //                 handleClickAction={() => this.props.handleDeleteItem(row)}
        //                 tooltip={'Kliknij aby usunąć z koszyka'}
        //             >
        //                 <div className={'text-danger'}>
        //                     <IconTimes/>
        //                 </div>
        //             </TableActionsButtons_AnyAction>
        //     }
        //     </div>
        // )
    };

    formatNazwa = (cell: string, row:any) => {
        return (<div className={'tekstCenter'}>
                {(row.typ === 'P')?
                        <>{cell} <div className={'pakietKoszyk'}>PAKIET</div></>
                    :
                        cell
                }
            </div>
        )
    };

    onSortChange = (sortName: any, sortOrder: any) => {

        // @ts-ignore
        this.props.handleChangeSortowanie(changeSortowanieKoszyk(this.props.sortProps, sortName));

    }

    render() {
        const {data, pageableObject, handleChangeSizePerPage, handleChangePage, loading} = this.props;
        const {changeProps, delRow} = this.state;

        const columns = [{
            dataField: 'imagePath',
            text: 'Zdjęcie',
            hidden: !this.props.displayImages,
            formatter: this.formatZdjecieRow
        }, {
            dataField: 'indeks',
            text: 'Indeks TOP',
            align: 'center',
            // formatter: formatIndeksRow
            formatter: (cell: any, row: any, index: any, extraData: any) => (
                (row.typ === 'G' || (!KoszykService.isEmpty(row.idUm) && row.idUm !== 0 && row.idUm !== '0'))?
                        cell
                    :
                        <TopIndeksWithBiggerView item={row} odswiezIlosc={this.props.odswiezIlosc} edycja={this.props.czyEdycja} alwaysDostepne={true}><a id='indeks'>{cell}</a></TopIndeksWithBiggerView>
            ),
            headerFormatter: () => {return <>Indeks TOP &nbsp;{ikonaSort('k.indeks', this.props.sortProps)}</>},
            headerStyle: {cursor: 'pointer'},
            onSort: this.onSortChange,
            sort: true
        }, {
            dataField: 'producent',
            text: 'Producent',
            align: 'center',
            headerFormatter: () => {return <>Producent &nbsp;{ikonaSort('producent', this.props.sortProps)}</>},
            headerStyle: {cursor: 'pointer'},
            onSort: this.onSortChange,
            sort: true
        }, {
            dataField: 'nazwa',
            text: 'Nazwa',
            align: 'center',
            formatter: this.formatNazwa,
            headerFormatter: () => {return <>Nazwa &nbsp;{ikonaSort('k.nazwa', this.props.sortProps)}</>},
            headerStyle: {cursor: 'pointer'},
            onSort: this.onSortChange,
            sort: true
        }, {
            dataField: 'opakowania',
            text: 'W opakowaniu',
            align: 'center'
        }, {
            dataField: 'jm2',
            text: 'JM opak.',
            align: 'center'
        }, {
            dataField: 'cena',
            text: 'Cena szt/kg',
            formatter: this.formatMoneyRow
        }, {
            dataField: 'vat',
            text: 'VAT',
            align: 'center'
        }, {
            dataField: 'wartosc',
            text: 'Wartość',
            formatter: this.formatMoneyRow,
            headerFormatter: () => {return <>Wartość &nbsp;{ikonaSort('14', this.props.sortProps)}</>},
            headerStyle: {cursor: 'pointer'},
            onSort: this.onSortChange,
            sort: true
        }, {
            dataField: 'ilosc',
            text: 'Ilość',
            style: {maxWidth: '146px'},
            formatExtraData: changeProps || this.props.changeOferta,
            formatter: this.formatIloscRow,
            align: 'center',
            headerFormatter: () => {return <>Ilość &nbsp;{ikonaSort('ilosc', this.props.sortProps)}</>},
            headerStyle: {cursor: 'pointer'},
            onSort: this.onSortChange,
            sort: true
        }, {
            dataField: 'indeks',
            text: 'Akcja',
            formatter: this.formatUsunRow,
            align: 'center'
        }];

        return (
            <div id={'KoszykTable'}>
                <TopCard heading={'Produkty w koszyku'} hasPadding={false} enableExportKoszyk={true}>

                    <BootstrapTable remote keyField='id' data={data} columns={columns} hover
                                    noDataIndication={'Koszyk jest pusty'}
                                    loading={loading}
                                    overlay={ overlayFactory({ spinner: true, styles: { overlay: (base: any) => ({...base, background: 'rgba(96,93,141,0.3)'}) } }) }
                                    pagination={(paginationFactory({
                                        page: (pageableObject.currentPage) + 1,
                                        sizePerPage: pageableObject.currentSize,
                                        totalSize: pageableObject.totalElements,
                                        sizePerPageList: [10, 12, 20, 50, 100],
                                        onSizePerPageChange: handleChangeSizePerPage,
                                        onPageChange: handleChangePage
                                    }))}
                    />

                </TopCard>

                {/*<Modal show={this.state.showModalDeletePromocje} onHide={() => this.setState({showModalDeletePromocje: false})}>*/}
                {/*    <Modal.Header closeButton>*/}
                {/*        <Modal.Title>Usuwanie promocji</Modal.Title>*/}
                {/*    </Modal.Header>*/}
                {/*    <Modal.Body>Kliknięcie przycisku usuń spowoduje usunięcie wszystkich pozycji powiązanych z tą promocją. Czy napewno usunąć?</Modal.Body>*/}
                {/*    <Modal.Footer>*/}
                {/*        <Button variant="secondary" onClick={() => this.setState({showModalDeletePromocje: false})}>*/}
                {/*            Anuluj*/}
                {/*        </Button>*/}
                {/*        <Button variant="primary" onClick={() => {this.props.handleDeleteItem(delRow); this.setState({showModalDeletePromocje: false})}}>*/}
                {/*            Tak, usuń promocje z koszyka*/}
                {/*        </Button>*/}
                {/*    </Modal.Footer>*/}
                {/*</Modal>*/}

            </div>
        );
    }
};

export default KoszykTable;
