import React from 'react';
import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {IconExternalLink} from "../../../../sharedComponents/icons/FontAwesome";

interface Props {
    reklamacja: any
}

const AdminReklamacjaPopupSzczegoly: React.FC<Props> = ({reklamacja}) => {
    return (
        <div id={'AdminReklamacjaPopupSzczegoly'}>
            <h6 className={'heading-label'}>
                Lista reklamowanego towaru:
            </h6>
            <div id={'FakturaReklamacjaPozycje'}>
                {
                    reklamacja.pozycje.map((pozycja: any) => (
                        <div className={'FakturaReklamacjaPozycjaItem'}>
                            <Row>
                                <Col sm={2} md={2} lg={1} xl={1}>
                                    <div className={'one-line'}>
                                        # {pozycja.pozycja}
                                    </div>
                                </Col>
                                <Col sm={7} md={7} lg={4} xl={4}>
                                    <div>
                                        <Link to={'/oferta/' + pozycja.indeks} target={'_blank'}>
                                            {pozycja.indeks} <IconExternalLink/>
                                        </Link>
                                    </div>
                                    <h6>
                                        {pozycja.producent}, {pozycja.nazwa}
                                    </h6>
                                </Col>
                                <Col sm={3} md={3} lg={3} xl={2}>
                                    <div className={'one-line'}>
                                        <label>Zamówiono:</label>
                                        <span>
                                        {pozycja.zamowiono} {pozycja.jm}
                                    </span>
                                    </div>
                                </Col>
                                <Col sm={12} md={12} lg={4} xl={5}>
                                    <div className={'one-line'}>
                                        <label>Powód:</label>
                                        <span>
                                       {pozycja.powod}
                                    </span>
                                    </div>
                                </Col>
                            </Row>

                        </div>
                    ))
                }
            </div>
            <h6 className={'heading-label'}>
                Uwagi ogólne:
            </h6>
            <div className="content-uwaga" dangerouslySetInnerHTML={{__html: reklamacja.uwaga}}></div>
        </div>
    );
};

export default AdminReklamacjaPopupSzczegoly;