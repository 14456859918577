import {HOST_API} from "../ApplicationConfig";
import axios from 'axios';

const HOST = HOST_API + 'komunikaty/';

export default class PowiadomieniaService {

    static getKomunikatyList = () => {
        return axios.request({
            method: 'get',
            url: `${HOST}getKomunikatyList`
        })
    };

    static getNieodczytaneKomunikatyCount = () => {
        return axios.request({
            method: 'get',
            url: `${HOST}getNieodczytaneKomunikatyCount`
        })
    };

    static potwierdzOdczyt = (id: number) => {
        return axios.request({
            method: 'put',
            url: `${HOST}potwierdzOdczyt/${id}`
        })
    };

}