import React from 'react';
import TopCard from "../../../../sharedComponents/TopCard";
import OverflowTextWrapper from "../../../../sharedComponents/typographyComponents/overflowText/OverflowTextWrapper";
import {TableFormatters_formatBoolean} from "../../../../sharedComponents/tableComponents/tableFormatters";
import {TableActionButtons_EditDelete} from "../../../../sharedComponents/tableComponents/tableButtons";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator'
import TopIndeksWithBiggerView from "../../../../sharedComponents/TopIndeksWithBiggerView";
import {IconCart} from "../../../../sharedComponents/icons/FontAwesome";
import Shared_ProductAddToBasketForm
    from "../../../../sharedComponents/_reusable/ProductAddToBasket/Shared_ProductAddToBasketForm";
import {Button} from "react-bootstrap";
import ReactTooltip from "react-tooltip";
// @ts-ignore
import overlayFactory from 'react-bootstrap-table2-overlay';

interface Props {
    komunikaty: any,
    handleClickEdit: (komunikat: any) => void;
    handleClickDelete: (komunikat: any) => void;
    pageableObject: any,
    handleChangeSizePerPage: (e: number) => void;
    handleChangePage: (e: any, a: any) => void;
    loading: boolean
}

const AdminKomunikatyTable: React.FC<Props> = ({komunikaty, handleClickEdit, handleClickDelete,pageableObject,handleChangeSizePerPage,handleChangePage,loading}) => {

    const formatKomunikatRow = (cell: string, row: any) => {
        return <> <div data-tip data-for={"tresc" + row.id} style={{cursor: 'default'}}>
            {/*<OverflowTextWrapper text={cell} howManyLettersVisible={30}/>*/}
            {(cell.length < 30)? cell : cell.substr(0, 27) + '...'}
            </div>
                <ReactTooltip id={"tresc" + row.id} place='bottom' effect='float'
                              // delayHide={500}
                              delayShow={200}
                              // delayUpdate={500}
                              // backgroundColor={"#605D8D"}
                              border={true}
                >
                    <p style={{whiteSpace: 'pre-line', color: 'white', margin: '0'}}>{cell}</p>
                </ReactTooltip>
        <div/>
        </>
    };

    const formatNiePokazujRow = (cell: boolean) => {
        // tutaj odwrotnosc wartosci - true: (0) pokazuj, false (1)- nie pokazuj
        // let pokazuj = !cell;

        return <TableFormatters_formatBoolean value={cell} titleTrue={'Komunikat jest widoczny'} titleFalse={'Komunikat jest niewidoczny'}/>
    };

    const formatCzyB2BRow = (cell: boolean) => {
        return <TableFormatters_formatBoolean value={cell} titleTrue={'Zaznaczono opcję B2B'} titleFalse={'Brak zaznaczenia opcji B2B'}/>
    };

    const formatCzyWysylacEmailRow = (cell: boolean) => {
        return <TableFormatters_formatBoolean value={cell} titleTrue={'Zaznaczono opcję wysyłania email'} titleFalse={'Brak zaznaczenia opcji wysyłania email'}/>
    };

    const formatCzyWysylacMobRow = (cell: boolean) => {
        return <TableFormatters_formatBoolean value={cell} titleTrue={'Zaznaczono opcję wysyłania Mobilne'} titleFalse={'Brak zaznaczenia opcji wysyłania Mobilne'}/>
    };

    const formatAkcjeRow = (cell: any, row: any) => {
        return <TableActionButtons_EditDelete handleClickEdit={() => handleClickEdit(row)} handleClickDelete={() => handleClickDelete(row)}/>
    };

    const columns = [{
        dataField: 'id',
        text: 'id',
        hidden: true
    }, {
        dataField: 'dataWpisu',
        text: 'Data wpisu',
        align: 'center',
    }, {
        dataField: 'dataZmiany',
        text: 'Data zmiany',
        align: 'center',
    }, {
        dataField: 'komunikat',
        text: 'Komunikat',
        formatter: formatKomunikatRow
    }, {
        dataField: 'kontrahent',
        text: 'Kontrahent',
        align: 'center',
    }, {
        dataField: 'waznyOd',
        text: 'Ważny od',
        align: 'center',
    }, {
        dataField: 'waznosc',
        text: 'Ważny do',
        align: 'center',
    }, {
        dataField: 'niePokazuj',
        text: 'Czy pokazać?',
        hidden: true,
        formatter: formatNiePokazujRow
    }, {
        dataField: 'czyKomunikatB2b',
        text: 'Czy B2B?',
        align: 'center',
        formatter: formatCzyB2BRow
    }, {
        dataField: 'czyEmail',
        text: 'Czy email?',
        formatter: formatCzyWysylacEmailRow
    }, {
        dataField: 'czyMob',
        text: 'Czy Mob?',
        formatter: formatCzyWysylacMobRow
    }, {
        dataField: 'dataWyswietleniaB2b',
        text: 'Ostatnio wyswietlono',
        align: 'center'
    }, {
        dataField: 'dataWysylkiEmail',
        text: 'Data wysyłki',
        align: 'center'
    }, {
        dataField: 'id',
        text: 'Akcje',
        style: {width: '68px'},
        formatter: formatAkcjeRow
    }];

    return (
        <div id={'AdminKomunikatyTable'}>
            <TopCard heading={'Dodane komunikaty'} hasPadding={false} enableExport={false}>

                <BootstrapTable remote keyField='id' data={ komunikaty } columns={ columns } hover noDataIndication={'Brak pasujących wyników'}
                                loading={loading}
                                overlay={ overlayFactory({ spinner: true, styles: { overlay: (base: any) => ({...base, background: 'rgba(96,93,141,0.3)'}) } }) }
                                pagination={(paginationFactory({
                                    page: (pageableObject.currentPage) + 1,
                                    sizePerPage: pageableObject.currentSize,
                                    totalSize: pageableObject.totalElements,
                                    sizePerPageList: [10, 12, 20, 50, 100],
                                    onSizePerPageChange: handleChangeSizePerPage,
                                    onPageChange: handleChangePage
                                }))}
                />

                {/*<BootstrapTable data={komunikaty} hover={true}*/}
                {/*                options={*/}
                {/*                    {*/}
                {/*                        noDataText: 'Brak pasujących wyników',*/}
                {/*                        sizePerPageList: [10, 12, 20, 50, 100],*/}
                {/*                        sizePerPage: pageableObject.currentSize,*/}
                {/*                        page: (pageableObject.currentPage) + 1,*/}
                {/*                        onSizePerPageList: handleChangeSizePerPage,*/}
                {/*                        onPageChange: handleChangePage,*/}
                {/*                    }*/}
                {/*                }*/}
                {/*                remote={true}*/}
                {/*                pagination={true}*/}
                {/*                fetchInfo={{dataTotalSize: pageableObject.totalElements}}*/}
                {/*>*/}
                {/*    <TableHeaderColumn dataField={'id'}*/}
                {/*                       isKey={true}*/}
                {/*                       dataSort={true}*/}
                {/*                       hidden={true}*/}
                {/*    >ID</TableHeaderColumn>*/}
                {/*    <TableHeaderColumn dataField={'dataWpisu'}*/}
                {/*                       dataSort={true}*/}
                {/*                       width={"100"}*/}
                {/*    >Data wpisu</TableHeaderColumn>*/}
                {/*    <TableHeaderColumn dataField={'dataZmiany'}*/}
                {/*                       dataSort={true}*/}
                {/*                       width={"100"}*/}
                {/*    >Data zmiany</TableHeaderColumn>*/}
                {/*    <TableHeaderColumn dataField={'komunikat'}*/}
                {/*                       dataSort={true}*/}
                {/*                       width={"3le00"}*/}
                {/*                       dataFormat={formatKomunikatRow}*/}
                {/*    >Komunikat</TableHeaderColumn>*/}
                {/*    <TableHeaderColumn dataField={'kontrahent'}*/}
                {/*                       dataSort={true}*/}
                {/*                       width={"120"}*/}
                {/*    >Kontrahent</TableHeaderColumn>*/}
                {/*    <TableHeaderColumn dataField={'waznosc'}*/}
                {/*                       dataSort={true}*/}
                {/*                       width={"100"}*/}
                {/*    >Ważny do</TableHeaderColumn>*/}
                {/*    <TableHeaderColumn dataField={'niePokazuj'}*/}
                {/*                       dataSort={true}*/}
                {/*                       width={"80"}*/}
                {/*                       dataFormat={formatNiePokazujRow}*/}
                {/*    >Czy pokazać?</TableHeaderColumn>*/}
                {/*    <TableHeaderColumn dataField={'czyKomunikatB2b'}*/}
                {/*                       dataSort={true}*/}
                {/*                       width={"70"}*/}
                {/*                       dataFormat={formatCzyB2BRow}*/}
                {/*    >Czy B2B?</TableHeaderColumn>*/}
                {/*    <TableHeaderColumn dataField={'czyEmail'}*/}
                {/*                       dataSort={true}*/}
                {/*                       width={"70"}*/}
                {/*                       dataFormat={formatCzyWysylacEmailRow}*/}
                {/*    >Czy email?</TableHeaderColumn>*/}
                {/*    <TableHeaderColumn dataField={'dataWyswietleniaB2b'}*/}
                {/*                       dataSort={true}*/}
                {/*                       width={"100"}*/}
                {/*    >Ostatnio wyswietlono</TableHeaderColumn>*/}
                {/*    <TableHeaderColumn dataField={'dataWysylkiEmail'}*/}
                {/*                       dataSort={true}*/}
                {/*                       width={"100"}*/}
                {/*    >Dostarczono email</TableHeaderColumn>*/}
                {/*    <TableHeaderColumn dataField={'id'}*/}
                {/*                       dataSort={true}*/}
                {/*                       width={"80"}*/}
                {/*                       dataFormat={formatAkcjeRow}*/}
                {/*    >Akcje</TableHeaderColumn>*/}
                {/*</BootstrapTable>*/}
            </TopCard>
        </div>
    );
};

export default AdminKomunikatyTable;