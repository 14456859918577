import React, {Component} from 'react';
import {displayNotificationError, displayNotificationInfo} from "../../../utilities/notifications/NotificationsManager";
import {Button, Col, Form, FormGroup, InputGroup, Row} from "react-bootstrap";
import {IconCartPlus, IconRefresh} from "../../icons/FontAwesome";

// @ts-ignore
import InputNumber from 'rc-input-number'
import 'rc-input-number/assets/index.css';
import {formatMoney} from "../../../utilities/formatters/formatMoney";
import ReactTooltip from "react-tooltip";
import {famix_przeliczIloscDlaJednostekMiary} from "../../../utilities/famix_others/famix_operations";
import KoszykService from "../../../services/KoszykService";

interface Props {
    item: any,
    fieldForAmount: string,
    inline: boolean,
    showButton: boolean,
    showButtonLabel?: boolean,
    showPriceInNotInline?: boolean,
    id?: string,
    alwaysDostepne?: boolean,
    handleChangeInBasket: (previousAmount: any, amount: any, item: any, opakowanie: string) => void,
    odswiezIloscLokalnie?: (amount: any, item: any, typOpak?: string) => void,
    czyZmieniacJm?: boolean
}

interface State {
    amount: number,
    nowa: any,
    product: any,
    czyZaokraglonoIWyslanoEnterem: boolean,
    selectJm: boolean,
    zakupOpakowanieTyp: any,
    options: any,
    ileValue: number,
    jm: string,
    animJm: boolean
}

const MIN = 0;
const MAX = 9999;

class Shared_ProductAddToBasketForm extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            amount: 0,
            nowa: null,
            product: {
                cena: 0,
                cenaMetka: 0,
                ilosc: 0,
                indeks: "",
                indeksKodPask: "",
                jm: "",
                jm2: "",
                jm3: "",
                niezaimport: "",
                nrKat: "",
                nrKat2: "",
                nrKat3: "",
                przJm2: 0,
                przJm3: 0,
                sprzedazJm: "",
                uwagi: ""
            },
            czyZaokraglonoIWyslanoEnterem: false,
            selectJm: false,
            zakupOpakowanieTyp: this.valueSelect(this.props.item),
            options: [],
            ileValue: 0,
            jm: this.props.item.zakupJm,
            animJm: false
        }
    }

    componentDidMount() {
        if (this.props.item) {
            if (this.props.item[this.props.fieldForAmount] !== undefined && this.props.item[this.props.fieldForAmount] !== '') {
                this.setState({
                    amount: (this.props.item[this.props.fieldForAmount] !== null) ? this.props.item[this.props.fieldForAmount] : 0,
                })
            }
            this.setState({ileValue: this.props.item.koszykIlosc, product: this.props.item, jm: this.props.item.zakupJm});
            // this.generateSelectOptions();
        }
        this.setState({animJm: true});
    }

    componentDidUpdate(prevProps: any) {

        let input: any = document.getElementById('input-' + this.props.item.indeks);
        // let s = prevProps.item['zakupJm'];
        // let n = this.props.item.zakupJm;


        if (input.value !== this.state.nowa && this.state.nowa !== null) {
            input.value = this.state.nowa;
        }

        // console.log('test')
        // console.log(s, n)

        // if(s !== n) {
        //     alert('zmiana')
        // }
        //
        // if (prevProps.item[this.props.fieldForAmount] !== this.props.item[this.props.fieldForAmount]) {
        //     this.setState({amount: (this.props.item[this.props.fieldForAmount] !== null) ? this.props.item[this.props.fieldForAmount] : 0})
        // }


    }

    static getDerivedStateFromProps: React.GetDerivedStateFromProps<
        Props, any> = (nextProps, prevState) => { //console.log(nextProps.item[nextProps.fieldForAmount], prevState.amount)

        // console.log('get');

        if (nextProps.item !== null && nextProps.item !== undefined &&
            nextProps.item[nextProps.fieldForAmount] !== prevState.amount
        ) {
            return {amount: (nextProps.item[nextProps.fieldForAmount] !== null) ? nextProps.item[nextProps.fieldForAmount] : 0, nowa: null};
        }

        // if(nextProps.item.zakupJm !== prevState.jm)
        // {
        //     return {jm: nextProps.item.zakupJm}
        // }
        return null;
    };

    // shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>, nextContext: any): boolean {
    //     console.log('fff')
    //     if(nextProps.item.zakupJm !== this.state.jm)
    //     {
    //         return true;
    //     }
    //     return false;
    // }

    valueSelect = (item: any) => {
        if (item.zakupJm.indexOf('SZT') > -1) {
            return{value: 'jednostka', label: `Zakup po jednostce (${item.jm})`, jm: item.jm};
        }
        if (item.zakupJm.indexOf('DIS') > -1)
            return{value: 'opakowanie', label: `Zakup po opakowaniu (${item.jm2})`, jm: item.jm2};

        if (item.zakupJm.indexOf('KRT') > -1)
            return{value: 'karton', label: `Zakup po kartonie (${item.jm3})`, jm: item.jm3};

        if (item.zakupJm.indexOf('STD') > -1)
            return{value: 'opakowanie', label: `Zakup po opakowaniu (${item.jm3})`, jm: item.jm3};

        // if (item.zakupJm.indexOf('KG') > -1)
        //     return{value: 'jednostka', label: `Zakup po jednostce (${item.jm})`, jm: item.jm};
    }

    generateSelectOptions = () => {

        let {item} = this.props;
        let options = [];

        if (item.jm.indexOf('KG') <= -1 && item.jm2.indexOf('DIS') <= -1) {
            options.push({value: 'jednostka', label: `Zakup po jednostce (${item.jm})`, jm: item.jm});
        }
        if (item.jm !== item.jm2)
            options.push({value: 'opakowanie', label: `Zakup po opakowaniu (${item.jm2})`, jm: item.jm2});

        if (item.jm2 !== item.jm3)
            options.push({value: 'karton', label: `Zakup po kartonie (${item.jm3})`, jm: item.jm3});

        return options;
    };

    czyZmieniacJm = (): boolean => {

        let {item} = this.props;

        if (item.koszykIlosc === 0 && !KoszykService.isEmpty(item.jm2) && !KoszykService.isEmpty(item.jm3)) {
            if ((item.jm === 'SZT' && item.jm2 === 'SZT' && item.jm3 === 'KRT') || (item.jm === 'SZT' && item.jm2 === 'DIS' && item.jm3 === 'KRT')) {
                return true;
                //item.zakupJm = zakupNaKrt ? item.jm3 : item.jm;
                //	console.log(item.indeks+"  Zakup JM:"+item.zakupJm);
            }
        }

        return false;

    }

    onChangeAmount = (nowa: any) => {
        this.setState({nowa})
    };

    onPressEnter = (e: any) => {
        e.preventDefault();
        if(this.state.nowa !== null)
        this.aktualizujZamowienie(true);
    };

    onClickButton = () => {
        if(this.state.nowa !== null)
        this.aktualizujZamowienie()
    };

    aktualizujZamowienie = (czyWyslanoEnterem = false) => {

        console.log(czyWyslanoEnterem)

        let amount = this.state.nowa;
        let {item} = this.props;
        let czyPoprawne = true;
        let czyPoprawnaIloscSztuk = true;

        let zaokraglonaAmount: any;


        //@ts-ignore
        if (isNaN(amount) || amount === '' || amount === null) {
            //czyPoprawne = false;
            // @ts-ignore
            amount = 0;
        } else {

            if (amount < MIN || amount > MAX) {
                czyPoprawne = false
            }

            if (item.jm.indexOf('KG') === -1) {
                // @ts-ignore
                if (amount.toString().indexOf('.') > -1 || amount.toString().indexOf(',') > -1) {
                    czyPoprawne = false
                }
            }

            if (item.jm === 'KG' || item.jm2 === 'DIS') {
                zaokraglonaAmount = amount
            }
        }

        // zaokraglenie dla wartosci wpisywanej z palca. Np produkt jest pakowany po 12 sztuk, wiec mozna kupic tylko wielokrotnosc 12 sztuk: 12, 24, 36 itd. Dodać ograniczenie zaokraglajace, np jak uzytkownik wpisze 40 to aplikacja przyjmie 36 i wyswietli odpowiedni komunikat

        if (czyPoprawne) {
            let previousAmount = this.props.item[this.props.fieldForAmount];
            if (previousAmount === null || previousAmount === undefined || previousAmount === '')
                previousAmount = 0;

            console.log('wchodzi czyPoprawne')
            this.setState({czyZaokraglonoIWyslanoEnterem: czyWyslanoEnterem})

            if (!czyPoprawnaIloscSztuk) {

                console.log('wchodzi !czyPoprawnaIloscSztuk')

                if (czyWyslanoEnterem) {
                    this.setState({czyZaokraglonoIWyslanoEnterem: true})
                }

                //@ts-ignore
                if (Boolean(document.getElementById('input-' + item.indeks))) {
                    //@ts-ignore
                    document.getElementById('input-' + item.indeks).value = zaokraglonaAmount;
                }


                displayNotificationInfo(`Zaokrąglono podaną ilość produktu`, `Ilość produktu w tej jednostce musi być wielokrotnością liczby ${(item.jm === item.jm2) ? item.ilJm3 : item.ilJm2}. Do koszyka dodano zaokrągloną ilość.`)


                this.props.handleChangeInBasket(previousAmount, zaokraglonaAmount, this.props.item, item.zakupJm);

                this.forceUpdate(() => {

                    this.setState({amount: zaokraglonaAmount});

                    //@ts-ignore
                    if (Boolean(document.getElementById('input-' + item.indeks))) {
                        //@ts-ignore
                        document.getElementById('input-' + item.indeks).value = zaokraglonaAmount;
                    }
                })

            } else {
                //alert(previousAmount+' - '+amount)
                this.props.handleChangeInBasket(previousAmount, amount, this.props.item, item.zakupJm);
            }
        } else {
            displayNotificationError('Błąd dodania produktu do koszyka', 'Podano nieprawidłową ilość produktu')
        }



    };

    onBlurInput = (inline: boolean, showButton: boolean, item: any) => {
        console.log('onBlurInput')
        console.log('Po aktualizacji:')
        console.log('koszyk: ' + this.props.item.koszyk)
        console.log('koszykIlosc: ' + this.props.item.koszykIlosc)
        if (this.state.czyZaokraglonoIWyslanoEnterem) {
            this.setState({czyZaokraglonoIWyslanoEnterem: false})
        } else {
            // if(inline && !showButton){
            // widok koszyka
            // if ((this.state.amount !== this.props.item[this.props.fieldForAmount] && this.props.item[this.props.fieldForAmount] !== null) || (this.state.amount > 0 && this.props.item[this.props.fieldForAmount] === null)) {
            // if (window.confirm('Wykryto niezatwierdzone zmiany, czy wykonać aktualizację?'))
            //     this.aktualizujZamowienie()
            // else
            //     this.setState({amount: this.props.item[this.props.fieldForAmount] === null ? 0 : this.props.item[this.props.fieldForAmount]})
            // }
            // } else {
            // setTimeout(()=>{
            //     if ((this.state.amount !== this.props.item[this.props.fieldForAmount] && this.props.item[this.props.fieldForAmount] !== null) || (this.state.amount > 0 && this.props.item[this.props.fieldForAmount] === null)) {
            // if (window.confirm(`Wykryto niezatwierdzone zmiany dla produktu ${item.nazwa}. Czy wykonać aktualizację ilości?`))
            if(this.state.nowa !== null)
            {
                this.aktualizujZamowienie()
            }

            // else
            //     this.setState({amount: this.props.item[this.props.fieldForAmount] === null ? 0 : this.props.item[this.props.fieldForAmount]})
            // }
            // },1000);
            // }
        }


    };

    onClickInput = () => {
        //console.log('test click ' + this.state.amount)
        let {item} = this.props;
        let {amount} = this.state;

        if (amount===0){
            //@ts-ignore
            if (Boolean(document.getElementById('input-' + item.indeks))) {
                //@ts-ignore
                document.getElementById('input-' + item.indeks).value = '';
            }
        }
    }

    // 27.05.2021 - jednostke dostaje z api
    // renderJm() {
    //     let item = this.props.item
    //     if (item.jm.indexOf("KG") > -1 || item.jm2.indexOf("DIS") > -1) {
    //         return item.jm2
    //     } else {
    //         return item.jm
    //     }
    // }

    onChangeZakupOpakowanieTyp = (jm: string) => {
        // this.setState({zakupOpakowanieTyp: typ});
        //localStorage.setItem('OPAKOWANIE_TYP', JSON.stringify(typ))
        let {item} = this.props;
        // let ile = (this.state.nowa)? this.state.nowa : this.state.amount;

        // //if(item.zakupJm !== typ.jm)
        // //{
        //     this.setState({
        //         //@ts-ignore
        //         ileValue: famix_przeliczIloscDlaJednostekMiary(this.state.zakupOpakowanieTyp.value, typ.value, this.state.ileValue, this.state.product), zakupOpakowanieTyp: typ
        //     });
        //         // @ts-ignore
        //         //this.props.odswiezIloscLokalnie(this.state.nowa, item, typ.jm)
        //
        // //}

        if(item.zakupJm !== jm)
        {
            // @ts-ignore
            this.props.odswiezIloscLokalnie(0, item, jm)
        }


    };

    render() {

        let {item, fieldForAmount, inline, showButton, showButtonLabel, id, alwaysDostepne, showPriceInNotInline = true, czyZmieniacJm = true} = this.props;
        let {amount, nowa, selectJm, options, zakupOpakowanieTyp, product, animJm} = this.state;

        return (
            <div className={'ProductAddToBasketForm'} id={id}>
                {
                    inline ?
                        <Form inline={true}>
                            <FormGroup>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <div data-tip data-for={"jm" + item.indeks}>
                                        <InputGroup.Text
                                            // onClick={() => this.setState({selectJm: !selectJm})}
                                                         className={'inputgroup-prepend-text'+((this.czyZmieniacJm())? ' edit' : ' noedit')}>{item.zakupJm}
                                             {/*className={'inputgroup-prepend-text'}>{zakupOpakowanieTyp.jm}*/}
                                        </InputGroup.Text>
                                        </div>

                                        {(this.czyZmieniacJm() && !nowa && czyZmieniacJm) &&
                                            <ReactTooltip id={"jm" + item.indeks} place='left' effect='solid'
                                                          delayHide={500}
                                                          delayShow={500}
                                                          delayUpdate={500}
                                                          backgroundColor={"#605D8D"}
                                                          border={true}
                                            >
                                                {
                                                    this.generateSelectOptions().map((jm: any) => (
                                                        <Button className={'buttonJm'}
                                                                onClick={() => this.onChangeZakupOpakowanieTyp(jm.jm)}>{jm.jm}</Button>
                                                    ))
                                                }
                                            </ReactTooltip>
                                        }

                                    </InputGroup.Prepend>
                                    <InputNumber
                                        value={(nowa !== null)? nowa : amount}
                                        onChange={this.onChangeAmount}
                                        disabled={(alwaysDostepne) ? false : !item.dostepne}
                                        precision={!(zakupOpakowanieTyp.value === 'SZT') ? 0 : (product.jm.indexOf('KG')) > -1 ? 2 : 0}
                                        step={!(zakupOpakowanieTyp.value === 'SZT') ? 1 : (product.jm === product.jm2) ? product.ilJm3 : product.ilJm2}
                                        min={MIN} max={MAX}
                                        onPressEnter={this.onPressEnter}
                                        id={'input-' + item.indeks}
                                        onBlur={() => this.onBlurInput(inline, showButton, item)}
                                        onClick={this.onClickInput}
                                    />
                                </InputGroup>
                            </FormGroup>
                            {
                                showButton &&
                                <FormGroup>
                                    <Button variant={'primary'}
                                            type={'button'}
                                            size={'sm'}
                                            disabled={(alwaysDostepne) ? false : !item.dostepne}
                                            onClick={this.onClickButton}
                                            title={(!Boolean(item[fieldForAmount])) ? "Dodaj produkt do koszyka" : "Zmień ilość produktu w koszyku bądź usuń z koszyka"}
                                    >
                                        {/*<IconCartPlus/> {showButtonLabel && <span id={`${item.indeks}-btn-inner`}>{!Boolean(item[fieldForAmount]) ? "Dodaj" : "Zmień"}</span>}*/}
                                        <span id={`${item.indeks}-btn-inner`}>
                                        {
                                            (!Boolean(item[fieldForAmount])) ?
                                                <><IconCartPlus/> {showButtonLabel && 'Dodaj'}</>
                                                :
                                                <><IconRefresh/> {showButtonLabel && 'Zmień'}</>
                                        }
                                        </span>
                                    </Button>
                                </FormGroup>
                            }
                        </Form>
                        :
                        <Form className={'form-not-inline'}>
                            <Row>
                                {
                                    showPriceInNotInline &&
                                    <Col>
                                        <h4>
                                            {formatMoney(item.cena)}/{item.jm}
                                        </h4>
                                    </Col>
                                }
                                <Col>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            {/*<InputGroup.Text*/}
                                            {/*    className={'inputgroup-prepend-text'}>{item.zakupJm}</InputGroup.Text>*/}

                                            <div data-tip data-for={"jm" + item.indeks}>
                                                <InputGroup.Text
                                                    // onClick={() => this.setState({selectJm: !selectJm})}
                                                    className={'inputgroup-prepend-text'+((this.czyZmieniacJm())? ' edit' : ' noedit')}>{item.zakupJm}
                                                    {/*className={'inputgroup-prepend-text'}>{zakupOpakowanieTyp.jm}*/}
                                                </InputGroup.Text>
                                            </div>

                                            {(this.czyZmieniacJm() && !nowa && czyZmieniacJm) &&
                                            <ReactTooltip id={"jm" + item.indeks} place='bottom' effect='solid'
                                                          delayHide={500}
                                                          delayShow={500}
                                                          delayUpdate={500}
                                                          backgroundColor={"#605D8D"}
                                                          border={true}
                                            >
                                                {
                                                    this.generateSelectOptions().map((jm: any) => (
                                                        <Button className={'buttonJm'}
                                                                onClick={() => this.onChangeZakupOpakowanieTyp(jm.jm)}>{jm.jm}</Button>
                                                    ))
                                                }
                                            </ReactTooltip>
                                            }
                                        </InputGroup.Prepend>
                                        <InputNumber
                                            value={(nowa !== null)? nowa : amount}
                                            onChange={this.onChangeAmount}
                                            disabled={(alwaysDostepne) ? false : !item.dostepne}
                                            precision={!(zakupOpakowanieTyp.value === 'SZT') ? 0 : (product.jm.indexOf('KG')) > -1 ? 2 : 0}
                                            step={!(zakupOpakowanieTyp.value === 'SZT') ? 1 : (product.jm === product.jm2) ? product.ilJm3 : product.ilJm2}
                                            min={MIN} max={MAX}
                                            onPressEnter={this.onPressEnter}
                                            id={'input-' + item.indeks}
                                            onBlur={() => this.onBlurInput(inline, showButton, item)}
                                            onClick={this.onClickInput}
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                            {
                                showButton &&
                                <Button variant={'primary'}
                                        type={'button'}
                                        size={'sm'}
                                        block={true}
                                        disabled={(alwaysDostepne) ? false : !item.dostepne}
                                        onClick={this.onClickButton}
                                        title={(!Boolean(item[fieldForAmount])) ? "Dodaj produkt do koszyka" : "Zmień ilość produktu w koszyku bądź usuń z koszyka"}
                                >
                                    {/*<IconCartPlus/> {showButtonLabel && <span id={`${item.indeks}-btn-inner`}>{!Boolean(item[fieldForAmount]) ? "Dodaj" : "Zmień"}</span>}*/}
                                    <span id={`${item.indeks}-btn-inner`}>
                                        {
                                            (!Boolean(item[fieldForAmount])) ?
                                                <><IconCartPlus/> {showButtonLabel && 'Dodaj'}</>
                                                :
                                                <><IconRefresh/> {showButtonLabel && 'Zmień'}</>
                                        }
                                    </span>
                                </Button>
                            }
                        </Form>
                }
            </div>
        );
    }
}

export default Shared_ProductAddToBasketForm;
