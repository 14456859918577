export const yearsArray = [
    {"value": 1990, "label": "1990"}, {"value": 1991, "label": "1991"}, {"value": 1992, "label": "1992"}, {"value": 1993, "label": "1993"}, {
        "value": 1994,
        "label": "1994"
    }, {"value": 1995, "label": "1995"}, {"value": 1996, "label": "1996"}, {"value": 1997, "label": "1997"}, {"value": 1998, "label": "1998"}, {
        "value": 1999,
        "label": "1999"
    }, {"value": 2000, "label": "2000"}, {"value": 2001, "label": "2001"}, {"value": 2002, "label": "2002"}, {"value": 2003, "label": "2003"}, {
        "value": 2004,
        "label": "2004"
    }, {"value": 2005, "label": "2005"}, {"value": 2006, "label": "2006"}, {"value": 2007, "label": "2007"}, {"value": 2008, "label": "2008"}, {
        "value": 2009,
        "label": "2009"
    }, {"value": 2010, "label": "2010"}, {"value": 2011, "label": "2011"}, {"value": 2012, "label": "2012"}, {"value": 2013, "label": "2013"}, {
        "value": 2014,
        "label": "2014"
    }, {"value": 2015, "label": "2015"}, {"value": 2016, "label": "2016"}, {"value": 2017, "label": "2017"}, {"value": 2018, "label": "2018"}, {
        "value": 2019,
        "label": "2019"
    }, {"value": 2020, "label": "2020"}, {"value": 2021, "label": "2021"}, {"value": 2022, "label": "2022"}, {"value": 2023, "label": "2023"}, {
        "value": 2024,
        "label": "2024"
    }, {"value": 2025, "label": "2025"}, {"value": 2026, "label": "2026"}, {"value": 2027, "label": "2027"}, {"value": 2028, "label": "2028"}, {
        "value": 2029,
        "label": "2029"
    }, {"value": 2030, "label": "2030"}, {"value": 2031, "label": "2031"}, {"value": 2032, "label": "2032"}, {"value": 2033, "label": "2033"}, {
        "value": 2034,
        "label": "2034"
    }, {"value": 2035, "label": "2035"}, {"value": 2036, "label": "2036"}, {"value": 2037, "label": "2037"}, {"value": 2038, "label": "2038"}, {
        "value": 2039,
        "label": "2039"
    }, {"value": 2040, "label": "2040"}, {"value": 2041, "label": "2041"}, {"value": 2042, "label": "2042"}, {"value": 2043, "label": "2043"}, {
        "value": 2044,
        "label": "2044"
    }, {"value": 2045, "label": "2045"}, {"value": 2046, "label": "2046"}, {"value": 2047, "label": "2047"}, {"value": 2048, "label": "2048"}, {
        "value": 2049,
        "label": "2049"
    }, {"value": 2050, "label": "2050"}, {"value": 2051, "label": "2051"}, {"value": 2052, "label": "2052"}, {"value": 2053, "label": "2053"}, {
        "value": 2054,
        "label": "2054"
    }, {"value": 2055, "label": "2055"}, {"value": 2056, "label": "2056"}, {"value": 2057, "label": "2057"}, {"value": 2058, "label": "2058"}, {
        "value": 2059,
        "label": "2059"
    }, {"value": 2060, "label": "2060"}, {"value": 2061, "label": "2061"}, {"value": 2062, "label": "2062"}, {"value": 2063, "label": "2063"}, {
        "value": 2064,
        "label": "2064"
    }, {"value": 2065, "label": "2065"}, {"value": 2066, "label": "2066"}, {"value": 2067, "label": "2067"}, {"value": 2068, "label": "2068"}, {
        "value": 2069,
        "label": "2069"
    }, {"value": 2070, "label": "2070"}, {"value": 2071, "label": "2071"}, {"value": 2072, "label": "2072"}, {"value": 2073, "label": "2073"}, {
        "value": 2074,
        "label": "2074"
    }, {"value": 2075, "label": "2075"}, {"value": 2076, "label": "2076"}, {"value": 2077, "label": "2077"}, {"value": 2078, "label": "2078"}, {
        "value": 2079,
        "label": "2079"
    }, {"value": 2080, "label": "2080"}, {"value": 2081, "label": "2081"}, {"value": 2082, "label": "2082"}, {"value": 2083, "label": "2083"}, {
        "value": 2084,
        "label": "2084"
    }, {"value": 2085, "label": "2085"}, {"value": 2086, "label": "2086"}, {"value": 2087, "label": "2087"}, {"value": 2088, "label": "2088"}, {
        "value": 2089,
        "label": "2089"
    }, {"value": 2090, "label": "2090"}, {"value": 2091, "label": "2091"}, {"value": 2092, "label": "2092"}, {"value": 2093, "label": "2093"}, {
        "value": 2094,
        "label": "2094"
    }, {"value": 2095, "label": "2095"}, {"value": 2096, "label": "2096"}, {"value": 2097, "label": "2097"}, {"value": 2098, "label": "2098"}, {
        "value": 2099,
        "label": "2099"
    }, {"value": 2100, "label": "2100"}
];

export const monthsArray = [
    {value: 1, label: 'styczeń', valueString: '01'},
    {value: 2, label: 'luty', valueString: '02'},
    {value: 3, label: 'marzec', valueString: '03'},
    {value: 4, label: 'kwiecień', valueString: '04'},
    {value: 5, label: 'maj', valueString: '05'},
    {value: 6, label: 'czerwiec', valueString: '06'},
    {value: 7, label: 'lipiec', valueString: '07'},
    {value: 8, label: 'sierpień', valueString: '08'},
    {value: 9, label: 'wrzesień', valueString: '09'},
    {value: 10, label: 'październik', valueString: '10'},
    {value: 11, label: 'listopad', valueString: '11'},
    {value: 12, label: 'grudzień', valueString: '12'},
];

export const getFirstDayOfCurrentMonthAndYear = ():string => {
    let date = new Date().toJSON().substr(0, 7)
    date = date + '-01';
    return date
};

export const getYearFromDate = (date: string): string => {
    return date.substr(0, 4)
};

export const getMonthFromDate = (date: string): string => {
    return date.substr(5, 2)
};

export const getYearObjFromDate = (date: string): object | any => {
    return yearsArray.find((y: any) => y.label === date.substr(0, 4));
};

export const getMonthObjFromDate = (date: string): object | any => {
    return monthsArray.find((m: any) => m.valueString === date.substr(5, 2))
};

