import {HOST_API} from "../ApplicationConfig";
import axios from 'axios';

const HOST = HOST_API + 'admin/';

export default class AdminService {

    static getKomunikaty = (page: number, size: number) => {
        return axios.request({
            method: 'get',
            url: `${HOST}getKomunikaty?&page=${page}&size=${size}&paged=true`, //&sort=m.indeks,asc`,
        })
    };

    static getUzytkownicy = (page: number, size: number, params: any) => {
        return axios.request({
            method: 'post',
            url: `${HOST}getUzytkownicyPaged?&page=${page}&size=${size}&paged=true`, //&sort=m.indeks,asc`,
            data: JSON.stringify(params)
        })
    };

    static odblokujDostep = (nazwa: string) => {
        return axios.request({
            method: 'get',
            url: `${HOST}odblokujDostep/${nazwa}`
        })
    };

    static getKontrahenci = (kontahent: string) => {
        return axios.request({
            method: 'get',
            url: `${HOST}getKontrahenci?findParam=${kontahent}`
        })
    };

    static getBanery = () => {
        return axios.request({
            method: 'get',
            url: `${HOST}getBanery`
        })
    };

    static addKlient = (klient: any) => {
        return axios.request({
            method: 'put',
            url: `${HOST}addKlient`,
            data: JSON.stringify(klient)
        })
    };

    static editKlient = (klient: any) => {
        return axios.request({
            method: 'put',
            url: `${HOST}editKlient`,
            data: JSON.stringify(klient)
        })
    };

    static zmienUstawienia = (data: any) => {
        return axios.request({
            method: 'post',
            url: `${HOST}zmienUstawienieAdmin`,
            data: data
        })
    };

    static getSieciAdmin = (kontahent: string) => {
        return axios.request({
            method: 'get',
            url: `${HOST}getKontrahenciNet?findParam=${kontahent}`
        })
    };

    static getKontrahForKomunikaty = (warunek: string, page: number, size: number) => {
        return axios.request({
            method: 'post',
            url: `${HOST}getKontrahForKomunikaty?page=${page}&size=${size}&paged=true`,
            data: {
                warunek: warunek
            }
        })
    };

    static addKomunikat = (data: any) => {
        return axios.request({
            method: 'put',
            url: `${HOST}addKomunikat`,
            data: data
        })
    };

    static delKomunikat = (id: number) => {
        return axios.request({
            method: 'delete',
            url: `${HOST}delKomunikat`,
            data: {
                id: id
            }
        })
    };

    static updKomunikat = (data: any) => {
        return axios.request({
            method: 'put',
            url: `${HOST}updKomunikat`,
            data: data
        })
    };

    static getPromocjeDlaBanerow = (page: number, size: number, data: any) => {
        return axios.request({
            method: 'post',
            url: `${HOST}getPromocjeDlaBanerow?&page=${page}&size=${size}&paged=true`, //&sort=m.indeks,asc`,
            data: data
        })
    };

    static deleteBaner = (id: any) => {
        return axios.request({
            method: 'delete',
            url: `${HOST}deleteBaner/${id}`
        })
    };

    static updateBaner = (data: any) => {
        return axios.request({
            method: 'put',
            url: `${HOST}updateBaner`,
            data: data,
            headers: { "Content-Type": "multipart/form-data" }
        })
    };

    static getImgBaner = (id: any) => {
        return axios.request({
            method: 'get',
            url: HOST_API + `public/klient/getImgForBaner/${id}`,
            responseType: 'arraybuffer'
        })
    };

    static delKlient = (id: number) => {
        return axios.request({
            method: 'put',
            url: `${HOST}removeKlient/${id}`
        })
    };
}
