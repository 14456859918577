import React from 'react';
import {
    displayNotificationError,
    displayNotificationInfo,
    displayNotificationSuccess
} from "../../utilities/notifications/NotificationsManager";
import {isMobileDevice} from "../../utilities/rwd/rwdUtilities";
import {Accordion, Button, Card, Col, Row, Table} from "react-bootstrap";
import PromocjeService from "../../services/PromocjeService";
import TopLoading from "../../sharedComponents/loader/TopLoading";
import OfertaService from "../../services/OfertaService";
import {_omit} from "../../utilities/lodash/lodashUtilities";
import moment from "moment";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import {IconCart, IconTimes} from "../../sharedComponents/icons/FontAwesome";
import Shared_ProductAddToBasketForm
    from "../../sharedComponents/_reusable/ProductAddToBasket/Shared_ProductAddToBasketForm";
import {TableImage_WithPreview} from "../../sharedComponents/tableComponents/tableImages";
import ReactTooltip from "react-tooltip";
import Shared_ProductImage
    from "../../sharedComponents/_reusable/ProductImageLazyLoadingWithErrorHandling/Shared_ProductImage";
import {TableFormatters_formatMoney} from "../../sharedComponents/tableComponents/tableFormatters";
import {famix_zaokraglijIloscDlaJM1} from "../../utilities/famix_others/famix_operations";
import PromocjaInfo from "./PromocjaInfo";
import KoszykService from "../../services/KoszykService";
import {changeSortowanie} from "../../utilities/arrays/arraysUtilities";
// @ts-ignore
import overlayFactory from 'react-bootstrap-table2-overlay';
import OfertaFilterbox from "../oferta/components/OfertaFilterbox";
// @ts-ignore
import InputNumber from 'rc-input-number';
import {formatMoney} from "../../utilities/formatters/formatMoney";
import {isEmpty} from "lodash";
import Shared_ProductAddToBasketForm_function
    from "../../sharedComponents/_reusable/ProductAddToBasket/Shared_ProductAddToBasketForm_function";

// interface MatchParams {
//     path: string;
// }
//
// export interface RouteComponentProps<MatchParams> {
//     match: any;
// }
//
// interface Props extends RouteComponentProps<MatchParams> {
// }

interface Props {
    idUm: any,
    sezon?: any,
    ustawNazwePromocji: (nazwa: string) => void,
    kategorie?: any,
    producenci?: any,
    sezony?: any,
    odswiez?: (amount: any, item: any, typOpak: string) => void,
    odswierzKoszyk?: () => void,
    czyZOferty: boolean,
    czyZLinku: boolean,
    clickIndeksRow?: any,
    czyEdycja: boolean
}

interface State {
    promocjaInfo: any,
    promocjaProgowa: any,
    promocjaProgowaCu: any,
    promocjaProgowaG: any,
    progi: any,
    isRequestLoading: boolean,
    isRequestError: boolean,
    isRequestLoadingOferta: boolean,
    isRequestErrorOferta: boolean,
    produkty: any,
    pageableObject: any,
    params: any,
    sort: any,
    page: number,
    size: number,
    rodzajPromocji: string,
    czyEdycja: boolean,
    edycjaSymbol: string,
    edycjaNumer: number,
    shouldKoszykUpdate: boolean,
    loading: boolean,
    kategorie: any,
    producenci: any,
    sezony: any,
    changeOferta: boolean,
    ile: any,
    poTerminie: boolean,
    wKoszyku: boolean
}

class PromocjaModuleBody extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            promocjaInfo: [],
            shouldKoszykUpdate: false,
            rodzajPromocji: '',
            promocjaProgowa: [],
            promocjaProgowaCu: [],
            promocjaProgowaG: [],
            progi: [],
            isRequestLoading: true,
            isRequestError: false,
            isRequestLoadingOferta: true,
            isRequestErrorOferta: false,
            produkty: [],
            pageableObject: {
                currentSize: 12,
                currentPage: 0,
                totalElements: 0,
            },
            params: {
                "czyEdycja": "",
                "indeks": "",
                "kategoria": "",
                "kodPaskowy": "",
                "nazwa": "",
                "nazwam": "",
                "producent": "",
                "sezon": "",
                "wykluczKrotkaData": false,
                "wykluczProduktySezonowe": false,
                "idProm": ""
            },
            sort: [],
            page: 0,
            size: 10,
            czyEdycja: false,
            edycjaSymbol: "",
            edycjaNumer: 0,
            loading: false,
            kategorie: [],
            producenci: [],
            sezony: [],
            changeOferta: false,
            ile: [],
            poTerminie: false,
            wKoszyku: false
        }
    }

    componentDidMount() {

        if(isMobileDevice())
            window.scrollTo(0,0);

        // displayNotificationInfo('Ten widok zawiera dane statyczne', '')

        // if (Boolean(this.props.match))
        //     if (Boolean(this.props.match.params))
        //         if (Boolean(this.props.match.params.id))
        //         {
        //             this.pobierzPromocjeInfo(this.props.match.params.id);
        //             // this.pobierzOferte();
        //         }

        if(isEmpty(this.props.sezon)){
            this.pobierzPromocjeInfo(this.props.idUm);
        } else {
            let par = {
                "czyEdycja": "",
                    "indeks": "",
                    "kategoria": "",
                    "kodPaskowy": "",
                    "nazwa": "",
                    "nazwam": "",
                    "producent": "",
                    "sezon": this.props.sezon,
                    "wykluczKrotkaData": false,
                    "wykluczProduktySezonowe": false,
                    "idProm": ""
            };
            this.setState({params: par});
            if(!this.props.producenci || !this.props.sezony || !this.props.kategorie)
            {
                this.pobierzProducentow();
                this.pobierzKategorie();
                this.pobierzSezony();
            }

            this.pobierzOferte(par);
        }

    }

    dodajIle = () => {

        let arr: object[] = Array.from(this.state.progi, (item: any) => (
                {
                    ...item,
                    ile: 1
                }
            )
        );

        this.setState({progi: arr});

    }

    pobierzPromocjeInfo = (id: any) => {
        this.setState({
            isRequestLoading: true,
            isRequestError: false,
        });

        PromocjeService.getPromocjaInfo(id).then((response) => {
            this.setState({promocjaInfo: response.data, isRequestLoading: false, isRequestError: false});

            if(response.data.DATA_DO < moment().format('YYYY-MM-DD')){
                this.setState({poTerminie: true});
            }
            // else {
                if(response.data.RODZAJ === 'PROM_PAK_G')
                {
                    this.setState({rodzajPromocji: 'P'});
                    this.pobierzPromocjaProgowa(id);
                    this.promocjaProgowaSprawdzKoszyk(id);
                } else if(response.data.RODZAJ === 'PROM_PAK_CU')
                {
                    this.setState({rodzajPromocji: 'PCU'});
                    this.pobierzPromocjaCenaUpust(id);
                    this.promocjaProgowaPCUSprawdzKoszyk(id);
                } else if(response.data.RODZAJ === 'PROM_PAK_L')
                {
                    this.setState({rodzajPromocji: 'PL'});
                    this.pobierzPromocjaCenaUpust_PROM_PAK_L(id);
                    // this.pobierzPromocjaCenaUpust(id);
                } else if(response.data.RODZAJ === 'PROM_PROG_CU')
                {
                    this.setState({rodzajPromocji: 'PPCU'});
                    this.pobierzPromocjaProgCenaUpust(id);
                } else if(response.data.RODZAJ === 'PROM_PROG_G')
                {
                    this.setState({rodzajPromocji: 'PPG'});
                    this.pobierzPromocjaProgGratis(id);
                } else if(response.data.RODZAJ === 'PROM_PROST_CU')
                {
                    this.setState({rodzajPromocji: 'PROM_PROST_CU'});
                    if(!this.props.producenci || !this.props.sezony || !this.props.kategorie)
                    {
                        this.pobierzProducentow();
                        this.pobierzKategorie();
                        this.pobierzSezony();
                    }
                    this.pobierzOferte();
                }
            // }



            this.props.ustawNazwePromocji(response.data.NAZWA);

        }).catch((e) => {
            console.log('error', e);
            this.setState({isRequestLoading: false, isRequestError: true});
        })
    }

    pobierzProducentow = () => {
        OfertaService.getProducenci()
            .then((response: any) => {
                if (response.status < 300)
                    this.setState({producenci: response.data});
            })
            .catch((error: any) => {
                console.log(error);
            })
    };

    pobierzKategorie = () => {
        OfertaService.getKategorie()
            .then((response: any) => {
                if (response.status < 300)
                    this.setState({kategorie: response.data});
            })
            .catch((error: any) => {
                console.log(error);
            })
    };

    pobierzSezony = () => {
        OfertaService.getSezony()
            .then((response: any) => {
                if (response.status < 300)
                    this.setState({sezony: response.data});
            })
            .catch((error: any) => {
                console.log(error);
            })
    };

    pobierzPromocjaProgowa = (id: any) => {
        this.setState({
            isRequestLoading: true,
            isRequestError: false,
        });

        PromocjeService.getPromocjaProgrowa(this.props.czyEdycja, id).then((response) => {

            this.setState({promocjaProgowa: response.data, produkty: response.data.produkty, isRequestLoading: false, isRequestError: false}, () => this.dodajIle());
            // this.setState({promocjaProgowa: response.data, progi: response.data.progi, isRequestLoading: false, isRequestError: false}, () => this.dodajIle());
            this.sprawdzCzyWKoszyku(response.data.produkty);

        }).catch((e) => {
            console.log('error', e);
            this.setState({isRequestLoading: false, isRequestError: true});
        })

    }

    promocjaProgowaSprawdzKoszyk = (id: any) => {

        PromocjeService.procPromPakGSprawdzKoszyk(this.props.czyEdycja, id).then((response) => {
            this.setState({progi: response.data});
        }).catch((e) => {
            console.log('error', e);
        })

    }

    promocjaProgowaPCUSprawdzKoszyk = (id: any) => {

        PromocjeService.procPromPakCuSprawdzKoszyk(this.props.czyEdycja, id).then((response) => {
            this.setState({progi: response.data});
        }).catch((e) => {
            console.log('error', e);
        })

    }

    pobierzPromocjaCenaUpust = (id: any) => {
        this.setState({
            isRequestLoading: true,
            isRequestError: false,
        });

        PromocjeService.getPromocjaCenaUpustNew(false, id).then((response) => {

            this.setState({promocjaProgowa: response.data, produkty: response.data.produkty, isRequestLoading: false, isRequestError: false}, () => this.dodajIle());
            this.sprawdzCzyWKoszyku(response.data.produkty);

        }).catch((e) => {
            console.log('error', e);
            this.setState({isRequestLoading: false, isRequestError: true});
        })

        // PromocjeService.getPromocjaCenaUpust(false, id).then((response) => {
        //
        //     this.setState({promocjaProgowa: response.data, progi: response.data.progi, isRequestLoading: false, isRequestError: false}, () => this.dodajIle());
        //
        // }).catch((e) => {
        //     console.log('error');
        //     this.setState({isRequestLoading: false, isRequestError: true});
        // })

    }

    pobierzPromocjaCenaUpust_PROM_PAK_L = (id: any) => {
        this.setState({
            isRequestLoading: true,
            isRequestError: false,
        });

        PromocjeService.getPromocjaCenaUpust(false, id).then((response) => {

            this.setState({promocjaProgowa: response.data, progi: response.data.progi, isRequestLoading: false, isRequestError: false}, () => this.dodajIle());

        }).catch((e) => {
            console.log('error');
            this.setState({isRequestLoading: false, isRequestError: true});
        })
    }

    pobierzPromocjaProgCenaUpust = (id: any) => {
        this.setState({
            isRequestLoading: true,
            isRequestError: false,
        });

        PromocjeService.getPromocjaPromProgCu(false, id).then((response) => {

            // this.setState({promocjaProgowaCu: response.data, progi: response.data.progi, isRequestLoading: false, isRequestError: false}, () => this.dodajIle());
            this.setState({promocjaProgowaCu: response.data, isRequestLoading: false, isRequestError: false});
            this.sprawdzCzyWKoszyku(response.data);

        }).catch((e) => {
            console.log('error', e);
            this.setState({isRequestLoading: false, isRequestError: true});
        })

    }

    sprawdzCzyWKoszyku = (indeksy: any) => {
        // console.log(indeksy)

        for (const i of indeksy) {
            if(i.koszykIlosc > 0){
                this.setState({wKoszyku: true});
                break;
            } else {
                this.setState({wKoszyku: false});
            }
        }
    }

    pobierzPromocjaProgGratis = (id: any) => {
        this.setState({
            isRequestLoading: true,
            isRequestError: false,
        });

        PromocjeService.getPromocjaPromProgG(false, id).then((response) => {

            // this.setState({promocjaProgowaCu: response.data, progi: response.data.progi, isRequestLoading: false, isRequestError: false}, () => this.dodajIle());
            this.setState({promocjaProgowaG: response.data, isRequestLoading: false, isRequestError: false});
            this.sprawdzCzyWKoszyku(response.data);

        }).catch((e) => {
            console.log('error', e);
            this.setState({isRequestLoading: false, isRequestError: true});
        })

    }

    generateSortString = (): string => {
        let sortowanie = this.state.sort;
        let sortString = '';

        for (let i=0; sortowanie.length > i; i++)
        {
            // @ts-ignore
            sortString = sortString + '&sort=' + sortowanie[i].kolumna + ',' + sortowanie[i].kierunek;
        }

        return sortString;
    }

    pobierzOferte = (params = this.state.params, page = this.state.page, size = this.state.size, sort = this.state.sort) => {

        this.setState({
            // isRequestLoadingOferta: true,
            isRequestErrorOferta: false,
        });

        params.czyEdycja = false;
        params.idProm = this.props.idUm;

        OfertaService.getOferta(page, size, params, this.generateSortString())
            .then((response: any) => {
                    if (response && response.status < 300) {

                        // let ileElementow = sessionStorage.getItem('totalElements');
                        let resp = response.data.content;
                        let prod = [];

                        if (this.props.clickIndeksRow != null){
                            prod.push(this.props.clickIndeksRow);
                            let index = resp.findIndex((produ: any)=> produ.indeks === this.props.clickIndeksRow?.indeks);

                            // console.log('Index:'+index);
                            // console.log('Lenght poberanego:'+resp.length);

                            if(index!=-1){
                                let  usuniety = resp.splice(index,1);
                                // console.log('Usuniety element----------------')
                                // console.log(usuniety);
                                // console.log('Usuniety element----------------')
                                // console.log('Lenght po usunieciu: '+resp.length);
                            }
                        }

                        prod = prod.concat(resp);

                        this.setState({
                            isRequestLoadingOferta: false,
                            isRequestErrorOferta: false,
                            isRequestLoading: false,
                            produkty: prod,
                            loading: false,
                            pageableObject: Object.assign({}, _omit(response.data, "content"), {currentPage: page}, {currentSize: size}, {allElements: response.data.totalElements}),
                            params: Object.assign({}, _omit(params, "params"),{totalElements: response.data.totalElements})
                        });

                        if (page === 99999) {
                            sessionStorage.setItem('totalElements', response.data.totalElements);
                            sessionStorage.setItem('AKTUALIZACJA', JSON.stringify(moment().format('YYYY.MM.DD HH:mm')));
                        }

                    } else {
                        this.setState({
                            isRequestLoadingOferta: false,
                            isRequestErrorOferta: true,
                            loading: false
                        });
                    }
                }
            )
            .catch((error: any) => {
                this.setState({
                    isRequestLoadingOferta: false,
                    isRequestErrorOferta: true,
                    loading: false
                });
            })
    };

    onChangePageInTable = (page: number, size: number) => {
        let strona = page;
        if (strona === 1) {
            strona = 0;
        } else {
            strona = page-1;
        }

        this.setState({
            loading: true,
            page: strona,
            size: size
        }, () => this.pobierzOferte())
    };

    onChangeSizeInTable = (size: number) => {
        localStorage.setItem('FAMIX_CURRENT_TABLESIZE', String(size))
        this.setState({
            size
        }, () => this.pobierzOferte())
    };

    formatZdjecieRow = (cell: string, row: any) => {
        return (
            <div>
                <div data-tip data-for={"punkt" + row.indeks}>
                    <TableImage_WithPreview item={row} desc={'Produkt ' + row.nazwa}/>
                </div>

                <ReactTooltip place="right" type="light" effect="float" id={"punkt" + row.indeks} className={'image-tooltip'} globalEventOff='click'>
                    <div id={'ProductImage'} data-event-off='click'>
                        <Shared_ProductImage item={row} />
                    </div>
                </ReactTooltip>
            </div>
        )
    };

    formatMoneyRow = (cell: number, row: object) => {
        return <TableFormatters_formatMoney value={cell}/>
    };

    formatOpakowanieRow = (cell: any, row: any) => {
        return(
            <div className={'formatOpakowanieRow'}>
                {
                    (row.jm !== row.jm2) &&
                    <div>
                        {row.jm2} ({famix_zaokraglijIloscDlaJM1(row.jm, row.ilJm2)} {row.jm})
                    </div>
                }
                {
                    (row.jm2 !== row.jm3) &&
                    <div>
                        {row.jm3} ({famix_zaokraglijIloscDlaJM1(row.jm, row.ilJm3)} {row.jm})
                    </div>
                }
                {
                    (row.jm === row.jm2 === row.jm3) &&
                    <span>
                        -
                    </span>
                }
            </div>
        )
    };

    ikonaSort = (typ: string) => {
        let {sort} = this.state;
        let index = -1;

        for (let i = 0; sort.length > i; i++)
        {
            if (sort[i].kolumna === typ)
            {
                index = i;
                break;
            }
        }

        if (index !== -1)
        {
            if (sort[index].kierunek === "asc")
            {
                return <i className="fa fa-sort-asc" />
            } else
                return <i className="fa fa-sort-desc" />
        } else
            return <i className="fa fa-sort" style={{color: '#aeaeae'}}/>

    }

    onSortChange = (sortName: any, sortOrder: any) => {

        let sort = changeSortowanie(this.state.sort, sortName);

        this.setState({sort: sort, page: 0, loading: true}, () => this.pobierzOferte());

    }

    odswiezIloscWOfercieLokalnie = (amount: any, item: any, typOpak: string) => {
        let {produkty} = this.state;
        let produkt: any = produkty.find((p: any) => p.indeks === item.indeks);
        if (Boolean(produkt)) {
            produkt.koszyk = amount
            produkt.koszykIlosc = amount
            if(typOpak) produkt.zakupJm = typOpak
        }
        // console.log(typOpak)
        this.setState({produkty, changeOferta: !this.state.changeOferta})
        // this.setState({produkty})

        // @ts-ignore
        this.props.odswierzKoszyk();

        // @ts-ignore
        if(this.props.czyZOferty) this.props.odswiez(amount, item, typOpak);
    };

    odswiezIloscWOfercieLokalniePromPakG = (amount: any, item: any, typOpak: string) => {
        let {produkty} = this.state;
        let produkt: any = produkty.find((p: any) => p.indeks === item.indeks);
        if (Boolean(produkt)) {
            // produkt.koszyk = amount
            produkt.koszykIlosc = amount
            if(typOpak) produkt.zakupJm = typOpak
        }
        // console.log(typOpak)
        this.setState({produkty, changeOferta: !this.state.changeOferta})
        // this.setState({produkty})
        this.sprawdzCzyWKoszyku(produkty);

        // @ts-ignore
        this.props.odswierzKoszyk();

        // @ts-ignore
        if(this.props.czyZOferty) this.props.odswiez(amount, item, typOpak);
    };

    odswiezIloscWOfercieLokalniePromPakPCU = (amount: any, item: any, typOpak: string) => {
        let {produkty} = this.state;
        let produkt: any = produkty.find((p: any) => p.indeks === item.indeks);
        if (Boolean(produkt)) {
            // produkt.koszyk = amount
            produkt.koszykIlosc = amount
            if(typOpak) produkt.zakupJm = typOpak
        }
        // console.log(typOpak)
        this.setState({produkty, changeOferta: !this.state.changeOferta})
        // this.setState({produkty})

        this.sprawdzCzyWKoszyku(produkty);

        // @ts-ignore
        this.props.odswierzKoszyk();

        // @ts-ignore
        if(this.props.czyZOferty) this.props.odswiez(amount, item, typOpak);
    };

    odswiezIloscWOfercieLokalniePPCU = (amount: any, item: any, typOpak: string, cenaProm: any, progAkt: any) => {
        let {promocjaProgowaCu} = this.state;
        let produkt: any = promocjaProgowaCu.find((p: any) => p.indeks === item.indeks);
        if (Boolean(produkt)) {
            produkt.koszyk = amount
            produkt.koszykIlosc = amount
            produkt.cenaProgAkt = cenaProm
            produkt.progAkt = progAkt
            if(typOpak) produkt.zakupJm = typOpak
        }
        // console.log(typOpak)
        this.setState({promocjaProgowaCu, changeOferta: !this.state.changeOferta})
        // this.setState({produkty})

        this.sprawdzCzyWKoszyku(promocjaProgowaCu);

        // @ts-ignore
        this.props.odswierzKoszyk();

        // @ts-ignore
        if(this.props.czyZOferty) this.props.odswiez(amount, item, typOpak);
    };

    odswiezIloscWOfercieLokalniePPG = (amount: any, item: any, typOpak: string, cenaProm: any, progAkt: any) => {
        let {promocjaProgowaG} = this.state;
        let produkt: any = promocjaProgowaG.find((p: any) => p.indeks === item.indeks);
        if (Boolean(produkt)) {
            produkt.koszyk = amount
            produkt.koszykIlosc = amount
            // produkt.cenaProgAkt = cenaProm
            produkt.progAkt = progAkt
            if(typOpak) produkt.zakupJm = typOpak
        }
        // console.log(typOpak)
        this.setState({promocjaProgowaG, changeOferta: !this.state.changeOferta})
        // this.setState({produkty})

        this.sprawdzCzyWKoszyku(promocjaProgowaG);

        // @ts-ignore
        this.props.odswierzKoszyk();

        // @ts-ignore
        //if(this.props.czyZOferty) this.props.odswiez(amount, item, typOpak);
    };

    // odswiezCenePromPPCU = (cenaProm: any, item: any) => {
    //     let {promocjaProgowaCu} = this.state;
    //     let produkt: any = promocjaProgowaCu.find((p: any) => p.indeks === item.indeks);
    //
    //     if (Boolean(produkt)) {
    //         produkt.cena = cenaProm
    //     }
    //
    //     // this.setState({promocjaProgowaCu});
    //     this.setState({promocjaProgowaCu, changeOferta: !this.state.changeOferta})
    //
    //     // // @ts-ignore
    //     // if(this.props.czyZOferty) this.props.odswiez(amount, item, typOpak);
    // };




    columnsProgGratis = [
        {
            dataField: 'indeks',
            text: 'Indeks',
            // headerFormatter: () => {return <>Indeks &nbsp;{this.ikonaSort('indeks')}</>},
            align: 'center',
            headerStyle: {cursor: 'pointer'},
            // formatter: (cell: any, row: any, index: any, extraData: any) => (
            //     {cell}
            //     // <TopIndeksWithBiggerView item={row} zmienInput={this.zmienInput} odswiezIlosc={this.props.odswiezIlosc} edycja={this.props.czyEdycja}><a id='indeks'>{cell}</a></TopIndeksWithBiggerView>
            // ),
            // onSort: this.onSortChange,
            // sort: true
        }, {
            dataField: 'producent',
            text: 'Producent',
            // headerFormatter: () => {return <>Producent &nbsp;{this.ikonaSort('cast(PRODUCENT as varchar(60) character set win1250) collate PXW_PLK ')}</>},
            align: 'center',
            headerStyle: {cursor: 'pointer'},
            // onSort: this.onSortChange,
            // sort: true
        }, {
            dataField: 'nazwa',
            text: 'Nazwa',
            // headerFormatter: () => {return <>Nazwa &nbsp;{this.ikonaSort('cast(NAZWA as varchar(60) character set win1250) collate PXW_PLK ')}</>},
            align: 'center',
            headerStyle: {cursor: 'pointer'},
            // onSort: this.onSortChange,
            // sort: true
        },
        // {
        //     dataField: 'nrKat',
        //     text: 'EAN szt.',
        //     align: 'center'
        // },
        // {
        //     dataField: 'cenaS',
        //     text: 'Cena cennik.',
        //     // headerFormatter: () => {return <>Cena cennik. &nbsp;{this.ikonaSort('cena_s')}</>},
        //     align: 'center',
        //     headerStyle: {cursor: 'pointer'},
        //     formatter: this.formatMoneyRow,
        //     // onSort: this.onSortChange,
        //     // sort: true
        // },
        {
            dataField: 'cena',
            text: 'Cena prom.',
            formatter: this.formatMoneyRow
        }, {
            dataField: 'ilPak',
            text: 'Ilość',
            align: 'center',
            formatter: (cell: any, row: any, index: any, extraData: any) => (
                <>{cell} ({row.jm})</>
            )
        },

        // {
        //     dataField: 'jm',
        //     text: 'Opak / karton',
        //     formatter: this.formatOpakowanieRow,
        //     align: 'center'
        // }
        // , {
        //     dataField: 'zakupJm',
        //     text: 'Koszyk',
        //     headerFormatter: () => {return( <><IconCart/>&nbsp; Koszyk</>)},
        //     style: {width: '145px'},
        //     // formatExtraData: changeProps || this.props.changeOferta,
        //     formatter: (cell: any, row: any, index: any, extraData: any) => (
        //         row.koszykIlosc !== null &&
        //         <Shared_ProductAddToBasketForm item={row} fieldForAmount={'koszykIlosc'} inline={true} odswiezIloscLokalnie={() => console.log('od')} showButton={false} handleChangeInBasket={() => alert('Edycja ilosci w budowie...')}/>
        //     )
        // }
    ];

    rowStyle = (row: any, rowIndex: number) => {
        const style: any = {};

        // if (row.id > 3) {
        //     style.backgroundColor = '#c8e6c9';
        // } else {
        //     style.backgroundColor = '#00BFFF';
        // }

        // if (rowIndex > 2) {
        //     style.fontWeight = 'bold';
        //     style.color = 'white';
        // }

        style.color = '#777777';
        style.backgroundColor = '#E9AA01';

        return style;
    };

    onDodajPakiet = (prog: number) => {
        let idUm = this.props.idUm;
        let edycja = this.state.czyEdycja;
        let ilpak = 1;
        // let ilpak = this.state.progi[prog-1].ile;

        PromocjeService.dodajPakiet(idUm, prog, edycja, ilpak).then((response) => {
            displayNotificationSuccess('Dodano pakiet do koszyka', 'Próg '+prog);
            this.pobierzPromocjaProgowa(idUm);
            this.promocjaProgowaSprawdzKoszyk(idUm);
            // @ts-ignore
            this.props.odswierzKoszyk();
            // this.setState({shouldKoszykUpdate: true});
        }).catch((e) => {
            console.log('error dodaj pakiet')
        })
    }

    onDodajPakietCu = (prog: number) => {
        let idUm = this.props.idUm;
        let edycja = this.state.czyEdycja;
        let ilpak = this.state.progi[prog-1].ile;

        PromocjeService.dodajPakietCu(idUm, prog, edycja, ilpak).then((response) => {
            displayNotificationSuccess('Dodano pakiet do koszyka', 'Próg '+prog);
            // @ts-ignore
            this.props.odswierzKoszyk();
            // this.setState({shouldKoszykUpdate: true});
        }).catch((e) => {
            console.log('error dodaj pakiet')
        })
    }

    formatPromocjaHeader = () => {
        return(<a href={'/oferta'}><div id={'popup-close-icon'} title={'Kliknij, aby wrócić do oferty'}>
                <IconTimes/>
            </div></a>
        )
    };

    onClickFiltruj = (params: any) => {
        this.setState({params: params, page: 0, loading: true}, () => this.pobierzOferte())
    };

    onSelectOrClearValue = (type: string, value: any) => {
        let {params}: any = this.state;
        if (value === null)
            params[type] = null;
        else {
            if (type === 'producent')
                params[type] = value.producent;
            else if (type === 'kategoria')
                params[type] = value.grupa;
            else if (type === 'sezon')
                params[type] = value.grupa;
        }
        this.onClickFiltruj(params);
    };

    onChangeInBasket = (previousAmount: any, amount: any, item: any, opakowanie: string) => {

        if (amount === previousAmount) {
            // brak zmiany
        }
        if (amount === 0 && previousAmount !== 0) {
            this.deleteFromBasket(item);
        }
        if ((amount !== previousAmount && previousAmount === 0) || (amount !== previousAmount && previousAmount > 0 && amount > 0)) {
            // dodano produkt do koszyka lub zmieniono ilość produktu w koszyku

            KoszykService.addOrEditPozycjaKoszyka(Object.assign(item, {ilosc: amount, sprzedazJm: opakowanie, czyEdycja: this.state.czyEdycja}))
                .then((response: any) => {
                    let jm = item.zakupJm;

                    if (response && response.status < 300) {
                        this.setState({shouldKoszykUpdate: true}, () => this.odswiezIloscWOfercieLokalnie(amount, item, opakowanie));
                        if (amount !== previousAmount && previousAmount === 0)  // dodano produkt
                            displayNotificationInfo('Dodano produkt do koszyka', `Dodano produkt ${item.nazwa} w ilości ${amount} ${jm}`)
                        if (amount !== previousAmount && previousAmount > 0 && amount > 0)  // edytowano produkt
                            displayNotificationInfo('Zmieniono ilość produktu w koszyku', `Zmieniono ilość produktu ${item.nazwa} z ${previousAmount} na ${amount} ${jm}`)

                    } else {
                        if (amount !== previousAmount && previousAmount === 0)
                            displayNotificationError("Nie dodano produktu do koszyka", "Wystąpił błąd podczas próby wykonania akcji")
                        if (amount !== previousAmount && previousAmount > 0 && amount > 0)
                            displayNotificationError("Nie edytowano ilości produktu w koszyka", "Wystąpił błąd podczas próby wykonania akcji")
                    }

                })
                .catch((error: any) => {
                    if (amount !== previousAmount && previousAmount === 0)
                        displayNotificationError("Nie dodano produktu do koszyka", "Wystąpił błąd podczas próby wykonania akcji")
                    if (amount !== previousAmount && previousAmount > 0 && amount > 0)
                        displayNotificationError("Nie edytowano ilości produktu w koszyka", "Wystąpił błąd podczas próby wykonania akcji")
                })
        }
    };

    onChangeInBasketPromPakPCU = (previousAmount: any, amount: any, item: any, opakowanie: string) => {

        if (amount === previousAmount) {
            // brak zmiany
        }
        if (amount === 0 && previousAmount !== 0) {
            KoszykService.addOrEditPozycjaKoszykaPromPakPCU(Object.assign(item, {ilosc: 0, sprzedazJm: item.zakupJm, czyEdycja: this.state.czyEdycja}))
                .then((response: any) => {
                    if (response && response.status < 300) {
                        this.setState({shouldKoszykUpdate: true, progi: response.data}, () => {this.odswiezIloscWOfercieLokalniePromPakPCU(0, item, item.zakupJm); }); //this.pobierzPodsumowanieKoszyka();
                        displayNotificationInfo('Usunięto produkt z koszyka', 'Usunięto produkt o nazwie ' + item.nazwa)
                    } else {
                        displayNotificationError("Nie usunięto produktu z koszyka", "Wystąpił błąd podczas próby wykonania akcji")
                    }

                })
                .catch((error: any) => {
                    displayNotificationError("Wystąpił błąd podczas próby usunięcia produktu z koszyka", error.message)
                })
        }
        if ((amount !== previousAmount && previousAmount === 0) || (amount !== previousAmount && previousAmount > 0 && amount > 0)) {
            // dodano produkt do koszyka lub zmieniono ilość produktu w koszyku

            KoszykService.addOrEditPozycjaKoszykaPromPakPCU(Object.assign(item, {ilosc: amount, sprzedazJm: opakowanie, czyEdycja: this.state.czyEdycja}))
                .then((response: any) => {
                    let jm = item.zakupJm;

                    if (response && response.status < 300) {
                        this.setState({shouldKoszykUpdate: true, progi: response.data}, () => this.odswiezIloscWOfercieLokalniePromPakPCU(amount, item, opakowanie));
                        if (amount !== previousAmount && previousAmount === 0)  // dodano produkt
                            displayNotificationInfo('Dodano produkt do koszyka', `Dodano produkt ${item.nazwa} w ilości ${amount} ${jm}`)
                        if (amount !== previousAmount && previousAmount > 0 && amount > 0)  // edytowano produkt
                            displayNotificationInfo('Zmieniono ilość produktu w koszyku', `Zmieniono ilość produktu ${item.nazwa} z ${previousAmount} na ${amount} ${jm}`)

                    } else {
                        if (amount !== previousAmount && previousAmount === 0)
                            displayNotificationError("Nie dodano produktu do koszyka", "Wystąpił błąd podczas próby wykonania akcji")
                        if (amount !== previousAmount && previousAmount > 0 && amount > 0)
                            displayNotificationError("Nie edytowano ilości produktu w koszyka", "Wystąpił błąd podczas próby wykonania akcji")
                    }

                })
                .catch((error: any) => {
                    if (amount !== previousAmount && previousAmount === 0)
                        displayNotificationError("Nie dodano produktu do koszyka", "Wystąpił błąd podczas próby wykonania akcji")
                    if (amount !== previousAmount && previousAmount > 0 && amount > 0)
                        displayNotificationError("Nie edytowano ilości produktu w koszyka", "Wystąpił błąd podczas próby wykonania akcji")
                })
        }
    };

    onChangeInBasketPromPakG = (previousAmount: any, amount: any, item: any, opakowanie: string) => {

        if (amount === previousAmount) {
            // brak zmiany
        }
        if (amount === 0 && previousAmount !== 0) {
            KoszykService.addOrEditPozycjaKoszykaPromPakG(Object.assign(item, {ilosc: 0, sprzedazJm: item.zakupJm, czyEdycja: this.state.czyEdycja}))
                .then((response: any) => {
                    if (response && response.status < 300) {
                        this.setState({shouldKoszykUpdate: true, progi: response.data}, () => {this.odswiezIloscWOfercieLokalniePromPakG(0, item, item.zakupJm); }); //this.pobierzPodsumowanieKoszyka();
                        displayNotificationInfo('Usunięto produkt z koszyka', 'Usunięto produkt o nazwie ' + item.nazwa);
                    } else {
                        displayNotificationError("Nie usunięto produktu z koszyka", "Wystąpił błąd podczas próby wykonania akcji")
                    }

                })
                .catch((error: any) => {
                    displayNotificationError("Wystąpił błąd podczas próby usunięcia produktu z koszyka", error.message)
                })
        }
        if ((amount !== previousAmount && previousAmount === 0) || (amount !== previousAmount && previousAmount > 0 && amount > 0)) {
            // dodano produkt do koszyka lub zmieniono ilość produktu w koszyku

            KoszykService.addOrEditPozycjaKoszykaPromPakG(Object.assign(item, {ilosc: amount, sprzedazJm: opakowanie, czyEdycja: this.state.czyEdycja}))
                .then((response: any) => {
                    let jm = item.zakupJm;

                    if (response && response.status < 300) {
                        this.setState({shouldKoszykUpdate: true, progi: response.data}, () => this.odswiezIloscWOfercieLokalniePromPakG(amount, item, opakowanie));
                        if (amount !== previousAmount && previousAmount === 0)  // dodano produkt
                            displayNotificationInfo('Dodano produkt do koszyka', `Dodano produkt ${item.nazwa} w ilości ${amount} ${jm}`)
                        if (amount !== previousAmount && previousAmount > 0 && amount > 0)  // edytowano produkt
                            displayNotificationInfo('Zmieniono ilość produktu w koszyku', `Zmieniono ilość produktu ${item.nazwa} z ${previousAmount} na ${amount} ${jm}`)

                    } else {
                        if (amount !== previousAmount && previousAmount === 0)
                            displayNotificationError("Nie dodano produktu do koszyka", "Wystąpił błąd podczas próby wykonania akcji")
                        if (amount !== previousAmount && previousAmount > 0 && amount > 0)
                            displayNotificationError("Nie edytowano ilości produktu w koszyka", "Wystąpił błąd podczas próby wykonania akcji")
                    }

                })
                .catch((error: any) => {
                    if (amount !== previousAmount && previousAmount === 0)
                        displayNotificationError("Nie dodano produktu do koszyka", "Wystąpił błąd podczas próby wykonania akcji")
                    if (amount !== previousAmount && previousAmount > 0 && amount > 0)
                        displayNotificationError("Nie edytowano ilości produktu w koszyka", "Wystąpił błąd podczas próby wykonania akcji")
                })
        }
    };

    deleteFromBasket = (item: any) => {
        KoszykService.addOrEditPozycjaKoszyka(Object.assign(item, {ilosc: 0, sprzedazJm: item.zakupJm, czyEdycja: this.state.czyEdycja}))
            .then((response: any) => {
                if (response && response.status < 300) {
                    this.setState({shouldKoszykUpdate: true}, () => {this.odswiezIloscWOfercieLokalnie(0, item, item.zakupJm); }); //this.pobierzPodsumowanieKoszyka();
                    displayNotificationInfo('Usunięto produkt z koszyka', 'Usunięto produkt o nazwie ' + item.nazwa)
                } else {
                    displayNotificationError("Nie usunięto produktu z koszyka", "Wystąpił błąd podczas próby wykonania akcji")
                }

            })
            .catch((error: any) => {
                displayNotificationError("Wystąpił błąd podczas próby usunięcia produktu z koszyka", error.message)
            })
    };

    onChangeInBasketPPCU = (previousAmount: any, amount: any, item: any, opakowanie: string) => {

        if (amount === previousAmount) {
            // brak zmiany
        }
        if (amount === 0 && previousAmount !== 0) {
            this.deleteFromBasketPPCU(item);
        }
        if ((amount !== previousAmount && previousAmount === 0) || (amount !== previousAmount && previousAmount > 0 && amount > 0)) {
            // dodano produkt do koszyka lub zmieniono ilość produktu w koszyku

            PromocjeService.addEditItemPromProgCu(Object.assign(item, {ilosc: amount, sprzedazJm: opakowanie, czyEdycja: this.state.czyEdycja}))
                .then((response: any) => {
                    let jm = item.zakupJm;

                    if (response && response.status < 300) {
                        this.setState({shouldKoszykUpdate: true}, () => this.odswiezIloscWOfercieLokalniePPCU(amount, item, opakowanie, response.data.cena, response.data.prog));
                        if (amount !== previousAmount && previousAmount === 0)  // dodano produkt
                            displayNotificationInfo('Dodano produkt do koszyka', `Dodano produkt ${item.nazwa} w ilości ${amount} ${jm}`)
                        if (amount !== previousAmount && previousAmount > 0 && amount > 0)  // edytowano produkt
                            displayNotificationInfo('Zmieniono ilość produktu w koszyku', `Zmieniono ilość produktu ${item.nazwa} z ${previousAmount} na ${amount} ${jm}`)

                    } else {
                        if (amount !== previousAmount && previousAmount === 0)
                            displayNotificationError("Nie dodano produktu do koszyka", "Wystąpił błąd podczas próby wykonania akcji")
                        if (amount !== previousAmount && previousAmount > 0 && amount > 0)
                            displayNotificationError("Nie edytowano ilości produktu w koszyka", "Wystąpił błąd podczas próby wykonania akcji")
                    }

                })
                .catch((error: any) => {
                    if (amount !== previousAmount && previousAmount === 0)
                        displayNotificationError("Nie dodano produktu do koszyka", "Wystąpił błąd podczas próby wykonania akcji")
                    if (amount !== previousAmount && previousAmount > 0 && amount > 0)
                        displayNotificationError("Nie edytowano ilości produktu w koszyka", "Wystąpił błąd podczas próby wykonania akcji")
                })
        }
    };

    deleteFromBasketPPCU = (item: any) => {
        PromocjeService.addEditItemPromProgCu(Object.assign(item, {ilosc: 0, sprzedazJm: item.zakupJm, czyEdycja: this.state.czyEdycja}))
            .then((response: any) => {
                if (response && response.status < 300) {
                    this.setState({shouldKoszykUpdate: true}, () => {this.odswiezIloscWOfercieLokalniePPCU(0, item, item.zakupJm, response.cena, response.data.prog); }); //this.pobierzPodsumowanieKoszyka();
                    displayNotificationInfo('Usunięto produkt z koszyka', 'Usunięto produkt o nazwie ' + item.nazwa)
                } else {
                    displayNotificationError("Nie usunięto produktu z koszyka", "Wystąpił błąd podczas próby wykonania akcji")
                }

            })
            .catch((error: any) => {
                displayNotificationError("Wystąpił błąd podczas próby usunięcia produktu z koszyka", error.message)
            })
    };

    onChangeInBasketPPG = (previousAmount: any, amount: any, item: any, opakowanie: string) => {

        if (amount === previousAmount) {
            // brak zmiany
        }
        if (amount === 0 && previousAmount !== 0) {
            this.deleteFromBasketPPG(item);
        }
        if ((amount !== previousAmount && previousAmount === 0) || (amount !== previousAmount && previousAmount > 0 && amount > 0)) {
            // dodano produkt do koszyka lub zmieniono ilość produktu w koszyku

            PromocjeService.addEditItemPromProgG(Object.assign(item, {iloscWpr: amount, listaIdUm: null, punkty: 0, sprzedazJm: opakowanie, czyEdycja: this.state.czyEdycja}))
                .then((response: any) => {
                    let jm = item.zakupJm;

                    this.setState({shouldKoszykUpdate: true}, () => this.odswiezIloscWOfercieLokalniePPG(amount, item, opakowanie, response.data.cena, response.data.prog));
                    if (amount !== previousAmount && previousAmount === 0)  // dodano produkt
                        displayNotificationInfo('Dodano produkt do koszyka', `Dodano produkt ${item.nazwa} w ilości ${amount} ${jm}`)
                    if (amount !== previousAmount && previousAmount > 0 && amount > 0)  // edytowano produkt
                        displayNotificationInfo('Zmieniono ilość produktu w koszyku', `Zmieniono ilość produktu ${item.nazwa} z ${previousAmount} na ${amount} ${jm}`)

                })
                .catch((error: any) => {
                    if (amount !== previousAmount && previousAmount === 0)
                        displayNotificationError("Nie dodano produktu do koszyka", "Wystąpił błąd podczas próby wykonania akcji")
                    if (amount !== previousAmount && previousAmount > 0 && amount > 0)
                        displayNotificationError("Nie edytowano ilości produktu w koszyka", "Wystąpił błąd podczas próby wykonania akcji")
                })
        }
    };

    deleteFromBasketPPG = (item: any) => {
        PromocjeService.addEditItemPromProgG(Object.assign(item, {iloscWpr: 0, listaIdUm: null, punkty: 0, sprzedazJm: item.zakupJm, czyEdycja: this.state.czyEdycja}))
            .then((response: any) => {

                this.setState({shouldKoszykUpdate: true}, () => {this.odswiezIloscWOfercieLokalniePPG(0, item, item.zakupJm, response.cena, response.data.prog); }); //this.pobierzPodsumowanieKoszyka();
                displayNotificationInfo('Usunięto produkt z koszyka', 'Usunięto produkt o nazwie ' + item.nazwa)

            })
            .catch((error: any) => {
                displayNotificationError("Wystąpił błąd podczas próby usunięcia produktu z koszyka", error.message)
            })
    };

    onChangeIlosc = (index: number, e: any) => {
        let progi = this.state.progi;

        progi[index].ile = e;

        this.setState({progi});
    }

    columns = [{
        dataField: 'imagePath',
        text: 'Zdjęcie',
        formatter: this.formatZdjecieRow
    }, {
        dataField: 'indeks',
        text: 'Indeks',
        headerFormatter: () => {return <>Indeks &nbsp;{this.ikonaSort('indeks')}</>},
        align: 'center',
        headerStyle: {cursor: 'pointer'},
        onSort: this.onSortChange,
        sort: true
    }, {
        dataField: 'producent',
        text: 'Producent',
        headerFormatter: () => {return <>Producent &nbsp;{this.ikonaSort('cast(PRODUCENT as varchar(60) character set win1250) collate PXW_PLK ')}</>},
        align: 'center',
        headerStyle: {cursor: 'pointer'},
        onSort: this.onSortChange,
        sort: true
    }, {
        dataField: 'nazwaPokaz',
        text: 'Nazwa',
        headerFormatter: () => {return <>Nazwa &nbsp;{this.ikonaSort('cast(NAZWA as varchar(60) character set win1250) collate PXW_PLK ')}</>},
        align: 'center',
        headerStyle: {cursor: 'pointer'},
        onSort: this.onSortChange,
        sort: true
    }, {
        dataField: 'nrKat',
        text: 'EAN szt.',
        headerFormatter: () => {return <>EAN szt. &nbsp;{this.ikonaSort('nr_kat')}</>},
        align: 'center',
        headerStyle: {cursor: 'pointer'},
        onSort: this.onSortChange,
        sort: true
    }, {
        dataField: 'cenaS',
        text: 'Cena cennik.',
        headerFormatter: () => {return <>Cena cennik. &nbsp;{this.ikonaSort('cena_s')}</>},
        align: 'center',
        headerStyle: {cursor: 'pointer'},
        formatter: this.formatMoneyRow,
        onSort: this.onSortChange,
        sort: true
    }, {
        dataField: 'cena',
        text: 'Cena prom.',
        headerFormatter: () => {return <>Cena prom. &nbsp;{this.ikonaSort('cena')}</>},
        headerStyle: {cursor: 'pointer'},
        formatter: this.formatMoneyRow,
        onSort: this.onSortChange,
        sort: true
    }, {
        dataField: 'jm',
        text: 'Opak / karton',
        formatter: this.formatOpakowanieRow
    }, {
        dataField: 'zakupJm',
        text: 'Koszyk',
        headerFormatter: () => {return( <><IconCart/>&nbsp; Koszyk&nbsp;{this.ikonaSort('koszyk_ilosc')}</>)},
        style: {width: '145px'},
        // formatExtraData: this.state.changeOferta,
        formatter: (cell: any, row: any, index: any, extraData: any) => (
            // <Shared_ProductAddToBasketForm item={row} fieldForAmount={'koszykIlosc'} inline={true} odswiezIloscLokalnie={() => console.log('od')} showButton={false} alwaysDostepne={true} handleChangeInBasket={() => alert('Edycja ilosci w budowie...')}/>
            // @ts-ignore
            <Shared_ProductAddToBasketForm item={row} fieldForAmount={'koszykIlosc'} alwaysDostepne={true} inline={true} odswiezIloscLokalnie={this.odswiezIloscWOfercieLokalnie} showButton={false} handleChangeInBasket={this.onChangeInBasket}/>
        ),
        headerStyle: {cursor: 'pointer'},
        onSort: this.onSortChange,
        sort: true
    }];

    columnsPPCU = [{
        dataField: 'indeks',
        text: 'Indeks',
        align: 'center'
    }, {
        dataField: 'producent',
        text: 'Producent',
        align: 'center'
    }, {
        dataField: 'nazwa',
        text: 'Nazwa',
        align: 'center'
    }, {
        dataField: 'nrKat',
        text: 'EAN szt.',
        align: 'center'
    }, {
        dataField: 'cenaS',
        text: 'Cena cennik.',
        align: 'center',
        formatter: this.formatMoneyRow
    }, {
        dataField: 'cenaProgAkt',
        text: 'Cena prom.',
        formatter: this.formatMoneyRow
    },
    //     {
    //     dataField: 'jm',
    //     text: 'Opak / karton',
    //     align: 'center',
    //     formatter: this.formatOpakowanieRow
    // },
        {
        dataField: 'progi',
        text: 'Progi',
        align: 'center',
        formatter: (cell: any, row: any, index: any, extraData: any) => (
            cell.filter((c: any) => c.prog !== 0).map((e: any, index: number) => {
                return <div className={(row.progAkt === e.prog)? 'progAkt' : ''}>{e.progIl} {row.jm} - {formatMoney(e.cena)}<br/></div>
            })
        )
    }, {
        dataField: 'zakupJm',
        text: 'Koszyk',
        style: {width: '145px'},
        // formatExtraData: this.state.changeOferta,
        formatter: (cell: any, row: any, index: any, extraData: any) => (
            // <Shared_ProductAddToBasketForm item={row} fieldForAmount={'koszykIlosc'} inline={true} odswiezIloscLokalnie={() => console.log('od')} showButton={false} alwaysDostepne={true} handleChangeInBasket={() => alert('Edycja ilosci w budowie...')}/>
            // @ts-ignore
            <Shared_ProductAddToBasketForm item={row} fieldForAmount={'koszykIlosc'} alwaysDostepne={true} inline={true} odswiezIloscLokalnie={this.odswiezIloscWOfercieLokalniePPCU} showButton={false} handleChangeInBasket={this.onChangeInBasketPPCU}/>
        )
    }];

    expandRowPPCU = {
        renderer: (row: any) => (
            <div>
                <p>{ `This Expand row is belong to rowKey ${row.indeks}` }</p>
            </div>
        ),
        // expanded: [this.state.promocjaProgowaCu && this.state.promocjaProgowaCu.map((i: any) => i.indeks)],
        // showExpandColumn: true
    };

    columnsPPG = [{
        dataField: 'indeks',
        text: 'Indeks',
        align: 'center'
    }, {
        dataField: 'producent',
        text: 'Producent',
        align: 'center'
    }, {
        dataField: 'nazwa',
        text: 'Nazwa',
        align: 'center'
    }, {
        dataField: 'nrKat',
        text: 'EAN szt.',
        align: 'center'
    }, {
        dataField: 'cenaS',
        text: 'Cena cennik.',
        align: 'center',
        formatter: this.formatMoneyRow
    }, {
        dataField: 'cena',
        text: 'Cena prom.',
        formatter: this.formatMoneyRow
    },
        //     {
        //     dataField: 'jm',
        //     text: 'Opak / karton',
        //     align: 'center',
        //     formatter: this.formatOpakowanieRow
        // },
        {
            dataField: 'gratisy',
            text: 'Progi',
            align: 'center',
            formatter: (cell: any, row: any, id: any, extraData: any) => (
                cell.map((e: any, index: number) => {
                    // return <div className={(row.progAkt === e.prog)? 'progAkt' : ''}>{e.progIl} {row.jm} - {e.nazwa}<br/></div>
                    return <><div data-tip data-for={"gratis" + e.indeks + index + id} className={(row.progAkt === e.prog)? 'ppg-prog progAkt' : 'ppg-prog'}>
                        <div>{e.progIl} {row.jm} - {e.nazwa}<br/></div>
                    </div>

                        {/*<ReactTooltip place="right" type="light" effect="float" id={"punkt" + row.indeks} className={'image-tooltip'} globalEventOff='click'>*/}
                    <ReactTooltip id={"gratis" + e.indeks + index + id} place='left' effect='solid'
                        // delayHide={500}
                                  delayShow={200}
                        // delayUpdate={500}
                        // backgroundColor={"#605D8D"}
                                  border={true}
                                  className={'prog-tooltip'}
                                  type="light"
                    >
                        {
                            (row.progAkt === e.prog)?
                                <p>OTRZYMAŁEŚ GRATIS</p>
                                :
                                <p>Jeśli kupisz {e.progIl} {row.jm}, otrzymasz:</p>
                        }
                        <p style={{whiteSpace: 'pre-line', color: 'white', margin: '0'}}><div>{e.nazwa}<br/></div></p>
                    </ReactTooltip>
                     </>
                    // return <div className={(row.progAkt === e.prog)? 'progAkt' : ''}>{e.progIl} {row.jm} - {formatMoney(e.cena)}<br/></div>
                    // <div>{e.progIl} {row.jm} - {e.nazwa}<br/></div>
                })
            )
        },
        {
            dataField: 'zakupJm',
            text: 'Koszyk',
            style: {width: '145px'},
            // formatExtraData: this.state.changeOferta,
            formatter: (cell: any, row: any, index: any, extraData: any) => (
                // <Shared_ProductAddToBasketForm item={row} fieldForAmount={'koszykIlosc'} inline={true} odswiezIloscLokalnie={() => console.log('od')} showButton={false} alwaysDostepne={true} handleChangeInBasket={() => alert('Edycja ilosci w budowie...')}/>
                // @ts-ignore
                // <Shared_ProductAddToBasketForm item={row} fieldForAmount={'koszykIlosc'} alwaysDostepne={true} inline={true} odswiezIloscLokalnie={() => alert('w budowie..')} showButton={false} handleChangeInBasket={() => alert('w budowie..')}/>
                <Shared_ProductAddToBasketForm item={row} fieldForAmount={'koszykIlosc'} alwaysDostepne={true} inline={true} odswiezIloscLokalnie={this.odswiezIloscWOfercieLokalniePPG} showButton={false} handleChangeInBasket={this.onChangeInBasketPPG}/>
            )
        }];

    columnsProg = [
        {
            dataField: 'indeks',
            text: 'Indeks',
            // headerFormatter: () => {return <>Indeks &nbsp;{this.ikonaSort('indeks')}</>},
            align: 'center',
            headerStyle: {cursor: 'pointer'},
            // formatter: (cell: any, row: any, index: any, extraData: any) => (
            //     {cell}
            //     // <TopIndeksWithBiggerView item={row} zmienInput={this.zmienInput} odswiezIlosc={this.props.odswiezIlosc} edycja={this.props.czyEdycja}><a id='indeks'>{cell}</a></TopIndeksWithBiggerView>
            // ),
            // onSort: this.onSortChange,
            // sort: true
        }, {
            dataField: 'producent',
            text: 'Producent',
            // headerFormatter: () => {return <>Producent &nbsp;{this.ikonaSort('cast(PRODUCENT as varchar(60) character set win1250) collate PXW_PLK ')}</>},
            align: 'center',
            headerStyle: {cursor: 'pointer'},
            // onSort: this.onSortChange,
            // sort: true
        }, {
            dataField: 'nazwa',
            text: 'Nazwa',
            // headerFormatter: () => {return <>Nazwa &nbsp;{this.ikonaSort('cast(NAZWA as varchar(60) character set win1250) collate PXW_PLK ')}</>},
            align: 'center',
            headerStyle: {cursor: 'pointer'},
            // onSort: this.onSortChange,
            // sort: true
        }, {
            dataField: 'nrKat',
            text: 'EAN szt.',
            align: 'center'
        }, {
            dataField: 'cenaS',
            text: 'Cena cennik.',
            // headerFormatter: () => {return <>Cena cennik. &nbsp;{this.ikonaSort('cena_s')}</>},
            align: 'center',
            headerStyle: {cursor: 'pointer'},
            formatter: this.formatMoneyRow,
            // onSort: this.onSortChange,
            // sort: true
        }, {
            dataField: 'cena',
            text: 'Cena prom.',
            formatter: this.formatMoneyRow
        }, {
            dataField: 'ilPak',
            text: 'Min. ilość',
            align: 'center',
            formatter: (cell: any, row: any, index: any, extraData: any) => (
                <>{cell} ({row.jm})</>
            )
        },
        {
            dataField: 'jm',
            text: 'Opak / karton',
            formatter: this.formatOpakowanieRow,
            align: 'center'
        }
        // , {
        //     dataField: 'zakupJm',
        //     text: 'Koszyk',
        //     headerFormatter: () => {return( <><IconCart/>&nbsp; Koszyk</>)},
        //     style: {width: '145px'},
        //     // formatExtraData: changeProps || this.props.changeOferta,
        //     formatter: (cell: any, row: any, index: any, extraData: any) => (
        //         row.koszykIlosc !== null &&
        //         <Shared_ProductAddToBasketForm item={row} fieldForAmount={'koszykIlosc'} inline={true} odswiezIloscLokalnie={() => console.log('od')} showButton={false} handleChangeInBasket={() => alert('Edycja ilosci w budowie...')}/>
        //     )
        // }
    ];



    columnsPromPakG = [
        {
            dataField: 'indeks',
            text: 'Indeks',
            // headerFormatter: () => {return <>Indeks &nbsp;{this.ikonaSort('indeks')}</>},
            align: 'center',
            headerStyle: {cursor: 'pointer'},
            formatter: ((cell: any, row: any) => {return <div>{(row.obow === 1) && <span style={{color: 'red', fontWeight: 'bold'}}>* </span>}{cell}</div>})
            // formatter: (cell: any, row: any, index: any, extraData: any) => (
            //     {cell}
            //     // <TopIndeksWithBiggerView item={row} zmienInput={this.zmienInput} odswiezIlosc={this.props.odswiezIlosc} edycja={this.props.czyEdycja}><a id='indeks'>{cell}</a></TopIndeksWithBiggerView>
            // ),
            // onSort: this.onSortChange,
            // sort: true
        }, {
            dataField: 'producent',
            text: 'Producent',
            // headerFormatter: () => {return <>Producent &nbsp;{this.ikonaSort('cast(PRODUCENT as varchar(60) character set win1250) collate PXW_PLK ')}</>},
            align: 'center',
            headerStyle: {cursor: 'pointer'},
            // onSort: this.onSortChange,
            // sort: true
        }, {
            dataField: 'nazwa',
            text: 'Nazwa',
            // headerFormatter: () => {return <>Nazwa &nbsp;{this.ikonaSort('cast(NAZWA as varchar(60) character set win1250) collate PXW_PLK ')}</>},
            align: 'center',
            headerStyle: {cursor: 'pointer'},
            // onSort: this.onSortChange,
            // sort: true
        }, {
            dataField: 'nrKat',
            text: 'EAN szt.',
            align: 'center'
        }, {
            dataField: 'cenaS',
            text: 'Cena cennik.',
            // headerFormatter: () => {return <>Cena cennik. &nbsp;{this.ikonaSort('cena_s')}</>},
            align: 'center',
            headerStyle: {cursor: 'pointer'},
            formatter: this.formatMoneyRow,
            // onSort: this.onSortChange,
            // sort: true
        }, {
            dataField: 'cena',
            text: 'Cena prom.',
            formatter: this.formatMoneyRow
        }, {
            dataField: 'progi',
            text: 'Progi',
            align: 'center',
            // formatter: (cell: any, row: any, index: any, extraData: any) => (
            //     <>{cell} ({row.jm})</>
            // )
            formatter: (cell: any, row: any, id: any, extraData: any) => (
                // (row.obow === 1)?
                cell.map((e: any, index: number) => {
                    // return <div className={(row.progAkt === e.prog)? 'progAkt' : ''}>{e.progIl} {row.jm} - {e.nazwa}<br/></div>
                    // className={(this.ktoryProg(this.state.progi) === e.prog)? 'ppg-prog progAkt' : 'ppg-prog'}
                    return <><div data-tip data-for={"prog" + e.prog + id} className={'ppg-prog'}>
                        <div>Próg {e.prog}<br/></div>
                    </div>

                        {/*<ReactTooltip place="right" type="light" effect="float" id={"punkt" + row.indeks} className={'image-tooltip'} globalEventOff='click'>*/}
                        <ReactTooltip id={"prog" + e.prog + id} place='left' effect='solid'
                            // delayHide={500}
                                      delayShow={200}
                            // delayUpdate={500}
                            // backgroundColor={"#605D8D"}
                                      border={true}
                                      className={'prog-tooltip'}
                                      type="light"
                        >
                            <h6>Warunek:</h6>
                            {(!KoszykService.isEmpty(e.progIl))&& <p style={{whiteSpace: 'pre-line', color: 'white', margin: '0'}}><div>Ilościowy: {e.progIl} {row.jm}<br/></div></p>}
                            {(!KoszykService.isEmpty(e.progWr))&&<p style={{whiteSpace: 'pre-line', color: 'white', margin: '0'}}><div>Wartościowy: <TableFormatters_formatMoney value={e.progWr}/></div></p>}
                        </ReactTooltip>
                    </>
                    // return <div className={(row.progAkt === e.prog)? 'progAkt' : ''}>{e.progIl} {row.jm} - {formatMoney(e.cena)}<br/></div>
                    // <div>{e.progIl} {row.jm} - {e.nazwa}<br/></div>
                })
                // :
                // <></>
            )
        }, {
            dataField: 'jm',
            text: 'Opak / karton',
            formatter: this.formatOpakowanieRow
        }
        // , {
        //     dataField: 'jm',
        //     text: 'Opak / karton',
        //     formatter: this.formatOpakowanieRow,
        //     align: 'center'
        // }
        // ,{
        //     dataField: 'zakupJm',
        //     text: 'Koszyk',
        //     style: {width: '145px'},
        //     // formatExtraData: this.state.changeOferta,
        //     formatter: (cell: any, row: any, index: any, extraData: any) => (
        //         // <Shared_ProductAddToBasketForm item={row} fieldForAmount={'koszykIlosc'} inline={true} odswiezIloscLokalnie={() => console.log('od')} showButton={false} alwaysDostepne={true} handleChangeInBasket={() => alert('Edycja ilosci w budowie...')}/>
        //         // @ts-ignore
        //         // <Shared_ProductAddToBasketForm item={row} fieldForAmount={'koszykIlosc'} alwaysDostepne={true} inline={true} odswiezIloscLokalnie={() => alert('w budowie..')} showButton={false} handleChangeInBasket={() => alert('w budowie..')}/>
        //         <Shared_ProductAddToBasketForm item={row} fieldForAmount={'koszykIlosc'} alwaysDostepne={true} inline={true} odswiezIloscLokalnie={this.odswiezIloscWOfercieLokalniePPG} showButton={false} handleChangeInBasket={this.onChangeInBasketPPG}/>
        //     )
        // }
        , {
            dataField: 'koszykIlosc',
            text: 'Koszyk',
            // headerFormatter: () => {return( <><IconCart/>&nbsp; Koszyk</>)},
            style: {width: '145px'},
            // formatExtraData: changeProps || this.props.changeOferta,
            formatter: (cell: any, row: any, index: any, extraData: any) => {return(
                row.koszykIlosc !== null &&
                // <Shared_ProductAddToBasketForm item={row} fieldForAmount={'koszykIlosc'} inline={true} odswiezIloscLokalnie={() => console.log('od')} showButton={false} handleChangeInBasket={() => alert('Edycja ilosci w budowie...')}/>
                // @ts-ignore
                //<Shared_ProductAddToBasketForm item={row} fieldForAmount={'koszykIlosc'} alwaysDostepne={true} inline={true} odswiezIloscLokalnie={this.odswiezIloscWOfercieLokalniePPG} showButton={false} handleChangeInBasket={this.onChangeInBasketPPG}/>
                <Shared_ProductAddToBasketForm item={row} fieldForAmount={'koszykIlosc'} alwaysDostepne={true} inline={true} odswiezIloscLokalnie={this.odswiezIloscWOfercieLokalniePromPakG} showButton={false} handleChangeInBasket={this.onChangeInBasketPromPakG}/>
            )}
        }
    ];

    columnsProgPCU = [
        {
            dataField: 'indeks',
            text: 'Indeks',
            // headerFormatter: () => {return <>Indeks &nbsp;{this.ikonaSort('indeks')}</>},
            align: 'center',
            headerStyle: {cursor: 'pointer'},
            // formatter: (cell: any, row: any, index: any, extraData: any) => (
            //     {cell}
            //     // <TopIndeksWithBiggerView item={row} zmienInput={this.zmienInput} odswiezIlosc={this.props.odswiezIlosc} edycja={this.props.czyEdycja}><a id='indeks'>{cell}</a></TopIndeksWithBiggerView>
            // ),
            // onSort: this.onSortChange,
            // sort: true
        }, {
            dataField: 'producent',
            text: 'Producent',
            // headerFormatter: () => {return <>Producent &nbsp;{this.ikonaSort('cast(PRODUCENT as varchar(60) character set win1250) collate PXW_PLK ')}</>},
            align: 'center',
            headerStyle: {cursor: 'pointer'},
            // onSort: this.onSortChange,
            // sort: true
        }, {
            dataField: 'nazwa',
            text: 'Nazwa',
            // headerFormatter: () => {return <>Nazwa &nbsp;{this.ikonaSort('cast(NAZWA as varchar(60) character set win1250) collate PXW_PLK ')}</>},
            align: 'center',
            headerStyle: {cursor: 'pointer'},
            // onSort: this.onSortChange,
            // sort: true
        }, {
            dataField: 'nrKat',
            text: 'EAN szt.',
            align: 'center'
        }, {
            dataField: 'cenaS',
            text: 'Cena cennik.',
            // headerFormatter: () => {return <>Cena cennik. &nbsp;{this.ikonaSort('cena_s')}</>},
            align: 'center',
            headerStyle: {cursor: 'pointer'},
            formatter: this.formatMoneyRow,
            // onSort: this.onSortChange,
            // sort: true
        }, {
            dataField: 'cena',
            text: 'Cena sprz.',
            formatter: this.formatMoneyRow
        }, {
            dataField: 'progi',
            text: 'Progi',
            align: 'center',
            // formatter: (cell: any, row: any, index: any, extraData: any) => (
            //     <>{cell} ({row.jm})</>
            // )
            formatter: (cell: any, row: any, id: any, extraData: any) => (
                // (row.obow === 1)?
                cell.filter((c: any) => c.prog !== 0).map((e: any, index: number) => {
                    // return <div className={(row.progAkt === e.prog)? 'progAkt' : ''}>{e.progIl} {row.jm} - {e.nazwa}<br/></div>
                    // className={(this.ktoryProg(this.state.progi) === e.prog)? 'ppg-prog progAkt' : 'ppg-prog'}
                    return <><div data-tip data-for={"prog" + e.prog + id} className={'ppg-prog'}>
                        <div>Próg {e.prog} - <div style={{display: 'inline-block'}}><TableFormatters_formatMoney value={e.cena}/></div><br/></div>
                    </div>

                        {/*<ReactTooltip place="right" type="light" effect="float" id={"punkt" + row.indeks} className={'image-tooltip'} globalEventOff='click'>*/}
                        {(!(KoszykService.isEmpty(e.progIl)) || !(KoszykService.isEmpty(e.progWr))) &&
                        <ReactTooltip id={"prog" + e.prog + id} place='left' effect='solid'
                            // delayHide={500}
                                      delayShow={200}
                            // delayUpdate={500}
                            // backgroundColor={"#605D8D"}
                                      border={true}
                                      className={'prog-tooltip'}
                                      type="light"
                        >
                            <h6>Warunek:</h6>
                            {(!KoszykService.isEmpty(e.progIl)) &&
                            <p style={{whiteSpace: 'pre-line', color: 'white', margin: '0'}}>
                                <div>Ilościowy: {e.progIl} {row.jm}<br/></div>
                            </p>}
                            {(!KoszykService.isEmpty(e.progWr)) &&
                            <p style={{whiteSpace: 'pre-line', color: 'white', margin: '0'}}>
                                <div>Wartościowy: <TableFormatters_formatMoney value={e.progWr}/></div>
                            </p>}
                        </ReactTooltip>
                        }
                    </>
                    // return <div className={(row.progAkt === e.prog)? 'progAkt' : ''}>{e.progIl} {row.jm} - {formatMoney(e.cena)}<br/></div>
                    // <div>{e.progIl} {row.jm} - {e.nazwa}<br/></div>
                })
                // :
                // <></>
            )
        }
        , {
            dataField: 'jm',
            text: 'Opak / karton',
            formatter: this.formatOpakowanieRow,
            align: 'center'
        }
        , {
            dataField: 'zakupJm',
            text: 'Koszyk',
            headerFormatter: () => {return( <><IconCart/>&nbsp; Koszyk</>)},
            style: {width: '145px'},
            // formatExtraData: changeProps || this.props.changeOferta,
            formatter: (cell: any, row: any, index: any, extraData: any) => (
                row.koszykIlosc !== null &&
                <Shared_ProductAddToBasketForm_function item={row} odswiezIloscLokalnie={this.odswiezIloscWOfercieLokalniePromPakPCU} handleChangeInBasket={this.onChangeInBasketPromPakPCU}/>
                // // @ts-ignore
                // <Shared_ProductAddToBasketForm item={row} alwaysDostepne={true} fieldForAmount={'koszykIlosc'} inline={true} odswiezIloscLokalnie={this.odswiezIloscWOfercieLokalniePromPakPCU} showButton={false} handleChangeInBasket={this.onChangeInBasketPromPakPCU}/>
            )
        }
    ];

    ktoryProg = (progi: any) => {
        let wszystkieProgi = progi;
        let jakiProg = 0;

        wszystkieProgi.forEach((prog: any) => {
            if(prog.prog > jakiProg){
                jakiProg = prog.prog;
            }
        })

        return jakiProg;
    }

    czyOgolnyWarunek = (): boolean => {
        let aktProg = this.ktoryProg(this.state.progi);
        // let ileProgow = this.state.promocjaProgowa.warunkiNaPrograch.length;
        let czyWarunek = false;

        for(const w of this.state.promocjaProgowa.warunkiNaPrograch) {
            if(((w.prog) === aktProg + 1)){
                if((w.progIl !== null) || (w.progWr !== null))
                {
                    czyWarunek = true;
                    break;
                }
            }
        }

        return czyWarunek;
    }

    renderTypBody = (typ: any, produkty: any) => {
        let {isRequestLoading, params, pageableObject, isRequestLoadingOferta, isRequestErrorOferta, isRequestError, loading, progi, promocjaProgowaCu, promocjaProgowaG, promocjaProgowa} = this.state;
        let {kategorie, producenci, sezony, czyZOferty, czyZLinku} = this.props;

        switch (typ) {
            case 'P':
                return((produkty.length > 0) ?
                    <div>
                        {(produkty.length > 0) && <div>
                            {promocjaProgowa.warunkiNaPrograch.map((p: any) => {
                                return <Button size={'sm'} onClick={() => this.onDodajPakiet(p.prog)}
                                               style={{margin: '0 2px 15px 2px'}}>DODAJ PODSTAWOWY
                                    PAKIET {p.prog}</Button>
                            })
                            }
                            <BootstrapTable remote keyField='indeks' data={produkty} columns={this.columnsPromPakG}
                                            hover noDataIndication={'Brak pasujących wyników'}
                                            headerClasses={(czyZLinku) ? 'headerbsZLinku' : 'headerbs'}
                            />
                            {(this.ktoryProg(progi) !== 0) && <div>
                                <h6 style={{marginTop: '15px'}}>Otrzymane gratisy:</h6>
                                <ul>
                                    {promocjaProgowa.gratisyNaProgach[this.ktoryProg(progi) - 1].gratisy.map((gratis: any) =>
                                        <div>
                                            <li><h6>{gratis.nazwa}</h6></li>
                                        </div>
                                    )}
                                </ul>

                            </div>
                            }

                            <hr/>

                            <Row>
                                <Col style={{textAlign: 'right'}}>
                                    <h3 style={{display: 'inline-block'}}>Aktualny próg: </h3>
                                    <Table className={'progiInfoTable'}>{progi.map((prog: any) =>
                                        <div className={'progInfo'}>
                                            <td>
                                                {(this.ktoryProg(progi) === 0) ?
                                                    <i>nie osiągnięto żadnego progu</i>
                                                    :
                                                    <h6>
                                                        <div className={'nrProgu'}>Próg {prog.prog}</div>
                                                        <div className={'ilPak'}>x{prog.ilPak}</div>
                                                    </h6>
                                                }
                                            </td>
                                        </div>
                                    )}</Table>
                                </Col>
                            </Row>
                            {/*{(this.ktoryProg(progi) < promocjaProgowa.warunkiNaPrograch.length) && <div>*/}
                            {(this.czyOgolnyWarunek()) && <div>
                                <Row>
                                    <Col>
                                        <h5>Aby osiągnąć próg {this.ktoryProg(progi) + 1} należy również spełnić warunki
                                            ogólne:</h5>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <h6 className={'heading-label'}>
                                            Warunek ogólny ilościowy:
                                        </h6>
                                        <h6>{KoszykService.isEmpty(promocjaProgowa.warunkiNaPrograch[this.ktoryProg(progi)].progIl) ? '-' :
                                            <div>{promocjaProgowa.warunkiNaPrograch[this.ktoryProg(progi)].progIl} {promocjaProgowa.warunkiNaPrograch[this.ktoryProg(progi)].jm}</div>}</h6>
                                    </Col>
                                    <Col>
                                        <h6 className={'heading-label'}>
                                            Warunek ogólny wartościowy:
                                        </h6>
                                        <h6 style={{textAlign: 'left'}}>{KoszykService.isEmpty(promocjaProgowa.warunkiNaPrograch[this.ktoryProg(progi)].progWr) ? '-' :
                                            <TableFormatters_formatMoney
                                                value={promocjaProgowa.warunkiNaPrograch[this.ktoryProg(progi)].progWr}/>}</h6>
                                    </Col>
                                    <Col>
                                        <h6 className={'heading-label'}>
                                            Indeksy wymagane <span style={{color: 'red', fontWeight: 'bold'}}>*</span> :
                                        </h6>
                                        <h6>{produkty.filter((p: any) => p.obow === 1).map((pro: any, index: number, array: any) =>
                                            <div>
                                                {pro.indeks}{(index < array.length - 1) ? ', ' : ''}</div>
                                        )}</h6>
                                    </Col>
                                </Row>
                            </div>
                            }
                        </div>
                        }
                    </div>
                    :
                    this.promWyczerpana()

                    // <Accordion>
                    // {
                        // 26.05.2022
                        // progi.map((prom: any, index: any) => {
                        //     return <Card title={'Próg ' + (index+1)}>
                        //         <div className={'prog-row'}>
                        //             <Card.Header className={'headerNoClick'}>
                        //                 <div className={'progHeader'}>
                        //                     <Row>
                        //                         <Col xl={9} style={{padding: '0'}} className={'my-auto'}>
                        //                             <Accordion.Toggle as={Card.Header} eventKey={index}>
                        //
                        //                                 <h5>Próg {index+1}</h5>
                        //
                        //                             </Accordion.Toggle>
                        //                         </Col>
                        //
                        //                         <Col xl={3} style={{textAlign: "right", padding: '0'}} className={'my-auto'}>
                        //                             <div style={{maxWidth: '80px', display: 'inline-block'}}>
                        //                                 <InputNumber
                        //                                     value={prom.ile}
                        //                                     defaultValue={1}
                        //                                     onChange={(e: any)=> this.onChangeIlosc(index, e)}
                        //                                     precision={0}
                        //                                     step={1}
                        //                                     min={1}
                        //                                 />
                        //                             </div>
                        //                             <i className="fa fa-times" style={{color: '#605D8D', margin: '0 5px'}}/>
                        //                             <Button size={'sm'} onClick={() => this.onDodajPakiet(index+1)}>DODAJ PAKIET</Button>
                        //                         </Col>
                        //                     </Row>
                        //                 </div>
                        //             </Card.Header>
                        //
                        //             <Accordion.Collapse eventKey={index}>
                        //                 <Card.Body>
                        //                     <BootstrapTable remote keyField='indeks' data={ prom.produkty } columns={ this.columnsProg } hover noDataIndication={'Brak pasujących wyników'}
                        //                                     headerClasses={'headerbs'}
                        //                     />
                        //
                        //                     <h6 style={{marginTop: '15px'}}>Gratisy:</h6>
                        //                     <hr style={{marginTop: '0'}}/>
                        //
                        //                     {/*<ul>*/}
                        //                     {/*    {prom.gratisy.map((gratis: any) => {*/}
                        //                     {/*        return <li><h6>{gratis.indeks} {gratis.nazwa}</h6></li>*/}
                        //                     {/*    })}*/}
                        //                     {/*</ul>*/}
                        //
                        //                     <BootstrapTable remote keyField='indeks' data={ prom.gratisy } columns={ this.columnsProgGratis } hover noDataIndication={'Brak pasujących wyników'}
                        //                                     headerClasses={'headerbs'} rowStyle={this.rowStyle}
                        //                     />
                        //
                        //                 </Card.Body>
                        //             </Accordion.Collapse>
                        //         </div>
                        //     </Card>
                        // })
                    // }
                // </Accordion>
                );
            case 'PCU':
                return ((produkty.length > 0)?
                        <div>
                    {(produkty.length > 0) && <div>
                    <BootstrapTable remote keyField='indeks' data={ produkty } columns={ this.columnsProgPCU } hover noDataIndication={'Brak pasujących wyników'}
                                    headerClasses={(czyZLinku)? 'headerbsZLinku' : 'headerbs'}
                    />
                    {/*{(this.ktoryProg(progi) !== 0) && <div>*/}
                    {/*    <h6 style={{marginTop: '15px'}}>Otrzymane gratisy:</h6>*/}
                    {/*    <ul>*/}
                    {/*        {promocjaProgowa.gratisyNaProgach[this.ktoryProg(progi) - 1].gratisy.map((gratis: any) =>*/}
                    {/*            <div>*/}
                    {/*                <li><h6>{gratis.nazwa}</h6></li>*/}
                    {/*            </div>*/}
                    {/*        )}*/}
                    {/*    </ul>*/}

                    {/*</div>*/}
                    {/*}*/}

                    <hr/>

                    <Row>
                        <Col style={{textAlign: 'right'}}>
                            <h3 style={{display: 'inline-block'}}>Aktualny próg: </h3>
                            <Table className={'progiInfoTable'}>
                                <div className={'progInfo2'}>
                                    <td>
                                        {(this.ktoryProg(progi) === 0)?
                                            <i>nie osiągnięto żadnego progu</i>
                                            :
                                            <h6>
                                                <div className={'nrProgu'}>Próg {this.ktoryProg(progi)}</div>
                                                {/*<div className={'ilPak'}>x{prog.ilPak}</div>*/}
                                            </h6>
                                        }
                                    </td>
                                </div>
                            </Table>
                        </Col>
                    </Row>
                    {/*{(this.ktoryProg(progi) < promocjaProgowa.warunkiNaPrograch.length) && <div>*/}
                    {(this.czyOgolnyWarunek()) && <div>
                        <Row>
                            <Col>
                                <h5>Aby osiągnąć próg {this.ktoryProg(progi) + 1} należy również spełnić warunki ogólne:</h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h6 className={'heading-label'}>
                                    Warunek ogólny ilościowy:
                                </h6>
                                <h6>{KoszykService.isEmpty(promocjaProgowa.warunkiNaPrograch[this.ktoryProg(progi)].progIl) ? '-' : <div>{promocjaProgowa.warunkiNaPrograch[this.ktoryProg(progi)].progIl} {promocjaProgowa.warunkiNaPrograch[this.ktoryProg(progi)].jm}</div>}</h6>
                            </Col>
                            <Col>
                                <h6 className={'heading-label'}>
                                    Warunek ogólny wartościowy:
                                </h6>
                                <h6 style={{textAlign: 'left'}}>{KoszykService.isEmpty(promocjaProgowa.warunkiNaPrograch[this.ktoryProg(progi)].progWr) ? '-' : <TableFormatters_formatMoney value={promocjaProgowa.warunkiNaPrograch[this.ktoryProg(progi)].progWr}/>}</h6>
                            </Col>
                            <Col>
                                <h6 className={'heading-label'}>
                                    Indeksy wymagane <span style={{color: 'red', fontWeight: 'bold'}}>*</span> :
                                </h6>
                                <h6>{produkty.filter((p: any) => p.obow === 1).map((pro: any, index: number, array: any) => <div>
                                    {pro.indeks}{(index < array.length - 1)? ', ' : ''}</div>
                                )}</h6>
                            </Col>
                        </Row>
                    </div>
                    }
                    </div>}
                </div>
                        :
                        this.promWyczerpana()

                //     <Accordion>
                //     {
                //         progi.map((prom: any, index: any) => {
                //             return <Card title={'Próg ' + (index+1)}>
                //                 <div className={'prog-row'}>
                //                     <Card.Header className={'headerNoClick'}>
                //                         <div className={'progHeader'}>
                //                             <Row>
                //                                 <Col xl={9} style={{padding: '0'}} className={'my-auto'}>
                //                                     <Accordion.Toggle as={Card.Header} eventKey={index}>
                //
                //                                         <h5>Próg {index+1}</h5>
                //
                //                                     </Accordion.Toggle>
                //                                 </Col>
                //
                //                                 <Col xl={3} style={{textAlign: "right", padding: '0'}} className={'my-auto'}>
                //                                     <div style={{maxWidth: '80px', display: 'inline-block'}}>
                //                                         <InputNumber
                //                                             value={prom.ile}
                //                                             defaultValue={1}
                //                                             onChange={(e: any)=> this.onChangeIlosc(index, e)}
                //                                             precision={0}
                //                                             step={1}
                //                                             min={1}
                //                                         />
                //                                     </div>
                //                                     <i className="fa fa-times" style={{color: '#605D8D', margin: '0 5px'}}/>
                //                                     <Button size={'sm'} onClick={() => this.onDodajPakietCu(index+1)}>DODAJ PAKIET</Button>
                //                                 </Col>
                //                             </Row>
                //                         </div>
                //                     </Card.Header>
                //
                //                     <Accordion.Collapse eventKey={index}>
                //                         <Card.Body>
                //                             <BootstrapTable remote keyField='indeks' data={ prom.produkty } columns={ this.columnsProg } hover noDataIndication={'Brak pasujących wyników'}
                //                                             headerClasses={'headerbs'}
                //                             />
                //
                //                         </Card.Body>
                //                     </Accordion.Collapse>
                //                 </div>
                //             </Card>
                //         })
                //     }
                // </Accordion>
                );
            case 'PL':
                return ( <Accordion>
                    {
                        progi.map((prom: any, index: any) => {
                            return <Card title={'Próg ' + (index+1)}>
                                <div className={'prog-row'}>
                                    <Card.Header className={'headerNoClick'}>
                                        <div className={'progHeader'}>
                                            <Row>
                                                <Col xl={9} style={{padding: '0'}} className={'my-auto'}>
                                                    <Accordion.Toggle as={Card.Header} eventKey={index}>

                                                        <h5>Próg {index+1}</h5>

                                                    </Accordion.Toggle>
                                                </Col>

                                                <Col xl={3} style={{textAlign: "right", padding: '0'}} className={'my-auto'}>
                                                    <div className={'punkty'} style={{maxWidth: '80px', display: 'inline-block'}}>
                                                        + {prom.punkty} pkt
                                                    </div>

                                                    <div style={{maxWidth: '80px', display: 'inline-block'}}>
                                                        <InputNumber
                                                            value={prom.ile}
                                                            defaultValue={1}
                                                            onChange={(e: any)=> this.onChangeIlosc(index, e)}
                                                            precision={0}
                                                            step={1}
                                                            min={1}
                                                        />
                                                    </div>
                                                    <i className="fa fa-times" style={{color: '#605D8D', margin: '0 5px'}}/>
                                                    <Button size={'sm'} onClick={() => this.onDodajPakietCu(index+1)}>DODAJ PAKIET</Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Card.Header>

                                    <Accordion.Collapse eventKey={index}>
                                        <Card.Body>
                                            <BootstrapTable remote keyField='indeks' data={ prom.produkty } columns={ this.columnsProg } hover noDataIndication={'Brak pasujących wyników'}
                                                            headerClasses={'headerbs'}
                                                            // headerClasses={(czyZLinku)? 'headerbsZLinku' : 'headerbs'}
                                            />

                                        </Card.Body>
                                    </Accordion.Collapse>
                                </div>
                            </Card>
                        })
                    }
                </Accordion>);
            case 'PPCU':
                return (
                    <TopLoading isLoading={isRequestLoading} isError={isRequestError}>
                        {(promocjaProgowaCu.length > 0) ?
                            <BootstrapTable remote keyField='indeks' data={promocjaProgowaCu} columns={this.columnsPPCU}
                                            hover noDataIndication={'Brak pasujących wyników'}
                                            headerClasses={(czyZLinku) ? 'headerbsZLinku' : 'headerbs'}
                                            loading={loading}
                                            overlay={overlayFactory({
                                                spinner: true,
                                                styles: {
                                                    overlay: (base: any) => ({
                                                        ...base,
                                                        background: 'rgba(96,93,141,0.3)'
                                                    })
                                                }
                                            })}
                                // expandRow={this.expandRowPPCU}
                                            pagination={(paginationFactory({
                                                page: (pageableObject.currentPage === 99999) ? 1 : pageableObject.currentPage + 1,
                                                sizePerPage: pageableObject.currentSize,
                                                totalSize: pageableObject.allElements,
                                                sizePerPageList: [10, 12, 20, 50, 100],
                                                onSizePerPageChange: this.onChangeSizeInTable,
                                                onPageChange: this.onChangePageInTable
                                            }))}
                            />
                            :
                            this.promWyczerpana()
                        }
                    </TopLoading>
                )
            case 'PPG':
                return (
                    <TopLoading isLoading={isRequestLoading} isError={isRequestError}>
                        {(promocjaProgowaG.length > 0) ?
                            <BootstrapTable remote keyField='indeks' data={promocjaProgowaG} columns={this.columnsPPG}
                                            hover noDataIndication={'Brak pasujących wyników'}
                                            headerClasses={(czyZLinku) ? 'headerbsZLinku' : 'headerbs'}
                                            loading={loading}
                                            overlay={overlayFactory({
                                                spinner: true,
                                                styles: {
                                                    overlay: (base: any) => ({
                                                        ...base,
                                                        background: 'rgba(96,93,141,0.3)'
                                                    })
                                                }
                                            })}
                                // expandRow={this.expandRowPPCU}
                                            pagination={(paginationFactory({
                                                page: (pageableObject.currentPage === 99999) ? 1 : pageableObject.currentPage + 1,
                                                sizePerPage: pageableObject.currentSize,
                                                totalSize: pageableObject.allElements,
                                                sizePerPageList: [10, 12, 20, 50, 100],
                                                onSizePerPageChange: this.onChangeSizeInTable,
                                                onPageChange: this.onChangePageInTable
                                            }))}
                            />
                            :
                            this.promWyczerpana()
                        }
                    </TopLoading>
                )
        }

        return (
            <TopLoading isLoading={isRequestLoadingOferta} isError={isRequestErrorOferta}>

                {(produkty.length > 0)?
                    <>
                        <OfertaFilterbox handleClickFiltruj={this.onClickFiltruj}
                                         kategorie={kategorie? kategorie : this.state.kategorie}
                                         producenci={producenci? producenci : this.state.producenci}
                                         sezony={sezony? sezony : this.state.sezony}
                                         params={params}
                                         handleSelectOrClearValue={this.onSelectOrClearValue}
                                         isButtonDisabled={isRequestLoading}
                        />

                        <BootstrapTable remote keyField='indeks' data={ produkty } columns={ this.columns } hover noDataIndication={'Brak pasujących wyników'}
                                        headerClasses={(czyZLinku)? 'headerbsZLinku' : 'headerbs'}
                                        loading={loading}
                                        overlay={ overlayFactory({ spinner: true, styles: { overlay: (base: any) => ({...base, background: 'rgba(96,93,141,0.3)'}) } }) }
                                        pagination={(paginationFactory({
                                            page: (pageableObject.currentPage === 99999)? 1 : pageableObject.currentPage+1,
                                            sizePerPage: pageableObject.currentSize,
                                            totalSize: pageableObject.allElements,
                                            sizePerPageList: [10, 12, 20, 50, 100],
                                            onSizePerPageChange: this.onChangeSizeInTable,
                                            onPageChange: this.onChangePageInTable
                                        }))}
                        />
                    </>
                    :
                    this.promWyczerpana()
                }

            </TopLoading>
        )
    }

    promWyczerpana = () => {
        return <div style={{textAlign: 'center'}}>
            <h5>Promocja wyczerpana</h5>
            <p>Zapraszamy do skorzystania z innych <a href={'/promocje'}>promocji</a></p>
        </div>
    }

    onDelPromZKoszyka = () => {
        let data = {
            idUm: this.props.idUm,
            czyEdycja: this.props.czyEdycja
        }

        KoszykService.deletePromocjeZKoszyka(data).then((response) => {
            displayNotificationInfo('Usunięto promocje z koszyka', 'ID promocji: ' + data.idUm);

            if(this.state.poTerminie){
                this.pobierzPromocjeInfo(this.props.idUm);
            } else {
                this.pobierzPromocjeInfo(this.props.idUm);
            }

            // @ts-ignore
            this.props.odswierzKoszyk();

        }).catch((e) => {
            displayNotificationError('Error', '');
        })
    }

    render() {
        let {promocjaInfo, poTerminie, isRequestError, isRequestLoading, produkty, rodzajPromocji, wKoszyku} = this.state;
        let {sezon} = this.props;

        // console.log('render')

        return (<TopLoading isLoading={isRequestLoading} isError={isRequestError}>
                <div id={'PROMOCJA_MODULE'}>
                    {(sezon)?
                        <>{this.renderTypBody('sezon', produkty)}</>
                        :
                        <>
                            <PromocjaInfo promocja={promocjaInfo} idProm={this.props.idUm}/>

                            <hr/>

                            {(poTerminie)?
                                <div style={{textAlign: 'center'}}>
                                    <h5>Promocja wygasła dnia {promocjaInfo.DATA_DO}</h5>
                                    <p>Zapraszamy do skorzystania z innych <a href={'/promocje'}>promocji</a></p>
                                </div>
                                :
                                <>{this.renderTypBody(rodzajPromocji, produkty)}</>
                            }

                            {(rodzajPromocji != 'PROM_PROST_CU') && (wKoszyku) &&
                            <div style={{textAlign: 'right'}}><Button size={'sm'} className={'usunAllIdUm'} onClick={this.onDelPromZKoszyka}><i className={'fa fa-trash'}/> Usuń promocje z koszyka</Button></div>
                            }
                        </>
                    }

                </div>
            </TopLoading>

        );
    }
}

export default PromocjaModuleBody;