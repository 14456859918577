import React, {Component} from 'react';
import {Button, Col, Form, FormGroup, Row} from "react-bootstrap";
import Select, {OptionTypeBase} from "react-select";
import Req from "../../formComponents/Req";
import AddFormMargin from "../../formComponents/AddFormMargin";
import {IconExportFile, IconPrint} from "../../icons/FontAwesome";
import {displayNotificationError, displayNotificationSuccess} from "../../../utilities/notifications/NotificationsManager";
import WydrukiService from "../../../services/WydrukiService";
import {saveAnyFileAndDownload} from "../../../utilities/files/fileOperations";
import TopLoading from "../../loader/TopLoading";
import {TextAlert} from "../../typographyComponents/TextAlert/TextAlert";
import {SmallerSelectStyles} from "../../../utilities/forms/jsStyles";
import {isMobileDevice} from "../../../utilities/rwd/rwdUtilities";
import {saveAs} from "file-saver";
import ZamowieniaService from "../../../services/ZamowieniaService";
import {formatMoney} from "../../../utilities/formatters/formatMoney";
import {ExportToCsv} from "export-to-csv";
import FakturyService from "../../../services/FakturyService";

interface Props {
    typy: any,
    faktura?: any,
    zamowienie?: any,
    handleClickZamknij: () => void;
}

class Shared_WydrukOrExportContainer extends Component<Props> {

    state = {
        typ: null,
        isLoadingWydruk: false,
        isErrorWydruk: false,
        pobranoPlik: false,

        separators: [
            {value: ";", label: "; średnik"},
            {value: " ", label: "spacja"},
            {value: "\t", label: "tabulator"},
            {value: "/", label: "/ ukośnik"},
            {value: ",", label: ", przecinek"},
        ],
        settings: {
            separator: {value: ";", label: "; średnik"},
            columnsToExport: []
        },
        columnsAll: [],
        columnsNames: [
            {value: 'poz', label: 'Pozycja'},
            {value: 'indeks', label: 'Indeks'},
            {value: 'nazwa', label: 'Nazwa'},
            {value: 'cena', label: 'Cena'},
            {value: 'iloscWpr', label: 'Ilosc'},
            {value: 'jmWpr', label: 'JM'},
            {value: 'przyjal', label: 'Przyjal'},
        ],
        columnsNamesFaktury: [
            {value: 'poz', label: 'Pozycja'},
            {value: 'indeks', label: 'Indeks'},
            {value: 'producent', label: 'Producent'},
            {value: 'nazwa', label: 'Nazwa'},
            {value: 'cena', label: 'Cena'},
            {value: 'ilosc', label: 'Ilosc'},
            {value: 'jm', label: 'JM'},
            {value: 'wartosc', label: 'Wartosc'},
        ],
        columnsNamesPoIndeksie: [
            {value: 'indeks', label: 'Indeks'},
            {value: 'iloscWpr', label: 'Ilosc'},
        ],
        currentZamowienie: [],
        expCsv: false
    };

    componentDidMount() {
        if (this.props.typy.length > 0)
            this.setState({
                typ: this.props.typy[0]
            })

        if(this.props.faktura){
            this.pobierzPozycjeFaktury(this.props.faktura);
            this.prepareColumnsFaktury(this.props.faktura);
        } else
        {
            this.prepareColumns(this.props.zamowienie);
            this.pobierzPozycje(this.props.zamowienie);
        }

    }

    pobierzPozycje = (row: any) => {
        this.setState({
            isLoadingPozycje: true,
            isErrorPozycje: false,
        });

        ZamowieniaService.getPozycjeZamowienia(0, 1000, {
            "numer": row.numer,
            "symbol": row.symbol
        })
            .then((response: any) => {
                if (response && response.status < 300) {
                    this.setState({
                        currentZamowienie: response.data,
                        isLoadingPozycje: false
                    })
                } else {
                    this.setState({isLoadingPozycje: false, isErrorPozycje: true})
                }
            })
            .catch((error: any) => {
                this.setState({isLoadingPozycje: false, isErrorPozycje: true})
            })

    };

    pobierzPozycjeFaktury = (row: any) => {
        this.setState({
            isLoadingPozycje: true,
            isErrorPozycje: false,
        });

        FakturyService.getPozycjeFaktury({ "data": row.dataWys, "podstawa": row.podstawa })
            .then((response: any) => {
                if (response && response.status < 300) {
                    this.setState({
                        currentZamowienie: response.data,
                        isLoadingPozycje: false
                    })
                } else {
                    this.setState({isLoadingPozycje: false, isErrorPozycje: true})
                }
            })
            .catch((error: any) => {
                this.setState({isLoadingPozycje: false, isErrorPozycje: true})
            })

    };

    prepareColumns = (data: any) => {
        if (this.state.columnsNames.length > 0) {
            this.setState({
                settings: {
                    separator: {value: ";", label: "; średnik"},
                    columnsToExport: Array.from(this.state.columnsNames)
                },
                columnsAll: Array.from(this.state.columnsNames),
            })
        } else {
            let columnsNames: any = [];

            if (data.length > 0) {
                let obj = data[0];

                Object.keys(obj).forEach(key => {
                    columnsNames.push({
                        value: key,
                        label: key
                    })
                })

            }

            this.setState({
                settings: {
                    separator: {value: ";", label: "; średnik"},
                    columnsToExport: columnsNames
                },
                columnsAll: columnsNames
            })
        }
    };

    prepareColumnsFaktury = (data: any) => {
        if (this.state.columnsNamesFaktury.length > 0) {
            this.setState({
                settings: {
                    separator: {value: ";", label: "; średnik"},
                    columnsToExport: Array.from(this.state.columnsNamesFaktury)
                },
                columnsAll: Array.from(this.state.columnsNamesFaktury),
            })
        } else {
            let columnsNames: any = [];

            if (data.length > 0) {
                let obj = data[0];

                Object.keys(obj).forEach(key => {
                    columnsNames.push({
                        value: key,
                        label: key
                    })
                })

            }

            this.setState({
                settings: {
                    separator: {value: ";", label: "; średnik"},
                    columnsToExport: columnsNames
                },
                columnsAll: columnsNames
            })
        }
    };

    onClickExport = () => {

        let transformedData: any = [];

        this.state.currentZamowienie.forEach((obj: any) => {

            let transformedObj: any = {};

            this.state.settings.columnsToExport.forEach((column: any) => {

                let val: any = obj[column.value];

                if (val === true) {
                    val = 'tak'
                } else if (val === false) {
                    val = 'nie'
                } else if (val === null || val === undefined) {
                    val = '-'
                } else if (column.value === "cena" || column.value === "cenaKatalogowa" || column.value === "wartoscNetto" || column.value === "wartoscBrutto" || column.value === "kwota" || column.value === "kwotaNetto" || column.value === "kwotaBrutto" || column.value === "zaplata") {
                    val = formatMoney(val)
                } else {
                    val = val.toString();
                }

                transformedObj = Object.assign(transformedObj, {[column.label]: val})

            });

            transformedData.push(transformedObj)
        });


        const options = {
            filename: 'famix-export-'+(this.props.faktura? ('faktura-'+this.props.faktura.numer+'-') : ('zamowienie-'+this.props.zamowienie.numer+'-')) + new Date().toJSON().substr(0, 19),
            fieldSeparator: this.state.settings.separator.value,
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: true,
            title: 'FAMIX Export '+(this.props.faktura? ('faktura-'+this.props.faktura.numer+'-') : ('zamowienie-'+this.props.zamowienie.numer+'-')) + new Date().toJSON().substr(0, 19),
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            headers: this.state.columnsNames.length > 0 ? this.state.columnsNames : null
        };

        // @ts-ignore
        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(transformedData);

    };

    onClickExportPoIndeksie = () => {

        let transformedData: any = [];

        this.state.currentZamowienie.forEach((obj: any) => {

            let transformedObj: any = {};

            this.state.columnsNamesPoIndeksie.forEach((column: any) => {

                let val: any = obj[column.value];

                if(column.value === 'iloscWpr'){
                    val = val + ';';
                }

                transformedObj = Object.assign(transformedObj, {[column.label]: val})

            });

            transformedData.push(transformedObj)
        });


        const options = {
            filename: 'famix-export-'+(this.props.faktura? ('faktura-'+this.props.faktura.numer+'-') : ('zamowienie-'+this.props.zamowienie.numer+'-')) + new Date().toJSON().substr(0, 19),
            fieldSeparator: ";",
            quoteStrings: '',
            decimalSeparator: '.',
            showLabels: false,
            showTitle: false,
            useTextFile: false,
            useBom: false,
            useKeysAsHeaders: false,
            headers: this.state.columnsNames.length > 0 ? this.state.columnsNames : null
        };

        // @ts-ignore
        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(transformedData);

    };

    onChangeTyp = (e: any) => {
        this.setState({typ: e, expCsv: (e.valueOf().recno === 3)})
    };

    onClickButtonWydrukuj = () => {

        let {typ}: any = this.state;

        // jesli exp csv
        if(typ.recno === 3)
        {
            this.onClickExport();
        } else if(typ.recno === 4)
        {
            this.onClickExportPoIndeksie();
        } else
        if (Boolean(this.props.faktura) || Boolean(this.props.zamowienie)) {
            if (Boolean(typ)) {

                this.setState({
                    isLoadingWydruk: true,
                    isErrorWydruk: false,
                });

                let obj = {
                    //@ts-ignore
                    ...typ,
                    "symbol": Boolean(this.props.faktura) ? this.props.faktura.symbol : this.props.zamowienie.symbol,
                    "numer": Boolean(this.props.faktura) ? this.props.faktura.numer : this.props.zamowienie.numer,
                    "dataWys": Boolean(this.props.faktura) ? this.props.faktura.dataWys : this.props.zamowienie.dataZam.substr(0, 10),
                    "faktCzyZamow": Boolean(this.props.faktura) ? "NETFAK" : "NETZAM"
                };

                WydrukiService.getWydruk(btoa(JSON.stringify(obj)))
                    .then((response: any) => {
                        if (response && response.status < 300) {
                            this.setState({
                                isLoadingWydruk: false,
                                isErrorWydruk: false,
                                pobranoPlik: true
                            });

                            displayNotificationSuccess('Pomyślnie zaciagnięto dane', 'Plik pobierze się automatycznie')

                            // let nazwa = '';
                            // if (Boolean(this.props.faktura))
                            //     nazwa = `faktura-${this.props.faktura.symbol}-${this.props.faktura.numer}-${new Date().toJSON().substr(0, 10)}.${typ.urlFormat}`;
                            // if (Boolean(this.props.zamowienie))
                            //     nazwa = `zamowienie-${this.props.zamowienie.symbol}-${this.props.zamowienie.numer}-${new Date().toJSON().substr(0, 10)}.${typ.urlFormat}`;

                            let nazwa = response.data.fileName;
                            let plik = response.data.plik;

                            // saveAs(new Blob([response.data.plik], {type: `application/pdf`}), nazwa);

                            let buff = new Buffer(plik, 'base64');
                            // let base64ToStringNew = buff.toString('ascii');

                            const url = window.URL.createObjectURL(new Blob([buff]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', nazwa);
                            document.body.appendChild(link);
                            link.click();

                            // saveAnyFileAndDownload(response.data, nazwa, typ.urlFormat);

                            setTimeout(() => {
                                this.props.handleClickZamknij()
                            }, 3000)

                        } else {
                            this.setState({
                                isLoadingWydruk: false,
                                isErrorWydruk: true,
                            });
                            displayNotificationError('Wystąpił błąd podczas próby wydruku bądź exportu', '')
                        }
                    })
                    .catch((error: any) => {
                        this.setState({
                            isLoadingWydruk: false,
                            isErrorWydruk: true,
                        });
                        displayNotificationError('Wystąpił błąd podczas próby wydruku bądź exportu', error.message)
                    })

            } else {
                displayNotificationError('Nie wybrano formatu wydruku bądź exportu', '')
            }
        } else {
            displayNotificationError('Błąd odczytu dokumentu', 'Proszę zamknąć popup i ponownie go uruchomić')
        }


    };

    onChangeSeparator = (e: any) => {
        let settings = this.state.settings;
        settings.separator = e;
        this.setState({settings})
    };

    onChangeColumnsToExport = (e: any) => {
        let settings = this.state.settings;
        settings.columnsToExport = e;
        this.setState({settings})
    };

    render() {

        let {typy} = this.props;
        let {typ, isLoadingWydruk, isErrorWydruk, pobranoPlik, settings, separators, columnsAll, expCsv} = this.state;

        return (
            <div>
                <Form>
                    <Row>
                        <Col xs={12} sm={6} md={5} lg={4} xl={3}>
                            <FormGroup>
                                <Form.Label>Format {Boolean(this.props.faktura) ? " wydruku" : " exportu"}: <Req/></Form.Label>
                                <Select
                                    getOptionLabel={({opisFormatu}: OptionTypeBase) => opisFormatu}
                                    getOptionValue={({formaty}: OptionTypeBase) => formaty}
                                    value={typ}
                                    options={typy}
                                    onChange={this.onChangeTyp}
                                    styles={SmallerSelectStyles}
                                    isClearable={false}
                                    menuPosition={"fixed"}
                                    placeholder={'Wybierz...'}
                                    isSearchable={!isMobileDevice()}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} sm={6} md={7} lg={8} xl={9}>
                            <FormGroup>
                                <AddFormMargin hideForMobileDevices767={true}/>
                                <Button variant={'primary'} size={'sm'} onClick={this.onClickButtonWydrukuj} disabled={isLoadingWydruk}>
                                    {Boolean(this.props.faktura) ? <><IconPrint/> Pobierz wydruk</> : <><IconExportFile/> Pobierz export</>}
                                </Button>
                                <div style={{
                                    display: isMobileDevice() ? "block" : 'inline-block',
                                    marginLeft: isMobileDevice() ? 0 : '15px',
                                    marginTop: isMobileDevice() ? "5px" : 0
                                }}>
                                    <TopLoading isLoading={isLoadingWydruk} isError={isErrorWydruk} isBig={false} isCentered={false} loadingText={'Trwa pobieranie pliku...'}
                                                errorMessage={'Nie udało się pobrać pliku'}>
                                        {
                                            pobranoPlik &&
                                            <TextAlert type={'success'}>Pomyślnie pobrano plik. Okno zamknie się automatycznie za 3 sekundy</TextAlert>
                                        }
                                    </TopLoading>
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>

                    {expCsv &&
                    <Form id={'TopExportForm'}>
                        <Row>
                            <Col md={5} lg={4} xl={3}>
                                <FormGroup>
                                    <Form.Label>
                                        Separator
                                    </Form.Label>
                                    <Select
                                        value={settings.separator}
                                        options={separators}
                                        onChange={this.onChangeSeparator}
                                        isClearable={false}
                                        menuPosition={"fixed"}
                                        styles={SmallerSelectStyles}
                                        isSearchable={!isMobileDevice()}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={7} lg={8} xl={9}>
                                <FormGroup>
                                    <Form.Label>
                                        Kolumny do exportu
                                    </Form.Label>
                                    <Select
                                        value={settings.columnsToExport}
                                        options={columnsAll}
                                        onChange={this.onChangeColumnsToExport}
                                        isMulti={true}
                                        isClearable={false}
                                        menuPosition={"fixed"}
                                        styles={SmallerSelectStyles}
                                        isSearchable={!isMobileDevice()}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg={12}>
                                <TextAlert type={'info'}>Zmiany zapisują się automatycznie</TextAlert>
                            </Col>
                        </Row>
                    </Form>
                    }

                </Form>
            </div>
        );
    }
}

export default Shared_WydrukOrExportContainer;