import React from 'react';
import {formatMoney} from "../../../utilities/formatters/formatMoney";
import TopCard from "../../../sharedComponents/TopCard";
import {IconExclamation} from "../../../sharedComponents/icons/FontAwesome";
import {prettyColumns_Rozrachunki} from "../../../utilities/exports/prettyColumnToExport";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
// @ts-ignore
import overlayFactory from 'react-bootstrap-table2-overlay';
import {changeSortowanie} from "../../../utilities/arrays/arraysUtilities";

interface Props  {
    rozrachunki: any,
    pageableObject?: any,
    handleChangeSizePerPage?: (e: number) => void;
    handleChangePage?: (e: any, a: any) => void;
    handleChangeSortowanie?: (sortowanie: any) => void,
    loading: boolean,
    sortProps?: any,
}

// const RozrachunkiTable: React.FC<Props> = ({rozrachunki, pageableObject, handleChangeSizePerPage, handleChangePage, loading}) => {
class RozrachunkiTable extends React.Component<Props> {

    formatMoneyRow = (cell: number, row: object) => {
        return <div className={"text-right"}>{this.formatPoTerminie((formatMoney(cell)), row)}</div>
    };

    formatPoTerminie = (cell: any, row: any) => {
        if (this.czyPoTerminie(row.opoznienie))
            return <span className={'text-danger'}>{cell}</span>
        else return cell
    };

    formatTerminPlatnosci = (cell: any, row:any) => {
        if (this.czyPoTerminie(row.opoznienie)) {
            return <span className={'text-danger'}>{cell} <IconExclamation/></span>
        } else return cell
    };

    czyPoTerminie = (opoznienie:number) =>{
        return (opoznienie >= 0)
    };

    ikonaSort = (typ: string) => {
        let {sortProps} = this.props;
        let index = -1;

        for (let i = 0; sortProps.length > i; i++)
        {
            if (sortProps[i].kolumna === typ)
            {
                index = i;
                break;
            }
        }

        if (index !== -1)
        {
            if (sortProps[index].kierunek === "asc")
            {
                return <i className="fa fa-sort-asc" />
            } else
                return <i className="fa fa-sort-desc" />
        } else
            return <i className="fa fa-sort" style={{color: '#aeaeae'}}/>

    }

    onSortChange = (sortName: any, sortOrder: any) => {

        // @ts-ignore
        this.props.handleChangeSortowanie(changeSortowanie(this.props.sortProps, sortName));

    }

    columns = [{
        dataField: 'id',
        text: 'Product ID',
        hidden: true
    }, {
        dataField: 'numerFaktury',
        text: 'Numer',
        formatter: this.formatPoTerminie,
        headerFormatter: () => {return <>Numer &nbsp;{this.ikonaSort('numer')}</>},
        headerStyle: {cursor: 'pointer'},
        onSort: this.onSortChange,
        sort: true
    }, {
        dataField: 'poz',
        text: 'Pozycja',
        headerFormatter: () => {return <>Pozycja &nbsp;{this.ikonaSort('poz')}</>},
        headerStyle: {cursor: 'pointer'},
        onSort: this.onSortChange,
        sort: true
    }, {
        dataField: 'magazyn',
        text: 'Magazyn'
    }, {
        dataField: 'doData',
        text: 'Data wystawienia',
        headerFormatter: () => {return <>Data wystawienia &nbsp;{this.ikonaSort('do_data')}</>},
        headerStyle: {cursor: 'pointer'},
        onSort: this.onSortChange,
        sort: true
    }, {
        dataField: 'kwota',
        text: 'Kwota',
        formatter: this.formatMoneyRow,
        headerFormatter: () => {return <>Kwota &nbsp;{this.ikonaSort('kwota')}</>},
        headerStyle: {cursor: 'pointer'},
        onSort: this.onSortChange,
        sort: true
    }, {
        dataField: 'opis',
        text: 'Opis',
        headerFormatter: () => {return <>Opis &nbsp;{this.ikonaSort('opis')}</>},
        headerStyle: {cursor: 'pointer'},
        onSort: this.onSortChange,
        sort: true
    }, {
        dataField: 'termPl',
        text: 'Termin płatności',
        formatter: this.formatTerminPlatnosci,
        headerFormatter: () => {return <>Termin płatności &nbsp;{this.ikonaSort('term_pl')}</>},
        headerStyle: {cursor: 'pointer'},
        onSort: this.onSortChange,
        sort: true
    }, {
        dataField: 'opoznienie',
        text: 'Opóźnienie',
        headerFormatter: () => {return <>Opóźnienie &nbsp;{this.ikonaSort('opoznienie')}</>},
        headerStyle: {cursor: 'pointer'},
        onSort: this.onSortChange,
        sort: true
    }, {
        dataField: 'nrRach',
        text: 'Nr rach',
        headerFormatter: () => {return <>Nr rach &nbsp;{this.ikonaSort('nr_rach')}</>},
        headerStyle: {cursor: 'pointer'},
        onSort: this.onSortChange,
        sort: true
    }];

    render(){
        let {rozrachunki, loading, pageableObject, handleChangePage, handleChangeSizePerPage} = this.props;

        return (
            <div id={'RozrachunkiTable'}>
                <TopCard heading={'Rozrachunki w systemie'} hasPadding={false} dataToExport={rozrachunki} enableExport={true} columnsNames={prettyColumns_Rozrachunki}>
                    <BootstrapTable remote keyField='id' data={ rozrachunki } columns={ this.columns } hover noDataIndication={'Brak pasujących wyników'}
                                    loading={loading}
                                    overlay={ overlayFactory({ spinner: true, styles: { overlay: (base: any) => ({...base, background: 'rgba(96,93,141,0.3)'}) } }) }
                                    pagination={(paginationFactory({
                                        page: (pageableObject.currentPage) + 1,
                                        sizePerPage: pageableObject.currentSize,
                                        totalSize: pageableObject.totalElements,
                                        sizePerPageList: [10, 12, 20, 50, 100],
                                        onSizePerPageChange: handleChangeSizePerPage,
                                        onPageChange: handleChangePage
                                    }))}
                    />
                </TopCard>
            </div>
            );
    }

};

export default RozrachunkiTable;