import React, {Component} from 'react';
import PopupWrapper from "./popups/PopupWrapper";
import defaultimg from '../resources/images/nopicture.png';
import {isMobileDevice} from "../utilities/rwd/rwdUtilities";
import OfertaService from "../services/OfertaService";
import TopCard from "./TopCard";
import {Badge, Button, Col, Form, FormGroup, InputGroup, Row} from "react-bootstrap";
import ProductImage from "../modules/produkt/components/ProductImage";
import ProductDescription from "../modules/produkt/components/ProductDescription";
import Shared_ProductCardAddForm from "./_reusable/ProductCardAddForm/Shared_ProductCardAddForm";
import TopLoading from "./loader/TopLoading";
import KoszykService from "../services/KoszykService";
import {displayNotificationError, displayNotificationInfo} from "../utilities/notifications/NotificationsManager";
import {
    famix_przeliczIloscDlaJednostekMiary,
    famix_zaokraglijIloscDlaJM1
} from "../utilities/famix_others/famix_operations";
import {NormalSelectStyles} from "../utilities/forms/jsStyles";
import Select from "react-select";
// @ts-ignore
import InputNumber from 'rc-input-number';
import {formatMoney} from "../utilities/formatters/formatMoney";
import {Decimal} from "decimal.js";
import {TextAlert} from "./typographyComponents/TextAlert/TextAlert";
import {IconCartPlus, IconRefresh} from "./icons/FontAwesome";

interface Props {
    children: any,
    item: any,
    //handleChangeInBasket: (previousAmount: any, amount: any, item: any) => void,
    odswiezIlosc: (amount: any, item: any, typOpak?: string) => void,
    // zmienInput: (item: any, ilosc: any) => void,
    edycja: boolean
    alwaysDostepne?: boolean,
    openPromocjePopup?: (indeks: any) => void,
}

interface State {
    biggerView: boolean,
    error: boolean,
    product: any,
    isLoading: boolean,
    zakupOpakowanieTyp: any,
    isError: any,
    ileValue: number,
    czyZaokraglonoIWyslanoEnterem: boolean
}

class TopIndeksWithBiggerView extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            biggerView: false,
            error: false,
            product: {
                cena: 0,
                cenaMetka: 0,
                ilosc: 0,
                indeks: "",
                indeksKodPask: "",
                jm: "",
                jm2: "",
                jm3: "",
                niezaimport: "",
                nrKat: "",
                nrKat2: "",
                nrKat3: "",
                przJm2: 0,
                przJm3: 0,
                sprzedazJm: "",
                uwagi: ""
            },
            isLoading: false,
            zakupOpakowanieTyp: this.valueSelect(this.props.item),
            czyZaokraglonoIWyslanoEnterem: false,
            isError: null,
            ileValue: 0
        }
    }
    // state = {
    //     biggerView: false,
    //     error: false,
    //     product: this.props.item,
    //     isLoading: false,
    //     zakupOpakowanieTyp: {},
    //     isError: null
    // };

    // componentDidMount() {
    //     this.setState({zakup})this.valueSelect(this.props.item)
    // }

    componentDidMount() {
        this.setState({ileValue: this.props.item.koszykIlosc, product: this.props.item});
    }

    onDeleteProductFromBasket = (item: any) => {
        // KoszykService.deletePozycjaKoszyka(item.indeks)
        //     .then((response: any) => {
        //         if (response && response.status < 300) {
        //             //this.setState({shouldKoszykUpdate: true}, () => this.odswiezIloscLokalnie(0));
        //             this.props.odswiezIlosc(0, item);
        //             displayNotificationInfo('Usunięto produkt z koszyka', 'Usunięto produkt o nazwie ' + item.nazwa)
        //             this.setState({biggerView: false})
        //         } else {
        //             displayNotificationError("Nie usunięto produktu z koszyka", "Wystąpił błąd podczas próby wykonania akcji")
        //         }
        //     })
        //     .catch((error: any) => {
        //         displayNotificationError("Nie usunięto produktu z koszyka", "Wystąpił błąd podczas próby wykonania akcji")
        //     })

        // 18.08.2021

        KoszykService.addOrEditPozycjaKoszyka(Object.assign(item, {ilosc: 0, sprzedazJm: item.zakupJm, czyEdycja: this.props.edycja}))
            .then((response: any) => {
                if (response && response.status < 300) {
                    this.props.odswiezIlosc(0, item);
                    displayNotificationInfo('Usunięto produkt z koszyka', 'Usunięto produkt o nazwie ' + item.nazwa)
                    this.setState({biggerView: false})
                } else {
                    displayNotificationError("Nie usunięto produktu z koszyka", "Wystąpił błąd podczas próby wykonania akcji")
                }

            })
            .catch((error: any) => {
                displayNotificationError("Wystąpił błąd podczas próby usunięcia produktu z koszyka", error.message)
            })
    };

    odswiezIloscLokalnie = (amount: number) => {
        let {product}: any = this.state;
        product.koszyk = amount;
        product.koszykIlosc = amount;
        this.setState({product})
    };

    renderJm() {
        let item = this.props.item
        // if (item.jm.indexOf("KG") > -1 || item.jm2.indexOf("DIS") > -1) {
        //     return item.jm2
        // } else {
        //     return item.jm
        // }

        if (item.jm.indexOf('KG') <= -1 && item.jm2.indexOf('DIS') <= -1) {
            return 'jednostka'
        }
        if (item.jm !== item.jm2)
            return 'opakowanie'

        if (item.jm2 !== item.jm3)
            return 'karton'
    }

    onAddOrChangeProductQuantityInBasket = (czyDodawanie: boolean, item: any, iloscSztuk: number, ilosc: number, typOpakowania: string, jm: string) => {

        //@ts-ignore
        let ilDoWyswietlenia = famix_przeliczIloscDlaJednostekMiary(typOpakowania, this.renderJm(), ilosc, item);
        //console.log(jm);

        let il = ilosc;

        KoszykService.addOrEditPozycjaKoszyka(Object.assign(item, {ilosc: il, sprzedazJm: jm, czyEdycja: this.props.edycja}))
            .then((response: any) => {
                if (response && response.status < 300) {
                    //this.setState({shouldKoszykUpdate: true}, () => this.odswiezIloscLokalnie(ilosc));
                    // this.props.odswiezIlosc(ilDoWyswietlenia, item);
                    this.props.odswiezIlosc(iloscSztuk, item, jm);
                    //this.props.item.koszykIlosc = ilosc;

                    if (czyDodawanie)  // dodano produkt
                    {
                        if (typOpakowania === 'jednostka')
                            displayNotificationInfo('Dodano produkt do koszyka', `Dodano produkt ${item.nazwa} w ilości ${il} ${jm}`)
                        else
                            displayNotificationInfo('Dodano produkt do koszyka', `Dodano produkt ${item.nazwa} w ilości ${ilosc} ${typOpakowania} (${iloscSztuk} ${jm})`)
                        this.setState({biggerView: false});
                        //this.props.zmienInput(item, il);

                        // let arr = this.state.klienci;
                        // //@ts-ignore
                        // let obj = arr.find((p: any) => p.klientId === id);
                        // //@ts-ignore
                        // obj['nazwa'] = null;
                        // this.setState({klienci: arr})
                    }
                    else  // edytowano produkt
                    {
                        if (typOpakowania === 'jednostka')
                            displayNotificationInfo('Zmieniono ilość produktu w koszyku', `Zmieniono ilość produktu ${item.nazwa} na ${il} ${jm}`)
                        else
                            displayNotificationInfo('Zmieniono ilość produktu w koszyku', `Zmieniono ilość produktu ${item.nazwa} na ${ilosc} ${typOpakowania} (${iloscSztuk} ${jm})`)
                        this.setState({biggerView: false});
                        //this.props.zmienInput(item, il);
                    }
                    //alert('ok1')
                    //this.props.zmienInput(item.indeks, il);
                } else {
                    if (czyDodawanie)
                        displayNotificationError("Nie dodano produktu do koszyka", "Wystąpił błąd podczas próby wykonania akcji")
                    else  // edytowano produkt
                        displayNotificationError("Nie edytowano ilości produktu w koszyka", "Wystąpił błąd podczas próby wykonania akcji")
                }

            })
            .catch((error: any) => {
                //displayNotificationError("Wystąpił błąd podczas próby dodania bądź edytowania ilości produktu w koszyku", error.message)
                if (czyDodawanie)
                    displayNotificationError("Nie dodano produktu do koszyka", "Wystąpił błąd podczas próby wykonania akcji")
                else  // edytowano produkt
                    displayNotificationError("Nie edytowano ilości produktu w koszyka", "Wystąpił błąd podczas próby wykonania akcji")
            })
    };


    generateSelectOptions = () => {

        let {item} = this.props;
        let options = [];
        if (item.jm.indexOf('KG') <= -1 && item.jm2.indexOf('DIS') <= -1) {
            options.push({value: 'jednostka', label: `Zakup po jednostce (${item.jm})`, jm: item.jm});
        }
        if (item.jm !== item.jm2)
            options.push({value: 'opakowanie', label: `Zakup po opakowaniu (${item.jm2})`, jm: item.jm2});

        if (item.jm2 !== item.jm3)
            options.push({value: 'karton', label: `Zakup po kartonie (${item.jm3})`, jm: item.jm3});

        return options
    };

    valueSelect = (item: any) => {
        if (item.zakupJm.indexOf('SZT') > -1) {
            return{value: 'jednostka', label: `Zakup po jednostce (${item.jm})`, jm: item.jm};
        }
        if (item.zakupJm.indexOf('DIS') > -1)
            return{value: 'opakowanie', label: `Zakup po opakowaniu (${item.jm2})`, jm: item.jm2};

        if (item.zakupJm.indexOf('KRT') > -1)
            return{value: 'karton', label: `Zakup po kartonie (${item.jm3})`, jm: item.jm3};

        if (item.zakupJm.indexOf('STD') > -1)
            return{value: 'opakowanie', label: `Zakup po opakowaniu (${item.jm3})`, jm: item.jm3};
    }

    onChangeZakupOpakowanieTyp = (typ: any) => {
        this.setState({zakupOpakowanieTyp: typ});
        //localStorage.setItem('OPAKOWANIE_TYP', JSON.stringify(typ))

        this.setState({
            //@ts-ignore
            ileValue: famix_przeliczIloscDlaJednostekMiary(this.state.zakupOpakowanieTyp.value, typ.value, this.state.ileValue, this.state.product)
        })
    };

    onClickInput = () => {
        //console.log('test click ' + this.state.amount)
        //let {pro} = this.props;
        let {ileValue, product} = this.state;

        if (ileValue===0){
            //@ts-ignore
            if (Boolean(document.getElementById('input-' + product.indeks))) {
                //@ts-ignore
                document.getElementById('input-' + product.indeks).value = '';
            }
        }
    }

    onChangeAmount = (amount: any) => {
        this.setState({ileValue: amount});
    };

    renderObliczCene = (zakupOpakowanieTyp: any) => {
        let {item} = this.props;

        if (zakupOpakowanieTyp.value === 'jednostka') {
            return item.cena
        }
        if (zakupOpakowanieTyp.value === 'opakowanie') {
            return item.cenaJm2
        }
        if (zakupOpakowanieTyp.value === 'karton') {
            return item.cenaJm3
        }
    };

    renderWygenerujTypZaCoCena = (zakupOpakowanieTyp: any) => {
        let {item} = this.props;

        if (zakupOpakowanieTyp.value === 'jednostka') {
            return item.jm
        }
        if (zakupOpakowanieTyp.value === 'opakowanie') {
            return `opakowanie (${famix_zaokraglijIloscDlaJM1(item.jm, item.ilJm2)} ${item.jm})`
        }
        if (zakupOpakowanieTyp.value === 'karton') {
            return `karton (${famix_zaokraglijIloscDlaJM1(item.jm, item.ilJm3)} ${item.jm})`
        }

    };

    onPressEnter = (e: any, czyWyslanoEnterem = false) => {
        e.preventDefault();

        let {ileValue, zakupOpakowanieTyp} = this.state;
        let {item} = this.props;

        let czyPoprawne = true;
        let czyPoprawnaIloscSztuk = true;


        let ilosc = ileValue; // to wysylamy do koszyka, zawsze bedzie w jednostce miary 1 (szt / kg)


        //@ts-ignore
        if (isNaN(ileValue) || ileValue === '' || ileValue === null) {
            czyPoprawne = false;
        } else {

            if (ileValue < 0 || ileValue > 99999999) {
                czyPoprawne = false
            }

            if (this.props.item.jm.indexOf('KG') === -1) {
                if (ileValue.toString().indexOf('.') > -1 || ileValue.toString().indexOf(',') > -1) {
                    czyPoprawne = false
                }
            }
        }


        if (!Boolean(zakupOpakowanieTyp)) {
            czyPoprawne = false;
        } else {

            // walidacja by byla odpowienia ilosc sztuk: ilosc % ilosc sztuk w jednostce miary opakowania badz kartonu (jesli jm === jm2)
            // jesli produkt ma jednostke miary  STD , walidacja jest pomijana

            if (zakupOpakowanieTyp.value === 'jednostka') {
                if (item.jm !== 'STD') {
                    if (item.jm === item.jm2) {
                        // ilosc sztuk w kartonach
                        console.log('jm = jm2');

                    } else {
                        // ilosc sztuk w opakowaniach
                        if (Decimal.mod(ileValue, item.ilJm2).toNumber() !== 0) {
                            //    czyPoprawne = false;
                            czyPoprawnaIloscSztuk = false;

                            let dzielenie = Decimal.div(ileValue, item.ilJm2).toNumber();
                            if (dzielenie < 1) {
                                // klient probuje zamowic mniej niz 1 karton towaru - zmiana na ilosc sztuk w jednym kartonie
                                ilosc = item.ilJm2
                            } else {
                                // klient probuje zamowic wiecej niz 1 karton towaru - zaokraglamy w dół. Jesli zamowi 3.3 kartonu, to system wklepie 3 kartony
                                ilosc = parseFloat((item.ilJm2 * Math.floor(dzielenie)).toFixed(2))
                            }

                        }
                    }
                }
            }
        }

        if (czyPoprawne) {

            let stareAmount = item.koszykIlosc;
            let stareJm = item.zakupJm;

            if (stareAmount === null || stareAmount === undefined || stareAmount === '')
                stareAmount = 0;

            if ((stareAmount === ileValue) && (stareJm === zakupOpakowanieTyp.jm)) {
                // brak akcji
            } else if (ileValue === 0) {
                this.onDeleteProductFromBasket(item)
            } else {

                // if (zakupOpakowanieTyp.value !== 'jednostka') {
                //     //@ts-ignore
                //     ilosc = famix_przeliczIloscDlaJednostekMiary(zakupOpakowanieTyp.value, 'jednostka', amount, item)
                //     //alert(ilosc);
                // }

                if (!czyPoprawnaIloscSztuk) {

                    console.log('nie poprawna ilosc');
                    console.log('poprawiona ilosc: '+ilosc);

                    if (czyWyslanoEnterem) {
                        this.setState({czyZaokraglonoIWyslanoEnterem: true})
                    }

                    this.forceUpdate(() => {

                        this.setState({ileValue: ilosc});

                        //@ts-ignore
                        if (Boolean(document.getElementById('input-' + item.indeks))) {
                            //@ts-ignore
                            document.getElementById('input-' + item.indeks).value = ilosc;
                        }

                    });

                    this.onAddOrChangeProductQuantityInBasket((stareAmount === 0 && ileValue > 0), item, ilosc, ilosc, zakupOpakowanieTyp.value, zakupOpakowanieTyp.jm)

                } else {
                    console.log('poprawna ilosc');
                    this.onAddOrChangeProductQuantityInBasket((stareAmount === 0 && ileValue > 0), item, ilosc, ileValue, zakupOpakowanieTyp.value, zakupOpakowanieTyp.jm)
                }
            }
        } else {
            displayNotificationError('Błąd dodania produktu do koszyka', 'Podano nieprawidłową ilość produktu')
        }

    };

    onClose = () => {
        this.setState({biggerView: false, ileValue: this.state.product.koszykIlosc, zakupOpakowanieTyp: this.valueSelect(this.props.item)});
    }

    render() {
        let {shouldKoszykUpdate, product, isLoading, isError, zakupOpakowanieTyp, biggerView, ileValue}: any = this.state;
        let {alwaysDostepne} = this.props;

        return (
            <>
                <div onClick={() => this.setState({biggerView: true, ileValue: this.state.product.koszykIlosc, zakupOpakowanieTyp: this.valueSelect(this.props.item)})} style={{textAlign: 'center'}}>
                {this.props.children}
                </div>
                {
                    biggerView &&
                    <PopupWrapper header={isMobileDevice() ? `Karta produktu` : `Karta produktu ${product.producent} ${product.nazwa}`} onClose={() => this.onClose()}>


                        <TopLoading isLoading={isLoading} isError={isError}
                                    errorMessage={'Wystąpił błąd z pobraniem karty produktu. Proszę sprawdzić poprawność adresu URL.'}>
                            {
                                product &&
                                <div id={'PRODUKT_MODULE'}>


                                        <Row>
                                            <Col sm={12} md={7} lg={7} xl={6}>
                                                <ProductImage item={product} openPromocjePopup={(indeks) =>
                                                    // @ts-ignore
                                                    this.props.openPromocjePopup(indeks)}/>
                                            </Col>
                                            <Col sm={12} md={5} lg={5} xl={6}>
                                                <ProductDescription item={product}/>
                                                {/*<Shared_ProductCardAddForm item={product}*/}
                                                {/*                           handleDeleteProductFromBasket={this.onDeleteProductFromBasket}*/}
                                                {/*                           handleAddOrChangeProductQuantityInBasket={this.onAddOrChangeProductQuantityInBasket}*/}
                                                {/*/>*/}

                                                <div id={'Shared_ProductCardAddForm'}>
                                                    <h1 id={'price-container'}>
                                                        <Badge variant={'warning'}>
                                                            {
                                                                formatMoney(this.renderObliczCene(zakupOpakowanieTyp))
                                                            }
                                                        </Badge>
                                                        <span>
                                                            / {this.renderWygenerujTypZaCoCena(zakupOpakowanieTyp)}
                                                        </span>
                                                    </h1>
                                                <div id={'form-container'}>
                                                    <label>
                                                        Wybierz typ zakupu oraz ilość towaru
                                                    </label>
                                                    <Form inline={true}>
                                                        <FormGroup>
                                                            <Select
                                                                id={'SelectZakupOpakowanieTyp'}
                                                                value={zakupOpakowanieTyp}
                                                                options={this.generateSelectOptions()}
                                                                isDisabled={(alwaysDostepne) ? false : !product.dostepne}
                                                                isClearable={false}
                                                                onChange={this.onChangeZakupOpakowanieTyp}
                                                                styles={NormalSelectStyles}
                                                                isSearchable={!isMobileDevice()}
                                                                menuPosition={"fixed"}
                                                                menuShouldBlockScroll={false}
                                                            />
                                                        </FormGroup>

                                                        {/*<label>{product.zakupJm}</label>*/}
                                                        {/*<input value={product.koszykIlosc}/>*/}

                                                        <FormGroup>
                                                            <InputNumber
                                                                value={ileValue}
                                                                onChange={this.onChangeAmount}
                                                                disabled={(alwaysDostepne) ? false : !product.dostepne}
                                                                precision={!(zakupOpakowanieTyp.value === 'SZT') ? 0 : (product.jm.indexOf('KG')) > -1 ? 2 : 0}
                                                                step={!(zakupOpakowanieTyp.value === 'SZT') ? 1 : (product.jm === product.jm2) ? product.ilJm3 : product.ilJm2}
                                                                min={0} max={99999999}
                                                                onPressEnter={this.onPressEnter}
                                                                id={'input-' + product.indeks}
                                                                // onBlur={() => {
                                                                //     if (this.state.czyZaokraglonoIWyslanoEnterem)
                                                                //         this.setState({ileValue: this.props.item.koszyk, czyZaokraglonoIWyslanoEnterem: false})
                                                                // }}
                                                                onClick={this.onClickInput}
                                                            />
                                                        </FormGroup>

                                                        <FormGroup>
                                                            <Button variant={'primary'} onClick={this.onPressEnter} disabled={(alwaysDostepne) ? false : !product.dostepne}>
                                                                {
                                                                    (product.koszykIlosc && product.koszykIlosc > 0) ?
                                                                        <><IconRefresh/> Zmień</>
                                                                        :
                                                                        <><IconCartPlus/> Dodaj</>
                                                                }
                                                            </Button>
                                                        </FormGroup>

                                                        {
                                                            (product.koszykIlosc && product.koszykIlosc > 0) ?
                                                                <TextAlert type={'info'}>Produkt jest już dodany do koszyka - możesz zmienić jego ilość</TextAlert>
                                                                :
                                                                ""
                                                        }

                                                    </Form>
                                                </div>
                                                </div>
                                            </Col>
                                        </Row>



                                </div>
                            }
                        </TopLoading>


                    </PopupWrapper>
                }
        </>
    );
    }
}

export default TopIndeksWithBiggerView;
