import React, {Component} from 'react';
import {Col, Row} from "react-bootstrap";
import PromocjaGazetkaSlider from "./PromocjaGazetkaSlider";
import PromocjaGazetkaOferta from "./PromocjaGazetkaOferta";
import PromocjaHeader from "../PromocjaHeader";
import PromocjaGazetkaNavigation from "./PromocjaGazetkaNavigation";
import {
    displayNotificationError, displayNotificationInfo,
    displayNotificationWarning
} from "../../../utilities/notifications/NotificationsManager";
import {TextAlert} from "../../../sharedComponents/typographyComponents/TextAlert/TextAlert";
import PromocjeService from "../../../services/PromocjeService";
import KoszykService from "../../../services/KoszykService";
// import '../../../styles/modules/PromocjeModule.less';

interface Props {
    gazetka: any,
    strony: any,
    czyEdycja: boolean,
    // handleAddProductToBasketByClick: (product: any, currentPageCounter: number) => void,
    shouldKoszykUpdate: () => void
}

class PromocjaGazetkaContainer extends Component<Props> {

    state = {
        currentPageCounter: 1,
        currentArea: null,
        produkty: [],
        loadingProdukty: true,
        loadingProduktyError: false,
        loading: false
    };

    componentDidMount() {
        // document.addEventListener('keydown', this.handleKeydown)
        this.getProduktyNaStronie(1);
    }

    // handleKeydown = (key: any) => {
    //     if (key.keyCode === 37) {
    //         // left arrow - previous
    //         this.onChangeCurrentPageCounter('-')
    //     }
    //     if (key.keyCode === 39) {
    //         // right arrow - next
    //         this.onChangeCurrentPageCounter('+')
    //     }
    // };

    getProduktyNaStronie = (currentPageCounter: number) => {
        this.setState({loadingProduktyError: false});

        let dane = {
            // @ts-ignore
            idGaz: this.props.gazetka.idUm,
            strona: currentPageCounter,
            czyEdycja: this.props.czyEdycja
        }

        PromocjeService.getIndeksyNaStronie(dane).then((response) => {

            this.setState({
                produkty: response.data,
                loadingProdukty: false,
                loadingProduktyError: false,
                loading: false
            });

        }).catch((e) => {
            this.setState({
                loadingProdukty: false,
                loadingProduktyError: true,
                loading: false
            });
            displayNotificationError('Błąd', '');
        })

    };

    onChangeCurrentPageCounter = (type: string) => {

        if (type === '-' && this.state.currentPageCounter > 0) {
            this.setState((prevState: any) => ({currentPageCounter: prevState.currentPageCounter - 1, loading: true}), () => this.getProduktyNaStronie(this.state.currentPageCounter));
        }
        if (type === '+' && this.state.currentPageCounter < this.props.strony.length) {
            this.setState((prevState: any) => ({currentPageCounter: prevState.currentPageCounter + 1, loading: true}), () => this.getProduktyNaStronie(this.state.currentPageCounter));
        }

    };


    onChangeCurrentArea = (area: any) => {
        this.setState({currentArea: area})
    };

    // onClickCurrentArea = (area: any) => {
    //     if (area.product.czyDostepny) {
    //         if (document.getElementById('row-product-' + area.product.id)) {
    //             //@ts-ignore
    //             let inputNumber: any = (document.getElementById('row-product-' + area.product.id).firstChild.firstChild.firstChild);
    //             inputNumber.setAttribute('style', 'transition:all ease 0.2s; border-color:#4F4D83; box-shadow:1px 1px 3px #4F4D83 ');
    //             this.props.handleAddProductToBasketByClick(area.product, this.state.currentPageCounter);
    //             setTimeout(() => {
    //                 inputNumber.setAttribute('style', 'border-color: #D9D9D9; box-shadow: none')
    //             }, 150)
    //         }
    //     } else {
    //         displayNotificationWarning('Produkt niedostępny', 'Nie można dodać tego produktu do koszyka')
    //     }
    // };

    onChangeInBasket = (previousAmount: any, amount: any, item: any, opakowanie: string) => {

        if (amount === previousAmount) {
            // brak zmiany
        }
        if (amount === 0 && previousAmount !== 0) {
            this.deleteFromBasket(item);
        }
        if ((amount !== previousAmount && previousAmount === 0) || (amount !== previousAmount && previousAmount > 0 && amount > 0)) {
            // dodano produkt do koszyka lub zmieniono ilość produktu w koszyku

            KoszykService.addOrEditPozycjaKoszyka(Object.assign(item, {ilosc: amount, sprzedazJm: opakowanie, czyEdycja: this.props.czyEdycja}))
                .then((response: any) => {

                    let jm = item.zakupJm;

                    if (response && response.status < 300) {
                        this.odswiezIloscWOfercieLokalnie(amount, item, opakowanie);
                        this.props.shouldKoszykUpdate();
                        // this.setState({shouldKoszykUpdate: true}, () => this.odswiezIloscWOfercieLokalnie(amount, item, opakowanie));
                        if (amount !== previousAmount && previousAmount === 0)  // dodano produkt
                            displayNotificationInfo('Dodano produkt do koszyka', `Dodano produkt ${item.nazwa} w ilości ${amount} ${jm}`)
                        if (amount !== previousAmount && previousAmount > 0 && amount > 0)  // edytowano produkt
                            displayNotificationInfo('Zmieniono ilość produktu w koszyku', `Zmieniono ilość produktu ${item.nazwa} z ${previousAmount} na ${amount} ${jm}`)

                    } else {
                        if (amount !== previousAmount && previousAmount === 0)
                            displayNotificationError("Nie dodano produktu do koszyka", "Wystąpił błąd podczas próby wykonania akcji")
                        if (amount !== previousAmount && previousAmount > 0 && amount > 0)
                            displayNotificationError("Nie edytowano ilości produktu w koszyka", "Wystąpił błąd podczas próby wykonania akcji")
                    }

                })
                .catch((error: any) => {
                    if (amount !== previousAmount && previousAmount === 0)
                        displayNotificationError("Nie dodano produktu do koszyka", "Wystąpił błąd podczas próby wykonania akcji")
                    if (amount !== previousAmount && previousAmount > 0 && amount > 0)
                        displayNotificationError("Nie edytowano ilości produktu w koszyka", "Wystąpił błąd podczas próby wykonania akcji")
                })
        }
    };

    deleteFromBasket = (item: any) => {
        KoszykService.addOrEditPozycjaKoszyka(Object.assign(item, {ilosc: 0, sprzedazJm: item.zakupJm, czyEdycja: this.props.czyEdycja}))
            .then((response: any) => {
                if (response && response.status < 300) {
                    this.odswiezIloscWOfercieLokalnie(0, item);
                    this.props.shouldKoszykUpdate();
                    // this.setState({shouldKoszykUpdate: true}, () => {this.odswiezIloscWOfercieLokalnie(0, item); }); //this.pobierzPodsumowanieKoszyka();
                    displayNotificationInfo('Usunięto produkt z koszyka', 'Usunięto produkt o nazwie ' + item.nazwa)
                } else {
                    displayNotificationError("Nie usunięto produktu z koszyka", "Wystąpił błąd podczas próby wykonania akcji")
                }

            })
            .catch((error: any) => {
                displayNotificationError("Wystąpił błąd podczas próby usunięcia produktu z koszyka", error.message)
            })
    };

    odswiezIloscWOfercieLokalnie = (amount: any, item: any, typOpak?: string) => {
        let {produkty} = this.state;
        let produkt: any = produkty.find((p: any) => p.indeks === item.indeks);

        if (Boolean(produkt)) {
            produkt.koszyk = amount
            produkt.koszykIlosc = amount
            if(typOpak) produkt.zakupJm = typOpak
        }

        this.setState({produkty})
        // this.setState({produkty, zmianaOferta: !this.state.zmianaOferta})
    };

    render() {

        let {produkty, loadingProdukty, loadingProduktyError, loading} = this.state;
        let {strony, gazetka, czyEdycja} = this.props;

        return (
            <div id={'PromocjaGazetkaContainer'}>
                <Row>
                    {
                        strony.length > 0 &&
                        <Col md={12} lg={12} xl={6}>
                            <PromocjaGazetkaSlider
                                strona={strony[this.state.currentPageCounter-1]}
                                handleChangeCurrentArea={this.onChangeCurrentArea}
                                // handleClickCurrentArea={this.onClickCurrentArea}
                                current={this.state.currentPageCounter-1}
                                disablePrev={this.state.currentPageCounter === 1}
                                disableNext={this.state.currentPageCounter === strony.length}
                                handleChangecurrentPageCounter={this.onChangeCurrentPageCounter}
                            />
                            {/*<PromocjaGazetkaNavigation current={this.state.currentPageCounter-1}*/}
                            {/*                           disablePrev={this.state.currentPageCounter === 1}*/}
                            {/*                           disableNext={this.state.currentPageCounter === strony.length}*/}
                            {/*                           handleChangecurrentPageCounter={this.onChangeCurrentPageCounter}/>*/}
                        </Col>
                    }
                    <Col md={12} lg={12} xl={6}>
                        {
                            strony.length > 0 ?
                                <div className={'gazOfer'}>
                                    {/*<PromocjaHeader promocja={gazetka}/>*/}
                                    <PromocjaGazetkaOferta
                                        produktyStrona={produkty}
                                        czyEdycja={czyEdycja}
                                        handleChangeInBasket={this.onChangeInBasket}
                                        odswiezIloscLokalnie={this.odswiezIloscWOfercieLokalnie}
                                        loadingProdukty={loadingProdukty}
                                        loadingProduktyError={loadingProduktyError}
                                        loading={loading}
                                    />
                                </div>
                                :
                                <TextAlert type={'info'}>W gazetce nie zdefiniowano stron ani produktów</TextAlert>
                        }
                    </Col>
                </Row>
            </div>
        );
    }
}

export default PromocjaGazetkaContainer;