import React from 'react';
import logo from "../../resources/images/famixlogo.png";
import {Link} from 'react-router-dom';
import {Button} from "react-bootstrap";

const NotFoundModule = () =>
    <div style={{textAlign: 'center', marginTop: '50px'}}>

        <img src={logo} alt={'logo'} style={{maxWidth: '300px'}}/>
        <div className={'loginContainer'} style={{textAlign: 'center', color: '#434242', marginTop: '20px'}}>
            <h1>404</h1>
            <h5>Podana strona nie istnieje.</h5>
            <Link to={'/'}>
                <Button variant={'primary'}>
                    Przejdź do strony głównej
                </Button>
            </Link>
        </div>
    </div>

export default NotFoundModule;
