import React from 'react';
import Select from "react-select";
import {HeaderSmallerSelectStyles} from "../../../utilities/forms/jsStyles";
import {isMobileDevice, isSMMobileDevice} from "../../../utilities/rwd/rwdUtilities";
import localStorageTypes from "../../../utilities/localStorage/localStorageTypes";
import {SiecContext} from "../../../SiecContext";
import LoginService from "../../../services/LoginService";
import {Button} from "react-bootstrap";
import SieciSelectAdmin from "./SieciSelectAdmin";

interface Props {
    handleChangeSiec: (siecObj: any) => void;
    isAdminPanel?: boolean;
}

class SieciSelectHeaderComponent extends React.Component<Props> {

    znajdzSiecPoKontrahencie = (kontrahent: string) => {
        let siec = null;
        //@ts-ignore
        let sieci = JSON.parse(localStorage.getItem(localStorageTypes.CURRENT_SIECI_ARRAY));
        if (sieci.find((s: any) => s.kontrahent = kontrahent))
            siec = sieci.find((s: any) => s.kontrahent = kontrahent);
        return siec
    };

    render() {

        let siec = this.context;

        return (
            <div id={'SieciSelectHeaderComponent'}>
                {
                (LoginService.czyKlientWlasciciel())?
                <Select
                    getOptionLabel={({kontrahent}: any) => kontrahent}
                    getOptionValue={({kontrahent}: any) => kontrahent}
                    value={
                        // Boolean(siec.kontrahent) ?
                        //     this.znajdzSiecPoKontrahencie(siec.kontrahent)
                        //     :
                        //     LoginService.checkIfUserIsLogged() ?
                                {"kontrahent": LoginService.getCurrentUser().kontrahent}
                                // : null
                    }
                    options={
                        Boolean(localStorage.getItem(localStorageTypes.CURRENT_SIECI_ARRAY)) ?
                            //@ts-ignore
                            JSON.parse(localStorage.getItem(localStorageTypes.CURRENT_SIECI_ARRAY)) : []
                    }
                    onChange={(e: any) => {
                        LoginService.removeUserFromSessionBezUzytkownika();
                        siec.changeKontrahent(e);
                        //@ts-ignore
                        this.props.handleChangeSiec(e)
                    }}
                    styles={HeaderSmallerSelectStyles}
                    isClearable={false}
                    placeholder={'Wybierz...'}
                    isSearchable={!isMobileDevice()}
                    // isDisabled={LoginService.czyKlientWlasciciel()}
                />
                    :
                    <>
                        {
                            (!LoginService.czyAdmin()) &&
                            <h6>{LoginService.getCurrentUser().kontrahent}</h6>
                        }
                        {
                            (!this.props.isAdminPanel && LoginService.czyAdmin() ) &&
                            <SieciSelectAdmin handleChangeSiec={this.props.handleChangeSiec}/>
                        }
                        {
                            (this.props.isAdminPanel && isSMMobileDevice()) &&
                            <Button href={'/'} size={'sm'} style={{height: '27px', marginTop: '8px', marginRight: '5px'}}>Widok klienta</Button>
                        }
                        {
                            (LoginService.czyAdmin() && isSMMobileDevice()) &&
                            <Button href={'/admin'} size={'sm'} style={{height: '27px', marginTop: '8px', marginRight: '5px'}}>ADMIN PANEL</Button>
                        }
                    </>

                }
            </div>
        );
    }
}

SieciSelectHeaderComponent.contextType = SiecContext;

export default SieciSelectHeaderComponent;
