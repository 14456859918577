import React from 'react';
import {Link} from 'react-router-dom'
import {NavbarLinks} from "../../../utilities/applicationLinks/NavbarLinks";
import {AdminNavbarLinks} from "../../../utilities/applicationLinks/AdminNavbarLinks";
import CurrentUserHeaderComponent from "./CurrentUserHeaderComponent";
import SieciSelectHeaderComponent from "./SieciSelectHeaderComponent";
import {isSMMobileDevice} from "../../../utilities/rwd/rwdUtilities";

interface Props {
    isToggle: boolean,
    isAdminPanel: boolean,
    handleChangeSiec: (siecObj: any) => void;
    nowePowiadomienia: any;
}

const NavbarComponent: React.FC<Props> = ({isToggle,isAdminPanel,handleChangeSiec, nowePowiadomienia}) => {

    const links = (isAdminPanel)? AdminNavbarLinks : NavbarLinks;
    let location = window.location.pathname

    const propsyfix = {
        nowePowiadomienia: nowePowiadomienia
    }

    if(location==='/') location ='/oferta'

    return (
        <nav className={isToggle ? 'toggled' : ''}>
            <ul>
                {
                    links.map(link =>
                        <li key={link.key} className={location.indexOf(link.url)>-1 ? 'active' : ''}>
                            <Link to={link.url}>{(link.icon) && <i className={link.icon}/>}&nbsp; {link.name}</Link>
                        </li>
                    )
                }
            </ul>
            {
                (isSMMobileDevice()) &&
                <SieciSelectHeaderComponent handleChangeSiec={handleChangeSiec} isAdminPanel={isAdminPanel}/>
            }
            <CurrentUserHeaderComponent {...propsyfix}/>
        </nav>
    );
};

export default NavbarComponent;
