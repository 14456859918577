import React from 'react';
import TopExport from "./exports/TopExport";
import {IconDownload, IconExportPdf, IconFileCSV, IconSettings} from "./icons/FontAwesome";
import PobierzOferte from "./exports/PobierzOferte";
import TopExportKoszyk from "./exports/TopExportKoszyk";

interface Props {
    children: any,
    heading: string,
    hasPadding: boolean,
    enableExport?: boolean,
    enableExportKoszyk?: boolean,
    pobierzOferte?: boolean,
    dataToExport?: any,
    columnsNames?: any,
    parametryPdf?: object[],
    rightComponent?: any
}

/*
   aby uzyć opcji exportu nalezy podać:
   - enableExprot = true,
   - dataToExport = array of data,
   - *columnNames - opcjonalnie, aby kolumny zawierały przyjazne etykiety
                    [{value:'idPr',label:'ID_Produktu'}]  - pattern
                    nie można uzywać spacji ani polskich znaków!
                    jesli columnNames nie bedzie podane to będą wzięte nazwy pól z api
*/

const TopCard: React.FC<Props> = ({children, heading, hasPadding, enableExport = false, enableExportKoszyk = false, pobierzOferte = false, rightComponent, parametryPdf, dataToExport = [], columnsNames = []}) => {
    return (
        <div className={'TopCard'}>
            <h1>
                {
                    enableExport &&
                    <TopExport data={dataToExport} columnsNames={columnsNames} parametryPdf={parametryPdf}/>
                }
                {
                    enableExportKoszyk &&
                    <TopExportKoszyk/>
                }
                {
                    pobierzOferte &&
                    <PobierzOferte/>
                }
                <span className={enableExport?'with-export':''}>
                    {heading}
                </span>
                {
                    rightComponent && rightComponent
                }
            </h1>
            <div className={hasPadding ? "top-card-content withPadding" : 'top-card-content'}>
                {children}
            </div>
        </div>
    );
};

export default TopCard;
