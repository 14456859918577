import React from 'react';
import LayoutComponent from "../../sharedComponents/layout/LayoutComponent";
import {Col, Row} from "react-bootstrap";
import ProductImage from "./components/ProductImage";
import ProductDescription from "./components/ProductDescription";
import {isMobileDevice} from "../../utilities/rwd/rwdUtilities";
import TopCard from "../../sharedComponents/TopCard";
import Shared_ProductCardAddForm from '../../sharedComponents/_reusable/ProductCardAddForm/Shared_ProductCardAddForm';
import OfertaService from "../../services/OfertaService";
import TopLoading from "../../sharedComponents/loader/TopLoading";
import KoszykService from "../../services/KoszykService";
import {displayNotificationError, displayNotificationInfo} from "../../utilities/notifications/NotificationsManager";
import {IconMultistar, IconTimes} from "../../sharedComponents/icons/FontAwesome";
import Shared_ProductImage
    from "../../sharedComponents/_reusable/ProductImageLazyLoadingWithErrorHandling/Shared_ProductImage";

interface MatchParams {
    path: string;
}

export interface RouteComponentProps<MatchParams> {
    match: any;
    history: any;
}

interface Props extends RouteComponentProps<MatchParams> {
}


class ProduktModule extends React.Component<Props> {
    state = {
        shouldKoszykUpdate: false,
        product: null,
        isLoading: false,
        isError: null,
        czyEdycja: false
    };

    componentDidMount() {

        if (isMobileDevice())
            window.scrollTo(0, 0);

        let {match} = this.props;

        if (match)
            if (match.params)
                if (match.params.indeks)
                    this.findProductByIndex(match.params.indeks)

        this.pobierzPodsumowanieKoszyka();

    }

    pobierzPodsumowanieKoszyka = () => {
        KoszykService.getStanKoszyka()
            .then((response: any) => {
                this.setState({
                    // stanKoszyka: response.data,
                    czyEdycja: (response.data.numer !== 0),
                    // edycjaSymbol: response.data.symbol,
                    // edycjaNumer: response.data.numer
                });
            })
            .catch((error: any) => {
                console.log('error');
            })
    };

    findProductByIndex = (index: any) => {

        this.setState({
            isLoading: true,
            isError: null
        });

        OfertaService.getOferta(0, 1, {indeks: index, czyEdycja: this.state.czyEdycja})
            .then((response: any) => {
                let product = null;

                if (response.status < 300)
                    if (response.data)
                        if (response.data.content)
                            if (response.data.content.length === 1) {
                                product = response.data.content[0]
                            }


                if (product !== null) {
                    this.setState({product: product, isLoading: false});
                } else {
                    this.setState({isError: true, isLoading: false});
                }
            })
            .catch((error: any) => {
                this.setState({isError: true, isLoading: false});
            })

    };

    renderBreadcrumbs = () => {
        let breadcrumbs = [{name: 'Oferta', url: '/oferta'}];
        let {params} = this.props.match;

        breadcrumbs.push({
            name: 'Produkt ' + params.indeks,
            url: ''
        });

        return breadcrumbs;
    };

    odswiezIloscLokalnie = (amount: number) => {
        let {product}: any = this.state;
        product.koszyk = amount;
        product.koszykIlosc = amount;
        this.setState({product})
    };

    onDeleteProductFromBasket = (item: any) => {
        // KoszykService.deletePozycjaKoszyka(item.indeks)
        //     .then((response: any) => {
        //         if (response && response.status < 300) {
        //             this.setState({shouldKoszykUpdate: true}, () => this.odswiezIloscLokalnie(0));
        //             displayNotificationInfo('Usunięto produkt z koszyka', 'Usunięto produkt o nazwie ' + item.nazwa)
        //         } else {
        //             displayNotificationError("Nie usunięto produktu z koszyka", "Wystąpił błąd podczas próby wykonania akcji")
        //         }
        //     })
        //     .catch((error: any) => {
        //         displayNotificationError("Nie usunięto produktu z koszyka", "Wystąpił błąd podczas próby wykonania akcji")
        //     })

        // 02.09.2021

        KoszykService.addOrEditPozycjaKoszyka(Object.assign(item, {ilosc: 0, sprzedazJm: item.zakupJm, czyEdycja: this.state.czyEdycja}))
            .then((response: any) => {
                if (response && response.status < 300) {
                    this.setState({shouldKoszykUpdate: true}, () => this.odswiezIloscLokalnie(0));
                    displayNotificationInfo('Usunięto produkt z koszyka', 'Usunięto produkt o nazwie ' + item.nazwa)
                } else {
                    displayNotificationError("Nie usunięto produktu z koszyka", "Wystąpił błąd podczas próby wykonania akcji")
                }

            })
            .catch((error: any) => {
                displayNotificationError("Wystąpił błąd podczas próby usunięcia produktu z koszyka", error.message)
            })
    };

    onAddOrChangeProductQuantityInBasket = (czyDodawanie: boolean, item: any, iloscSztuk: number, ilosc: number, typOpakowania: string, jm: string) => {
        console.log(typOpakowania)
        console.log(ilosc)
        console.log(iloscSztuk)

        let il = ilosc

        // if (typOpakowania
        //
        // ga=== 'karton'){
        //     il = iloscSztuk
        // }

        // if (typOpakowania !== 'jednostka') {
        //     if (typOpakowania === 'karton') {
        //         il = item.ilJm3 / item.ilJm2
        //         console.log('karton')
        //     } else {
        //         if (item.jm.indexOf('KG') > -1) {
        //             il = iloscSztuk / item.ilJm2
        //             console.log('kg')
        //         } else {
        //             il = iloscSztuk
        //             console.log('iloscSztuk')
        //         }
        //     }
        // }

        console.log('IL: ' + il)

        console.log(item)
        console.log(jm)

        KoszykService.addOrEditPozycjaKoszyka(Object.assign(item, {ilosc: il, sprzedazJm: jm, czyEdycja: this.state.czyEdycja}))
            .then((response: any) => {
                if (response && response.status < 300) {
                    this.setState({shouldKoszykUpdate: true}, () => this.odswiezIloscLokalnie(ilosc));

                    if (czyDodawanie)  // dodano produkt
                        if (typOpakowania === 'jednostka')
                            displayNotificationInfo('Dodano produkt do koszyka', `Dodano produkt ${item.nazwa} w ilości ${iloscSztuk} ${item.jm}`)
                        else
                            displayNotificationInfo('Dodano produkt do koszyka', `Dodano produkt ${item.nazwa} w ilości ${ilosc} ${typOpakowania} (${iloscSztuk} ${item.jm})`)
                    else  // edytowano produkt
                    if (typOpakowania === 'jednostka')
                        displayNotificationInfo('Zmieniono ilość produktu w koszyku', `Zmieniono ilość produktu ${item.nazwa} na ${il} ${item.jm}`)
                    else
                        displayNotificationInfo('Zmieniono ilość produktu w koszyku', `Zmieniono ilość produktu ${item.nazwa} na ${ilosc} ${typOpakowania} (${iloscSztuk} ${item.jm})`)

                } else {
                    if (czyDodawanie)
                        displayNotificationError("Nie dodano produktu do koszyka", "Wystąpił błąd podczas próby wykonania akcji")
                    else  // edytowano produkt
                        displayNotificationError("Nie edytowano ilości produktu w koszyka", "Wystąpił błąd podczas próby wykonania akcji")
                }

            })
            .catch((error: any) => {
                //displayNotificationError("Wystąpił błąd podczas próby dodania bądź edytowania ilości produktu w koszyku", error.message)
                if (czyDodawanie)
                    displayNotificationError("Nie dodano produktu do koszyka", "Wystąpił błąd podczas próby wykonania akcji")
                else  // edytowano produkt
                    displayNotificationError("Nie edytowano ilości produktu w koszyka", "Wystąpił błąd podczas próby wykonania akcji")
            })
    };

    formatPromocjaHeader = () => {
        return(<a href={'/oferta'}><div id={'popup-close-icon'} title={'Kliknij, aby wrócić do oferty'}>
                <IconTimes/>
            </div></a>
        )
    };

    render() {

        let {shouldKoszykUpdate, product, isLoading, isError}: any = this.state;

        return (
            <LayoutComponent breadcrumbs={this.renderBreadcrumbs()}
                             shouldKoszykUpdate={shouldKoszykUpdate}
                             handleShouldKoszykUpdateChange={() => this.setState({shouldKoszykUpdate: false})}
                             handleChangeSiec={(siecObj) =>
                                 //@ts-ignore
                                 this.findProductByIndex(this.props.match.params.indeks)
                             }
            >
                <TopLoading isLoading={isLoading} isError={isError}
                            errorMessage={'Wystąpił błąd z pobraniem karty produktu. Proszę sprawdzić poprawność adresu URL.'}>
                    {
                        product &&
                        <div id={'PRODUKT_MODULE'}>
                            <TopCard
                                heading={isMobileDevice() ? `Karta produktu` : `Karta produktu ${product.producent} ${product.nazwa}`}
                                hasPadding={true} rightComponent={this.formatPromocjaHeader()}>
                                {/*<div onClick={()=>this.props.history.goBack()}>Powrót</div>*/}
                                <Row>
                                    <Col sm={12} md={7} lg={7} xl={7}>
                                        {/*<ProductImage item={product}/>*/}
                                        <div id={'ProductImage'}>
                                            {
                                                (Boolean(product.upustProcentowy) && product.upustProcentowy > 0) &&
                                                <div className={'product-promocja'}>
                                                    <div className={'triangle'}></div>
                                                    <div className={'inner'}>
                                                        <IconMultistar/> Promocja
                                                    </div>
                                                </div>
                                            }
                                            <Shared_ProductImage item={product} />
                                        </div>
                                    </Col>
                                    <Col sm={12} md={5} lg={5} xl={5}>
                                        <ProductDescription item={product}/>
                                        <Shared_ProductCardAddForm item={product}
                                                                   handleDeleteProductFromBasket={this.onDeleteProductFromBasket}
                                                                   handleAddOrChangeProductQuantityInBasket={this.onAddOrChangeProductQuantityInBasket}
                                        />
                                    </Col>
                                </Row>
                            </TopCard>
                        </div>
                    }
                </TopLoading>

            </LayoutComponent>
        );
    }
}

export default ProduktModule;
