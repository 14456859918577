import React from 'react';
import TopCard from "../../../sharedComponents/TopCard";
import {Card, Col, Row} from 'react-bootstrap';
import RozrachunkiService from "../../../services/RozrachunkiService";
import TopLoading from "../../../sharedComponents/loader/TopLoading";
import {formatMoney} from "../../../utilities/formatters/formatMoney";
import {Link} from "react-router-dom";

class InformacjeFakturyComponent extends React.Component {

    state = {
        naleznosci: null,
        isLoading: false,
        isError: false,
    };

    componentDidMount() {
        this.pobierzNaleznosci()
    }

    pobierzNaleznosci = () => {
        this.setState({
            isLoading: true,
            isError: false,
        });
        RozrachunkiService.getNaleznosci()
            .then((response: any) => {
                if (response && response.status < 300) {
                    this.setState({
                        naleznosci: response.data,
                        isLoading: false,
                        isError: false,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                        isError: true,
                    });
                }
            })
            .catch((error: any) => {
                this.setState({
                    isLoading: false,
                    isError: true,
                });
            })
    };

    render() {

        let {naleznosci, isLoading, isError}: any = this.state;

        return (
            <div id={'InformacjeFakturyComponent'}>
                <TopCard heading={'Faktury'} hasPadding={true}>

                    <TopLoading isLoading={isLoading} isError={isError} isBig={false}>
                        {
                            (naleznosci) &&
                            <>  <Link to={'/rozrachunki'} className={'link'}>
                                    <Card>
                                        <Row>
                                            <Col xs={12} sm={6} md={5} lg={5} xl={4} style={{padding: '0'}}>
                                                <div className={'color-box nierozliczone'}>
                                                    <label>Do zapłaty ogółem</label>
                                                    <span>
                                                        {formatMoney(naleznosci.doZaplatyOgolem)}
                                                  </span>
                                                </div>
                                            </Col>
                                            <Col xs={12} sm={6} md={7} lg={7} xl={8}>
                                                <div className={'text-box-info'}>
                                                    <h6>
                                                        Ilość dokumentów do zapłaty: <span className={'text-bold'}>{naleznosci.iloscDokumentow}</span>
                                                    </h6>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Link>
                                <Link to={'/rozrachunki'} className={'link'}>
                                    <Card style={{marginBottom: '0'}}>
                                        <Row>
                                            <Col xs={12} sm={6} md={5} lg={5} xl={4} style={{padding: '0'}}>
                                                <div className={'color-box '+(naleznosci.doZaplatyPoTerminie === 0? 'terminok' : 'poterminie')}>
                                                    <label>Po terminie</label>
                                                    <span>
                                                      {formatMoney(naleznosci.doZaplatyPoTerminie)}
                                                  </span>
                                                </div>
                                            </Col>
                                            <Col xs={12} sm={6} md={7} lg={7} xl={8}>
                                                <div className={'text-box-info'}>
                                                    {(naleznosci.doZaplatyPoTerminie === 0?
                                                    <h6>Dziękujemy za terminowe płatnośći</h6>
                                                    :
                                                    <>
                                                        <h6>
                                                            Ilość przeterminowanych dokumentów: <span className={'text-bold'}>{naleznosci.iloscDokumentowPoTerminie}</span>
                                                        </h6>
                                                        <h6>
                                                            Termin najstarszej upłynął dnia: <span className={'text-bold'}>{naleznosci.terminNajstarszego}</span>
                                                        </h6>
                                                    </>
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Link>
                            </>
                        }
                    </TopLoading>

                </TopCard>
            </div>
        );
    }
}

export default InformacjeFakturyComponent;
