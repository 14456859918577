import React from 'react';
import TopCard from "../../../sharedComponents/TopCard";
import {formatMoney} from "../../../utilities/formatters/formatMoney";
import {prettyColumns_Zamowienia} from "../../../utilities/exports/prettyColumnToExport";
import {IconExportFile, IconList, IconMinusSquare} from "../../../sharedComponents/icons/FontAwesome";
import {changeNestedArrayToFlat, changeSortowanie} from "../../../utilities/arrays/arraysUtilities";
import {TableActionsButtons_AnyAction} from "../../../sharedComponents/tableComponents/tableButtons";
import {TableFormatters_formatBoolean} from "../../../sharedComponents/tableComponents/tableFormatters";
import ZamowieniaService from "../../../services/ZamowieniaService";
import {displayNotificationError, displayNotificationInfo} from "../../../utilities/notifications/NotificationsManager";
import KoszykService from "../../../services/KoszykService";
import {Button, Modal} from "react-bootstrap";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import ZamowieniaExpand from "./tableComponents/ZamowieniaExpand";
// @ts-ignore
import overlayFactory from 'react-bootstrap-table2-overlay';

interface Props {
    data: object[],
    parametryPdf: object[],
    handleClickPokazBraki: (zamowienie: any) => void;
    handleClickPokazMenuExportow: (zamowienie: any) => void;
    pageableObject?: any,
    handleChangeSizePerPage?: (e: number) => void,
    handleChangePage?: (e: any, a: any) => void,
    odswiez: () => void;
    handleChangeSortowanie?: (sortowanie: any) => void,
    sortProps?: any,
    loading: boolean
}

class ZamowieniaTable extends React.Component<Props> {

    state = {
        currentZamowienie: null,
        currentPozycje: [],
        isLoadingPozycje: false,
        isErrorPozycje: false,
        show: false,
        anulujSym: '',
        anulujNr: ''
    };

    formatZamowienieExportRow = (cell: any,row:any) => {
        return <TableActionsButtons_AnyAction handleClickAction={() => {
            this.props.handleClickPokazMenuExportow(row)
        }} tooltip={'Pokaż menu exportów'}><IconExportFile/></TableActionsButtons_AnyAction>

    };

    formatMoneyRow = (cell: number) => {
        return <div className={"text-right"}>{formatMoney(cell)}</div>
    };

    formatDataZamowieniaRow = (cell: string) => {
        if(!KoszykService.isEmpty(cell))
        return cell.substr(0, 10)
        else return ''
    };

    formatFakturaPokazBrakiRow = (cell: any, row: any) => {
        if(row.statPo === 20 || row.statPo === 100)
            return <TableActionsButtons_AnyAction handleClickAction={() => this.props.handleClickPokazBraki(row)}
                                                  tooltip={'Pokaż braki'}><IconMinusSquare/></TableActionsButtons_AnyAction>
        else
            return <></>
    };

    edytujZamowienie = (sym: any, nr: any) => {

        KoszykService.getStanKoszyka()
            .then((response: any) => {

                if (response.data.numer !== 0)
                {
                    displayNotificationError('Błąd edycji zamówienia', 'Jesteś w trakcie edycji innego zamówienia');
                } else
                {
                    // if (response.data.iloscPozycji !== 0)
                    // {
                    //     displayNotificationError('Koszyk zawiera pozycje', 'Przed edycją zamówienia koszyk musi być pusty');
                    // } else
                    // {
                        ZamowieniaService.getZamowienieDoEdycji(sym, nr).then((response: any) => {
                            displayNotificationInfo(`Tryb edycji zamówienia`, `Możesz teraz edytować zamówienie ${sym}${nr}`);
                            window.location.replace("/koszyk");
                        }).catch((e) => {
                            displayNotificationError('Błąd edycji zamówienia', e.response.data.message);
                            this.props.odswiez();
                        })
                    // }
                }

            })
            .catch((error: any) => {
                displayNotificationError('Błąd edycji zamówienia', 'Nie pobrano stanu koszyka');
            })

    }

    formatEdycja = (cell: any, row: any) => {
        if (cell === 1)
            return <TableActionsButtons_AnyAction handleClickAction={() => this.edytujZamowienie(row.symbol, row.numer)}
                                                  tooltip={'Edytuj zamówienie'}><i className="fa fa-pencil-square-o"/></TableActionsButtons_AnyAction>
        else
            return <></>

    };

    anulujZamowienie = (sym: any, nr: any) => {
        this.setState({anulujSym: sym, anulujNr: nr, show: true});
    }

    formatAnuluj = (cell: any, row: any) => {
        if (cell === 1)
            return <TableActionsButtons_AnyAction handleClickAction={() => this.anulujZamowienie(row.symbol, row.numer)}
                                                  tooltip={'Anuluj zamówienie'}><i className="fa fa-times"/></TableActionsButtons_AnyAction>
        else
            return <></>

    };

    formatZamknieteRow = (cell: any) => {
        return <TableFormatters_formatBoolean value={cell} titleTrue={'Tak'} titleFalse={'Nie'} classnameFalse={'text-grey'}/>
    };

    zamknij = () => {
        this.setState({show: false});
    }

    onAnulujZamowienie = () => {
        let {anulujSym, anulujNr} = this.state;

        ZamowieniaService.anulujZamowienie(anulujSym, anulujNr).then((response: any) => {
            displayNotificationInfo(`Zamówienie anulowane`, `Zamówienie ${anulujSym}${anulujNr} zostało poprawnie anulowane`);
            this.props.odswiez();
        }).catch((e) => {
            displayNotificationError('Błąd anulacji zamówienia', e.response.data.message);
            this.props.odswiez();
        })

        this.zamknij();
    }

    ikonaSort = (typ: string) => {
        let {sortProps} = this.props;
        let index = -1;

        for (let i = 0; sortProps.length > i; i++)
        {
            if (sortProps[i].kolumna === typ)
            {
                index = i;
                break;
            }
        }

        if (index !== -1)
        {
            if (sortProps[index].kierunek === "asc")
            {
                return <i className="fa fa-sort-asc" />
            } else
                return <i className="fa fa-sort-desc" />
        } else
            return <i className="fa fa-sort" style={{color: '#aeaeae'}}/>

    }

    onSortChange = (sortName: any, sortOrder: any) => {

        // @ts-ignore
        this.props.handleChangeSortowanie(changeSortowanie(this.props.sortProps, sortName));

    }

    expandRow = {
        renderer: (row: any) => (
            <div>
                <ZamowieniaExpand row={row}/>
            </div>
        ),
        onlyOneExpanding: true,
        expandByColumnOnly: true,
        showExpandColumn: true,
        onExpand: (row: any, isExpand: any, rowIndex: any, e: any) => {
            e.stopPropagation();
        },
        expandHeaderColumnRenderer: ({ }) => {
            return <>Poz.</>
        },
        // @ts-ignore
        expandColumnRenderer: ({expanded} ) => {
            return <div className={'TableButtons'}><Button variant={'light'} size={'sm'}><IconList/></Button></div>
        }
    };

    columns = [{
        dataField: 'symbol',
        text: 'Symbol',
        headerFormatter: () => {return <>Symbol &nbsp;{this.ikonaSort('symbol')}</>},
        align: 'center',
        headerStyle: {cursor: 'pointer'},
        onSort: this.onSortChange,
        sort: true
    }, {
        dataField: 'numer',
        text: 'Numer',
        headerFormatter: () => {return <>Numer &nbsp;{this.ikonaSort('numer')}</>},
        align: 'center',
        headerStyle: {cursor: 'pointer'},
        onSort: this.onSortChange,
        sort: true
    }, {
        dataField: 'ilePoz',
        text: 'Liczba pozycji',
        headerFormatter: () => {return <>Liczba pozycji &nbsp;{this.ikonaSort('ile_poz')}</>},
        align: 'center',
        headerStyle: {cursor: 'pointer'},
        onSort: this.onSortChange,
        sort: true
    }, {
        dataField: 'wart',
        text: 'Wartość',
        formatter: this.formatMoneyRow,
        headerFormatter: () => {return <>Wartość &nbsp;{this.ikonaSort('wart')}</>},
        headerStyle: {cursor: 'pointer'},
        onSort: this.onSortChange,
        sort: true
    }, {
        dataField: 'dataZam',
        text: 'Data zamówienia',
        formatter: this.formatDataZamowieniaRow,
        headerFormatter: () => {return <>Data zamówienia &nbsp;{this.ikonaSort('data_zam')}</>},
        align: 'center',
        headerStyle: {cursor: 'pointer'},
        onSort: this.onSortChange,
        sort: true
    }, {
        dataField: 'data_wyj',
        text: 'Data realizacji',
        formatter: this.formatDataZamowieniaRow,
        headerFormatter: () => {return <>Data realizacji &nbsp;{this.ikonaSort('data_wyj')}</>},
        align: 'center',
        headerStyle: {cursor: 'pointer'},
        onSort: this.onSortChange,
        sort: true
    }, {
        dataField: 'statusOpis',
        text: 'Status',
        // formatter: this.formatDataZamowieniaRow,
        headerFormatter: () => {return <>Status &nbsp;{this.ikonaSort('stat_po')}</>},
        align: 'center',
        headerStyle: {cursor: 'pointer'},
        onSort: this.onSortChange,
        sort: true
    },
    //     {
    //     dataField: 'zamk',
    //     text: 'Zamknięte?',
    //     formatter: this.formatZamknieteRow
    // },
        {
        dataField: 'numer',
        text: 'Exp',
        formatter: this.formatZamowienieExportRow
    }, {
        dataField: 'id',
        text: 'Braki',
        formatter: this.formatFakturaPokazBrakiRow
    }, {
        dataField: 'statusZam',
        text: 'Edycja',
        formatter: this.formatEdycja
    }, {
        dataField: 'statusZam',
        text: 'Anuluj',
        formatter: this.formatAnuluj
    }];

    render() {
        let {data, parametryPdf, pageableObject, handleChangeSizePerPage, handleChangePage, loading} = this.props;
        let {anulujSym, anulujNr} = this.state;

        return (
            <div id={'ZamowieniaTable'}>

                <Modal show={this.state.show} onHide={this.zamknij}>
                    <Modal.Header closeButton>
                        <Modal.Title>Anuluj zamówienie</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Czy na pewno chcesz anulować zamówienie {anulujSym}{anulujNr}?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.zamknij}>
                            Zamknij
                        </Button>
                        <Button variant="primary" onClick={this.onAnulujZamowienie}>
                            Tak, anuluj zamówienie
                        </Button>
                    </Modal.Footer>
                </Modal>

                <TopCard heading={'Złożone zamówienia'} hasPadding={false} enableExport={true}
                         dataToExport={changeNestedArrayToFlat(data, 'pozycje')}
                         columnsNames={prettyColumns_Zamowienia} parametryPdf={parametryPdf}>

                    <BootstrapTable remote keyField='numer' data={ data } columns={ this.columns } hover noDataIndication={'Brak pasujących wyników'}
                                    expandRow={this.expandRow}
                                    loading={loading}
                                    overlay={ overlayFactory({ spinner: true, styles: { overlay: (base: any) => ({...base, background: 'rgba(96,93,141,0.3)'}) } }) }
                                    pagination={(paginationFactory({
                                        page: (pageableObject.currentPage) + 1,
                                        sizePerPage: pageableObject.currentSize,
                                        totalSize: pageableObject.totalElements,
                                        sizePerPageList: [10, 12, 20, 50, 100],
                                        onSizePerPageChange: handleChangeSizePerPage,
                                        onPageChange: handleChangePage
                                    }))}
                    />

                </TopCard>
            </div>
        );
    }
}

export default ZamowieniaTable;
