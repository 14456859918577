import React, {Component} from 'react';
import {Button, Col, Form, FormGroup, Row} from "react-bootstrap";
import {IconInfo, IconSend} from "../../../sharedComponents/icons/FontAwesome";
import Req from "../../../sharedComponents/formComponents/Req";
import AddFormMargin from "../../../sharedComponents/formComponents/AddFormMargin";
import Select from "react-select";
import {NormalSelectStyles} from "../../../utilities/forms/jsStyles";
import {displayNotificationError} from "../../../utilities/notifications/NotificationsManager";
import {formatMoney} from "../../../utilities/formatters/formatMoney";
import {TextAlert} from "../../../sharedComponents/typographyComponents/TextAlert/TextAlert";
import {isMobileDevice} from "../../../utilities/rwd/rwdUtilities";
import KoszykService from "../../../services/KoszykService";

interface Props {
    czyZablokowacPrzycisk: boolean,
    warunkiDostawy: any,
    wartoscKoszyka: any,
    handleClickWyslijZamowienie: (obj: any) => void,
    czyEdycja: boolean,
    uwagiRest: string
}

interface State {
    uwagi: string,
    datyDostawy: any,
    dataDostawy: any
}

class KoszykOrderForm extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            uwagi: '',
            datyDostawy: [],
            dataDostawy: null
        }
    }

    componentDidMount() {
        this.generujDateDostawy();
        this.setState({uwagi: this.props.uwagiRest});
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any) {
        if(this.props.uwagiRest !== prevProps.uwagiRest)
        {
            this.setState({uwagi: this.props.uwagiRest});
        }
    }

    generujDateDostawy = () => {
        if (this.props.warunkiDostawy) {
            if (this.props.warunkiDostawy.pierwszyTermin && this.props.warunkiDostawy.nastepnyTermin) {
                let daty = [
                    {value: this.props.warunkiDostawy.pierwszyTermin, label: `Najbliższy termin: ${this.props.warunkiDostawy.pierwszyTermin}`},
                    {value: this.props.warunkiDostawy.nastepnyTermin, label: `Następny termin: ${this.props.warunkiDostawy.nastepnyTermin}`},
                ]
                this.setState({
                    datyDostawy: daty,
                    dataDostawy: daty[0]
                })
            }
        }
    };

    onChangeDataDostawy = (dataDostawy: any) => {
        if (dataDostawy >= new Date().toJSON().substr(0, 10))
            this.setState({dataDostawy})
        else
            displayNotificationError('Błąd wyboru daty dostawy', 'Nie można ustawić daty wcześniejszej niż dzisiejsza')
    };

    onChangeUwagi = (e: any) => {
        this.setState({uwagi: e.target.value});
    };

    onClickButtonWyslij = () => {
        let czyPoprawne = true;
        let {czyEdycja} = this.props;

        if (this.props.czyZablokowacPrzycisk) {
            czyPoprawne = false;
            displayNotificationError('Nie można wysłać pustego zamówienia', 'Brak pozycji w koszyku');
        }

        if (!Boolean(this.state.dataDostawy)) {
            czyPoprawne = false;
            displayNotificationError('Błąd wyboru daty dostawy', 'Nie wybrano daty dostawy')
        }

        if(!this.czyOsiagnietoMinimalnaKwote()){
            // czyPoprawne = false;
            // displayNotificationError('Nie osiągnięto kwoty minimalnej', 'Wartość produktów w koszyku jest mniejsza niż wymagana kwota minimalna')
            alert('Jeżeli suma zamówień na dzień dostawy nie będzie spełniać minimum logistycznego, dostawa może zostać przełożona na następny termin.');
        }

        if(this.state.dataDostawy.value.includes('nie')){
            czyPoprawne = false;
            displayNotificationError('Zła data dostawy', this.state.dataDostawy.value);
        }


        if (czyPoprawne) {
            this.props.handleClickWyslijZamowienie({
                //@ts-ignore
                "dataRea": this.state.dataDostawy.value,
                "uwagi": this.state.uwagi
            })
        }
    };

    czyOsiagnietoMinimalnaKwote = () => {
        let czyOsiagnieto = false;

        if (!KoszykService.isEmpty(this.props.warunkiDostawy))
            if (!KoszykService.isEmpty(this.props.warunkiDostawy.minimalnaWartoscZamowienia))
                if (this.props.wartoscKoszyka >= this.props.warunkiDostawy.minimalnaWartoscZamowienia && this.props.wartoscKoszyka !== 0)
                    czyOsiagnieto = true;

        return czyOsiagnieto;
    };

    obliczIleBrakujeDoMinimalnejKwoty = () => {
        let ile = 0;

        if (this.props.warunkiDostawy && !this.czyOsiagnietoMinimalnaKwote())
            ile = this.props.warunkiDostawy.minimalnaWartoscZamowienia - this.props.wartoscKoszyka;

        return ile;
    };

    render() {

        let {dataDostawy, datyDostawy, uwagi} = this.state;
        let {czyZablokowacPrzycisk, czyEdycja} = this.props;

        return (
            <div id={'KoszykOrderForm'}>
                <Form>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={9} xl={9} style={{padding: '0'}}>
                            <Row>
                                <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <FormGroup>
                                        <Form.Label>Data dostawy: <Req/></Form.Label>
                                        <Select options={datyDostawy} value={dataDostawy}
                                                styles={NormalSelectStyles}
                                                onChange={(dataDostawy: any) => {
                                                    this.setState({dataDostawy})
                                                }}
                                                isSearchable={!isMobileDevice()}/>
                                    </FormGroup>
                                    <FormGroup>
                                        <Form.Label>Forma płatności: </Form.Label>
                                        <div style={{lineHeight: '34px'}}>
                                            {(this.props.warunkiDostawy) && this.props.warunkiDostawy.formaPlatnosci}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                                    <FormGroup>
                                        <Form.Label>Uwagi ({uwagi.length}/100):</Form.Label>
                                        <textarea className={'form-control'} rows={4} value={uwagi} maxLength={100} onChange={this.onChangeUwagi}/>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={3} xl={3} className={'text-center'}>
                            <FormGroup>
                                <AddFormMargin/>
                                <Button variant={'primary'}
                                        type={'button'}
                                        // disabled={czyZablokowacPrzycisk || !this.czyOsiagnietoMinimalnaKwote()}
                                        disabled={czyZablokowacPrzycisk}
                                        onClick={this.onClickButtonWyslij}
                                ><IconSend/> {czyEdycja? "Aktualizuj zamówienie" : "Wyślij zamówienie"}</Button>
                            </FormGroup>
                            <FormGroup>
                                <div className={'minimalna-text'}>
                                    Minimalna kwota zamówienia wynosi: {(this.props.warunkiDostawy) && formatMoney(this.props.warunkiDostawy.minimalnaWartoscZamowienia)}
                                </div>
                                {
                                    this.czyOsiagnietoMinimalnaKwote() ?
                                        <TextAlert type={'success'}>Osiągnieto kwotę minimalną</TextAlert>
                                        :
                                        (this.props.warunkiDostawy && this.props.warunkiDostawy.minimalnaWartoscZamowienia !== 0) && <TextAlert type={'danger'}>Do osiągniecia minimalnej kwoty brakuje: {formatMoney(this.obliczIleBrakujeDoMinimalnejKwoty())}</TextAlert>
                                }
                                <div className={'minimalna-text'}><br/>
                                    Produkty z koszyka usuwane są automatycznie po 7 dniach.
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
}

export default KoszykOrderForm;