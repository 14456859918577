import React, {Component} from 'react';
import LayoutComponent from "../../../sharedComponents/layout/LayoutComponent";
import AdminKlienciTable from "./components/AdminKlienciTable";
import AdminKlienciFilterbox from "./components/AdminKlienciFilterbox";
import AdminKlienciForm from "./components/AdminKlienciForm";
import {Button} from "react-bootstrap";
import {IconAdd} from "../../../sharedComponents/icons/FontAwesome";
import AdminService from "../../../services/AdminService";
import TopLoading from "../../../sharedComponents/loader/TopLoading";
import {_omit} from "../../../utilities/lodash/lodashUtilities";
import {displayNotificationError, displayNotificationSuccess} from "../../../utilities/notifications/NotificationsManager";

class AdminKlienciModule extends Component {

    state = {
        showForm: false,
        klienci: [],

        params: {
            email: "",
            // kto: "",
            role: "",
            telefon: "",
            topNazwa: "",
            zgodaEmail: false,
            zgodaMob: false
        },

        isRequestLoading: true,
        isRequestError: false,

        page: 0,
        size: 12,
        pageableObject: {
            currentPage: 0,
            currentSize: 0,
            totalElements: 0
        },

        klientEdit: null,
        loading: false
    };

    componentDidMount() {
        this.getKlienci()
    }

    getKlienci = (params = this.state.params, page: number = this.state.page, size: number = this.state.size) => {
        this.setState({
            // isRequestLoading: true,
            isRequestError: false,
        });


        AdminService.getUzytkownicy(page, size, params)
            .then((response: any) => {
                if (response && response.status < 300) {
                    this.setState({
                        isRequestLoading: false,
                        isRequestError: false,
                        loading: false,
                        klienci: response.data.content,
                        pageableObject: Object.assign({}, _omit(response.data, "content"), {currentPage: page}, {currentSize: size}),
                    });
                } else {
                    this.setState({
                        isRequestLoading: false,
                        isRequestError: true,
                        loading: false,
                        hasMoreForGrid: false,
                    });
                }
            })
            .catch((error: any) => {
                this.setState({
                    isRequestLoading: false,
                    isRequestError: true,
                    loading: false,
                    hasMoreForGrid: false,
                });
            })
    };


    onClickFiltruj = (params: any) => {
        this.setState({params: params, page: 0, produkty: [], loading: true}, () => this.getKlienci());
    };

    /* table functions  */

    onChangePageInTable = (page: number, size: number) => {
        this.setState({
            page: page - 1,
            size: size,
            loading: true
        }, () => this.getKlienci())
    };

    onChangeSizeInTable = (size: number) => {
        this.setState({
            size,
            loading: true
        }, () => this.getKlienci())
    };

    odblokujUzytkownika = (nazwa: string) => {
        AdminService.odblokujDostep(nazwa).then((response) => {
            displayNotificationSuccess('Odblokowano dostęp', nazwa);
            this.getKlienci();
        }).catch((e) => {
            console.log('error');
        })
    }

    onClickZapisz = (klient: any) => {
        let {klientEdit} = this.state;

        if(klientEdit)
        {
            AdminService.editKlient(klient)
                .then((response) => {
                    if (response && response.status < 300) {
                        this.setState(({page: 0, showForm: false, klientEdit: null}), () => this.getKlienci());
                        displayNotificationSuccess('Pomyślnie edytowano klienta', '')
                    } else {
                        displayNotificationError('Wystąpił błąd podczas próby edycji klienta', '')
                    }
                })
                .catch((error: any) => {
                    displayNotificationError('Wystąpił błąd podczas próby edycji klienta', '')
                })
        } else
        {
            AdminService.addKlient(klient)
                .then((response) => {
                    if (response && response.status < 300) {
                        this.setState(({page: 0, showForm: false, klientEdit: null}), () => this.getKlienci());
                        displayNotificationSuccess('Pomyślnie dodano klienta', '')
                    } else {
                        displayNotificationError('Wystąpił błąd podczas próby dodania klienta', '')
                    }
                })
                .catch((error: any) => {
                    displayNotificationError('Wystąpił błąd podczas próby dodania klienta', '')
                })
        }
    };

    onClickEdytuj = (item: any) => {
        // alert(item.id);
        this.setState({klientEdit: item}, () => this.setState({showForm: !this.state.showForm}));
    }

    onClickUsun = (item: any) => {
        if(window.confirm(`Czy na pewno usunąć użytkownika ${item.login}?`)) {
            AdminService.delKlient(item.id)
                .then((response) => {
                    if (response && response.status < 300) {
                        this.getKlienci();
                        displayNotificationSuccess('Pomyślnie usunięto użytkownika', '')
                    } else {
                        displayNotificationError('Wystąpił błąd podczas próby usunięcia użytkownika', '')
                    }
                })
                .catch((error: any) => {
                    displayNotificationError('Wystąpił błąd podczas próby usunięcia użytkownika', '')
                })
        }
    }

    render() {

        let {klienci, pageableObject, isRequestLoading, isRequestError, klientEdit, loading} = this.state;

        return (
            <LayoutComponent breadcrumbs={[{url: "/admin", name: 'PA'}, {
                url: "/klienci",
                name: 'Klienci'
            }]} isAdminPanel={true}>
                <div id={'ADMIN_MODULE'}>
                    <div id={'KLIENCI_MODULE'}>
                        {
                            this.state.showForm &&
                                <AdminKlienciForm handleClickAnuluj={() => {
                                    this.setState({showForm: false, klientEdit: null})
                                }}
                                                  handleClickZapisz={this.onClickZapisz}
                                                  itemEdycja={klientEdit}
                                />
                        }
                        <>
                            {
                                !this.state.showForm &&
                                <div>
                                    <div className={'float-right'}>
                                        <Button variant={'success'}
                                                size={'sm'}
                                                onClick={() =>
                                                    this.setState({
                                                        showForm: true,
                                                    })
                                                }><IconAdd/> Dodaj</Button>
                                    </div>
                                    <div className={'clearfix'}></div>
                                </div>
                            }
                            <AdminKlienciFilterbox handleClickSzukaj={this.onClickFiltruj}/>
                        </>
                        <TopLoading isLoading={isRequestLoading} isError={isRequestError}>
                            <AdminKlienciTable klienci={klienci}
                                               pageableObject={pageableObject}
                                               handleChangePage={this.onChangePageInTable}
                                               handleChangeSizePerPage={this.onChangeSizeInTable}
                                               odblokujUzytkownika={this.odblokujUzytkownika}
                                               clickEdytuj={this.onClickEdytuj}
                                               clickUsun={this.onClickUsun}
                                               loading={loading}
                            />
                        </TopLoading>
                    </div>
                </div>
            </LayoutComponent>
        );
    }
}

export default AdminKlienciModule;