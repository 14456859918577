import React from 'react';
import TopCard from "../../../../sharedComponents/TopCard";
// import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import tgrid from "../../../../utilities/table/tgrid";
import {TableFormatters_formatBoolean} from "../../../../sharedComponents/tableComponents/tableFormatters";
import {TableActionButtons_EditAnyAction} from "../../../../sharedComponents/tableComponents/tableButtons";
import {
    IconEdit,
    IconEnvelope,
    IconLockOpen,
    IconLockUser,
    IconPhone, IconTrash
} from "../../../../sharedComponents/icons/FontAwesome";
import {prettyColumns_AdminKlienci} from "../../../../utilities/exports/prettyColumnToExport";
import {Button, ButtonToolbar} from "react-bootstrap";
// import paginationFactory from "react-bootstrap-table2-paginator";
import ZamowieniaTable from "../../../zamowienia/components/ZamowieniaTable";
// import {odblokujDostep} from "../../../../services/AdminService";
// @ts-ignore
import overlayFactory from 'react-bootstrap-table2-overlay';

interface Props {
    klienci: any[],
    pageableObject?: any,
    handleChangeSizePerPage?: (e: number) => void;
    handleChangePage?: (e: any, a: any) => void;
    odblokujUzytkownika?: (nazwa: string) => void;
    clickEdytuj: (item: any) => void;
    clickUsun: (item: any) => void;
    loading: boolean
}

class AdminKlienciTable extends React.Component<Props> {

    formatNazwa = (cell: string, row: any) => {
        return (
            <div>
                <h6 style={{margin: '0'}}>{cell}</h6>
                {/*<h6 style={{margin: 0, fontWeight: 'normal'}} className={'heading-label'}>*/}
                {/*    {row.telefon && <><IconPhone/> {row.telefon}</>}*/}
                {/*    {(row.telefon && row.email) && <span style={{margin: '0 3px', color: "#ddd"}}>|</span>}*/}
                {/*    {row.email && <><IconEnvelope/> {row.email}</>}*/}
                {/*</h6>*/}
            </div>
        )
    };

    formatTypUzytkownika = (cell: any, row: any) => {
        if (row.roleSystemAdmin)
            return <div className={'tekstCenter'}>ADMIN</div>
        else return (
            <div className={'tekstCenter'}>
                {cell}
                <h6 style={{margin: 0, fontWeight: 'normal'}} className={'heading-label'}>{(row.roleKlientWlasciciel)? 'Właściciel' : 'Pracownik'}</h6>
            </div>
        )
    };

    formatCzyAktywny = (cell: boolean) => {
        return <TableFormatters_formatBoolean value={cell} titleTrue={'Jest aktywny'} titleFalse={'Nie jest aktywny'}/>
    };

    formatCzyZgoda = (cell: boolean) => {
        return <TableFormatters_formatBoolean value={cell} titleTrue={'Zgoda zatwierdzona'} titleFalse={'Brak zgody'}/>
    };

    formatActionButtons = (cell: any, row: any) => {

        let czyZablokowany: boolean = !Boolean(row.accountNonLocked);

        return <div className={'TableButtons'}>
            <Button variant={'light'}
                    size={'sm'}
                    onClick={() => {
                        // @ts-ignore
                        this.props.odblokujUzytkownika(row.kontrahent);
                    }} title={'Klient zablokowany - kliknij, by odblokować'}
                    hidden={!czyZablokowany}
                    // className={actionClassname}
            >
                <IconLockUser/>
            </Button>
            <Button variant={'light'}
                    size={'sm'}
                    onClick={() => this.props.clickEdytuj(row)} title={'Kliknij aby edytować użytkownika'}
                    className={'edit'}
            >
                <IconEdit/>
            </Button>
            <Button variant={'light'}
                    size={'sm'}
                    onClick={() => this.props.clickUsun(row)} title={'Kliknij aby usunąć użytkownika'}
                    className={'delete'}
            >
                <IconTrash/>
            </Button>
        </div>


        // <TableActionButtons_EditAnyAction
        //     handleClickEdit={() => {
        //         alert('edit');
        //     }}
        //     handleClickAnyAction={() => {
        //     }}
        //     actionIcon={czyZablokowany ? <IconLockUser/> : <IconLockOpen/>}
        //     actionTitle={czyZablokowany ? 'Klient aktywny - kliknij by zablokować' : 'Klient nieaktywny - kliknij, by odblokować'}
        // />
    };

    render() {

        let {pageableObject, handleChangeSizePerPage, handleChangePage, klienci, loading} = this.props;

        const columns = [{
            dataField: 'id',
            text: 'ID',
            align: 'center'
        }, {
            dataField: 'kontrahent',
            text: 'Kontrahent',
            align: 'center',
            formatter: this.formatNazwa
        }, {
            dataField: 'login',
            text: 'Login',
            align: 'center'
        }, {
            dataField: 'regjon',
            text: 'Region',
            align: 'center'
        }, {
            dataField: 'pracownik',
            text: 'Pracownik',
            align: 'center'
        }, {
            dataField: 'typ',
            text: 'Typ konta',
            align: 'center',
            formatter: this.formatTypUzytkownika
        }, {
            dataField: 'telefon',
            text: 'Telefon',
            align: 'center'
        }, {
            dataField: 'email',
            text: 'E-mail',
            align: 'center'
        }, {
            dataField: 'zgodaEmail',
            text: 'Zgoda email',
            style: {width: '50px'},
            formatter: this.formatCzyZgoda
        }, {
            dataField: 'zgodaMob',
            text: 'Zgoda mobilne',
            style: {width: '50px'},
            formatter: this.formatCzyZgoda
        },{
            dataField: 'aktywny',
            text: 'Czy aktywny?',
            style: {width: '50px'},
            formatter: this.formatCzyAktywny
        }, {
            dataField: 'ostLogWww',
            text: 'Ost. logowanie www',
            align: 'center'
        }, {
            dataField: 'ostLogMob',
            text: 'Ost. logowanie mobilne',
            align: 'center'
        }, {
            dataField: 'id',
            text: 'Akcje',
            align: 'center',
            style: {width: '110px'},
            formatter: this.formatActionButtons
        }];

        return (
            <div id={'AdminKlienciTable'}>
                <TopCard heading={'Klienci w systemie'} hasPadding={false} enableExport={true} dataToExport={klienci} columnsNames={prettyColumns_AdminKlienci}>

                    <BootstrapTable remote keyField='numer' data={klienci} columns={ columns } hover noDataIndication={'Brak pasujących wyników'}
                                    loading={loading}
                                    overlay={ overlayFactory({ spinner: true, styles: { overlay: (base: any) => ({...base, background: 'rgba(96,93,141,0.3)'}) } }) }
                                    pagination={(paginationFactory({
                                        page: (pageableObject.currentPage) + 1,
                                        sizePerPage: pageableObject.currentSize,
                                        totalSize: pageableObject.totalElements,
                                        sizePerPageList: [10, 12, 20, 50, 100],
                                        onSizePerPageChange: handleChangeSizePerPage,
                                        onPageChange: handleChangePage
                                    }))}
                    />

                </TopCard>
            </div>
        );
    }

};

export default AdminKlienciTable;