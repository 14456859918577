import {HOST_API} from "../ApplicationConfig";
import axios from 'axios';

const HOST = HOST_API + 'promocje/';

export default class PromocjeService {

    static getPromocje = () => {
        return axios.request({
            method: 'get',
            url: `${HOST}getPromocje`
        })
    };

    static getPromocjeGrupy = () => {
        return axios.request({
            method: 'get',
            url: `${HOST}getPromocjeGrupy`
        })
    };

    static getPromocjeGrupyNew = () => {
        return axios.request({
            method: 'get',
            url: `${HOST}getPromocjeGrupyNew`
        })
    };

    static getPromocjeNew = (prom: string, sort: string) => {
        return axios.request({
            method: 'get',
            url: `${HOST}getPromocjeNew/${prom}/${sort}`
        })
    };

    static getPromocjeIndeks = (indeks: string) => {
        return axios.request({
            method: 'get',
            url: `${HOST}getPromocjeDlaIndeksu/${indeks}`
        })
    };

    static getPromocjaInfo = (idUm: string) => {
        return axios.request({
            method: 'get',
            url: `${HOST}getPromocjaInfo/${idUm}`
        })
    };

    static getBaneryForUser = () => {
        return axios.request({
            method: 'get',
            url: `${HOST}getBaneryForUser`
        })
    };

    static dodajNowyBaneru = (data: any) => {
        return axios.request({
            method: 'post',
            url: `${HOST_API}admin/addNewBaner`,
            data: data,
            headers: { "Content-Type": "multipart/form-data" }
        })
    };

    static getPromocjaProgrowa = (czyEdycja: boolean, idUm: any) => {
        return axios.request({
            method: 'post',
            // url: `${HOST}getPromocja`,
            url: `${HOST}getPromocjaPromPakGNew`,
            // url: `${HOST}getPromocjaPromPakG`,
            data: {
                czyEdycja: czyEdycja,
                idUmowy: idUm
            }
        })
    };

    static procPromPakGSprawdzKoszyk = (czyEdycja: boolean, idUm: any) => {
        return axios.request({
            method: 'post',
            // url: `${HOST}getPromocja`,
            url: `${HOST}procPromPakGSprawdzKoszyk`,
            data: {
                czyEdycja: czyEdycja,
                idUmowy: idUm
            }
        })
    };

    static procPromPakCuSprawdzKoszyk = (czyEdycja: boolean, idUm: any) => {
        return axios.request({
            method: 'post',
            // url: `${HOST}getPromocja`,
            url: `${HOST}procPromPakCuSprawdzKoszyk`,
            data: {
                czyEdycja: czyEdycja,
                idUmowy: idUm
            }
        })
    };

    static getPromocjaCenaUpust = (czyEdycja: boolean, idUm: any) => {
        return axios.request({
            method: 'post',
            // url: `${HOST}getPromocja`,
            url: `${HOST}getPromocjaPromPakCu`,
            data: {
                czyEdycja: czyEdycja,
                idUmowy: idUm
            }
        })
    };

    static getPromocjaCenaUpustNew = (czyEdycja: boolean, idUm: any) => {
        return axios.request({
            method: 'post',
            // url: `${HOST}getPromocja`,
            url: `${HOST}getPromocjaPromPakCuNew`,
            data: {
                czyEdycja: czyEdycja,
                idUmowy: idUm
            }
        })
    };

    static getPromocjaPromProgCu = (czyEdycja: boolean, idUm: any) => {
        return axios.request({
            method: 'post',
            // url: `${HOST}getPromocja`,
            url: `${HOST}getPromocjaPromProgCu`,
            data: {
                czyEdycja: czyEdycja,
                idUmowy: idUm
            }
        })
    };

    static getPromocjaPromProgG = (czyEdycja: boolean, idUm: any) => {
        return axios.request({
            method: 'post',
            // url: `${HOST}getPromocja`,
            url: `${HOST}getPromocjaPromProgG`,
            data: {
                czyEdycja: czyEdycja,
                idUmowy: idUm
            }
        })
    };

    static addEditItemPromProgCu = (obj: any) => {
        return axios.request({
            method: 'put',
            url: `${HOST}addEditItemPromProgCu`,
            data: JSON.stringify(obj)
        })
    };

    static addEditItemPromProgG = (obj: any) => {
        return axios.request({
            method: 'post',
            url: `${HOST}addEditPromProgG`,
            data: JSON.stringify(obj)
        })
    };

    static dodajPakiet = (idUm: number, prog: number, czyEdycja: boolean, ile: number) => {
        return axios.request({
            method: 'post',
            url: `${HOST}addPakietPromPakG`,
            data: {
                czyEdycja: czyEdycja,
                idUmowy: idUm,
                prog: prog,
                ilPak: ile
            }
        })
    };

    static dodajPakietCu = (idUm: number, prog: number, czyEdycja: boolean, ile: number) => {
        return axios.request({
            method: 'post',
            url: `${HOST}addPakietPromPakCu`,
            data: {
                czyEdycja: czyEdycja,
                idUmowy: idUm,
                prog: prog,
                ilPak: ile
            }
        })
    };

    static getStronyGazetki = (dane: any) => {
        return axios.request({
            method: 'post',
            url: `${HOST}getStronyGazetki`,
            data: dane
        })
    };

    static getIndeksyNaStronie = (dane: any) => {
        return axios.request({
            method: 'post',
            url: `${HOST}getIndeksyNaStronie`,
            data: dane
        })
    };

    static getLabel = (promocja: string) => {
        switch (promocja) {
            case 'PROM_PROST_CU':
                return 'cenowa';
            case 'PROM_PAK_G':
                return 'pakietowa - gratis';
            case 'PROM_PAK_CU':
                return 'pakietowa - cena upust';
            case 'PROM_PROG_CU':
                return 'progowa - cena upust';
            case 'PROM_PAK_L':
                return 'lojalnościowa';
            case 'PROM_PROG_G':
                return 'progowa - gratis';
        }
    };

}