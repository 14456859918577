import React from 'react';

interface Props{
    hideForMobileDevices991?:boolean,
    hideForMobileDevices767?:boolean,
    hideForMobileDevices575?:boolean,
    marginTop?: string
}

const AddFormMargin:React.FC<Props> = ({marginTop, hideForMobileDevices991=false,hideForMobileDevices767=false,hideForMobileDevices575=false}) => {

    let classname = 'add-form-margin';
    if(hideForMobileDevices991) classname += ' hide-for-mobile-devices-991';
    if(hideForMobileDevices767) classname += ' hide-for-mobile-devices-767';
    if(hideForMobileDevices575) classname += ' hide-for-mobile-devices-575';

    return (
        <div className={classname} style={{marginTop: marginTop? marginTop : '28px'}}/>
    );
};

export default AddFormMargin;