export const NavbarLinks = [
    {
        key: 1,
        level: 1,
        name: 'Informacje',
        url: '/informacje',
        icon: 'fa fa-info-circle'
    }, {
        key: 2,
        level: 1,
        name: 'Oferta',
        url: '/oferta',
        icon: 'fa fa-cubes'
    },
    // {
    //     key: 7,
    //     level: 1,
    //     name: 'Quick add',
    //     url: '/quickadd',
    // },
    {
        key: 3,
        level: 1,
        name: 'Promocje',
        url: '/promocje',
        icon: 'fa fa-percent'
    }, {
        key: 4,
        level: 1,
        name: 'Zamówienia',
        url: '/zamowienia',
        icon: 'fa fa-cart-arrow-down'
    }, {
        key: 5,
        level: 1,
        name: 'Faktury',
        url: '/faktury',
        icon: 'fa fa-file-text'
    }, {
        key: 6,
        level: 1,
        name: 'Rozrachunki',
        url: '/rozrachunki',
        icon: 'fa fa-money'
    },
];
