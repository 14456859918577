import React from 'react';
import {formatMoney} from "../../../utilities/formatters/formatMoney";
import {IconCheck, IconTimes} from "../../../sharedComponents/icons/FontAwesome";
import {calculateBruttoPrice} from '../../../utilities/calculations/moneyCalcs';
import {famix_zaokraglijIloscDlaJM1} from "../../../utilities/famix_others/famix_operations";

interface Props {
    item: any
}

const ProductDescription: React.FC<Props> = ({item}) => {

    return (
        <div id={'ProductDescription'}>
            <h2>
                {item.producent}
            </h2>
            <h1>{item.nazwa}</h1>
            <h6>
                Indeks: {item.indeks}
                {/*Indeks: <a href={'../oferta/'+item.indeks}>{item.indeks}</a>*/}
            </h6>

            <table className={'infoProd'}>
                <tbody>
                <tr>
                    <td>EAN:</td>
                    <td>{item.nrKat}</td>
                </tr>
                {
                    (item.jm !== item.jm2) &&
                    <tr>
                        <td>Ilość w opakowaniu:</td>
                        <td>{item.jm2}: {famix_zaokraglijIloscDlaJM1(item.jm,item.ilJm2)} {item.jm}</td>
                    </tr>
                }
                {
                    (item.jm2 !== item.jm3) &&
                    <tr>
                        <td>Ilość w kartonie:</td>
                        <td>{item.jm3}: {famix_zaokraglijIloscDlaJM1(item.jm,item.ilJm3)} {item.jm}</td>
                    </tr>
                }
                <tr>
                    <td>
                        Ilość na warstwie:
                    </td>
                    <td>
                        {(item.ilWa ? item.ilWa : '?')} {item.jm}
                    </td>
                </tr>
                <tr>
                    <td>
                        Ilość na palecie:
                    </td>
                    <td>
                        {(item.ilPa ? item.ilPa : '?')} {item.jm}
                    </td>
                </tr>
                <tr>
                    <td>
                        Cena podstawowa:
                    </td>
                    <td>
                        {formatMoney(item.cenaS)} netto / {formatMoney(calculateBruttoPrice(item.cenaS, item.vat))} brutto /{item.jm}
                    </td>
                </tr>
                <tr>
                    <td>
                        Cena sprzedaży:
                    </td>
                    <td>
                        {formatMoney(item.cena)} netto / {formatMoney(calculateBruttoPrice(item.cena, item.vat))} brutto /{item.jm}
                    </td>
                </tr>
                <tr>
                    <td>
                        Stawka VAT:
                    </td>
                    <td>
                        {item.vat}%
                    </td>
                </tr>
                {/*<tr>*/}
                {/*    <td>*/}
                {/*        Dostępność:*/}
                {/*    </td>*/}
                {/*    <td>*/}
                {/*        {*/}
                {/*            item.dostepne ?*/}
                {/*                <span className={'card-dostepny'}><IconCheck/> Produkt dostępny</span>*/}
                {/*                :*/}
                {/*                <span className={'card-niedostepny'}><IconTimes/> Produkt niedostępny - prosimy o kontakt z wybranym oddziałem</span>*/}
                {/*        }*/}
                {/*    </td>*/}
                {/*</tr>*/}
                </tbody>
            </table>
        </div>
    );
};

export default ProductDescription;
