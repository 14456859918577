import {_merge, _omit} from "../lodash/lodashUtilities";
import React from "react";

export const changeNestedArrayToFlat = (arr: any[], nestedObject: string) => {
    let flatarr: any[] = [];

    arr.forEach((item: any) => {
        if (Boolean(item[nestedObject]) && item[nestedObject].length > 0) {
            item[nestedObject].forEach((nestedItem: any) => {
                flatarr.push(
                    _merge(_omit(item, nestedObject), nestedItem)
                );
            });
        } else {
            flatarr.push(_omit(item, nestedObject));
        }

    });

    return flatarr;

};

export const returnSumOfArrayByKey = (arr: any[], key: string) => {
    let sum = 0;

    arr.forEach((item: any) => {
        sum = sum + item[key];
    });

    return sum;
};

// export const changeSortowanie = (oldSort: any, clickColumn: string): object[] => {
//     let sort = oldSort;
//     let jest = false;
//     let kolumna = clickColumn;
//
//     if (kolumna === 'nazwaPokaz') {
//
//         kolumna = 'cast(NAZWA as varchar(60) character set win1250) collate PXW_PLK ';
//
//     } else if (kolumna === 'cenaS') {
//
//         kolumna = 'cena_s';
//
//     } else if (kolumna === 'producent') {
//
//         kolumna = 'cast(PRODUCENT as varchar(60) character set win1250) collate PXW_PLK ';
//
//     } else if (kolumna === 'nrKat') {
//
//         kolumna = 'nr_kat';
//
//     }
//
//     for (let i = 0; sort.length > i; i++)
//     {
//         if (kolumna === sort[i].kolumna) {
//             jest = true;
//             if (sort[i].kierunek === 'asc')
//             {
//                 sort[i].kierunek = 'desc';
//             } else {
//                 sort.splice(i, 1);
//             }
//         }
//     }
//
//     if (!jest) {
//         sort.push(Object.assign({kolumna: kolumna, kierunek: 'asc'}));
//     }
//
//     // zwraca obiekt (kolumna: '', kierunek: '') z nazwami kolumn i rodzajem sortowania (malejaco/rosnaco) w kolejnosci klikania
//     return sort;
// }

export const ikonaSort = (typ: string, sort: any): any => {
        // let {sort} = this.state;
        let index = -1;

        for (let i = 0; sort.length > i; i++)
        {
            if (sort[i].kolumna === typ)
            {
                index = i;
                break;
            }
        }

        if (index !== -1)
        {
            if (sort[index].kierunek === "asc")
            {
                return <i className="fa fa-sort-asc" />
            } else
                return <i className="fa fa-sort-desc" />
        } else
            return <i className="fa fa-sort" style={{color: '#aeaeae'}}/>

}

export const changeSortowanie = (oldSort: any, clickColumn: string): object[] => {
    let sort = oldSort;
    let jest = false;
    let kolumna = clickColumn;

    if (kolumna === 'nazwaPokaz') {

        kolumna = 'cast(NAZWA as varchar(60) character set win1250) collate PXW_PLK ';

    } else if (kolumna === 'cenaS') {

        kolumna = 'cena_s';

    } else if (kolumna === 'producent') {

        kolumna = 'cast(PRODUCENT as varchar(60) character set win1250) collate PXW_PLK ';

    } else if (kolumna === 'nrKat') {

        kolumna = 'nr_kat';

    } else if (kolumna === 'upustProcentowy') {

        kolumna = 'upust_procentowy';

    } else if (kolumna === 'zakupJm') {

        kolumna = 'koszyk_ilosc';

    } else if (kolumna === 'numerFaktury') {

        kolumna = 'numer';

    } else if (kolumna === 'dataWys') {

        kolumna = 'data_wys';

    } else if (kolumna === 'termPl') {

        kolumna = 'term_pl';

    } else if (kolumna === 'magazyn') {

        kolumna = 'numag';

    } else if (kolumna === 'doData') {

        kolumna = 'do_data';

    } else if (kolumna === 'nrRach') {

        kolumna = 'nr_rach';

    } else if (kolumna === 'ilePoz') {

        kolumna = 'ile_poz';

    } else if (kolumna === 'statusOpis') {

        kolumna = 'stat_po';

    } else if (kolumna === 'dataZam') {

        kolumna = 'data_zam';

    }

    for (let i = 0; sort.length > i; i++)
    {
        if (kolumna === sort[i].kolumna) {
            // jest juz kolumna
            jest = true;
            if (sort[i].kierunek === 'asc')
            {
                sort.splice(i, 1);
                sort.unshift(Object.assign({kolumna: kolumna, kierunek: 'desc'}));
                // sort[i].kierunek = 'desc';
            } else {
                sort.splice(i, 1);
            }
        }
    }

    if (!jest) {
        sort.unshift(Object.assign({kolumna: kolumna, kierunek: 'asc'}));
    }

    // zwraca obiekt (kolumna: '', kierunek: '') z nazwami kolumn i rodzajem sortowania (malejaco/rosnaco) w kolejnosci klikania
    return sort;
}

export const changeSortowanieKoszyk = (oldSort: any, clickColumn: string): object[] => {
    let sort = oldSort;
    let jest = false;
    let kolumna = clickColumn;

    if (kolumna === 'indeks') {

        kolumna = 'k.indeks';

    } else if (kolumna === 'nazwa') {

        kolumna = 'k.nazwa';

    } else if (kolumna === 'wartosc') {

        kolumna = '14';

    }

    for (let i = 0; sort.length > i; i++)
    {
        if (kolumna === sort[i].kolumna) {
            // jest juz kolumna
            jest = true;
            if (sort[i].kierunek === 'asc')
            {
                sort.splice(i, 1);
                sort.unshift(Object.assign({kolumna: kolumna, kierunek: 'desc'}));
                // sort[i].kierunek = 'desc';
            } else {
                sort.splice(i, 1);
            }
        }
    }

    if (!jest) {
        sort.unshift(Object.assign({kolumna: kolumna, kierunek: 'asc'}));
    }

    // zwraca obiekt (kolumna: '', kierunek: '') z nazwami kolumn i rodzajem sortowania (malejaco/rosnaco) w kolejnosci klikania
    return sort;
}

export const changeSortowanieReklamacje = (oldSort: any, clickColumn: string): object[] => {
    let sort = oldSort;
    let jest = false;
    let kolumna = clickColumn;

    if (kolumna === 'nrReklamacji') {

        kolumna = 'reklamacja';

    } else if (kolumna === 'statusOpis') {

        kolumna = 'status';

    } else if (kolumna === 'nrFaktury') {

        kolumna = 'fk_num';

    } else if (kolumna === 'dataWys') {

        kolumna = 'data_wys';

    } else if (kolumna === 'dataWpr') {

        kolumna = '3';

    } else if (kolumna === 'powodRekl') {

        kolumna = 'grupa';

    } else if (kolumna === 'nrKorekty') {

        kolumna = 'nr_korekty';

    }

    for (let i = 0; sort.length > i; i++)
    {
        if (kolumna === sort[i].kolumna) {
            // jest juz kolumna
            jest = true;
            if (sort[i].kierunek === 'asc')
            {
                sort.splice(i, 1);
                sort.unshift(Object.assign({kolumna: kolumna, kierunek: 'desc'}));
                // sort[i].kierunek = 'desc';
            } else {
                sort.splice(i, 1);
            }
        }
    }

    if (!jest) {
        sort.unshift(Object.assign({kolumna: kolumna, kierunek: 'asc'}));
    }

    // zwraca obiekt (kolumna: '', kierunek: '') z nazwami kolumn i rodzajem sortowania (malejaco/rosnaco) w kolejnosci klikania
    return sort;
}

export const changeSortowaniePozycjeFaktury = (oldSort: any, clickColumn: string): object[] => {
    let sort = oldSort;
    let jest = false;
    let kolumna = clickColumn;

    if (kolumna === 'indeks') {

        kolumna = 'g.indeks';

    } else if (kolumna === 'nazwa') {

        kolumna = 'g.nazwa';

    } else if (kolumna === 'cena') {

        kolumna = 'g.cena';

    } else if (kolumna === 'jm') {

        kolumna = 'g.jm';

    } else if (kolumna === 'wartosc') {

        kolumna = '12';

    }

    for (let i = 0; sort.length > i; i++)
    {
        if (kolumna === sort[i].kolumna) {
            // jest juz kolumna
            jest = true;
            if (sort[i].kierunek === 'asc')
            {
                sort.splice(i, 1);
                sort.unshift(Object.assign({kolumna: kolumna, kierunek: 'desc'}));
                // sort[i].kierunek = 'desc';
            } else {
                sort.splice(i, 1);
            }
        }
    }

    if (!jest) {
        sort.unshift(Object.assign({kolumna: kolumna, kierunek: 'asc'}));
    }

    // zwraca obiekt (kolumna: '', kierunek: '') z nazwami kolumn i rodzajem sortowania (malejaco/rosnaco) w kolejnosci klikania
    return sort;
}

export const changeSortowaniePromocje = (oldSort: any, clickColumn: string): object[] => {
    let sort = oldSort;
    let jest = false;
    let kolumna = clickColumn;

    if (kolumna === 'nazwa') {

        kolumna = 'cast(NAZWA%20as%20varchar(60)%20character%20set%20win1250)%20collate%20PXW_PLK%20 ';

    } else if (kolumna === 'opis') {

        kolumna = 'cast(OPIS%20as%20varchar(100)%20character%20set%20win1250)%20collate%20PXW_PLK%20 ';

    } else if (kolumna === 'idUm') {

        kolumna = 'id_um';

    } else if (kolumna === 'dataOd') {

        kolumna = 'data_od';

    } else if (kolumna === 'dataDo') {

        kolumna = 'data_do';

    }

    for (let i = 0; sort.length > i; i++)
    {
        if (kolumna === sort[i].kolumna) {
            // jest juz kolumna
            jest = true;
            if (sort[i].kierunek === 'asc')
            {
                sort.splice(i, 1);
                sort.unshift(Object.assign({kolumna: kolumna, kierunek: 'desc'}));
                // sort[i].kierunek = 'desc';
            } else {
                sort.splice(i, 1);
            }
        }
    }

    if (!jest) {
        sort.unshift(Object.assign({kolumna: kolumna, kierunek: 'asc'}));
    }

    // zwraca obiekt (kolumna: '', kierunek: '') z nazwami kolumn i rodzajem sortowania (malejaco/rosnaco) w kolejnosci klikania
    return sort;
}

export const isEmpty = (value: any): boolean => {
    if((value === undefined) || (value === null) || (value === '') || (value === 'null')){
        return true
    } else {
        return false
    }
}