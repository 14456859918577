import React from 'react';
import {Button, Col, Form, FormControl, FormGroup, Row} from "react-bootstrap";
import Req from "../../../sharedComponents/formComponents/Req";
import AddFormMargin from "../../../sharedComponents/formComponents/AddFormMargin";
import {IconFilter} from "../../../sharedComponents/icons/FontAwesome";
import {SmallerSelectStyles} from "../../../utilities/forms/jsStyles";
import Select from "react-select";
import {
    getFirstDayOfCurrentMonthAndYear,
    getMonthObjFromDate,
    getYearObjFromDate,
    monthsArray,
    yearsArray
} from "../../../utilities/calendar_date/calendar_dateUtilities";
import {isMobileDevice} from "../../../utilities/rwd/rwdUtilities";

interface Props {
    handleClickFiltruj: (params: any) => void;
}

class ZamowieniaFilterbox extends React.Component<Props> {

    state = {
        selectedYear: null,
        selectedMonth: null,
        symbol: '',
        czyPokazywacZamowieniaPolaczone: true,
        statusFiltr: {value: null, label: ''}
    };

    componentDidMount() {
        this.setYearAndMonth()
    }

    statusArray: any = [
        {value: null, label: 'WSZYSTKIE'},
        {value: -20, label: 'Anulowane przez klienta'},
        {value: -5, label: 'W trakcie edycji przez klienta'},
        {value: -10, label: 'W trakcie edycji przez PH'},
        {value: 0, label: 'Przyjęte'},
        {value: 10, label: 'W trakcie realizacji'},
        {value: 20, label: 'Wystawiono Fk'},
        {value: 100, label: 'Usunięte'}
    ];

    setYearAndMonth = () => {
        this.setState({
            selectedYear: getYearObjFromDate(getFirstDayOfCurrentMonthAndYear()),
            selectedMonth: getMonthObjFromDate(getFirstDayOfCurrentMonthAndYear()),
        });
    };

    onChangeYear = (e: any) => {
        this.setState({selectedYear: e}, () => this.onClickFiltruj());
        //this.onClickFiltruj();
    };

    onChangeMonth = (e: any) => {
        this.setState({selectedMonth: e}, () => this.onClickFiltruj())
    };

    onChangeSymbol = (e: any) => {
        this.setState({symbol: e.target.value.toUpperCase()})
    };

    onChangeStatus = (e: any) => {
        this.setState({statusFiltr: e}, () => this.onClickFiltruj())
    };

    onClickCzyPokazywacZamowieniaPolaczone = (e: any) => {
        this.setState((prevState: any) => ({czyPokazywacZamowieniaPolaczone: !prevState.czyPokazywacZamowieniaPolaczone}))
    };

    onClickFiltruj = () => {

        if (Boolean(this.state.selectedYear) && Boolean(this.state.selectedMonth)) {

            //@ts-ignore
            let date = `${this.state.selectedYear.value}-${this.state.selectedMonth.valueString}-01`

            let params = {
                // "czyPokazacZamowieniaPolaczone": this.state.czyPokazywacZamowieniaPolaczone,
                "dataZamowien": date,
                "symbolZam": this.state.symbol,
                "statusPo": this.state.statusFiltr.value
            };

            if(this.state.statusFiltr.value === null) this.setState({statusFiltr: {value: null, label: ''}});

            this.props.handleClickFiltruj(params)
        }
    };

    handleKeyPress(target: any) {
        if (target.charCode==13){
            this.onClickFiltruj()
        }
    }

    render() {

        return (
            <div id={'ZamowieniaFilterbox'}>
                <Form>

                    <Row>
                        <Col md={6} lg={4} xl={3} style={{padding: '0'}}>
                            <FormGroup>
                                <Form.Label>Zamówienia z okresu (rok / miesiąc): <Req/></Form.Label>
                                <Row>
                                    <Col xs={5} sm={5} md={5} lg={5} xl={5} style={{padding: '0'}}>
                                        <Select
                                            options={yearsArray}
                                            value={this.state.selectedYear}
                                            onChange={this.onChangeYear}
                                            isClearable={false}
                                            styles={SmallerSelectStyles}
                                            placeholder={'Wybierz rok...'}
                                            isSearchable={!isMobileDevice()}
                                        />
                                    </Col>
                                    <Col xs={7} sm={7} md={7} lg={7} xl={7} id={'miesiac'}>
                                        <Select
                                            options={monthsArray}
                                            value={this.state.selectedMonth}
                                            onChange={this.onChangeMonth}
                                            isClearable={false}
                                            styles={SmallerSelectStyles}
                                            placeholder={'Wybierz miesiąc...'}
                                            isSearchable={!isMobileDevice()}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Col>
                        <Col md={4} lg={3} xl={2} style={{padding: '0'}}>
                            <FormGroup>
                                <Form.Label>Symbol zamówienia:</Form.Label>
                                <FormControl size={'sm'} maxLength={10} value={this.state.symbol} onChange={this.onChangeSymbol} onKeyPress={this.handleKeyPress.bind(this)}/>
                            </FormGroup>
                        </Col>
                        <Col md={4} lg={3} xl={2} className={'noPading'}>
                            <FormGroup>
                                <Form.Label>Status zamówienia:</Form.Label>
                                <Select
                                    options={this.statusArray}
                                    value={this.state.statusFiltr}
                                    onChange={this.onChangeStatus}
                                    isClearable={false}
                                    styles={SmallerSelectStyles}
                                    placeholder={'Wybierz status...'}
                                    isSearchable={!isMobileDevice()}
                                />
                            </FormGroup>
                        </Col>
                        {/*<Col md={4} lg={3} xl={3}>*/}
                        {/*    <FormGroup controlId={'ZamowieniaPolaczoneCheckbox'}>*/}
                        {/*        <AddFormMargin hideForMobileDevices991={true}/>*/}
                        {/*        <Form.Check inline={true} type="checkbox"*/}
                        {/*                    label="Pokaż zamówienia połączone"*/}
                        {/*                    onClick={this.onClickCzyPokazywacZamowieniaPolaczone}*/}
                        {/*                    checked={this.state.czyPokazywacZamowieniaPolaczone}*/}
                        {/*        />*/}
                        {/*    </FormGroup>*/}
                        {/*</Col>*/}
                        {/*<Col md={6} lg={5} xl={4}>
                            <FormGroup>
                                <AddFormMargin hideForMobileDevices767={true}/>
                                <Form.Check inline={true} type="checkbox" label="Zamówienia internetowe"/>
                                <Form.Check inline={true} type="checkbox" label="Wszystkie zamówienia"/>
                            </FormGroup>
                        </Col>*/}
                        <Col md={6} lg={2} xl={2} style={{padding: '0'}}>
                            <FormGroup>
                                <AddFormMargin hideForMobileDevices991={true}/>
                                <Button type={'button'} variant={'primary'} size={'sm'} onClick={this.onClickFiltruj}>
                                    <IconFilter/> Filtruj
                                </Button>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
}

export default ZamowieniaFilterbox;
